import { useEffect, useState } from "react";



const ChartMakeSubAccount = (props) => {
    const [checked, setChecked] = useState(false);


    useEffect(()=> {
      if(props?.defaultVal === true){
        setChecked(true)
      }
    },[props?.defaultVal])
    
    const onselectionChange = (e) => {
        setChecked(e.target.checked)
       
        props.subAccountSelect(e.target.checked);
      };


    return (  
        <>
         <input
                        type="checkbox"
                        checked={checked}  onChange={onselectionChange}
                        className="w-[0.8vw] h-[1.6vh]"
                        value="1"
                      />
        </>
    );
}
 
export default ChartMakeSubAccount;