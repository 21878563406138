/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown } from "react-bootstrap";
import { customerService } from "../../services/customer.service";
import LogicContext from "../../Context/LogicContext";
import { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { useContext } from "react";
import { GetRecoilCorporateList, SetRecoilCorporateList } from "../../store/corporateAtom";
import underscore from "underscore";
import CsvDownload from 'react-json-to-csv';
import RestartAlt from '@mui/icons-material/RestartAlt';
import Tooltip from '@mui/material/Tooltip';
import { useLocation, useNavigate } from "react-router-dom";
import { corporateService } from "../../services/corporation.service";
import RatingMainTable from "./RatingMainTable";
import { GetRatingListAtom, SetRatingListAtom } from "../../store/ratingAtom";
import RatingTypeSelect from "./RatingTypeSelect";


function RatingMainPage() {
  const logicContext = useContext(LogicContext);
  const getRatingListAtom = GetRatingListAtom();
const setRatingListAtom = SetRatingListAtom();
  const [customerSelect, setCustomerSelect] = useState([]);
  const [tableList, setTableList] = useState([]);

  const navigate = useNavigate();
  const mountedRef = useRef(true);

  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];


  useEffect(()=> {
    window.title = 'Rating page';
    if(getRatingListAtom.rating_info?.length){
      setTableList(getRatingListAtom.rating_info);
    }

  },[getRatingListAtom.rating_info]);


  
  function refreshList(){
    customerService.getRatingList().then( response => {
      console.log("Rating... ", response?.data);
      if(response?.data.rating_info){
        setRatingListAtom(response.data);
          setTableList(response?.data?.rating_info);
      }

  });

  }
 
  function addNewCorporate(){
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`)
  }



  function selectCustomer(selectionList) {
    // setCustomerSelect(selectionList)
  }


  function customFilter(value) {
//     console.log("type ", value);
//     if(value === 100){
//       return setTableList(getRecoilCorporateData?.Corporate_Info);
//     }
//    const newList = getRecoilCorporateData?.Corporate_Info.filter((data)=> data?.Corporate_Type_Id === value );
//    setTableList(newList);
  }


  
  function exportCustomers(){
    // customerService.getAllCustomers().then(res => {
    //   setAllCustomers(res.data);
    // }).catch(err => {
    //   logicContext.showToast('error getting customers data ', 'warning');
    // });
  }



  function pdfExportHandler(){
  }

  return (
    <>
  
    <section>
      
      <div className="   w-full   lg:flex justify-start">
        <section className=" w-full">
          <section className="bg-white py-4 px-2    md:flex justify-between ">
            <div className="flex px-2 mt-1">
        

         
             
            <Tooltip title="Reload">
           <RestartAlt
             className="cursor ml-2 mt-1"
             onClick={() => refreshList()}
             color="primary"
             fontSize="large"
           />
         </Tooltip>

         <Tooltip title="Total ">
         <div className="card font-weight-bold text-primary p-1 pl-3 pr-3 ml-3  bg-light">
                 Total: {tableList?.length}
         </div>
         </Tooltip>

            </div>
          
            <div >
              {/* {customerSelect.length > 0 ? (
                <section className="max-w-xl flex  pl-9 justify-center w-[80vh]">
                  <div className="w-full md:w-[70%] lg: flex justify-between ">
                    <button className=" bg-filterColor text-black font-[Poppins]  font-bold  mt-1 px-2 rounded h-[5.8vh]  sm:px-10 md: text-xs  lg:w-[50vw]">
                      ACTIVE
                    </button>
                    <button className=" bg-filterColor text-black font-[Poppins] font-bold  mt-1 px-2 rounded h-[5.8vh]  mx-2  sm:px-10 md:   text-xs lg:w-[50vw]">
                      IN ACTIVE
                    </button>
          
                    <button className="bg-filterColorRed  text-black  font-[Poppins]  mt-1  px-2 rounded mr-4 h-[5.8vh]  sm:px-11   text-xs px-11 lg:w-[50vw]">
                      DELETE
                    </button>
                  </div>
                </section>
              ) : null} */}
            </div>

            <div className="flex justify-between">
            <div className="   grid grid-cols-1 gap-1 content-center">
        
            
        

             

            </div>
          
              <button className="  font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8 text-xs">
                IMPORT
              </button>
              <div className="btn-group">
               <button onClick={()=> exportCustomers()} className=" font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8   text-xs" data-toggle="dropdown" aria-expanded="false">
                EXPORT
              </button>
                {/* <ul className="dropdown-menu">
                <li><a  className="dropdown-item" href="#"> <CsvDownload  data={allCustomers}>CSV  <small>{allCustomers.length} item(s)</small> </CsvDownload> </a></li>
                <li><a   className="dropdown-item" href="#">Excel  <small>{allCustomers.length} item(s)</small></a></li>
                <li><a onClick={()=> pdfExportHandler()}  className="dropdown-item" href="#">PDF</a></li>
              </ul> */}
            </div>

            <div className=" grid grid-cols-1 gap-1 content-center">
            <button onClick={addNewCorporate}
                className="bg-buttonColor font-[Poppins] py-2 rounded-md  px-3  text-white text-xs">
                  ADD NEW
                </button>
            </div>
           

               
             
            </div>
          </section>

          <section className="">
          {/* <div className="font-[Poppins] font-bold py-3 mt-1 px-2 w-15 flex justify-center my-2 rounded bg-[#476EF8] text-white  text-xs">
                CORPORATE USER LIST
              </div> */}
            {/* Table */}
            <RatingMainTable  tableList={tableList} />
          </section>
        </section>
      </div>
    </section>
    </>

  );
}

export default RatingMainPage;
