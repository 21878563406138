

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


export const  compositeGroupAddForm = atom({key:'compositeGroupAddForm', default:[]});


const compositeGroupAtomEditAtom = atom({key:'compositeGroupAtomEditAtom', default:{}});



export  const SetCompositeGroupEdit = () => useSetRecoilState(compositeGroupAtomEditAtom);
export  const GetCompositeGRoupEdit = () => useRecoilValue(compositeGroupAtomEditAtom);
export  const ResetCompositeGRoupEdit = () => useResetRecoilState(compositeGroupAtomEditAtom);



export  const SetCompositeGroupAddList = () => useSetRecoilState(compositeGroupAddForm);
export  const GetCompositeGroupAddList = () => useRecoilValue(compositeGroupAddForm);
export const ResetCompositeGroupAddList = ()=> useResetRecoilState(compositeGroupAddForm)