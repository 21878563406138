
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const broadCastService = {
    submitBroadCast,
    getCustomerList,
    getBroadcastStatus
};



async function  submitBroadCast(url,form) {
    const response = await fetch(url, {method:"POST",
headers:{"Content-Type": "application/json"},
body: JSON.stringify(form)});
    const resData = await response.json();
    return resData;
}

async function getCustomerList(url) {
    const response = await fetch(url +'/customer-list', {method:"GET",
headers:{"Content-Type": "application/json"}});
    const resData = await response.json();
    return resData;
}

async function getBroadcastStatus(url) {
    const response = await fetch(url +'/message-status', {method:"GET",
headers:{"Content-Type": "application/json"}});
    const resData = await response.json();
    return resData;
}













