

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";



const corporateApprovedAtom = atom({key:'rewardHistory_atom', default:[]
});


export  const SetRewardHistoryStoreList= () => useSetRecoilState(corporateApprovedAtom);
export  const GetRewardHistoryStoreList = () => useRecoilValue(corporateApprovedAtom);







