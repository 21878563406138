import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import React,{ useState, useEffect, useContext } from "react";
import GeneralModal from '../../helpers/GeneralModal';
import { createModal } from "react-modal-promise";
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import IVRSystemTab from './IvrSystemTab';
import IVRHistoryTab from './IvrHistoryTab';
import IVRSchedule from './IVRSchedule';
import ServiceHistory from './ServiceHistory';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;



  
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  




function IVRPage() {
    const [value, setValue] = React.useState('1');



    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return ( 
        <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="">
            <Tab label="IVR System" value="1" />
            <Tab label="Delgro History" value="2" />
            <Tab label="Service History" value="3" />
            <Tab label="Schedule" value="4" />
          </TabList>
        </Box>
        
        <TabPanel value="1">
          <IVRSystemTab />
        </TabPanel>
        <TabPanel value="2">
          <IVRHistoryTab />
        </TabPanel>
        <TabPanel value="3">
          <ServiceHistory />
        </TabPanel>
        <TabPanel value="4">
          <IVRSchedule />
        </TabPanel>
      </TabContext>
    </Box>


        </>
     );
}

export default IVRPage;