
import { authHeader } from '../helpers/authHeader';
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';


export const transactionService = {
    getTransactionInvoice,
    getTransactionPayment,
    getRetainerInvoice,
    getSalesOrder,
    getPackage,
    getBill,
    getCreditNote,
    getMasterData
};



  function getMasterData(){ 
   return  axios.get(`/transactions-master-data`, authHeader())
  }


  function getTransactionInvoice(customer_id){
  return  axios.get(`/get-transaction-invoice/${customer_id}`, authHeader())}


  function getTransactionPayment(customer_id){
  return  axios.get(`get-transaction-payment/${customer_id}`, authHeader())

  }
  


  function getRetainerInvoice(customer_id){
   return  axios.get(`/get-transaction-retainer/${customer_id}`, authHeader())
  }

  function getSalesOrder(customer_id){
   return axios.get(`/get-transaction-sales-order/${customer_id}`, authHeader())

  }

  function getPackage(customer_id){
   return axios.get(`/get-transaction-package/${customer_id}`, authHeader());

  }


  function getBill(customer_id){
     return axios.get(`/get-transaction-bill/${customer_id}`, authHeader())
  }


  function getCreditNote(customer_id){
   return axios.get(`/get-transaction-credit-note/${customer_id}`, authHeader())
  }



