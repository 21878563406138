
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const voucherService = {
     getCorporateVoucherList,
     corporateVoucherDetails,
    createVoucher,
    shareVouchers,
    updateVoucher,
    getVoucherOffline,
    getVoucherOfflineDetails,
    searchVoucherById,
    verifyOtp,
    sendVoucherOtp,
    voucherRedeemAction,
    getVoucherList,
    voucherDetails

    
 
};


async function  getVoucherOfflineDetails(id) {
    return  axios.get(`/accounts/voucher-offline-details/${id}`, authHeader());
}



async function  getCorporateVoucherList() {
    return  axios.get(`/accounts/get-cor-voucher-list`, authHeader());
}

function corporateVoucherDetails(id){
    return axios.get(`/accounts/get-cop-voucher-details/${id}`, authHeader())
   }

function updateVoucher(data){
    console.log('DGS ', data);
    return axios.put(`/accounts/update-voucher`, data, authHeader())
 
   }
   function createVoucher(data){
    return axios.post(`/accounts/create-voucher`,data, authHeader())
 
   }


async function  getVoucherList() {
    return  axios.get(`/accounts/get-voucher-list`, authHeader());
}

function voucherDetails(id){
    return axios.get(`/accounts/get-voucher-details/${id}`, authHeader())
   }




function shareVouchers(data){
    return axios.post(`/accounts/share-vouchers`,data, authHeader())
 
   }


function getVoucherOffline(){
    return axios.get(`/accounts/voucher-offline`, authHeader())
 
   }


function  searchVoucherById(id){
    return axios.get(`/accounts/search-voucher-by-id/${id}`, authHeader())
   }
   
   
   function  verifyOtp(data){
       return axios.put(`/accounts/verify-voucher-otp`, data, authHeader())
    }
    
    
    function  sendVoucherOtp(id){
        return axios.get(`/accounts/send-voucher-otp/${id}`, authHeader())
       }

    function  voucherRedeemAction(data){
        return axios.post(`/accounts/voucher-redeem-action`, data, authHeader())
       }


