/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLocation, useNavigate } from "react-router-dom";
// import CustomerTable from "./CustomerTable";


import { useCallback, useEffect } from "react";

import RestartAlt from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import VoucherCorporateMainTable from "./VoucherCorporateMainTable";
import { voucherService } from "../../services/voucherService";
import { GetVoucherListAtom, SetVoucherListAtom } from "../../store/voucherAtom";
import Tooltip from "@mui/material/Tooltip";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



function VoucherCorporateMainPage() {
  const { pathname } = useLocation();
  const getVoucherList = GetVoucherListAtom();
  const setVoucherList = SetVoucherListAtom();
  const navigate = useNavigate();
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];


  useEffect(() => {
    document.title = 'Voucher Purchase';
    if (getVoucherList.voucher_info.length < 1) {
      loadVoucherData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const reloadData = () => {
    loadVoucherData();
  }


  const loadVoucherData = () => {
    voucherService.getCorporateVoucherList().then(res => {
      if (res.data?.voucher_info?.length) {
        setVoucherList({...res?.data, newVoucherList:[]});
      }
    });
  }

  function addNew() {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`)
  }


  return (

    <>
      <Grid container >
        <Grid item xs={12}>
          {/* grid start */}
          <div className="row   mt-5 mb-2" >
            <div className="col" >
              <Tooltip title="Reload">
                <RestartAlt
                  className="cursor ml-2 mt-2 mr-2"
                  onClick={() => reloadData()}
                  color="primary"
                  fontSize="large"
                />
              </Tooltip>
            </div>



            <div className="col offset-3" >

              <div className="flex  justify-end">
                {/* <button
                  style={{ backgroundColor: "#476EF8" }}
                  onClick={() => addNew()}
                  className=" mr-3  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
                >
                  EXPORT
                </button> */}
                <button
                  style={{ backgroundColor: "#476EF8" }}
                  onClick={() => addNew()}
                  className="  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
                >
                  ADD NEW
                </button>

              </div>
            </div>

          </div>
          {/* End of Grid */}


        </Grid>
      </Grid>


      <section className="mt-5   w-full">
        <VoucherCorporateMainTable list={getVoucherList.voucher_info} />
      </section>
    </>

  );
}

export default VoucherCorporateMainPage;
