

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


const rewardCenterAtom = atom({key:'reward_center_atom', default:{
    earn_info:[],faq_info:[],redeem_info:[]}});


export  const SetRewardCenterList = () => useSetRecoilState(rewardCenterAtom);
export  const GetRewardCenterList = () => useRecoilValue(rewardCenterAtom);





