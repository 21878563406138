import { useEffect } from "react";
import Select from 'react-select';



function IVRFilter(props) {

  useEffect(()=> {

  },[]);

  const notificationFilterList =[
    {label:" New Registration",Filter_Option: 10, Notification_Filter_Id:1},
    {label:" Active Subscribers",Filter_Option: 11, Notification_Filter_Id:2},
    {label:"Inactive subscribers",Filter_Option: 12, Notification_Filter_Id:3},
    {label:"Month No wallet balance ",Filter_Option: 13, Notification_Filter_Id:4},
    {label:"Subscription due",Filter_Option: 14, Notification_Filter_Id:5},
  ]

  
  const customStyles = {
    control: base => ({
      ...base,
      height: 30,
      backgroundColor: '',
      // backgroundColor: '#F1F1F1',
      width: 250,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',width:100, height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#FFF', color:"black"}),

  
    
  }

    const handleChange = (value) => {
      props.onFilterSelect(value)
      };


    return ( 
        <>
         <Select   styles={customStyles} onChange={handleChange} 
         placeholder="Select by Group..."
          options={notificationFilterList}
         />
        </>
     );
}

export default IVRFilter;
