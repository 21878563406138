import styles from "./Comment.module.css";
import chat from "../../assets/chat.png";
import { customerService } from "../../services/customer.service";
import { useEffect, useState } from "react";
import { useNavigate,  useLocation } from "react-router-dom";
import { Table, Input } from 'antd';

const { Search } = Input;



function CustomerDetailsGiftList(props) {
  const { customer_id } = props;
  const [history, setHistory] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { pathname } = useLocation();
  const navigate = useNavigate();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  useEffect(() => {
    customerService.customerDetailsGift(customer_id).then((res) => {

        console.log('Gift_List', res.data);
      if (res?.data) {
        setHistory(res.data);
        setFilteredData(res.data);
        // const  customizeData =  res.data.map((record)=> {
        //   return {...record, History_Created_By: record.History_Created_By?.toString()}
        // })
        // setHistory(customizeData);
        // setFilteredData(customizeData);

      }
    });
  }, [customer_id]);




  const handleRowClick = (record) => {
    // console.log('Row clicked:', record);voucher/details
    navigate(`/${currModuleId}/${currModuleName}/voucher/details/${record?.Ref_No}`);
  };


  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = history.filter((record) =>
      Object.values(record).some((text) =>
        String(text).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };


  const paginationOptions = {
    pageSize: 10,
    showSizeChanger: true,
    total: filteredData.length,
    // onChange: (page, pageSize) => {
    //   console.log(page, pageSize);
    //   const newList = tableDataList.slice(0, pageSize);
    //   console.log('check ', newList);
    //   setTableList(newList);
    // },
  };



const columns = [
    {
      title: 'Ref',
      key: 'id',
      dataIndex: 'Ref_No',
      className:"text-primary",
      sorter: (a, b) => a.Ref_No.localeCompare(b.Ref_No)
    //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },
    {
      title: 'Type',
      key: 'id',
      dataIndex: 'Gift_Type_Name',
      sorter: (a, b) => a.Gift_Type_Name.localeCompare(b.Gift_Type_Name)
    //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },
 
    {
      title: "Amount",
      dataIndex: "Gift_Amount",
      sorter: (a, b) => a.Gift_Amount.localeCompare(b.Gift_Amount)
    },
  
    {
      title: "Gifted By",
      dataIndex: "Gifted_By",
      sorter: (a, b) => a.Gifted_By.localeCompare(b.Gifted_By)
    },
    {
      title: "Gifted By Mobile Number",
      dataIndex: "Gifted_By_Mobile_Number",
      sorter: (a, b) => a.Gifted_By_Mobile_Number.localeCompare(b.Gifted_By_Mobile_Number)
    },
    {
      title: "Gift Date",
      dataIndex: "Gift_Date",
      sorter: (a, b) => a.Gift_Date.localeCompare(b.Gift_Date)
    },
  ];



  return (
    <>
      <div className="mt-4 p-4 w-full bg-white @container">
        <div className="">
        <Search
        placeholder="Search..."
        onSearch={handleSearch}
        onChange={(e) => handleSearch(e.target.value)}
        value={searchText}
        style={{ marginBottom: 10, width: 250 }}
      />

        <Table key={filteredData?.length} columns={columns} dataSource={filteredData}
                      pagination={paginationOptions}
                      rowClassName="show-cursor"
                      style={{ background: '#f0f2f5', borderRadius: '8px' }}
                      onRow={(record) => {
                        return {
                          onClick: () => {
                            handleRowClick(record);
                          },
                        };
                      }}
                        />

        </div>
      </div>
    </>
  );
}

export default CustomerDetailsGiftList;
