import { useEffect, useState } from 'react';
import { GetInvoiceEntryMasterData} from '../../store/invoiceAtom';

let list = [
    {Discount_Type_Id: 1, Discount_Type_Name: 'Flat'},
     {Discount_Type_Id: 2, Discount_Type_Name: '%'}
]
 

function PurchaseDiscountType(props) {
    const masterData = GetInvoiceEntryMasterData();
  const [customOption, setCustomOption] = useState([]); 

    



    useEffect(()=> {
      let topElement = {};
      const tempData =  list?.map((element, index)=> {
        if(props?.defaultVal === element?.Discount_Type_Id){
          topElement = {...element, label:`${element?.Discount_Type_Name}`};
        }
     
         return ({...element, label:`${element?.Discount_Type_Name}`})
       }
       );
       if(topElement?.label){
        tempData.unshift(topElement)
      }
       setCustomOption(tempData)
   
     },[masterData, props]);


 
     const  createSelectItems = ()=> {
        let items = [];         
        list.forEach((discount, i)=> {
          items.push(<option key={i} value={discount.Discount_Type_Id}>{discount.Discount_Type_Name}</option>)
        });


        if(props?.defaultVal === 2){
          return items.reverse();
        }else{

          return items;
        }
    } 
      

    return ( 
        <>
  <select  onChange={(e)=> props.onDiscountTypeSelect(e.target.value)}>{createSelectItems()} </select>
        </>
     );
}

export default PurchaseDiscountType;