import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import PriceDetailsTable from "./PriceDetailsTable";



function PriceListContent(props) {
    const { data } = props;



    useEffect(()=> {
        
   

    },[data]);


    //  dynamically manage different status
    const manageSalesStatus = (status) => {
        let defaultClass = 'status_color1 text-white';

        switch (status) {
            case 'Draft':
                defaultClass = 'text-light status_color2';
                break;
            case 'Active':
                defaultClass = 'text-light status_color3';
                break;
            case 'Pending for Approval':
                defaultClass = 'text-dark status_color4';
                break;
            case 'Fulfilled':
                defaultClass = 'text-light status_color5';
                break;
            case 'Rejected':
                defaultClass = 'text-light status_color6';
                break;
            case 'Confirmed':
                defaultClass = 'text-light status_color1';
                break;
            case 'Partially Billed':
                defaultClass = 'text-light status_color8';
                break;

            default:
                break;
        }

        return (
            <span className={`py-2 pl-4 pr-4 pt-2 pb-2 h5 rounded-circle ${defaultClass} `}>
                {status}
            </span>
        );
    };


    // view subscription by going to subscription module




    return (
        <section className="   mt-2 w-full max-w-6xl text-filterTextColor">
        
         <li class="list-group-item active font-bold text-center"> <h2> Product Item Information </h2> </li>

           
            <Grid className=" bg-light pb-5  " container spacing={2}>
              
                <Grid className="border-2 mx-4  bg-light  my-2 " item xs={12}>
                    <Grid container spacing={2}>

                        <Grid item xs={6}>
                            <span className="#476ef8  ">
                            <li class="list-group-item   text-left"> Corporate Name:  <span className="text-primary font-bold" >{data?.Corporate_Name}  </span> </li>
                            <li class="list-group-item   text-left"> Contact Person:<span className="text-primary" >  {data?.Corporate_Contact_Person}  </span> </li>
                            <li class="list-group-item   text-left">Contact Number: <span className="text-primary">  {data?.Corporate_Contact_Person_Mobile} </span> </li>
                            </span>
                        </Grid>

                        <Grid item xs={6}>
                            <span className="#476ef8  ">
                            <li class="list-group-item   text-left">  Email: <span className="text-primary">{data?.Corporate_Email_Address}   </span> </li>
                            <li class="list-group-item   text-left"> Address: <span className="text-primary"> {data?.Corporate_Adddress}, {data?.Area_Name},
                             {data?.City_Name}, {data?.Country_Name}  </span> </li> 
                              <li class="list-group-item   text-left"> Agreement Date: <span className="text-primary"> {moment(data?.Agreement_Date).format('MMMM Do YYYY')} </span> </li>
                            </span>
                        </Grid>
                            <div  className="mt-5" >

                           
                            </div>
                       
                    </Grid>
                </Grid>

            </Grid>
            

        </section>
    );
}

export default PriceListContent;
