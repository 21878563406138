import { useEffect } from 'react';
import { GetInvoiceEntryMasterData} from '../../store/invoiceAtom';



function InvoiceDiscountType(props) {
    const masterData = GetInvoiceEntryMasterData();
    console.log('SEee ', masterData['Discount_Type'])


    useEffect(()=> {
      if(props?.defaultVal){
//  do nothing
      }else{
        if(masterData['Discount_Type']?.length > 0){
          props.onDiscountTypeSelect(masterData['Discount_Type'][0]['Discount_Type_Id'])
      }
      }
           
    },[props, masterData]);

    const handleChange = (e) => {
      props.onDiscountTypeSelect(e.target.value)
      };

 
     const  createSelectItems = ()=> {
        let items = [];         
    
        masterData['Discount_Type']?.forEach((discount, i)=> {
          items.push(<option key={i} value={discount.Discount_Type_Id}>{discount.Discount_Type_Name}</option>)
        });


        if(props?.defaultVal === 2){
          return items.reverse();
        }else{

          return items;
        }
    } 
      

    return ( 
        <>

  <select onChange={handleChange}>{createSelectItems()} </select>
        </>
     );
}

export default InvoiceDiscountType;