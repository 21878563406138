import React, { useRef, useContext } from "react";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import moment from "moment";

// import InvoicePaymentTable from "./InvoicePaymentTable";
// import InvoiceDetailsTable from "./InvoiceDetailsTable";

const SalesOrderInvoice = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const { item_id } = useParams();
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const viewInvoice = (invoiceId) => {
    navigate(
      `/${currModuleId}/${currModuleName}/invoices/details/${invoiceId}`
    );
  };

  return (
    <>
      <div className="mt-2 ">
        <TableContainer className="mt-3 w-full border mb-5">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              {/* <col style={{width:'20%'}}/> */}
            </colgroup>
            <TableHead style={{ backgroundColor: "#F1F1F1" }}>
              <TableRow>
                <TableCell>Reference No</TableCell>
                <TableCell>Invoice No</TableCell>
                <TableCell> Date </TableCell>
                <TableCell> Amount </TableCell>
                {/* <TableCell> Amount </TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody className=" mb-3">
              {props?.tableData?.map((invoice, i) => {
                return (
                  <TableRow
                    className="border-1"
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      {invoice.Invoice_Ref_No}{" "}
                    </TableCell>
                    <TableCell
                      className="text-primary cursor"
                      onClick={() => viewInvoice(invoice.Invoice_Ref_No)}
                      align="left"
                    >
                      {invoice.Invoice_No}{" "}
                    </TableCell>
                    <TableCell align="left">
                      {moment(invoice.Invoice_Date).format("DD-MM-YYYY")}{" "}
                    </TableCell>

                    <TableCell align="left">
                      <CurrencyFormat
                        value={invoice.Invoice_Amount?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default SalesOrderInvoice;
