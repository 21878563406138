import { Grid } from "@mui/material";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PurchaseReceiveDetailsTable from "./PurchaseReceiveDetailsTable";


function PurchaseReceiveContent(props) {
  const { purchaseData } = props.props;

  const { pathname } = useLocation();
  // const setPurchaseEdit = SetPurChaseOrderEdit();


  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const navigate = useNavigate();


  const manageInvoiceStatus = (status) => {
    let defaultClass = 'bg-primary text-white';

    switch (status) {
      case 'Draft':
        defaultClass = 'text-light bg-danger';
        break;
      case 'Open':
        defaultClass = 'text-light bg-primary';
        break;
      case 'Pending Approval':
        defaultClass = 'text-dark bg-warning';
        break;
      case 'Billed':
        defaultClass = 'text-light bg-info';
        break;
      case 'Paid':
        defaultClass = 'text-light bg-success';
        break;
      case 'Received':
        defaultClass = 'text-light bg-success';
        break;
      case 'Partial Received':
        defaultClass = 'text-dark bg-warning';
        break;

      default:
        break;
    }


    return (
      <div className={`py-2 pl-4 pr-4 pt-2 pb-2 h5 ${defaultClass} rounded`}>
        {status}
      </div>
    )



  }


  const viewPurchaseOrder = (id) => {
    navigate(`/${currModuleId}/${currModuleName}/${'purchase-order'}/details/${id}`)
  }


  return (
    <section className="   mt-2 w-full max-w-6xl text-filterTextColor">

      <Grid className="border-2  ml-1 " container spacing={2}>

        <Grid className="border-2 mx-2 my-2 " item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>

              <div>
                <div>
                  <span className="#476ef8 font-bold ">
                    PURCHASE  RECEIVED
                  </span>
                  <br></br>
                  <span className=" ">
                    <i className="subheading font-bold" >
                      <span  onClick={()=> viewPurchaseOrder(purchaseData?.Purchase_Order_Ref_No)}
                       className="text-primary subheading font-bold" >Receive #  {purchaseData?.Purchase_Receive_Number} </span>  </i>
                  </span>{" "}
                  <br></br>
                  <span className=" ">
                    <i className="subheading font-bold " >
                      <span className="text-black cursor subheading font-bold  text-primary"
                       onClick={()=> viewPurchaseOrder(purchaseData?.Purchase_Order_Ref_No)}
                       >Purchase Order Number:{purchaseData?.Purchase_Order_Ref_No} </span>  </i>
                  </span>{" "}
                  <br></br>
                </div>
              </div>

            </Grid>
            <Grid item className=" pl-1 flex justify-end " xs={6}>
                  <div className="w-50">
                    {manageInvoiceStatus(purchaseData?.Purchase_Receive_Status_Name)}
                  </div>
                </Grid> 

            <Grid item xs={12}>
              <Grid className="pb-2" container spacing={0} >
                <Grid item xs={12}>
                  <Grid container spacing={2} >
                    <Grid  item xs={1}>
                      <div>
                        <span className="heading text-filterTextColor font-bold">Date:</span>
                      </div>
                    </Grid>
                    <Grid item className="" xs={6}>
                      <div className="heading ml-4 text-filterTextColor ">
                        {purchaseData?.Purchase_Receive_Date_String}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid className="border-2 mx-2  my-2 " item xs={12}>
          <section >
            <PurchaseReceiveDetailsTable tableData={purchaseData?.Purchase_Receive_details} />
          </section>


        </Grid>





      </Grid>





    </section>
  );
}

export default PurchaseReceiveContent;
