/* eslint-disable jsx-a11y/anchor-is-valid */

import LogicContext from "../../Context/LogicContext";
import { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { useContext } from "react";
import { SetRecoilCorporateApprovedList, GetRecoilCorporateApprovedList  } from "../../store/corporateApproveList";
import CustomerContext from "../../Context/CustomerContext";
import underscore from "underscore";
import CsvDownload from 'react-json-to-csv';
import RestartAlt from '@mui/icons-material/RestartAlt';
import Tooltip from '@mui/material/Tooltip';
import { useLocation, useNavigate } from "react-router-dom";
import { corporateService } from "../../services/corporation.service";
import CorporateApprovedTable from "./CorporateApprovalTable";
import CoApproveFilter from "./CoApproveFilter";



function CorporateApprovalPage() {
  const logicContext = useContext(LogicContext);
  const getRecoilList = GetRecoilCorporateApprovedList();
  const setRecoilList = SetRecoilCorporateApprovedList();
 

  const [tableList, setTableList] = useState([]);

  const navigate = useNavigate();
  const mountedRef = useRef(true);

  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const new_list = queryParams.get('new_list');


  console.log('NEW ', new_list);


  // useEffect(()=> {
  //   window.title = "Customer approved ";
  //   if(getRecoilList.Corporate_Info?.length){
  //     getCorpListList();
  //   }
  // },[getRecoilList.Corporate_Info?.length]);
  
  useEffect(()=> {
    
    if(getRecoilList.Corporate_Info.length){
      setTableList(getRecoilList.Corporate_Info);
      let newList = getRecoilList.Corporate_Info.filter((cust)=>  cust.Customer_Approve_Status_Id === 1);
      if(new_list){
        // dddd
        onStatusFilterSelect({"Status_Id":1,"Status_Name":"1st App. Pending","label": "1st App. Pending"});
        
      }
       
    }
//   eslint-disable-next-line react-hooks/exhaustive-deps
  },[getRecoilList]);


  
  function getCorpListList(){
    corporateService.getCorporateApprovedList().then( response => {
      console.info('RunMain_Page', response.data);
      if(response?.data.Corporate_Info){
        console.log("Set_Recoil", )
        setRecoilList(response?.data);
        setTableList(response?.data?.Corporate_Info);
     
      }
  });

  }
 
  function addNewCorporate(){
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`)
  }


  function onStatusFilterSelect(filter) {
    console.log('Filter ', filter);
    const option =  filter.Status_Id;
    console.log("type ", option);
    if(option == '0'){
      setTableList(getRecoilList.Corporate_Info);
    }else{
        let newList = getRecoilList.Corporate_Info.filter((cust)=>  cust.Corporate_Approval_Status_Id === option);
        setTableList(newList);
    }
  }


  
  function exportCustomers(){
  
  }




  return (
    <>
  
    <section>
      
      <div className="   w-full   lg:flex justify-start">
        <section className=" w-full">
          <section className="bg-white py-4 px-2    md:flex justify-between ">
            <div className="flex px-2 mt-1">
 
             
            <Tooltip title="Reload">
           <RestartAlt
             className="cursor ml-2 mt-1"
             onClick={() => getCorpListList()}
             color="primary"
             fontSize="large"
           />
         </Tooltip>

         <Tooltip title="Total ">
         <div className="card font-weight-bold text-primary p-1 pl-3 pr-3 ml-3  bg-light">
                 Total: {tableList?.length}
         </div>
         </Tooltip>
         <CoApproveFilter className="ml-2"  approve_status={getRecoilList.approval_type} onStatusFilterSelect={onStatusFilterSelect} />
            </div>
          
        

            <div className="flex justify-between">
         
              <button className="  font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8 text-xs">
                IMPORT
              </button>
              <div className="btn-group">
               <button onClick={()=> exportCustomers()} className=" font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8   text-xs" data-toggle="dropdown" aria-expanded="false">
                EXPORT
              </button>
          
            </div>

            <div className=" grid grid-cols-1 gap-1 content-center">
            {/* <button onClick={addNewCorporate}
                className="bg-buttonColor font-[Poppins] py-2 rounded-md  px-3  text-white text-xs">
                  ADD NEW
                </button> */}
            </div>
           

               
             
            </div>
          </section>

          <section className="">
            <CorporateApprovedTable  header={getRecoilList.Display_Column} tableList={tableList}  />
          </section>
        </section>
      </div>
    </section>
    </>

  );
}

export default CorporateApprovalPage;
