import select from "../../assets/selectarrow.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { transactionService } from "../../services/transaction.service";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import underscore from "underscore";
import up from "../../assets/icons/Caret_up.png";
import down from "../../assets/icons/Caret_down.png";
import { useRecoilValue } from "recoil";
import { transactionmasterDataAtom } from "../../store/transAtom";

const SELECTION = {Invoice_Date:'Invoice_Date', Invoice_No:'Invoice_No', Order_No:'Order_No', Amount:'Amount', Invoice_Status:'Invoice_Status'}


function VendorTPRV(props) { 
  const [invoiceList, setInvoiceList] =  useState([]);
  const [searchList, setSearchList] =  useState([]); 
  const masterData =  useRecoilValue(transactionmasterDataAtom);
  var masterDataGroup = underscore.groupBy(masterData, "Master_Type");
  const invoiceStatus = masterDataGroup[3];
  const [accending, setAccending] = useState(true);
  const [selectHead, setSelectHead] = useState('');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  

  useEffect(() => {
    transactionService.getTransactionInvoice(props.customer_id).then(res => {
      setInvoiceList(res?.data);
      setSearchList(res?.data);

    })
  }, [props.customer_id])


  function statusSelectHandler(value){
    if(value === 'All'){
      return setSearchList(invoiceList);
    }else{
      var tempList = invoiceList?.filter((item)=> item.Invoice_Status === value );
      setSearchList(tempList)
    }
  }
  

  function orderByName(value) {
    setSelectHead(value)
    var customFilter = underscore.sortBy(invoiceList, value);
    setInvoiceList(customFilter)
  }

  
  function orderByNameAscDesc(value) {
    setAccending(prev =>  !prev)
    setInvoiceList(invoiceList.reverse())
  }
  

  function invoiceSearch (search) {
    if (search.length <  3) {
      setSearchList(invoiceList)
    }else{
      var tempResult = invoiceList.filter((payment)=>{
        return payment?.Amount?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
        ||  payment?.Invoice_Date?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
        ||  payment?.Invoice_No?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
        ||  payment?.Order_No?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
        ||  payment?.Invoice_Status.toString()?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
      });

      setSearchList(tempResult);
    }
}

function viewInvoiceDetails(id) {
  navigate( `/${currModuleId}/${currModuleName}/invoices/details/${id}` );
 
}


const manageInvoiceStatus = (status) =>{
  let defaultClass = 'text-primary';

      switch (status) {
        case 'Draft':
        defaultClass = 'text-danger';
          break;
        case 'Sent':
        defaultClass = 'text-success';
          break;
        case 'Pending Approval':
        defaultClass = 'text-warning';
          break;
        case 'Shipped':
        defaultClass = 'text-info';
          break;
      
        default:
          break;
      }



 


      return (
        <span className={`text-sm font-bold md:text-sm ${defaultClass} `}>
        {status}
      </span>
     
      )

  
    
}


  
    return (  
        <>
          <div
          className="tab-pane border-2 p-3 fade show active"
          id="tabs-home"
          role="tabpanel"
          aria-labelledby="tabs-home-tab"
        >
         <section>
         <div className="flex  justify-between">
            

              <div className="flex  w-full justify-between">
                <div>
                <span
                  className="text-sm font-bold"
                  style={{ color: "#45444B" }}
                >
                 Purchase Receives:
                </span>

                <select className="bg-blue-300 text-black" onChange={(ev)=> statusSelectHandler(ev.target.value)}
                 style={{height:"40px", width:"120px", border:"2", outline:"none"}} >
                  <optgroup>
                    {invoiceStatus?.reverse().map((status, i )=> {
                      return (
                        <option className="text-sm " value={status.Name}> {status.Name}  </option>
                      )
                    })}
                  </optgroup>
                </select>
                </div>
                <div>
                  
                <span  className="text-sm  bg-blue-700 text-white p-2 rounded" >
                  Add New
                </span>
                </div>
            

              </div>
            </div>

            

            {/* TABLE */}
            <TableContainer className="mt-3" component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <colgroup>
            
              <col style={{width:'15%'}}/>
              <col style={{width:'20%'}}/>
              <col style={{width:'20%'}}/>
              <col style={{width:'10%'}}/>
          
          </colgroup>
                <TableHead style={{ backgroundColor: "#F1F1F1" }}>
          
                  <TableRow>
                 

                    <TableCell align="left">
                      <div className="flex">
                      
                      <span className={(selectHead === SELECTION.Invoice_No ? "text-primary":  null )}  onClick={() => orderByName('Invoice_No')}>Purchase 
Receives No</span>
                      <span> {selectHead ===  SELECTION.Invoice_No ?  <img src={accending ? up : down}
                              alt="" onClick={() => orderByNameAscDesc('Invoice_No') } />  : null}</span>
                      </div>
                      </TableCell>

                    <TableCell align="left">
                      <div className="flex">
                        
                      <span className={(selectHead === SELECTION.Order_No ? "text-primary":  null )}  onClick={() => orderByName('Order_No')}>Date</span>
                      <span> {selectHead ===  SELECTION.Order_No ?  <img src={accending ? up : down}
                              alt="" onClick={() => orderByNameAscDesc('Order_No') } />  : null}</span>

                      </div>
                      </TableCell>

                    <TableCell align="left">
                      <div className="flex">
                      <span className={(selectHead === SELECTION.Amount ? "text-primary":  null )}  onClick={() => orderByName('Amount')}>Purchase Order No</span>
                       <span>
                       {selectHead ===  SELECTION.Amount ?  <img src={accending ? up : down}
                              alt="" onClick={() => orderByNameAscDesc('Amount') } />  : null}
                       </span>
                      </div>
                   
                      
                      </TableCell>
                    <TableCell align="left">Quantity</TableCell>

                
                  </TableRow>
                </TableHead>
                
                <TableBody>
                  {searchList?.map((invoice, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      onClick={()=> viewInvoiceDetails(invoice.Invoice_Ref_No)}
                    >
                   
                      <TableCell align="left" className="text-primary">{invoice?.Invoice_No}</TableCell>
                      <TableCell align="left">{invoice?.Order_No}</TableCell>
                      <TableCell align="left">{invoice?.Amount}</TableCell>
                      <TableCell align="left">{}</TableCell>
                      <TableCell align="left"> {manageInvoiceStatus(invoice?.Invoice_Status)} </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </section>
          </div>
        </>
    );
}

export default VendorTPRV;