import { useEffect } from "react";

function ActionPaneComponent(props) {
  useEffect(() => {}, [props?.bulkActionData]);

  const createSelectItems = () => {
    let items = [];
    props?.bulkActionData?.forEach((item, i) => {
      items.push(
        <option key={i} value={item?.Filtering_Field_Value}>
          {item.Filtering_Name}
        </option>
      );
    });

    return items;
  };

  const handleChange = (value) => {
    props.onSelectBulkAction(value);
  };

  return (
    <>
      <select
        className="w-full h-[48px] text-sm border-[1px] border-gray-200 rounded-lg outline-none focus:outline-none "
        onChange={handleChange}
      >
        {createSelectItems()}
      </select>
    </>
  );
}

export default ActionPaneComponent;
