import { useEffect, useState } from 'react';
import Select from 'react-select';




function CopFilterList(props) {
  const [customOptionList, setCustomOptionList] = useState([]);

  useEffect(()=> {
    const customOption = props.copList?.map((item)=> {
      return ({...item, label:`${item.Corporate_Name }`})
    });
    if(customOption.length){
      setCustomOptionList(customOption);
    }
  },[props.copList]);




  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      zIndex: 0,
      width: 190,
    }),

    indicatorSeparator: styles => ({ ...styles, display: 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '', height: 20, paddingBottom: "45px", marginLeft: "13px" }),
    option: styles => ({ ...styles, color: "black" }),
    menuPortal: styles => ({ ...styles, zIndex: 99999 })
  }

  return (
    <>
      <div style={{ width: '300px' }}>
        <Select
          menuPlacement="auto"
          menuPosition="fixed"
          placeholder="Corporate"
          key={customOptionList.length} options={customOptionList}   onChange={props?.onCopFilterSelect} styles={customStyles} />
      </div>
    </>
  );
}

export default CopFilterList;
