import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function CorpFileviewer({fileSource}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);





const displayView = () => {

  if(fileSource){
    const splitList =  fileSource.split('.');
    const extension =  splitList[splitList.length - 1];
    if(extension === 'pdf'){
        return (
            <>
              <div class="container">
                    <iframe src={fileSource} width="100%" height="600px"></iframe>
                </div>
            
            </>
        )
    }else {
        return (
            <>
              <div>
                {fileSource ? 
                 <img src={fileSource} alt='' /> : <div className='text-danger text-center font-bold' >No file was uploaded!</div> }
               
             </div>
            </>
        )

    }
  

  }else{
    return (
      <>
        <div>
           <div className='text-danger text-center font-bold' >No file was uploaded!</div> 
       </div>
      </>
  )
  }


}

  return (
    <div>
    
      <button  onClick={handleOpen} className="text-primary btn-md mr-5 btn-sm"> View/Download File</button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
         
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
           {displayView()}
            </Typography>
            <span className='p-3 text-center' >
                    
            {fileSource  && 
              <div class="d-flex justify-content-center align-items-center">
             <a className='text-primary text-center' href={fileSource} target="_blank" 
             rel="noopener noreferrer"> <DownloadIcon color="primary" fontSize="large"  /> </a>  
             </div>
             
              }
                    </span> 
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}