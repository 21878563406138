/* eslint-disable jsx-a11y/anchor-is-valid */
import { useForm } from "react-hook-form";
import underscore from "underscore";
import { AddCustomerImage } from "./AddCustomerImage";
import "./AddCustomerInfoForm.css";

import React, { Component } from "react";
import { atom, useRecoilValue } from "recoil";
import { useEffect } from "react";
import { useState } from "react";

import { editCustomerAtom } from "../store/customerAtom";
import GeneralSelect from "./GeneralSelect";
import CustomerTypeCheck from "./CustomerTypeCheck";




function AddCustomerInfoForm(props) {
  const [isCorporate, setIsCorporate] = useState(false);

  const { masterData } = props;
  var masterDataGroup = underscore?.groupBy(masterData, "Master_Type");
  var companyList = [];
  let salutationList = masterDataGroup[12];
  companyList = masterDataGroup[15];
 


  var customerToEdit = useRecoilValue(editCustomerAtom);
  const { register, handleSubmit, setValue, reset,formState: { errors }} = useForm();



  function customerTypeSelect(sel) {
    setValue('Customer_Type_Id', sel);
    if(sel == 3){
      setIsCorporate(true);
    }else{
      setIsCorporate(false);
    }
  }



  useEffect(() => {
  
    if(customerToEdit?.Customer_Id){
    
      setValue('Customer_Type_Id', customerToEdit.Customer_Type_Id);
      setValue('customer_first_name', customerToEdit.Customer_First_Name);
      setValue('Customer_Work_Mobile_No', customerToEdit.Customer_Work_Mobile_No);
      setValue('Customer_Last_Name', customerToEdit.Customer_Last_Name);
      setValue('customer_email_address', customerToEdit.Customer_Email_Address);
      setValue('Customer_Mobile_No', customerToEdit.Customer_Mobile_No);
      setValue('salutation_id', customerToEdit.Salutation_Id);

      if(customerToEdit?.Customer_Type_Id == 3){
        setIsCorporate(true);
      }

    }

  }, [customerToEdit]);

  function onErrors(error){
    console.error(errors)
  }
  const emailValidate =
    "/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/";
  // formData.append(
  //     "file",
  //     this.state.pictureAsFile
  // );

  const submitImage = (image) => {
    props.selectedCustomerImage(image)
    // formData.append("file", image);
    
  };
  
  

  const onSubmit = (data) => {
    props.submitSection(data);
  };

  const salutationSelect = (selection) => {
    setValue('salutation_id', selection?.Code);
    // setSalutation(selection);
  };


  const onCompanySelect = (company) => {
    setValue('company_id', company?.Code);
  }

  return (
    <>
      {/* PICTURE MOBILE */}
      <section className="   max-w-6xl max-auto  w-full">
        <section className=" mt-10 md:hidden">
          {/* <div className="bg-red-100 pt-[15vh] pl-[10vh] text-4xl h-[35vh] mb-2 w-[80vw]">
          Picture
        </div>
        <img src={avater} alt="" />
        <input type="file" className="ml-5" /> */}
          <AddCustomerImage submitFile={submitImage} />
        </section>

        <form onSubmit={ handleSubmit(onSubmit, onErrors)}>
          <section className=" ">
            <section className="flex justify-between">
              {/* CUSTOMER INFORMATION  */}

          
             <CustomerTypeCheck  defaultVal={customerToEdit?.Customer_Type_Id}   customerTypeSelect={customerTypeSelect} />
          

              {/* PICTURE desktop */}
              <section className=" hidden md:block ">
                <AddCustomerImage submitFile={submitImage} />
              </section>
            </section>

            {/* CONTACT DETAILS */}
            <section className="">
              <p className="font-bold text-sm"> Primary Contact Details </p>

              <div className=" lg:flex ml-4 ">
                <label className="">
                  <span className="text-sm text-filterTextColor font-bold">
                    {" "}
                    Salutation<i>(Optional)</i>
                  </span>
                  <br></br>
                  <GeneralSelect onOptionSelect={salutationSelect} 
                   defaultVal={ customerToEdit?.Salutation_Name}  list={salutationList} />
                </label>

                  {isCorporate  &&   
                <label className="ml-4  ">
                  <span className="text-sm text-filterTextColor font-bold">
                    Company 
                  </span>
                  <br></br>
               
                <GeneralSelect onOptionSelect={onCompanySelect} 
                   defaultVal={ customerToEdit?.Corporate_Name}  list={companyList} />
                </label>
                }
              </div>

              <div className=" lg:flex  ">
                <label className="   lg:w-[35vw]">
                  <span className="text-sm text-filterTextColor font-bold">
                    Primary Contact First name
                  </span>
                  <br></br>
                  <input
                    className="bg-inputColor h-[6vh] pl-3  lg:w-[35vw]"
                    placeholder="enter firstname"
                    name="first_name"
                    {...register("customer_first_name", {
                      required: "First name is required",
                    })}
                  />
                  <small className="text-danger">
                    {errors?.customer_first_name &&
                      errors.customer_first_name.message}
                  </small>
                </label>

                <label className="ml-4 ">
                  <span className="text-sm font-bold text-filterTextColor">
                    Work Phone Number
                  </span>
                  <br></br>
                  <input
                    className="bg-inputColor h-[6vh] pl-3 lg:w-[35vw]"
                    placeholder="enter work number"
                    name="phone"
                    {...register("Customer_Work_Mobile_No", {
                      minLength: {
                        value: 6,
                        message:
                          "Phone number should be more than 6 characters.",
                      },
                      required: "Work phone number is required",
                    })}
                  />
                  <br></br>
                  <small className="text-danger">
                    {errors?.Customer_Work_Mobile_No &&
                      errors.Customer_Work_Mobile_No.message}
                  </small>
                </label>
              </div>

              <div className="lg:flex ">
                <label className=" lg:w-[35vw]">
                  <span className="text-sm font-bold text-filterTextColor">
                    Primary contact last name
                  </span>
                  <br></br>
                  <input
                    className="bg-inputColor h-[6vh] pl-3  lg:w-[35vw]"
                    placeholder="enter lastname"
                    name="last_name"
                    {...register("Customer_Last_Name", {
                      required: "Last name is required",
                    })}
                  />
                  <small className="text-danger">
                    {errors?.Customer_Last_Name &&
                      errors.Customer_Last_Name.message}
                  </small>
                </label>

                <label className="ml-4">
                  <span className="text-sm font-bold">Mobile Number</span>
                  <br></br>
                  <input
                    className="bg-inputColor h-[6vh] pl-3 lg:w-[35vw]"
                    placeholder="enter mobile number "
                    name="customer_email"
                    {...register("Customer_Mobile_No", {
                      required: "Mobile number is required",
                    })}
                  />
                  <br></br>
                  <small className="text-danger">
                    {errors?.Customer_Mobile_No &&
                      errors.Customer_Mobile_No.message}
                  </small>
                </label>
              </div>

              <div>
                <label className=" ">
                  <span className="text-sm text-filterTextColor font-bold">
                    Email Address
                  </span>
                  <br></br>
                  <input
                    className="bg-inputColor h-[6vh] pl-3 lg:w-[35vw]"
                    placeholder="enter email"
                    name="email"
                    {...register("customer_email_address", {
                      required: " Email address is required",
                    })}
                  />
                  <br></br>
                  <small className="text-danger">
                    {errors?.customer_email_address &&
                      errors.customer_email_address.message}
                  </small>
                </label>
              </div>

              {/* BUTTON DESKTOP */}
              <div className="hidden lg:flex ">
                <button
                  type="submit"
                  className="rounded-lg px-10  my-3 py-2 bg-blue-500 text-blue-100"
                >
                  Save
                </button>
              </div>
            </section>



            {/* Add  Another Contact  */}
            {/* <section className="flex justify-end mr-5 mb-5">
              <button className="text-sm ">ADD ANOTHER CONTACT PERSON</button>
            </section> */}
          </section>

          {/* BUTTON */}
          <div className="flex justify-end mr-5 md:ml-[10vw] lg:hidden">
            <button
              type="submit"
              className="rounded-lg px-10  my-3 py-2 bg-blue-500 text-blue-100"
            >
              Save
            </button>
          </div>
       
        </form>
      </section>

    </>
  );
}

export default AddCustomerInfoForm;
