/* eslint-disable jsx-a11y/anchor-is-valid */

import { createContext, useState, useContext } from "react";
import Box from '@mui/material/Box';
import LogicContext from '../Context/LogicContext';
import Modal from '@mui/material/Modal';
import  '../pages/NewModel.css';
import EditIcon from '@mui/icons-material/Edit';
import { useForm } from "react-hook-form";
import {itemsService} from '../services/items.service';
import {accountService} from '../services/accountService';
import {SetItemMasterData}  from '../store/itemAtom';
  import axios from "axios";
import { authHeader } from '../helpers/authHeader';
import { SetChartTableFilter,SetChartList,
  SetChartTableHead} from '../store/chartOfAccountAtom';


const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-40%, -40%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };



const NotificationContext = createContext({
    showModal: ({}) => { },
    closeModal: () => { },
    getMasterData: () => { },
    reloadChartList: ()=> {},

});

export function NotificationContextProvider(props) {
    const [open, setOpen] = useState(false);
    const [newRecord, setNewRecord] = useState(false);
    const setItemMasterData = SetItemMasterData();
    const logicContext = useContext(LogicContext);
    const [tableItems, setTableItems] = useState([]);
    const [itemModify, setItemModify] = useState({});
    const [serverProps, setServerProps] = useState({});

    const setChartFilter = SetChartTableFilter();
    const setChartTablehead = SetChartTableHead();
    const subchartList = SetChartList();

    const handleOpen = () => setOpen(true);


    const handleClose = () => {
        setOpen(false)
        setItemModify({})
        setServerProps({});
        reset({ });
        setTableItems([]);
        setNewRecord(false);
    };

    const { register, setValue, getValues, handleSubmit ,reset, formState: { errors }} = useForm();


    
    const openModalHandler = (serverProps) => {
        setOpen(true);
        setItemModify({ Status_Name :'Active'})
        setServerProps(serverProps);
        axios.get(`/get-category-master-data/${serverProps.sql_ref_no_get}/${serverProps.option_get}`, authHeader()).then(res => {
          if(res?.data?.length){
            setTableItems(res?.data);
          }
          }).catch(err => console.log(err))
    }


    const LoadMasterData = ()=> {
      itemsService.getItemsMasterData().then((res) => {
        setItemMasterData(res?.data)
    }).catch(err => console.log(err));

    }


    function editItem(item){
        setValue("Name", item.Name);
        setValue("Status_Name", item.Status_Name);
        setValue("code", item.Id);
        setItemModify(item);
    }


    function changeStatus(status){
        setValue("Status_Id", status);
        setItemModify((prev)=> ({...prev,Status_Id: status}))
    }


    function newRecordHandler(){
      setNewRecord(true);
      setItemModify({})
      reset({ });
    }

    function reloadChartOfAccount() {
      accountService.getChartOfAccount().then(res => {
          if(res?.data !== undefined){
              subchartList(res.data['Chart of Accounts']);
              setChartFilter(res.data['Filtering Criteria']);
              setChartTablehead(res.data['Display Column']);
          }
      });
    }


    function updateItemToServer(){
        let formData = new FormData(); 

        if(newRecord === true){
          formData.set('sql_ref_no',serverProps.sql_ref_no_send)
          formData.set('option',serverProps.newOption)
          formData.set('Name', getValues('Name'))
          formData.delete('Code')
          formData.delete('Status')
          // formData.set('Code', itemModify.Id );
          // formData.set('Status', itemModify.Status_Id);
          axios.post('/new-master-data',formData, authHeader()).then(res => {
            console.log('NEW_', res.data);
            if(res?.data?.master_info?.length){
              setTableItems(res?.data.master_info);
            }
            setNewRecord(false);
            LoadMasterData();

            logicContext.showToast('New item has been created successfully!', 'success');
          }).catch(err => {
            logicContext.showToast('Connection error !', 'error');
          })

        }else{
          formData.set('sql_ref_no',serverProps.sql_ref_no_send)
          formData.set('option',serverProps.updateOption)
          formData.set('Name', getValues('Name'))
          formData.set('Code', itemModify.Id );
          formData.set('Status', itemModify.Status_Id);
          axios.put('/update-master-data',formData, authHeader()).then(res => {
            if(res?.data?.master_info?.length){
              setTableItems(res?.data.master_info);
            }
            LoadMasterData();
            logicContext.showToast('Item update was successful!', 'success');
          }).catch(err => {
            logicContext.showToast('Connection error !', 'error');
          })

        }
      

    }



    const context = {
        showModal: openModalHandler,
        getMasterData : LoadMasterData,
        reloadChartList: reloadChartOfAccount
    };



    return (
        <NotificationContext.Provider value={context}>

<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
 
      <section className=" w-full">
      <section className="flex justify-between">
        <button onClick={handleClose} className=" btn-sm"> <span className="text-danger">X</span> </button>
        <section>


            {newRecord ? 
               <button onClick={()=> updateItemToServer()}
               style={{ backgroundColor: "#476EF8" }}
               className="bg-filterColor  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-4"
             >
               Save
             </button> 
             :

             <button onClick={()=> updateItemToServer()}
             style={{ backgroundColor: "#476EF8" }}
             className="bg-filterColor  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-4"
           >
             Update
           </button>
            }

            <button onClick={()=> newRecordHandler()}
              style={{ backgroundColor: "#476EF8" }}
              className="bg-filterColor ml-2 rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-4"
            >
              New
            </button>
        </section>
           
          </section>

          <section className="flex my-2 input justify-between">
           
             <div>
             <span className="text-xs">Name</span> <br></br>
             <input
              {...register("Name", {
               required: "Item name is required",
             })}
               className="bg-filterInput bottom-0 outline-none border-transparent   focus:border-transparent focus:ring-0"
               type="text"
               placeholder="Item name"
             />
           </div>
            
           

          
          
            <div className="section2 mt-3">
              <label className="">
                <span className="text-xs">Status</span> <br></br>
                <div className="dropdown bg-inputColor h-[6.5vh] pt-2 w-[10.2vw]  border-0">
                  <a
                    style={{ textDecoration: "none" }}
                    className="text-filterTextColor h-[6vh] pl-[2.0vh]  text-xs  dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="font-weight-bold select ">
                    {itemModify?.Status_Name}
                    </span>
                  </a>

                  <ul
                    className="dropdown-menu w-300"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li onClick={()=> changeStatus('Active')}>  <a className="dropdown-item" href="#"> Active{" "} </a> </li>
                    <li><hr class="dropdown-divider"/></li>
                    <li onClick={()=> changeStatus('Inactive')} >  <a className="dropdown-item" href="#"> Inactive{" "} </a> </li>
                  </ul>
                </div>
              </label>
            </div>
          </section>
          <section>
            <div className="flex justify-center mt-[2.7vh] ">
              {newRecord ? null :
              
              <div className="lg:w-[25vw] ">
              <div className="flex    border-2 rounded ">
                <button className="flex  bg-inputColor items-center justify-center px-4  ">
                  <svg
                    width="24"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="bg-inputColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.4996 10.875C16.4996 11.9875 16.1697 13.0751 15.5516 14.0001C14.9335 14.9251 14.055 15.6461 13.0272 16.0718C11.9994 16.4976 10.8684 16.609 9.77723 16.3919C8.68608 16.1749 7.6838 15.6391 6.89713 14.8525C6.11046 14.0658 5.57473 13.0635 5.35769 11.9724C5.14065 10.8812 5.25204 9.75024 5.67779 8.72241C6.10353 7.69457 6.8245 6.81607 7.74953 6.19798C8.67455 5.5799 9.76209 5.25 10.8746 5.25C12.3659 5.25169 13.7957 5.84487 14.8502 6.89939C15.9047 7.95392 16.4979 9.38368 16.4996 10.875ZM21.5294 21.5304C21.3888 21.6711 21.198 21.7501 20.9991 21.7501C20.8001 21.7501 20.6094 21.6711 20.4687 21.5304L16.4165 17.4783C14.7122 18.9092 12.5213 19.6269 10.3006 19.4817C8.08 19.3366 6.00111 18.3398 4.49749 16.6993C2.99387 15.0587 2.18161 12.9011 2.23008 10.6762C2.27855 8.45139 3.18402 6.33114 4.75766 4.75763C6.3313 3.18413 8.45163 2.27884 10.6765 2.23057C12.9013 2.18229 15.0589 2.99474 16.6993 4.4985C18.3398 6.00226 19.3363 8.08123 19.4813 10.3019C19.6262 12.5225 18.9083 14.7134 17.4773 16.4176L21.5294 20.4698C21.6701 20.6104 21.7491 20.8012 21.7491 21.0001C21.7491 21.199 21.6701 21.3898 21.5294 21.5304ZM10.8746 18C12.2838 18 13.6613 17.5821 14.833 16.7992C16.0047 16.0163 16.918 14.9035 17.4573 13.6016C17.9965 12.2997 18.1376 10.8671 17.8627 9.48498C17.5878 8.10287 16.9092 6.83331 15.9127 5.83686C14.9163 4.84042 13.6467 4.16182 12.2646 3.8869C10.8825 3.61199 9.44991 3.75308 8.14799 4.29236C6.84607 4.83163 5.73329 5.74486 4.95039 6.91656C4.16748 8.08826 3.74961 9.46581 3.74961 10.875C3.75175 12.764 4.5031 14.575 5.83883 15.9108C7.17457 17.2465 8.9856 17.9979 10.8746 18Z"
                      fill="#45444B"
                    />
                  </svg>
                </button>
                <input 
                  className="px-4 z-0 text-sm bg-inputColor  py-2 h-[6.2vh] w-100"
                  placeholder="Search"
                />
              </div>
            </div>
              }
            
            </div>
          </section>

          <section>
   <div className="overflow-y-auto max-h-96 mt-3">
  <table className="min-w-full text-left table-auto">
    <thead className="bg-gray-100">
      <tr>
        <th className="px-4 py-2">ID</th>
        <th className="px-4 py-2">Name</th>
        <th className="px-4 py-2">Status</th>
        <th className="px-4 py-2">Edit</th>
      </tr>
    </thead>
    <tbody className="divide-y divide-gray-200">
      {tableItems?.map((item, i) => (
        <tr key={i} className="hover:bg-gray-50">
          <td className="px-4 py-2">{item.Id}</td>
          <td className="px-4 py-2">{item.Name}</td>
          <td className="px-4 py-2">{item.Status_Name}</td>
          <td className="px-4 py-2 cursor-pointer" onClick={() => editItem(item)}>
            <EditIcon className="text-blue-500" />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

          </section>
      </section>
        </Box>
      </Modal>


            {props.children}
        </NotificationContext.Provider>
    );
}

export default NotificationContext;
