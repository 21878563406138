import React, { useEffect } from "react";
import "../../pages/Image.css";

import imageDisplay from "../../assets/imageD.png";
import CurrencyFormat from 'react-currency-format';

import { itemsService } from "../../services/items.service";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import SubscriptionChangeDate from "./SubcriptionChangeDate";
import  moment from 'moment';
import SubscriptionGiftDetails from "./SubscriptionGiftDetails";
import SubManageContact from "./SubManageContact";
import { useNavigate, useParams, useLocation } from "react-router-dom";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function SubOverview(props) {
  const { subDetails} = props;


  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];


 
  
  useEffect(() => {}, [props?.subDetails?.Subscription_Ref_No]);


  function submitImageRecord(body) {
    itemsService.submitItemImageRecord(body).then((res) => {
    });
  }


  function viewSalesOrder(subscription){
    if(subDetails?.Sales_Order_Ref_No){
      navigate(`/${currModuleId}/${currModuleName}/${'sales-order'}/details/${subDetails?.Sales_Order_Ref_No}`);
    }
   


  }

  return (
    <>
      <section className=" mt-1 right-1 border  md:  " style={{}}>
        <section className="mx-4 ">
          {/* BEANS SECTION */}
          <section className="profile_section  py-4">
            <Grid container columnSpacing={{ xs: 30 }}>
              <Grid item xs={6}>
                <Item>
                  <div>
                    <div>
                      <img src={imageDisplay} alt="" />
                    </div>

                    <div className="mt-2">
                      <p>
                        <span>Name : </span>
                        <span className="font-bold text-md">
                          {subDetails?.Salutation_Name}
                          {subDetails?.Customer_Full_Name}
                        </span>
                      </p>
                      <p>
                        <span>Email : </span>
                        <span className=" font-bold text-sm py-2">
                          {subDetails?.Customer_Email_Address}{" "}
                        </span>
                      </p>

                      <p>
                        <span>Mobile Number :</span>
                        <span className="font-bold text-sm">
                          {" "}
                          {subDetails?.Customer_Mobile_No}{" "}
                        </span>
                      </p>
                      {subDetails?.Subscription_Status_Id === 2 ? (
                        <p>
                          <span className="">Subscription Status : </span>
                          <h4 className="font-weight-bold badge badge-light p-2">
                            {subDetails?.Subscription_Status_Name}
                          </h4>
                        </p>
                      ) : (
                        <p >
                          <span className="">Subscription Status : </span>
                          <h6 style={{backgroundColor:"rgba(112, 204, 64, 0.1)"}} className="font-bold badge badge-success p-2">
                            <div  style={{color:"#70CC40"}}>
                            {subDetails?.Subscription_Status_Name}{" "}
                            </div>
                          </h6>
                        </p>
                      )}
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={5}>
                <Item>
                  <section className="flex justify-end">
                    <div>
                      <span style={{ color: "#476EF8" }} className="font-bold">
                        Subscription Details
                      </span>
                      <div className="mt-3">
                        <span>Subscription ID :</span>
                        <span className="font-bold mx-2">
                          {subDetails?.Subscription_Ref_No}{" "}
                        </span>
                      </div> 
                      <div>
                        <span>Subscription No :</span>
                        <span className="font-bold mx-2">
                          {subDetails?.Subscription_No}{" "}
                        </span>
                      </div>

                      <div>
                        <span>References No :</span>
                        <span className="font-bold mx-2">
                          {subDetails?.Subscription_Ref_No}{" "}
                        </span>
                      </div>
                      <div>
                        <span>Sales Person :</span>
                        <span className="font-bold mx-2">
                          {subDetails?.Encoded_By_Name}{" "}
                        </span>
                        <span style={{ color: "#476EF8" }}>Update</span>
                      </div>
                      <div>
                        <span>Source :</span>
                        <span className="font-bold mx-2"> </span>
                      </div>
                      <div>
                        <span>Is this a Gift ?</span>
                        <span className="font-bold mx-2">
                          {subDetails?.Gift_Name}{" "}
                        </span>
                      </div>
                      <div>
                        <span>Created by ?</span>
                        <span className="font-bold mx-2">
                          {subDetails?.Encoded_By_Name}{" "}
                        </span>
                      </div>
                     <SubscriptionGiftDetails giftPerson={subDetails} />
                    </div>
                  </section>
                </Item>
              </Grid>
            </Grid>

          </section>
        </section>
      </section>

      <section className="  mt-2 right-1 border  py-4  md:  " style={{}}>
        <section className="mx-4">
          <Grid container columnSpacing={{ xs: 4 }} spacing={3}>
            <Grid item xs={4}>
              <Item>
                <div>
                  <span style={{ color: "#476EF8" }}>Billing Address</span>
                  <div className="flex">
                    <span className=" text-sm">Country:</span>
                    <div className="text-sm font-bold">{subDetails?.Billing_Country_Name}  </div>
                  </div>
                  <div className="flex">
                    <span className=" text-sm">State:</span>
                    <div className="text-sm font-bold">{subDetails?.Billing_City_Name}  </div>
                  </div>
                  <div className="flex">
                    <span className=" text-sm">Area:</span>
                    <div className="text-sm font-bold">{subDetails?.Billing_Area_Name}  </div>
                  </div>
                  <div className="flex">
                    <span className=" text-sm"> Address:</span>
                    <div className="text-sm font-bold">{subDetails?.Billing_Address1}   </div>
                  </div>
                  <div className="flex">
                    <span className=" text-sm">Branch :</span>
                    <div className="text-sm font-bold">{subDetails?.Branch_Name}  </div>
                  </div>
                  <div className="flex">
                    <span className=" text-sm">Unit :</span>
                    <div className="text-sm font-bold">{subDetails?.Unit_Name}  </div>
                  </div>



                </div>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item>
                <div>
                  <span style={{ color: "#476EF8" }}>Shipping Address</span>
                  <div>
      
                  <div className="flex">
                    <span className=" text-sm">Country:</span>
                    <div className="text-sm font-bold">{subDetails?.Shipping_Country_Name}  </div>
                  </div>
                  <div className="flex">
                    <span className=" text-sm">State:</span>
                    <div className="text-sm font-bold">{subDetails?.Shipping_City_Name} </div>
                  </div>
                  <div className="flex">
                    <span className=" text-sm"> Area:</span>
                    <div className="text-sm font-bold">{subDetails?.Shipping_Area_Name}  </div>
                  </div>
                  <div className="flex">
                    <span className=" text-sm"> Address:</span>
                    <div className="text-sm font-bold">{subDetails?.Shipping_Address1}  </div>
                  </div> 
                  <div className="flex">
                    <span className=" text-sm">GPS local:</span>
                    <div className="text-sm font-bold">{subDetails?.Shipping_Address1}   </div>
                  </div>



                </div>
                </div>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item>
                <div>
                  <span style={{ color: "#476EF8" }}>Associated Contacts</span>
                  <div className="">
                    <span className="text-sm">noreply@grocedy.com</span>
                  </div>
                  <div>
                  <SubManageContact contactsList={subDetails?.contacts} />
                  </div>
                </div>
              </Item>
            </Grid>
          </Grid>
        </section>
      </section>

      <section className="  mt-2 right-1 border  py-4  md:  " style={{}}>
        <section className="mx-4">
          <Grid container columnSpacing={{ xs: 37 }} spacing={2}>
            <Grid item xs={6}>
              <Item>
                <div>
                  <span style={{ color: "#476EF8" }} className="font-bold">
                    Payment Method
                  </span>
                  {/* <div className="mt-3">
                    {(gwayList?.length < 0)  &&
                    <span  className="text-sm">
                    No Payment Option Associated
                  </span>
                    }
                    
                  {gwayList?.map((payment, i)=> {
                    return (
                      <p className="font-weight-bold" key={i}>{payment?.Payment_Gateway_Name}  </p>
                    )
                  })}

                  </div> */}
                  <div className="">
                    <div>
                      <span>Payment Term:</span>
                      <span className="font-bold mx-2">Net 27</span>
                    </div>
                    <div>
                      <span>Enable Partial Payment:</span>
                      <span className="font-bold mx-2">Yes</span>
                    </div>
                    <div>
                      <span>Payment Gateway:</span>
                      <div className="">
                        {subDetails?.gateway?.length < 0 && (
                          <span className="text-sm">
                            No Payment Option Associated
                          </span>
                        )}

                        {subDetails?.gateway?.map((payment, i) => {
                          return (
                            <i key={i}>
                              {" "}
                              <p
                                className="font-weight-bold ml-2 text-xs"
                                key={i}
                              >
                                {payment?.Payment_Gateway_Name}{" "}
                              </p>
                            </i>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <div>
                <span style={{ color: "#476EF8" }}>Other Details</span>
              
                      <div>
                      <span>Repeat Every:</span>
                      <span className="font-bold mx-2">
                        
                        1 Month(s)
                      </span>
                    </div>
                   

             
                  {(subDetails?.Recuring_Subscription_Id === 1) &&
                      <div>
                      <span>Subscription Cycle:</span>
                      <span className="font-bold mx-2">
                      Recurring
                      </span>
                    </div>
                  }
             
                  {(subDetails?.Recuring_Subscription_Id === 2) &&
                        <div>
                        <span>Subscription Cycle:</span>
                        <span className="font-bold  text-sm">
                        {subDetails?.Subscription_Expire_Cycle_No}
                        </span>
                      </div>
                  }
                
                

               

                  <div>
                    <span>Subscription Start Date:</span>
                    <span className="font-bold  text-sm">
                      {moment(subDetails?.Subscription_Activated_Date_String).format(" DD-MM-YYYY").replace(/-/g, '/')}
                    </span>
                  </div>

                
                  {(subDetails?.Recuring_Subscription_Id === 2) &&
                    <div>
                    <span>Subscription End Date:</span>
                    <span className="font-bold  text-sm">
                    {moment(subDetails?.Subscription_End_Date_String).format("DD-MM-YYYY").replace(/-/g, '/')}
                    </span>
                  </div>
                    }


                  {(subDetails?.Recuring_Subscription_Id === 1) &&
                  <div>
                    <span>Subscription End Date:</span>
                    <span className="font-weight-bold text-sm"> ∞</span>
                    
                  </div>
                    }

               
                </div>
              </Item>
            </Grid>
          </Grid>
        </section>
      </section>
      <section className="  mt-2 right-1 border  py-4  md:  " style={{}}>
        <section className="mx-4">
          <Grid container columnSpacing={{ xs: 4 }} spacing={3}>
            <Grid item xs={4}>
              <Item>
                <div>
                  <span>Subscription Amount</span>
                  <div>
                    <span className="text-xl my-3 font-bold ">
                      <CurrencyFormat value={parseFloat(subDetails?.Subscription_Amount )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={' ₦'} />
                    </span>
                  </div>
                  <div>
                    <span className="text-xs">1 month(s)</span>
                  </div>
                </div>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item>
                <div>
                  <span>Next Billing Date</span>
                  <div>
                    <span className="text-xl my-3 font-bold ">
                      {subDetails?.Next_Billed_Date_String}{" "}
                    </span>
                  </div>
                  <div>
                    <SubscriptionChangeDate  updateBillingDate={props.updateBillingDate} />
                  </div>
                </div>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item>
                <div>
                  <span>Last Billing Date</span>
                  <div>
                    <span className="text-xl my-3 font-bold ">
                      {subDetails?.Last_Billed_Date_String}{" "}
                    </span>
                  </div>
                </div>
              </Item>
            </Grid>
          </Grid>
          <hr />
          <div>
            <span className="text-sm mt-2" style={{ color: "#558223" }}>
              Renews forever
            </span>
          </div>
        </section>
      </section>
      <section className="  mt-2 right-1 border py-4  md:  " style={{}}>
        <section className="mx-4">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <table className=" w-full ">
                <tr
                  style={{
                    backgroundColor: "#F1F1F1",
                    height: "50px",
                    paddinLeft: "10px",
                  }}
                >
                  <th className="text-sm pl-2">Plan & Addon Details</th>
                  <th className="text-sm pl-2">Qty</th>
                  <th className="text-sm pl-2">Rate</th>
                  <th className="text-sm pl-2">Tax</th>
                  <th className="text-sm pl-2">Amount</th>
                </tr>
                {props?.planAddonCouponList?.map((list, i) => {
                  return (
                    <tr key={i} className="p-1 cursor  " onClick={()=> viewSalesOrder(list)}>
                      <td className="text-sm">
                        <span className="text-sm font-bold pl-2">
                          {list?.Plan_Name}{" "}
                        </span>
                        <div>
                          <span className="text-sm pl-2">
                            {list?.Product_Name}{" "}
                          </span>
                        </div>
                    
                      </td>
                      <td className="text-sm pl-2">
                        {list?.Product_Quantity}{" "}
                      </td>
                      <td className="text-sm pl-2">
                        <CurrencyFormat value={ parseFloat(list?.Product_Rate)?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={' ₦'} />
                      </td>
                      <td className="text-sm pl-2">{list?.Tax_Id} </td>
                      <td className="text-sm pl-2">
                        <CurrencyFormat value={  parseFloat(list?.Total_Amount)?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={' ₦'} />
                      </td>
                    </tr>
                  );
                })}
              </table>
            </Grid>
          </Grid>
        </section>
      </section>
    </>
  );
}

export default SubOverview;
