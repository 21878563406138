
import {
    DataGrid, gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport,
    useGridApiContext,
    useGridSelector
} from "@mui/x-data-grid";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useEffect } from "react";
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";


function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  


function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            // @ts-expect-error
            rowsPerPageOptions={[6]}
            checkboxSelection={true}
            components={{
                Toolbar: CustomToolbar,
                Pagination: CustomPagination,
              }}
            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}



const TableHead = [
    { field: 'sn', headerName: 'S/N', width: 80, headerClassName: " " },
    { field: 'Action_URL_Name', headerName: 'Action_URL_Name', width: 185, headerClassName: " ", cellClassName:' text-center text-primary font-weight-bold show-cursor' },
    { field: 'Action_URL_Web', headerName: ' Web Url', width: 220, headerClassName: " " },
    { field: 'Action_URL_Mobile', headerName: 'Mobile  Url', width: 220, headerClassName: " " },
    { field: 'Feature_Action_Status_Name', headerName: 'Status', width: 165, headerClassName: " " },
   
]




function ProductActionUrlTable(props) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];


    useEffect(() => {
        document.title = "Features Menu";
    }, [props?.list]);


    function OnItemCellClick(event) {
        const id = event.row['Action_URL_Id'];
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`);
    }

    return (

        <>
            <div className="mt-5">

                <div style={{ height: 450, width: '100%' }}>
                    <DataGrid
                        columns={TableHead}
                        rows={props?.list.map((item, i) => {
                            return {
                                ...item, id: i, sn: i + 1,
                            }
                        })}
                        components={{
                            Pagination: CustomPagination,
                        }}
                        onRowClick={(event) => {
                            OnItemCellClick(event);
                        }}

                    />
                </div>
            </div>
        </>
    );
}

export default ProductActionUrlTable;