
import { DataGrid ,  gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport ,
    useGridApiContext,
    useGridSelector} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';
import './CompositeItemGroupTable.css';
import { GetCompositeGroup} from "../../../store/itemAtom";


  const TableHead = [

     { field: 'Composite_Group_Name', headerName:'Name' , width: 400, cellClassName:'text-center' },
     { field: 'Total_Items', headerName:'Total Item' , width: 400, cellClassName:'pl-4 ' },
     { field: 'Composite_Group_Status_Name', headerName:'Status' , width: 250 ,cellClassName:'text-center'},
     
  
    ]

      function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarExport />
          </GridToolbarContainer>
        );
      }

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }


function CompositeItemGroupTable(props) {
   let groupList =  GetCompositeGroup();


    const tableData = groupList?.map((item, i) => {
        return ({...item, id: item.Composite_Group_Id})
    });

 
    function onSelectChange(selection){
        props.selectedItems(selection)
    }



function onRowClick(item){
  props.rowClick(item)
  

}

    return ( 
        <>
<div className="table" style={{ height: 500, width: '100%' }}>
      <DataGrid  className="table_css"
        columns={TableHead}
        rows={tableData}
        pageSize={6}
        rowsPerPageOptions={[6]}
        checkboxSelection={true}
        onRowSelectionModelChange={(ev)=> onSelectChange(ev)}
        components={{
          Toolbar: CustomToolbar,
          Pagination: CustomPagination,
        }}
          onCellClick={( event) => {
            event.defaultMuiPrevented = true;
            
          }}
          onRowClick={(event)=> onRowClick(event)}
          
       
          sx={{
            // boxShadow: 2,
            // border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
              cursor: 'pointer',
            },
          }}
      />
  
    </div>
        
        </>
     );
}

export default CompositeItemGroupTable;
