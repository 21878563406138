import PrimaryImageUpload from "./primaryImageUpload";



const AddItemImageUpload = (props) => {


  return (

    <>
   
    <div className="flex flex-col items-center justify-center">
      {/* Top image */}
      <div className="mb-4 ">
      <PrimaryImageUpload Item_Id={props?.Item_Id} />
      </div>

      {/* Bottom four images */}
      <div className="grid grid-cols-4 gap-4">
      <PrimaryImageUpload Item_Id={props?.Item_Id} />
      <PrimaryImageUpload Item_Id={props?.Item_Id} />
      <PrimaryImageUpload Item_Id={props?.Item_Id} />
      <PrimaryImageUpload Item_Id={props?.Item_Id} />
      </div>
    </div>
    </>
   

  );
};
export default AddItemImageUpload;