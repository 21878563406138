import React, { useRef, useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import moment from "moment/moment";
import message from "../../assets/message.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { invoiceService } from "../../services/invoice.service";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import InvoiceScroll from "../InvoiceScroll";
import InvoicePaymentTable from "./InvoicePaymentTable";
import InvoiceDetailsTable from "./InvoiceDetailsTable";
import {
  SetInvoiceList,
  GetInvoiceList,
  SetInvoiceAccSummary,
  SetInvoiceFilter,
  SetInvoiceTableHeader,
  SetInvoiceBulkAction,
  GetInvoiceBulkAction,
  SetInvoiceEdit,
} from "../../store/invoiceAtom";
import LogicContext from "../../Context/LogicContext";
import { ImAttachment } from "react-icons/im";
import { FaFilePdf } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";
// import { useReactToPrint } from 'react-to-print';

const InvoiceDetailsPage = () => {
  const getInvoiceList = GetInvoiceList();
  const setEdit = SetInvoiceEdit();
  const [selectedList, setSelectedList] = useState([]);
  const myPromiseModal = createModal(GeneralModal);
  const getBulkActions = GetInvoiceBulkAction();
  const setBulkFilter = SetInvoiceFilter();
  const setInvoiceTableHeader = SetInvoiceTableHeader();
  const setInvoiceSummary = SetInvoiceAccSummary();
  const setBulkActions = SetInvoiceBulkAction();
  const setInvoiceList = SetInvoiceList();
  const { invoice_id } = useParams();
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const [invoiceData, setInvoiceData] = useState({});
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  useEffect(() => {
    invoiceService.getInvoiceDetails(invoice_id).then((response) => {
      console.log("inv ", response.data);
      if (response.data) {
        setInvoiceData(response.data);
      }
    });
  }, [invoice_id]);

  const getInvoiceDetails = async (id) => {
    const response = await invoiceService.getInvoiceDetails(id);
    if (response?.data) {
      setInvoiceData(response.data);
    }
  };

  function viewItemDetails(id) {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
    getInvoiceDetails(id);
  }

  function viewPaymentRecord(id) {
    navigate(
      `/${currModuleId}/${currModuleName}/payment-received/details/${id}`
    );
    getInvoiceDetails(id);
  }

  const editCurrentInvoice = async () => {
    const response = await myPromiseModal({
      title: "Modify Invoice",
      body: " Do you want to modify this record?",
      showInput: false,
      placeHolder: "",
    });
    if (response.decision === true) {
      setEdit(invoiceData);
      navigate(
        `/${currModuleId}/${currModuleName}/${currUserMenu}/add-invoice`
      );
    }
  };

  function onSelection(list) {
    let tempList = list?.filter((item) => item.checked === true);
    setSelectedList(tempList);
  }

  const reFilterMainData = (refNum) => {
    let newList = getInvoiceList?.filter(
      (invoice) => invoice.Invoice_Ref_No !== refNum
    );
    setInvoiceList([...newList]);
  };

  const deleteInvoice = async () => {
    await myPromiseModal({
      title: "confirm your Delete!",
      body: `Do you want to delete ${invoiceData?.Invoice_No} ?`,
      showInput: false,
      placeHolder: "",
    }).then((response) => {
      if (response.decision === true) {
        let option = 4;
        let temp = `<a> <refno> ${invoiceData.Invoice_Ref_No} </refno> </a>`;
        let xml_string_refno = `<root> ${temp} </root>`;
        invoiceService
          .invoiceBulkAction({ xml_string_refno, option })
          .then((res) => {
            if (res?.data !== undefined) {
              const { Message_Text } = res.data[0];
              logicContext.showToast(Message_Text, "success");
              navigate(-1);

              return reFilterMainData(invoiceData.Invoice_Ref_No);
            }
          })
          .catch((err) => {
            logicContext.showToast("Error", "error");
          });
      }
    });
  };

  async function bulkActions(action) {
    var currDisplayItem = false;
    await myPromiseModal({
      title: "confirm your action!",
      body: `Do you want to ${action.Action_Name} ?`,
      showInput: false,
      placeHolder: "",
    }).then((result) => {
      if (result.decision === true) {
        let temp = "";
        const option = action.Procedure_Option;
        selectedList.map((item) => {
          if (item.Invoice_Ref_No == invoice_id) {
            currDisplayItem = true;
          } else {
            currDisplayItem = false;
          }
          temp += `<a> <refno> ${item.Invoice_Ref_No} </refno> </a>`;
        });

        let xml_string_refno = `<root> ${temp} </root>`;
        invoiceService
          .invoiceBulkAction({ xml_string_refno, option })
          .then((res) => {
            if (res?.data !== undefined) {
              const { Message_Text } = res.data[0];
              logicContext.showToast(Message_Text, "success");
              if (currDisplayItem === true) {
                getInvoiceDetails(invoice_id);
              }

              return getInvoiceData();
            }
          })
          .catch((err) => {
            logicContext.showToast("Error", "error");
          });
      }
    });
  }

  const getInvoiceData = async () => {
    try {
      const response = await invoiceService.getInvoiceData();

      if (response?.data !== undefined) {
        setInvoiceList(response.data["Invoice Info"]);
        setInvoiceSummary(response.data["Amount Summary"][0]);
        setBulkFilter(response.data["Filtering Criteria"]);
        setInvoiceTableHeader(response.data["Display Column"]);
        setBulkActions(response.data["Bulk Action"]);
      }
    } catch (error) {
      logicContext.showToast("connection error getting invoice data", "error");
    }
  };

  const calculateSubTotal = (list) => {
    let result = 0;
    list?.map((item) => {
      result += item.Plan_Sales_Rate * item?.Plan_Sales_Quantity;
    });

    return result;
  };

  const calculateGrandTotal = (list) => {
    let grandTotal = 0;
    list?.map((item) => {
      grandTotal += item.Plan_Sales_Rate * item?.Plan_Sales_Quantity;
    });

    grandTotal = grandTotal - invoiceData?.Discount_Amount;
    grandTotal = grandTotal + invoiceData?.Shipping_Amount;
    grandTotal = grandTotal + invoiceData?.Adjustment_Amount;
    return grandTotal;
  };

  const calculatePayment = (list) => {
    let grandTotal = 0;
    list?.map((item) => {
      grandTotal += item.Payment_Amount;
    });

    return grandTotal;
  };

  const manageInvoiceStatus = (status) => {
    let defaultClass = "bg-primary text-white";

    switch (status) {
      case "Draft":
        defaultClass = "text-light bg-danger";
        break;
      case "Sent":
        defaultClass = "text-light bg-success";
        break;
      case "Pending Approval":
        defaultClass = "text-light bg-warning";
        break;
      case "Shipped":
        defaultClass = "text-light bg-info";
        break;

      default:
        break;
    }

    return (
      <span
        className={`py-2.5 px-4 text-sm font-medium ${defaultClass} rounded`}
      >
        {status}
      </span>
    );
  };

  async function recordPayment() {
    await myPromiseModal({
      title: "Record Payment!",
      body: `Record Payment for ${invoiceData?.Invoice_No} ?`,
      showInput: false,
      placeHolder: "",
    }).then((response) => {
      if (response.decision === true) {
        navigate(
          `/${currModuleId}/${currModuleName}/${"payment-received"}/add-payment-received?Customer_Id=${
            invoiceData?.Customer_Id
          }&Customer_Full_Name=${
            invoiceData?.Customer_Full_Name
          }&Invoice_Ref_No=${invoiceData?.Invoice_Ref_No}`
        );
      }
    });
  }
  function collectCharge() {}

  return (
    <>
      <section className="">
        <section className="flex flex-wrap justify-between">
          <div className="mt-3">
            {selectedList.length > 0 && (
              <Dropdown>
                <Dropdown.Toggle
                  className="border-[1px] border-gray-200"
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "#45444B",
                    fontSize: "12px",
                    height: "40px",
                  }}
                  id="dropdown-basic"
                >
                  Bulk Actions
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-32 px-2 text-sm shadow-lg">
                  {getBulkActions?.map((action, i) => {
                    return (
                      <Dropdown.Item
                        className="px-0 text-gray-600"
                        key={i}
                        onClick={() => bulkActions(action)}
                      >
                        {action.Action_Name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>

          <div className="flex flex-row flex-wrap items-center">
            <div className="mt-3 flex flex-row items-center mr-6">
              <button
                type="button"
                onClick={() => recordPayment(2)}
                className="w-fit px-6 py-2.5 text-sm font-semibold bg-[#486EF8] tracking-widest outline-none rounded-lg text-white hover:bg-[#486EF8]/80 active:bg-[#486EF8] transition-all duration-300 active:outline-none"
              >
                Record payment
              </button>
              <button
                onClick={() => collectCharge(2)}
                className="ml-2 w-fit px-6 py-2.5 text-sm font-semibold bg-[#486EF8] tracking-widest outline-none rounded-lg text-white hover:bg-[#486EF8]/80 active:bg-[#486EF8] transition-all duration-300 active:outline-none"
              >
                collect charge
              </button>
            </div>
            <div className="mt-3 flex fle-row items-center space-x-1 ">
              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <ImAttachment className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <FaFilePdf onClick={()=> deleteInvoice()} className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <IoPrintSharp className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <MdEdit onClick={()=>  editCurrentInvoice()} className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <MdDelete className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
            </div>
          </div>
        </section>
        <section className="mt-4 lg:flex space-x-1">
          <div className="lg:w-[210px]">
            <InvoiceScroll
              onSelection={onSelection}
              viewItemDetails={viewItemDetails}
            ></InvoiceScroll>
          </div>
          <section className="w-full overflow-x-hidden mt-4 lg:mt-0 lg:pl-4">
            <section className="w-full">
              <div className="mt-2 border-[1px] border-gray-200 rounded-lg bg-white p-3 lg:p-4">
                <div className="font-bold">
                  {" "}
                  Payments Received (
                  {invoiceData?.payment_details?.length
                    ? invoiceData?.payment_details?.length
                    : 0}
                  ){" "}
                </div>
                <InvoicePaymentTable
                  tableData={invoiceData?.payment_details}
                  viewPaymentRecord={viewPaymentRecord}
                ></InvoicePaymentTable>
              </div>

              {/* Package page  */}

              <div className="mt-4 border-[1px] border-gray-200 rounded-lg bg-white p-4">
                <div>
                  <div>
                    <div>
                      <span>Invoice ID : {invoiceData?.Invoice_Ref_No} </span>
                    </div>
                    <div>
                      <span>
                        <i className="text-sm font-medium text-primary">
                          Invoice Number:{invoiceData?.Invoice_No}{" "}
                        </i>
                      </span>
                    </div>
                  </div>

                  <div className="my-2 ">
                    <span>Balance Due</span>
                    <span className=" font-bold ml-1">
                      <CurrencyFormat
                        value={parseFloat(invoiceData?.Invoice_Amount)?.toFixed(
                          2
                        )}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </span>
                  </div>
                </div>
                <div className="flex justify-end">
                  {manageInvoiceStatus(invoiceData?.Invoice_Status_Name)}
                </div>

                <div>
                  <div>
                    <div>
                      <span className="#476ef8 font-bold">
                        Worldbay Technologies Ltd
                      </span>
                      <br></br>
                      <span className="text-sm font-bold">TIN :</span> <br></br>
                      <span className="text-sm font-bold">VAT: </span>
                    </div>
                    <div className="">
                      <span className="text-sm font-bold">RC : </span>
                      <span className="text-sm"></span> <br></br>
                      <span className="text-sm font-bold">VAT:</span> <br></br>
                      <span className="text-sm italic text-gray-400 font-medium">
                        Plot 4b Chief Augustine Anozie Street Lekki Phase 1
                        Lagos Nigeria
                      </span>
                    </div>
                  </div>
                </div>
                <Grid item xs={12} className="mt-4">
                  <Grid container spacing={10}>
                    <Grid item xs={6}>
                      <div>
                        <div>
                          <span className="#476ef8 font-bold">Billing To:</span>{" "}
                          <br></br>
                          <span className="text-sm">
                            <span className=" text-sm">
                              {" "}
                              {invoiceData?.Customer_Full_Name}
                            </span>{" "}
                            <br />
                            <span className=" text-sm text-capitalize">
                              {" "}
                              {invoiceData?.Billing_Address1}{" "}
                            </span>
                            <span className=" text-sm text-capitalize">
                              {" "}
                              {invoiceData?.Billing_Area_Name}{" "}
                            </span>
                            <br />
                            {invoiceData?.Billing_City_Name}{" "}
                            {invoiceData?.Billing_Country_Name} <br />
                            {invoiceData?.Customer_Mobile_No}
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div>
                        <div>
                          <span className="#476ef8 font-bold">
                            Invoice Date
                          </span>
                          <br></br>
                          <span className="#476ef8 font-bold">Terms</span>
                          <span>
                            {" "}
                            <br></br>
                            <span className="#476ef8 font-bold">Due Date</span>
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div>
                        <div>
                          <span className="text-sm">
                            {" "}
                            {moment(invoiceData?.Invoice_Date).format(
                              "DD-MM-YYYY"
                            )}{" "}
                          </span>
                          <br></br>
                          <span className="text-sm">
                            {invoiceData?.Payment_Term_Name}{" "}
                          </span>
                          <span>
                            {" "}
                            <br></br>
                            <span className="text-sm">
                              {" "}
                              {moment(invoiceData?.Due_Date).format(
                                "DD-MM-YYYY"
                              )}
                            </span>
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <span>
                    {" "}
                    <br></br>
                    <span className="text-sm">Pickup Date: </span>
                  </span>
                </Grid>

                <Grid item xs={12}>
                  <InvoiceDetailsTable
                    tableData={invoiceData["invoice details"]}
                  ></InvoiceDetailsTable>

                  <div className="my-2  ml-[20vw] ">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}></Grid>

                        <Grid item xs={4}>
                          <div>
                            <div>
                              <span className="#476ef8 font-bold">
                                Sub Total:
                              </span>{" "}
                              <br></br>
                              <span className="text-sm">Discount</span>
                              <span className="text-sm">
                                {" "}
                                <br></br>
                                Shipping Charge
                              </span>{" "}
                              <br></br>
                              <span className="text-sm">Adjustment</span>{" "}
                              <br></br>
                              <span className="h5 font-bold">Total</span>{" "}
                              <br></br>
                              <span className="text-sm font-bold">
                                Payment Made
                              </span>{" "}
                              <br></br>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div>
                            <div>
                              <span className="#476ef8 ">
                                <CurrencyFormat
                                  value={parseFloat(
                                    calculateSubTotal(
                                      invoiceData["invoice details"]
                                    )
                                  )?.toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                />
                                {}{" "}
                              </span>
                              <br></br>
                              <span className="text-sm">
                                <CurrencyFormat
                                  value={parseFloat(
                                    invoiceData?.Discount_Amount
                                  )?.toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                />
                                {}{" "}
                              </span>
                              <span>
                                {" "}
                                <br></br>
                                <span className="text-sm">
                                  <CurrencyFormat
                                    value={parseFloat(
                                      invoiceData?.Shipping_Amount
                                    )?.toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"₦"}
                                  />
                                </span>
                              </span>
                              <br></br>
                              <span>
                                <span className="text-sm">
                                  <CurrencyFormat
                                    value={parseFloat(
                                      invoiceData?.Adjustment_Amount
                                    )?.toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"₦"}
                                  />
                                </span>
                              </span>
                              <br></br>
                              <span className="h5 font-bold">
                                <CurrencyFormat
                                  value={parseFloat(
                                    calculateGrandTotal(
                                      invoiceData["invoice details"]
                                    )
                                  )?.toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                />
                              </span>
                              <br></br>
                              <span
                                style={{ color: "#F17E7E" }}
                                className="text-sm"
                              >
                                <CurrencyFormat
                                  className=" font-bold"
                                  value={parseFloat(
                                    calculatePayment(
                                      invoiceData["payment_details"]
                                    )
                                  )?.toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                />
                              </span>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>

                  <div className="mt-4 ">
                    <span className="font-bold">Notes</span>
                    <p className="text-sm text-gray-600">
                      Please send an email to accounts@worldbaytech.com or call
                      08099346131 if you have any questions concerning this
                      invoice. Looking forward to your business.
                    </p>
                  </div>
                </Grid>
              </div>
            </section>
          </section>
        </section>
      </section>
    </>
  );
};

export default InvoiceDetailsPage;
