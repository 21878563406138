/* eslint-disable jsx-a11y/anchor-is-valid */

// import './AddItemPage.css';
import { useEffect, useState } from 'react';
import { itemsService } from '../../services/items.service';
import AddItemForm from './AddItemFormProps/AddItemForm';
import { GetItemToEdit } from '../../store/itemAtom';
import AddItemImage from './Add-items-props/AddItemImage';
import AddAssciates from '../AddAssciates';
import { Tabs } from 'antd';
import AddItemImageUpload from './Add-items-props/AddItemImageUpload';
import Tooltip from "@mui/material/Tooltip";
import goback from "../../assets/goback.svg";
import { useNavigate, useParams } from "react-router-dom";


function AddItemPage() {
  const itemToEditData = GetItemToEdit();
  const [tabActiveKey, setTabActiveKey] =  useState('1');
  const navigate = useNavigate();



  useEffect(()=> {

  },[itemToEditData]);




  const changeTab = (key) => {
    setTabActiveKey(key);
  };

  function updateItemId(id) {
    // setItemToEdit(prev => { return ({ ...prev, Item_Id: id }) })
  }


  return (
    <>
      <Tooltip title="Go back to previous page">
                  <button
                    type="cancel"
                    className="btn btn-sm  btn-light "
                    onClick={() => navigate(-1)}
                  >
                    <img src={goback} alt="" />
                  </button>
                </Tooltip>

      <section className="flex my-3  w-full ">
        <div className="  md:mt-2 flex ">
       
          <Tabs
          activeKey={tabActiveKey}
          onChange={(key) => setTabActiveKey(key)}
    defaultActiveKey="1"
    items={[
      {
        label: 'Item Information',
        key: '1',
        children: <AddItemForm   changeTab={(val) => changeTab(val)} 
        updateItemId={(id) => updateItemId(id)} />,

      },
      {
        label: 'Associates Items',
        key: '2',
        children: < AddAssciates item_id={itemToEditData?.Item_Id} ></AddAssciates> ,
        disabled: !itemToEditData?.Item_Id,

      },
      {
        label: 'Item Pictures',
        key: '3',
        children: <AddItemImageUpload /> ,
        // children: < AddItemImage item_id={itemToEditData?.Item_Id} getItemImages={itemsService.getItemImages} submitItemImage={submitItemImage} />,
        disabled: !itemToEditData?.Item_Id,

      },
    ]}
  />

       
        
        </div>
      </section>




    </>
  );
}

export default AddItemPage;