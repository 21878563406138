
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const salesService = {
    getSalesOrderList,
    salesOrderDetails,
    setSalesOrderBulkAction,
     getEntrySalesOrderData,
     submitSalesOrder,
     getSalesOrderInvoiceList
};

async function  getSalesOrderList() {
    return  axios.get(`/sales/sales-order-list`, authHeader());
}

async function  salesOrderDetails(salesOrderId) {
    return  axios.get(`/sales/sales-order-details/${salesOrderId}`, authHeader());
}

async function  setSalesOrderBulkAction(body) {
    return  axios.patch(`/sales/set-sales-order-action`, body, authHeader());
}

async function  getEntrySalesOrderData() {
    return  axios.get(`/sales/get-sales-entry-data`, authHeader());
}

async function  submitSalesOrder(body) {
    return  axios.post(`/sales/submit-sales-order`,body, authHeader());
}

async function  getSalesOrderInvoiceList() {
    return  axios.get(`/sales/sales-order-entry-invoice-list`, authHeader());
}

















