import Select from 'react-select';
import { GetAddonMasterData} from '../store/productAtom';



function AddOnUnitSelect(props) {
  const masterData = GetAddonMasterData();

  const customOption = masterData[15]?.map((item)=> {
    return ({...item, label:`${item.Name}`})
  })

  
  const customStyles = {
    control: base => ({
      ...base,
      height: 50,
      backgroundColor: '#F1F1F1',
      width: 450,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),

  
    
  }

    const handleChange = (value) => {
      props.onUnitSelect(value)
      };


    return ( 
        <>
   
         <Select  placeholder={"Search Country...."}   styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal}
          options={customOption}
         />


        </>
     );
}

export default AddOnUnitSelect;
