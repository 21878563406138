
const optionList = [
    {label:'Not Shipped', value:'so_not_shipped'},
    {label:'Shipped', value:'so_shipped'},
    {label:'Delivered', value:'so_delivered'},
]

function PackageFilter(props) {

  const createSelectItems = () => {
    let items = [];
    optionList?.forEach((item, i) => {
      items.push(
        <option key={i} value={item?.value}>
          {item.label}
        </option>
      );
    });

    return items;
  };

  const handleChange = (e) => {
    props.onFilterSelect(e.target.value);
  };

  return (
    <>
      <select
        className="w-full h-[48px] text-sm border-[1px] border-gray-200 rounded-lg outline-none focus:outline-none "
        onChange={handleChange}
      >
        {createSelectItems()}
      </select>
    </>
  );
}

export default PackageFilter;
