
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const accountService = {
    getAccountInvoice,
    getPaymentLink,
    getAccountEstimate,
    getReoccuring,
    getExpense,
    getReOccuringExpense,
    getProject,
    getJournal,
    getChartOfAccount,
    getChartEntryMasterData,
    saveNewChartAccount,
    getChartOfAccountDetails,
    getChartOfAccountCurrencies,
    getConversionRate,
    updateAccountStatus,
    getPaymentReceiveList,
    savePaymentReceivedTableMenu,
    getPaymentReceiveDetails,
    savePaymentReceivedBulkAction,
    getPaymentEntryData,
    submitPaymentReceived,
    getPurchaseOrderList,
    savePaymentPurchaseOrderTableMenu,
    setPurchaseBulkAction,
    getPurchaseDetails,
    getPurchaseBill,
    getPurchaseReceives,
    getPurchaseEntryMasterData,
    getPurchaseHistory,
    submitPurchaseOrder,
    deletePaymentReceive,
     getPaymentGatewayListByDate,
     getPaymentVariate,
      walletCheckDetails,
     
    
 
};

async function  getAccountInvoice( customer_id) {
    return  axios.get(`/accounts/account-invoice/${customer_id}`, authHeader());
}


async function  getPaymentLink( customer_id) {
    return  axios.get(`/accounts/payment-link/${customer_id}`, authHeader());
}

async function  getChartEntryMasterData( ) {
    return  axios.get(`/accounts/get-chart-entry-master-data`, authHeader());
}

async function  saveNewChartAccount(body ) {
    return  axios.post(`/accounts/save-new-chart-account`,body, authHeader());
}

async function  updateAccountStatus(body ) {
    return  axios.put(`/accounts/update-account-status`,body, authHeader());
}


async function  getChartOfAccountDetails( id) {
    return  axios.get(`/accounts/get-chart-account-details/${id}`, authHeader());
}

async function  getAccountEstimate( customer_id) {
    return  axios.get(`/accounts/account-estimate/${customer_id}`, authHeader());
}


async function  getReoccuring( customer_id) {
    return  axios.get(`/accounts/account-reoccuring/${customer_id}`, authHeader());
}

async function  getConversionRate( account_id, curr_id, option) {
    return  axios.get(`/accounts/conversion-rate/${account_id}/${curr_id}/${option}`, authHeader());
}


async function  getExpense( customer_id) {
    return  axios.get(`/accounts/account-expense/${customer_id}`, authHeader());
}


async function  getReOccuringExpense( customer_id) {
    return  axios.get(`/accounts/reoccuring-expense/${customer_id}`, authHeader());
}

async function  getProject( customer_id) {
    return  axios.get(`/accounts/get-projects/${customer_id}`, authHeader());
}


async function  getJournal( customer_id) {
    return  axios.get(`/accounts/get-journal/${customer_id}`, authHeader());
}

async function  getChartOfAccount() {
    return  axios.get(`/accounts/get-chart-of-account`, authHeader());
}

async function  getChartOfAccountCurrencies() {
    return  axios.get(`/accounts/get-chart-of-account-currencies`, authHeader());
}

async function  getPaymentReceiveList() {
    return  axios.get(`/accounts/get-payment-receive-list`, authHeader());
}

async function  savePaymentReceivedTableMenu(body) {
    return  axios.put(`/accounts/save-payment-table-menu`, body, authHeader());
}


async function  savePaymentReceivedBulkAction(body) {
    return  axios.put(`/accounts/save-payment-received-bulk-action`, body, authHeader());
}


async function  getPaymentReceiveDetails(ref_id) {
    return  axios.get(`/accounts/get-payment-receive-details/${ref_id}`, authHeader());
}

async function  getPaymentEntryData(customer_id) {
    return  axios.get(`/accounts/payment-entry-data/${customer_id}`, authHeader());
}

async function  submitPaymentReceived(body) {
    return  axios.post(`/accounts/submit-payment-receive`,body, authHeader());
}

async function  getPurchaseOrderList() {
    return  axios.get(`/accounts/get-purchase-order`, authHeader());
}

async function  savePaymentPurchaseOrderTableMenu(body) {
    return  axios.put(`/accounts/save-purchase-order-table-menu`, body, authHeader());
}


async function  getPurchaseDetails(purchase_id ) {
    return  axios.get(`/accounts/get-purchase-details/${purchase_id}`, authHeader());
}

async function  setPurchaseBulkAction(body ) {
    return  axios.put(`/accounts/set-purchase-bulk-action`,body, authHeader());
}
async function  getPurchaseBill(purchase_id ) {
    return  axios.get(`/accounts/get-purchase-bill/${purchase_id}`, authHeader());
}

async function  getPurchaseReceives(purchase_id ) {
    return  axios.get(`/accounts/get-purchase-receives/${purchase_id}`, authHeader());
}

async function  getPurchaseHistory(purchase_id ) {
    return  axios.get(`/accounts/get-purchase-history/${purchase_id}`, authHeader());
}

async function  getPurchaseEntryMasterData() {
    return  axios.get(`/accounts/get-purchase-entry-master-data`, authHeader());
}

async function  submitPurchaseOrder(body) {
    return  axios.post(`/accounts/submit-purchase-order`,body, authHeader());
}

async function  deletePaymentReceive(id) {
    return  axios.delete(`/accounts/delete-payment-receive/${id}`, authHeader());
}


async function  getPaymentGatewayListByDate(from_date, to_date) {
    return  axios.get(`/accounts/payment-gateway-list/${from_date}/${to_date}`, authHeader());
}


async function  getPaymentVariate() {
    return  axios.get(`/accounts/payment-variant`, authHeader());
}



async function  walletCheckDetails(data) {
    return  axios.post(`/accounts/wallet-check-details`, data, authHeader());
}












