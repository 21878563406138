
import { useEffect } from "react";
import { useContext } from "react";
import {GetVendorListData} from '../../store/vendorAtom';

import { Grid } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useRef } from "react";



function VendorDetailsScroll(props) {
  const vendorList = GetVendorListData();
  const [tableData, setTableData] = useState([]);
  const { vendor_id } = useParams();





    const mountRef = useRef(true); 

    
    useEffect(() => {
      mountRef.current = true;
        if(mountRef.current){
          setTableData( vendorList['Vendor_Info']?.map((ele) => {return { ...ele, checked: false } }));
        }

        return ()=> {
          mountRef.current = false;
        }
    
      }, [vendorList]);

      console.log('ls ', vendorList)


      const onCheckAll = async (e, index) => {
        let element = tableData[index];
        tableData[index] = { ...element, checked: e.target.checked };
        await setTableData((prev) => {
          return [...tableData];
        });
        props?.onSelection(tableData);
      };


    return ( 
        <>
        
<section className=" w-30  z-0 mt-3	main border-2 b">
  
  {/* <ActionPaneComponent bulkActionData={getTableFilter}
   onSelectBulkAction={onSelectBulkAction} /> */}

      <div className=" ">
    {tableData?.map((vendor, i)=> {
      return (  
        <div   onClick={() => props.viewVendorDetails(vendor.Vendor_Id)} key={i} 
        className={(vendor_id == vendor?.Vendor_Id ?"app_bg_color1  cursor " : " border cursor ")}>
        <div className="col text-md pt-2 " >
        {/* <label className="ml-1">
          <input onChange={(e)=> onCheckAll(e, i)}  checked={vendor?.checked ? vendor?.checked : '' } 
           type="checkbox" className="w-[2vh] h-[2vh]" />
        </label> */}
          {vendor?.Vendor_Full_Name}

        </div>
        <div className="col pb-1 ">
       
         <span className="text-sm font-bold md:text-sm">  ID: {vendor?.Vendor_Id} </span>  <br/>

         <span className="text-sm font-bold md:text-sm mb-3 ">
          <i className="font-weight-bold pb-3"> <PhoneIcon className="text-primary" /> {vendor?.Vendor_Mobile_No} </i>
         </span>
     

        </div>

      </div>

      )
    })}
      

   
      </div>


</section>
        </>
     );
}

export default VendorDetailsScroll;