import React, { useRef } from "react";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";

import { useReactToPrint } from "react-to-print";
import { Dropdown } from "react-bootstrap";

import LogicContext from "../../Context/LogicContext";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { ImAttachment } from "react-icons/im";
import { FaFilePdf } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";
import PackageScroll from "./PackagesScroll";
import PackageDetailsContent from "./PackageDetailcontent";
import { packageService } from "../../services/packageService";
import {SetPackageEditAtom , GetPackageListAtom, SetPackageListAtom} from "../../store/packageStore";
import { Button, Modal } from 'antd';

const PackageDetailsPage = () => {
  const myPromiseModal = createModal(GeneralModal);
  const getPackageAtomList = GetPackageListAtom();
  const setPackageAtomList = SetPackageListAtom();
  const setPackageEditAtom = SetPackageEditAtom();
  const logicContext = useContext(LogicContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  const { id } = useParams();
  const navigate = useNavigate();
  const [packageDetails, setPackageDetails] = useState({});
  const [packageList, setPackageList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const mountRef = useRef(false);
  const componentRef = useRef();
  const {register, setValue, getValues, handleSubmit,control,reset,formState: { errors }} = useForm();



  useEffect(() => {
    document.title = " Package Details";
    mountRef.current = true;
    getPackageDetails(id);

    return () => {
      mountRef.current = false;
    };
  }, [id]);


  const getPackageDetails = (id) => {
    packageService.packageDetails(id).then((res)=> {
        console.log('resssss_PACK ', res.data);
        if(res?.data?.length){
          const newDetails =  res.data[0];
            setPackageList(res.data);
            setPackageDetails(newDetails);

            const tempList =  getPackageAtomList[type];
            if(tempList?.length){
              const newList =  tempList.map((pkg)=> {
                if(pkg.Sales_Order_Ref_No === newDetails?.Sales_Order_Ref_No){
                  return newDetails;
                }else{
                  return pkg
                }
              });
              setPackageAtomList((prev)=> {
                return {...prev, [type]: newList}
              });
            }
        }else{
          setPackageList([]);
          setPackageDetails({});

        }
    })

  }

  const editPackage = async () => {
    if(packageDetails.Sales_Order_Status_Id !== 5){
        return logicContext.showToast("Only NOT SHIPPED item can be modified!", "error");
        
    }
    const response = await myPromiseModal({
      title: "Modify Package",
      body: " Do you want to modify this record?",
      showInput: false,
      placeHolder: "",
    });

    if (response.decision === true) {
      setPackageEditAtom(packageList);
      navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
    }
  };
  const markAsShipped = async () => {
    if(packageDetails.Sales_Order_Status_Id !== 5){
        return logicContext.showToast("Only NOT SHIPPED item can be modified!", "error");
        
    }
    const response = await myPromiseModal({
      title: "Ship item manually",
      body: `Sales Order with ${packageDetails.Sales_Order_Status_Id} will marked as shipped? `,
      showInput: false,
      placeHolder: "",
    });

    if (response.decision === true) {
      setPackageEditAtom(packageList);
      navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new-shipping`);
    }
  };





  const deletePackage = async () => {
   
    const response = await myPromiseModal({
      title: "Delete Package",
      body: `Package with ${packageDetails?.Sales_Order_Ref_No} will be deleted.`,
      showInput: false,
      placeHolder: "",
    });

    if (response.decision === true) {
      packageService.deletePackage(packageDetails?.Sales_Order_Ref_No).then(res => {
        const { message_text, message_id } =  res.data[0];

        const tempList =  getPackageAtomList[type];
        if(tempList?.length){
          const newList =  tempList.filter((lst)=> lst.Sales_Order_Ref_No !== packageDetails?.Sales_Order_Ref_No);
          setPackageAtomList((prev)=> {
            return {...prev, [type]: newList}
          });
        }
        logicContext.showToast(message_text, "");
        navigate(-1);
      }).catch((err)=> {
        console.log('del_error ', err);
      });
      // navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
    }
  };

 



  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const showModal = () => {
    setIsModalOpen(true);
  };



  const handleCancel = () => {
    setIsModalOpen(false);
  };



  const onSubmitFormMarkDelivered = async () => {
   const formData = getValues();
   const newForm = {...formData, sales_order_ref_no: packageDetails?.Sales_Order_Ref_No}
   packageService.deliveredPackage(newForm).then(res => {
    setIsModalOpen(false);
    navigate( `/${currModuleId}/${currModuleName}/package-setup`);
    const { message_text, message_id } =  res.data[0];
    logicContext.showToast(message_text, "success");


   }).catch((err)=> {
    console.log('err ', err);
   })


  };



  return (
    <>
     <>
     {/* <form onSubmit={handleSubmit(onSubmitForm)}> */}
     <Modal title="Mark as Delivered" open={isModalOpen} onOk={onSubmitFormMarkDelivered} onCancel={handleCancel}>
      <div class="flex space-x-4">
 
        <div class="flex flex-col">
          <label for="date" class="text-gray-700 mb-1">Date</label>
          <input {...register("shipment_delivery_date")}  type="date" id="date" class="border border-gray-300 rounded-md px-3 py-2 
           w-32 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
        </div>

      
        <div class="flex flex-col">
          <label for="time" class="text-gray-700 mb-1">Time</label>
          <input {...register("shipment_delivery_time")}  type="time" id="time" class="border border-gray-300 
          rounded-md px-3 py-2  w-32 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
        </div>
</div>
      </Modal>

     {/* </form> */}
   
    </>

      <section className="mt-2">
       

        <section className="w-full">
          <div className="flex flex-row items-center justify-end">
          
            <Dropdown className=" mr-3">
              <Dropdown.Toggle
                className="text-sm text-white bg-[#476EF8]"
                id="dropdown-basic"
              >
                ACTION
              </Dropdown.Toggle>
              <Dropdown.Menu className="mt-1 shadow-xl border-[1px] border-gray-200 rounded-md bg-white">
              
                
                      {(packageDetails?.Sales_Order_Status_Id === 5) && 
                          <Dropdown.Item
                          className="border-1 active:bg-gray-200"
                          onClick={() => markAsShipped()}
                        >
                       <div className=" text-sm text-gray-600 py-[2px] hover:bg-100">
                       Mark as Shipped
                      </div>
                    </Dropdown.Item>
                      
                      }
                
                      {(packageDetails?.Sales_Order_Status_Id === 6) && 
                          <Dropdown.Item
                          className="border-1 active:bg-gray-200"
                          onClick={() => showModal()}
                        >
                       <div className=" text-sm text-gray-600 py-[2px] hover:bg-100">
                       Mark as delivered
                      </div>
                    </Dropdown.Item>
                      
                      }
                
              </Dropdown.Menu>
            </Dropdown>
            <div className="flex fle-row items-center space-x-1 ">
              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <ImAttachment className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div
                onClick={() => handlePrint()}
                className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer"
              >
                <FaFilePdf className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div
                onClick={() => handlePrint()}
                className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer"
              >
                <IoPrintSharp className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              <div
                onClick={editPackage}
                className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer"
              >
                <MdEdit className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              <div
               onClick={()=> deletePackage()}
                className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer"
              >
                <MdDelete  className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
            </div>
          </div>

          <section className="mt-4 lg:flex space-x-1">
            <div className="lg:w-[210px]">
              <PackageScroll  />
            </div>
        {/* Body product */}
            <PackageDetailsContent packageList={packageList}  packageDetails={packageDetails} />
          
            {/* Body product end */}
          </section>
        </section>
      </section>
    </>
  );
};

export default PackageDetailsPage;
