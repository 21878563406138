import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import "../../helpers/PaymentBuilkAction.css";
import {
  GetBulkActions,
  GetDepositTo,
  GetPaymentMode,
} from "../../store/paymentReceivedAtom";

const style = {
  position: "absolute",
  top: "30%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function PaymentBulkAction(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const bulkAction = GetBulkActions();
  const depositTo = GetDepositTo();
  const paymentMode = GetPaymentMode();
  const [optionList, setOptionList] = React.useState([]);
  const [mainWindow, setMainWindow] = React.useState(0);

  const onBulkActionChange = (e) => {
    const selection = Number(e.target.value);
    if (selection === 1) {
      setMainWindow(selection);
      setOptionList(paymentMode);
    }
    if (selection === 2) {
      setOptionList(depositTo);
      setMainWindow(selection);
    }
    if (selection === 0) {
      setOptionList([]);
      setMainWindow(0);
    }
  };

  const onBulkOptionChange = (e) => {
    if (mainWindow === 1) {
      props.onPaymentModeSelect(Number(e.target.value));
    }
    if (mainWindow === 2) {
      props.onDepositSelect(Number(e.target.value));
    }
  };

  function updateAction() {
    if (mainWindow === 0) {
      handleClose();
    } else {
      props.performBulkActions();
      handleClose();
    }
  }

  return (
    <div>
      <button className="btn bg-light" onClick={handleOpen}>
        Bulk Action
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="p-4 mt-32 w-full mx-auto max-w-lg bg-white rounded-xl">
          <div className="flex justify-center">
            <Typography
              id="modal-modal-title"
              className="text-2xl font-bold text-gray-700"
            >
              BULK UPDATE PAYMENT RECEIVE
            </Typography>
          </div>

          <div className="mt-6 px-4 border-[1px] border-gray-200 rounded-lg">
            <div className="mt-1 text-center text-xs text-gray-400 italic">
              Choose a field from the dropdown and update with new information.
            </div>

            <div className="mt-4">
              <select
                onChange={onBulkActionChange}
                className="bg-filterInput border-[1px] border-gray-200 rounded"
              >
                <option value="0">select a field</option>

                {bulkAction?.map((action, i) => {
                  return (
                    <option className="p-3 " key={i} value={action.Action_Id}>
                      {action.Action_Name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/*  second column */}
            <div className="mt-3 pb-3">
              <select
                onChange={onBulkOptionChange}
                className="bg-filterInput border-[1px] border-gray-200 rounded"
                width="300"
                style={{ width: "300px" }}
              >
                <option width="30" style={{ width: "30px" }} value="1">
                  option
                </option>

                {optionList?.map((option, i) => {
                  return (
                    <option
                      width="30"
                      style={{ width: "30px" }}
                      className="p-3 styled-width"
                      key={i}
                      value={option?.Payment_Mode_Id || option?.Account_Id}
                    >
                      {option?.Payment_Mode_Name || option?.Account_Name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="my-2 flex flex-row items-center justify-between">
            <div className="">
              <button className="btn btn-danger" onClick={handleClose}>
                Cancel
              </button>
            </div>
            <div className="">
              <button className="btn btn-primary" onClick={updateAction}>
                Update
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
