

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";



const carrierBagAtom = atom({key:'carrier_bag_return', default:[]
});

const carrierEditAtom = atom({key:'carrier_edit_atom_return', default:{}
});


export  const SetCarrierBagStoreList= () => useSetRecoilState(carrierBagAtom);
export  const GetCarrierBagStoreList = () => useRecoilValue(carrierBagAtom);

export  const SetEditReturn= () => useSetRecoilState(carrierEditAtom);
export  const GetEditReturn = () => useRecoilValue(carrierEditAtom);







