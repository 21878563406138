/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useContext } from "react";
// import "./CustomerDetails.css";
import "./FinishItemSetup.css";
import plus from "../assets/Plus.svg";

// import "./CustomerDetails.css";
import {  useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import LogicContext from "../Context/LogicContext";

import FinishItemTable2 from "./FinishItemTable2";
import { itemsSetupService } from "../services/itemSetup.service";
import FinishItemsSetupSearchItems from "./FinishItemSetupSearch";
import AlertConfirm from "../helpers/AlertConfirm";
import './scrollBar.css';
import  {GetProductionItems} from '../store/finishItemAtom';
import {SetProductionSelectItem, GetProductionSelectItem} from '../store/productionAtom';


function ProductionSetupTab(props) {
    const selectedItem = GetProductionSelectItem();
    const setSelectedItem = SetProductionSelectItem();
    const [itemOnSelectWindow, setItemOnSelectWindow] = useState({});
    const [currentQty, setCurrentQty] = useState(1);
    const [dropDownList, setDropDownList] = useState([]);
    const [selectedTableData, setSelectedTableData] = useState([]);
    const logicContext = useContext(LogicContext);
    const [alertState, setAlertState] = useState(false);
    const { batch_id } = useParams();
    const { pathname } = useLocation();
    var url = pathname.split("/");
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];
  
  
    var itemList = GetProductionItems();
  
    useEffect(() => {
      itemsSetupService.getFinishItemsDropDown().then((res) => {
        if (res.data !== undefined) {
          setDropDownList(res?.data);
        }
      });
    }, []);


      


  
  
    useEffect(()=> {
  
      if(batch_id !== undefined){
        let routeProps = batch_id?.split('_');
        let newItemId = routeProps[0];
        if(routeProps[1] == 1){
             props.changeTab();
        }else{
          
          const selectedRouteItem =  itemList.filter((item)=>  item.Item_Id == newItemId);
         setSelectedItem(selectedRouteItem[0]);

         itemsSetupService.selectItemSetup(newItemId).then((res) => {
          if (res.data !== undefined) {
            setSelectedTableData(res?.data);
          } else {
          }
        }).catch((error) => {
          logicContext.showToast('Items not found!', "info");
          setSelectedTableData([]);
        });
    
        }
     
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[batch_id]);
  
  

  
  
    function userDropDpwnSelect(item) {
      setItemOnSelectWindow(item);
    }
  
  
  
    function addItemOnSelectWindowTTable() {
      const exist = selectedTableData.filter(
        (item) => item.Item_Id === itemOnSelectWindow.Item_Id
      );
  
      if (exist.length > 0) {
        logicContext.showToast("Selected Item already exist!", "error");
        return;
      }
  
      if (itemOnSelectWindow?.Item_Id) {
        setSelectedTableData((prev) => {
          return [...prev, { ...itemOnSelectWindow, Item_quantity: currentQty }];
        });
        // setCurrentQty(1);
        setItemOnSelectWindow({});
      } else {
        logicContext.showToast("Please select a new Item!", "warning");
      }
    }
  
  
    async function deleteItemfromTableList(item) {
      const newItem = await selectedTableData?.filter(
        (data) => data.Item_Id !== item.Item_Id
      );
      setSelectedTableData(newItem);
    }
  
  
    function modifyQuatity(newQuatity, itemModify) {
      const modifiedList = selectedTableData.map((item) => {
        if (item.Item_Id === itemModify.Item_Id) {
          return { ...item, Item_quantity: parseInt(newQuatity) };
        } else {
          return item;
        }
      });
  
      setSelectedTableData(modifiedList);
    }
  
  
    function submitItemListToServer() {
      setAlertState(true);
    }
  
  

  
    function closeAlert(response) {
      setAlertState(false);
      if (response === true) {
        let xml_string = "";
        let temp = "";
        selectedTableData.map((item, i) => {
          temp += `<a><item_id>${item.Item_Id} </item_id> <item_quantity>${item.Item_quantity} </item_quantity> </a>`;
        });
        xml_string = `<root> ${temp} </root>`;
        itemsSetupService
          .submitItemSetup(
            { xml_string: xml_string },
            { item_id: selectedItem.Item_Id }
          )
          .then((res) => {
            logicContext.showToast(res.data[0]["Message_Text"], "success");
            setSelectedTableData([]);
          });
      } else {
      }
    }
  



    return ( 
        <>

{alertState ? (
        <AlertConfirm title={"Confirmation?"}body={"Do you want to save?"} closeAlert={closeAlert}
        ></AlertConfirm>) : null}

      

      <section className="   mt-2">
       
        <section className="flex relative justify-between">
      

          <section className="w-full z-50 ">
       

            <section className="mx-2  mt-2 max-auto max-w-6xl text-filterTextColor">
              <section className=" ">
                <section className=" mt-1 right-1   md:  ">
                  <section className="">
                    <section className="profile_section ">
                      <div className="  ">
                        <div className="flex justify-between w-full">
                          {" "}
                          <FinishItemsSetupSearchItems
                            list={dropDownList}
                            type={selectedItem?.Item_Variety_Id}
                            userSelection={userDropDpwnSelect}
                          />
                          <div className="required-q">
                            <span className="text-xs">Required Quantity</span>{" "}
                            <br></br>
                            <input
                              onChange={(e) => setCurrentQty(e.target.value)}
                              className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                              type="number"
                            />
                          </div>
                          <div>
                            <button
                              onClick={addItemOnSelectWindowTTable}
                              style={{ borderRadius: "5px" }}
                              className=" rounded mt-4  px-3 h-[5.8vh] text-filterTextColor bg-filterColor  ml-1 "
                            >
                              <img src={plus} alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* end */}

                      <section className="mt-3">
                        <FinishItemTable2
                          modifyQuatity={modifyQuatity}
                          deleteItemfromTableList={deleteItemfromTableList}
                          tableData={selectedTableData}
                        ></FinishItemTable2>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>

            <section className="mt-[10vh] ">
              <div className="flex justify-end  ">
                <button
                  style={{ backgroundColor: "#C6C6C6", color: "#151EFA" }}
                  className=" h-[6vh] rounded  text-xs py-1 mr-2 px-4"
                >
                  DELETE
                </button>
                <button
                  onClick={submitItemListToServer}
                  style={{ backgroundColor: "#476EF8" }}
                  className=" h-[6vh]  rounded text-white text-xs py-1 mr-2 px-4"
                >
                  SAVE
                </button>
              </div>
            </section>
          </section>
        </section>
      </section>
        </>
     );
}

export default ProductionSetupTab;