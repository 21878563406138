import { TableBody,TableCell, TableContainer, TableHead,TableRow} from "@mui/material";
  import { Table } from "react-bootstrap";
  import CurrencyFormat from 'react-currency-format';



const BillPaymentMade = (props) => {

    return ( 

        <>
          <div className="mt-2 ">
  
  <TableContainer className="mt-3 w-full border mb-5">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <colgroup>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                </colgroup>
              <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Payment</TableCell>
                  <TableCell> Reference </TableCell>
                  <TableCell> Payment Made </TableCell>
                  <TableCell> Amount </TableCell>
         
                </TableRow>
              </TableHead>
  
              <TableBody className=" mb-3">
                {props?.tableData?.map((item, i) => {
                  return (
                    <TableRow className="border-1"
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{item.Bill_No} </TableCell>
                      <TableCell align="left">{item.Bill_Date}  </TableCell>
                      <TableCell align="left">{item.Bill_Status_Name}  </TableCell>
                      <TableCell align="center">{item.Due_Date} </TableCell>
                  
                      <TableCell align="left">
                        <CurrencyFormat value={parseFloat(item.Bill_Amount)?.toFixed(2)}
                         displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                       </TableCell>
                
                      <TableCell align="left">
                        <CurrencyFormat value={parseFloat(item.Balance_Due_Amount)?.toFixed(2)}
                         displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                       </TableCell>
                
               
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
  </div>
      
        
        </>
     );
}
 
export default BillPaymentMade;