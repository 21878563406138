import { TableBody,TableCell, TableContainer, TableHead,TableRow} from "@mui/material";
  import DeleteIcon from "@mui/icons-material/Delete";
  import { Table } from "react-bootstrap";
  import CurrencyFormat from 'react-currency-format';
  import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
  
  
  
  
  function PurchaseDetailsTable(props) {


  return (
      <>
  <div className="mt-5 ">
    <h6  className="text-center text-center font-bold" >Purchase Order List </h6>
  
  <TableContainer className="mt-3 w-full">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {/* <colgroup>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'10%'}}/>
                    <col style={{width:'10%'}}/>
                    <col style={{width:'20%'}}/>
                </colgroup> */}
              <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                <TableRow>
                  <TableCell className="subheading">Items and Description</TableCell>
                  <TableCell className="subheading">SKU</TableCell>
                  <TableCell className="subheading">Ordered</TableCell>
                  <TableCell className="subheading">Warehouse Name</TableCell>
                  <TableCell className="subheading"> Status </TableCell>
                  <TableCell className="subheading"> Rate </TableCell>
                  <TableCell className="subheading"> Tax </TableCell>
                  <TableCell className="subheading"> Amount </TableCell>
                  {/* <TableCell  align="center">Rate</TableCell>
                  <TableCell  align="right">Amount</TableCell> */}
                  {/* <TableCell></TableCell> */}
                </TableRow>
              </TableHead>
  
              <TableBody className=" mb-3">
                {props?.tableData?.map((item, i) => {
                  return (
                    <TableRow className="border-1"
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell  className="subheading"  align="left">{item.Item_Name} </TableCell>
                      <TableCell  className="subheading" align="left">{item.Item_Sku}  </TableCell>
                      <TableCell  className="subheading" align="left">{item.Item_Quantity?.toFixed(2)}  {item.Item_Unit_Name} </TableCell>
                      <TableCell  className="subheading" align="left">{item.Received_Warehouse_Name}  </TableCell>
                      <TableCell  className="subheading" align="left">{item.Received_Status_Name} </TableCell>
                  
                      <TableCell  className="subheading" align="left">
                        <CurrencyFormat value={parseFloat(item.Item_Rate)?.toFixed(2)}
                         displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                       </TableCell>
                      <TableCell  className="subheading" align="left">
                        {item.Item_Tax_Name}
                       </TableCell>
                
                      <TableCell  className="subheading" align="left">
                        <CurrencyFormat value={parseFloat(item.Purchase_Amount)?.toFixed(2)}
                         displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                       </TableCell>
                
               
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
  </div>
      
      </>
    );
  }
  
  export default PurchaseDetailsTable;