import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import profile from "../../assets/itempimage.svg";
import singleImage from "../../assets/itemSingle.svg";
import {imageService}  from '../../services/image.service';
import DeleteIcon from "@mui/icons-material/Delete";



function VendorImageComponent(props) {

    const [image1Preview1, setImage1Preview1] = useState(null);


    
   


    useEffect(()=> {
        setImage1Preview1(null);
  
        if(props?.vendor_id !== undefined){
          setImage1Preview1(imageService.VENDOR_IMAGE_URL + props?.vendor_id  +'.'+props?.format);
        //   props.getItemImages(props?.plan_id).then(res => {
        //     console.log('IMAGES  Lis', res.data)
        //    res?.data?.Plan_Image?.map((item, i)=> {
           
        //    })
        // })

        }
     
      },[props?.vendor_id, props.format]);


    const imageOneChangeHandler = (e) => {
        const file = e.target.files[0];
        let fileName = file.name.split(' ').join('_');
        let fileExtension = fileName.split('.');
       if(file){
        let option = 1;
        if(image1Preview1){
          option = 2;
        }
        props.submitItemImage(file, '1' , 1,fileExtension[1], option )
        const reader = new FileReader();
            reader.addEventListener("load", () => {
              setImage1Preview1(reader.result);
            });
            reader.readAsDataURL(file);
       }
      };


  

      function imageOneError(){
        setImage1Preview1(null)
      }

   
   const selectImageOne = ()=> {
     document.getElementById('image-one').click();
   }
  



    return ( 
        <>
            <div className="flex justify-center ">
       <div className="row  ">
        <div className="col justify-content-center ">
          
            <input style={{ display: 'none' }}	id="image-one"	accept="image/*"  onChange={imageOneChangeHandler}	type="file"	name="name"	/>
            {/* <DeleteIcon color="error"/> */}
            <span onClick={selectImageOne}>
            <img style={{width: '150px', height:"200" }}  onError={()=> imageOneError()}  src={image1Preview1 ? image1Preview1 : profile  }  alt="" className="profileImage rounded profileImage2" />
        
            </span>
              
       
          </div>
          </div>
       </div>
        
        
    </>
     );
}

export default VendorImageComponent;