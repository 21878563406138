import React from 'react';
import { DataGrid } from "@mui/x-data-grid";
  import PaginationItem from '@mui/material/PaginationItem';
  import { useEffect } from 'react';
import { itemsSetupService} from '../services/itemSetup.service';
import { GetCompositeGroup, SetICompositeGroup, SetICompositeGroupBackup} from "../store/itemAtom";
    import { useLocation, useNavigate } from "react-router-dom";




    const TableHead = [
        { field: 'Composite_Group_Name', headerName:'Name' , width: 100 , headerClassName: "tableHeader "},
        { field: 'Total_Items', headerName:'Items' , width: 50 , headerClassName: "tableHeader "},
        { field: 'Composite_Group_Status_Name', headerName:'Status' , width: 65 , headerClassName: "tableHeader "},
        ]


function CompositeGroupDetaiilsScroller(props) {
  const setCompositeGroup = SetICompositeGroup();
  const setBackUp = SetICompositeGroupBackup();
  const navigate = useNavigate();

    let groupList =  GetCompositeGroup();

    const { pathname } = useLocation();
    var url = pathname.split("/");
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];


    function OnItemCellClick (event){
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${event.row.Composite_Group_Id}`)
      }


      useEffect(()=> {
        if(groupList.length  < 1){
          itemsSetupService.getCompositesGroupItems().then((res) => {
            if(res?.data != undefined){ 
              setCompositeGroup(res?.data)
              setBackUp(res?.data);
             
            }
           
          })
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[groupList.length])


 
  

  const rows = groupList?.map((item, i) => {
    return ({...item, id: item.Composite_Group_Id})  
});



    return (
        <div className="">
    
        <div  style={{ height: 450, width: '100%' }}>
              <DataGrid
                columns={TableHead}
                rows={rows}
                components={{
                  Pagination: PaginationItem,
                }}
                checkboxSelection={false}
                onRowClick={(event)=> {  OnItemCellClick(event);
                }}
              
                sx={{
               
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                    cursor: 'pointer',
                  },
                }}
              />
            </div>
        </div>
    );
}

export default CompositeGroupDetaiilsScroller;