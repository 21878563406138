import React, { useEffect, useState } from "react";

import "../scrollBar.css";
import { Dropdown } from "react-bootstrap";
import { productService } from "../../services/product.service";
import "../../components/AddProduct.css";
import ProductAddModal from "./ProductAddModal";
import Card from "@mui/material/Card";
import {
  ResetPlanEdit,
  SetProductList,
  GetProductList,
} from "../../store/productAtom";

function ProductslideScroll(props) {
  const setProductList = SetProductList();
  const getProductList = GetProductList();

  console.log('getProductList', getProductList);

  useEffect(() => {
    if (getProductList?.length === 0) {
      productService.getAllProducts().then((res) => {
        if (res.data !== undefined) {
          setProductList(res.data);
          props.viewItemDetails(res.data[0]);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getAllProduct() {
    productService.getAllProducts().then((res) => {
      if (res.data !== undefined) {
        setProductList(res.data);
        props.viewItemDetails(res.data[0]);
      }
    });
  }

  function reloadProductList() {
    getAllProduct();
  }

  return (
    <>
 <section className="py-4 border rounded-lg bg-white border-gray-200 flex flex-col overflow-y-auto h-[300px] lg:h-[1000px] scrollbar-thumb-gray-500 scrollbar-thin scrollbar-track-gray-100 pb-[100px] max-w-4xl w-full mx-auto">
  <div className="px-4 grid grid-cols-2 lg:grid-cols-1 gap-4">
    <div className="flex items-center space-x-4">
      <Dropdown>
        <Dropdown.Toggle
          className="text-gray-700 bg-white font-semibold text-sm h-9 border border-gray-300 rounded-md px-4 focus:outline-none"
          id="dropdown-basic"
        >
          All Products
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item>All</Dropdown.Item>
          <Dropdown.Item>Composite Items</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
    <div>
      <ProductAddModal reloadProductList={reloadProductList} />
    </div>
  </div>
  
  <div className="mt-6 px-4 space-y-4">
    {getProductList?.map((product, i) => (
      <Card
        key={i}
        className={`w-full cursor-pointer p-4 rounded-lg shadow-sm transition-transform transform hover:scale-105 ${
          props?.selectedProduct.Product_Id === product?.Product_Id
            ? "bg-blue-50 border-l-4 border-blue-500"
            : "bg-white border border-gray-200"
        }`}
        onClick={() => props.viewItemDetails(product)}
      >
        <p className="text-gray-900 font-bold text-lg mb-2">
          {product?.Product_Name}
        </p>
        <div className="grid grid-cols-3 gap-4 text-center">
          <div>
            <span className="text-gray-600 text-sm">{product?.Total_Plans}</span>
            <br />
            <span className="text-gray-800 font-semibold">Plans</span>
          </div>
          <div>
            <span className="text-gray-600 text-sm">{product?.Total_Addons}</span>
            <br />
            <span className="text-gray-800 font-semibold">Add-ons</span>
          </div>
          <div>
            <span className="text-gray-600 text-sm">{product?.Total_Coupons}</span>
            <br />
            <span className="text-gray-800 font-semibold">Coupons</span>
          </div>
        </div>
      </Card>
    ))}
  </div>
</section>


    </>
  );
}

export default ProductslideScroll;
