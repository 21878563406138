/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { authenticationService } from "../services/authentication.service";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { MdArrowDropDown } from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import { BsPersonCircle } from "react-icons/bs";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import "./dropdown.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function UserNameItem(props) {
  const navigate = useNavigate();

  const userDetails = authenticationService.currentUserObject();

  function logout() {
    authenticationService.Logout();
    navigate("/login");
  }

  return (
    <>
      <Menu as="div" className="relative">
        <div>
          <Menu.Button className="w-full flex flex-row items-center text-sm font-medium text-gray-700 focus:outline-none">
            <div className="flex flex-row items-center align-middle space-x-1">
              <div className="p-1 rounded-full bg-gray-200">
                <BsPersonCircle className="h-6 w-6 text-[#486EF8] " />
              </div>
              <p className="mt-2.5 hidden md:inline">{userDetails?.email}</p>
            </div>
            <div className="hidden sm:inline">
              <MdArrowDropDown className=" h-6 w-6 text-gray-500 " />
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-32 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={logout}
                    className={`${
                      active ? "bg-red-500 text-white" : "text-red-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    Logout
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}

export default UserNameItem;
