import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Select from 'react-select';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const customStyles = {
    control: base => ({
      ...base,
      height: 50,
      backgroundColor: '#F1F1F1',
      width: 330,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"})
  }


function SubManageContact(props) {
    const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);





    return (
        <>
        
    <div>
        <span onClick={handleOpen} className="text-sm" style={{ color: "#476EF8" }}>
                      Manage Contacts
                    </span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
        
            <Typography id="transition-modal-title" variant="h6" component="h2">
             
              <div className='app_bg_color1 p-3 mb-2'>  Manage Contact</div>
            </Typography>
     
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            <ul className="list-group">
              {props?.contactsList?.map((item, i)=> {
                return (
                  <li key={i} className="list-group-item"> 
                  <input type="checkbox" />
                  <span className='ml-2'>{item?.User_Full_Name} </span>
                  <span className='ml-2'>{item?.Person_Email_Address} </span>
                   </li>
                )
              })}
               

             
               
                </ul>
            </Typography>
            <div className='clearfix'>
           <div className='float-left'>
           <button className='btn btn-primary m-3 '>save</button>
           <button onClick={handleClose} className='btn btn-light m-3 '>cancel</button>
           </div>

          </div>
          </Box>
         
        </Fade>
      </Modal>
    </div>
        </>
      );
}

export default SubManageContact;