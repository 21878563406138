import React, { useEffect, useState, useContext, useRef } from "react";
import CustomerSearchSelect from '../credit_note/CustomerSearchSelect';

import { useNavigate } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import LogicContext from "../../Context/LogicContext";
import { useForm } from "react-hook-form";
import { packageService } from "../../services/packageService";
import { GetPackageMasterData, SetPackageMasterData, GetPackageEditAtom, SetPackageEditAtom, GetPackageListAtom } from "../../store/packageStore";
import SalesOrderSelect from "./SalesOrderSelect";
import moment from "moment";
import PackageCustomerSelect from "./PackageCustomerSelect";
import PickAddressSelect from "./PickAddressSelect";
import CurrencyFormat from 'react-currency-format';
import ShippingCheck from "./ShippingCheck";


function PackageNewShipping() {

  const navigate = useNavigate();
  const setPackageMasterData = SetPackageMasterData();
  const getPackageMasterData = GetPackageMasterData();
  const getPackageEditAtom = GetPackageEditAtom();
  const getPackageListAtom = GetPackageListAtom();
  const setPackageEditAtom = SetPackageEditAtom();
  const myPromiseModal = createModal(GeneralModal);
  const {register, setValue, getValues, handleSubmit,control,reset,formState: { errors }} = useForm();
  const logicContext = useContext(LogicContext);
  const [selectedSalesOrder, setSelectedSalesOrder] = useState([]);
  const [showOption, setShowOption] = useState(false);





    useEffect(()=> {
      if(getPackageEditAtom?.length){
        setSelectedSalesOrder(getPackageEditAtom);
        // [{sql_ref_no:77,ales_order_ref_no:,shipment_date:,shipment_remark:,shipment_delivered_status_id:
        //   ,shipment_delivery_date:,shipment_delivery_time:,user_id:,option:16}]
        setValue("sales_order_ref_no", getPackageEditAtom[0]?.Sales_Order_Ref_No);
        setValue("shipment_delivered_status_id", getPackageEditAtom[0]?.Sales_Order_Ref_No);
      
      }

      return  ()=> {
        setPackageEditAtom([]);
      }

    },[]);

   



  function shipmentDate(){
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD")
  //   if(getPackageEditAtom[0]?.Package_Date !== undefined){
  //     defaultDate = moment(getPackageEditAtom[0]?.Package_Date).format("YYYY-MM-DD")
    
  //  }
   
    return (
    
     <input
              type="date"
              defaultValue={defaultDate}
              className="w-full border border-gray-300 rounded-md shadow-sm p-2"
              {...register("shipment_date", {
                required: "Shipment is required",
              })}
            /> 
    )
  }


  function deliveryDate(){
    // let defaultDate = moment(Date.now()).format("YYYY-MM-DD")
    // if(getPackageEditAtom[0]?.Pickup_Date !== undefined){
    //    defaultDate = moment(getPackageEditAtom[0]?.Pickup_Date).format("YYYY-MM-DD")
    // }
    return (
      <>
       <input
                type="date"
                // defaultValue={defaultDate}
                className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                {...register("shipment_delivery_date")}
              /> 
       
      </>
    )
  }
  function deliveryTime(){
    // let defaultDate = moment(Date.now()).format("YYYY-MM-DD")
    // if(getPackageEditAtom[0]?.Pickup_Date !== undefined){
    //    defaultDate = moment(getPackageEditAtom[0]?.Pickup_Date).format("YYYY-MM-DD")
    // }
    return (
      <>
       <input
                type="time"
                // defaultValue={defaultDate}
                className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                {...register("shipment_delivery_time")}
              /> 
        <small className="text-red-500">
                {Boolean(errors["pickup_date"]?.message) && String(errors["pickup_date"]?.message)}
              </small>
      </>
    )
  }



  const onSubmit = async () => {
    await myPromiseModal({ title: "Confirmation", body: " Do you want to save this record?",
    showInput:false, placeHolder:'',showInput:false, placeHolder:''}).then((res) => {
        if (res.decision === true) {
          const newData = getValues();
          let option = 16;
          // if(getPackageEditAtom?.length){
          //   option = 14;
          // }
          
          packageService.subOrUpdatePackage({...newData, option}).then((res) => {
              if (res?.data !== undefined) {
                const { message_text, message_id } =  res.data[0];
                if (message_id === 1) {
                  logicContext.showToast(message_text, "success");
                  setPackageEditAtom([]); // Always set the edit to empty after save
                  navigate(-1);
                } else {
                  return logicContext.showToast(message_text, "error");
                }
              }
            });
        }
      })
      .catch((err) => {
        console.log('Error ', err);
        logicContext.showToast("error submitting record", "error");
      });
  };


  function onErrors(error) {
    logicContext.showToast("Form error", "error");
  }

  const onStatusCheck = (val) => {
    setShowOption(val);
    if(val){
      setValue("shipment_delivered_status_id",1);
    }else{
      setValue("shipment_delivered_status_id",2);

    }

    // setIsChecked(!isChecked);
  };



  return (
    <div className="p-8">
       <form onSubmit={handleSubmit(onSubmit, onErrors)}>
      <h1 className="text-xl font-semibold mb-6 flex items-center">
        {/* <span role="img" aria-label="package">📦</span> {getPackageEditAtom[0]?.Sales_Order_No ? 'New Package': 'Modify Package' }  */}
        New Shipment
      </h1>
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="grid grid-cols-2 gap-4">
          <div>  
            <label className=" text-sm ">Package Slip#</label>
            <input
              type="text"
              value={getPackageEditAtom[0]?.Package_Slip_Number}
              className="w-full border border-gray-300 rounded-md shadow-sm p-2"
              readOnly
            />
             <small className=""> Auto generated  </small>
          </div>
        
        
          {/* <div>
          
          </div> */}
          <div>
            <label className=" text-sm">Shipment Date</label>
            {shipmentDate()}
          </div>

        
         
        </div>


           {/* Table Section */}
      <table className="w-full bg-white border mt-3">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">#</th>
            <th className="py-2 px-4 border-b">Item & Description</th>
            <th className="py-2 px-4 border-b">Qty</th>
            <th className="py-2 px-4 border-b">Rate</th>
            <th className="py-2 px-4 border-b">Discount</th>
            <th className="py-2 px-4 border-b">Sub Total</th>
          </tr>
        </thead>
        <tbody>
        {selectedSalesOrder?.map((pkg, i)=> {
            return (
                <>
                <tr>
                <td className="py-2 px-4 border-b">{i+1} </td>
                <td className="py-2 px-4 border-b">{pkg?.Item_Name} </td>
                <td className="py-2 px-4 border-b">
                
                  <CurrencyFormat value={parseFloat(pkg?.Item_Sales_Quantity )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} />
                  </td>
                <td className="py-2 px-4 border-b">
                <CurrencyFormat value={parseFloat(pkg?.Item_Sales_Rate )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} />
                  </td>
                <td className="py-2 px-4 border-b">
                <CurrencyFormat value={parseFloat(pkg?.Discount_Amount )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} />
                  
                   </td>
                <td className="py-2 px-4 border-b">
                <CurrencyFormat value={parseFloat((pkg?.Item_Sales_Rate * pkg.Item_Sales_Quantity ) - pkg?.Discount_Amount )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} />
                 </td>
              </tr>
             
                </>
            )
          
                
            })}
          
        </tbody>
      </table>
     

      <div className="flex justify-between items-center mt-8">
        <p className="text-sm text-gray-500">
            <ShippingCheck onStatusCheck={onStatusCheck} />
        </p>
        <h3 className="font-semibold">
          Grand Total:  
          <CurrencyFormat value={parseFloat(selectedSalesOrder[0]?.Sales_Order_Amount )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} />
               
          </h3>
      </div>
      {showOption &&  
      <>
       <small>Delivered on</small>
      <div class="flex items-start">
                       <span>
                        {deliveryDate()} 
                       </span>
                       <span>
                       {deliveryTime()}
                       </span>
                </div>
      
      </>
      
      }
     
                       

        <div className="mt-4">
          <label className=" text-sm ">Shipment Remark</label>
          <textarea
          {...register("shipment_remark")} 
            rows="3"
            className="w-full border border-gray-300 rounded-md shadow-sm p-2"
          ></textarea>
        </div>

        <div className="flex justify-end mt-4">
       
          <button onClick={()=> navigate(-1)} type="button" className="bg-gray-200 text-gray-700 py-2 px-4 rounded-md mr-2">
            Cancel
          </button>
          <button className="bg-blue-600 text-white py-2 px-4 rounded-md">
             {/* {getPackageEditAtom[0]?.Sales_Order_No ? 'Update': 'Save'} */}
             Save
          </button>
        </div>
      </div>
      </form>
    </div>
  );
}

export default PackageNewShipping;