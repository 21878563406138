import { BehaviorSubject } from "rxjs";

const spinnerObsSubject = new BehaviorSubject(false); 

export const spinnerService = {
    showSpinner,
    disableSpinner,
    spinnerSubject: spinnerObsSubject.asObservable(),
};


async function  showSpinner( ) {
    spinnerObsSubject.next(true);
}

async function  disableSpinner() {
    spinnerObsSubject.next(false);
}


