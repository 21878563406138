import { atom , useSetRecoilState, useRecoilValue} from "recoil";




export const customerRewardListAtom = atom({key:'customer_reward_point',default:[]});


export const SetCustomerRewardAtom = () => useSetRecoilState(customerRewardListAtom);
export const GetCustomerRewardAtom = () => useRecoilValue(customerRewardListAtom);
