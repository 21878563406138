import { Dropdown } from "react-bootstrap";
import React,{useEffect, useState} from "react";
import { itemsSetupService} from '../services/itemSetup.service';
import  {SetProductionItems, GetProductionItems} from '../store/finishItemAtom';
import { Link, useLocation } from "react-router-dom";
import ProductionEntryTable from "../components/ProductionTable";
import Tooltip from '@mui/material/Tooltip';
import {  ResetItemToEdit} from "../store/itemAtom";
import RestartAlt from '@mui/icons-material/RestartAlt';


function ProductionPage() {

    const resetItemToEdit = ResetItemToEdit();
    var itemList = GetProductionItems();
    const [list, setList] = useState(itemList)
    const setItemList = SetProductionItems();
    const { pathname } = useLocation();
    var url = pathname.split("/");
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];
    
  
    useEffect(()=> {
    resetItemToEdit();
        if(itemList.length < 1){
          itemsSetupService.getDropDownList().then(res => {
            if(res?.data.length > 0){
                setItemList(res?.data);
                setList(res?.data);
            }
        });
        }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[itemList]);
  


  const loadDataFromServer = ()=>{
    itemsSetupService.getDropDownList().then(res => {
      if(res?.data.length > 0){
          setItemList(res?.data);
          setList(res?.data);
      }
  });
  }
  
  
  
    function customFilter(filter){
        if(filter === 3 ){
          setList(itemList)
        }else if(filter === 1){
          let newList = itemList?.filter(item =>  item.Item_Variety_Id === filter);
          setList(newList);
        }else if(filter === 2){
          let newList = itemList?.filter(item =>  item.Item_Variety_Id === filter);
          setList(newList);
        }
    }


    return ( 
        <>
           <section className=" py-4 px-2    md:flex justify-between ">
       
       <div className="flex">
       <div className=" mt-1  rounded bg-filterColor pr-4 -ml-2 ">
            <Dropdown>
                <Dropdown.Toggle
                  style={{
                    backgroundColor: "#E5E5E5",
                    border: "none",
                    color: "#45444B",
                    fontSize: "12px",
                    height: "35px",
                  }}
                  id="dropdown-basic"
                >
                  FILTER BY
                </Dropdown.Toggle>
  
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => customFilter(3)}>
                    All
                  </Dropdown.Item>
  
                  <Dropdown.Item onClick={() => customFilter(1)}>
                    Single Items
                  </Dropdown.Item>
  
                  <Dropdown.Item onClick={() => customFilter(2)}>
                    Composite Items
                  </Dropdown.Item>
  
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <Tooltip title="Reload">
            <RestartAlt className="cursor ml-2 mt-1" onClick={()=> loadDataFromServer()} color="primary" fontSize="large" />
          </Tooltip>
       </div>

            
            <div className="flex  ">
              <div className="flex ">
                <button
                  style={{ color: "#476EF8" }}
                  className="  rounded  text-xs py-[1.8vh] font-[Poppins]   px-4"
                >
                  EXPORT
                </button>
              
              </div>
              <Tooltip title="Create new item">
              <Link to={`/${currModuleId}/${currModuleName}/items/add-item/${4}`} >
              <button
                style={{ backgroundColor: "#476EF8" }}
                className="  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
              >
                ADD ITEM
              </button>
              </Link>
              </Tooltip>
           
            </div>
          </section>
  

  
          <section>
            <ProductionEntryTable list={list}></ProductionEntryTable>
          </section>
        </>
     );
}

export default ProductionPage;