import { useEffect, useState } from "react";


function AddonSubPartialSub(props) {
    const [partialYes, setPartialYes] = useState(false); 
    const [partialNo, setpartialNo] = useState(true); 


    useEffect(()=> {
        if(props?.val === 1){
            setPartialYes(true);
            setpartialNo(false);
        }else if(props?.val === 2){
            setPartialYes(true);
            setpartialNo(false);
        }
      },[props?.val])



    const handlePartialYes = () => {
        setPartialYes(true);
        setpartialNo(false);
        props.allowPartial(1);
      };


    const handlePartialNo = () => {
        setPartialYes(false);
        setpartialNo(true);
        props.allowPartial(2);
      };



    return ( 
        <>
         <div className=" text-sm flex">
                     
                     <div className=" flex">
                       <div>
                         {" "}
                         <input
                           type="radio"
                           checked={partialYes}  onChange={handlePartialYes}
                           className="w-[0.8vw] h-[1.6vh]"
                           value="1"
                         />
                       </div>
                       <div className="mt-[0.2vh]">
                         {" "}
                         <span className="text-xs ml-2"> Yes</span>
                       </div>
                     </div>
                     <div className="ml-2 flex">
                       <div>
                         {" "}
                         <input
                      checked={partialNo}  onChange={handlePartialNo}
                           type="radio"
                           className="w-[0.8vw] h-[1.6vh]"
                           value="2"
                         />
                       </div>
                       <div className="mt-[0.2vh]">
                         {" "}
                         <span className="text-xs ml-2">No</span>
                       </div>
                     </div>
                   </div>
        </>
     );
}

export default AddonSubPartialSub;