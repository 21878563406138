import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/delgro_logo.png";

const DelgroLogo = () => {
  return (
    <Link to="/">
      <div className="w-fit flex-shrink-0 cursor-pointer">
        <img src={logo} alt="logo" className="h-12 w-auto" />
      </div>
    </Link>
  );
};

export default DelgroLogo;
