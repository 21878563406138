import { DataGrid ,  gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport ,
    useGridApiContext,
    useGridSelector} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';


  const TableHead = [
    { field: 'Customer_Id', headerName:'Customer Id' , width: 195 , headerClassName: "tableHeader "},
    { field: 'Customer_Full_Name', headerName:'Name' , width: 195 , headerClassName: "tableHeader "},
    { field: 'Customer_Email_Address', headerName:'Email' , width: 195 , headerClassName: "tableHeader "},
     { field: 'Zoho_Wallet_Balance', headerName:'Zoho Balance' , width: 195 , headerClassName: "tableHeader "},
     { field: 'Grocedy_Wallet_Balance', headerName:'Grocedy Balance' , width: 195 , headerClassName: "tableHeader "},
     { field: 'Difference_Balance', headerName:'Difference' , width: 195 , headerClassName: "tableHeader "},
     { field: 'Updated_Date', headerName:'Updated' , width: 195 , headerClassName: "tableHeader "},
  
    ]

    const tableData = [
   
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
      ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }


  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }


function TableTwoBackup(props) {


    return ( 
        <>


<div className="table" style={{ height: 500, width: '100%' }}>
      <DataGrid  
        columns={TableHead}
        rows={props?.list.map((item, i)=>  {return {...item, id:i} } ) }
        pageSize={6}
        rowsPerPageOptions={[6]}
        checkboxSelection={true}
        components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
          }}
          onCellClick={( event) => {
            event.defaultMuiPrevented = true;
            
          }}
          
       
          sx={{
            // boxShadow: 2,
            // border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
              cursor: 'pointer',
            },
          }}
      />
  
    </div>
        
        </>
     );
}

export default TableTwoBackup;