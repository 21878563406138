// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// import "jquery/dist/jquery.min.js";
// import "@popperjs/core";
// import "bootstrap/dist/js/bootstrap.js";
import { BrowserRouter, Router } from "react-router-dom";
import { LogicContextProvider } from "./Context/LogicContext";
import { NotificationContextProvider } from "./Context/NotificationContext";
import {RecoilRoot} from 'recoil';
import { CustomerContextProvider } from "./Context/CustomerContext";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';





const Index = () => {


}



ReactDOM.render(
  
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback} >
    <RecoilRoot>
    <LogicContextProvider>
      <CustomerContextProvider>
      <NotificationContextProvider>
        <BrowserRouter>
          <App ></App>
        </BrowserRouter>
      </NotificationContextProvider>
  </CustomerContextProvider>
  </LogicContextProvider>
    </RecoilRoot>
    </ErrorBoundary>
    </React.StrictMode>,

  document.getElementById("root") || document.createElement('div')
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
