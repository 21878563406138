
import {
    DataGrid, gridPageCountSelector,GridToolbar,
    gridPageSelector, GridToolbarContainer, GridToolbarExport,
    useGridApiContext,
    useGridSelector
} from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useEffect } from "react";
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import Chip from '@mui/material/Chip';


function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            // @ts-expect-error
            rowsPerPageOptions={[6]}
            checkboxSelection={true}
            components={{
                Toolbar: CustomToolbar,
                Pagination: CustomPagination,
              }}
            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}

const TableHead = [
    { field: 'sn', headerName: 'S/N', width: 80, headerClassName: " " },
    { field: 'Corporate_Name', headerName: 'Corporate Name', width: 165, headerClassName: " ", cellClassName:' text-center text-primary font-weight-bold show-cursor' },
    { field: 'Corporate_Short_Code', headerName: 'Short Code', width: 165, headerClassName: " " },
    { field: 'Corporate_Contact_Person', headerName: 'Contact Person', width: 165, headerClassName: " " },
    { field: 'Corporate_Email_Address', headerName: 'Email Address', width: 165, headerClassName: " " },
    { field: 'Corporate_Status_Name', headerName: 'Status', width: 165, headerClassName: " " },
]




function PriceListMainTable(props) {

    const navigate = useNavigate();
    const { pathname } = useLocation();
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];


    useEffect(() => {
        document.title = "Price List";
    }, [props?.list]);


    function OnItemCellClick(event) {
        const id = event.row['Corporate_Id'];
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`);
    }

    return (

        <>
            <div className="mt-5">

                <div className="border-2" style={{ height: 450, width: '100%' }}>
                    <DataGrid
                        columns={TableHead}
                        pageSize={6}
                        rowsPerPageOptions={[5]}
                        
                        rows={props?.list.map((item, i) => {
                            return {
                                ...item, id: i, sn: i + 1,
                            }
                        })}
                        components={{
                            Toolbar: CustomToolbar, Pagination: CustomPagination}}
                            
                        onRowClick={(event) => {
                            OnItemCellClick(event);
                        }}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}

                        sx={{
                            borderColor: 'primary.light',
                            '& .MuiDataGrid-cell:hover': {
                              color: 'primary.main',
                              cursor: 'pointer',
                            },
                          }}

                    />
                </div>
            </div>
        </>
    );
}

export default PriceListMainTable;