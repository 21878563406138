import { useEffect, useState } from 'react';
import Select from 'react-select';
import { GetRecoilBillListData} from "../../store/billsAtom";




function BillSearchItems(props) {
    const masterData = GetRecoilBillListData();
    const [customOption, setCustomOption] = useState([]);
  

    useEffect(()=> {

     const tempData =  masterData['Item']?.map((element)=> {
        return ({...element, label:`${element?.Item_Name}`})
      }
      );
      setCustomOption(tempData)
    },[masterData])


    const handleChange = (item)=> {
        props.currentSelectedItem(item)
    }
  

    return ( 
        <>
        <div className="">
        <small>Select item</small>
        <div >
        <Select  className="lg:w-[38.5vw] "  options={customOption} onChange={handleChange}  />

        </div>
        </div>

        
        </>
     );
}

export default BillSearchItems;
