
import React from "react";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Table } from "react-bootstrap";

function CompositGroupDetailsTable(props) {

  return (
    <>
    
 
      <div className=" ">
        <TableContainer className="mt-3">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#F1F1F1" }}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell >Category</TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="center">Rate</TableCell>
                <TableCell align="center">Amount</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {props?.tableData?.map((item, i) => {
                return (
                  <TableRow 
                  onClick={()=> props.viewItemDetails(item.Item_Id)}
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{item.Item_Id} </TableCell>
                    <TableCell align="left">{item.Item_Name} </TableCell>
                    <TableCell align="left">{item.Item_Sku} </TableCell>
                    <TableCell align="left"> {item.Item_Category_Name}{" "} </TableCell>
                    <TableCell align="center"> {item.Item_Quantity} </TableCell>
                    <TableCell align="center"> {item.Sales_Rate} </TableCell>
                    <TableCell align="center"> {item.Total_Amount} </TableCell>
                   
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

      </div>
    </>
  );
}

export default CompositGroupDetailsTable
;
