import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';


function AlertConfirm(props) {
    const [open, setOpen] = useState(true);



    async function showModalHandler() {
        setOpen(true);
    }


    function closeModalHandler() {
        setOpen(false);
        props.closeAlert(false);
      
       
    }


    return ( 
        <>
        
         <Dialog
        open={open}
        onClose={()=> closeModalHandler()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className=' text-center bg-gray-200'>
          <span className='font-weight-bold '>{props.title}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className='text-center'>
           {props.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions  className=' text-center bg-gray-200'>
          <Button color={'error'} variant="outlined" onClick={()=> {props.closeAlert(false); closeModalHandler() }}>Cancel</Button>
          <Button color={'primary'} variant="contained"  onClick={()=>  {props.closeAlert(true); closeModalHandler() }} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
        </>
     );
}

export default AlertConfirm;