


import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const itemsSetupService = {
    getDropDownList,
    selectItemSetup,
    getFinishItemsDropDown,
    submitItemSetup,
    getProductionDropDown,
    getProductionSideTableData,
    getProductionItemprops,
    submitProduction,
    modifyProduction,
    getProductionHistory,
    getProdMasterdata,
    getCompositesGroupItems,
    getCompositesFormData,
    submitCompositeGroup,
    getItCompositeGroupImages,
    getCompositeInformation,
    getCompositeSellDetails,
    changeGroupStatus,
    compositesUploadImage,
    saveImageProperties,
    getAssociateItemsSelectList,
    submitAssociateItems
 
};




async function  getDropDownList() {
   return axios.get(`/get-item-setup-list`,  authHeader())
}

async function  getProductionDropDown() {
   return axios.get(`/get-production-dropdown`,  authHeader())
}

async function  getProductionSideTableData() {
   return axios.get(`/get-production-side-table-data`,  authHeader())
}


async function  getAssociateItemsSelectList() {
   return axios.get(`/get-associate-items-select-list`,  authHeader())
}


function submitProduction(xml_string, item_props){
   var body = {...xml_string, ...item_props}
  return axios.put('/submit-production', body, authHeader()); 
 }


function modifyProduction(id){
  return axios.get(`/modify-production/${id}`, authHeader()); 
 }


function getProductionHistory(body){
  return axios.put(`/get-production-history`,body,  authHeader()); 
 }

function getProdMasterdata(){
  return axios.get(`/get-production-master-data`,  authHeader()); 
 }


async function  getProductionItemprops(id) {
   return axios.get(`/get-production-item-props/${id}`,  authHeader())
}



async function  selectItemSetup(id) {
   return axios.get(`/select-item-setup/${id}`,  authHeader())
}



async function  getFinishItemsDropDown() {
   return axios.get(`/get-finish-item-drop-down`,  authHeader())
}


function submitItemSetup(xml_string, item_props){
   var body = {...xml_string, ...item_props}
  return axios.put('/submit-item-setup', body, authHeader()); 
 }

function getCompositesGroupItems(){
  return axios.get('/composites-group-items', authHeader()); 
 }

function getCompositesFormData(){
  return axios.get('/composites-select-list', authHeader()); 
 }

function submitCompositeGroup(data){
  return axios.post('/submit-composite-group',data,  authHeader()); 
 }
function submitAssociateItems(data){
  return axios.post('/submit-associate-items',data,  authHeader()); 
 }

 function getItCompositeGroupImages(group_id){
   return axios.get(`/get-composite-group-images/${group_id}`, authHeader())
 }

 function saveImageProperties(body){
   return axios.post(`/save-image-properties`,body, authHeader())
 }

 function compositesUploadImage(body){
   return axios.post(`/composite-image-upload`,body,  authHeader())
 }

 function getCompositeInformation(group_id){
   return axios.get(`/get-composite-group-data/${group_id}`, authHeader())
 }

 function getCompositeSellDetails(group_id){
   return axios.get(`/get-composite-sell-details/${group_id}`, authHeader())
 }

 function  changeGroupStatus( body){
   return axios.put(`/change-composite-group-status`, body, authHeader())
 }










