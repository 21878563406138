import { useEffect, useState } from "react";
import Select from 'react-select';
import { GetSubEntryMasterdata} from "../../store/subscriptionAtom";
import { authenticationService } from "../../services/authentication.service";





function AddSubscriptionadminSelect(props) {
    const getEntryMasterdata = GetSubEntryMasterdata();
    const authUser = authenticationService.currentUserObject();

    useEffect(()=> {

    },[getEntryMasterdata]);


  const  createSelectItems = ()=> {
    let items = [];         
    let currentUser = {}
    getEntryMasterdata?.Officer?.forEach((user, i)=> {
      if(user?.User_Id === authUser?.User_Id){
        currentUser  = user;
      }
      items.push(<option key={i} value={user}>{user.User_Full_Name}</option>)
    });
    props.onAdminSelect(currentUser);
    items.unshift(<option  key={items?.length + 1} value={currentUser?.User_Id}>{currentUser.User_Full_Name}</option>);

    
    return items;
} 



    const handleChange = (value) => {
      props.onAdminSelect(value);
      };


    return ( 
        <>

       <select style={{backgroundColor:'#F1F1F1',}} className="w-full h-[48px] text-sm " onChange={handleChange}>
          {createSelectItems()}
  </select>


        </>
     );
}

export default AddSubscriptionadminSelect;
