import { useEffect, useState } from "react";

function SubscriptionGiftSelect(props) {
    const [isGift, setIsGidt] = useState(false); 
    const [notGift, setNotGidt] = useState(true); 


    useEffect(()=> {
      // props.val
      if(props?.val === 1){
        setIsGidt(true);
        setNotGidt(false);
      }

      if(props?.val === 2){
        setIsGidt(false);
         setNotGidt(true);
      }
    },[props?.val]);


   const handleSetGift = () => {
    setIsGidt(true);
    setNotGidt(false);
    props.onGiftChecked(1);
  };


const handleUnbsetGift = () => {
  setIsGidt(false);
  setNotGidt(true);
  props.onGiftChecked(2)
  };


    return ( 
        <>
   <div className=" text-sm flex">
                     
                     <div className=" flex">
                       <div>
                         {" "}
                         <input
                           type="radio"
                           checked={isGift}  onChange={handleSetGift}
                           className="w-[0.8vw] h-[1.6vh]"
                           value="1"
                         />
                       </div>
                       <div className="mt-[0.2vh]">
                         {" "}
                         <span className="text-xs ml-2"> Yes</span>
                       </div>
                     </div>
                     <div className="ml-2 flex">
                       <div>
                         {" "}
                         <input
                            checked={notGift}  onChange={handleUnbsetGift}
                           type="radio"
                           className="w-[0.8vw] h-[1.6vh]"
                           value="2"
                         />
                       </div>
                       <div className="mt-[0.2vh]">
                         {" "}
                         <span className="text-xs ml-2">No</span>
                       </div>
                     </div>
                   </div>
        </>
     );
}

export default SubscriptionGiftSelect;