import { twMerge } from "tailwind-merge";

function EmailInputComponent(props) {
  return (
    <div className="">
      <label htmlFor="email" className="text-base text-gray-700">
        Email
      </label>
      <input
        type="email"
        id="email_input"
        className={twMerge(
          " w-full py-2 rounded-md border-gray-300 bg-white text-base text-gray-600  placeholder:text-gray-500 placeholder:text-sm focus:ring-[1px] focus:ring-[#486EF8]"
        )}
        placeholder="Enter your email"
        required
        onChange={(e) => props.emailOnchange(e.target.value)}
      />
    </div>
  );
}

export default EmailInputComponent;
