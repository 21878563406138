import { useEffect, useState } from "react";

function CustomerTypeCheck(props) {
  const { customerTypeSelect, defaultVal} = props;
  const [checkBox, setCheckBox ] = useState(null);

  useEffect(()=> {
    if(defaultVal === 1){
        setCheckBox(1)
    }else if(defaultVal === 2){
        setCheckBox(2);
    }else if(defaultVal === 3){
        setCheckBox(3)
    }

  },[defaultVal]);

  const typeSelect = (sel) => {
    setCheckBox(sel);
    customerTypeSelect(sel);
  }



    return (
        <>
         <div className="mt-10 pt-[20vh] ">
                <h4 className="ml-2 text-sm text-filterTextColor">
                  Customer Type:
                </h4>
                <div>
                  <label> 
                     {" "}
                    <input
                    onChange={ (ev)=> typeSelect(ev.target.value)}
                   
                    checked={(checkBox == 1) ?  'checked' : ''}
                      type="radio"
                      id="html"
                      name="Customer_Type_Id"
                      value={1}
                    />
                    <span className="ml-2 text-filterTextColor">Individual</span>
                  </label>

                  <label className="ml-3">
                     {" "}
                    <input
                checked={(checkBox == 2) ?  'checked' : ''}

                    onChange={ (ev)=> typeSelect(ev.target.value)}
                      name="Customer_Type_Id"
                      type="radio"
                      id="html"
                      value={2}
                    />
                    <span className="ml-2 text-filterTextColor">
                      {" "}
                      Business
                    </span>
                  </label>

                  <label className="ml-3">
                     {" "}
                    <input
                    checked={(checkBox == 3) ?  'checked' : ''}
                  
                    onChange={ (ev)=> typeSelect(ev.target.value)}
                      name="Customer_Type_Id"
                      type="radio"
                      id="html"
                      value={3}
                    />
                    <span className="ml-2 text-filterTextColor">
                      {" "}
                      Corporate
                    </span>
                  </label>
                  <br></br>
                  {/* <small className="text-danger">
                    {errors?.Customer_Type_Id &&
                      errors.Customer_Type_Id.message}
                  </small> */}
                </div>
              </div>
        </>
      );
}

export default CustomerTypeCheck;