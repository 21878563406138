import { useEffect, useState } from 'react';
import Select from 'react-select';
import { GetEntryData} from "../../store/purchaseOrderAtom";




function APurchaseDiscountType(props) { 
  const masterData = GetEntryData();
  const [customOption, setCustomOption] = useState([]);


  useEffect(()=> {
   const tempData =  masterData['Transaction_Discount_Type']?.map((element)=> {
      return ({...element, label:`${element?.Purchase_Order_Discount_Type_Name}`})
    }
    );

    setCustomOption(tempData)

  },[masterData])


  
  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      backgroundColor: '#F1F1F1',
      // width: 512,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
    menuPortal:styles => ({ ...styles, zIndex: 9999 })
  }

    const handleChange = (value) => {
      props.onTransDiscountTypeSelect(value)
      };


    return ( 
        <>
   
         <Select    styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal} options={customOption}/>


        </>
     );
}

export default APurchaseDiscountType;
