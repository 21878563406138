import chat from "../../assets/chat.png";
import edit from "../../assets/edit.svg";
import canccel from "../../assets/x-octagon.svg";
// import "./CustomerOverview.css";
import { useState } from "react";
import { imageService } from "../../services/image.service";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import VendorContactPersonModal from "./VendorContactPersonModal";
import CurrencyFormat from "react-currency-format";
import VendorImageComponent from "./VendorImageComponent";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function VendorOverview(props) {
  const { vendorDetails } = props;
  const [addressIsVisible, setVisible] = useState(false);
  const [shippingIsVisible, setShVisible] = useState(false);
  const [otherAddress, setOtherAddress] = useState(false);
  const [customeAddress, setCustomAddress] = useState(false);
  const [location, setLocation] = useState(false);

  const Image = <img src={edit} alt="" />;
  const Cancel = <img src={canccel} alt="" />;
  const CancelShip = <img src={canccel} alt="" />;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <section className=" mt-1 right-1 border-2  md:  " style={{}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Item>
                    {" "}
                    <section className=" border-2 mt-2 mx-3 px-2">
                      <Grid item xs={6}>
                        {/*  start */}
                        <Grid container className="p-2" spacing={2}>
                          <Grid item xs={6}>
                            <VendorImageComponent
                              vendor_id={vendorDetails?.Vendor_Id}
                              format={vendorDetails?.Vendor_Image_Format}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <div>
                              <span className="font-bold text-sm">
                                {vendorDetails?.Vendor_Full_Name}
                              </span>
                              <br></br>
                              <span className="text-sm">
                                {" "}
                                {vendorDetails?.Vendor_Email_Address}
                              </span>{" "}
                              <br></br>
                              <span className="text-sm">
                                {vendorDetails?.Vendor_Mobile_No}
                              </span>
                              <div className="flex  justify-between w-full">

                                <button onClick={() => props?.EditVendorDetails()}
                                  style={{ backgroundColor: "GREEN", color: "white"}}
                                  className="py-2 px-2 font-bold my-3 mr-1 rounded text-xs">
                                  EDIT
                                </button>

                                <button style={{ backgroundColor: "RED", color: "white"}}
                                  onClick={() => props?.deleteVendor()}
                                  className="py-2 px-2 font-bold my-3 ml-1 rounded text-xs" >
                                  DELETE
                                </button>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        {/*  col 1 */}
                        {/* <div className="flex justify-between mt-3">
                            <div className="mb-3">
                            <VendorImageComponent vendor_id={props?.vendorDetails?.Vendor_Id} />
                            </div>
                           

                           
                          </div> */}

                        {/*  cut out */}
                      </Grid>
                    </section>
                    <section className="mx-2">
                      <Grid spacing={2}>
                        <section className="border-2  mx-2 my-4">
                          <Grid spacing={2}>
                            <Grid xs={12}>
                              <Item>
                                {" "}
                                <div className="grid grid-col-4  py-2 px-3   justify-center grid-flow-col gap-1 mt-2 sm:gap-5 justify-between  ">
                                  <div>
                                    <p className="text-sm">
                                      Outstanding <br></br>Payables
                                    </p>
                                    <span className="font-bold">
                                      {"\u20A6"}0.00
                                    </span>
                                  </div>
                                  <div>
                                    <p className="text-sm">
                                      Unused <br></br>Credits
                                    </p>
                                    <span className="font-bold">
                                      {"\u20A6"}0.00
                                    </span>
                                  </div>
                                  <div>
                                    <p className="text-sm">
                                      Payment <br></br>due period
                                    </p>
                                    <span className="font-bold ">
                                      Payment Terms
                                    </span>
                                  </div>
                                </div>
                              </Item>
                            </Grid>
                            <hr className="w-95 ml-3 "></hr>

                            <Grid xs={12}>
                              <Item>
                                {" "}
                                <div className="grid grid-col-2 px-3 grid-flow-col justify-center gap-1 mt-2 sm:justify-between px-2">
                                  <div>
                                    <p className="text-sm">
                                      Items to be received:
                                      <span
                                        className="font-bold ml-2"
                                        style={{ color: "#476EF8" }}
                                      >
                                        0
                                      </span>{" "}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="text-sm">
                                      Total Items Ordered:{" "}
                                      <span
                                        className="font-bold ml-2"
                                        style={{ color: "#476EF8" }}
                                      >
                                        0
                                      </span>{" "}
                                    </p>
                                  </div>
                                </div>
                              </Item>
                            </Grid>
                          </Grid>
                        </section>
                        {/* ADDRESS INFORMATION */}
                        <section className="mt-4 information">
                          <Grid  spacing={2}>
                            
                            <Grid  className=" border-2 py-3 px-2  mx-2" xs={12}>
                              <Grid container spacing={2} className="  mt-2  " >
                                <Grid  xs={6}>
                                   <div className="text-xs mx-4  ">
                                    <div>
                                      <h1
                                        className="text-xs sm:text-sm font-bold  "
                                        style={{ color: "#476EF8" }}
                                      >
                                        Address
                                      </h1>
                                      <span
                                        onClick={() =>
                                          setVisible(!addressIsVisible)
                                        }
                                        className="text-xs sm:text-sm flex font-bold flex "
                                      >
                                        {addressIsVisible
                                          ? " Billing Address:"
                                          : " Billing Address:"}

                                        <span className="mt-1 ml-1 w-3">
                                          <span>
                                            {addressIsVisible ? Cancel : Image}
                                          </span>
                                        </span>
                                      </span>{" "}
                                      {addressIsVisible && (
                                        <div>
                                          {" "}
                                          <div className="text-xs sm:text-sm">
                                            <span className="label">
                                              Attention:
                                            </span>
                                            <span className="text-xs">
                                              {" "}
                                              {
                                                vendorDetails?.Billing_Attention
                                              }{" "}
                                            </span>
                                          </div>
                                          <div className="text-xs sm:text-sm">
                                            <span className="label">
                                              Address :
                                            </span>
                                            <span className="text-xs">
                                              {vendorDetails?.Billing_Address1}
                                            </span>
                                          </div>
                                          <div className="text-xs sm:text-sm">
                                            <span className="label">
                                              Address 2:
                                            </span>
                                            <span className="text-xs">
                                              {vendorDetails?.Billing_Address2}
                                            </span>
                                          </div>
                                          <div className="text-xs sm:text-sm">
                                            <span className="label">
                                              Country:
                                            </span>
                                            <span className="text-xs">
                                              {" "}
                                              {
                                                vendorDetails?.Billing_Country_Name
                                              }{" "}
                                            </span>
                                          </div>
                                          <div className="text-xs sm:text-sm">
                                            <span className="label">
                                              State:
                                            </span>
                                            <span className="text-xs">
                                              {" "}
                                              {
                                                vendorDetails?.Billing_City_Name
                                              }{" "}
                                            </span>
                                          </div>
                                          <div className="text-xs sm:text-sm">
                                            <span className="label">
                                              Zip Code:
                                            </span>

                                            <span className="text-xs">
                                              {vendorDetails?.Billing_Zip_Code}{" "}
                                            </span>
                                          </div>
                                          <div className="text-xs sm:text-sm">
                                            <span className="label">Area:</span>
                                            <span className="text-xs">
                                              {vendorDetails?.Billing_Area_Name}{" "}
                                            </span>
                                          </div>
                                          <div className="text-xs sm:text-sm">
                                            <span className="label">Fax:</span>
                                            <span className="text-xs">
                                              {" "}
                                              {
                                                vendorDetails?.Billing_Fax_Number
                                              }{" "}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {/* SHIPPING ADDRESS */}
                                    <div className="">
                                      <i className="bi bi-arrow-down-short"></i>
                                      <span
                                        onClick={() =>
                                          setShVisible(!shippingIsVisible)
                                        }
                                        className="text-xs flex sm:text-sm font-bold  "
                                      >
                                        <span>
                                          {shippingIsVisible
                                            ? "Shipping Address"
                                            : " Shipping Address"}
                                        </span>
                                        <span className="mt-2 ml-1 w-3">
                                          <span>
                                            {shippingIsVisible ? Cancel : Image}
                                          </span>
                                        </span>
                                      </span>{" "}
                                      {shippingIsVisible && (
                                        <div>
                                          {" "}
                                          <div className="text-xs sm:text-sm">
                                            <span className="label font-bold">
                                              Attention:{" "}
                                            </span>
                                            <span className="text-xs">
                                              {
                                                vendorDetails?.Shipping_Attention
                                              }{" "}
                                            </span>
                                          </div>
                                          <div className="text-xs sm:text-sm">
                                            <span className="label">
                                              Address 1:
                                            </span>
                                            <span className="text-xs">
                                              {vendorDetails?.Shipping_Address1}
                                            </span>
                                          </div>
                                          <div className="text-xs sm:text-sm">
                                            <span className="label">
                                              Address 2:
                                            </span>

                                            <span className="text-xs">
                                              {vendorDetails?.Shipping_Address2}
                                            </span>
                                          </div>
                                          <div className="text-xs sm:text-sm">
                                            <span className="label">
                                              Country:
                                            </span>

                                            <span className="text-xs">
                                              {
                                                vendorDetails?.Shipping_Country_Name
                                              }{" "}
                                            </span>
                                          </div>
                                          <div className="text-xs sm:text-sm">
                                            <span className="label">
                                              State:
                                            </span>

                                            <span className="text-xs">
                                              {
                                                vendorDetails?.Shipping_City_Name
                                              }{" "}
                                            </span>
                                          </div>
                                          <div className="text-xs sm:text-sm">
                                            <span className="label">
                                              Zip Code:
                                            </span>

                                            <span className="text-xs">
                                              {vendorDetails?.Shipping_Zip_Code}{" "}
                                            </span>
                                          </div>
                                          <div className="text-xs sm:text-sm">
                                            <span className="label">Area:</span>

                                            <span className="text-xs">
                                              {
                                                vendorDetails?.Shipping_Area_Name
                                              }{" "}
                                            </span>
                                          </div>
                                          <div className="text-xs sm:text-sm">
                                            <span className="label">Fax:</span>

                                            <span className="text-xs">
                                              {
                                                vendorDetails?.Shipping_Fax_Number
                                              }{" "}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {/* LOCATION 2 */}

                                    <div className="text-xs">
                                      <span
                                        onClick={() => setLocation(!location)}
                                        className="text-xs flex sm:text-sm font-bold "
                                      >
                                        {location ? "Location:" : "Location:"}
                                        <span className="mt-1 ml-1 w-3">
                                          <span>
                                            {location ? CancelShip : Image}
                                          </span>
                                        </span>
                                      </span>

                                      {location && (
                                        <div>
                                          <label htmlFor="">
                                            <div className="text-xs sm:text-sm ">
                                              <span className="label">
                                                Branch:
                                              </span>

                                              <span className="text-xs ">
                                                Display Branch
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm ">
                                              <span className="label">
                                                Api:
                                              </span>

                                              <span className="text-xs">
                                                Display API
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-xs ">
                                              <span className="label">
                                                Latitude:
                                              </span>

                                              <span className="text-xs">
                                                {vendorDetails?.GPS_Latitude}{" "}
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-xs ">
                                              <span className="label">
                                                Longitude:
                                              </span>

                                              <span className="text-xs">
                                                {vendorDetails?.GPS_Longitude}{" "}
                                              </span>
                                            </div>
                                          </label>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                               
                                </Grid>

                                <Grid xs={6}>
                                  <Item> 
                             <section className="flex justify-center ">
                             <div className="text-xs ">
                                    <div>
                                      <h1
                                        className="text-xs sm:text-sm font-bold "
                                        style={{ color: "#476EF8" }}
                                      >
                                        Other Address
                                      </h1>
                                      <div className="text-xs sm:text-sm ">
                                        <span className="label">
                                          Customer Id:
                                        </span>

                                        <span className="font-fold">
                                          {vendorDetails?.Customer_Id}{" "}
                                        </span>
                                      </div>
                                      <div className="text-xs sm:text-sm">
                                        <span className="label">
                                          Customer Type:
                                        </span>
                                        <span className="">
                                          {vendorDetails?.Customer_Type_Name}
                                        </span>
                                      </div>

                                      <div className="text-xs sm:text-sm ">
                                        <span className="label">
                                          {" "}
                                          Currency Code:
                                        </span>
                                        <span className="">
                                          {vendorDetails?.Currency_Symbol}
                                        </span>
                                      </div>

                                      <div className="text-xs sm:text-sm ">
                                        <span className="label">Source:</span>

                                        <span className="">API</span>
                                      </div>
                                      <div className="mt-2">
                                        <span
                                          onClick={() =>
                                            setOtherAddress(!otherAddress)
                                          }
                                          className="text-xs sm:text-sm flex font-bold  "
                                        >
                                          {otherAddress ? "Other:" : "Other"}

                                          <span className="mt-1 ml-1 w-3">
                                            <span>
                                              {otherAddress
                                                ? CancelShip
                                                : Image}
                                            </span>
                                          </span>
                                        </span>{" "}
                                        {otherAddress && (
                                          <div>
                                            {" "}
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                Currency:
                                              </span>

                                              <span className="text-xs">
                                                {" "}
                                                {
                                                  vendorDetails?.Currency_Name
                                                }{" "}
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                Payment Terms:
                                              </span>

                                              <span className="text-xs">
                                                {
                                                  vendorDetails?.Payment_Term_Name
                                                }
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                Country:
                                              </span>

                                              <span className="text-xs">
                                                {" "}
                                                Display Country
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                Price List:
                                              </span>

                                              <span className="text-xs">
                                                {vendorDetails?.Price_List_Name}{" "}
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                Website:
                                              </span>

                                              <span className="text-xs">
                                                {" "}
                                                Display Website
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                Facebook:
                                              </span>

                                              <span className="text-xs">
                                                {vendorDetails?.Facebook_URL}{" "}
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                Twitter:
                                              </span>

                                              <span className="text-xs">
                                                {vendorDetails?.Twitter_URL}{" "}
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                Remark:
                                              </span>

                                              <span className="text-xs">
                                                {vendorDetails?.Customer_Remark}{" "}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>

                                      <div className="mt-2">
                                        <span
                                          onClick={() =>
                                            setCustomAddress(!customeAddress)
                                          }
                                          className="text-xs flex  sm:text-sm font-bold  "
                                        >
                                          {customeAddress
                                            ? " Custom Feild:"
                                            : "Custom Feild"}
                                          <span className="mt-1 ml-1 w-3">
                                            <span>
                                              {customeAddress
                                                ? CancelShip
                                                : Image}
                                            </span>
                                          </span>
                                        </span>{" "}
                                        {customeAddress && (
                                          <div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                Religion:
                                              </span>

                                              <span className="text-xs">
                                                {vendorDetails?.Religion_Name}{" "}
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                Bustop:
                                              </span>

                                              <span className="text-xs">
                                                {" "}
                                                Display Bustop
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                Referred Person:
                                              </span>

                                              <span className="text-xs">
                                                Display Referred Person
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                State:
                                              </span>

                                              <span className="text-xs">
                                                Display State
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                Date of Birth:
                                              </span>

                                              <span className="text-xs">
                                                {" "}
                                                Display Date of Birth
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                Age:
                                              </span>

                                              <span className="text-xs">
                                                {" "}
                                                Display Age
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                Profession:
                                              </span>

                                              <span className="text-xs">
                                                {" "}
                                                Display Profession
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                              Referred Email:
                                              </span>

                                              <span className="text-xs">
                                                Display Email
                                              </span>
                                            </div>
                                            <div className="text-xs sm:text-sm">
                                              <span className="label">
                                                Address:
                                              </span>

                                              <span className="text-xs">
                                                {" "}
                                                Display Address
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                             </section>
                                  
                                  </Item>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Item>
                                {" "}
                                <section className="mt-4 information    border-2 py-3 px-2">
                                  <div className="mb-5">
                                    <h1
                                      className="text-xs sm:text-sm  "
                                      style={{ color: "#476EF8" }}
                                    >
                                      ADD NEW INVOICE
                                    </h1>
                                  </div>

                                  <div className="mb-5">
                                    <h1
                                      className="text-xs sm:text-sm font-bold "
                                      style={{ color: "#476EF8" }}
                                    >
                                      <VendorContactPersonModal
                                        customerData={vendorDetails}
                                      />
                                    </h1>
                                  </div>

                                  <div className="h-40 mb-5">
                                    <h1
                                      className="text-xs sm:text-sm"
                                      style={{ color: "#476EF8" }}
                                    >
                                      ADD CONTACT
                                    </h1>
                                  </div>
                                </section>
                              </Item>
                            </Grid>
                            <Grid item xs={12}>
                              <Item>
                                <section className="mt-4 information border-2 py-3 px-2 mb-3">
                                  <div className="flex mt-2  ">
                                    <div className="text-xs sm:text-xs  w-40">
                                      <span className="label">Time:</span>

                                      <span className="text-xs ml-2">
                                        Display Time
                                      </span>
                                    </div>

                                    <div className="text-xs ">
                                      <label className="">
                                        <img
                                          src={chat}
                                          alt=""
                                          className="inline-block"
                                        />
                                        <span className="ml-2 label">
                                          Bill updated
                                        </span>
                                        <p className="text-xs">
                                          Bill 000701 marked as void by Lanre
                                          Smith
                                          <br></br>
                                          <i style={{ color: "#4999F6" }}>
                                            View Details
                                          </i>
                                        </p>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="flex mt-2  ">
                                    <div className="text-xs sm:text-xs  w-40">
                                      <span className="label ">Time:</span>

                                      <span className="text-xs ml-2">
                                        Display Time
                                      </span>
                                    </div>

                                    {/* <div className="text-xs ">
                                      <label className="">
                                        <img
                                          src={chat}
                                          alt=""
                                          className="inline-block"
                                        />
                                        <span className="ml-2 label">
                                          Contact Added
                                        </span>
                                        <p className="text-xs">
                                          Estimate added Estimate EST-000886 of{" "}
                                          <br></br> amount ₦4,547,350.00 created
                                          by Femi Paul
                                        </p>
                                      </label>
                                    </div> */}
                                  </div>
                                </section>
                              </Item>
                            </Grid>
                          </Grid>
                        </section>
                      </Grid>
                    </section>
                  </Item>
                </Grid>
              </Grid>
            </section>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}

export default VendorOverview;
