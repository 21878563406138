import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';

  
   function EditProductConfirm({ isOpen, onResolve, onReject}) {
    const [open, setOpen] = useState(true);



    async function showModalHandler() {
        setOpen(true);
    }


    function closeModalHandler() {
        setOpen(false);
      
       
    }
  
    return (
        <Dialog
        open={open}
        onClose={()=> onReject()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className=' text-center bg-gray-200'>
          <span className='font-weight-bold '> Add or modify record</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className='text-center'>
           {/* {body} */}
           Please select an option below
          </DialogContentText>
        </DialogContent>
        <DialogActions  className=' text-center bg-gray-200'>
          <Button color={'primary'} variant="outlined"  onClick={() => onResolve(1)}>Add New</Button>
          <Button color={'primary'} variant="contained"  onClick={() => onResolve(2)} >
            Edit
          </Button>
          <Button color={'error'} variant="contained"  onClick={()=> onReject()} >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
        
    );
  }
   
  
  export default  EditProductConfirm;