import { useEffect, useRef, useState } from "react";

import CurrencyFormat from "react-currency-format";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { GetPackageListAtom } from "../../store/packageStore";
import { useLocation, useNavigate } from 'react-router-dom';
import PackageFilter from "./PackageFilter";


function PackageScroll(props) {
  const getPackageListAtom = GetPackageListAtom();
  const mountRef = useRef(true);
  const [tableData, setTableData] = useState([]);
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const navigate = useNavigate();



  useEffect(() => {
    mountRef.current = true;
    const tempList =  getPackageListAtom[type];
    if(tempList?.length){
      setTableData(tempList);
    }

    return () => {
      mountRef.current = false;
    };
  }, [type,getPackageListAtom ]);


  const onFilterSelect = (selection)=> {
    const tempList =  getPackageListAtom[selection];
    if(tempList?.length){
      setTableData(tempList);
    }
  }

  const managePurchaseStatus = (status, id) => {
    let defaultClass = "color1";

    switch (id) {
      case 1:
        defaultClass = "color2";
        break;
      case 2:
        defaultClass = "color3";
        break;
      case 3:
        defaultClass = "color4";
        break;
      case 4:
        defaultClass = "color5";
        break;
      case 5:
        defaultClass = "color6";
        break;
      case 6:
        defaultClass = "color1";
        break;
      case 7:
        defaultClass = "color8";
        break;

      default:
        break;
    }

    return (
      <span className={`text-sm font-medium ${defaultClass} `}>{status}</span>
    );
  };

  const viewDetails = (id) => {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}?type=${type}`
    );
  };


  return (
    <>
      <section className="border-[1px] rounded-lg bg-white border-gray-200 flex flex-col overflow-y-scroll h-[300px] lg:h-[1000px] scrollbar-thumb-gray-500 scrollbar-thin scrollbar-track-gray-100 pb-[100px]">
        <div className="border-b-[1px] border-b-gray-300">
          <PackageFilter onFilterSelect={onFilterSelect}
          />
        </div>
        <div
          className={twMerge(
            `p-2 lg:p-1 grid grid-cols-2 lg:grid-cols-none  border-b-[1px] cursor-pointer border-gray-200`
          )}
        >
        
        </div>
        <div>
          {tableData?.map((pkg, i) => {
            return (
              <div
                key={i}
                onClick={() => viewDetails(pkg?.Sales_Order_Ref_No)}
                className={twMerge(
                  `p-2 lg:p-1 grid grid-cols-2 lg:grid-cols-none  border-b-[1px] cursor-pointer border-gray-200 ${
                    Number(id) === pkg?.Sales_Order_Ref_No
                      ? "bg-gray-200"
                      : "hover:bg-gray-100"
                  }`
                )}
              >
                <div className="flex flex-row" >
             

                  <div className="ml-1" >
                    <div >
                      <span className="text-sm font-bold text-gray-700">
                        {pkg?.Customer_Full_Name}
                      </span>{" "}
                      <br></br>
                      <span className="text-xs text-gray-400 font-semibold italic">
                        {pkg?.Sales_Order_No}
                      </span>
                      <br></br>
                      <span className="text-sm lg:text-xs font-medium text-gray-600">
                        <CurrencyFormat
                          value={parseFloat(pkg?.Sales_Order_Amount)?.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦"}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="lg:mt-2 lg:ml-5 ">
                  <div className={twMerge("text-sm font-normal")}>
                    {managePurchaseStatus(pkg?.Sales_Order_Status_Name, pkg.Sales_Order_Status_Id)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default PackageScroll;
