
// import { DataGrid ,  gridPageCountSelector,
//     gridPageSelector, GridToolbarContainer, GridToolbarExport ,
//     useGridApiContext,
//     useGridSelector} from "@mui/x-data-grid";
import React,{ useCallback, useEffect, useState}  from 'react';
import {
  DataGrid} from "@mui/x-data-grid";

import {productService} from '../services/product.service';
import {  useParams } from "react-router-dom";
import underscore from 'underscore';
import { Table } from "react-bootstrap";
import { GetAssoPlanTable, ResetAssoPlanTable, SetAssoPlanTable} from "../store/productAtom";



const TableHead = [
  { field: 'Plan_Id', headerName: 'Id', width: 195, headerClassName: " " },
  { field: 'Plan_Name', headerName: 'Name', width: 195, headerClassName: " " },
]


function AddonAssociates(props) {

    const { product_id } = useParams();
    const tableData =  GetAssoPlanTable();
    const setTableData =  SetAssoPlanTable();



  
  useEffect(()=> {
    if(props?.tempTableList?.length){
      setTableData(props?.tempTableList)
    }
    
    setTimeout(() => {
      if(props?.tempTableList  === undefined){
        if(product_id !== undefined ){
          productService.getProductByPlan(product_id).then(res => {
            if(res?.data !== undefined){
              let uniqueList = underscore.uniq(res?.data, ele => ele.Item_Id)
              setTableData(uniqueList); 
            }
          });
        }
       
      }
    }, 2000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[product_id,props?.tempTableList])






  function onSelectChange(selectedList){
    var selection = selectedList?.map((o) => {return tableData[o]})
    props.onSelectedAssociatesItems(selection);
}




  return (
    <>
      <div className="">
        <div style={{ height: 245, width: '85%' }}>
        <div className="text-sm pb-2"> Associated Plans</div>
          <DataGrid
            columns={TableHead}
            rows={tableData?.map((item, i)=> { return ({...item, id:i}) })}
       
            checkboxSelection={true}
            onRowSelectionModelChange={onSelectChange}
          />
        </div>
      </div>

      {/* <Table style={{ height: 245, width: '90%' }} striped bordered hover size="sm">
  <thead>
    <tr>
      <th><input type="checkbox"   value="1" /> </th>
      <th>First Name</th>
      <th>Last Name</th>
      <th>Username</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><input type="checkbox"   value="1" /></td>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
    </tr>
    <tr>
      <td><input type="checkbox"   value="1" /></td>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
    </tr>
 
  
  </tbody>
</Table> */}


    </>
  );
}

export default AddonAssociates;