
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const priceService = {
     getPriceList,
     getPriceDetails,
     updateSingleItem,
     updateStatus
    
};





async function  getPriceList() {
    return  axios.get(`/inventory/price-list`, authHeader());
}

async function  getPriceDetails(id, product_id) {
    return  axios.get(`/inventory/price-details/${id}/${product_id}`, authHeader());
}
async function  updateSingleItem(data) {
    return  axios.put(`/inventory/update-single-item`, data, authHeader());
}


async function  updateStatus(data) {
    return  axios.put(`/inventory/update-single-status`, data, authHeader());
}









