import React, { Component } from 'react';
import BillsOverview from './BillsOverview';



export class BillWrapperComponent extends React.Component {


    render() {
        return (
           <BillsOverview billDetails={this.props.billDetails} viewVendor={this.props?.viewVendor} />
        )
    }
}