
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';



export const corporateService = {
    getCorporateList,
    getCorporateListNoCache,
    getCorporateDetails,
    createCorporation,
    getCorporateListSearch,
    getCustomerApprovedList,
    getCustomerApproveDetails,
    approveOrCancelCustomerRequest,
    uploadFile,
    getCorporateApprovedList,
    approveOrCancelCorporateRequest,
    toggleAdminStatus
};


function getCorporateList(start= 0,end=50){
  return axios.get(`/subscriptions/get-corporation-list?start=${start}&end=${end}`, authHeader());
} 


function uploadFile(fileData){
  return axios.post(`/external-grocedy/upload-files`, fileData);
} 

function getCorporateListSearch(search,start= 0,end=50){
  return axios.get(`/subscriptions/get-corporation-list-search?start=${start}&end=${end}&search=${search}`, authHeader());
} 


function getCorporateListNoCache(start= 0,end=50){
  return axios.get(`/subscriptions/get-corporation-list-no-cache?start=${start}&end=${end}`, authHeader());
} 

function getCorporateDetails(id){
  return axios.get(`/subscriptions/get-corporation-details/${id}`, authHeader());
}

function toggleAdminStatus(statusId, customer_id){
  return axios.get(`/subscriptions/toggle-admin-status/${statusId}/${customer_id}`, authHeader());
}

function createCorporation(data){
  return axios.post(`/subscriptions/create-corporation`,data, authHeader());
}


function getCustomerApprovedList(start= 0,end=50){
  return axios.get(`/subscriptions/get-customer-app-list?start=${start}&end=${end}`, authHeader());
} 

function getCorporateApprovedList(){
  return axios.get(`/subscriptions/get-corporate-approval-list`, authHeader());
} 



function getCustomerApproveDetails(id){
  return axios.get(`/subscriptions/get-customer-approve-details/${id}`, authHeader());
}

function approveOrCancelCustomerRequest(id,option, remark){
  return axios.get(`/subscriptions/approve-or-cancel-request/${id}/${option}/${remark}`, authHeader());
}

function approveOrCancelCorporateRequest(id,option, remark){
  return axios.get(`/subscriptions/approve-or-cancel-corporate-request/${id}/${option}/${remark}`, authHeader());
}









