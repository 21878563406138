import React, { useEffect, useRef, useState, useContext } from "react";
import { Grid } from "@mui/material";

import { useParams } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import LogicContext from "../../Context/LogicContext";
import PriceListScroll from "./PriceListScroller";
import { priceService } from "../../services/priceService";
import PriceListContent from "./PriceListContent";
import PriceDetailsTable from "./PriceDetailsTable";
import { GetPriceDataStore } from "../../store/priceListAtom";





const PriceListDetailsPage = () => {
    const { id } = useParams();
    const [corporateDetail, setCorDetails] = useState({});
    const [storeData, setStoredData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [productCatId, setProductCategoryId] = useState(null);

    const myPromiseModal = createModal(GeneralModal);
    const logicContext = useContext(LogicContext);
    const getPriceData = GetPriceDataStore();


    console.log('getPriceData', getPriceData);
    useEffect(() => {
        document.title = "Price Details";
        const corData = getPriceData.coporate_info?.filter((cop) => cop.Corporate_Id === Number(id));
        if (corData?.length > 0) {
            setCorDetails(corData[0]);
        }

        return () => {
            //code here
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);



    const loadPriceDetails = (id, product_id) => {
        setTableData([]);
        priceService.getPriceDetails(id, product_id).then(res => {
            if (res?.data?.length) {
                setTableData(res.data);
                setStoredData(res.data);
            } else {
                logicContext.showToast('No data for selected item', '');
                setTableData([]);
                setStoredData([]);
            }

        });
    }


    const onProductSelect = (data) => {
        setProductCategoryId(data?.Product_Id);
        loadPriceDetails(id, data?.Product_Id);
    }


    const onMarketValueChange = (newValue, product, index) => {
        console.log('Market)val ', newValue);
        let productData = { ...product, Item_Rate_Market: Number(newValue ? newValue : 0)};
        
       
               let newList = tableData?.map( (tempProd)=> {
                    if(tempProd.Item_Id === productData.Item_Id ){
                        return productData;
                    }else{
                        return tempProd;
                    }
                });
                setTableData(newList);
       
    }



    const onGrocedyValueChange = (newValue, product, index) => {
      console.log('newValue ', newValue);
        let productData = { ...product, Item_Rate_Zoho: Number(newValue ? newValue : 0)};
       
               let newList = tableData?.map( (tempProd)=> {
                    if(tempProd.Item_Id === productData.Item_Id ){
                        return productData;
                    }else{
                        return tempProd;
                    }
                });
                setTableData(newList);
       
    }



    const onGrocedyPercentChange = (newValue) => {
        if(Number(newValue) < 0){
            logicContext.showToast('Discount percent cannot be Negative!', 'info');
            return;
        }
        if(newValue){
                   
                console.log('Prev_List ', storeData?.length);
               let newList = storeData?.map((tempProd) => {
    // Ensure newValue and tempProd.Item_Rate_Zoho are valid numbers
    const itemRate = Number(tempProd.Item_Rate_Zoho) || 0;
    const percentRate = (Number(newValue) || 0) / 100 * itemRate;

    return { ...tempProd, Item_Rate_Zoho: itemRate - percentRate };
});
                console.log('New_list ', newList);
                setTableData(newList);
        

        }else{
            loadPriceDetails(id, productCatId);
        }
 
    }



    const sendSingleItem = async (index, product) => {
        console.log(index, product);
        await myPromiseModal({title: `Save ${product?.Item_Name}`, body: " Do you want to save this record?",
        showInput:false, placeHolder:''}).then((res) => {
          if (res.decision === true) {
            let temp =`<a><sku>${product.Item_Id}</sku><market_rate>${product?.Item_Rate_Market}</market_rate><grocedy_rate>${product?.Item_Rate_Zoho}</grocedy_rate></a>`;
          
                priceService.updateSingleItem({corporate_id: id,product_id: productCatId, xml_string_item: `<root>${temp}</root>` }).then(res => {
                    console.log('ressss ', res.data);
                    if(res.data?.length){
                        if(res.data[0]?.message_id === 1){
                            logicContext.showToast(res.data[0]?.message_text,'success');
                           return loadPriceDetails(id, productCatId);
                        }
                    }
                });
          }
        }).catch((err) => {
          console.log('form error ',err)
          logicContext.showToast("error submitting record", "error",);
        })

    }


    const sendAllProductItems = async (searchList) => {
        await myPromiseModal({title: `Save ${ searchList.length ? searchList?.length : tableData?.length }  items`, body: " Do you want to save these record?",
        showInput:false, placeHolder:''}).then((res) => {
          if (res.decision === true) {
            let temp =``;

            if(searchList.length > 0){
                // Update all for search List.
                let example =  searchList?.map((product) => {
                    temp  +=`<a><sku>${product.Item_Id}</sku><market_rate>${product?.Item_Rate_Market}</market_rate><grocedy_rate>${product?.Item_Rate_Zoho}</grocedy_rate></a>`;
                    return temp;
                });
    
                priceService.updateSingleItem({corporate_id: id,product_id: productCatId, xml_string_item: `<root>${temp}</root>` }).then(res => {
                    if(res.data?.length){
                        if(res.data[0]?.message_id === 1){
                           logicContext.showToast(res.data[0]?.message_text,'success');
                           return loadPriceDetails(id, productCatId);
                        }
                    }
                });

            }else{
                // update all for general list
                let example =  tableData?.map((product) => {
                    temp  +=`<a><sku>${product.Item_Id}</sku><market_rate>${product?.Item_Rate_Market}</market_rate><grocedy_rate>${product?.Item_Rate_Zoho}</grocedy_rate></a>`;
                    return temp;
                });
    
                priceService.updateSingleItem({corporate_id: id,product_id: productCatId, xml_string_item: `<root>${temp}</root>` }).then(res => {
                    if(res.data?.length){
                        if(res.data[0]?.message_id === 1){
                           logicContext.showToast(res.data[0]?.message_text,'success');
                           return loadPriceDetails(id, productCatId);
                        }
                    }
                });

            }

     
           
          }
        }).catch((err) => {
          console.log('form error ',err)
          logicContext.showToast("error submitting record", "error",);
        })

    }



    const updateStatus = async(item)=> {
        let option = 5;
        switch (productCatId) {
            case 3:
                option = 5
                break;
            case 1:
                option = 6
                break;
            case 2:
                option = 6
                break;
        
            default:
                break;
        }
        const res =  await priceService.updateStatus({...item, corporate_id: id, option:option});
        console.log('REs   ', res.data);
        loadPriceDetails(id, productCatId);
    }






    return (
        <>
            <section className="  mt-2 ">
                <Grid container className="" spacing={2}>
                    <Grid item xs={12}>
                        <section className="max-auto max-w-6xl ">
                            <div className="flex flex-wrap justify-between  ">
                         
                            </div>
                        </section>
                    </Grid>

                    <Grid item xs={12}>
                        <section className=" ">
                            <Grid container className="" spacing={0}>
                                <Grid className="" item xs={2.8}>
                                    <PriceListScroll />
                                </Grid>
                                <Grid item xs={9.2}>
                                    <section className="w-full  z-50 ">

                                        <section className="   ">
                                            <PriceListContent data={corporateDetail} />
                                            <PriceDetailsTable  updateStatus={updateStatus}  productCatId={productCatId} cor_id={id} onProductSelect={onProductSelect}  sendSingleItem={sendSingleItem}  onMarketValueChange={onMarketValueChange} 
                                                onGrocedyValueChange={onGrocedyValueChange} sendAllProductItems={sendAllProductItems}  data={tableData}
                                                onGrocedyPercentChange={onGrocedyPercentChange} />
                                        </section>

                                    </section>
                                </Grid>
                            </Grid>

                            {/* w-[56.3vw] */}
                        </section>
                    </Grid>
                </Grid>
            </section>


        </>
    );
};

export default PriceListDetailsPage;
