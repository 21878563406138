

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";



const corporateApprovedAtom = atom({key:'corporateApprovedAtom', default:{
Area:[],City:[], Corporate_Type:[], Corporate_officer_type:[], 
Country:[], Display_Column:[], approval_type:[], Corporate_Info:[], 
access_type:[],approval_permission:[] }
});


export  const SetRecoilCorporateApprovedList = () => useSetRecoilState(corporateApprovedAtom);
export  const GetRecoilCorporateApprovedList = () => useRecoilValue(corporateApprovedAtom);







