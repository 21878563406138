// import { DataGrid ,  gridPageCountSelector,
//     gridPageSelector, GridToolbarContainer, GridToolbarExport ,
//     useGridApiContext,
//     useGridSelector} from "@mui/x-data-grid";
    import { DataGrid ,  gridPageCountSelector,
        gridPageSelector, GridToolbarContainer, GridToolbarExport ,
        useGridApiContext,
        useGridSelector} from "@mui/x-data-grid";
        import Pagination from '@mui/material/Pagination';
      import PaginationItem from '@mui/material/PaginationItem';


    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);
      
        return (
          <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            // @ts-expect-error
            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
          />
        );
      }
    


        const TableHead = [
            { field: 'id', headerName:'Id' , width: 195 , headerClassName: "tableHeader "},
            { field: 'firstName', headerName:'Name' , width: 195 , headerClassName: "tableHeader "},
            { field: 'lastName', headerName:'Changed By' , width: 195 , headerClassName: "tableHeader "},
            { field: 'Remark', headerName:'Reason' , width: 195 , headerClassName: "tableHeader "},
            { field: 'Total_Amount', headerName:'Rate' , width: 195 , headerClassName: "tableHeader "},
            { field: 'Total_Amount2', headerName:'Rate2' , width: 195 , headerClassName: "tableHeader "},
            { field: 'Total_Amount3', headerName:'Rate3' , width: 195 , headerClassName: "tableHeader "},
        
            ]

            const tableData = [
                { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
                { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
                { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
                { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
                { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
                { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
                { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
                { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
                { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
            ];

function TableOneBackup() {

    return (

        <>
<div className="mt-5">

<div  style={{ height: 450, width: '100%' }}>
      <DataGrid
        columns={TableHead}
        rows={tableData}
        components={{
          Pagination: CustomPagination,
        }}
      />
    </div>
</div>
        
        </>
      );
}

export default TableOneBackup;