import { Fragment, useState, useContext } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import LogicContext from "../Context/LogicContext";
import { useNavigate, useLocation } from "react-router-dom";

interface PropsInterface {
  selectModal: (form: any) => void;
}

export default function Example(props: PropsInterface) {
  const [selected, setSelected] = useState<any>(null);
  const logicContext = useContext(LogicContext);
  const moduleMenuList: any = logicContext.getModule();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const modalSelection = (selection: any) => {
    setSelected(selected);
    props.selectModal(selection);
    navigate(`/${selection.Module_Id}/${selection.Module_Name}/dashboard`);
    // http://localhost:3000/3/Inventory/dashboard
    handleClose();
  };

  return (
    <div className="">
      <Listbox value={selected}>
        <div className="relative">
          <Listbox.Button className="focus:outline-none outline-none">
            <div className="px-2 w-[150px] h-[42px] flex flex-row items-center justify-between bg-[#486EF8] border-[1px] border-gray-200 rounded-md">
              <p className="mt-2.5 ml-2 text-center mr-2 text-white font-semibold text-sm">
                {currModuleName}
              </p>
              <IoMdArrowDropdown className="h-6 w-6 text-white" />
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none text-sm">
              {Object.keys(moduleMenuList).map((item, i) => (
                <Listbox.Option
                  key={i}
                  onClick={() => modalSelection(moduleMenuList[item][0])}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 px-3 ${
                      active ? "bg-gray-100 text-gray-800" : "text-gray-600"
                    }`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={` ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {item}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-[#1976D2]">
                          <CheckIcon className="h-5 w-5" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
