import React, { useEffect, useRef, useState,useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";

import { Grid } from "@mui/material";
import { Dropdown } from "react-bootstrap";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { settingsService } from "../../services/settingsService";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import LogicContext from "../../Context/LogicContext";
import ToggleSwitch from "../../components/ToggleSwitch";
import { FaPaperclip, FaPencilAlt, FaTrash } from "react-icons/fa";
import FeatureActionUrlScroll from "./FeatureActionUrlScroll";
import FeatureActionUrlContent from "./FeatureActionUrlContent";
import { SetActionUrlEditAtom, GetFeatureListAtom, SetFeatureListAtom  } from "../../store/actionUrlAtom";








const FeatureActionUrlDetailsPage = () => {
    const { id } = useParams();
    const [featureDetail, setFeatureDetail] = useState({});
    const myPromiseModal = createModal(GeneralModal);
    const logicContext = useContext(LogicContext);
    const actionListData =  GetFeatureListAtom();
    const setActionListData =  SetFeatureListAtom();
    const setEditData =  SetActionUrlEditAtom();
  

    const navigate = useNavigate();
    const { pathname } = useLocation();  
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];


    useEffect(() => {
        document.title = "Feature Details Page";
        loadVoucherDetails(id);
   
        return ()=>{ 
            //code here
          }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);



      const loadVoucherDetails = (id) => {
        settingsService.actionUrlDetails(id).then(res => {
            console.log('Details ', res.data);
            console.log('see ', actionListData);
            if(res?.data?.length){
                const featureDetail = res.data[0];
                setFeatureDetail(featureDetail);
                let newList =  actionListData.map((item, i)=> {
                    if(item.Feature_Action_URL_Id === featureDetail.Feature_Action_URL_Id ){
                        return featureDetail;
                    }else{
                        return item;
                    }
                });
                setActionListData(newList);
               
            }
        });
      }


      const createNewVoucher = () => {
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
      }

      const toogleItemStatus = (val) => {
        console.log('val ', val);
        if (val === true) {
            settingsService.ActionUrlStatusChange({status_id: 1, Ref_no: id }).then((res) => {
            console.log('body ', res.data);
            logicContext.showToast(res.data[0]["message_text"], "success");
            loadVoucherDetails(id);
          });
        }
    
        if (val === false) {
            settingsService.ActionUrlStatusChange({status_id: 2, Ref_no: id }).then((res) => {
                console.log('body ', res.data);
                logicContext.showToast(res.data[0]["message_text"], "success");
                loadVoucherDetails(id);
              });
        }
      };


      const modifyFeature =  async()=> {
        await myPromiseModal({
            title: "Please confirm!", body: `Modify feature.`,
            showInput: false, placeHolder: ''}).then((res) => {
            if (res.decision === true) {
                setEditData(featureDetail);
                navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
            }
        });
      }



    return (
        <>
                <section className="  mt-2 ">
                    <Grid container className="" spacing={2}>
                        <Grid item xs={12}>
                            <section className=" ">
                                <div className="flex flex-wrap justify-between  ">
                                    <button onClick={()=> createNewVoucher()}
                                        style={{ borderRadius: "5px" }}
                                        className=" bg-blue-600  px-3 h-[4vh]  text-white text-xs hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50 ">
                                        ADD NEW 
                                    </button>

                                    <section className="flex">
                                        
                                    <div className="flex   justify-start font-bold ">
                                    <span className="mt-1" > {(featureDetail?.Feature_Action_Status_Id === 1) ? 'Active': 'InActive'} </span>
                                    <ToggleSwitch
                                        toogleItemStatus={toogleItemStatus}
                                        Item_Status_Id={featureDetail?.Feature_Action_Status_Id}
                                        ></ToggleSwitch>
                                        
                                        
                                    </div>
                                   

                                    <div className="flex   justify-end">
                                        <div className="flex ">
                                        <div  onClick={()=> modifyFeature()} class="bg-filterColor h-11   grid cursor-pointer content-center ml-2 rounded hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-opacity-100">

                                        <FaPencilAlt  size={22} class=" object-center w-12 " />
                                        </div>

                                        <div  onClick={()=> modifyFeature()} class="bg-filterColor h-11   grid cursor-pointer content-center ml-2 rounded hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-opacity-100">

                                        < FaTrash size={22} class=" object-center w-12 " />
                                        </div>


                                        
                                
                                        </div>
                                    </div>
                                    </section>


                                </div>
                            </section>
                        </Grid>

                        <Grid item xs={12}>
                            <section className=" ">
                                <Grid container className="" spacing={0}>
                                    <Grid className="" item xs={2.8}>
                                        <FeatureActionUrlScroll />
                                    </Grid>
                                    <Grid item xs={9.2}>
                                        <section className="w-full  z-50 ">

                                            <section className=" ">
                                                <FeatureActionUrlContent  featureDetail={featureDetail} ></FeatureActionUrlContent>
                                                
                                            </section>

                                        </section>
                                    </Grid>
                                </Grid>

                                {/* w-[56.3vw] */}
                            </section>
                        </Grid>
                    </Grid>
                </section>


        </>
    );
};

export default FeatureActionUrlDetailsPage;
