import { useEffect, useState } from 'react';
import Select from 'react-select';
import { GetEntryData} from "../../store/purchaseOrderAtom";




function AddPurchaseOrderCustomer(props) {
  const masterData = GetEntryData();
  const [customOption, setCustomOption] = useState([]);



  useEffect(()=> {
   const tempData =  masterData['Customer_Warehouse']?.filter((element)=> element.Delivery_To_Type_Id === 1)
    .map((element)=> {
      return ({...element, label:`${element?.Customer_Full_Name}`})
    }
    );

    setCustomOption(tempData)

  },[masterData])



  
  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      backgroundColor: '#F1F1F1',
      // width: 512,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"})
  }

    const handleChange = (value) => {
      props.onDeliverSelect(value)
      };


    return ( 
        <>
   
         <Select placeholder="Select Customer"   styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal} options={customOption}/>


        </>
     );
}

export default AddPurchaseOrderCustomer;
