


const BASE_IMAGE_URL = 'https://micro-svc.delgro.xyz/images/';  // for prod
const BASE_ITEM_GROUP_IMAGE_URL = 'https://micro-svc.delgro.xyz/items-group-images/';  //for local
const BASE_ITEM_IMAGE_URL = 'https://micro-svc.delgro.xyz/items-images/'; // fro prod
const COMPOSITE_IMAGE_URL = 'https://micro-svc.delgro.xyz/composite-group-images/'; // fro prod
const INVOICE_ATTACHMENT_URL = 'https://micro-svc.delgro.xyz/invoice-attachments/'; // fro prod
const PAYMENT_RECEIVE_ATTACHMENT_URL = 'https://s3.eu-west-2.amazonaws.com/delgro-bucket.com/payment-slip/'; 
const PURCHASE_ORDER_ATTACHMENT_URL = 'https://s3.eu-west-2.amazonaws.com/delgro-bucket.com/purchase-order/'; 
const SALES_ORDER_ATTACHMENT_URL = 'https://s3.eu-west-2.amazonaws.com/delgro-bucket.com/sales-order/'; 
const PRODUCT_PLAN_IMAGE_URL = 'https://s3.eu-west-2.amazonaws.com/delgro-bucket.com/product-plan/'; 
const VENDOR_IMAGE_URL = 'https://s3.eu-west-2.amazonaws.com/delgro-bucket.com/vendor-upload/'; 
const BILL_IMAGE_URL = 'https://s3.eu-west-2.amazonaws.com/delgro-bucket.com/bill/'; 





export const imageService = {
    BASE_IMAGE_URL,
    BASE_ITEM_IMAGE_URL,
    BASE_ITEM_GROUP_IMAGE_URL,
    COMPOSITE_IMAGE_URL,
    INVOICE_ATTACHMENT_URL,
    PAYMENT_RECEIVE_ATTACHMENT_URL,
    PURCHASE_ORDER_ATTACHMENT_URL,
    PRODUCT_PLAN_IMAGE_URL,
    VENDOR_IMAGE_URL,
    BILL_IMAGE_URL
};


