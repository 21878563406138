import React, { useEffect } from 'react'
import Switch from '@mui/material/Switch';




function ProductPageToogle(props) {
    // const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };
    const [checked, setChecked] = React.useState(false);

    useEffect(()=>{
      if(props.val === 'A'){
        setChecked(true)
      }else{
        setChecked(false)
      }
    },[props?.val]);

    const handleChange = (event) => {
      setChecked(event.target.checked);
      props.toogleItemStatus(event.target.checked)
    };
  

  return (
     
     <Switch 
     checked={checked}
     onChange={handleChange}
      />
   
  )
}

export default ProductPageToogle;