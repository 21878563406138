

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


const invoiceTableHeader = atom({key:'invoiceTableHeader', default:[]});
const invoiceAccountSummary = atom({key:'invoiceAccountSummary', default:{}});
const invoiceEntryMasterdata = atom({key:'invoiceEntryMasterdata', default:{}});
const invoiceEditAtom = atom({key:'invoiceEditAtom', default:{}});
const invoiceBulkAction = atom({key:'invoiceBulkAction', default:[]});
const invoiceTableList = atom({key:'invoiceTableList', default:[]});
const invoiceFilterList = atom({key:'invoiceFilterListData', default:[]});


export  const SetInvoiceList = () => useSetRecoilState(invoiceTableList);
export  const GetInvoiceList = () => useRecoilValue(invoiceTableList);
export const ResetInvoiceList = ()=> useResetRecoilState(invoiceTableList);

export  const SetInvoiceEdit = () => useSetRecoilState(invoiceEditAtom);
export  const GetInvoiceEdit = () => useRecoilValue(invoiceEditAtom);
export const ResetInvoiceEdit = ()=> useResetRecoilState(invoiceEditAtom);


export  const SetInvoiceAccSummary = () => useSetRecoilState(invoiceAccountSummary);
export  const GetInvoiceAccSummary = () => useRecoilValue(invoiceAccountSummary);

export  const SetInvoiceBulkAction = () => useSetRecoilState(invoiceBulkAction);
export  const GetInvoiceBulkAction = () => useRecoilValue(invoiceBulkAction);


export  const SetInvoiceTableHeader = () => useSetRecoilState(invoiceTableHeader);
export  const GetInvoiceTableHeader = () => useRecoilValue(invoiceTableHeader);

export  const SetInvoiceFilter = () => useSetRecoilState(invoiceFilterList);
export  const GetInvoiceFilter = () => useRecoilValue(invoiceFilterList);

export  const SetInvoiceEntryMasterData = () => useSetRecoilState(invoiceEntryMasterdata);
export  const GetInvoiceEntryMasterData = () => useRecoilValue(invoiceEntryMasterdata);




