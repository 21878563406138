import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "./ForgotPasswordPage.css";
import logo from "../assets/delgro_logo.png";
import login_icon from "../assets/login_icon.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import EmailInputComponent from "../components/EmailInputComponent";
import PasswordInputcomponent from "../components/PasswordInputComponent";
import LogicContext from "../Context/LogicContext";
import DelgroLogo from "../components/delgro_logo/DelgroLogo";
import Container from "../components/my-container/Container";

function ForgotPassword() {
  const logicContext = useContext(LogicContext);
  const [pageInformation, setPageInformation] = useState(
    " enter your email address"
  );
  const [email, setEmail] = useState("");
  const HOSTNAME = process.env.REACT_APP_API_BASEURL_GET;

  const [showOTPForm, setShowOTPForm] = useState(false);
  const [showNewPasswordField, setShowNewPasswordField] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [newPassword, setNewPassword] = useState(false);

  var queryProperties = {
    sql_ref_no: 3,
    user_name: "",
    login_applicatoin: 1,
    option: 1,
  };

  function submitOtp(event) {
    event.preventDefault();
    setShowOTPForm(false);
    setShowNewPasswordField(true);
    logicContext.showToast("success");
  }

  const getUserTypedEmail = (email) => {
    setEmail(email);
  };

  const getUserTypedpassword = (password) => {
    setNewPassword(password);
  };

  const getUserTypedConfirmpassword = (password) => {
    setNewPassword(password);
  };

  function handleUserEmailSubmit(event) {
    event.preventDefault();
    if (email === "") return toast.error("enter a valid email address.");
    queryProperties.user_name = email;

    axios
      .get(`${HOSTNAME}api/data/get`, {
        params: { jstring: JSON.stringify([queryProperties]) },
      })
      .then((res) => {
        const response = res.data[0];
        if (response?.Message_Id === 1) {
          setShowOTPForm(true);
          setPageInformation("Enter verification code");
        } else {
          toast.error(response?.Message_Text);
        }
      })
      .catch((err) => {
        logicContext.showAlert("connection failed", "error");
      });
  }

  return (
    <>
      <div className="bg-white pb-40">
        <Container>
          {/* <div>
        <ToastContainer theme="colored" />
      </div> */}
          <div className="w-fit lg:px-10 mt-4">
            <DelgroLogo />
          </div>
          <div className="mt-6 flex flex-col md:grid md:grid-cols-9 items-center md:gap-6 lg:px-10">
            <div className="md:col-span-5 flex flex-col">
              <h3 className="text-lg font-semibold text-gray-600 max-w-md">
                You are about to enter a productive zone where the impossible is
                possible
              </h3>
              <img
                src={login_icon}
                className="h-auto w-full max-w-[530px] aspect-auto object-contain"
                alt="banner"
              />
            </div>
            <div className="mt-16 w-full md:mt-0 md:col-span-4 max-w-md mx-auto">
              <h2 className="text-3xl font-semibold text-gray-700 md:max-w-md">
                Forget Password,
              </h2>
              <p className="text-lg font-medium text-gray-700">
                {pageInformation}
              </p>
              <form>
                <div className="">
                  <div>
                    {!showNewPasswordField && !showOTPForm ? (
                      <EmailInputComponent emailOnchange={getUserTypedEmail} />
                    ) : null}

                    {showOTPForm ? (
                      <div className="form-group login-page-group3 layout">
                        <label>verification code</label>
                        <input
                          type="number"
                          value={otpValue}
                          className="form-control"
                          placeholder="Enter otp"
                          onChange={(e) => setOtpValue(e.target.value)}
                        />
                      </div>
                    ) : null}
                  </div>

                  {showNewPasswordField ? (
                    <PasswordInputcomponent
                      passwordChange={getUserTypedpassword}
                    />
                  ) : null}

                  {showNewPasswordField ? (
                    <PasswordInputcomponent
                      passwordChange={getUserTypedConfirmpassword}
                    />
                  ) : null}

                  {!showNewPasswordField && !showOTPForm ? (
                    <div className="mt-10">
                      <button
                        type="submit"
                        className="w-full py-3 text-sm font-semibold bg-[#486EF8] tracking-widest outline-none rounded-lg text-white hover:bg-[#486EF8]/80 active:bg-[#486EF8] transition-all duration-300 active:outline-none"
                        onClick={handleUserEmailSubmit}
                      >
                        SUBMIT EMAIL
                      </button>
                    </div>
                  ) : null}

                  {showOTPForm ? (
                    <div className="mt-10">
                      <button
                        onClick={submitOtp}
                        type="submit"
                        className="w-full py-3 text-sm font-semibold bg-[#486EF8] tracking-widest outline-none rounded-lg text-white hover:bg-[#486EF8]/80 active:bg-[#486EF8] transition-all duration-300 active:outline-none"
                      >
                        SUBMIT OTP
                      </button>
                    </div>
                  ) : null}

                  {showNewPasswordField ? (
                    <div className="mt-10">
                      <button
                        type="submit"
                        className="w-full py-3 text-sm font-semibold bg-[#486EF8] tracking-widest outline-none rounded-lg text-white hover:bg-[#486EF8]/80 active:bg-[#486EF8] transition-all duration-300 active:outline-none"
                      >
                        SUBMIT NEW PASSWORD
                      </button>
                    </div>
                  ) : null}

                  <div className="mt-4 flex items-center justify-center">
                    <Link to="/login">
                      <p className="w-fit text-base font-medium text-[#1890FF] border-b-[1.5px] border-b-white hover:border-b-[#1890FF]">
                        Back to Login
                      </p>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default ForgotPassword;
