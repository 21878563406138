import { atom , useSetRecoilState, useRecoilValue} from "recoil";




export const customerVoucherListAtom = atom({key:'customer_voucher_atopm',default:[]});


export const SetCustomerVoucherAtom = () => useSetRecoilState(customerVoucherListAtom);
export const GetCustomerVoucherAtom = () => useRecoilValue(customerVoucherListAtom);
