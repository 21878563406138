import React from 'react'
import { Scrollbars } from "react-custom-scrollbars";

function ImageScrollItemGroup(props) {
 
  return (
    <> 
     <Scrollbars
    autohide
    style={{ height: 600, margin: "0 auto" }}
  >
    
    <div className='card'>
      <ul className='list-group list-group-flush'>
        {props?.list.map((item, i) => 
        <li key={i} className='list-group-item' >
          <div className='row show-cursor' onClick={()=> props.onImageSelect(item)}>
            <div className='col-5' >
            <img src={item?.Image_URL} width="500" alt="imageF" srcset="" />
            </div>
            <div className='col-7' > {item?.group_name} </div>
          </div>
        </li>
        )}
      </ul>

    </div>

  </Scrollbars></>
  )
}

export default ImageScrollItemGroup;