/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import {imageService} from '../../services/image.service';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DownloadIcon from '@mui/icons-material/Download';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



function BillFileViewer(props) {
    const [open, setOpen] =  useState(false); 
    const [currentPreview, setCurrentPreview] =  useState(null); 

  const handleClose = () => setOpen(false);

    useEffect(()=> {

    },[props]);

    const handleOpen = (file) => {
        setOpen(true)
        setCurrentPreview(file)
    };

    let fileData = props?.fileList?.map((file, i)=> {

        return (
        
             <div key={i} className="col-sm">
            <img width='200' className="cursor" onClick={()=> handleOpen(file)}
             key={i} src={imageService?.BILL_IMAGE_URL + file.Bill_Ref_No 
                + file.Image_Reference_No + '.' + file.Image_Format} alt={file.Image_Format + '  file'} ></img>
              </div>
        )
    });


    return ( 
        <>
        <div className="container">
            <div className="row">
            {fileData}
            </div>
            <small  className="text-center" >Click on the item to preview </small>
         
            </div>


    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <img  
             key={currentPreview?.BILL_IMAGE_URL} src={imageService.BILL_IMAGE_URL + currentPreview?.Bill_Ref_No 
                + currentPreview?.Image_Reference_No + '.' + currentPreview?.Image_Format} alt=' _file' ></img>

       
<p key={currentPreview?.BILL_IMAGE_URL}> <a href={imageService.BILL_IMAGE_URL +
 currentPreview?.Bill_Ref_No 
                + currentPreview?.Image_Reference_No + '.' + 
                currentPreview?.Image_Format} target="_blank" alt=''>
                   <DownloadIcon fontSize="large" />
                </a>.</p>
        </Box>
      </Modal>
    </div>
        
        </>
     );
}

export default BillFileViewer;