
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}



function SubscriptionChangeDate(props) {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(null);
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    const saveDate = () => {
        handleClose();
        props.updateBillingDate(value)

    }

    

    return ( 
        <>
           <div>
    
      <span onClick={handleOpen}
      style={{ color: "#476EF8" }} className="text-xs">
                      Change
             </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="parent-modal-title">Select new billing date</h2>
            <input type="date"  onChange={(newValue) => {
                                setValue(newValue.target.value);
    }}  />
     <div className='clearfix'>
        <button onClick={saveDate} className='btn btn-sm btn-success mt-1' >
            save
        </button>
        <button onClick={handleClose} className='float-right btn-sm mt-1 btn btn-danger' >
            cancel
        </button>
        </div>
         
        </Box>
       
      </Modal>
    </div>
  
        </>
     );
}

export default SubscriptionChangeDate;