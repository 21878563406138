
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const purchaseService = {
     getVendorList,
     getVendorDetails,
     submitVendor,
     submitVendorAddress,
     submitVendorOtherInfo,
     getVendorNotification,
   saveVendorTableHeader,
   changeVendorStatus,
   deleteVendor,
   getBillsList,
   getBillDetails,
   setBillBulkAction,
   saveBillTableHeader,
   getBillEntryData,
   saveNewBill,
   getBillHistory,
   getPurchaseReceiveList,
   savePurchaseReceiveTableHeader,
   getPurchaseReceiveDetails,
   submitPurchaseReceive,
   convertBillToReceive
    
 
};

async function  getVendorList() {
    return  axios.get(`/purchases/get-vendor-list`, authHeader());
}

async function  getVendorDetails(vendor_id) {
    return  axios.get(`/purchases/get-vendor-details/${vendor_id}`, authHeader());
}

async function  submitVendor(data) {
    return  axios.post(`/purchases/submit-vendor`,data, authHeader());
}


async function  submitVendorAddress(data) {
    return  axios.post(`/purchases/submit-vendor-address`,data, authHeader());
}

async function  submitVendorOtherInfo(data) {
    return  axios.post(`/purchases/submit-vendor-other-info`,data, authHeader());
}


async function  getVendorNotification(vendor_id) {
    return  axios.get(`/purchases/get-vendor-notification/${vendor_id}`, authHeader());
}

async function  deleteVendor(vendor_id) {
    return  axios.delete(`/purchases/delete-vendor/${vendor_id}`, authHeader());
}

async function  saveVendorTableHeader(body) {
    return  axios.post(`/purchases/save-vendor-table-header`, body, authHeader());
}

async function  changeVendorStatus(body) {
    return  axios.put(`/purchases/change-vendor-status`, body, authHeader());
}

async function  getBillsList() {
    return  axios.get(`/purchases/bills-list`, authHeader());
}

async function  getBillDetails(bill_id) {
    return  axios.get(`/purchases/bill-details/${bill_id}`, authHeader());
}

async function  setBillBulkAction(body) {
    return  axios.put(`/purchases/set-bill-bulk-action`, body, authHeader());
}

async function  saveBillTableHeader(body) {
    return  axios.post(`/purchases/save-bill-table-header`, body, authHeader());
}


async function  getBillEntryData() {
    return  axios.get(`/purchases/bill-entry-data`, authHeader());
}



async function  saveNewBill(body) {
    return  axios.post(`/purchases/save-bill-record`, body, authHeader());
}

async function  getBillHistory(id) {
    return  axios.get(`/purchases/bill-history/${id}`, authHeader());
}

async function  getPurchaseReceiveList() {
    return  axios.get(`/purchases/purchase-receive-list`, authHeader());
}

async function  savePurchaseReceiveTableHeader(body) {
    return  axios.post(`/purchases/purchase-receive-save-table-column`, body, authHeader());
}

async function  getPurchaseReceiveDetails(purchase_id) {
    return  axios.get(`/purchases/purchase-receives-details/${purchase_id}`, authHeader());
}


async function  submitPurchaseReceive(form) {
    return  axios.post(`/purchases/submit-purchase-receive`, form, authHeader());
}

async function  convertBillToReceive(form) {
    return  axios.post(`/purchases/convert-to-bill`, form, authHeader());
}













