import { useEffect, useState } from 'react';
import Select from 'react-select';
import { GetEntryData} from '../../store/vendorAtom';
import underscore from "underscore";





function VendorBillingStateComponent(props) {
  const entryMasterData = GetEntryData();
  const [customOption, setCustomOption] = useState([]);


  useEffect(()=> {
    var masterDataGroup = underscore?.groupBy(entryMasterData, "Master_Type");
    let countryList = masterDataGroup[2];

   const tempData =  countryList?.map((element)=> {
    if(element.Parent_Code1 === props?.countryCode){
      return ({...element, label:`${element?.Name}`})
    }

    }
    );
    console.log('seee ', tempData)
    if(tempData !== [undefined]){
      setCustomOption(tempData)
    }

  },[entryMasterData, props?.countryCode])





  
  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      backgroundColor: '#F1F1F1',
      // width: 512,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
    menuPortal:styles => ({ ...styles, zIndex: 9999 })
  }

    const handleChange = (value) => {
      props.onBillingStateSelect(value)
      };


    return ( 
        <>
   
         <Select   styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal} options={customOption}/>


        </>
     );
}

export default VendorBillingStateComponent;
