import Select from 'react-select';
import { GetCustomerList} from "../../store/paymentReceivedAtom";
import { customerService } from '../../services/customer.service';
import { useContext, useState } from 'react';
import LogicContext from "../../Context/LogicContext";




function CarryBagAddCustomer(props) {
  const customerList = GetCustomerList();
  const [searchResult, setSearchResult] = useState([]);
  const logicContext = useContext(LogicContext);



  const customOption = customerList?.map((item)=> {
    return ({...item, label:`${item?.Customer_Name}`})
  });



  
  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      backgroundColor: '#F1F1F1',
      // width: 512,
    }),
    indicatorSeparator: styles => ({ ...styles }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"})
  }

    const handleChange = (value) => {
      props.onCustomerSelect(value);
      
      };



      function processSearch(val){
        const search  = val?.target?.value;
        if(search?.length > 2){
          customerService.getCustomerListSearch(search).then(res => {
            if(res?.data?.length > 0){
                let newList =  res?.data.map((item)=>  {return {...item, label: item.Name}});
              return setSearchResult(newList);
            }else{
              logicContext.showToast('No available record for your search query! ', '');
              return setSearchResult([]);
            }
          });
        }else{
          setSearchResult([]);
        }
     
      }


    return ( 
        <>
   
         <Select placeholder="Search Customer Name" key={props?.defaultVal}  onKeyDown={(val)=> processSearch(val)}    styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal ? props?.defaultVal : undefined}
           options={searchResult}/>
          


        </>
     );
}

export default CarryBagAddCustomer;
