
import { DataGrid ,  gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport ,
    useGridApiContext,
    useGridSelector} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';
  import  {GetFinishItems} from '../store/finishItemAtom';
  import { Link, useLocation, useNavigate } from "react-router-dom";

  const TableHead = [

    { field: 'Item_Id', headerName:'Code' , width: 172 , headerClassName: " "},
     { field: 'Item_Name', headerName:'Name' , width: 220 , headerClassName: " "},
     { field: 'Item_Variety_Name', headerName:'Item Type' , width: 172 , headerClassName: " "},
     { field: 'Item_Sku', headerName:'Item SKU' , width: 172 , headerClassName: " "},
     { field: 'Total_Item', headerName:'Item Qty' , width: 172 , headerClassName: " "},
     { field: 'Setup_Status', headerName:'Status' , width: 152 , headerClassName: " "},
  
    ]

      function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarExport />
          </GridToolbarContainer>
        );
      }

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }


function ProductionTable(props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];


  const tableData = props?.list?.map((item, i) => {
    return ({...item, id: item.Item_Id})
});



function onRowClick(item){
  navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${item.id}`+'_'+ 3)
}

    return ( 
        <>
<div className="table" style={{ height: 500, width: '100%' }}>
      <DataGrid  className="table_css"
        columns={TableHead}
        rows={tableData}
        pageSize={6}
        rowsPerPageOptions={[6]}
        components={{
          Toolbar: CustomToolbar,
          Pagination: CustomPagination,
        }}
          onCellClick={( event) => {
            event.defaultMuiPrevented = true;
            
          }}
          onRowClick={(event)=> onRowClick(event)}
          
       
          sx={{
            // boxShadow: 2,
            // border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
              cursor: 'pointer',
            },
          }}
      />
  
    </div>
        
        </>
     );
}

export default ProductionTable;
