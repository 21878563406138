
import { useEffect } from "react";
import { useState } from "react";


function ChartAccountTableSelect(props) {
    const [isChecked, setIsChecked] = useState(false);

  useEffect(()=> {
    if(props.val?.Display_Id === 1){
        setIsChecked(true)
    }
  },[props.val?.Display_Id]);


    const handleOnChange = () => {
        setIsChecked(!isChecked);
        props.tableMenuSelect(props.val, props.i)
      };


    return (  
        <>
         <div>
          <li className='dropdown-item  d-flex justify-content-start'> 
                   <small className="dropdown-item " href="#">{props.val?.Column_Name}  </small>
                    <input type="checkbox" checked={isChecked}  onChange={handleOnChange}/>
                     </li>
      </div>
        </>
    );
}

export default ChartAccountTableSelect;