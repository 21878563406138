import { atom , useSetRecoilState, useRecoilValue, selector} from "recoil";



export const settingListAtom = atom({key:'geocedy_setting__atom',default:{action_url_info:[],
     feature_info:[]}});
export const featureEditAtom = atom({key:'geocedy_setting__atom_feature_atom',default:{}});

export  const SetSettingsListAtom = () => useSetRecoilState(settingListAtom);
export  const GetSettingsListAtom = () => useRecoilValue(settingListAtom);

export  const SetEditFeatureAtom = () => useSetRecoilState(featureEditAtom);
export  const GetEditFeatureAtom = () => useRecoilValue(featureEditAtom);
