import Tooltip from "@mui/material/Tooltip";

import CompositAssosateSelect from "../pages/CompositAssosateSelect"
import AddCompositiAsscoaiteTable from "../pages/AddCompositiAsscoaiteTable";
import {GetAssociateTableList, SetAssociateTableList,GetItemToEdit ,ResetAssociateTableList} from '../store/itemAtom';
import { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import LogicContext from "../Context/LogicContext";
import { createModal } from "react-modal-promise";
import GeneralModal from "../helpers/GeneralModal";
import { itemsSetupService } from "../services/itemSetup.service";
import {itemsService} from '../services/items.service';

// import 




function AddAssciates(props) {
  const { register, setValue, getValues,  handleSubmit, reset, formState: { errors }} = useForm();
const tableData = GetAssociateTableList();
const itemToEditData = GetItemToEdit();
const setTableData =  SetAssociateTableList();
const resetTableList =  ResetAssociateTableList();
const [currSelection, setCurSelection] = useState([]);
const logicContext = useContext(LogicContext);
const myPromiseModal = createModal(GeneralModal);


  function userSelection(selection){
    const qty = getValues('Item_Quantity');
    setCurSelection({...selection, Item_Quantity: qty });
  
  }
// console.log('Item_edit ', itemToEditData);
  useEffect(()=> {
    setValue("Item_Quantity", 1); 
    // if(tableData.length < 1){
      itemsService.getAssociateItems(itemToEditData.Item_Id).then( res => {
        if( res?.data !== undefined && res.data?.length){
          setTableData(res.data);
        }
      })
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[itemToEditData.Item_Id])



  function addSelectionToTable() {
    if (!currSelection.Item_Id) {
      return logicContext.showToast("Please select an item", "info");
    }

    const exist = tableData.find((element) => element.Item_Id === currSelection.Item_Id);
    if (exist)
      return logicContext.showToast( "Item already selected, please select another item","error");
      setTableData((prev) => {
      return [...prev, { ...currSelection }];
    });
  }

  function deleteItemFromTable(remove) {
    const newList = tableData.filter((item) => item.Item_Id !== remove.Item_Id);
    setTableData(newList);
  }


  function cancelTableList(){
    resetTableList();
  }


  function modifyQuatity(newValue, itemToUpdate) {
    if (newValue < 1) return;

    const modifiedList = tableData.map((item) => {
      if (item.Item_Id === itemToUpdate.Item_Id) {
        return { ...item, Item_Quantity: parseInt(newValue) };
      } else {
        return item;
      }
    });
    setTableData(modifiedList);
  }



  function quantityChange(value){
    if(value < 1){
      return logicContext.showToast('minimum quantity must not be lesser than 1', 'error');
    }

    setCurSelection(prev => {
      return({...prev, Item_Quantity: parseInt(value)})
    });

  }


  async function saveAssociateItems() {

    await myPromiseModal({ title: "Confirmation", body: " Do you want to save this record?",
    showInput:false, placeHolder:'' }).then((response) => {
  
      if (response.decision === true) {
        let xml_string = "";
        let temp = "";
        tableData.map((item, i) => {
          temp += `<a><asso_item_id>${item.Item_Id} </asso_item_id>  
                  <asso_item_quantity>${item.Item_Quantity} </asso_item_quantity> 
                   </a>
                  `;
        });
        xml_string = `<root> ${temp} </root>`;
        let option = 1;
        itemsSetupService.submitAssociateItems({ xml_string, Item_Id: itemToEditData.Item_Id, option: option })
          .then((res) => {
            if (res?.data?.item_info?.length > 0) {
              
              setTableData(res.data?.item_info);
             
              logicContext.showToast('Associates items has saved successfully!', "success");
              
              setTimeout(() => {
           
                  // resetTableList();
            
              }, 2000);
            }
          });
      }
    });
  }

  return (
    <>
  

      <section className="mx-2  mt-4 max-auto  w-full text-filterTextColor">
        <section className=" ">
          <section className=" mt-1 right-1   md:  ">
            <section className="">
              <section className="profile_section ">
                <div className=" w-full ">
                  <span className="text-sm font-bold">Associated Items</span>
                  <div className="flex justify-between profile_section_two ">
                    {" "}
                   <CompositAssosateSelect userSelection={userSelection}></CompositAssosateSelect>

                    <div className="flex justify-between w-full ml-3">
                    <div className="required-quantity-two w-full  ">
                      <span className="text-xs">Quantity </span> <br></br>
                      <input
                       onChange={(e)=> quantityChange(e.target.value)}
                      defaultValue={getValues('Item_Quantity')}
                        className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type="number"
                  
                        placeholder=""
                 
                      />
                    </div>
                    <div className="required-quantity-two  w-full  ">
                      <span className="text-xs">Price </span> <br></br>
                      <input
                      readOnly={true}
                      defaultValue={0}
                      value={currSelection.Sales_Rate}
                        className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type="number"
                        placeholder=""
                    
                    
                      />
                    </div>
                    <div className="required-quantity-two  w-full ">
                      <span className="text-xs">Total Amt </span> <br></br>
                      <input
                      readOnly={true}
                        className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type="number"
                        defaultValue={0}
                        value={currSelection.Sales_Rate * currSelection?.Item_Quantity}
                        placeholder=""
                    
                      />
                    </div>
                    <div className=" mt-4 w-full ">
                      <Tooltip title="add">
                        <button
                          onClick={()=> addSelectionToTable()}
                          style={{ backgroundColor: "#476EF8" }}
                          className="  rounded text-white text-sm   py-[1.6vh]   px-4"
                        >
                          ADD
                        </button>
                      </Tooltip>
                    </div>
                    </div>
                  
                  </div>
                </div>
                {/* end */}
              </section>
            </section>
          </section>
        </section>
      </section>

      <section className="mt-4 ml-[0.5vw] mr-[3vw]">
       <AddCompositiAsscoaiteTable  modifyQuatity={modifyQuatity}
        deleteItemFromTable={deleteItemFromTable}
       tableData={tableData}></AddCompositiAsscoaiteTable>
      </section>

      <div className="row">
        <div className="col offset-6"></div>
        <div className="col">
          <button onClick={()=> cancelTableList()} className="btn btn-light text-danger mr-3" >
            {" "}
            CANCEL
          </button>

          <button
          onClick={()=> saveAssociateItems()}
            style={{ backgroundColor: "#476EF8" }}
            className="  rounded text-white text-sm font-[Poppins]  py-[1.8vh]   px-3"
          >
            SUBMIT
          </button>
        </div>
      </div>
    </>
  );
}

export default AddAssciates;
