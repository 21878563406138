
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';



export const subscriptionService = {
    getSubList,
    getMasterData,
    updateItemTableHeader,
    submitBulkAction,
    getEntryMasterData,
    submitSubscription,
    getSubscription,
    getCustomer,
    updateSubBillingDate,
    submitSalesOrderSubscription,
  
};




async function  getSubList() {
   return axios.get(`/subscriptions/get-subscriptions-list`,  authHeader())
}

function getCustomer(customer_id){
 return  axios.get(`/get-single-customer/${customer_id}`, authHeader())
   
     }

async function updateSubBillingDate(body){
 return  axios.put(`/subscriptions/update-sub-billing-date`, body, authHeader());
   
     }

async function  getEntryMasterData() {
   return axios.get(`/subscriptions/get-entry-masterdata`,  authHeader())
}

async function  getMasterData() {
   return axios.get(`/subscriptions/get-master-data`,  authHeader())
}
async function  getSubscription(id) {
   return axios.get(`/subscriptions/get-subscription/${id}`,  authHeader())
}

function updateItemTableHeader(xmlString){
   var body = {xml_string : xmlString}
   return axios.put('/subscriptions/update-table-header', body, authHeader())
        
 }
function submitBulkAction(body){
   return axios.post('/subscriptions/submit-bulk-action', body, authHeader())
        
 }
function submitSubscription(body){
   return axios.post('/subscriptions/submit-subscription', body, authHeader())
        
 }     
 
function submitSalesOrderSubscription(body){
   return axios.post('/subscriptions/submit-sales-order-subscription', body, authHeader())
 }   
 










