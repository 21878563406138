

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


const productMasterData = atom({key:'productMasterData', default:{}});
const productAddonMasterData = atom({key:'productAddonMasterData', default:{}});
const couponMasterData = atom({key:'couponMasterData', default:{}});

const planEditAtom = atom({key:'planEditAtom', default:{}});
const addonEditAtom = atom({key:'addonnEditAtom', default:{}});
const couponEditAtom = atom({key:'couponEditAtom', default:{}});

const addonAssoTableList = atom({key:'addonAssoTableList', default:[]});
const addonAssoPlanTableList = atom({key:'addonAssoPlanTableList', default:[]});

const productAtomList = atom({key:'productAtomList', default:[]});
const productEditAtom = atom({key:'productEditAtom', default:{}});


const couponTableOne = atom({key:'couponTableOne', default:[]});
const couponTableTwo = atom({key:'couponTableTwo', default:[]});



export  const SetProductMasterData = () => useSetRecoilState(productMasterData);
export  const GetProductMasterData = () => useRecoilValue(productMasterData);

export  const SetProductList = () => useSetRecoilState(productAtomList);
export  const GetProductList = () => useRecoilValue(productAtomList);

export  const SetProductEdit = () => useSetRecoilState(productEditAtom);
export  const GetProductEdit = () => useRecoilValue(productEditAtom);
export  const ResetProductEdit = () => useResetRecoilState(productEditAtom);

export  const SetCouponMasterData = () => useSetRecoilState(couponMasterData);
export  const GetCouponMasterData = () => useRecoilValue(couponMasterData);

export  const SetPlanEdit = () => useSetRecoilState(planEditAtom);
export  const GetPlanEdit = () => useRecoilValue(planEditAtom);
export  const ResetPlanEdit = () => useResetRecoilState(planEditAtom);

export  const SetAddonEdit = () => useSetRecoilState(addonEditAtom);
export  const GetAddonEdit = () => useRecoilValue(addonEditAtom);
export  const ResetAddonEdit = () => useResetRecoilState(addonEditAtom);

export  const SetAssoTable = () => useSetRecoilState(addonAssoTableList);
export  const GetAssoTable = () => useRecoilValue(addonAssoTableList);
export  const ResetAssoTable = () => useResetRecoilState(addonAssoTableList);


export  const SetAssoPlanTable = () => useSetRecoilState(addonAssoPlanTableList);
export  const GetAssoPlanTable = () => useRecoilValue(addonAssoPlanTableList);
export  const ResetAssoPlanTable = () => useResetRecoilState(addonAssoPlanTableList);

export  const SetCouponEdit = () => useSetRecoilState(couponEditAtom);
export  const GetCouponEdit = () => useRecoilValue(couponEditAtom);
export  const ResetCouponEdit = () => useResetRecoilState(couponEditAtom);


export  const SetCouponTableOne = () => useSetRecoilState(couponTableOne);
export  const GetCouponTableOne = () => useRecoilValue(couponTableOne);
export  const ResetCouponTableOne = () => useResetRecoilState(couponTableOne);

export  const SetCouponTableTwo = () => useSetRecoilState(couponTableTwo);
export  const GetCouponTableTwo = () => useRecoilValue(couponTableTwo);
export  const ResetCouponTableTwo = () => useResetRecoilState(couponTableTwo);

export  const SetAddonMasterData = () => useSetRecoilState(productAddonMasterData);
export  const GetAddonMasterData = () => useRecoilValue(productAddonMasterData);