// import { DataGrid ,  gridPageCountSelector,
//     gridPageSelector, GridToolbarContainer, GridToolbarExport ,
//     useGridApiContext,
//     useGridSelector} from "@mui/x-data-grid";
import { DataGrid ,  gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport ,
    useGridApiContext,
    useGridSelector} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';
  import {
    SetCouponMasterData,GetCouponMasterData, GetCouponTableTwo} from "../store/productAtom";


    const TableHead = [
        { field: 'Code', headerName:'Id' , width: 120, headerClassName: " "},
        { field: 'Name', headerName:'Name' , width: 120 , headerClassName: " "},
        { field: 'Parent_Name1', headerName:'Addon Type' , width: 120 , headerClassName: " "},
     
    
        ]



function AdCouponTable2(props) {
  const masterData = GetCouponMasterData();
  const couponTableTwo =  GetCouponTableTwo()


  function onSelectChange(selectedList){
    var selection = selectedList?.map((index) => {return couponTableTwo[index]})
    props.onSelectAssociatesAddons(selection);
    
}

return (

    <>
<div className="mt-4">

<div  style={{ height: 300, width: '85%' }}>
  <DataGrid
    columns={TableHead}
    rows={couponTableTwo?.map(((ele, i) => {return ({...ele, id: i})}))}
    checkboxSelection={true}
        onRowSelectionModelChange={onSelectChange}
  />
</div>
</div>
    
    </>
  );
}

export default AdCouponTable2;