

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";



const rewardRedeemAtom = atom({key:'reward_redeem', default:[]
});


export  const SetRewardRedeemStoreList= () => useSetRecoilState(rewardRedeemAtom);
export  const GetRewardRedeemStoreList = () => useRecoilValue(rewardRedeemAtom);







