import { DataGrid ,  gridPageCountSelector,
  gridPageSelector, GridToolbarContainer, GridToolbarExport ,
  useGridApiContext,
  useGridSelector} from "@mui/x-data-grid";
  import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import moment from "moment";


const TableHead = [
  { field: 'Customer_Name', headerName:'Customer Name' , width: 200 , headerClassName: "tableHeader "},
  { field: 'Customer_Email_Address', headerName:'Email ' , width: 200 , headerClassName: "tableHeader "},
   { field: 'Send_DateTime', headerName:'Sent' , width: 200 , headerClassName: "tableHeader "},
   { field: 'Read_Status_Name', headerName:'Delivered' , width: 100 , headerClassName: "tableHeader "},
   { field: 'Notification_Type_Name', headerName:'type' , width: 100 , headerClassName: "tableHeader "},
   
  ]



function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}


function NotificationDetaisTable(props) {


  return ( 
      <>


<div className="table" style={{ height: 500, width: '100%' }}>
    <DataGrid  rows={props?.list.map((item, i)=> { return {...item, id: i, Send_DateTime: 
   moment(item?.Send_DateTime,).format(" DD-MM-YYYY:HH:mm:ss") } } )}
      columns={TableHead}
      pageSize={6}
      rowsPerPageOptions={[6]}
      checkboxSelection={true}
      components={{
          Toolbar: CustomToolbar,
          Pagination: CustomPagination,
        }}
        onCellClick={( event) => {
          event.defaultMuiPrevented = true;
          
        }}
        
     
        sx={{
          // boxShadow: 2,
          // border: 2,
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
            cursor: 'pointer',
          },
        }}
    />

  </div>
      
      </>
   );
}

export default NotificationDetaisTable