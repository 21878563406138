import { atom , useSetRecoilState, useRecoilValue} from "recoil";




export const notificationMainAtom = atom({key:'notificationMainAtomList',default:{notification:[],
    notification_template:[], notification_type:[], notification_filter:[], customers:[]}});
    // notification_template:[], notification_type:[], customers:[]}});


export  const SetRecoilNotify = () => useSetRecoilState(notificationMainAtom);
export  const GetRecoilNotify = () => useRecoilValue(notificationMainAtom);
