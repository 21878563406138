import React, { useState, useContext } from "react";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import CurrencyFormat from 'react-currency-format';
import LogicContext from "../../Context/LogicContext";

function AddPaymentTable(props) {
  const { pathname } = useLocation();  
  const navigate = useNavigate();
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const logicContext = useContext(LogicContext);

  function viewInvoiceDetails(invoice_id){
    navigate(`/${currModuleId}/${currModuleName}/invoices/details/${invoice_id}`)
}


function onPayAmountChange(newAmount, index) {
  const newQuantity = parseInt(newAmount);


  if(newQuantity > 0){
    props.onPayAmountChange(newAmount, index);
  }else{
    props.onPayAmountChange(0, index);
  }
  
}

return (

    <>
     <div className=" mb-5 border-1 ">
        <TableContainer className="mt-3">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#F1F1F1" }}>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Invoice Number</TableCell>
                <TableCell>Invoice Amount</TableCell>
                <TableCell> Dues Amount</TableCell>
                <TableCell>Payment Amount</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
         
             {props?.invoiceList?.map((item,i)=> {
              return(

                <TableRow key={i}
               
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{item?.Invoice_Date_String} </TableCell>
                <TableCell onClick={()=> viewInvoiceDetails(item?.Invoice_Ref_No)}
                  align="left" className="text-primary">{item?.Invoice_No} </TableCell>
                <TableCell align="left">

                  <CurrencyFormat value={parseFloat(item?.Invoice_Amount ).toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                 </TableCell>

                <TableCell align="left"> 
                <CurrencyFormat value={parseFloat(item?.Due_Amount ).toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                </TableCell>
                

                <TableCell align="left"> 
                       
                       <input
                    type="number"
                    defaultValue={item?.Adjustment_Amount}
                    key={item?.Adjustment_Amount}
                    autoFocus="autoFocus"
                    style={{ width: "100px" }}
                    onChange={(e)=> onPayAmountChange(e.target.value, i)}
                 
                  />{" "}
                 </TableCell>
            
              </TableRow>
              )
             })}
              
             
            </TableBody>
          </Table>
        </TableContainer>

    
      </div>
    
    </>
  );
}

export default AddPaymentTable;