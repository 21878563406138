import { atom , useSetRecoilState, useRecoilValue, selector} from "recoil";



export const pageAtom = atom({key:'home_page__atom',default:[]});
export const settingEditAtom = atom({key:'homePage_edit_atom',default:{}});

export  const SetFeatureListAtom = () => useSetRecoilState(pageAtom);
export  const GetFeatureListAtom = () => useRecoilValue(pageAtom);

export  const SetActionUrlEditAtom = () => useSetRecoilState(settingEditAtom);
export  const GetActionUrlEditAtom = () => useRecoilValue(settingEditAtom);
