import { atom , useSetRecoilState, useRecoilValue, selector} from "recoil";



export const walletVariantAtom = atom({key:'walletVariant_atom',default:{wallet_info:[],
    wallet_type:[]
}});

export  const SetWalletVariantListAtom = () => useSetRecoilState(walletVariantAtom);
export  const GetWalletVariantListAtom = () => useRecoilValue(walletVariantAtom);





