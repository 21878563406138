/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useContext, useRef } from "react";
import { purchaseService } from "../../services/purchase.service";
import { Dropdown } from "react-bootstrap";
import LogicContext from "../../Context/LogicContext";
import { GetPurchaseReceiveListData, SetPurchaseReceiveListData } from '../../store/purchaseReceiveAtom';
// import CurrencyFormat from 'react-currency-format';
import Tooltip from "@mui/material/Tooltip";
import RestartAlt from "@mui/icons-material/RestartAlt";
import { useState } from "react";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import { useLocation, useNavigate } from "react-router-dom";
import PurchaseReceiveTable from "./PurchaseReceiveTable";





function PurchaseReceivePage() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];
    const logicContext = useContext(LogicContext);
    const setPurchaseData = SetPurchaseReceiveListData();
    const getPurchaseListData = GetPurchaseReceiveListData();
    const myPromiseModal = createModal(GeneralModal);

    const [tableData, setTableData] = useState([]);
    const [itemSelected, setItemSelected] = useState([]);
    const mountRef = useRef(false);



    useEffect(() => {
        document.title = 'Purchase Receives';
        // console.log('SEEEE ', getPurchaseListData)
        if (getPurchaseListData['Purchase_Receive_Info']?.length > 0) {
            setTableData(getPurchaseListData['Purchase_Receive_Info']);
        }

    }, [getPurchaseListData])



    const getPurchaseReceiveListApi = async () => {
        purchaseService.getPurchaseReceiveList().then(response => {
            console.log('Purchase Data ', response.data)
            if (response?.data !== undefined) {
                setPurchaseData(response.data);
            }
        });
    }


    const onActionFilterPerform = (action) => {
        const option = action.target.value;
        if (Number(option) === 0) {
            return setTableData(getPurchaseListData['Purchase_Receive_Info']);
        }
        var newList = getPurchaseListData['Purchase_Receive_Info'].filter((item) => item.Purchase_Receive_Status_Id === Number(option));
        setTableData(newList);
    }



    function selectedTableItems(itemList) {
        setItemSelected(itemList);
    }



    function addNewInvoice() {
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`)
    }




    async function bulkActions(action) {
        console.log(action)
        console.log(itemSelected)
        await myPromiseModal({
            title: 'confirm your action!',
            body: `Do you want to ${action.Action_Name} ?`,showInput:false, placeHolder:''
        }).then((result) => {

            if (result.decision === true) {
                let temp = '';
                const option = action.Procedure_Option;
                itemSelected.map(item => {
                    temp += `<a> <refno> ${item.Bill_Ref_No} </refno> </a>`
                });

                let xml_string_refno = `<root> ${temp} </root>`;
                purchaseService.setBillBulkAction({ xml_string_refno, option }).then(res => {
                    if (res?.data !== undefined) {
                        const { Message_Text } = res.data[0];
                        logicContext.showToast(Message_Text, 'success');
                        return getPurchaseReceiveListApi();
                    }
                }).catch((err) => {
                    logicContext.showToast('Error', 'error');
                });
            }

        });
    }



    return (
        <>
                <section >

                    <section className="bg-white  px-2    md:flex justify-between ">
                        <div className="flex px-2 mt-1">

                            {itemSelected.length > 0 ?
                                (
                                    <div className="row">
                                        <div className="col col-md-4">
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    style={{
                                                        backgroundColor: "#E5E5E5",
                                                        border: "none",
                                                        color: "#45444B",
                                                        fontSize: "12px",
                                                        height: "45px",
                                                    }}
                                                    id="dropdown-basic"
                                                >
                                                    Bulk Actions
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {getPurchaseListData['Purchase_Receive_Info']?.map((action, i) => {
                                                        return (
                                                            <Dropdown.Item key={i} onClick={() => bulkActions(action)}>
                                                                {action.Action_Name}
                                                            </Dropdown.Item>
                                                        )

                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>


                                        </div>

                                    </div>
                                ) :
                                (<ActionPaneComponent bulkActionData={getPurchaseListData['Filtering_Criteria']}
                                    onSelectBulkAction={onActionFilterPerform} />)}




                            <Tooltip title="Reload">
                                <RestartAlt
                                    className="cursor ml-2 mt-1"
                                    onClick={() => getPurchaseReceiveListApi()}
                                    color="primary"
                                    fontSize="large"
                                />
                            </Tooltip>

                        </div>



                        <div className="flex justify-between">
                            <button className="  font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8 text-sm">
                                IMPORT
                            </button>
                            <div className="btn-group">
                                <button className=" font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8   text-sm" data-toggle="dropdown" aria-expanded="false">
                                    EXPORT
                                </button>
                            </div>

                            {/* <button onClick={addNewInvoice}
                                className="bg-buttonColor font-[Poppins] py-3 mt-1 mr-1 px-3 rounded md:ml-8  text-white text-xs">
                                ADD NEW
                            </button> */}

                        </div>
                    </section>

                    <section className="mt-5">
                        <PurchaseReceiveTable  tableData={tableData} selectedTableItems={selectedTableItems} />
                    </section>
                </section>
         
        </>



    )
}

export default PurchaseReceivePage;
