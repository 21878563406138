import styles from "./Comment.module.css";
import chat from "../../assets/chat.png";
import { customerService } from "../../services/customer.service";
import { useEffect, useState } from "react";
import React from "react";
import moment from "moment";
import { Grid } from "@mui/material";
import RoomIcon from "@material-ui/icons/Room";

function CustomerCreditScore(props) {
  const { customer_id } = props;
  const [creditScore, setCreditScore] = useState({});

  // useEffect(() => {
  //   customerService.customerCreditScore(customer_id).then((res) => {
  //     console.log("Logggg ", res.data);
  //     if (res?.data !== undefined) {
  //       setCreditScore(res.data[0]);
  //     }
  //   });
  // }, [customer_id]);


  useEffect(()=> {
    console.log("ZOHO_ID ", props?.Zoho_Customer_Id);
    if(props?.Zoho_Customer_Id){

    customerService.getCustomerCreditScore(props?.Zoho_Customer_Id).then((res)=> {
      console.log('credit_score', res.data);
      if(res?.data?.length){
        setCreditScore(res.data[0]);
      }else{
        console.log('NO_RECOMMENDATION');
      }
    }).catch((err)=> {
      setCreditScore({});
      console.log('credit_score_error ', err);
    });
    }

  },[props?.Zoho_Customer_Id]);


  return (
    <>
      <div className="container w-full">
        <h1 class="mt-2 text-primary text-3xl font-weight-bold">
          Score - {creditScore?.score}
        </h1>
        <div className="mt-3">
          <ul class="list-group">
          
            <li class="list-group-item text-lg active">
              Id -{" "}  <span className="font-bold text-white">  {creditScore?.id} </span>{" "}
            </li>
            <li class="mt-2 list p-2"> Score Rank - <span className="font-weight-bold">
                {creditScore?.score_rank}
              </span>
            </li>

            <li class="list p-2">
              {" "}
              Transaction Recommendation -  <span className="font-weight-bold">
                {creditScore?.Transaction_Recommendation}{" "}
              </span>
            </li>

            <li class="list p-2">
              {" "}
              Balance recommendation - <span className="font-weight-bold"> {creditScore?.Balance_Recommendation}
              </span>
            </li>

            <li class="list p-2">  Frequency recommendation -
              <span className="font-weight-bold">  {creditScore?.Freq_Recom}
              </span>{" "}
            </li>

            <li class="list p-2">
              Subscription recommendation -
              <span className="font-weight-bold">
                {creditScore?.Subscription_Recommedndation}{" "}
              </span>{" "}
            </li>

            <li class="list p-2">
            Recency Recommendation -
              <span className="font-weight-bold">
                {creditScore?.Age_Recom}{" "}
              </span>{" "}
            </li>

          </ul>
        </div>
      </div>
    </>
  );
}

export default CustomerCreditScore;
