const SettingsDashBoard = () => {

    return ( 
    <>
    <div className="jumbotron mt-5">
  <h1 className="display-4"> Dashboard</h1>
  <p className="lead">Welcome to Settings Dashboard</p>
  <hr className="my-4"/>
  <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
  <p className="lead">
    {/* <a className="btn btn-primary btn-lg" href="#" role="button">Learn more</a> */}
  </p>
</div>
    
    </> );
}
 
export default SettingsDashBoard;