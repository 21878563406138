
import { GetProductMasterData} from '../store/productAtom';


function PlanBillSelection(props) {
  const masterData = GetProductMasterData();


      const  createSelectItems = ()=> {
        let items = [];         
        masterData[2]?.forEach((item, i)=> {
          items.push(<option key={i} value={i}>{item.Name}</option>)
        });
        return items;
    } 
      


      const handleChange = (value) => {
        props.onSelectBillDuration(value)
        };



    return ( 
        <>
        <select className=" p-3 pr-5" style={{ backgroundColor:'#F1F1F1'}} onChange={handleChange}>
          {createSelectItems()} 
        </select>
        </>
     );
}

export default PlanBillSelection;
