import { atom , useSetRecoilState, useRecoilValue} from "recoil";




 const priceDataAtom = atom({key:'price_list_atom',default:{coporate_info:[], item_group:[]}});


export const SetPriceDataSore = () => useSetRecoilState(priceDataAtom);
export const GetPriceDataStore = () => useRecoilValue(priceDataAtom);
