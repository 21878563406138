import React, { useEffect, useState } from "react";
import './AddItemImage.css';
import profile from "../../../assets/itempimage.svg";
import { itemsService } from "../../../services/items.service";
import singleImage from "../../../assets/itemSingle.svg";
import {imageService} from "../../../services/image.service";


function CompositeGroupAddPicture(props) {

    const [itemId, setGroupId] = useState(props?.Composite_Group_Id);
    useEffect(()=> {
        setGroupId(props?.Composite_Group_Id)
    },[props?.Composite_Group_Id]);

       const [image1Preview1, setImage1Preview1] = useState(null);
       const [image1Preview2, setImage1Preview2] = useState(null);
       const [image1Preview3, setImage1Preview3] = useState(null);
       const [image1Preview4, setImage1Preview4] = useState(null);
       const [image1Preview5, setImage1Preview5] = useState(null);
   
       useEffect(()=> {
         setImage1Preview1(null);
         setImage1Preview2(null);
         setImage1Preview3(null);
         setImage1Preview4(null);
         setImage1Preview5(null);
         props.getGroupImages(props?.Composite_Group_Id).then(res => {
            res?.data?.map((item, i)=> {
              switch (i) {
   
                case 0:
                 setImage1Preview1(imageService.COMPOSITE_IMAGE_URL + item.Composite_Group_Id +'_'+ item.Picture_Id +'.'+item.picture_format);
                  break;
   
                case 1:
                 setImage1Preview2(imageService.COMPOSITE_IMAGE_URL + item.Composite_Group_Id +'_'+ item.Picture_Id +'.'+item.picture_format);
                  break;
   
                case 2:
                 setImage1Preview3(imageService.COMPOSITE_IMAGE_URL + item.Composite_Group_Id +'_'+ item.Picture_Id +'.'+item.picture_format);
                  break;
   
                case 3:
                 setImage1Preview4(imageService.COMPOSITE_IMAGE_URL + item.Composite_Group_Id +'_'+ item.Picture_Id +'.'+item.picture_format);
                  break;
   
                case 4:
                 setImage1Preview5(imageService.COMPOSITE_IMAGE_URL + item.Composite_Group_Id +'_'+ item.Picture_Id +'.'+item.picture_format);
                  break;
              
                default:
                  
                  break;
              }
            
            })
         })
       },[props?.Composite_Group_Id]);
   
   
   
   
       const imageOneChangeHandler = (e) => {
         const file = e.target.files[0];
        if(file){
         props.submitItemImage(file, '1')
         const reader = new FileReader();
             reader.addEventListener("load", () => {
               setImage1Preview1(reader.result);
             });
             reader.readAsDataURL(file);
        }
       };
   
   
       const imageTwoChangeHandler = (e) => {
         const file = e.target.files[0];
       if(file){
         props.submitItemImage(file, '2')
         const reader = new FileReader();
             reader.addEventListener("load", () => {
               setImage1Preview2(reader.result);
             });
             reader.readAsDataURL(file);
       }
       };
   
   
       const imageThreeChangeHandler = (e) => {
         const file = e.target.files[0];
       if(file){
         props.submitItemImage(file, '3')
         const reader = new FileReader();
             reader.addEventListener("load", () => {
               setImage1Preview3(reader.result);
             });
             reader.readAsDataURL(file);
       }
       };
   
   
       const imageFourChangeHandler = (e) => {
         const file = e.target.files[0];
       if(file){
         props.submitItemImage(file, '4')
         const reader = new FileReader();
             reader.addEventListener("load", () => {
               setImage1Preview4(reader.result);
             });
             reader.readAsDataURL(file);
       }
       };
   
   
       const imageFiveChangeHandler = (e) => {
         const file = e.target.files[0];
       if(file){
         props.submitItemImage(file, '5')
         const reader = new FileReader();
             reader.addEventListener("load", () => {
               setImage1Preview5(reader.result);
             });
             reader.readAsDataURL(file);
       }
       };
   
   
       function imageOneError(){
         setImage1Preview1(null)
       }
   
       const imageTwoError = ()=> {
         setImage1Preview2(null)
   
       }
   
       const imageThreeError = (e)=> {
         setImage1Preview3(null)
   
       }
   
       const imageFourError = (e)=> {
         setImage1Preview4(null)
   
       }
   
       const imageFiveError = (e)=> {
         setImage1Preview5(null)
   
       }
   
   
   
    const selectImageTwo = ()=> {
      document.getElementById('image-two').click();
    }
   
   
    const selectImageThree = ()=> {
      document.getElementById('image-three').click();
    }
   
    
    const selectImageFour = ()=> {
      document.getElementById('image-four').click();
    }
   
    
    const selectImageFive = ()=> {
      document.getElementById('image-five').click();
    }
   
    
    const selectImageOne = ()=> {
      document.getElementById('image-one').click();
    }
   
   

    return (  
        <>
        
       <div className="flex justify-center mt-10">
       <div className="row  ">
        <div className="col justify-content-center ">
          
            <input style={{ display: 'none' }}	id="image-one"	accept="image/*"  onChange={imageOneChangeHandler}	type="file"	name="name"	/>
            <span onClick={selectImageOne}>
            <img onError={()=> imageOneError()}  src={image1Preview1 ? image1Preview1 : profile  }  alt="" className="profileImage rounded profileImage2" />
            </span>
              
              
              <span className="flex profilesmImage">

              <input onError={imageTwoError}  style={{ display: 'none' }}	id="image-two"	accept="image/*"  onChange={imageTwoChangeHandler}	type="file"	name="name"	/>
              <span onClick={selectImageTwo}>
              <img onError={()=> imageTwoError()} className="m-1 small-pic" src={image1Preview2 ? image1Preview2 : singleImage  } alt="" width="60"   />
              </span>


              <input style={{ display: 'none' }}	id="image-three"	accept="image/*"  onChange={imageThreeChangeHandler}	type="file"	name="name"	/>
              <span onClick={selectImageThree}>
              <img  onError={()=> imageThreeError()}  className="m-1 small-pic" src={image1Preview3 ? image1Preview3 : singleImage  } alt="" width="60"   />
              </span>


              <input style={{ display: 'none' }}	id="image-four"	accept="image/*"  onChange={imageFourChangeHandler}	type="file"	name="name"	/>
              <span onClick={selectImageFour}>
              <img onError={()=> imageFourError()} className="m-1 small-pic" src={image1Preview4 ? image1Preview4 : singleImage  } alt="" width="60"   />
              </span>


              <input style={{ display: 'none' }}	id="image-five"	accept="image/*"  onChange={imageFiveChangeHandler}	type="file"	name="name"	/>
              <span onClick={selectImageFive}>
              <img  onError={()=> imageFiveError()} className="m-1 small-pic" src={image1Preview5 ? image1Preview5 : singleImage  } alt="" width="60"   />
              </span>

              
                
                </span>
          </div>
          </div>
       </div>
        
       
     
        </>
    );
}

export default CompositeGroupAddPicture;