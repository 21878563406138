import { useEffect } from 'react';
import Select from 'react-select';


function SalesOrderSelect(props) {


  useEffect(() => {
   

  }, [props?.defaultVal]);

  const customOption = props?.list?.map((item) => {
    return ({ ...item, label: `${item.Sales_Order_No}` });
  });


  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      backgroundColor: '#fffff',
      zIndex: 9999,
    //   width: 250,
    }),

    indicatorSeparator: styles => ({ ...styles, display: 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '#fffff', height: 20, paddingBottom: "45px", marginLeft: "13px" }),
    option: styles => ({ ...styles, backgroundColor: 'white', color: "black" }),
    menuPortal: styles => ({ ...styles, zIndex: 99999 })
  }

  return (
    <>
     
        <Select menuPlacement="auto"
        placeholder="Select one"
          menuPosition="fixed" defaultValue={{label: props?.defaultVal }}
          onChange={(e) => props?.onOptionSelect(e)} options={customOption} styles={customStyles} />
     






    </>
  );
}

export default SalesOrderSelect;
