// import CustomerContext from "../Context/CustomerContext";
import { useEffect, useState } from "react";
import {  SetIVRHistoryStore, GetIVRHistoryStore } from '../../store/IVRAtom';
import { Grid } from "@mui/material";
import {  useParams } from "react-router-dom";
import moment from "moment/moment";
import { GetAdsStoreData } from "../../store/AddsStoreAtom";



function AddsSettingsScroll(props: any) {

  const getAtomStoreData = GetAdsStoreData();
//  const [tableData, setTableData] = useState([]); 
 const { id } = useParams();



 useEffect(()=> {
 
// setTableData(getAtomStoreData.map(ele => { return  {...ele, checked: false}} ))
 },[getAtomStoreData]);




      return ( 
        <>
     <section className="w-30  z-0 mt-3 main2 	 border-2 b">

      {getAtomStoreData?.map((record: any, index: number) => {
        return (

          <div key={index} onClick={() => props.viewItemDetails(record?.Ref_No)}
            className={"flex hover:bg-filterColor border  "
              + (Number(id) === record?.Ref_No ? "app_bg_color1 mb-2 show-cursor" : "mb-2 show-cursor")}
          >
            <Grid container spacing={1}>
              <Grid item xs={1}>
                {/* <label className="ml-1">
                  <input onChange={(e) => onCheckboxClick(e, index)} checked={info?.checked ? info?.checked : ''}
                    type="checkbox" className="w-[2vh] h-[2vh]" />
                </label> */}
              </Grid>
              <Grid 
                item xs={6}>
                <label className="ml-2 ">
                  <span className="subheading font-bold md:text-sm">Total : {record?.Total_Client} </span>{" "}
                  <br></br>
                  <span className="subheading font-bold md:text-sm">
                    <i className="font-bold" >{record?.Ad_Start_Date} </i>
                    {/* {moment(info.Call_Date).format(" DD-MM-YYYY:HH:mm:ss")} */}
                  </span>
                  <br></br>
                  {/* <div className="mt-2 mr-2" >{info?.Template_Name} </div> */}
                </label>
              </Grid>
              <Grid item xs={5}>

                <div>    <label className="ml-2" >
                  {/* {manageStatus(info?.Corporate_Approval_Status_Id, info?.Corporate_Approval_Status_Name)} */}
                  <div className="mt-2 mr-2" >{record?.Ad_Title} </div>

                    </label></div>

              </Grid>
            </Grid>
          </div>

        )
      })

      }

    </section>
        </>
      );

}

export default AddsSettingsScroll;
