import { useContext } from "react";
import HistoryItemSeacrh from "./HistoryItemSeacrh";
import HistoryProductionTable from "./HistoryProductionTable";
import HistoryStatus from "./HistoryStatus";
import { itemsSetupService } from "../services/itemSetup.service";
import { useForm } from "react-hook-form";
import { GetProdMasterData} from '../store/finishItemAtom';
import LogicContext from "../Context/LogicContext";
import underscore from  'underscore';
import './FinishedItemHistory.css';
import {SetTableHistory, GetTableHistory} from '../store/finishItemAtom';
import  moment from 'moment';
import {SetProductionEntryEdit} from '../store/productionAtom';
import {  useLocation, useNavigate } from "react-router-dom";




function FinishedItemHistory(props) {
    const  prodMasterData  =  GetProdMasterData();
    const  getTableHistory  =  GetTableHistory();
    const  setTableHistory  =  SetTableHistory();
    const setEditHistory = SetProductionEntryEdit();
      var masterDataGroup = underscore.groupBy(prodMasterData, "Master_Type");
      const navigate = useNavigate();
      const { pathname } = useLocation();
      var url = pathname.split("/");
      const currModuleId = url[1];
      const currModuleName = url[2];
      const currUserMenu = url[3];

     
      const wareHouseList2 = masterDataGroup[1];
      const statusList = masterDataGroup[2];

    const logicContext = useContext(LogicContext);
    const { register,setValue, getValues, handleSubmit, reset, formState: { errors }} = useForm();
            // setValue('batch_no', props.batch_no);
            if(props?.item_Id){
              setValue('item_id', props?.item_Id);
            }
          




    function selectedWareHousePick(val) {
        setValue("Warehouse_Id", val);
      }


    function selectedStatus(val) {
        setValue("status", val);
      }


      function searchHistory(){
          let list = getValues();
          if(!getValues('Warehouse_Id')){
              return logicContext.showToast('Please select a Wharehouse!', 'warning')
          }
   
          if(!getValues('fr_date')){
              return logicContext.showToast('Please select date [FROM]!', 'warning')
          }
          if(!getValues('to_date')){
              return logicContext.showToast('Please select date [TO]!', 'warning')
          }
   
        itemsSetupService.getProductionHistory(list).then(res => {
            if(res?.data !== undefined){
                setTableHistory(res.data);
            }else{
                return logicContext.showToast('No record found!', 'error')
            }
        }).catch(err => {
          setTableHistory([])
          logicContext.showToast('No record found!', "info");
        })
      }

      function  modifyProduction(production){
        setEditHistory(production);
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${production.Production_Item_Id}`+'_'+ 3)
     
          props.changeTab();
      }


    return ( 
        <>
            <section className="container ">
                  <section className="  mt-2  max-auto max-w-6xl text-filterTextColor">
                    <section className=" ">
                      <section className=" mt-1   md:  ">
                        <section className="">
                          <section className="profile_section ">
                            <div className=" z-90 ">
                              <div className="flex justify-between w-full">
                                <div className="required-q">
                                  <span className="text-xs">From Date</span>{" "}
                                  <br></br>

                                  <input
                                    type="date"
                                    defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                  {...register("fr_date")}
                                    className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                  />
                                </div>

                                <div className="required-q">
                                  <span className="text-xs">To Date</span>{" "}
                                  <br></br>
                                  <input
                                  defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                    type="date"
                                    {...register("to_date")}
                                    className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                  />
                                </div>
                                <HistoryItemSeacrh
                                    list={wareHouseList2}
                                  userSelection={selectedWareHousePick}
                                />
                                   <HistoryStatus
                                  userSelection={selectedStatus}
                                  list={statusList}
                                />

                                <button
                                onClick={searchHistory}
                                  style={{ backgroundColor: "#476EF8" }}
                                  className=" h-[6vh] mt-4  rounded text-white text-xs py-1 mr-2 px-4"
                                >
                                  SEARCH
                                </button>
                              </div>
                            </div>
                            <div className=" z-0 history-search ">
                              <div className="flex justify-center mt-[2.7vh] ">
                                <div className="lg:w-[25vw] ">
                                  <div className="flex    border-2 rounded ">
                                    <button className="flex  bg-inputColor items-center justify-center px-4  ">
                                      <svg
                                        width="24"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        fill="bg-inputColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.4996 10.875C16.4996 11.9875 16.1697 13.0751 15.5516 14.0001C14.9335 14.9251 14.055 15.6461 13.0272 16.0718C11.9994 16.4976 10.8684 16.609 9.77723 16.3919C8.68608 16.1749 7.6838 15.6391 6.89713 14.8525C6.11046 14.0658 5.57473 13.0635 5.35769 11.9724C5.14065 10.8812 5.25204 9.75024 5.67779 8.72241C6.10353 7.69457 6.8245 6.81607 7.74953 6.19798C8.67455 5.5799 9.76209 5.25 10.8746 5.25C12.3659 5.25169 13.7957 5.84487 14.8502 6.89939C15.9047 7.95392 16.4979 9.38368 16.4996 10.875ZM21.5294 21.5304C21.3888 21.6711 21.198 21.7501 20.9991 21.7501C20.8001 21.7501 20.6094 21.6711 20.4687 21.5304L16.4165 17.4783C14.7122 18.9092 12.5213 19.6269 10.3006 19.4817C8.08 19.3366 6.00111 18.3398 4.49749 16.6993C2.99387 15.0587 2.18161 12.9011 2.23008 10.6762C2.27855 8.45139 3.18402 6.33114 4.75766 4.75763C6.3313 3.18413 8.45163 2.27884 10.6765 2.23057C12.9013 2.18229 15.0589 2.99474 16.6993 4.4985C18.3398 6.00226 19.3363 8.08123 19.4813 10.3019C19.6262 12.5225 18.9083 14.7134 17.4773 16.4176L21.5294 20.4698C21.6701 20.6104 21.7491 20.8012 21.7491 21.0001C21.7491 21.199 21.6701 21.3898 21.5294 21.5304ZM10.8746 18C12.2838 18 13.6613 17.5821 14.833 16.7992C16.0047 16.0163 16.918 14.9035 17.4573 13.6016C17.9965 12.2997 18.1376 10.8671 17.8627 9.48498C17.5878 8.10287 16.9092 6.83331 15.9127 5.83686C14.9163 4.84042 13.6467 4.16182 12.2646 3.8869C10.8825 3.61199 9.44991 3.75308 8.14799 4.29236C6.84607 4.83163 5.73329 5.74486 4.95039 6.91656C4.16748 8.08826 3.74961 9.46581 3.74961 10.875C3.75175 12.764 4.5031 14.575 5.83883 15.9108C7.17457 17.2465 8.9856 17.9979 10.8746 18Z"
                                          fill="#45444B"
                                        />
                                      </svg>
                                    </button>
                                    <input
                                      className="px-4 text-sm bg-inputColor z-0 py-2 h-[5vh] w-100"
                                      placeholder="Search in history"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* end */}

                            <section className="mt-3">
                              <HistoryProductionTable
                                modifyProduction={modifyProduction}
                               
                                tableData={getTableHistory}
                              ></HistoryProductionTable>
                            </section>
                          </section>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
        </>
     );
}

export default FinishedItemHistory;