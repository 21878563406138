import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import BillOverviewTable from "./BillOverviewTable";
import { Link } from "react-router-dom";
import BillFileViewer from "./BillFileViewer";
import { useNavigate, useLocation } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function BillsOverview(props) {
  const { billDetails } = props;

  const { pathname } = useLocation();
  // const setPurchaseEdit = SetPurChaseOrderEdit();

  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const navigate = useNavigate();

  const viewPurchaseOrder = (id) => {
    navigate(
      `/${currModuleId}/${currModuleName}/${"purchase-order"}/details/${id}`
    );
  };

  const manageInvoiceStatus = (status) => {
    let defaultClass = "bg-primary text-white";

    switch (status) {
      case "Draft":
        defaultClass = "text-light bg-danger";
        break;
      case "Open":
        defaultClass = "text-light bg-primary";
        break;
      case "Pending Approval":
        defaultClass = "text-dark bg-warning";
        break;
      case "Billed":
        defaultClass = "text-light bg-info";
        break;
      case "Paid":
        defaultClass = "text-light bg-success";
        break;
      case "Received":
        defaultClass = "text-light bg-success";
        break;
      case "Partially Paid":
        defaultClass = "text-light bg-dark";
        break;

      default:
        break;
    }

    return (
      <span
        className={`px-4 py-2.5 rounded font-semibold ${defaultClass} rounded`}
      >
        {status}
      </span>
    );
  };

  return (
    <>
      <section className="mt-2 w-full text-gray-700">
        <div className="border-[1px] border-gray-200 rounded-lg p-4 lg:p-6 bg-white">
          <div className="flex flex-row justify-between">
            <div>
              <h1 className="font-bold text-xl text-gray-600 ">Bill</h1>
              <div className="font-bold text-gray-600 text-base italic">
                {billDetails?.Bill_Number}
              </div>
            </div>
            <div className="py-2.5 rounded px-4 text-sm">
              {manageInvoiceStatus(billDetails?.Bill_Status_Name)}
            </div>
          </div>
          <div>
            <div className="mt-3 flex flex-row items-center">
              <div className="w-[140px]">Bill Ref NO:</div>
              <div className="text-[#4999F6] font-medium">
                {billDetails?.Bill_Ref_No}{" "}
              </div>
            </div>

            <section className="my-2">
              {billDetails?.Purchase_Order_Ref_No && (
                <div
                  onClick={() =>
                    viewPurchaseOrder(billDetails?.Purchase_Order_Ref_No)
                  }
                  className="flex flex-row items-center"
                >
                  <div className="w-[140px]">Purchase Order Number:</div>
                  <div className="text-[#4999F6] font-medium">
                    {billDetails?.Purchase_Order_Ref_No}{" "}
                  </div>
                </div>
              )}
              <div className="flex flex-row items-center">
                <div className="w-[140px]">Terms:</div>
                <div className="text-gray-600 font-medium">
                  {billDetails?.Payment_Term_Name}{" "}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="w-[140px]">Bill Date:</div>
                <div className="text-gray-600 font-medium">
                  {billDetails?.Bill_Date_String}{" "}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="w-[140px]">Due Date:</div>
                <div className="text-gray-600 font-medium">
                  {billDetails?.Due_Date_String}{" "}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="w-[140px]">Rate Type:</div>
                <div className="text-gray-600 font-medium">
                  {billDetails?.Payment_Term_Name}{" "}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="w-[140px]">Discount Type:</div>
                <div className="text-gray-600 font-medium">
                  {billDetails?.Payment_Term_Name}{" "}
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="mt-4 border-[1px] border-gray-200 rounded-lg p-4 lg:p-6 bg-white">
          <div className="flex flex-row justify-between space-x-2">
            <div className="flex-1">
              <div className="flex flex-col lg:flex-row lg:space-x-2">
                <div className="font-semibold text-gray-600 text-sm">
                  Bill To :
                </div>
                <div className="text-[#4999F6] font-semibold text-sm">
                  Worldbay Technologies Ltd
                </div>
              </div>
              <div className="mt-1 flex flex-col lg:flex-row lg:space-x-2">
                <div className="font-semibold text-gray-600 text-sm">TIN :</div>
                <div className="text-sm">16311833-0001</div>
              </div>
              <div className="mt-1 flex flex-col lg:flex-row lg:space-x-2">
                <div className="font-semibold text-gray-600 text-sm">VAT :</div>
                <div className="text-sm">YBV060021065153</div>
              </div>
              <div className="mt-1 flex flex-col lg:flex-row lg:space-x-2">
                <div className="font-semibold text-gray-600 text-sm">RC :</div>
                <div className="text-sm">1065153</div>
              </div>
              <div className="mt-1 flex flex-col lg:flex-row lg:space-x-2">
                <div className="font-semibold text-gray-600 text-sm">
                  Address:
                </div>
                <div className="text-sm">{billDetails?.Vendor_Address}</div>
              </div>
            </div>

            <div className="flex-1">
              <div className="flex flex-col lg:flex-row lg:space-x-2">
                <div className="font-semibold text-gray-600 text-sm">
                  Bill From :
                </div>
                <div
                  onClick={() => props?.viewVendor(billDetails?.Vendor_Id)}
                  className="text-[#4999F6] font-semibold text-sm"
                >
                  {billDetails?.Vendor_Name}
                  <Link to="/" />
                </div>
              </div>
              <div className="mt-1 flex flex-col lg:flex-row lg:space-x-2">
                <div className="font-semibold text-gray-600 text-sm">
                  Bill Date :
                </div>
                <div className="text-sm">{billDetails?.Bill_Date_String}</div>
              </div>
              <div className="mt-1 flex flex-col lg:flex-row lg:space-x-2">
                <div className="font-semibold text-gray-600 text-sm">
                  Due Date :
                </div>
                <div className="text-sm">{billDetails?.Due_Date_String}</div>
              </div>

              <div className="mt-1 flex flex-col lg:flex-row lg:space-x-2">
                <div className="font-semibold text-gray-600 text-sm">
                  Terms :
                </div>
                <div className="text-sm">{billDetails?.Payment_Term_Name}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 border-[1px] border-gray-200 rounded-lg p-4 lg:p-6 bg-white">
          <section className="">
            <h1 className=" font-bold subheading">Attachments :</h1>
            <div>
              <BillFileViewer fileList={billDetails?.Attachments} />
            </div>
          </section>
        </div>

        <div className="mt-4 border-[1px] border-gray-200 rounded-lg p-4 lg:p-6 bg-white ">
          <BillOverviewTable
            billDetails={billDetails}
            tableData={billDetails?.Bill_details}
          ></BillOverviewTable>
        </div>
      </section>
    </>
  );
}

export default BillsOverview;
