import Select from 'react-select';
import { GetSubEntryMasterdata} from "../../store/subscriptionAtom";


function PlanNameSelect(props) {
  const getEntryMasterData = GetSubEntryMasterdata();
  const customOption = getEntryMasterData?.Plan?.map((item)=> {
    return ({...item, label:`${item.Plan_Name} ${item?.Plan_Id}`})
  });

  
  const customStyles = {
    control: base => ({
      ...base,
      height: 44,
      backgroundColor: '#F1F1F1',
      width: 300,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '', marginLeft:"1px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"})
  }

    const handleChange = (value) => {
      props.onSelection(value)
      };


    return ( 
         <Select   styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal}
          options={customOption}
          placeholder="Choose" />
     );
}

export default PlanNameSelect;
