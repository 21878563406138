import { useEffect, useState } from "react";
import Select from 'react-select';
import { itemsSetupService } from "../services/itemSetup.service";



function CompositSelect(props) {

    const customStyles = {
        control: base => ({
          ...base,
          height: 42,
          width:513,
     
        })
      }
    const [list, setList] =  useState([])

    useEffect(() => {
       if(list.length < 1){
        itemsSetupService.getCompositesFormData().then(res => {
            const customOption = res?.data.map((item)=> {
                return ({...item, label:`${item.Item_Name}`})
              });
              setList(customOption)
        });
       }

      }, [list.length]);
    
   


    function itemInInput(item){
        props.userSelection(item)
    }



    return ( 
        <>
        <div className="">
        <small>Item</small>
        <div >
        <Select   styles={customStyles}  onChange={itemInInput} options={list} />

        </div>
        </div>

        
        </>
     );
}

export default CompositSelect;
