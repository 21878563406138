import React from 'react'
import { DataGrid} from "@mui/x-data-grid";
  import PaginationItem from '@mui/material/PaginationItem';
  import { useEffect, useState } from 'react';
import  {GetProductionItems} from '../store/finishItemAtom';
import { GetProductionSelectItem} from '../store/productionAtom';




    const TableHead = [
        { field: 'Item_Name', headerName:'Name' , width: 100 , headerClassName: "tableHeader "},
        { field: 'Total_Item', headerName:'Items' , width: 50 , headerClassName: "tableHeader "},
        { field: 'Closing_Quantity', headerName:'Qty' , width: 65 , headerClassName: "tableHeader "},
        ]


function ProductionScrollTable(props) {
const selectedItem = GetProductionSelectItem();
var itemList = GetProductionItems();
  const [tableData, setTableData] = useState([]);


  useEffect(()=> {
    const rows = itemList?.map((item, i) => {
      return ({...item, id: item.Item_Id})  
  }).filter((item) =>  item.Item_Variety_Id == selectedItem?.Item_Variety_Id);
  setTableData(rows)

  },[selectedItem?.Item_Variety_Id, itemList])


function OnItemCellClick (event){
  props.selectItem(event.row)
}

  return (
    <>
    <div className="">
    
    <div  style={{ height: 450, width: '100%' }}>
          <DataGrid
            columns={TableHead}
            rows={tableData}
            components={{
              Pagination: PaginationItem,
            }}
            checkboxSelection={false}
            onRowClick={(event)=> {  OnItemCellClick(event);
            }}
            sx={{
           
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
                cursor: 'pointer',
              },
            }}
          />
        </div>
    </div>
            
            </>
  )
}

export default ProductionScrollTable;