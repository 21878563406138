import * as React from "react";
import { useEffect, useState } from "react";

import {
  DataGrid,
  gridPageCountSelector,
  GridToolbar,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { customerService } from "../../services/customer.service";
import {
  SetCustomerRewardAtom,
  GetCustomerRewardAtom,
} from "../../store/customerRewardpoint";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      rowsPerPageOptions={[6]}
      checkboxSelection={true}
      components={{
        Toolbar: CustomToolbar,
        Pagination: CustomPagination,
      }}
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const tableHead = [
  { field: "Ref_No", headerName: "Ref", width: 130 },
  { field: "Transaction_Date_String", headerName: "Date", width: 130 },
  { field: "Reward_Earn_Title", headerName: "Earn Title", width: 130 },
  {
    field: "Reward_Cowries_Quantity",
    headerName: "Reward Cowries",
    width: 130,
  },
  { field: "Reward_Earn_Description", headerName: "Description", width: 300 },
];

// function CustomPagination() {
//   const apiRef = useGridApiContext();
//   const page = useGridSelector(apiRef, gridPageSelector);
//   const pageCount = useGridSelector(apiRef, gridPageCountSelector);

//   return (
//     <Pagination
//       color="primary"
//       variant="outlined"
//       shape="rounded"
//       page={page + 1}
//       count={pageCount}
//       // @ts-expect-error
//       renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
//       onChange={(event, value) => apiRef.current.setPage(value - 1)}
//     />
//   );
// }

export default function RewardPointTab({ customer_id }) {
  const [tableList, setTableList] = useState([]);
  const setRewardList = SetCustomerRewardAtom();
  const rewardAtomList = GetCustomerRewardAtom();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  console.info("See ", rewardAtomList);

  useEffect(() => {
    if (!rewardAtomList.length) {
      getDataFromServer();
    } else {
      setTableList(rewardAtomList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id, rewardAtomList.length]);

  const getDataFromServer = () => {
    customerService.getRewardHistory(customer_id).then((res) => {
      if (res.data?.length) {
        let tempList = res.data.map((item) => {
          return { ...item, id: item?.Ref_No };
        });
        setRewardList(tempList);
      }
    });
  };

  // function OnItemCellClick(event) {
  //   const id = event.row['Invoice_Ref_No'];
  //   navigate(`/${currModuleId}/${currModuleName}/invoices/details/${id}`);
  // }

  return (
    <>
      <div className="ml-1 @container">
        <div className="mt-1 text-gray-600 text-xl font-bold">Reward Point</div>

        <DataGrid
          className="mt-4 overflow-x-auto bg-white outline-none border-[1px] border-gray-200 rounded-xl"
          style={{ height: 600, width: "100%" }}
          columns={tableHead}
          rows={tableList}
          pageSize={5}
          rowsPerPageOptions={[6]}
          // onRowClick={(event) => {
          //   OnItemCellClick(event);
          // }}

          components={{
            Pagination: CustomPagination,
          }}
          onCellClick={(event) => {
            event.defaultMuiPrevented = true;
          }}
          // sx={{
          //   borderColor: "primary.light",
          //   "& .MuiDataGrid-cell:hover": {
          //     color: "primary.main",
          //     cursor: "pointer",
          //   },
          // }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{ toolbar: { showQuickFilter: true } }}
        />
      </div>
    </>
  );
}
