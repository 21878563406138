import React from "react";
import AddTaxSelect from "./AddTaxSelect";
import PlanNameSelect from "./PlanNameSelect";

function AddSubPlanTable(props) {


  return (
    <div className="row container">
      <div className="">
        <table className=" table mt-3">
          <tr>
            <th
              scope="col"
              style={{ backgroundColor: "#F1F1F1", color: "#45444B", width:"30%" }}
              className="text-sm">
              Name
            </th>

            <th
              scope="col"
              style={{ backgroundColor: "#F1F1F1", color: "#45444B",width:"15%" }}
              className="text-sm">
              {" "}
              Minimum Pay{" "}
            </th>
            <th
              scope="col"
              style={{ backgroundColor: "#F1F1F1", color: "#45444B",width:"10%"  }}
              className="text-sm">
              Qty{" "}
            </th>
            <th
              scope="col"
              style={{ backgroundColor: "#F1F1F1", color: "#45444B",width:"10%" }}
              className="text-sm "
            >
              Unit Price{" "}
            </th>
            <th
              scope="col"
              style={{ backgroundColor: "#F1F1F1", color: "#45444B" }}
              className="text-sm "
            >
              Tax{" "}
            </th>
            <th
              scope="col"
              style={{ backgroundColor: "#F1F1F1", color: "#45444B" }}
              className="text-sm "
            >
              Amount{" "}
            </th>
          </tr>

          <tbody>
            <tr>
              <td className="">
                <PlanNameSelect defaultVal={props?.defaultVal} onSelection={props?.onPlanNameSelect} />
                  </td>

              <td className="text-sm">   <div className="">
                    <label className="mt-2 ">
                      <div     style={{ border: "solid 1px black" }} className="relative ">
                        <input
                          className="bg-filterInput pl-[4vw]  w-[8vw] bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="number"
                          name="minimum_pay"
                          placeholder="0.00"
                          onChange={props?.onPlanAmountChange}
                          value={props?.currentPlan?.Plan_Minimum_Payment}
                        />

                        <div
                      
                          className="bg-white top-0 px-2 text-xs   py-[1.5vh]  left-0 absolute"
                        >
                          NGN
                        </div>
                      </div>
                    </label>
                  </div>
                  </td>

              <td className="text-sm">

              <div  className="mt-2">
                    <label style={{ border: "solid 1px black" }} className="">
                      <span className="">
                        <input
                          className="bg-filterInput  py-[1.3vh]   w-[5vw] bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="number"
                          name="quantity"
                          placeholder="0.00"
                          onChange={props?.onPlanQuantityChange}
                          value={props?.currentPlan?.Quantity}
                        />
                      </span>
                    </label>
                  </div>
              </td>
              <td className="text-sm">
              <div className="mt-2">
                    <label style={{ border: "solid 1px black" }}  >
                  
                        <input
                          className="bg-filterInput  py-[1.3vh] w-[6vw]  bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          name="unit_price"
                          type="number"
                          placeholder="0.00"
                          onChange={props?.onPlanUnitChange}
                          value={props?.currentPlan?.Plan_Price}
                      
                        />
                  
                    </label>
                  </div>
              </td>
              <td className="text-sm">

              <AddTaxSelect defaultVal={props?.defaultTax}  onSelection={props?.onPlanTaxSelect} />
              </td>
              <td className="text-sm"><div className="pl-2 ml-2">
                    <label className="">
                      <span className="">
                        {"\u20A6"} 
                      
                      </span>
                      <span className="ml-2">
                      {props?.currentPlan?.Plan_Price * props?.currentPlan?.Quantity}
                      </span>
                    </label>
                  </div></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AddSubPlanTable;
