import { useEffect, useState } from "react";
import Select from 'react-select';


    // const optionsList = [
    //     { value: '0', label: 'All' },
    //     { value: 'A', label: 'Active' },
    //     { value: 'I', label: 'Inactive' }
    //   ]



function HistoryStatus(props) {

    const customOption = props.list.map((item)=> {
        return ({...item, label:`${item.Name}`})
      })

      useEffect(()=> {
        props.userSelection('0');
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[props?.list]);
      

function itemInInput(item){
    props.userSelection(item.Code)
}

    return ( 
        <>
        
        <div className="">
        <small>Status</small>
        <div >
        <Select  className="lg:w-[10vw]" defaultValue={0} defaultInputValue={'All'} onChange={itemInInput} options={customOption} />

        </div>
        </div>

        </>
     );
}

export default HistoryStatus ;