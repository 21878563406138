import React, { useRef, useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import moment from "moment/moment";
import message from "../../assets/message.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { invoiceService } from "../../services/invoice.service";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
// import InvoicePaymentTable from "./InvoicePaymentTable";
// import InvoiceDetailsTable from "./InvoiceDetailsTable";
import {GetRecoilCorporateApprovedList, SetRecoilCorporateApprovedList} from "../../store/corporateApproveList";
import { corporateService } from "../../services/corporation.service";
import LogicContext from "../../Context/LogicContext";
import CorporateScroll from "../corprate_page/CorporateScroll";
import CoAppFileModal from "./CoAppFileModal";
import CoAppAttachmentModal from "./CoAppAttachmentModal";
import CorporateAppScroll from "./CorperateAppScroll";
import CorpFileviewer from "./CorpFileViewer";





const CorporateAppDetailsPage = () => {
  const [selectedList, setSelectedList] = useState([]);
  const myPromiseModal = createModal(GeneralModal);
  const getRecoilData = GetRecoilCorporateApprovedList();
  const setRecoilData = SetRecoilCorporateApprovedList();


  const { corporate_id } = useParams();
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const [corporateData, setCorporateData] = useState({});
  const [corporateDocs, setCorporateDocs] = useState([]);
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];


  useEffect(() => {
       document.title = 'Corporate details';
    getDetailsData(corporate_id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corporate_id]);


  
  const getDetailsData = async (id) => {
    corporateService.getCorporateDetails(id).then((response) => {
      console.log('LOGGGGG ', response.data);

      if (response.data?.Corporate_Info) {
        setCorporateData(response.data?.Corporate_Info[0]);
        setCorporateDocs(response.data?.Document_Info);
      }
    });
  };


  function viewItemDetails(id) {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
    getDetailsData(id);
  }



  function onSelection(list) {
    let tempList = list?.filter((item) => item.checked === true);
    setSelectedList(tempList);
  }




  const displayStatus = (status, id) =>{
    let defaultClass = 'color_1';

        switch (id) {
          case 1:
          defaultClass = 'color_1';
            break;
          case  2:
          defaultClass = 'color_2';
            break;
          case 3:
          defaultClass = 'color_3';
            break;
          case 4:
          defaultClass = 'color_4';
            break;
          case 5:
          defaultClass = 'color_5';
            break;
        
          case 6:
          defaultClass = 'color_6';
            break;
        
          default:
            break;
        }


        return (
          <span className={`py-2 p-2 text-md  ${defaultClass} rounded`}>
          {status}
        </span>
        )
  }


   const approveCorporate = async()=> {
    await myPromiseModal({title: "confirm your action!",
        body: `Do you want to approve Corporate?`,showInput: true,
        placeHolder: "Remark",
      }).then((result) => {
        if(result?.decision === true){
            corporateService.approveOrCancelCorporateRequest(corporate_id,6, result.message ? result.message : 'welcome to Grocedy').then(res => {
                const data =  logicContext.httpResponseManager(res.data[0]);
                console.log('APP ', data);
              if(data?.message_id == 1){
                logicContext.showToast(data?.message_text, 'success');
                getDetailsData(corporate_id);
                getCorpListList();
              }
                
            }).catch(eeer => {
                console.log("Err ", eeer);
            });
        }

      });
   }


   const declineCorporate = async()=> {
    await myPromiseModal({title: "confirm your action!",
        body: `Do you want to Decline customer request?`,showInput: true,
        placeHolder: "Remark",
      }).then((result) => {
      
        if(result?.decision === true){

          if(!result?.message){
            return logicContext.showToast('Remark is required for selected action!');
          }
            corporateService.approveOrCancelCorporateRequest(corporate_id,7, result.message).then(res => {
              const data =  logicContext.httpResponseManager(res.data[0]);
              if(data?.message_id == 1){
                logicContext.showToast(data?.message_text, 'success');
                getDetailsData(corporate_id);
                getCorpListList();
              }
            });
        }

      });
   }


   const resendCorporate = async()=> {
    await myPromiseModal({title: "confirm your action!",
        body: `Do you want to Decline customer request?`,showInput: true,
        placeHolder: "remark",
      }).then((result) => {
    
        if(result?.decision === true){
          if(!result?.message){
            return logicContext.showToast('Remark is required for selected action!');
          }
            corporateService.approveOrCancelCorporateRequest(corporate_id,10, result.message).then(res => {
                
              const data =  logicContext.httpResponseManager(res.data[0]);
              if(data?.message_id == 1){
                getDetailsData(corporate_id);
                logicContext.showToast(data?.message_text, 'success');
                getCorpListList();
              }
            });
        }

      });
   }


   const displayButtons = () => {

    let result =  null;
    console.log('Look ', getRecoilData, 'vs ', corporateData?.Corporate_Approval_Status_Id);

    if(getRecoilData?.approval_permission?.length === 1){

      if(getRecoilData.approval_permission[0]?.Approval_Access_Id === corporateData?.Corporate_Approval_Status_Id){
         // Level one approval
        result = (
          <>
          <button onClick={()=> approveCorporate()} className="btn btn-primary btn-md mr- btn-sm">APPROVE</button>
           <button onClick={()=> declineCorporate()} className="btn btn-danger btn-md mx-2 btn-sm">DECLINE</button>
           <button onClick={()=> resendCorporate()} className="btn btn-success btn-md mr-5 btn-sm">RESEND</button>
         </> 
        )
       
      }
    
    }else  if(getRecoilData.approval_permission?.length === 2){

      if(getRecoilData.approval_permission[1]?.Approval_Access_Id === corporateData.Corporate_Approval_Status_Id){
         // Level two approval
        result = 
        (
          <>
          <button onClick={()=> approveCorporate()} className="btn btn-primary btn-md mr- btn-sm">APPROVE</button>
           <button onClick={()=> declineCorporate()} className="btn btn-danger btn-md mx-2 btn-sm">DECLINE</button>
           <button onClick={()=> resendCorporate()} className="btn btn-success btn-md mr-5 btn-sm">RESEND</button>
         
         </> 
        )
        
      }else if (getRecoilData.approval_permission[0]?.Approval_Access_Id === corporateData.Corporate_Approval_Status_Id){
        // Level one approval
        result = 
        (
          <>
          <button onClick={()=> approveCorporate()} className="btn btn-primary btn-md mr- btn-sm">APPROVE</button>
           <button onClick={()=> declineCorporate()} className="btn btn-danger btn-md mx-2 btn-sm">DECLINE</button>
           <button onClick={()=> resendCorporate()} className="btn btn-success btn-md mr-5 btn-sm">RESEND</button>
         
         </> 
        )

      }
      
    }
    return result
    
   }

   function getCorpListList(){
    corporateService.getCorporateApprovedList().then( response => {
      console.info('COR_APPP_Data', response.data);
      if(response?.data.Display_Column){
        setRecoilData(response?.data);
     
      }
  });

  }



  return (
    <>
      <section className="  mt-2 ">
        
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <div className="flex justify-end  ">
             
                    {/* <CoAppFileModal customerData={corporateData}  />
                    <CoAppAttachmentModal customerData={corporateData} /> */}
                    
                  </div>
          </Grid>
          

          <Grid item xs={12}>
            <section className="flex relative justify-between ">
                <CorporateAppScroll
                  onSelection={onSelection}
                  viewItemDetails={viewItemDetails}
                ></CorporateAppScroll>
         

              <section className="w-full z-50  ">
                <section className=" ml-[2vw] mt-2 max-auto max-w-6xl text-filterTextColor">
                  {/* Package page  */}

                  <Grid className="mt-2 border-2 p-2" container spacing={2}>
                 
                    <Grid item xs={6} className=" my-2">
                   
                    <span className="#476ef8 font-bold">  Corporate Information</span>
                      <div>
                        <span className="#476ef8 font-bold"> Corporate Name : <Link  className="text-primary" to={`/${currModuleId}/${currModuleName}/corporate/details/${corporateData?.Corporate_Id}`} >{corporateData?.Corporate_Name}</Link> </span>
                      </div>
                      <div>
                        <span className="#476ef8 font-bold"> Code : <Link  className="text-primary" to={`/${currModuleId}/${currModuleName}/corporate/details/${corporateData?.Corporate_Id}`} >{corporateData?.Corporate_Short_Code}</Link> </span>
                      </div>
                      <Grid>
                      <div>
                        <div>
                          <span className="text-sm">
                            Corporate ID : <span className="font-bold" >{corporateData?.Corporate_Id}</span>
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Corporate Type : <span className="font-bold" > {corporateData?.Corporate_Type_Name}</span>
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                          Corporate Phone : <span className="font-bold" >{corporateData?.Corporate_Contact_Person_Mobile}</span>
                          </span>
                        </div>
                      </div>
                      </Grid>
                  
                      <Grid item xs={8}>
                   
                      <div>
                        
                        <div>
                          <span className="text-sm">
                            Created Date : <span className="font-bold" >{moment(corporateData?.Encoded_Date).format("DD-MM-Y")}</span>
                          </span>
                        </div>
                        <div>
                         <span className="text-sm">
                            Employee:  <span className="font-bold" > {corporateData?.Number_Of_Officer_Name}</span>
                             </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Created Time :  <span className="font-bold" >{moment(corporateData?.Encoded_Date).format("hh:mm:ss a")}</span>
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Subscription Plan :  <span className="font-bold" >{corporateData?.Subscription_Name}</span>
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                             Duration :  <span className="font-bold" >{corporateData?.Subscription_Period}</span>
                          </span>
                        </div>
                      </div>
                    </Grid>
                  
                    </Grid>
                    <Grid item xs={4} className=" my-2">
                    <div className="flex  justify-end">
                        {displayStatus(corporateData?.Corporate_Approval_Status_Name, corporateData?.Corporate_Approval_Status_Id)}
                      </div>
                    </Grid>
                    <Grid item xs={12} className="border-2 my-2">
                      <div>
                      <span className="#476ef8 font-bold"> Contact Person Information</span>
                      <div>
                          <span className="text-sm">
                             Name : <span className="font-bold" >{corporateData?.Corporate_Name}</span>
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Email Address : <span className="font-bold" >{corporateData?.Corporate_Email_Address}</span>
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Contact Person : <span className="font-bold"  >{corporateData?.Corporate_Contact_Person}</span>
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Contact Number : <span className="font-bold"  >{corporateData?.Corporate_Contact_Person_Mobile}</span>
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Agreement Date : <span className="font-bold" >{moment(corporateData?.Agreement_Date).format("LLLL")}</span>
                          </span>
                        </div>

                        <div>
                          <span className="text-sm">
                            Address : <span  className="font-bold" >{corporateData?.Corporate_Adddress},{corporateData?.City_Name}
                            , {corporateData?.Country_Name} </span>
                            </span>
                        </div>

                        <div>
                          <span className="text-sm">
                            Role : <span  className="font-bold" > </span>
                            </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} className="border-2 my-2">
                      <div>
                      <span className="#476ef8 font-bold">Approval Information</span>
                        <div>
                          <span className="text-sm">
                            Review by : <span className="font-bold" >{corporateData?.Encoded_By_Name}</span>
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Review Date : <span className="font-bold" >{corporateData?.Encoded_By_Name}</span>
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">Type : <span className="font-bold" >{corporateData?.Corporate_Type_Name}</span> </span>
                        </div>
                        <div>
                          <span className="text-sm">Review Remark :  </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
 
                  {/* table */}
                  <table class="table mt-4">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">File Name</th>
                        <th scope="col">File URl</th>
                      </tr>
                    </thead>
                    <tbody>
                      {corporateDocs?.map((doc, index)=> {
                        return (
                          <>
                              <tr>
                        <th scope="row">{index + 1} </th>
                        <td>{doc?.File_Name} </td>
                        <td> 
                          <CorpFileviewer fileSource={doc?.File_URL} />
                           </td>
                      </tr>
                          
                          </>
                        )
                      })}
                  
                    
                    </tbody>
                  </table>
                  {/* table end */}
                  
                  <div className=" mt-5 flex justify-end">

                  {displayButtons()}
                  
                </div>
                       
                </section>
               
              </section>
            </section>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default CorporateAppDetailsPage;
