import React, { useRef, useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import moment from "moment/moment";
import message from "../../assets/message.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";


import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
// import InvoicePaymentTable from "./InvoicePaymentTable";
// import InvoiceDetailsTable from "./InvoiceDetailsTable";
import {
  SetCorporateEdit,
} from "../../store/corporateAtom";
import { rewardService } from "../../services/Reward.service";
import LogicContext from "../../Context/LogicContext";
import RewardScroller from "./RewardScroller";


// import { useReactToPrint } from 'react-to-print';



const RewardHistoryDetails = () => {
  const [selectedList, setSelectedList] = useState([]);
  const myPromiseModal = createModal(GeneralModal);
  // const getBulkActions = GetInvoiceBulkAction();

  const {  id } = useParams();
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const [historyDetails, setHistoryDetails] = useState({});
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];



  useEffect(() => {
       document.title = 'Reward history details';
    getHistoryDetails(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  
  const getHistoryDetails = async (id) => {
    rewardService.rewardHistoryDetails(id).then((response) => {
      console.log("History .. ", response.data);
      if (response.data[0]) {
        setHistoryDetails(response.data[0]);
      }
    });
  };


  function viewItemDetails(id) {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
    getHistoryDetails(id);
  }



  const editCurrentData = async () => {
    const response = await myPromiseModal({title: "Modify Corporate Data",
      body: " Do you want to modify this record?",showInput: false,
      placeHolder: ""});
  
  };

  function onSelection(list) {
    let tempList = list?.filter((item) => item.checked === true);
    setSelectedList(tempList);
  }




  const manageStatus = (statusCode, statusName) =>{
    let defaultClass = 'bg-primary text-white';

    switch (statusCode) {
      case 1:
      defaultClass = 'color_1';
        break;
      case  2:
      defaultClass = 'color_2';
        break;
      case 3:
      defaultClass = 'color_3';
        break;
      case 4:
      defaultClass = 'color_4';
        break;
      case 5:
      defaultClass = 'color_5';
        break;
    
      case 6:
      defaultClass = 'color_6';
        break;
    
      default:
        break;
    }

        return (
          <span className={` py-2 px-4 h5 ${defaultClass} rounded`}>
          {statusName}
        </span>
        )

    
      
  }



  return (
    <>
      <section className="  mt-2 ">
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <section className="mt-4 max-auto max-w-6xl d-flex flex-row-reverse ">
              <div className="flex flex-wrap justify-between md:">
                <div>
             
                  <div className="flex ">
                   
                    {/* <button
                      style={{ borderRadius: "5px" }}
                      className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                    >
                      <img src={paper} alt="" className="" />
                    </button> */}
                    {/* <button
                      style={{ borderRadius: "5px" }}
                      className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                    >
                      <img src={pdf} alt="" className="" />
                    </button> */}
                    <button
                      style={{ borderRadius: "5px" }}
                      className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                    >
                      <img src={printer} alt="" className="" />
                    </button>

                    {/* <button
                      style={{ borderRadius: "5px" }}
                      onClick={editCurrentData}
                      className="px-3 mr-2 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 "
                    >
                      <img src={pencil} alt="" />
                    </button>
                    <button
                      // onClick={deletePaymentReceive}
                      style={{ borderRadius: "5px" }}
                      className="  px-3 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 ml-2"
                    >
                      <img src={trash} alt="" />
                    </button> */}
                  </div>
                </div>
              </div>
            </section>
          </Grid>
          

          <Grid item xs={12}>
            <section className="flex relative justify-between ">
              <RewardScroller
                onSelection={onSelection}
                viewItemDetails={viewItemDetails}
              ></RewardScroller>

              <section className="w-full z-50  ">
                <section className=" ml-[2vw] mt-2 max-auto max-w-6xl text-filterTextColor">
                  {/* Package page  */}

                  <Grid className="mt-2 border-2 p-2" container spacing={2}>
                    <Grid item xs={8} className=" my-2">
                      <div>
                        <span className="#476ef8 font-bold text-primary">{historyDetails?.Corporate_Name} </span>
                      </div>
                      <div>
                        <div> Corporate ID :
                          <span className="text-sm font-bold ml-1">
                             {historyDetails?.Corporate_Id}
                          </span>
                        </div>
                        <div> Ref Number : 
                          <span className="text-sm font-bold ml-1">
                            {historyDetails?.Ref_No}
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4} className=" my-2">
                    
                        <div>
                          {/* Status */}
                          <span className="text-sm">
                             {manageStatus(historyDetails?.Corporate_Approval_Status_Id, historyDetails?.Corporate_Approval_Status_Name)}
                          </span>
                        </div>
                   
                    </Grid>
                    <Grid item xs={12} className="border-2 my-2">
                      <div>
                        <div> Transaction Date :
                          <span className="text-sm font-bold ml-1">
                             {moment(historyDetails?.Transaction_Date).format("dddd, MMMM Do YYYY, h:mm:ss a")}
                          </span>
                        </div>
                        <div> Wallet Transaction Ref :
                          <span className="text-sm font-bold ml-1">  {historyDetails?.Wallet_Transaction_Ref_No} </span>
                        </div>
                       
                      </div>
                    </Grid>
                    <Grid item xs={12} className="border-2 my-2">
                      <div>
                        <div> Email Address : 
                          <span className="text-sm font-bold ml-1">
                            {historyDetails?.Customer_Email_Address}
                          </span>
                        </div>
                        <div>  Name :
                          <span className="text-sm font-bold ml-1">
                            {historyDetails?.Customer_Full_Name}
                          </span>
                        </div>
                        <div> Customer ID :
                          <span className="text-sm font-bold ml-1">
                             {historyDetails?.Customer_Id}
                          </span>
                        </div>
                        <div>
                        Customer Mobile :
                          <span className="text-sm font-bold ml-1">
                             {historyDetails?.Customer_Mobile_No}
                          </span>
                        </div>

                      </div>
                    </Grid>

                    <Grid item xs={12} className="border-2 my-2">
                      <div>
                        <div> Title :
                          <span className="text-sm font-bold ml-1">
                              {historyDetails?.Reward_Earn_Title}
                          </span>
                        </div>
                        <div> Description :
                          <span className="text-sm font-bold ml-1">
                              {historyDetails?.Reward_Earn_Description}
                          </span>
                        </div>
                        </div>
                        <div>
                        Reward cowries quantity :
                          <span className="text-sm-1 font-bold ml">
                             {historyDetails?.Reward_Cowries_Quantity}
                          </span>
                        </div>
                        <div>
                        Reward transaction type :
                          <span className="text-sm font-bold ml">
                             {historyDetails?.Transaction_Type_Name}
                          </span>
                        </div>
                        <div>
                        Transaction amount :
                          <span className="text-sm font-bold ml">
                          <CurrencyFormat
                            className={"font-bold " }
                              value={parseFloat(historyDetails?.Wallet_Transaction_Amount)?.toFixed(2)}
                              displayType={"text"}  thousandSeparator={true} prefix={"₦"} />
                          </span>
                        </div>
                     
                    </Grid>

                    {/* <Grid item xs={12} className="border-2 my-2">
                      <div>
                        <div>
                          <span className="text-sm">
                            Maximum discount  : {historyDetails?.maximum_discount_percentage}%
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Subscription fee(s) : {historyDetails?.subscription_fees}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Subscription activation date : { moment(historyDetails?.corporateData?.subscription_activation_date).format("DD-MM-Y")}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">Trial : {historyDetails?.trail_period} {historyDetails?.trail_period_type_name} </span>
                        </div>
                        <div>
                          <span className="text-sm">Remark :  </span>
                        </div>
                      </div>
                    </Grid> */}
                  </Grid>
                </section>
              </section>
            </section>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default RewardHistoryDetails;
