import Select from 'react-select';
import { GetInvoiceEntryMasterData} from "../../store/invoiceAtom";




function AddInvoiceCustomer(props) {
  const masterData = GetInvoiceEntryMasterData();

  const customOption = masterData['Customers']?.map((item)=> {
    return ({...item, label:`${item?.Customer_Full_Name}`})
  });



  
  const customStyles = {
    control: base => ({
      ...base,
      height: 49.9,
      backgroundColor: '#F1F1F1',
      // width: 512,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"})
  }

    const handleChange = (value) => {
      props.onCustomerSelect(value)
      };


    return ( 
        <>
   
         <Select    styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal} options={customOption}/>


        </>
     );
}

export default AddInvoiceCustomer;
