

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";

const selectedProductionItemAtom = atom({key:'selectedProductionItemAtom', default:{Item_Variety_Id: 1}});
const productionEntryEdit = atom({key:'productionEntryEdit', default:{}});

export  const SetProductionSelectItem = () => useSetRecoilState(selectedProductionItemAtom);
export  const GetProductionSelectItem = () => useRecoilValue(selectedProductionItemAtom);
export const ResetProductionSelectItem = ()=> useResetRecoilState(selectedProductionItemAtom);

export  const SetProductionEntryEdit = () => useSetRecoilState(productionEntryEdit);
export  const GetProductionEntryEdit = () => useRecoilValue(productionEntryEdit);
export const ResetProductionEntryEdit = ()=> useResetRecoilState(productionEntryEdit);

