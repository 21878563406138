import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Table } from "react-bootstrap";
import CurrencyFormat from 'react-currency-format';




function InvoiceDetailsTable(props) {

return (

    <>
<div className="mt-2">

<TableContainer className="mt-3">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <colgroup>
                    {/* <col style={{width:'10%'}}/>
                    <col style={{width:'40%'}}/>
                    <col style={{width:'10%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/> */}
              </colgroup>
            <TableHead style={{ backgroundColor: "#F1F1F1" }}>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>item & Description</TableCell>
                <TableCell  align="center">Quantity</TableCell>
                <TableCell  align="right">Rate</TableCell>
                <TableCell  align="right">Amount</TableCell>
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody className=" mb-3">
              {props?.tableData?.map((item, i) => {
                return (
                  <TableRow className="border-1"
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{item.Plan_Id} </TableCell>
                    <TableCell align="left">{item.Plan_Name} </TableCell>
                    <TableCell align="center">{item.Plan_Sales_Quantity} </TableCell>
                    <TableCell align="right">
                      <CurrencyFormat value={parseFloat(item.Plan_Sales_Rate).toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                     </TableCell>
                    <TableCell align="right">
                    <CurrencyFormat value={parseFloat(item.Plan_Sales_Quantity * item.Plan_Sales_Quantity).toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                      
                    </TableCell>
             
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
</div>
    
    </>
  );
}

export default InvoiceDetailsTable;