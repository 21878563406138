import { useEffect, useState } from 'react';
import Select from 'react-select';


function GeneralSelect(props) {
  const { onOptionSelect, list, defaultVal} = props;
  const [optionList, setOptionList] = useState([]);


  useEffect(() => {
    // console.info('def ', props?.defaultVal);

  const customOption = list?.map((item) => {
    return ({ ...item, label: `${item.Name}` });
  });
  setOptionList(customOption);


  }, [props?.defaultVal, list?.length]);



  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      backgroundColor: '#F1F1F1',
      zIndex: 9999,
      width: 200,
    }),

    indicatorSeparator: styles => ({ ...styles, display: 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '', height: 20, paddingBottom: "45px", marginLeft: "13px" }),
    option: styles => ({ ...styles, backgroundColor: 'white', color: "black" }),
    menuPortal: styles => ({ ...styles, zIndex: 99999 })
  }

  return (
    <>
      <div style={{ width: '300px' }}>
        <Select menuPlacement="auto"
        placeholder="Select"
          menuPosition="fixed" 
          key={defaultVal}
          defaultValue={{label:defaultVal }}
          onChange={(selection) => onOptionSelect(selection)} options={optionList}  styles={customStyles} />
      </div>






    </>
  );
}

export default GeneralSelect;
