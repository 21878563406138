import { atom , useSetRecoilState, useRecoilValue, selector} from "recoil";



export const creditNoteAtom = atom({key:'Credit_note_atom',default:{credit_note:[], status_info:[]}});
export const creditNotEditAtom = atom({key:'Credit_note_edit_atom',default:{}});


export  const SetCreditNoteListAtom = () => useSetRecoilState(creditNoteAtom);
export  const GetCreditNoteListAtom = () => useRecoilValue(creditNoteAtom);


export  const SetEditCreditNoteAtom = () => useSetRecoilState(creditNotEditAtom);
export  const GetEditCreditNoteAtom = () => useRecoilValue(creditNotEditAtom);





