import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ShareIcon from '@material-ui/icons/Share';
import { useForm } from "react-hook-form";
import { voucherService } from '../../services/voucherService';




export default function CorporateShareModal(props) {
  const {triggerData} = props;
  
    const [open, setOpen] = React.useState( false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { register, setValue, getValues, handleSubmit, control, reset, formState: { errors } } = useForm();


    useEffect(()=> {
      setOpen(props?.editTrigger );
      if(triggerData?.Reciepient_Name){
        setValue('Reciepient_Name', triggerData.Reciepient_Name);
      }else{
        setValue('Reciepient_Name', '');
      }
      if(triggerData?.Reciepient_Email_Address){
        setValue('Reciepient_Email_Address', triggerData.Reciepient_Email_Address);
      }else{
        setValue('Reciepient_Name', '');
      }
      if(triggerData?.Reciepient_Mobile_Number){
        setValue('Reciepient_Mobile_Number', triggerData.Reciepient_Mobile_Number);
      }else{
        setValue('Reciepient_Name', '');
      }

    },[props?.editTrigger, triggerData]);
    
  

    const handleClose = () => {
        setOpen(false);
    };



    const onSubmit = async () => {
      const newData = getValues();
      props.onSubmitRecord({...triggerData, ...newData});
      const updateData =  {reciepient_name: newData.Reciepient_Name, reciepient_mobile: newData?.Reciepient_Mobile_Number,
        reciepient_email: newData?.Reciepient_Email_Address, ref_no: triggerData.Ref_No};
     
      if(triggerData?.Voucher_Status_Id === 1){
        voucherService.updateVoucher(updateData).then(res  => {
        });
      }
      setOpen(false);

    }



    return (
        <React.Fragment>
         
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title">

                <div className='jumbotron border-2 p-4' >
                 
                    <div className="list-group">
                      <div className='clearfix' >
                        <div className='float-left' >
                        <span className="list-group-item list-group-item-action active">
                        {triggerData?.Voucher_Number}
                    </span>
                        </div>
                        <div className='float-right' >
                    <h1 onClick={handleClose} slot='end' className='border-2 p-1 m-2 bg-danger text-light font-weight-bold show-cursor' >X</h1>
                        </div>

                      </div>
                  
                    <span className="list-group-item list-group-item-action"> 
                    <span className='text-primary font-weight-bold' >
                    Please enter recipient information.
                    </span>
                    </span>
                    </div>
                </div>
                {/* table */}
                <form onSubmit={handleSubmit(onSubmit)}  >
                <ul className="list-group">
                  <li className="list-group-item"> Name: <input   {...register("Reciepient_Name")} className='border-2' placeholder='   Enter name' size='3' /> </li>
                  <li className="list-group-item"> Email: <input   {...register("Reciepient_Email_Address")} className='border-2' placeholder='   Enter name' size='3' /> </li>
                  <li className="list-group-item"> Mobile: <input   {...register("Reciepient_Mobile_Number")} className='border-2' placeholder='   Enter name' size='3' /> </li>
                </ul>

                <div className='text-center' >
                  <button className='btn  bg-primary m-2 text-light' type='submit' >SUBMIT</button>

                </div>

                </form>
    
                  


            </Dialog>
        </React.Fragment>
    );
}