import Select from 'react-select';
import { GetInvoiceEntryMasterData} from '../../store/invoiceAtom';



function AddOnInvoiceTermsSelect(props) {
  const masterData = GetInvoiceEntryMasterData();

  const customOption = masterData['Payment_Term']?.map((item)=> {
    return ({...item, label:`${item.Payment_Term_Name}`})
  })

  
  const customStyles = {
    control: base => ({
      ...base,
      height: 60,
      backgroundColor: '#F1F1F1',
      maxHeight: 53,
      // width: 520,

    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),

  
    
  }

    const handleChange = (value) => {
      props.onPaymentTermsSelect(value)
      };


    return ( 
        <>
   
         <Select  placeholder={"Select Terms...."}   styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal}
          options={customOption}
         />


        </>
     );
}

export default AddOnInvoiceTermsSelect;
