// import { DataGrid ,  gridPageCountSelector,
//     gridPageSelector, GridToolbarContainer, GridToolbarExport ,
//     useGridApiContext,
//     useGridSelector} from "@mui/x-data-grid";
import { DataGrid ,  gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport ,
    useGridApiContext,
    useGridSelector} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';
  import {
    SetCouponMasterData,GetCouponMasterData, GetCouponTableOne, SetCouponTableOne} from "../store/productAtom";




    const TableHead = [
        { field: 'Code', headerName:'Id' , width: 178 , headerClassName: " "},
        { field: 'Name', headerName:'Name' , width: 178 , headerClassName: " "},
     
    
        ]

   

function AdCouponTable(props) {
  const masterData = GetCouponMasterData();
  const couponTableOne = GetCouponTableOne();
  


  function onSelectChange(selectedList){
    var selection = selectedList?.map((index) => {return couponTableOne[index]})
    props.onSelectAssociatePlan(selection);
    
}


return (

    <>
<div className="mt-4">

<div  style={{ height: 300, width: '85%' }}>
  <DataGrid
    columns={TableHead}
    rows={couponTableOne?.map(((ele, i) => {return ({...ele, id: i})}))}
    checkboxSelection={true}
        onRowSelectionModelChange={onSelectChange}
  />
</div>
</div>
    
    </>
  );
}

export default AdCouponTable;