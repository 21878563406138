import { useEffect, useState } from "react";
import { GetSubEntryMasterdata} from "../../store/subscriptionAtom";




function AddSubGatewaySelect(props) {
    const [gwayList, setGwayList] = useState([]); 
    const getEntryMasterdata = GetSubEntryMasterdata();

  
    useEffect(()=> {
      const customData = getEntryMasterdata['Payment Gateway']?.map((item, i)=> {
        return ({...item, IsChecked: false})
      });
      if(props?.editList?.length > 0){
        setGwayList( props?.editList);
      }else{
        setGwayList(customData)
      }
    },[getEntryMasterdata, props?.editList?.length]);


  const   onCheckItem = (e, index)=> {
    const newList = gwayList.map((item, i)=> {
      if(i === index){
        return ({...item, IsChecked: e.target.checked})
      }else{
        return (item)
      }
    });
          setGwayList((prev)=> {
            return([...newList]);
          });
          props.onGatewaySelect(gwayList)
    }


    const onCheckAll = (e)=>{
      const newList = gwayList.map((item)=> {
        return ({...item, IsChecked: e.target.checked})
      });
      setGwayList((prev)=> {
        return([...newList])
      });
      props.onGatewaySelect(gwayList)
    }


 


    return ( 
         
         <>

    <div className="row ">
      <div className="col-4">
      <table className=" table border-2 mt-3">
             
             <thead>
               <tr>
                 <th scope="col" style={{backgroundColor:"#F1F1F1", color:"#45444B"}} className="text-sm py-2 ">Name</th>
                 <th scope="col" style={{backgroundColor:"#F1F1F1", color:"#45444B"}} 
                 className="text-sm "><input type="checkbox" onChange={(e)=> onCheckAll(e)}   className="" />  Action  </th>
        
               </tr>
             </thead>
             
               <tbody>
              {gwayList?.map((gway, i)=> {
                   return(
                    <tr key={i}>
                     <td className="">{gway.Payment_Gateway_Name} </td>
                     <td className="text-sm">
                      <input type="checkbox" onChange={(e)=> onCheckItem(e, i)} 
                      checked={gway?.IsChecked ? gway?.IsChecked : '' }   className="" />
                       </td>
                     
                   </tr>
                  
                   )
              })}
              </tbody>

              
                 </table> 

      </div>
    </div>

    
        </>
     );
}

export default AddSubGatewaySelect;