
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { GetRecoilBillListData} from "../../store/billsAtom";
import { useNavigate } from "react-router-dom";





function BillVendorComponent(props) {
  const masterData = GetRecoilBillListData();
  const [customOption, setCustomOption] = useState([]); 
  const navigate = useNavigate();




  useEffect(()=> {
    let tempData = [];
    if(masterData['Vendor']){
      tempData = masterData['Vendor']?.map((item)=> {
        return ({...item, label:`${item?.Customer_Name}`})
      });

    }
   
  

    setCustomOption([...tempData,{label:'ADD NEW VENDOR', value:'ADD_NEW_VENDOR'}]);

  },[masterData])






  
  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      backgroundColor: '#F1F1F1',
      // width: 512,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"})
  }

    const handleChange = (selection) => {
      if(selection?.value === 'ADD_NEW_VENDOR'){
        navigate('/4/Accounts/vendors/add-new')

      }else{
        props.onVendorSelect(selection)
      }
      };


    return ( 
        <>
   
         <Select    styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal} options={customOption}>
          </Select>
                {/* <button  className="ml-5 btn bg-success " >add new vendor</button> */}
        


        </>

     );
}

export default BillVendorComponent;
