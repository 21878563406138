import { Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import LogicContext from "../../Context/LogicContext";
import { createModal } from "react-modal-promise";
import GeneralModal from "../../helpers/GeneralModal";
// import SelectSelect from "./StatusSelect";
import { FileUploader } from "react-drag-drop-files";
import { settingsService } from "../../services/settingsService";
import moment from "moment";
import { GetProductActionUrlEditAtom, SetProductActionUrlEditAtom } from "../../store/productUrlAtom";

import ProductActionStatusSelect from "./ProductActionStatusSelect";
// import FeatureSelect from "./FeatureSelect";





function ProductAddActionUrlPage() {
    const navigate = useNavigate();
    const myPromiseModal = createModal(GeneralModal);
    const { register, setValue, getValues, handleSubmit, control, reset, formState: { errors } } = useForm();
    const logicContext = useContext(LogicContext);
    
    const  setEditAction  = SetProductActionUrlEditAtom();
    const  getEditAction  = GetProductActionUrlEditAtom();


    

    useEffect(()=> {
        setValue('status_id', 1);
        console.log('log ', getEditAction);

        if(getEditAction?.Action_URL_Id){
            setValue('name', getEditAction.Action_URL_Name);
            setValue('status_id', getEditAction.Action_Status_Id);
            setValue('action_url_web', getEditAction.Action_URL_Web);
            setValue('action_url_mobile', getEditAction.Action_URL_Mobile);
            setValue('ref_no', getEditAction.Action_URL_Id);
            // setValue('feature_action_url_id', getEditFeature.Feature_Action_URL_Web);
        }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               

        return ()=> {
            setEditAction({});
        }
    },[]);




    const onSubmit = async () => {
    
        //  Start edit
        const formValues = getValues();
        if(getEditAction?.Action_URL_Id){
            await myPromiseModal({title: "Update record!", body: `Please confirm your action.`,
            showInput: false, placeHolder: ''}).then((res) => {
            if (res.decision === true) {
                settingsService.newOrUpdateProductActionUrl({...formValues, ref_no: getEditAction?.Feature_Action_URL_Id, option: 2}).then(res => {
                    if(res?.data?.length && res?.data[0]?.message_id === 1){
                        logicContext.showToast(res?.data[0].message_text, 'success');
                        navigate(-1);
                    }
                });
            }
        });

        // end Edit
        }else{

            //  New record
            console.log('new ', formValues);
            await myPromiseModal({title: "Save record!", body: `Please confirm your action.`,
                showInput: false, placeHolder: ''}).then((res) => {
                if (res.decision === true) {
                 
                    settingsService.newOrUpdateProductActionUrl({...formValues, option:1}).then(res => {
                        console.log('log_action ', res?.data);
                        if(res?.data?.length && res?.data[0]?.message_id === 1){
                            logicContext.showToast(res?.data[0].message_text, 'success');
                            reset();
                        }
                    });
                }
            });

            // end new record

        }
   

      
    }


    function formError(error) {
        console.log('errr ', error);
        logicContext.showToast("Form error", "error");
    }

 

    const onStatusSelect = (eveData) => {
        setValue('status_id', eveData.value);
    }

 


    return (
        <>
            <div className="jumbotron bg-light" >
                
                <div className="row" >
                    <div className="col" >
                    <h6 class="h4 mb-3 font-bold">Add Product Feature </h6>
                    </div>
                    <div className="col" >

                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit, formError)} >
                    <section className="">
                        <Grid container className="" spacing={2}>
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                    <span className="text-sm text-filterTextColor font-bold">
                                        Feature Name:
                                    </span>
                                    <br />
                                    <input
                                        className="bg-inputColor h-[6vh] pl-3  w-[35vw]"
                                        placeholder="enter feature name" type="text"
                                        name="feature_name"
                                        {...register("name", {
                                            required: "Action name is required",
                                        })}
                                    />
                                     <br/>
                                    <small className="text-red-500">
                                        {Boolean(errors["name"]?.message) &&
                                            String(errors["name"]?.message)}
                                    </small>
                                    {/* 222 */}
                                    <br />

                                    <span className="text-sm text-filterTextColor font-bold">
                                        Action Url Web:
                                    </span>
                                    <br />
                                    <input
                                        className="bg-inputColor h-[6vh] pl-3  w-[35vw]"
                                        placeholder="enter feature name" type="text"
                                        name="action_url_web"
                                        {...register("action_url_web", {
                                            required: "Web URL is required",
                                        })}
                                    />
                                     <br/>
                                    <small className="text-red-500">
                                        {Boolean(errors["action_url_web"]?.message) &&
                                            String(errors["action_url_web"]?.message)}
                                    </small>

                                    {/* 333 */}

                                    <br />
                                    <span className="text-sm text-filterTextColor font-bold">
                                        Action Url Mobile:
                                    </span>
                                    <br />
                                    <input
                                        className="bg-inputColor h-[6vh] pl-3  w-[35vw]"
                                        placeholder="enter feature name" type="text"
                                        name="action_url_mobile"
                                        {...register("action_url_mobile", {
                                            required: "Mobile url is required",
                                        })}
                                    />
                                     <br/>
                                    <small className="text-red-500">
                                        {Boolean(errors["action_url_mobile"]?.message) &&
                                            String(errors["action_url_mobile"]?.message)}
                                    </small>
                                </Grid>
                           
                                <Grid item className="my-2" xs={6}>
                                    <ProductActionStatusSelect defaultVal={getEditAction?.Action_Status_Id}  onStatusSelect={onStatusSelect} />

                                    <br />

                                </Grid>
           
                              
                            </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                             
                                <Grid item xs={6}>
                                <div className=" flex justify-end ">
                            <button
                                type="submit"
                                className="rounded-lg px-10  hover:bg-sky-700  my-3 py-2 bg-blue-500 text-blue-100"
                            >
                                Submit
                            </button>
                        </div>
                                    </Grid>
                                    </Grid>


                  
                    </section>
                </form>
            </div>
        </>
    );
}

export default ProductAddActionUrlPage;





