import { Route, Routes } from "react-router-dom";
import Userlayout from "../layout/Userlayout";
import NotFoundPage from "../pages/NotFoundPage";
import { PrivateRoute } from "./PrivateRoutes";

import BroadcastPageTab from "../pages/broadcast/BroadcastPageTab";
import NotificationSendPage from "../pages/Notification/NotificationSendPage";
import NotificationDetails from "../pages/Notification/NotificationDetails";
import NotificationDashBoard from "../pages/Notification/NotificationDashboard";
import NotificationAddNew from "../pages/Notification/NotificationAddNew";
import NotificationView from "../pages/Notification/NotificationView";
import VoucherCorporateMainPage from "../pages/vouchers-corporate/VoucherCorporateMainPage";
import IVRPage from "../pages/ivr_page/IVRPage";
import IVRDelgroDetailsPage from "../pages/ivr_page/IVRDelgroDetailsPage";
import IVRServiceDetailsPage from "../pages/ivr_page/IVRServiceDetailsPage";


function NotificationRoute() {
    return ( 
        <>
      <Userlayout>
        <Routes>
          <Route exact  path='/dashboard' element={
                <PrivateRoute>  <NotificationDashBoard /> </PrivateRoute>}>
          </Route>
          <Route exact  path='/broadcast' element={
                <PrivateRoute>  <BroadcastPageTab /> </PrivateRoute>}>
          </Route>

          <Route exact  path='/IVR' element={
                <PrivateRoute>  <IVRPage /> </PrivateRoute>}>
          </Route>


          <Route exact  path='/IVR/details/:id' element={
                <PrivateRoute>  <IVRDelgroDetailsPage /> </PrivateRoute>}>
          </Route>

          <Route exact  path='/IVR/service-details/:id' element={
                <PrivateRoute>  <IVRServiceDetailsPage /> </PrivateRoute>}>
          </Route>
          {/* IVR End */}

          <Route  path='/notification-send/details/:id' element={
                  <PrivateRoute>  <NotificationDetails /> </PrivateRoute>}>
            </Route>


            <Route  path='/notification-send' element={
                  <PrivateRoute>  <NotificationSendPage /> </PrivateRoute>}>
            </Route>
            <Route  path='/notification-send/add-new' element={
                  <PrivateRoute>  <NotificationAddNew /> </PrivateRoute>}>
            </Route>
            
        
            {/* /corporate/details/:corporate_id */}
            <Route  path='/notification-send/details/:notify_id' element={
                  <PrivateRoute>  <NotificationDetails /> </PrivateRoute>}>
            </Route>


            <Route  path='/notification-view' element={
                  <PrivateRoute>  < NotificationView/> </PrivateRoute>}>
            </Route>


        <Route path="/*" element={<NotFoundPage />}></Route>
        </Routes>
        </Userlayout>

        </>
     );
}

export default NotificationRoute;