
function VendorsComment() {
  return (
    <>
      <div className="container  mt-5">
        <div className="d-flex justify-content-center row">
          <div className="col-md-8">
            <div className="d-flex flex-column comment-section">
              <div className=" p-2">
               
                <div className="">
                <span className="mb-3 text-sm">
                  Add Comment{" "}
                  <span>
                    <i style={{color:"#B5B1CA"}}>(Internal use only)</i>
                  </span>
                </span>
                  <input  type="text" className="w-100 text-sm h-[15vh] bg-filterInput  "  placeholder="Enter remarks" />
                </div>
                <div className="mt-2">
                  <button
                    className="btn  btn-sm shadow-none"
                    type="button"
                    style={{backgroundColor:"#476EF8", color:"white"}}
                  >
                    Add Comment
                  </button>
                  <button
                    className="btn btn-outline-primary btn-sm ml-1 shadow-none"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VendorsComment;
