import { useEffect } from "react";
import Select from 'react-select';
import { GetSubEntryMasterdata} from "../../store/subscriptionAtom";



function AddsubProduct(props) {
  const getEntryMasterdata = GetSubEntryMasterdata();

  useEffect(()=> {

  },[props?.defaultVal])

  const customOption = getEntryMasterdata?.Product?.map((item)=> {
    return ({...item, label:`${item.Product_Name}  ${item.Product_Id}`})
  })

  
  const customStyles = {
    control: base => ({
      ...base,
      height: 50,
      backgroundColor: '#F1F1F1',
      width: 450,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),

  
    
  }

    const handleChange = (value) => {
      props.onProductSelect(value)
      };


    return ( 
        <>
   
         <Select   styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal}
          options={customOption}
         />


        </>
     );
}

export default AddsubProduct;
