

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";



const customerApprovedAtom = atom({key:'customerapprovedAtom', default:{
    Corporate_Info:[],Display_Column:[], customer_info:[], approve_status:[], 
    corporate_ddl:[]}
});


export  const SetRecoilApprovedList = () => useSetRecoilState(customerApprovedAtom);
export  const GetRecoilApprovedList = () => useRecoilValue(customerApprovedAtom);







