import { useEffect, useState } from 'react';
import Select from 'react-select';

const options = [
  { value: '1', label: 'Active' },
  { value: '2', label: 'InActive' }
]


function FeatureActionStatusSelect(props) {
  const [defData, setDefData] = useState(null);


  useEffect(() => {
    if (props?.defaultVal) {
      setDefData(options[Number(props?.defaultVal - 1)]);
    }

  }, [props?.defaultVal]);

  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      zIndex: 9999,
      width: 150,
    }),

    indicatorSeparator: styles => ({ ...styles, display: 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '', height: 20, paddingBottom: "45px", marginLeft: "13px" }),
    option: styles => ({ ...styles, color: "black" }),
    menuPortal: styles => ({ ...styles, zIndex: 99999 })
  }

  return (
    <>
      {/* <Select  defaultValue={props?.defaultVal}
          onChange={(e)=> props?.onCountrySelect(e)} options={customOption}     styles={customStyles} /> */}

      <div style={{ width: '300px' }}>

        <Select
          menuPlacement="auto"
          menuPosition="fixed"

          key={defData} options={options} defaultValue={defData} onChange={props?.onStatusSelect} styles={customStyles} />
      </div>

    </>
  );
}

export default FeatureActionStatusSelect;
