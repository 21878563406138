
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const itemsGroupService = {

    getGroupItemsList,
    getMasterData,
    submitItemGroup,
    setGroupItemActive,
    setGroupItemInActive,
    submitItemGroupInfo,
    getItemImages,
    getItemGroupDropDown,
    saveGroupItems,
    submitGroupImage,
    submitImageToDb,
    getGroupDetails,
    getGroupTabledata,
    updateItemGroup
    
    
};





async function  getGroupItemsList() {
   return axios.get(`/get-item-group-list`,  authHeader())
}



async function  getMasterData() {
   return axios.get(`/get-item-group-master-data`,  authHeader())
}

async function  submitItemGroup(body) {
   return axios.post(`/submit-item-group`,body,  authHeader())
}
async function  updateItemGroup(body) {
   return axios.put(`/update-item-group`,body,  authHeader())
}

async function  submitItemGroupInfo(body) {
   return axios.post(`/submit-item-group-info`,body,  authHeader())
}



function setGroupItemActive(xmlString){
    var body = {xml_string : xmlString}
   return axios.put('/set-group-item-active', body, authHeader()); 
  }


  function setGroupItemInActive(xmlString){
    var body = {xml_string : xmlString}
   return axios.put('/set-group-item-inactive', body, authHeader())
        
  }

  function saveGroupItems(body){
   return axios.post('/save-group-items', body, authHeader())
        
  }

  function getItemImages(item_id){
    return axios.get(`/get-item-group-images/${item_id}`, authHeader())
  }


  function getItemGroupDropDown(item_id){
   return axios.get(`/get-item-list-drop-down`, authHeader())
 }

  function submitGroupImage(body){
   return axios.post(`/submit-group-image`,body, authHeader())
 }

  function submitImageToDb(body){
   return axios.post(`/submit-group-image-to-db`,body, authHeader())
 }


 function getGroupDetails(item_group_id){
   return axios.get(`/get-group-details/${item_group_id}`, authHeader());
 }


 function getGroupTabledata(item_group_id){
   return axios.get(`/get-item-group-table/${item_group_id}`, authHeader());
 }




//  function getItemImages(item_id){
//    return axios.get(`/get-item-images/${item_id}`, authHeader())
//  }





