import { DataGrid ,  gridPageCountSelector,
  gridPageSelector, GridToolbarContainer, GridToolbarExport ,
  useGridApiContext,
  useGridSelector} from "@mui/x-data-grid";
  import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useContext, useEffect, useState } from "react";
import {GetPurchaseReceiveListData, SetPurchaseReceiveListData} from '../../store/purchaseReceiveAtom';
import LogicContext from "../../Context/LogicContext";
import {  useLocation, useNavigate } from "react-router-dom";
import { purchaseService } from "../../services/purchase.service";
import TableSelect from "../../helpers/TableSelect";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}



function PurchaseReceiveTable(props) {
  const getPurchaseReceiveListData = GetPurchaseReceiveListData();
  const setPurchaseReceiveListData = SetPurchaseReceiveListData();
const [purchaseList, setPurchaseList] = useState([]);
const [tableHeadList, setTableHeadHead] = useState([]);
const logicContext = useContext(LogicContext); 

const navigate = useNavigate();
const { pathname } = useLocation();  
var url = pathname.split('/')
const currModuleId = url[1];
const currModuleName = url[2];

const currUserMenu = url[3];


  // NB: Purchase receive  data is been query  from billSearch component file
useEffect(()=> {
  let isMount = true;
  if(getPurchaseReceiveListData['Display_Column']?.length){
    let newTableHead = getPurchaseReceiveListData['Display_Column']?.filter((item) => item.Display_Id === 1).map((item) => {
      return {
        ...item,field: item.Column_Value.trim(),headerName: item.Column_Name, width: 200};
    }).sort((a, b) => {
      return a.Print_Sequence - b.Print_Sequence;
    });
   if(isMount)  {
    setTableHeadHead(newTableHead)
   };
  }
  

  return ()=> {
    isMount = false;
  }
},[getPurchaseReceiveListData]) 



  useEffect(()=> {
    if(props?.tableData?.length > 0){
      var list = props?.tableData?.map((item, i) => {
        return { ...item, id: i } });
        setPurchaseList(list);
    }
  },[ props?.tableData]);



function OnItemCellClick(event){
  const id = event.row['Purchase_Receive_Ref_No'];
  navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`)
}


function onSelectChange(selectedList) {
  var selection = selectedList?.map((index) => {
    return props.tableData[index];
  });
  props.selectedTableItems(selection);
}


const tableMenuSelect = (option, i) => {
  var choice = option.Display_Id === 1 ? 0 : 1;
  var temp = getPurchaseReceiveListData['Display_Column']?.map((item) => {
      if (item.Ref_No === option.Ref_No) {
        return { ...item, Display_Id: choice };
      }
      return item;
    }) ;
 
    setPurchaseReceiveListData({...getPurchaseReceiveListData, Display_Column: temp })
};


function saveCurrentTableHeader() {
  var xmlString = "";
  getPurchaseReceiveListData['Display_Column']?.map((element) => {
    if (element.Display_Id === 1) {
      xmlString += `<a><refno>${element.Ref_No} </refno></a>`;
    }
  });
  purchaseService.savePurchaseReceiveTableHeader({xml_string: `<root> ${xmlString} </root>` , option: 1}).then((res)=> {
    logicContext.showToast('updated successfully', 'success');
  }).catch((err)=> {

  });



}


  return ( 
      <>

<div className="dropdown mr-2">
        <button
          className="btn btn-primary bg-primary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-toggle="dropdown"
          aria-expanded="false"
        ></button>

        <ul className="dropdown-menu  scrollable-menu "
          role="menu"
          aria-labelledby="dropdownMenuButton1" >
          {getPurchaseReceiveListData['Display_Column']?.map((val, i) => {
            return (
              <TableSelect
                key={i}
                val={val}
                i={i}
                tableMenuSelect={tableMenuSelect}
              />
            );
          })}

          <div className=" flex  justify-center">
            <div className="">
              {" "}
              <button
                style={{ backgroundColor: "#476EF8" }}
                className="btn text-white  btn-sm"
                onClick={() => saveCurrentTableHeader()}
              >
                Save
              </button>{" "}
            </div>
            <div className="ml-3">
              {" "}
              <button className="btn text-white  bg-danger btn-sm">
                Cancel
              </button>{" "}
            </div>
          </div>
        </ul>
      </div>
<DataGrid className="table" style={{ height: 500, width: '100%' }}
        columns={tableHeadList}
        rows={purchaseList} pageSize={6}  rowsPerPageOptions={[6]}  checkboxSelection={false}
        components={{  Toolbar: CustomToolbar, Pagination: CustomPagination,  }}
          onCellClick={( event) => {  event.defaultMuiPrevented = true}}
          onRowSelectionModelChange={onSelectChange}
          onRowClick={(event)=> {  OnItemCellClick(event) }}
        
          sx={{
            // boxShadow: 2,
            // border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {   color: 'primary.main', cursor: 'pointer' }  }}  />

      </>
   );
}

export default PurchaseReceiveTable