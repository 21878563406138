import { DataGrid ,  gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport ,
    useGridApiContext,
    useGridSelector} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';
import { useLocation, useNavigate } from "react-router-dom";
import moment from 'moment';




  const TableHead = [
    { field: 'id', headerName:'Ref No' , width: 100 , headerClassName: "tableHeader "},
    { field: 'Notification_Date', headerName:'Date' , width: 150 , headerClassName: "tableHeader "},
     { field: 'Notification_Message', headerName:'Message' , width: 300 , headerClassName: "tableHeader "},
     { field: 'No_Of_Client', headerName:'Number of client' , width: 150 , headerClassName: "tableHeader "},
     { field: 'Notification_Send_By_Name', headerName:'Sender' , width: 150 , headerClassName: "tableHeader "},
     { field: 'Notification_Type_Name', headerName:'Type' , width: 150 , headerClassName: "tableHeader "},
    ]


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }


  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }


function NotificationTable(props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();  
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];


  function OnItemCellClick(event){
    const id = event.row['Ref_No'];
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`);
}



    return ( 
        <>


<div className="table mb-3" style={{ height: 500, width: '100%' }}>
      <DataGrid  rows={ props?.list.map((item, i) =>  { 
        return {...item, id:item?.Ref_No, Notification_Date: moment(item?.Notification_Date).format('llll') , Notification_Message: item?.Notification_Message.substring(0,35)}
      })}
        columns={TableHead}
        pageSize={6}
        rowsPerPageOptions={[6]}
        checkboxSelection={true}
        onRowClick={(event)=> {
          OnItemCellClick(event);
        }}
        components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
          }}
          onCellClick={( event) => {
            event.defaultMuiPrevented = true;
            
          }}
          
       
          sx={{
            // boxShadow: 2,
            // border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
              cursor: 'pointer',
            },
          }}
      />
  
    </div>
        
        </>
     );
}

export default NotificationTable