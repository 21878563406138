import { atom , useSetRecoilState, useRecoilValue} from "recoil";




export const customerTransAtom = atom({key:'customer_trans_atom',default:{transation_history:[],
    transation_type:[]}});


export const SetCustomerTransListAtom = () => useSetRecoilState(customerTransAtom);
export const GetCustomerTransListAtom = () => useRecoilValue(customerTransAtom);
