import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import profile from "../assets/itempimage.svg";
import singleImage from "../assets/itemSingle.svg";
import { imageService } from "../services/image.service";
import DeleteIcon from "@mui/icons-material/Delete";

function ProductPlanImage(props) {
  const [image1Preview1, setImage1Preview1] = useState(null);
  const [image1Preview2, setImage1Preview2] = useState(null);
  const [image1Preview3, setImage1Preview3] = useState(null);
  const [image1Preview4, setImage1Preview4] = useState(null);
  const [image1Preview5, setImage1Preview5] = useState(null);
  const { product_id } = useParams();

  console.log("plan id ", props?.plan_id);

  useEffect(() => {
    setImage1Preview1(null);
    setImage1Preview2(null);
    setImage1Preview3(null);
    setImage1Preview4(null);
    setImage1Preview5(null);
    if (props?.plan_id !== undefined) {
      props.getItemImages(props?.plan_id, product_id).then((res) => {
        console.log("IMAGES  Lis", res.data);
        res?.data?.Plan_Image?.map((item, i) => {
          switch (i) {
            case 0:
              console.log(
                "IMAGE 565",
                imageService.PRODUCT_PLAN_IMAGE_URL +
                  item.Image_Reference_No +
                  "." +
                  item.Image_Format
              );
              setImage1Preview1(
                imageService.PRODUCT_PLAN_IMAGE_URL +
                  item.Image_Reference_No +
                  "." +
                  item.Image_Format
              );
              break;

            case 1:
              setImage1Preview2(
                imageService.PRODUCT_PLAN_IMAGE_URL +
                  item.Image_Reference_No +
                  "." +
                  item.Image_Format
              );
              break;

            case 2:
              setImage1Preview3(
                imageService.PRODUCT_PLAN_IMAGE_URL +
                  item.Image_Reference_No +
                  "." +
                  item.Image_Format
              );
              break;

            case 3:
              setImage1Preview4(
                imageService.PRODUCT_PLAN_IMAGE_URL +
                  item.Image_Reference_No +
                  "." +
                  item.Image_Format
              );
              break;

            case 4:
              setImage1Preview5(
                imageService.PRODUCT_PLAN_IMAGE_URL +
                  item.Image_Reference_No +
                  "." +
                  item.Image_Format
              );
              break;

            default:
              break;
          }
        });
      });
    }
  }, [product_id]);

  const imageOneChangeHandler = (e) => {
    const file = e.target.files[0];
    let fileName = file.name.split(" ").join("_");
    let fileExtension = fileName.split(".");
    if (file) {
      let option = 1;
      if (image1Preview1) {
        option = 2;
      }
      props.submitItemImage(file, "1", 1, fileExtension[1], option);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImage1Preview1(reader.result);
      });
      reader.readAsDataURL(file);
    }
  };

  const imageTwoChangeHandler = (e) => {
    const file = e.target.files[0];
    let fileName = file.name.split(" ").join("_");
    let fileExtension = fileName.split(".");
    if (file) {
      let option = 1;
      if (image1Preview2) {
        option = 2;
      }
      props.submitItemImage(file, "2", 0, fileExtension[1], option);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImage1Preview2(reader.result);
      });
      reader.readAsDataURL(file);
    }
  };

  const imageThreeChangeHandler = (e) => {
    const file = e.target.files[0];
    let fileName = file.name.split(" ").join("_");
    let fileExtension = fileName.split(".");
    if (file) {
      let option = 1;
      if (image1Preview3) {
        option = 2;
      }

      props.submitItemImage(file, "3", 0, fileExtension[1], option);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImage1Preview3(reader.result);
      });
      reader.readAsDataURL(file);
    }
  };

  const imageFourChangeHandler = (e) => {
    const file = e.target.files[0];
    let fileName = file.name.split(" ").join("_");
    let fileExtension = fileName.split(".");
    if (file) {
      let option = 1;
      if (image1Preview4) {
        option = 2;
      }
      props.submitItemImage(file, "4", 0, fileExtension[1], option);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImage1Preview4(reader.result);
      });
      reader.readAsDataURL(file);
    }
  };

  const imageFiveChangeHandler = (e) => {
    const file = e.target.files[0];
    let fileName = file.name.split(" ").join("_");
    let fileExtension = fileName.split(".");
    if (file) {
      let option = 1;
      if (image1Preview5) {
        option = 2;
      }
      props.submitItemImage(file, "5", 0, fileExtension[1], option);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImage1Preview5(reader.result);
      });
      reader.readAsDataURL(file);
    }
  };

  function imageOneError() {
    setImage1Preview1(null);
  }

  const imageTwoError = () => {
    setImage1Preview2(null);
  };

  const imageThreeError = (e) => {
    setImage1Preview3(null);
  };

  const imageFourError = (e) => {
    setImage1Preview4(null);
  };

  const imageFiveError = (e) => {
    setImage1Preview5(null);
  };

  const selectImageTwo = () => {
    document.getElementById("image-two").click();
  };

  const selectImageThree = () => {
    document.getElementById("image-three").click();
  };

  const selectImageFour = () => {
    document.getElementById("image-four").click();
  };

  const selectImageFive = () => {
    document.getElementById("image-five").click();
  };

  const selectImageOne = () => {
    document.getElementById("image-one").click();
  };

  return (
    <>
      <div className="flex justify-center mt-10">
        <div className="row  ">
          <div className="col justify-content-center ">
       <div>
       <div className="flex justify-center">
            <input
              style={{ display: "none" }}
              id="image-one"
              accept="image/*"
              onChange={imageOneChangeHandler}
              type="file"
              name="name"
            />
            {/* <DeleteIcon color="error"/> */}
            <span onClick={selectImageOne}>
              <img
                width="200"
                onError={() => imageOneError()}
                src={image1Preview1 ? image1Preview1 : profile}
                alt=""
                className="profileImage rounded profileImage2"
              />
            </span>
            </div>
       </div>
          

            <span className="flex profilesmImage">
              <input
                onError={imageTwoError}
                style={{ display: "none" }}
                id="image-two"
                accept="image/*"
                onChange={imageTwoChangeHandler}
                type="file"
                name="name"
              />
              <span onClick={selectImageTwo}>
                <img
                  onError={() => imageTwoError()}
                  className="m-1 small-pic2"
                  src={image1Preview2 ? image1Preview2 : singleImage}
                  alt=""
                  width="30"
                />
              </span>

              <input
                style={{ display: "none" }}
                id="image-three"
                accept="image/*"
                onChange={imageThreeChangeHandler}
                type="file"
                name="name"
              />
              <span onClick={selectImageThree}>
                <img
                  onError={() => imageThreeError()}
                  className="m-1 small-pic2"
                  src={image1Preview3 ? image1Preview3 : singleImage}
                  alt=""
                  width="30"
                />
              </span>

              <input
                style={{ display: "none" }}
                id="image-four"
                accept="image/*"
                onChange={imageFourChangeHandler}
                type="file"
                name="name"
              />
              <span onClick={selectImageFour}>
                <img
                  onError={() => imageFourError()}
                  className="m-1 small-pic2"
                  src={image1Preview4 ? image1Preview4 : singleImage}
                  alt=""
                  width="30"
                />
              </span>

              <input
                style={{ display: "none" }}
                id="image-five"
                accept="image/*"
                onChange={imageFiveChangeHandler}
                type="file"
                name="name"
              />
              <span onClick={selectImageFive}>
                <img
                  onError={() => imageFiveError()}
                  className="m-1 small-pic2"
                  src={image1Preview5 ? image1Preview5 : singleImage}
                  alt=""
                  width="30"
                />
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductPlanImage;
