import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "../pages/Image.css";

import avater from "../assets/avater.png";

export function AddCustomerImage(props) {
  const [imgData, setImgData] = useState(null);

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      props.submitFile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div className="App imageParent  w-50">
      <img className="playerProfilePic_home_tile" src={imgData} alt="" />

      {imgData ? null : <img src={avater} className="h-auto  mt-5  " alt="" />}

      <label>
        {" "}
        Choose your Picture
        <input
          type="file"
         
          accept="image/png"
          className="w-[9vw]  rounded  h-20"
          onChange={onChangePicture}
        />
      </label>
    </div>
  );
}
