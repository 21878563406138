import { useEffect } from 'react';
import Select from 'react-select';


function AreaSelect(props) {

  const customOption = props?.customList?.map((item)=> {
    return ({...item, label:`${item.Area_Name}`})
  });


  useEffect(()=> {

  },[props?.customList, props?.defaultVal]);
  
  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      backgroundColor: '#F1F1F1',
      zIndex: 0
      // width: 512,
    }),
  
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
    menuPortal:styles => ({ ...styles, zIndex: 0 })
  }
  

    return ( 
        <>
         <Select  defaultValue={props?.defaultVal} 
         onChange={(e)=> props?.onCountrySelect(e)} options={customOption}    styles={customStyles} />
        </>
     );
}

export default AreaSelect;
