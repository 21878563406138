import PlanSelect from "../../PlanSelect";
import { GetAddonMasterData} from '../../../store/productAtom';


function AddonCurrSelect(props) {
    const masterData = GetAddonMasterData();

    const handleChange = (val) => {
      props.onDurationSelect(val.Code)
      };

 
     const  createSelectItems = ()=> {
        let items = [];         
    
        masterData[14]?.forEach((item, i)=> {
          items.push(<option key={i} value={i}>{item.Name}</option>)
        });


        
        return items;
    } 
      

    return ( 
        <>


{/* <CustomSelect value={character} onChange={handleChange}>
        {props.productDimension.map((c) => (
          <StyledOption key={c.Code} value={c}>
            {c.Name}
          </StyledOption>
        ))}
      </CustomSelect> */}
   
  <select onChange={handleChange}>
    {createSelectItems()}
  </select>



        </>
     );
}

export default AddonCurrSelect;