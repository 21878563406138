import React from 'react';
import PurchaseReceiveContent from './PurchaseReceiveContent';



export class PurchaseReceiveWrapperClass extends React.Component {


    render() {
        return (
            <PurchaseReceiveContent props={this.props} />
        )
    }
}