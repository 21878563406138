import { TableBody,TableCell,TableContainer,TableHead,TableRow} from "@mui/material";
  import DeleteIcon from "@mui/icons-material/Delete";
  import { Table } from "react-bootstrap";
  import CurrencyFormat from 'react-currency-format';
  
  
  
  
  function PaymentDetailsTable(props) {
  
  return (
  
      <>
  <div className="mt-2">
  
  <TableContainer className="mt-3">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <colgroup>
                   
                 
                </colgroup>
              <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                <TableRow>
                  <TableCell>Invoice No</TableCell>
                  <TableCell>Invoice Date</TableCell>
                  <TableCell>Invoice Amount</TableCell>
                  <TableCell>Payment Amount</TableCell>
              
                  {/* <TableCell></TableCell> */}
                </TableRow>
              </TableHead>
  
              <TableBody className=" mb-3">
              {props?.tableData?.map((item, i) => {
                return (
                  <TableRow className="border-1"
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{item.Invoice_No} </TableCell>
                    <TableCell align="left">{item.Invoice_Date_String} </TableCell>
                    <TableCell align="left">
                      <CurrencyFormat value={parseFloat(item.Invoice_Amount).toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                     </TableCell>
                    <TableCell align="left">
                    <CurrencyFormat value={parseFloat(item?.Adjustment_Amount).toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                      
                    </TableCell>
             
                  </TableRow>
                );
              })}
              </TableBody>
            </Table>
          </TableContainer>
  </div>
      
      </>
    );
  }
  
  export default PaymentDetailsTable;