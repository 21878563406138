import { atom , useSetRecoilState, useRecoilValue} from "recoil";




export const ivrServiceHistoryAtom = atom({key:'ivr_service_history',default:{}});


export const SetServiceHistoryStore = () => useSetRecoilState(ivrServiceHistoryAtom);
export const GetServiceHistoryStore = () => useRecoilValue(ivrServiceHistoryAtom);
