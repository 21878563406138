import { useEffect, useState } from "react";
import Select from 'react-select';
import { GetEntryData} from '../../store/salesOrderAtom';



function SalesOrderSalesPerson(props) {
  const masterData = GetEntryData();

  const customOption = masterData['Sales_Officer']?.map((item)=> {
    console.log('OBS ', item)
    return ({...item, label:`${item.Column1}`})
  })

  
  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      backgroundColor: '#F1F1F1',
      // width: 520,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
  }

    const handleChange = (value) => {
      props.onSalesPersonSelect(value)
      };


    return ( 
        <>
   
         <Select  placeholder={"Select sales person...."}   styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal}
          options={customOption}
         />


        </>
     );
}

export default SalesOrderSalesPerson;
