import React, { useEffect, useRef, useState, useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";

import { Grid } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import VoucherCorporateScroll from "./VoucherCorporateScroll";
import VoucherCorporateContent from "./VoucherCorporateContent";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { voucherService } from "../../services/voucherService";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import LogicContext from "../../Context/LogicContext";
import { SetVoucherListAtom, GetVoucherListAtom } from "../../store/voucherAtom";





const VoucherCorDetailsPage = () => {
    const { id } = useParams();
    const [voucherDetails, setVoucherDetails] = useState({});
    const myPromiseModal = createModal(GeneralModal);
    const logicContext = useContext(LogicContext);
    const setVoucherAtomData = SetVoucherListAtom();
    const getVoucherAtomData = GetVoucherListAtom();

    const navigate = useNavigate();
    const { pathname } = useLocation();
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];


    useEffect(() => {
        document.title = "Voucher Details Page";
        loadCopVoucherDetails(id);

        return () => {
            //code here
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);



    const loadCopVoucherDetails = (id) => {
        voucherService.corporateVoucherDetails(id).then(res => {
            if (res?.data?.length) {
                setVoucherDetails(res.data[0]);
                setVoucherAtomData(prev => {
                    return { ...prev, newVoucherList: res.data };
                });
            } else {
                setVoucherDetails({});
                setVoucherAtomData(prev => {
                    return { ...prev, newVoucherList: [] };
                });
            }
        });
    }


    const createNewVoucher = () => {
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
    }




    const shareActionButtonAll = async (selectedIdList) => {
        if (selectedIdList.length < 1) {
            return logicContext.showToast(`No selection was made. Please select one or more vouchers`, "error");
        }
        await myPromiseModal({
            title: "Confirm Share action?", body: `A total of  ${selectedIdList?.length} Voucher(s)  will be allocated`,
            showInput: false, placeHolder: '',
        }).then((res) => {
            if (res.decision === true) {

                let xml_voucher = "";
                let temp = "";
                let cop_ref = '';

                let i = 0;
                let j = 0;
                let found = false;
                while (i < selectedIdList.length && j < getVoucherAtomData.newVoucherList.length) {
                    let voucherId = selectedIdList[i];
                    let voucher = getVoucherAtomData.newVoucherList[j];
                    if (voucher?.Ref_No === voucherId) {
                        cop_ref = voucher?.Corporate_Ref_No;
                        found = true;
                        temp += `<a>
                        <voucher_no>${voucher.Voucher_Number} </voucher_no>
                        <name>${voucher?.Reciepient_Name ? voucher?.Reciepient_Name : ''} </name>
                        <mobile>${voucher?.Reciepient_Mobile_Number ? voucher?.Reciepient_Mobile_Number : ''} </mobile>
                        <email>${voucher?.Reciepient_Email_Address ? voucher?.Reciepient_Email_Address : ''} </email>
                         </a>`;
                        i += 1;
                    }
                    j += 1;
                }

                if (!found) {
                    return logicContext.showToast(`Selected Voucher(s) does not exist in table List`, "");
                }

                xml_voucher = `<root> ${temp} </root>`;
                voucherService.shareVouchers({ ref_no: cop_ref, xml_voucher: xml_voucher.replace(/^\s+|\s+$/gm, '') }).then((res) => {

                    voucherService.voucherDetails(cop_ref).then(res => {
                        loadCopVoucherDetails(id);
                    });
                });
            }
        }).catch((err) => {
            logicContext.showToast("error submitting record", "error");
        });

    }


    return (
        <>
            <section className="  mt-2 ">
                <Grid container className="" spacing={2}>
                    <Grid item xs={12}>
                        <div className="flex   justify-end">
                            <button onClick={() => createNewVoucher()}
                                style={{ borderRadius: "5px" }}
                                className=" bg-blue-600  px-3 h-[6vh]  text-white text-xs hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50 ">
                                ADD NEW
                            </button>

                            <button
                                style={{ borderRadius: "5px" }}
                                className="  px-3 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 ml-2">
                                <img src={trash} alt="" />
                            </button>

                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <section className=" ">
                            <Grid container className="" spacing={0}>
                                <Grid className="" item xs={2.8}>
                                    <VoucherCorporateScroll />
                                </Grid>
                                <Grid item xs={9.2}>
                                    <section className="w-full  z-50 ">

                                        <section className="   ">
                                            <VoucherCorporateContent newVoucherList={getVoucherAtomData.newVoucherList} voucherDetails={voucherDetails} shareActionButtonAll={shareActionButtonAll}  ></VoucherCorporateContent>
                                        </section>

                                    </section>
                                </Grid>
                            </Grid>

                            {/* w-[56.3vw] */}
                        </section>
                    </Grid>
                </Grid>
            </section>


        </>
    );
};

export default VoucherCorDetailsPage;
