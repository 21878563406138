/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useContext } from "react";
// import "./CustomerDetails.css";
import "./FinishItemSetup.css";
import cimage from "../assets/cimage.svg";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import pencil from "../assets/pencil.png";
import trash from "../assets/trash.png";
// import "./CustomerDetails.css";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import LogicContext from "../Context/LogicContext";

import { itemsSetupService } from "../services/itemSetup.service";
import GeneralModal from "../helpers/GeneralModal";
import { createModal , } from 'react-modal-promise';

import CompositeGroupDetailsTable from "./CompositGroupDetailsTable";
import CompositeGroupDetailsScroller from "./CompositeGroupDetailsScroller";
import CompositesSellDetails from "./CompositeSellDetails";
import {SetCompositeGroupEdit, ResetCompositeGRoupEdit} from '../store/compositeGroup';
import {  ResetCompositeGroupAddList} from "../store/compositeGroup";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}




function CompositeGroupDetailsPage() {
  const { group_id } = useParams();
  const [selectedTableData, setSelectedTableData] = useState({});
  const [itemList, setItemList] = useState([]);
  const logicContext = useContext(LogicContext);
  const myPromiseModal = createModal(GeneralModal);
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const navigate = useNavigate();
  const resetTableDataList = ResetCompositeGroupAddList();

  const [value, setValue] = React.useState(0);
  const resetEdit = ResetCompositeGRoupEdit();
  const setGroupEdit = SetCompositeGroupEdit();


  const getItemDetailsCallback = useCallback((id)=> {
    itemsSetupService.getCompositeInformation(id).then((res) => {
      if (res?.data !== undefined) {
        setItemList(res.data);
        setSelectedTableData({
          Composite_Group_Name: res.data[0]["Composite_Group_Name"],
          Composite_Group_Description: res.data[0]["Composite_Group_Description"],
          Maximum_Amount: res.data[0]["Maximum_Amount"],
          Minimum_Amount: res.data[0]["Minimum_Amount"],
          Composite_Group_Id: res.data[0]["Composite_Group_Id"],
        });
      }
    });
  },[])




  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  useEffect(() => {
    resetTableDataList();
    resetEdit();
    getItemDetailsCallback(group_id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group_id, getItemDetailsCallback]);


  


  function changeStatus(optionCode){
    let xml_string = `<root> <a> <group_id> ${group_id}</group_id> </a> </root>`
    itemsSetupService.changeGroupStatus({xml_string, option: optionCode}).then(res => {
      const { Message_Text} = res.data[0];
      if(res?.data != undefined){
        logicContext.showToast(Message_Text, 'success');
      }
    });
  }


  async function editGroup(){
    await myPromiseModal({title:'Confirmation', body:' Do you want to modify this record?',
    showInput:false, placeHolder:''}).then(response => {
      if(response.decision === true){
        setGroupEdit(selectedTableData);
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`)
      }
    })
 
  }


  function viewItemDetails(id){
    // `/${currModuleId}/${currModuleName}/items/add-item/${3}`
    navigate(`/${currModuleId}/${currModuleName}/items/item-details/${id}`)
                
  }



  return (
    <>
      <div className="mx-1"></div>

      <section className="   mt-2">
        <section className="flex relative justify-between">
          <section className="scroll w-30  z-0 mt-3	main border-1">
            <p className="ml-4 pt-8 text-xs font-bold md:text-sm ">
              Composite Groups{" "}
            </p>

            <CompositeGroupDetailsScroller
            ></CompositeGroupDetailsScroller>
          </section>

          <section className="w-full z-50 ">
            <section className="  max-auto max-w-6xl ">
              <div className="flex   flex-wrap justify-between md:">
                <div></div>

                {/* ADD NEW TRANSACTION SECTION */}
                <div className="flex  justify-end w-[56.5vw]">
                  <div className="flex ">
               
                      <button onClick={()=> changeStatus(4)}
                      className=" h-[6vh] bg-filterColor  rounded text-black text-xs py-1 mr-2 px-6">
                        ACTIVE
                      </button>
                   

                      <button onClick={()=> changeStatus(5)}
                      className=" h-[6vh]  rounded text-black bg-filterColor text-xs py-1 mr-2 px-6">
                        INACTIVE
                      </button>
                 
                    <button
                      style={{ borderRadius: "5px" }}
                      className="   px-4 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1  "
                    >
                      <img src={trash} alt="" />
                    </button>

                    <button
                    onClick={()=> editGroup()}
                      style={{ borderRadius: "5px" }}
                      className=" mx-2 px-4  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1  "
                    >
                      <img src={pencil} alt="" />
                    </button>

                    <Link
                      to={`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`}
                    >
                      <button
                        style={{ backgroundColor: "#476EF8" }}
                        className=" h-[6vh]  rounded text-white text-xs py-1 mr-2 px-6"
                      >
                        ADD NEW
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            {/* tab */}
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange}>
                  <Tab label="Composite Items" {...a11yProps(0)} />
                  <Tab label="Sales Summary" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <section className=" mx-2 mt-4 grid grid-cols-2 w-full ">
                  <div className="">
                    <div className="">
                      <span className="text-xs">Group Name </span> <br></br>
                      <div className="h5">{selectedTableData?.name}</div>
                    </div>
                    <div className="mt-3">
                      <span className="text-xs">
                        Description : {selectedTableData?.description}{" "}
                      </span>{" "}
                      <br></br>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <img src={cimage} alt="" />
                  </div>
                </section>
                <section className="mt-4 mx-2">
                  <CompositeGroupDetailsTable tableData={itemList} viewItemDetails={viewItemDetails} ></CompositeGroupDetailsTable>
                </section>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <CompositesSellDetails group_id={group_id} viewItemDetails={viewItemDetails}></CompositesSellDetails>
              </TabPanel>
            </Box>
            {/* end tab */}
          </section>
        </section>
      </section>
    </>
  );
}

export default CompositeGroupDetailsPage;
