import "./LoginPage.css";
import { useContext, useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/delgro_logo.png";
import login_icon from "../assets/login_icon.png";
import EmailInputComponent from "../components/EmailInputComponent";
import PasswordInputcomponent from "../components/PasswordInputComponent";
import LogicContext from "../Context/LogicContext";
import { authenticationService } from "../services/authentication.service";
import { currentMenuListAtom } from "../store/storeMenuAtom";
import { useResetRecoilState } from "recoil";
import Container from "../components/my-container/Container";
import DelgroLogo from "../components/delgro_logo/DelgroLogo";

function LoginPage() {
  const logicContext = useContext(LogicContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const resetCurrentMenu = useResetRecoilState(currentMenuListAtom);

  useEffect(() => {
    resetCurrentMenu();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    if (email === "" || password === "")
      return logicContext.showToast("one or more  input is required!");

    authenticationService
      .login(email, password)
      .then((response, err) => {
        if (err) return logicContext.showToast("connection error", "error");
        return response.json();
      })
      .then((data) => {
        if (data["token"]) {
          localStorage.setItem("token", data["token"]);
          logicContext.setModuleList(data["moduleList"]);
          navigate("/Users/module-list");
        } else if (data["msg"]) {
          logicContext.showToast(data["msg"], "error");
        }
      });
  }

  const getUserTypedEmail = (email) => {
    setEmail(email);
  };

  const getUserTypedpassword = (password) => {
    setPassword(password);
  };

  return (
    <>
      <div className="bg-white pb-40">
        <Container>
          <div className="w-fit lg:px-10 mt-4">
            <DelgroLogo />
          </div>
          <div className="mt-6 flex flex-col md:grid md:grid-cols-9 items-center md:gap-6 lg:px-10">
            <div className="md:col-span-5 flex flex-col">
              <h3 className="text-lg font-semibold text-gray-600 max-w-md">
                You are about to enter a productive zone where the impossible is
                possible.
              </h3>

              <img
                src={login_icon}
                className="h-auto w-full max-w-[530px] aspect-auto object-contain"
                alt="banner"
              />
            </div>
            <div className="mt-16 w-full md:mt-0 md:col-span-4 max-w-md mx-auto">
              <div className="">
                <h1 className="text-3xl font-semibold text-gray-700 md:max-w-md">
                  Welcome back
                </h1>
                <form onSubmit={handleSubmit} className="mt-4">
                  <div className="">
                    <div>
                      <EmailInputComponent emailOnchange={getUserTypedEmail} />
                    </div>
                    <div className="mt-4">
                      <PasswordInputcomponent
                        passwordChange={getUserTypedpassword}
                      />
                    </div>
                    <div className="mt-10">
                      <button
                        type="submit"
                        className="w-full py-3 text-sm font-semibold bg-[#486EF8] tracking-widest outline-none rounded-lg text-white hover:bg-[#486EF8]/80 active:bg-[#486EF8] transition-all duration-300 active:outline-none"
                      >
                        LOG IN
                      </button>
                    </div>

                    <div className="mt-2 flex items-center justify-end">
                      <Link to="/forget-password">
                        <p className="w-fit text-base text-[#1890FF] border-b-[1.5px] border-b-white hover:border-b-[#1890FF]">
                          Forgot Password?
                        </p>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default LoginPage;
