

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


const broadcastReportAtom = atom({key:'broadcastReportAtom', default:{}});



export  const SetRecoilBroadcastReport = () => useSetRecoilState(broadcastReportAtom);
export  const GetRecoilBroadcastReport = () => useRecoilValue(broadcastReportAtom);





