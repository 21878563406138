import React, { useRef, useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import moment from "moment/moment";
import message from "../../assets/message.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
// import InvoicePaymentTable from "./InvoicePaymentTable";
// import InvoiceDetailsTable from "./InvoiceDetailsTable";
import {GetRecoilApprovedList, SetRecoilApprovedList} from "../../store/customerApprovedAtom";
import { corporateService } from "../../services/corporation.service";
import LogicContext from "../../Context/LogicContext";
import CustomerApproveScroll from "./customerApproveScroll";
import AttachmentModal from "./AttachmentViewModal";
import SampleFileViewerModal from "./SampleFileViewerModal";
import CustomerApproveToggle from "./CustomerApproveToggle";
import CorpFileviewer from "../corporate_approval/CorpFileViewer";






const CustomerApproveDetails = () => {
//   const g = GetRecoilApprovedList();
  const [selectedList, setSelectedList] = useState([]);
  const myPromiseModal = createModal(GeneralModal);
  const getRecoilData = GetRecoilApprovedList();
  const setRecoilData = SetRecoilApprovedList();

  const { customer_id } = useParams();
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const [customerData, setCustomerData] = useState({});
  const [customerDocs, setCustomerDocs] = useState([]);
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];



  useEffect(() => {
       document.title = 'Customer Approval details';
    getDetailsData(customer_id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id]);


  
  const getDetailsData = async (id) => {
    corporateService.getCustomerApproveDetails(id).then((response) => {
    console.log('DATA..... ', response.data);

      if (response.data[0]) {
        setCustomerData(response.data[0]);
      }
    });
  };


  function viewItemDetails(id) {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
    getDetailsData(id);
  }



  function onSelection(list) {
    let tempList = list?.filter((item) => item.checked === true);
    setSelectedList(tempList);
  }


  const updateCustomerStore = async(customer_id) => {
   await corporateService.getCustomerApproveDetails(customer_id).then((response) => {
        if (response.data[0]) {
          setCustomerData(response.data[0]);
          let newList =  getRecoilData.customer_info.map((record)=> {
            if(record?.Customer_Id == customer_id){
              return response.data[0];
            }
            return record;
          });
          
    
          setRecoilData({...getRecoilData,customer_info:newList});
 
        }
      });
   
    }



  const displayStatus = (status, id) =>{
    let defaultClass = 'color_1';

        switch (id) {
          case 1:
          defaultClass = 'color_1';
            break;
          case  2:
          defaultClass = 'color_2';
            break;
          case 3:
          defaultClass = 'color_3';
            break;
          case 4:
          defaultClass = 'color_4';
            break;
          case 5:
          defaultClass = 'color_5';
            break;
        
          case 6:
          defaultClass = 'color_6';
            break;
        
          default:
            break;
        }


        return (
          <span className={`py-2 p-2 text-md ${defaultClass} rounded`}>
          {status}
        </span>
        )
  }


   const approveCustomer = async()=> {
    await myPromiseModal({title: "confirm your action!",
        body: `Do you want to approve Customer?`,showInput: true,
        placeHolder: "Remark",
      }).then((result) => {
        console.log("result ", result);
        if(result?.decision === true){
            corporateService.approveOrCancelCustomerRequest(customer_id,1, result.message ? result.message : 'welcome to Grocedy').then(res => {
                const data =  logicContext.httpResponseManager(res.data[0]);
              if(data?.message_id == 1){
                updateCustomerStore(customer_id);
                logicContext.showToast(data?.message_text, 'success');
              }
                
            }).catch(eeer => {
                console.log("Err ", eeer);
            });
        }

      });
   }


   const declineCustomer = async()=> {
    await myPromiseModal({title: "confirm your action!",
        body: `Do you want to Decline customer request?`,showInput: true,
        placeHolder: "Remark",
      }).then((result) => {
      
        if(result?.decision === true){

          if(!result?.message){
            return logicContext.showToast('Remark is required for selected action!');
          }
            corporateService.approveOrCancelCustomerRequest(customer_id,2, result.message).then(res => {
                console.log('ress',res.data[0]);
              const data =  logicContext.httpResponseManager(res.data[0]);
              if(data?.message_id == 1){
                updateCustomerStore(customer_id);
                logicContext.showToast(data?.message_text, 'success');
              }
            });
        }

      });
   }


   const resendCustomer = async()=> {
    await myPromiseModal({title: "confirm your action!",
        body: `Do you want to Decline customer request?`,showInput: true,
        placeHolder: "remark",
      }).then((result) => {
    
        if(result?.decision === true){
          if(!result?.message){
            return logicContext.showToast('Remark is required for selected action!');
          }
            corporateService.approveOrCancelCustomerRequest(customer_id,3, result.message).then(res => {
                console.log('ress',res.data[0]);
              const data =  logicContext.httpResponseManager(res.data[0]);
              if(data?.message_id == 1){
                updateCustomerStore(customer_id);
                logicContext.showToast(data?.message_text, 'success');
              }
            });
        }

      });
   }


   const toggleItemAdminRole = (isChecked) => {
    if(customerData?.Customer_Corporate_Admin_Status_Id === 1){
      corporateService.toggleAdminStatus(2, customer_id).then( res => {
        const data =  logicContext.httpResponseManager(res.data[0]);
        if(data?.message_id == 1){
          getDetailsData(customer_id);
          // updateCustomerStore(customer_id);
          logicContext.showToast(data?.message_text, 'success');
        }
      });
    }else{
      corporateService.toggleAdminStatus(1, customer_id).then( res => {
        const data =  logicContext.httpResponseManager(res.data[0]);
        if(data?.message_id == 1){
          getDetailsData(customer_id);
          // updateCustomerStore(customer_id);
          logicContext.showToast(data?.message_text, 'success');
        }
      });
    }

   }



  return (
    <>
      <section className=" mt-2 ">
        
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <div className="flex justify-end  ">
                    {/* <SampleFileViewerModal customerData={customerData}  /> */}
                  </div>
          </Grid>
          

          <Grid item xs={12}>
            <section className="flex relative justify-between ">
                <CustomerApproveScroll
                  onSelection={onSelection}
                  viewItemDetails={viewItemDetails}
                ></CustomerApproveScroll>
         

              <section className="w-full z-50  ">
                <section className=" ml-[2vw] mt-2 max-auto max-w-6xl text-filterTextColor">
                  {/* Package page  */}

                  <Grid className="mt-2 border-2 p-2" container spacing={2}>
                 
                    <Grid item xs={6} className=" my-2">
                   
                    <span className="#476ef8 font-bold">  Corporate Information</span>
                      <div>
                        <span className="#476ef8 font-bold"> Company Name : <Link  className="text-primary" to={`/${currModuleId}/${currModuleName}/corporate/details/${customerData?.Corporate_Id}`} >{customerData?.Corporate_Name}</Link> </span>
                      </div>
                      <Grid>
                      <div>
                        <div>
                          <span className="text-sm">
                            Customer ID : {customerData?.Customer_Id}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Customer Type : {customerData?.Customer_Type_Name}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Customer Phone : {customerData?.Customer_Mobile_No}
                          </span>
                        </div>
                      </div>
                      </Grid>
                  
                      <Grid item xs={8}>
                   
                      <div>
                        
                        <div>
                          <span className="text-sm">
                            Created Date : {moment(customerData?.Encoded_Date).format("DD-MM-Y")}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm"> {customerData?.Number_Of_Officer_Name} </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Created Time : {moment(customerData?.Encoded_Date).format("hh:mm:ss a")}
                          </span>
                        </div>
                      </div>
                    </Grid>
                  
                    </Grid>
                    <Grid item xs={4} className=" my-2">
                    <div className="flex  justify-end">
                        {displayStatus(customerData?.Customer_Approve_Status_Name, customerData?.Customer_Approve_Status_Id)}
                       
                      </div>
                      <br/>
                       
                        <CustomerApproveToggle toggleItemAdminRole={toggleItemAdminRole} val={customerData?.Customer_Corporate_Admin_Status_Id} />
                    </Grid>
                    <Grid item xs={12} className="border-2 my-2">
                      <div>
                      <span className="#476ef8 font-bold"> Customer Information</span>
                      <div>
                          <span className="text-sm">
                           
                        <span className="#476ef8 font-bold"> Customer Name : 
                          <Link  className="text-primary" to={`/${currModuleId}/${currModuleName}/customers/customer-details/${customer_id}`} >{customerData?.Customer_Full_Name}</Link> </span>

                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Email Address : {customerData?.Customer_Email_Address}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Contact Person : {customerData?.Customer_Full_Name}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Contact Number : {customerData?.Customer_Mobile_No}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Registration Date : {moment(customerData?.Encoded_Date).format('LLL')} 
                          </span>
                        </div>

                        <div>
                          <span className="text-sm">Address : {customerData?.Corporate_Adddress} </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} className="border-2 my-2">
                      <div>
                      <span className="#476ef8 font-bold">Approval Information</span>
                        <div>
                          <span className="text-sm">
                            Review by : {customerData?.Encoded_By_Name}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Review Date : {moment(customerData?.Encoded_Date).format('LLL')}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">Type : {customerData?.Corporate_Type_Name} </span>
                        </div>
                        <div>
                          <span className="text-sm">Review Remark :  </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
               
                      {/* table */}
                  <table class="table mt-4">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">File Name</th>
                        <th scope="col">File URl</th>
                      </tr>
                    </thead>
                    <tbody>
                    
                              <tr>
                        <th scope="row"> </th>
                        <td>Upload</td>
                        <td> 
                          <CorpFileviewer fileSource={customerData?.Customer_Corporate_Id_Upload_URL} />
                           </td>
                      </tr>
                         
                    </tbody>
                  </table>
                  {/* table end */}


                  <div className=" mt-5 flex justify-end">
                  <button onClick={()=> approveCustomer()} className="btn btn-primary btn-md mr- btn-sm">APPROVE</button>
                    <button onClick={()=> declineCustomer()} className="btn btn-danger btn-md mx-2 btn-sm">DECLINE</button>
                    <button onClick={()=> resendCustomer()} className="btn btn-success btn-md mr-5 btn-sm">RESEND</button>
             
                  
                </div>

                       
                </section>
              </section>
            </section>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default CustomerApproveDetails;
