import React, { useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { notificationService } from "../../services/notification.service";

import LogicContext from "../../Context/LogicContext";

import NotificationScrol from "./NotificationScrol";
import NotificationDetaisTable from "./NotificationDetaisTable";
import moment from "moment";
// import { useReactToPrint } from 'react-to-print';



const NotificationDetails = () => {
  // 
  const {  id } = useParams();
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const [notifyDetailList, setNotifyDetailList] = useState([]);
  const [delivered, setDelivered] = useState(0);
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];


  useEffect(()=> {
    notificationService.getNotifyDetails(id).then(res => {
      if(res?.data?.length){
        setNotifyDetailList(res.data);
      }
      let count = 0;
      res?.data.forEach(element => {
        if(element?.Read_Status_Id === 1){
          count += 1;
        }
      });
      setDelivered(count);
    });
  },[id]);
  

  return (
    <>
  
     <section className="  mt-2 ">
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <section className="mt-4 flex justify-end  ">
              <div className="flex  md:">
                <div>
             
                  <div className="flex ">
                    <button
                      style={{ borderRadius: "5px" }}
                      className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                    >
                      <img src={paper} alt="" className="" />
                    </button>
                    <button
                      style={{ borderRadius: "5px" }}
                      className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                    >
                      <img src={pdf} alt="" className="" />
                    </button>
                    <button
                      style={{ borderRadius: "5px" }}
                      className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                    >
                      <img src={printer} alt="" className="" />
                    </button>

                    <button
                      style={{ borderRadius: "5px" }}
                     
                      className="px-3 mr-2 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 "
                    >
                      <img src={pencil} alt="" />
                    </button>
                    <button
                      // onClick={deletePaymentReceive}
                      style={{ borderRadius: "5px" }}
                      className="  px-3 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 ml-2"
                    >
                      <img src={trash} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </Grid>
          

          <Grid item xs={12}>
            <section className="flex relative justify-between ">
              <NotificationScrol
               
              ></NotificationScrol>

              <section className="w-full z-50  ">
                <section className=" ml-[2vw] mt-2 max-auto max-w-6xl text-filterTextColor">
                  {/* Package page  */}

                  <Grid className="mt-2 border-2 p-2" container spacing={2}>
                    <Grid item xs={12} className="border-2 my-2">
                      <div className="m-4">
                        <span className="#476ef8 text-primary">{moment(notifyDetailList[0]?.Notification_Date).format(" DD-MM-YYYY : HH:mm:ss")} </span>
                        <span className="#476ef8 font-bold ml-5 text-gray-500"> Delivered {delivered} / {notifyDetailList?.length} </span>
                      </div>
                    
                    </Grid>
                    <Grid item xs={12}spacing={2} className="border-2 my-2">

                   
                      <div>
                  
                        <div>
                          <span className="text-sm">
                          Date : <span className="ml-2 font-bold text-sm"> {moment(notifyDetailList[0]?.Notification_Date).format(" DD-MM-YYYY : HH:mm:ss")} </span>
                          </span>
                        </div>
                        
                        <div>
                          <span className="text-sm">
                          Notification Type : <span className="ml-2 font-bold text-sm">{notifyDetailList[0]?.Notification_Type_Name}</span>
                          </span>
                        </div>
                        
                        <div>
                          <span className="text-sm w-full">
                          Sent By : <span className=" w-full text-center font-bold text-xs">{notifyDetailList[0]?.Notification_Send_By_Name}</span>
                          </span>
                        </div>
                        <div className="">
                          <span className="text-sm w-full">
                          Subject : <span className=" w-full text-center font-bold text-xs">{notifyDetailList[0]?.Notification_Subject}</span>
                          </span>
                        </div>
                        <div className="mb-3">
                          <span className="text-sm w-full">
                          Description : <span className=" w-full text-center font-bold text-xs">{notifyDetailList[0]?.Notification_Message}</span>
                          </span>
                        </div>
                      </div>
                    </Grid>
                  
                    <Grid item xs={12} className="border-2 my-2">
                    <div className="m-2">
                        <span className="#476ef8 font-bold">Customer Details </span>
                      </div>
                        {/* <NotificationTable></NotificationTable> */}
                     <NotificationDetaisTable list={notifyDetailList} ></NotificationDetaisTable>
                    </Grid>
                  </Grid>
                </section>
              </section>
            </section>
          </Grid>
        </Grid>
      </section>
   
    </>
  );
};

export default NotificationDetails;
