import { DataGrid ,  gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport ,
    useGridApiContext,
    useGridSelector} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';
import { useLocation, useNavigate } from "react-router-dom";
import moment from 'moment';




  const TableHead = [
    { field: 'Corporate_Ref_No', headerName:'Ref No' , width: 100 , headerClassName: "tableHeader "},
    { field: 'Voucher_Date', headerName:'Date' , width: 210 , headerClassName: "tableHeader "},
     { field: 'Corporate_Name', headerName:'Corporate' , width: 150 , headerClassName: "tableHeader "},
     { field: 'Voucher_Quantity', headerName:'Voucher quantity' , width: 150 , headerClassName: "tableHeader "},
     { field: 'Voucher_Value', headerName:'Amount/Voucher (₦)' , width: 150 , headerClassName: "tableHeader "},
     { field: 'Total_Voucher_Amount', headerName:'Total amount (₦)' , width: 150 , headerClassName: "tableHeader "},
     { field: 'Created_By_Name', headerName:'Created By' , width: 150 , headerClassName: "tableHeader "},
    ]


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }


  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }


function VoucherCorporateMainTable(props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();  
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];


  function OnItemCellClick(event){
    const id = event.row['Corporate_Ref_No'];
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`);
}



    return ( 
        <>


<div className="table" style={{ height: 500, width: '100%' }}>
      <DataGrid  rows={ props?.list.map((item, i) =>  { 
        return {...item, id: i,Voucher_Value: '₦' + item?.Voucher_Value?.toLocaleString() ,
        Total_Voucher_Amount: item?.Total_Voucher_Amount.toLocaleString(), Voucher_Quantity: item?.Voucher_Quantity.toLocaleString() 
        ,Voucher_Date: moment(item?.Voucher_Date).format('llll')}
      })}
        columns={TableHead}
        pageSize={6}
        rowsPerPageOptions={[6]}
        // checkboxSelection={true}
        onRowClick={(event)=> {
          OnItemCellClick(event);
        }}
        components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
          }}
          onCellClick={( event) => {
            event.defaultMuiPrevented = true;
            
          }}
          
       
          sx={{
            // boxShadow: 2,
            // border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
              cursor: 'pointer',
            },
          }}
      />
  
    </div>
        
        </>
     );
}

export default VoucherCorporateMainTable