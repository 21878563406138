import CustomerContext from "../../Context/CustomerContext";
import { useContext, useEffect } from "react";

import {
  GetCustomerListBackUpAtom,
  SetCustomerListAtom,
  SetCustomerListBackUpAtom,
} from "../../store/customerAtom";
import { Grid } from "@mui/material";
// import CurrencyFormat from "react-currency-format";
// import moment from "moment";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useRef } from "react";
import { customerService } from "../../services/customer.service";

function CustomerDetailsSideScroller(props) {
  const customerAtom = GetCustomerListBackUpAtom();
  const { customer_id } = useParams();
  const [tableData, setTableData] = useState([]);
  const mountRef = useRef(true);

  useEffect(() => {
    mountRef.current = true;
    // if(customerList.length  === 0){
    //     customerContext.getCustomers();
    // }

    if (mountRef.current) {
      setTableData(
        customerAtom?.customers?.map((ele) => {
          return { ...ele, checked: false };
        })
      );
    }

    return () => {
      mountRef.current = false;
    };
  }, [customerAtom?.customers]);

  const onCheckAll = async (e, index) => {
    let element = tableData[index];
    tableData[index] = { ...element, checked: e.target.checked };
    await setTableData((prev) => {
      return [...tableData];
    });
    props?.onSelection(tableData);
  };

  return (
    <>
      <section className="py-2 border-[1px] rounded-lg bg-white border-gray-200 flex flex-col overflow-y-scroll h-[300px] lg:h-[1000px] scrollbar-thumb-gray-500 scrollbar-thin scrollbar-track-gray-100 pb-[100px]">
        {tableData?.map((cust, i) => {
          return (
            <div
              key={i}
              className={`flex hover:bg-gray-50 border-b-[1px] border-gray-200 px-2
                ${
                  customer_id == cust?.Customer_Id
                    ? "app_bg_color1 py-1 "
                    : "py-1"
                }
              `}
            >
              <label className="">
                <input
                  onChange={(e) => onCheckAll(e, i)}
                  checked={cust?.checked ? cust?.checked : ""}
                  type="checkbox"
                  className="w-4 h-4 rounded-sm border-[1px] border-gray-400 cursor-pointer"
                />
              </label>

              <div onClick={() => props.viewCustomer(cust.Customer_Id)}>
                <label className="ml-2 ">
                  <span className="text-sm text-[#262626] ">{cust?.Name}</span>{" "}
                  <br></br>
                  <span className="text-sm text-[#262626]">
                    <i> {cust?.Customer_Id} </i>
                  </span>
                  <br></br>
                  <span className="text-xs text-[#262626] md:text-sm">
                    {cust?.Customer_Type_Name1}{" "}
                  </span>
                </label>
              </div>

              <label
                onClick={() => props.viewCustomer(cust.Customer_Id)}
                className=""
              >
                <span className="text-xs text-[#262626] md:text-sm">
                  {cust?.Company_Name}
                </span>
                <br></br>
                {/* status : {cust?.Customer_Status} */}
              </label>
            </div>
          );
        })}
      </section>
    </>
  );
}

export default CustomerDetailsSideScroller;
