import { Grid } from "@mui/material";
import React, { useEffect } from "react";

import { useParams } from "react-router-dom";
import { accountService } from "../../services/accountService";
import { useState } from "react";
import RoomIcon from '@material-ui/icons/Room';
import moment from "moment";


function PurchaseOrderHistory() {
  const { purchase_id } = useParams();
  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    let isMount = true;
    accountService.getPurchaseHistory(purchase_id).then((response) => {
      if (response.data !== undefined && isMount) {
        setHistoryData(response.data);
      }
    });

    return () => {
      isMount = false;
    };
  }, [purchase_id]);



  return (
    <section className="   mt-2 w-full max-w-6xl text-filterTextColor">
      <Grid className="mt-2   " container spacing={2}>
        <Grid className=" border-2 ml-3" container spacing={2}>
          <Grid item xs={12}>
            {/* <span className="text-md" style={{ color: "blue" }}>
              View History
            </span> */}
          </Grid>
          <Grid item xs={12}>

            {historyData?.map((ele, i)=> {
              return (
                <Grid key={i}  item xs={12} container className=" pb-5 " spacing={2}>
              <Grid item xs={5}>
                <span className="text-sm font-bold"> { moment(ele.History_Date).format(" DD-MM-YYYY:HH:mm:ss")} </span>
              </Grid>
              <Grid item xs={7}>
                <span style={{border:"1px solid green "}} className=" px-[1.7vh] py-3 rounded-full  ">
                <RoomIcon />

                </span>
                <span className="text-sm ml-2">{ele.History_Description} <span className="font-bold"> </span> by <i>{ele.History_Created_By_Name} </i> </span>
              </Grid>
            </Grid>
              )
            })}
          

            
         
         
         
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
}

export default PurchaseOrderHistory;
