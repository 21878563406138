import React, { useEffect, useState, useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "react-bootstrap";
import PurchaseAmount from "./PurchaseAmount";
import CurrencyFormat from "react-currency-format";
import PurchaseTax from "./PurchaseTax";
import "./AddPurchaseTable.css"
import plus from "../../assets/Plus.svg";
import LogicContext from "../../Context/LogicContext";
import { NumericFormat } from 'react-number-format';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import PurchaseSearchItem from "./PurchaseSearchItem";

function AddPurchaseTable(props) {
  const [itemTableList, setItemTableList] = useState([]);
  const [tempSelection, setTempSelection] = useState({});
  const logicContext = useContext(LogicContext);

  const currentSelectedItem = (item) => {
    console.log('ss ', item)
    setTempSelection({ ...item, Item_Quantity: 1, Item_Rate: parseFloat(item.Purchase_Rate), Account_Id:0 , Purchase_Amount:0, Item_Tax_Id:0});
  }


  const AddToTable = () => {
    if (tempSelection?.Item_Id) {
      const found = itemTableList.find(element => element.Item_Id === tempSelection.Item_Id);
      if (found) {
        return logicContext.showToast('Selected item already in selection', 'error');
      }
      setItemTableList((prev) => {
        return ([...prev, { ...tempSelection }])
      });
      props?.onSelectionList([...itemTableList, { ...tempSelection }]);

    } else {
      return logicContext.showToast('Please select an item', 'info');
    }

  }

  useEffect(() => {

      if (props?.defaultList?.length > 0) {
        setItemTableList(props?.defaultList);
        
        setTimeout(() => {
          props?.onSelectionList([...props?.defaultList])
        }, 2500);
      }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.defaultList?.length])



  const onQuantityChange = (qty, index) => {
    const newQty = parseInt(qty)
    if (newQty > 0) {
      const currentItem = itemTableList[index];
      const newList = itemTableList?.map((item) => {
        if (item.Item_Id === currentItem.Item_Id) {
          return { ...item, Item_Quantity: parseFloat(newQty) }
        } else {
          return item;
        }

      });
      setItemTableList([...newList]);
      props?.onSelectionList([...newList])

    } else {

      setItemTableList([...itemTableList]);
    }



  }

  const onRateChange = (qty, index) => {
    const newQty = parseInt(qty)
    if (newQty > 0) {
      const currentItem = itemTableList[index];
      const newList = itemTableList?.map((item) => {
        if (item.Item_Id === currentItem.Item_Id) {
          return { ...item, Item_Rate: parseFloat(newQty) }
        } else {
          return item;
        }

      });
      setItemTableList([...newList]);
      props?.onSelectionList([...newList])

    } else {

      setItemTableList([...itemTableList]);
    }



  }




  function deleteItemFromTable(removedItem) {
    const newList = itemTableList.filter((item) => item.Item_Id !== removedItem.Item_Id);
    setItemTableList(newList);
    props?.onSelectionList(newList);
  }



  function onTaxSelect(data, index) {
    console.log(data, index)
    const currentItem = itemTableList[index];
    const newList = itemTableList?.map((item) => {
      if (item.Item_Id === currentItem.Item_Id) {
        return { ...item, Item_Tax_Id: data.Tax_Id }
      } else {
        return item;
      }

    });
    setItemTableList(newList);
    props?.onSelectionList(newList);
  }


  function onTaxAccountSelect(data, index) {
    console.log(data, index)
    const currentItem = itemTableList[index];
    const newList = itemTableList?.map((item) => {
      if (item.Item_Id === currentItem.Item_Id) {
        return { ...item, Account_Id: data.Account_Id }
      } else {
        return item;
      }

    });
    setItemTableList(newList);
    props?.onSelectionList(newList);
  }





  return (
    <section className=" mt-2 max-auto border-2 py-2 px-2 max-w-6xl text-filterTextColor">
      <section className=" ">
        <section className=" mt-1 right-1   md:  ">
          <section className="">
            <section className="profile_section ">
              <div className="  ">
                <div className="flex  justify-between w-full">
                  {" "}
                  <PurchaseSearchItem currentSelectedItem={currentSelectedItem} />

                  <div onClick={AddToTable}
                    style={{ borderRadius: "5px" }}
                    className=" btn rounded mt-4  px-3 h-[5.8vh] text-filterTextColor bg-filterColor  ml-1 " >
                    <img src={plus} alt="" />
                  </div>

                  <div>

                  </div>
                </div>
              </div>
              {/* end */}

              <section className="mt-3">
                {/*  start table */}
                <div className=" ">
                  <TableContainer className="mt-3 mb-5  h-[30vh]">
                    <Table  sx={{ minWidth: 650 }} aria-label="simple table">
                    <colgroup>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'10%'}}/>
                    <col style={{width:'10%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'15%'}}/>
                    <col style={{width:'5%'}}/>
                </colgroup>
                      <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                        <TableRow>
                          <TableCell className="subheading text-filterTextColor">Item Details</TableCell>
                          <TableCell  className="subheading text-filterTextColor">Account</TableCell>
                          <TableCell  className="subheading text-filterTextColor">Quantity</TableCell>
                          <TableCell  className="subheading text-filterTextColor">Rate</TableCell>
                          <TableCell  className="subheading text-filterTextColor">Tax</TableCell>
                          <TableCell  className="subheading text-filterTextColor">Amount</TableCell>
                          <TableCell  className="subheading text-filterTextColor"></TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {itemTableList?.map((item, i)=> {

                        return (
                          <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} >
                          <TableCell className="Table2" style={{ width: "120px " }}  align="left">
                          <span>  {item.Item_Name}</span>
                          </TableCell>

                          <TableCell align="left">
                            <PurchaseAmount defaultVal={{label: item.Account_Name}} onTaxAccountSelect={(data)=> onTaxAccountSelect(data, i)} />
                            </TableCell>

                          <TableCell  align="left">
                          
                                <NumericFormat
                                value={item.Item_Quantity?.toFixed(2)}
                                style={{ width: "120px" }}
                                thousandSeparator=","
                                decimalSeparator="."
                                allowedDecimalSeparators={['%']}
                                onValueChange={(values, sourceInfo) => {
                                  onQuantityChange(parseFloat(Number(values.value)), i)
                                }}
                              />
                          </TableCell>

                          <TableCell align="left"> 
                         <NumericFormat
                                value={item.Item_Rate?.toFixed(2)}
                                style={{ width: "120px" }}
                                thousandSeparator=","
                                decimalSeparator="."
                                // allowedDecimalSeparators={['.']}
                                onValueChange={(values, sourceInfo) => {
                                  onRateChange(values.value, i)
                                }}
                              />

                            </TableCell>

                          <TableCell align="left"> 
                            <PurchaseTax defaultVal={{label: item?.Tax_Name}} onTaxSelect={(data)=> onTaxSelect(data, i)} />
                          </TableCell>

                          <TableCell align="left">
                            <CurrencyFormat
                            className={"font-bold " }
                              value={parseFloat(item.Item_Quantity * item.Item_Rate)?.toFixed(2)}
                              displayType={"text"}  thousandSeparator={true} prefix={"₦"} />
                          </TableCell>

                          <TableCell align="left">
                            <DeleteIcon color="error" onClick={()=>deleteItemFromTable(item)} />
                          </TableCell>

                        </TableRow>
                          
                        )
                        })}
                    
                      </TableBody>
                    </Table>
                  </TableContainer>


                </div>

                {/* end table */}

              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}

export default AddPurchaseTable;
