import { atom , useSetRecoilState, useRecoilValue, selector} from "recoil";



export const voucherAtom = atom({key:'voucher_atom',default:{voucher_info:[], corporate_info:[], newVoucherList:[]}});
export const voucherOfflineAtom = atom({key:'voucher_offline_atom',default:[]});
export const generalVoucherAtomAtom = atom({key:'general_voucher__atom',default:[]});

export  const SetVoucherListAtom = () => useSetRecoilState(voucherAtom);
export  const GetVoucherListAtom = () => useRecoilValue(voucherAtom);

export  const SetOfflineVoucherListAtom = () => useSetRecoilState(voucherOfflineAtom);
export  const GetOfflineVoucherListAtom = () => useRecoilValue(voucherOfflineAtom);


export  const SetGeneralVoucherListAtom = () => useSetRecoilState(generalVoucherAtomAtom);
export  const GetGeneralVoucherListAtom = () => useRecoilValue(generalVoucherAtomAtom);





