import { GetProductMasterData} from '../store/productAtom';



function PlanMinimumFeeCurrency(props) {
    const masterData = GetProductMasterData();

    const handleChange = (val) => {
      props.onSelectMinimumCurrency(val.Code)
      };

 
     const  createSelectItems = ()=> {
        let items = [];         
    
        masterData[14]?.forEach((item, i)=> {
          items.push(<option key={i} value={i}>{item.Name}</option>)
        });


        
        return items;
    } 
      

    return ( 
        <>

  <select onChange={handleChange}>
    {createSelectItems()} 
  </select>
        </>
     );
}

export default PlanMinimumFeeCurrency;