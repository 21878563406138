// import CustomerContext from "../Context/CustomerContext";
import { useEffect, useState } from "react";
import { useContext } from "react";
import {GetRecoilApprovedList, SetRecoilApprovedList} from "../../store/customerApprovedAtom"
import { Grid } from "@mui/material";
import CurrencyFormat from 'react-currency-format';
import  moment from 'moment';
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import {  useParams } from "react-router-dom";


function CustomerApproveScroll(props) {

//   const getInvoiceFilters = GetInvoiceFilter();
 const customerApprovedData = GetRecoilApprovedList();
 const setApprovedList = SetRecoilApprovedList();
 const [tableData, setTableData] = useState([]); 
 const {  customer_id } = useParams();



 useEffect(()=> {
setTableData(customerApprovedData?.customer_info?.map(ele => { return  {...ele, checked: false}} ).reverse());
 },[customerApprovedData?.customer_info]);

 


// const onSelectBulkAction = (action)=> {
//   const option = action.target.value;
//   if(option === ''){
//       return setTableData(invoiceList?.map(ele => { return {...ele, checked: false} }));
//   }
//   var newList = invoiceList.filter((item)=> item.Invoice_Status_Id == option );
//   setTableData(newList?.map(ele => { return {...ele, checked: false} }));
// }


const onCheckAll = async (e, index)=>{
  let element =  tableData[index];
  tableData[index] = {...element, checked:e.target.checked}
 await setTableData((prev)=> { return([...tableData])});
 props?.onSelection(tableData);
}


const onCheckboxClick = async (e, index) => {
  let element = tableData[index];
  tableData[index] = { ...element, checked: e.target.checked };
  await setTableData((prev) => {
    return [...tableData];
  });
  props?.onSelection(tableData);
};




const manageInvoiceStatus = (status, id) =>{
  let defaultClass = 'color_1 p-1';

  switch (id) {
    case 1:
    defaultClass = 'color_1 p-1';
      break;
    case  2:
    defaultClass = 'color_2 p-1';
      break;
    case 3:
    defaultClass = 'color_3 p-1';
      break;
    case 4:
    defaultClass = 'color_4 p-1';
      break;
    case 5:
    defaultClass = 'color_5 p-1';
      break;
  
    case 6:
    defaultClass = 'color_6 p-1';
      break;
    case 7:
    defaultClass = 'color_7 p-1';
      break;
    case 8:
    defaultClass = 'color_8 p-1';
      break;
  
    default:
      break;
  }


      return (
        <span className={`text-sm font-bold md:text-sm ${defaultClass} `}>
        {status}
      </span>
     
      )}




      return ( 
        <>
     <section className="w-30  z-0 mt-3 main2 	 border-2 b">
      {/* <ActionPaneComponent bulkActionData={billListData['Filtering_Criteria']}
        onSelectBulkAction={onActionFilterPerform} />
      <div>
        <label className=" mt-3 mb-3 ml-2 ">
          <input onChange={(e) => onCheckAllElement(checkedAll)}
            type="checkbox" className="w-[2vh] h-[2vh]" checked={checkedAll ? checkedAll : ''} />
          <span className="ml-2 font-bold " >Select all</span>
        </label>
      </div> */}

      {tableData?.map((corp, index) => {
        return (

          <div key={index}
            className={"flex hover:bg-filterColor border  "
              + (Number(customer_id) === corp?.Customer_Id ? "app_bg_color1 mb-2 cursor" : "mb-2 cursor")}
          >
            <Grid container className="py-2" spacing={1}>
              <Grid item xs={1}>
                <label className="ml-1">
                  <input onChange={(e) => onCheckboxClick(e, index)} checked={corp?.checked ? corp?.checked : ''}
                    type="checkbox" className="w-[2vh] h-[2vh]" />
                </label>
              </Grid>
              <Grid onClick={() => props.viewItemDetails(corp?.Customer_Id)}
                item xs={6}>
                <label className="ml-2 ">
                  <span className="subheading font-bold text-sm">{corp?.Corporate_Name} </span>{" "}
                  <br></br>
                  <span className="subheading font-bold text-sm">
                    <i>{corp?.Customer_Email_Address} </i>
                  </span>
                  <br></br>
                  <div>{corp?.Customer_Mobile_No} </div>
                </label>
              </Grid>
              <Grid item xs={5}>

                <div>    <label className="ml-2" >
                  {manageInvoiceStatus(corp?.Customer_Approve_Status_Name, corp?.Customer_Approve_Status_Id)}   </label></div>

              </Grid>
            </Grid>
          </div>

        )
      })

      }

    </section>
        </>
      );

}

export default CustomerApproveScroll;
