import { DataGrid ,  gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport ,
    useGridApiContext,
    useGridSelector} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';
  import {GetVendorListData, SetVendorListData} from '../store/vendorAtom';
import { purchaseService } from "../services/purchase.service";
import { useContext, useEffect, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import LogicContext from "../Context/LogicContext";
import TableSelect from "../helpers/TableSelect";








  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }


  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }


function VendorTable(props) {
  const getVendorListData = GetVendorListData();
  const setVendorListData = SetVendorListData();
const [vendorList, setVendorList] = useState([]);
const [tableHeadList, setTableHeadHead] = useState([]);
const logicContext = useContext(LogicContext); 

const navigate = useNavigate();
const { pathname } = useLocation();  
var url = pathname.split('/')
const currModuleId = url[1];
const currModuleName = url[2];

const currUserMenu = url[3];


useEffect(()=> {
  let isMount = true;
  if(getVendorListData['Display_Column']?.length){
    let newTableHead = getVendorListData['Display_Column']?.filter((item) => item.Display_Id === 1).map((item) => {
      return {
        ...item,field: item.Column_Value.trim(),headerName: item.Column_Name, width: 150 };
    }).sort((a, b) => {
      return a.Print_Sequence - b.Print_Sequence;
    });
   if(isMount)  setTableHeadHead(newTableHead);

  }
  

  return ()=> {
    isMount = false;
  }
},[getVendorListData])


useEffect(()=> {
  if(props?.tableData?.length > 0){
    var list = props?.tableData?.map((item, i) => {
      return { ...item, id: i } });
      setVendorList(list);
  }
},[ props?.tableData]);


function OnItemCellClick(event){
  const id = event.row['Vendor_Id'];
  navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`)
}


function onSelectChange(selectedList) {
  var selection = selectedList?.map((index) => {
    return props.tableData[index];
  });
  props.selectedTableItems(selection);
}


const tableMenuSelect = (option, i) => {
  var choice = option.Display_Id === 1 ? 0 : 1;
  var temp = getVendorListData['Display_Column']?.map((item) => {
      if (item.Ref_No === option.Ref_No) {
        return { ...item, Display_Id: choice };
      }
      return item;
    }) ;
 
setVendorListData({...getVendorListData, Display_Column: temp })
};


function saveCurrentTableHeader() {
  var xmlString = "";
  getVendorListData['Display_Column']?.map((element) => {
    if (element.Display_Id === 1) {
      xmlString += `<a><refno>${element.Ref_No} </refno></a>`;
    }
  });
  purchaseService.saveVendorTableHeader({xml_string: `<root> ${xmlString} </root>` , option: 1}).then((res)=> {
    logicContext.showToast('updated successfully', 'success');
  }).catch((err)=> {

  });



}



    return ( 
        <>
                <div className="dropdown mr-2">
        <button
          className="btn btn-primary bg-primary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-toggle="dropdown"
          aria-expanded="false"
        ></button>

        <ul className="dropdown-menu  scrollable-menu "
          role="menu"
          aria-labelledby="dropdownMenuButton1" >
          {getVendorListData['Display_Column']?.map((val, i) => {
            return (
              <TableSelect
                key={i}
                val={val}
                i={i}
                tableMenuSelect={tableMenuSelect}
              />
            );
          })}

          <div className=" flex  justify-center">
            <div className="">
              {" "}
              <button
                style={{ backgroundColor: "#476EF8" }}
                className="btn text-white  btn-sm"
                onClick={() => saveCurrentTableHeader()}
              >
                Save
              </button>{" "}
            </div>
            <div className="ml-3">
              {" "}
              <button className="btn text-white  bg-danger btn-sm">
                Cancel
              </button>{" "}
            </div>
          </div>
        </ul>
      </div>

      <DataGrid className="table bg-light" style={{ height: 500, width: '100%' }} rows={vendorList}
        columns={tableHeadList}
        pageSize={6}
        rowsPerPageOptions={[6]}
        checkboxSelection={true}
        components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
          }}
          onCellClick={( event) => {
            event.defaultMuiPrevented = true;
            
          }}
          onRowSelectionModelChange={onSelectChange}
          onRowClick={(event)=> {
            OnItemCellClick(event);
          }}
        
     
          
       
          sx={{
            // boxShadow: 2,
            // border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
              cursor: 'pointer',
            },
          }}
      />
  
   
        
        </>
     );
}

export default VendorTable
;