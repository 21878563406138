import React, {useState, useEffect} from "react";


import eclips from "../../assets/greenEclips.svg";
import { GetSubList} from "../../store/subscriptionAtom";

import {  useParams } from "react-router-dom";
import CurrencyFormat from "react-currency-format";




function SubDetailsScroll(props) {
  const subList = GetSubList();
  const [tableData, setTableData] = useState([]); 

  const { sub_id } = useParams();
 

  useEffect(()=> {
    if(subList?.length > 0){
     let tempList = subList?.map((item)=> {
          return ({...item, checked: false})
        })
      setTableData(tempList.slice(0, 20));
    }
  },[subList ]);


  function paginate( e) {
    let tempList =  subList?.slice(0, Number(e.target.value));
           tempList = tempList?.map((item)=> {
            return ({...item, checked: false}) });
          setTableData([...tempList]);
  }


  function customFilter(code) {
    switch (code) {
      case '1':
        setTableData(subList)
        break;
      case '2':
        const activeList = subList.filter(item =>  item.Subscription_Status_Id ===1)
        setTableData(activeList)
        break;
      case '3':
        const trialList = subList.filter(item =>  item.Subscription_Status_Id ===2)
        setTableData(trialList)
        break;
      case '4':
        const temp = subList.filter(item =>  item.Subscription_Status_Id ===4)
        setTableData(temp)
        break;
      default:
        break;
    }
  
  }


  const onCheckAll = async (e, index)=>{
    let element =  tableData[index];
    tableData[index] = {...element, checked:e.target.checked}
   await setTableData((prev)=> { return([...tableData])});
   props?.onSelection(tableData);
  }



  return (
    <>
     <section className="bg-r w-30">
     <section className="  w-full  z-0 mt-3 	main border-1">
        {/* <p className="ml-4 mt-4 text-xs font-bold md:text-sm ">Items Information</p> */}
     <section className="    h-[200vh]">
     <div>
          <button className="md:mr-3 w-120 ">
            {" "}
            <select onChange={(e)=> customFilter(e.target.value)}
              style={{
                border: "none",
                borderRadius: "5px",
                backgroundColor: "#E5E5E5",
                color: "#45444B",
              }}
              className=" mb-4 text-xs" >
              <option value={1}  className="bg-filterColor">
                All
              </option>
              <option value={2}className="bg-filterColor">
                Active
              </option>
              <option value={3}  className="bg-filterColor">
              Trial
              </option>
              <option value={4} className="bg-filterColor">
              Trial Expired in the Previous Week
              </option>
              <option value={5} className="bg-filterColor">
              Trial Expiring in the Next Week
              </option>
              <option value={6} className="bg-filterColor">
                Packing Materials
              </option>
            </select>
          </button>
        </div>

        <div >
          {tableData?.map((sub, i)=> {
            return (
              <div key={i} sx={{ minWidth: 275 }}>
              <div  className="card cursor hover:bg-filterColor border border-dark-1 m-1 ">
                <div  container spacing={1}
                 className={"row " +(sub_id == sub?.Subscription_Ref_No ?"app_bg_color1 mb-2 cursor" : "mb-2 cursor") }>
                  <div item xs={6} className="col ">
                    <div>
                      <div >
                        <label className="">
                          <input  onChange={(e)=> onCheckAll(e, i)}  checked={sub?.checked ? sub?.checked : '' } 
                           type="checkbox" className="w-[2vh] h-[2vh]" />
                        </label>
                        <span onClick={()=> props.viewItemDetails(sub.Subscription_Ref_No)} className="text-sm ml-2">{sub?.Customer_First_Name} </span>
                      </div>
                      <i className="text-dark">Next Renewal on {sub?.Next_Billed_Date_String} </i>
                    </div>
                  </div>
                  <div  onClick={()=> props.viewItemDetails(sub.Subscription_Ref_No)} item xs={6} className=" col  ">
                      
                      <div>
                       
                          <CurrencyFormat className="font-weight-bold" value={sub?.Subscription_Amount?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                      </div>
  
                    <div  style={{backgroundColor:"rgba(112, 204, 64, 0.1)"}} className="rounded-full  flex justify-between py-1 w-full mt-7 mr-2">
                      <div className="mt-1.5">
                      <img src={eclips} alt="" />
                      </div>
                      <div style={{color:"#70CC40"}} className="text-xs ml-2 mr-2">{sub?.Subscription_Status_Name} </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )
          })}
        
        </div>
    
     </section>
    
      </section>
      <div className="clearfix mt-3 ml-2 text-center">
        <select  style={{backgroundColor:'#F1F1F1',}} onChange={paginate}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
        </div>
    
     </section>
    </> 
  );
}

export default SubDetailsScroll;
