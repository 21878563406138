import { useEffect } from 'react';
import Select from 'react-select';


function CountrySelect(props) {


  useEffect(()=> {

  },[props?.defaultVal]);

  const customOption = props?.customList?.map((item)=> {
    return ({...item, label:`${item.Country_Name}`})
  });
  
  
  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      backgroundColor: '#F1F1F1',
      zIndex: 0 
      // width: 512,
    }),
  
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '', color:""}),
    menuPortal:styles => ({ ...styles, zIndex: 99999 })
  }

    return ( 
        <>
         <Select  defaultValue={props?.defaultVal}
          onChange={(e)=> props?.onCountrySelect(e)} options={customOption}     styles={customStyles} />
        </>
     );
}

export default CountrySelect;
