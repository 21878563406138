import { useEffect, useState } from "react";
import { itemsSetupService } from "../services/itemSetup.service";
import { useParams } from "react-router-dom";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Table } from "react-bootstrap";

const CompositesSellDetails = (props) => {
  const [tableData, setTableData] = useState([]);
  const { group_id } = useParams();
  const [grandTotal, setGrandTotal] = useState(0);

  useEffect(() => {
    itemsSetupService.getCompositeSellDetails(group_id).then((res) => {
      if (res.data !== undefined) {
        setTableData(res.data);
        res.data?.map((item, i) => {
          setGrandTotal((prev) => {
            return prev + item.Total_Amount;
          });
        });
      }
    });
  }, [group_id]);

  return (
    <>
      <div className=" ">
        <TableContainer className="mt-3">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#F1F1F1" }}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell>Category</TableCell>
                <TableCell align="center">Total Quantity</TableCell>
                <TableCell align="center">Rate</TableCell>
                <TableCell align="center">Total Amount</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData?.map((item, i) => {
                return (
                  <TableRow
                  onClick={()=> props.viewItemDetails(item.Item_Id)}
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{item.Item_Id} </TableCell>
                    <TableCell align="left">{item.Item_Name} </TableCell>
                    <TableCell align="left">{item.Item_Sku} </TableCell>
                    <TableCell align="left">
                      {" "}
                      {item.Item_Category_Name}{" "}
                    </TableCell>
                    <TableCell align="center">{" "}{item.Total_Quantity}{" "} </TableCell>
                    <TableCell align="center">{" "}{item.Sales_Rate}{" "} </TableCell>
                    <TableCell align="center"> {item.Total_Amount} </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="row mx-1">
          <div  style={{backgroundColor:"#F1F1F1", color:"#1F59EE"}} className="col py-3 flex justify-between ">
            <span className="text-sm" slot="start ">Grand Total Amount</span>
            <div className="text-sm" slot="end">{grandTotal} </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompositesSellDetails;
