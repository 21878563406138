/* eslint-disable jsx-a11y/anchor-is-valid */

import LogicContext from "../../Context/LogicContext";
import { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { useContext } from "react";
import { SetCarrierBagStoreList, GetCarrierBagStoreList } from "../../store/carrierBagReturn";
import RestartAlt from '@mui/icons-material/RestartAlt';
import Tooltip from '@mui/material/Tooltip';
import { useLocation, useNavigate } from "react-router-dom";

import { rewardService } from '../../services/Reward.service';
import CarrierBagTable from "./CarrierBagTable";




function CarrierBagPage() {
  const logicContext = useContext(LogicContext);
  const getRecoilList = GetCarrierBagStoreList(); 
  const setRecoilList = SetCarrierBagStoreList();
 

  const [tableList, setTableList] = useState([]);

  const navigate = useNavigate();
  const mountedRef = useRef(true);

  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
 
console.log('New_list... ', getRecoilList);

  useEffect(()=> {
    window.title = "Reward History ";
  },[]);
  

  useEffect(()=> {
    
    if(getRecoilList.length){
      setTableList(getRecoilList);
       
    }
//   eslint-disable-next-line react-hooks/exhaustive-deps
  },[getRecoilList?.length]);



  
  function getList(){
    rewardService.getCarrierBagList().then( response => {
      console.info('LIST...', response?.data);
      if(response?.data?.length){
        setRecoilList(response?.data);
            setTableList(response?.data);
     
      }
  });

  }
 
const addNewClick = ()=> {
  navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
}




  return (
    <>
  
    <section>
      
      <div className="   w-full   lg:flex justify-start">
        <section className=" w-full">
          <section className="bg-white py-4 px-2    md:flex justify-between ">
            <div className="flex px-2 mt-1">
 
             
            <Tooltip title="Reload">
           <RestartAlt
             className="cursor ml-2 mt-1"
             onClick={() => getList()}
             color="primary"
             fontSize="large"
           />
         </Tooltip>

         <Tooltip title="Total ">
         <div className="card font-weight-bold text-primary p-1 pl-3 pr-3 ml-3  bg-light">
                 Total: {getRecoilList?.length}
         </div>
         </Tooltip>
         {/* <CoApproveFilter className="ml-2"  approve_status={getRecoilList.approval_type} onStatusFilterSelect={onStatusFilterSelect} /> */}
            </div>
          
        

            <div className="flex justify-between">
         
              <button className="  font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8 text-xs">
                IMPORT
              </button>
              <div className="btn-group">
               <button className=" font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8   text-xs" data-toggle="dropdown" aria-expanded="false">
                EXPORT
              </button>
          
            </div>

            <div className=" grid grid-cols-1 gap-1 content-center">
            <button 
            onClick={addNewClick}
                className="bg-buttonColor font-[Poppins] py-2 rounded-md  px-3  text-white text-xs">
                  ADD NEW
                </button>
            </div>
           

               
             
            </div>
          </section>

          <section className="">
            <CarrierBagTable tableList={getRecoilList}  />
            
          </section>
        </section>
      </div>
    </section>
    </>

  );
}

export default CarrierBagPage;
