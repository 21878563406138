
  import { Table } from "react-bootstrap";
  import { TableBody, TableCell, TableContainer, TableHead,TableRow} from "@mui/material";
import ListAltIcon from '@mui/icons-material/ListAlt';


   

function BaseCurrencyTable(props) {


return (

    <>


 <section>
            <TableContainer className="mt-3">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Details</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Debit</TableCell>
                    <TableCell>Credit</TableCell>
                  </TableRow>
                </TableHead>

               
                <TableBody>
                {props?.bcyList?.map((item, i)=> {
                          return (
                  <TableRow key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }} >
                                <TableCell align="left">{item.Post_Date} </TableCell>
                             <TableCell align="left">{item.Remark_Mst} </TableCell>
                            <TableCell align="left">{item.Voucher_Type_Name} </TableCell>
                            <TableCell align="left">{item.Debit_Amount} </TableCell>
                            <TableCell align="left">{item.Credit_Amount} </TableCell>
                  </TableRow>
                           )
                        })}
                </TableBody>
       
              </Table>
            </TableContainer>
          </section>
    
    </>
  );
}

export default BaseCurrencyTable;