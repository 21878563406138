/* eslint-disable jsx-a11y/anchor-is-valid */
import { itemsGroupService } from "../../../services/itemGroup.service";
import { useContext, useEffect, useState } from "react";
import underscore from "underscore";
import { useForm } from "react-hook-form";
import "../../../pages/ItemInformation.css";

import { SetMasterData, GetMasterData, GetGroupEdit  } from "../../../store/itemGroupAtom";
import { useNavigate } from "react-router-dom";
import TypeSelect from "./TypeSelect";
import {  } from "../../..";
import AlertConfirm from "../../../helpers/AlertConfirm";

function AddItemGroupForm(props) {
  const navigate = useNavigate();
  const setMasterData = SetMasterData();

  const masterDataList = GetMasterData();
  let editableGroup = GetGroupEdit()

  const [productUnitList, setProductUnitList] = useState([]);
  const [productCategory, setProductCategoryList] = useState([]);
  const [vendorsList, setVendorsList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [alertState, setAlertState] = useState(false);

    // form Properties
    const [brandselect, setBrandselect] = useState({});
    const [categorySelect, setCategorySelect] = useState({});
    const [unitSelect, setUnitSelect] = useState({});
    const [manufacturereSelect, setManufacturereSelect] = useState({});
    const [taxSelect, setTaxSelect] = useState({});




  const { register,setValue, handleSubmit,getValues,  reset, formState: { errors } } = useForm();

  useEffect(() => {
    if (editableGroup?.Item_Group_Name) {
  
  
      let tempUnit = {
        Name: editableGroup.Item_Group_Unit_Name,
        Code: editableGroup?.Item_Group_Unit_Id,
      };
      let tempManu = {
        Name: editableGroup.Item_Category_Name,
        Code: editableGroup.Item_Group_Manufacturer_Id,
      };
      let tempBrand = {
        Name: editableGroup.Item_Group_Brand_Name,
        Code: editableGroup.Item_Group_Brand_Id,
      };
      let tempTax = {
        Name: editableGroup.Item_Group_Tax_Name,
        Code: editableGroup.Item_Group_Tax_Id,
      };
      let tempCat = {
        Name: editableGroup.Item_Group_Category_Name,
        Code: editableGroup.Item_Group_Category_Id,
      };
   
      reset({ ...editableGroup });
      setUnitSelect(tempUnit);
      setManufacturereSelect(tempManu);
      setBrandselect(tempBrand);
      setTaxSelect(tempTax);
      setCategorySelect(tempCat);
  
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableGroup]);

  useEffect(() => {
    itemsGroupService.getMasterData().then((res) => {
      if(res.data.length > 0){
        var masterDataGroup = underscore.groupBy(res?.data, "Master_Type");
        setMasterData(res?.data);
      
        setProductUnitList(masterDataGroup[1]);
        setProductCategoryList(masterDataGroup[2]);
        setVendorsList(masterDataGroup[3]);
        setBrandList(masterDataGroup[4]);
        setTaxList(masterDataGroup[5]);

      }
  
  
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function selectGroupType(val) {
    setValue("Item_Group_Type_Id", val);
  }


  const onSubmit = async (data) => {
    setAlertState(true)
  };


  function closeAlert(response){
    setAlertState(false)
        if(response === true){
          props.submitForm( getValues());
        }
  }



  function onErrors(error) {
    console.error(errors);
  }


  return (
    <>
    {alertState  ? <AlertConfirm title={'Do you want to save it?'} 
     body={'Please check information before submitting.'} closeAlert={closeAlert}></AlertConfirm>  : null}
    

      <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <section className="">
          {/* TYPE SECTION */}
          <section className="flex mt-2 justify-between w-45 ">
            {/* here */}
           
            {/* val={itemToEdit?.Item_Type_Id} */}
            <TypeSelect
              addItemType={selectGroupType}
            ></TypeSelect>

            {/* returnable */}
            
            {/* val={itemToEdit?.Item_Returnable_Id} */}
            {/* <ItemReturnable
              addItemReturnable={addItemReturnable}
            /> */}
          </section>
          {/* ITEM INFORMATION SECTION  */}
          <section className="info_group flex mt-3 ">
            <section className="flex justify-between  w-full">
              <div>
                <span className="text-xs">Item Group Name</span> <br></br>
                <input
                  className="bg-filterInput bottom-0 outline-none h-[6.5vh] rounded border-transparent focus:border-transparent focus:ring-0"
                  type="text"
                  placeholder="Beans"
                  {...register("Item_Group_Name", {
                    required: "Item group name is required",
                  })}
                />
              </div>
              <small className="text-danger">
                {errors?.Item_Name && errors.Item_Name.message}
              </small>

              <div className="">
                <span className="text-xs">Description <i>(Optional)</i></span> <br></br>
                <input
                  {...register("Item_Group_Description")}
                  className="bg-filterInput bottom-0 h-[20vh] outline-none border-transparent focus:border-transparent focus:ring-0"
                  type="text"
                  placeholder="Write a Description"
                />
              </div>
            </section>
            {/* take of this space to control the space  */}
            {/* <section className=" mx-[3vh]">
              <section>
                <div className="">
                  <label className="">
                    <span className="text-xs">Unit</span> <br></br>
                    <div className="dropdown bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer border-0">
                      <a
                        style={{ textDecoration: "none" }}
                        className="text-filterTextColor h-[6vh] pl-[2.0vh] lg:w-[14vw] text-xs  dropdown-toggle"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {unitSelect.Name ? (
                          <span className="font-weight-bold">
                            {unitSelect.Name}{" "}
                          </span>
                        ) : (
                          "Select or type unit"
                        )}
                      </a>

                      <GeneralDropDown dropown={productUnitList} optionSelect={selectedUnitType} />

              
                    </div>
                  </label>
                </div>

                <div className="">
                  <label className="">
                    <span className="text-xs">Brand <i>(Optional)</i></span> <br></br>
                    <div className="dropdown bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer border-0">
                      <a
                        style={{ textDecoration: "none" }}
                        className="text-filterTextColor h-[6vh] pl-[2.0vh] lg:w-[14vw] text-xs  dropdown-toggle"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {brandselect.Name ? (
                          <span className="font-weight-bold">
                            {brandselect.Name}{" "}
                          </span>
                        ) : (
                          "Select or add Brand"
                        )}
                      </a>
                      <GeneralDropDown dropown={brandList} optionSelect={selectedBrand} />

                    </div>
                  </label>
                </div>
                <div className="">
                  <label className="">
                    <span className="text-xs">Category <i>(Optional)</i></span> <br></br>
                    <div className="dropdown bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer border-0">
                      <a
                        style={{ textDecoration: "none" }}
                        className="text-filterTextColor h-[6vh] pl-[2.0vh] lg:w-[14vw] text-xs  dropdown-toggle"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {categorySelect.Name ? (
                          <span className="font-weight-bold">
                            {categorySelect.Name}{" "}
                          </span>
                        ) : (
                          "Select category"
                        )}
                      </a>
                      <GeneralDropDown dropown={productCategory} optionSelect={selectedCategory} />

                    
              
                    </div>
                  </label>
                </div>
              </section>
            </section>
            <section className="">
              <section>
                <div className="">
                  <label className="">
                    <span className="text-xs">Manufacture <i>(Optional)</i> </span> <br></br>
                    <div className="dropdown bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer border-0">
                      <a
                        style={{ textDecoration: "none" }}
                        className="text-filterTextColor h-[6vh] pl-[2.0vh] lg:w-[14vw] text-xs  dropdown-toggle"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {manufacturereSelect.Name ? (
                          <span className="font-weight-bold">
                            {manufacturereSelect.Name}{" "}
                          </span>
                        ) : (
                          "Select or type manufacturer"
                        )}
                      </a>

                      <GeneralDropDown dropown={vendorsList} optionSelect={selectedManufacturer} />

                    </div>
                  </label>
                </div>
                <div className="">
                  <label className="">
                    <span className="text-xs">Tax <i>(Optional)</i></span> <br></br>
                    <div className="dropdown bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer  border-0">
                      <a
                        style={{ textDecoration: "none" }}
                        className="text-filterTextColor h-[6vh] pl-[2.0vh] lg:w-[14vw] text-xs  dropdown-toggle"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {taxSelect.Name ? (
                          <span className="font-weight-bold">
                            {taxSelect.Name}{" "}
                          </span>
                        ) : (
                          "Select tax"
                        )}
                      </a>
                      <GeneralDropDown dropown={taxList} optionSelect={selectTax} />

                   
                    </div>
                  </label>
                </div>
              </section>
            </section> */}
          </section>

          {/* <section className="flex my-5 justify-between   ">
            <button
              type="cancel"
              onClick={() => navigate(-1)}
              style={{ color: "#F17E7E" }}
              className="py-2 px-4 text-sm rounded "
            >
              Remove
            </button>

            <span
              style={{ color: "#476EF8" }}
              className="py-2 px-4  text-sm  "
            >
              ADD MORE ATTRIBUTES
            </span>
          </section> */}

          <section className="flex my-5 justify-between   ">
            <a
             
              onClick={() => navigate(-1)}
              style={{ color: "#F17E7E" ,  cursor: 'pointer'}}
              className="py-2 px-4 text-sm rounded "
            >
              Cancel
            </a>

            <button
              type="submit"
              style={{ backgroundColor: "#476EF8" }}
              className="py-2 px-4  text-sm  rounded text-white"
            >
              Save
            </button>
          </section>
        </section>
      </form>
    </>
  );
}

export default AddItemGroupForm;
