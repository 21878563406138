import React, { useRef, useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import moment from "moment/moment";
import message from "../../assets/message.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { invoiceService } from "../../services/invoice.service";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
// import InvoicePaymentTable from "./InvoicePaymentTable";
// import InvoiceDetailsTable from "./InvoiceDetailsTable";
import {
  SetCorporateEdit,
} from "../../store/corporateAtom";
import { customerService } from "../../services/customer.service";
import LogicContext from "../../Context/LogicContext";

import SampleFileViewerModal from "../customer_approval/SampleFileViewerModal";
import RatingScroll from "./RatingScroll";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RatingTypeSelect from "./RatingTypeSelect";
import RatingRangeSlider from "./RatingSlider";

// import { useReactToPrint } from 'react-to-print';



const RatingDetailsPage = () => {
  const setEdit = SetCorporateEdit();
  const [selectedList, setSelectedList] = useState([]);
  const [range, setRange] = useState([1,5]);
  const myPromiseModal = createModal(GeneralModal);
  // const getBulkActions = GetInvoiceBulkAction();

  const { id } = useParams();
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const [ratingList, setRatingList] = useState([]);
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];



  useEffect(() => {
       document.title = 'Rating details';
       setRatingList([]);
    getInvoiceDetails(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  
  const getInvoiceDetails = async (id) => {
    customerService.getRatingDetails(id, ...range).then((response) => {
      console.log("RAting.... ", response.data);
      if (response.data[0]) {
        setRatingList(response.data);
      }
    });
  };


  function viewItemDetails(id) {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
    getInvoiceDetails(id);
  }

  function viewPaymentRecord(id) {
    navigate(
      `/${currModuleId}/${currModuleName}/payment-received/details/${id}`
    );
    getInvoiceDetails(id);
  }


  function onSelection(list) {
    let tempList = list?.filter((item) => item.checked === true);
    setSelectedList(tempList);
  }



  

  async function sendRatingReply(rating) {
    await myPromiseModal({ title: `reply ${rating?.Customer_First_Name}'s rating`,
      body: ` ${rating.Rating_Remark}`,
      showInput: true,
      placeHolder: "enter your reply...",
    }).then((result) => {
      if(!result.message){
        return logicContext.showToast('Empty reply cannot be submitted!');
      }
      if (result.decision === true) {
        let bodyData = {};

        switch (id) {
          case '1':
            bodyData = {sql_ref_no:103,ref_no: rating.rating_ref_no,rating_remark: result.message,option:5};
            
            break;
          case '2':// Shopping
            bodyData = {sql_ref_no:104,salesorder_id: rating.rating_ref_no,rating_remark: result.message,option:3};
            
            break;
          case '3': // Shipping
            bodyData = {sql_ref_no:103,ref_no: rating.rating_ref_no,rating_remark: result.message,option:4};
            
            break;
          case '4': // Airtime
            bodyData = {sql_ref_no:104, ref_no: rating.rating_ref_no,rating_remark: result.message,option:3};

            
            break;
          case '5': //
          bodyData = {sql_ref_no:104, ref_no: rating.rating_ref_no,rating_remark: result.message,option:3};
           
            
            break;
          case '6':
          bodyData = {sql_ref_no:104, ref_no: rating.rating_ref_no,rating_remark: result.message,option:3};
            
            break;
        
          default:
            break;
        }

        customerService.submitReplyToRating(bodyData).then(res => {
          const data =  logicContext.httpResponseManager(res.data[0]);
          if(data?.message_id == 1){
            logicContext.showToast(data?.message_text, 'success');
          }
        });
       
     
      }
    });
  }


  const processReplyMessage = (formData)=> {
      customerService.submitReplyToRating(formData).then(res => {

      });

  }

  const getInvoiceData = async () => {
    try {
      const response = await invoiceService.getInvoiceData();

      // if (response?.data !== undefined) {
      //   setInvoiceList(response.data["Invoice Info"]);
      //   setInvoiceSummary(response.data["Amount Summary"][0]);
      //   setBulkFilter(response.data["Filtering Criteria"]);
      //   setInvoiceTableHeader(response.data["Display Column"]);
      //   setBulkActions(response.data["Bulk Action"]);
      // }
    } catch (error) {
      logicContext.showToast("connection error getting invoice data", "error");
    }
  };

  const calculateSubTotal = (list) => {
    let result = 0;
    list?.map((item) => {
      result += item.Plan_Sales_Rate * item?.Plan_Sales_Quantity;
    });

    return result;
  };

  const calculateGrandTotal = (list) => {
    let grandTotal = 0;
    // list?.map((item) => {
    //   grandTotal += item.Plan_Sales_Rate * item?.Plan_Sales_Quantity;
    // });

    // grandTotal = grandTotal - corporateData?.Discount_Amount;
    // grandTotal = grandTotal + corporateData?.Shipping_Amount;
    // grandTotal = grandTotal + corporateData?.Adjustment_Amount;
    return grandTotal;
  };

  const calculatePayment = (list) => {
    let grandTotal = 0;
    list?.map((item) => {
      grandTotal += item.Payment_Amount;
    });

    return grandTotal;
  };



  const manageStatus = (statusCode, statusName) =>{
    let defaultClass = 'bg-primary text-white';

    switch (statusCode) {
      case 1:
      defaultClass = 'color_1';
        break;
      case  2:
      defaultClass = 'color_2';
        break;
      case 3:
      defaultClass = 'color_3';
        break;
      case 4:
      defaultClass = 'color_4';
        break;
      case 5:
      defaultClass = 'color_5';
        break;
    
      case 6:
      defaultClass = 'color_6';
        break;
    
      default:
        break;
    }

        return (
          <span className={` py-2 px-4 h5 ${defaultClass} rounded`}>
          {statusName}
        </span>
        )

    
      
  }

 

  const handlerRatingChange = ( newValue) => {
    setRange(newValue);
  };




  function onRatingFilterSelect(selection){
  }




  return (
    <>
      <section className="  mt-2 ">
        <Grid container spacing={2}>
        <Grid item xs={12}>
            {/* <section className="mt-4 max-auto max-w-6xl d-flex flex-row-reverse ">
              <div className="flex flex-wrap justify-between md:">
                <div>
             
                  <div className="flex ">
                  
                    <button
                      style={{ borderRadius: "5px" }}
                      className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                    >
                      <img src={paper} alt="" className="" />
                    </button>
                    <button
                      style={{ borderRadius: "5px" }}
                      className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                    >
                      <img src={pdf} alt="" className="" />
                    </button>
                    <button
                      style={{ borderRadius: "5px" }}
                      className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                    >
                      <img src={printer} alt="" className="" />
                    </button>

                    <button
                      style={{ borderRadius: "5px" }}
                      onClick={editCurrentData}
                      className="px-3 mr-2 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 "
                    >
                      <img src={pencil} alt="" />
                    </button>
                    <button
                      style={{ borderRadius: "5px" }}
                      className="  px-3 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 ml-2"
                    >
                      <img src={trash} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </section> */}
          </Grid>
          

          <Grid item xs={12}>
            <section className="flex relative justify-between ">
              <RatingScroll
                viewItemDetails={viewItemDetails}
              ></RatingScroll>

              <section className="w-full z-50  ">
                <section className=" ml-[2vw] mt-2 max-auto max-w-6xl text-filterTextColor">
                  {/* Package page  */}

                  <Grid className="mt-2 border-2 p-2" container spacing={2}>
                    <Grid item xs={8} className=" my-2">
                      <div>
                        {/* <span className="#476ef8 font-bold text-primary">{corporateData?.Corporate_Name} </span> */}
                      </div>
                      <div>
                        <div>
                          <span className="text-sm">
                            {/* Corporate ID : {corporateData?.Corporate_Short_Code} */}
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4} className=" my-2">
                    
                        <div>
                          {/* Status */}
                          <span className="text-sm">
                             {/* {manageStatus(corporateData?.Corporate_Approval_Status_Id, corporateData?.Corporate_Approval_Status_Name)} */}
                          </span>
                        </div>
                   
                    </Grid>
                    <div className="flex justify-end  mb-3 mt-3 w-full">
                    <div class="d-flex justify-content-start">
                  
                        <RatingRangeSlider  handlerRatingChange={handlerRatingChange} />

                          <button onClick={()=> getInvoiceDetails(id)}
                          className=" ml-5  btn btn-primary" >apply</button>
                    </div>
                    <div className="border-2 font-weight-bold ml-4 pt-2 p-2" >
                      Total: {ratingList.length}
                    </div>
                    </div>

                    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="bg-light">
          <TableRow>
            <TableCell className="font-weight-bold" >Customer Name</TableCell>
            <TableCell align="right font-weight-bold">Mobile</TableCell>
            <TableCell align="right font-weight-bold">Email</TableCell>
            <TableCell align="right font-weight-bold">Rating</TableCell>
            <TableCell align="right font-weight-bold">Message</TableCell>
            <TableCell align="right font-weight-bold">Date</TableCell>
            <TableCell align="right font-weight-bold">Reply</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ratingList.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.Customer_Full_Name}
                
              </TableCell>
              <TableCell align="left">{row.Customer_Mobile_No}</TableCell>
              <TableCell align="left">{row.Customer_Email_Address}</TableCell>
              <TableCell align="center">{row.Rating_Value}</TableCell>
              <TableCell align="center">{row.Rating_Remark}</TableCell>
              <TableCell align="left"> {moment(row.Rating_Date).format("llll")} </TableCell>
              <TableCell align="left"> 
              <button className="btn btn-sm btn-primary"  onClick={()=> sendRatingReply(row)} >Reply</button>
               </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
                    {/* <Grid item xs={12} className="border-2 my-2">
                      <div>
                        <div>
                          <span className="text-sm">
                            Activation Date : {moment(corporateData?.Agreement_Date).format("DD-MM-Y")}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm"> No of Employee : {corporateData?.Number_Of_Officer_Name} </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Created Time : {moment(corporateData?.Agreement_Date).format("hh:mm:ss a")}
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} className="border-2 my-2">
                      <div>
                        <div>
                          <span className="text-sm">
                            Email Address : {corporateData?.Corporate_Email_Address}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Contact Person : {corporateData?.Corporate_Contact_Person}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Contact Number : {corporateData?.Corporate_Contact_Person_Mobile}
                          </span>
                        </div>

                        <div>
                          <span className="text-sm">Address : {corporateData?.Corporate_Adddress} </span>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} className="border-2 my-2">
                      <div>
                        <div>
                          <span className="text-sm">
                            Number of Employee : {corporateData?.Number_Of_Officer_Name}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Customer(s) : {corporateData?.Total_Client}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">Type : {corporateData?.Corporate_Type_Name} </span>
                        </div>
                        <div>
                          <span className="text-sm">Remark :  </span>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} className="border-2 my-2">
                      <div>
                        <div>
                          <span className="text-sm">
                            Maximum discount  : {corporateData?.maximum_discount_percentage}%
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Subscription fee(s) : {corporateData?.subscription_fees}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">
                            Subscription activation date : { moment(corporateData?.corporateData?.subscription_activation_date).format("DD-MM-Y")}
                          </span>
                        </div>
                        <div>
                          <span className="text-sm">Trial : {corporateData?.trail_period} {corporateData?.trail_period_type_name} </span>
                        </div>
                        <div>
                          <span className="text-sm">Remark :  </span>
                        </div>
                      </div>
                    </Grid> */}
                  </Grid>
                </section>
              </section>
            </section>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default RatingDetailsPage;
