import React, { useEffect, useContext, useRef } from "react";
import { accountService } from "../../services/accountService";
import LogicContext from "../../Context/LogicContext";
import {
  SetBulkActions,
  SetTableDataHeadList,
  SetTableDataList,
  GetTableDataList,
  SetTableFilter,
  GetTableFilter,
  SetDepositTo,
  SetPaymentMode,
  SetOfficerList,
  SetCustomerList,
} from "../../store/paymentReceivedAtom";
// import CurrencyFormat from 'react-currency-format';
import Tooltip from "@mui/material/Tooltip";
import RestartAlt from "@mui/icons-material/RestartAlt";
import { useState } from "react";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentBulkAction from "./PaymentBulkAction";
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import PaymentReceivedTable from "./PaymentReceivedTable";
import { IoMdRefresh } from "react-icons/io";

function PaymentReceivedPage() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const logicContext = useContext(LogicContext);
  const setPaymentList = SetTableDataList();
  const getPaymentList = GetTableDataList();
  const myPromiseModal = createModal(GeneralModal);
  const setBulkActions = SetBulkActions();
  const setBulkFilter = SetTableFilter();
  const setOfficerList = SetOfficerList();
  const setCustomerList = SetCustomerList();
  const getTableFilter = GetTableFilter();
  const setDepositTo = SetDepositTo();
  const setPaymentMode = SetPaymentMode();
  const setTableHeaderList = SetTableDataHeadList();
  const [tableData, setTableData] = useState([...getPaymentList]);
  const [itemSelected, setItemSelected] = useState([]);
  const paymentModeIdRef = useRef(null);
  const depositToRef = useRef(null);

  useEffect(() => {
    let isMounted = true;
    if (getPaymentList.length < 1 && isMounted) {
      accountService.getPaymentReceiveList().then((response) => {
        if (response?.data["customer"] !== undefined) {
          setPaymentList(response.data["Payment Info"]);
          setTableData(response.data["Payment Info"]);
          setBulkFilter(response.data["Filtering Criteria"]);
          setBulkActions(response.data["Bulk Action"]);
          setTableHeaderList(response.data["Display Column"]);
          setDepositTo(response.data["Deposit To"]);
          setPaymentMode(response.data["Payment Mode"]);
          setOfficerList(response.data["officer"]);
          setCustomerList(response.data["customer"]);
        }
      });
    }

    return () => {
      isMounted = false;
      paymentModeIdRef.current = null;
      depositToRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPaymentList.length]);

  const getPaymentListApi = async () => {
    try {
      const response = await accountService.getPaymentReceiveList();

      if (response?.data !== undefined) {
        setPaymentList(response.data["Payment Info"]);
        setTableData(response.data["Payment Info"]);
        setBulkFilter(response.data["Filtering Criteria"]);
        setBulkActions(response.data["Bulk Action"]);
        setTableHeaderList(response.data["Display Column"]);
        setDepositTo(response.data["Deposit To"]);
        setPaymentMode(response.data["Payment Mode"]);
        setOfficerList(response.data["officer"]);
        setCustomerList(response.data["customer"]);
      }
    } catch (error) {
      logicContext.showToast("connection error getting invoice data", "error");
    }
  };

  const onSelectBulkAction = (action) => {
    const option = action.target.value;
    if (option === "") {
      return setTableData(getPaymentList);
    }
    var newList = getPaymentList.filter(
      (item) => item.Payment_Status_Id === option
    );
    setTableData(newList);
  };

  function selectedTableItems(itemList) {
    setItemSelected(itemList);
  }

  async function performBulkActions() {
    let temp = "";
    let option = 0;
    if (paymentModeIdRef !== null) {
      option = 5;
    }
    if (depositToRef !== null) {
      option = 6;
    }

    itemSelected.map((item) => {
      temp += `<a> <refno> ${item.Payment_Ref_No} </refno> </a>`;
    });

    let xml_string_refno = `<root> ${temp} </root>`;
    let body = { xml_string_refno, option };
    if (paymentModeIdRef.current !== null) {
      body["payment_mode_id"] = paymentModeIdRef.current;
    }
    if (depositToRef.current !== null) {
      body["deposit_id"] = depositToRef.current;
    }
    accountService
      .savePaymentReceivedBulkAction({ ...body, option: option })
      .then((res) => {
        if (res?.data !== undefined) {
          const { Message_Text } = res.data[0];
          logicContext.showToast(Message_Text, "success");
          return getPaymentListApi();
        }
      })
      .catch((err) => {
        logicContext.showToast("Error", "error");
      });
  }

  function addNewInvoice() {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/add-payment-received`
    );
  }

  const onPaymentModeSelect = (val) => {
    paymentModeIdRef.current = val;
    depositToRef.current = null;
  };

  const onDepositSelect = (val) => {
    paymentModeIdRef.current = null;
    depositToRef.current = val;
  };

  return (
    <>
      <section className="md:flex items-center justify-between space-y-4 md:space-y-0">
        <div className="flex items-center space-x-2">
          {itemSelected.length > 0 ? (
            <PaymentBulkAction
              onPaymentModeSelect={onPaymentModeSelect}
              onDepositSelect={onDepositSelect}
              performBulkActions={performBulkActions}
            />
          ) : (
            <ActionPaneComponent
              bulkActionData={getTableFilter}
              onSelectBulkAction={onSelectBulkAction}
            />
          )}

          <Tooltip title="Reload">
            <div
              onClick={() => getPaymentListApi()}
              className="h-fit w-fit p-2 rounded-full cursor-pointer hover:bg-gray-200 active:bg-gray-300 "
            >
              <IoMdRefresh className="h-[20px] w-[20px] text-gray-600 " />
            </div>
          </Tooltip>
        </div>

        <div className="flex items-center space-x-4">
          <button className="font-[Poppins] font-semibold py-2 px-3 rounded text-sm">
            IMPORT
          </button>
          <div className="btn-group">
            <button
              className=" font-[Poppins] font-semibold py-2 px-3 rounded text-sm"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              EXPORT
            </button>
          </div>
          <button
            onClick={addNewInvoice}
            className="bg-buttonColor font-[Poppins] font-semibold py-2 px-3 rounded text-white text-sm"
          >
            ADD NEW
          </button>
        </div>
      </section>
      <section className="mt-5">
        <PaymentReceivedTable
          tableData={tableData}
          selectedTableItems={selectedTableItems}
        />
      </section>
    </>
  );
}

export default PaymentReceivedPage;
