import { GetWalletVariantListAtom, SetWalletVariantListAtom } from "../../store/variantAtom";


function WalletTypeSelect(props) {
    const  getWalletAtom = GetWalletVariantListAtom();

    return (
        <>

            <div className="">
                <span className="text-sm  ">Wallet Type</span>
                <div className="">
                    {/* <input
                        className="bg-filterInput h-[5.6vh] w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type=""
                    /> */}
                    <select onChange={(ev)=> props.selectedType(ev)} >
                    <optgroup>
                    {getWalletAtom?.wallet_type.map((type, i)=> {
                        return (
                        <option value={type.Wallet_Type_Id}>{type.Wallet_Type_Name}</option>
                        )
                    })}
                    
                    </optgroup>
          </select>
                </div>
            </div>
        </>
    );
}

export default WalletTypeSelect;