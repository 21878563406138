
import Select from 'react-select';




function ChartOfAccountParentName(props) {


    const customStyles = {
        control: base => ({
          ...base,
          height: 54,
          backgroundColor:'#F1F1F1',
          width:480,
        }),
        indicatorSeparator: styles => ({ ...styles, display : 'none' }),
        placeholder: styles => ({ ...styles, display : 'none' }),
        valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
        option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
      }
  

    const handleChange = (value) => {
      props.onParentNameSelect(value)
      };


    return ( 
        <>
   
         <Select defaultValue={props?.defaultVal}
           styles={customStyles} onChange={handleChange} options={props?.subList} />


        </>
     );
}

export default ChartOfAccountParentName;
