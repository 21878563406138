import { BehaviorSubject } from 'rxjs';
import jwt_decode from "jwt-decode";
import { spinnerService } from './spinner.service';

const currentUserTokenSubject = new BehaviorSubject({});
const BASE_URL = process.env.REACT_APP_API_BASEURL_GET;



export const authenticationService = {

    login,
    Logout,
    currentUserObject,
    isLoggedIn,
    getToken,
    currentUserSubject: currentUserTokenSubject.asObservable(),
    get currentUserTokenValue () { return  localStorage.getItem('token') }
};

function Logout() {
    // remove user from local storage to log user out
    
    localStorage.clear();
    currentUserTokenSubject.next(null);
   
}


    function isLoggedIn() {
        const TOKEN =  getToken();
        if(TOKEN){
            const payLoad = jwt_decode(TOKEN);
            if (payLoad.exp){
                if(payLoad.exp > Date.now() / 1000) {
                    return true;
                } else{
                    return false;
                } 
            }else{
                return false;
                
            }
        }
        return false
        }

function currentUserObject(){
    const TOKEN =  getToken();
    if(TOKEN){
        const payLoad = jwt_decode(TOKEN);
        const User_Id = payLoad['User_Id'];
        const Authentication_Token = payLoad['Authentication_Token'];
        const email = payLoad['email'];
        const  userprops = {User_Id, Authentication_Token, email}
        currentUserTokenSubject.next(userprops);
        return userprops;

    }else{
        console.info('No token')
        return {}
    }
  
}


function getToken(){
    return localStorage.getItem('token');
}

async function  login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    spinnerService.showSpinner()
    return fetch(`${BASE_URL}/login`, requestOptions).then(response =>  {
            spinnerService.disableSpinner();
            return response;
        }).catch((err)=> {
            spinnerService.disableSpinner();
            return err;
        })



}


