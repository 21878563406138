

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


const purchaseOrderMasterData = atom({key:'purchaseOrderMasterData', default:{}});
const purchaseOrderEntryData = atom({key:'purchaseOrderEntryData', default:{}});
const purchaseOrderEditEntryData = atom({key:'purchaseOrderEditEntryData', default:{}});



export  const SetPurchaseOrderMasterData = () => useSetRecoilState(purchaseOrderMasterData);
export  const GetPurchaseOrderMasterData = () => useRecoilValue(purchaseOrderMasterData);

export  const SetEntryData = () => useSetRecoilState(purchaseOrderEntryData);
export  const GetEntryData = () => useRecoilValue(purchaseOrderEntryData);

export  const SetPurChaseOrderEdit = () => useSetRecoilState(purchaseOrderEditEntryData);
export  const GetPurChaseOrderEdit = () => useRecoilValue(purchaseOrderEditEntryData);
export const ResetPurChaseOrderEdit = ()=> useResetRecoilState(purchaseOrderEditEntryData);






