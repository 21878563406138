
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useForm } from "react-hook-form";
import { rewardService } from '../../services/Reward.service';
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { GetRewardCenterList, SetRewardCenterList } from '../../store/rewardCenterAtom';
import { useEffect } from 'react';
import { Tooltip } from '@mui/material';




function RedeemContent(props) {
  const myPromiseModal = createModal(GeneralModal);
  const {register, setValue, getValues, handleSubmit,control,reset,formState: { errors }} = useForm();
  const getRewardList = GetRewardCenterList();
  const setRewardList = SetRewardCenterList();


   useEffect(()=> {
    if( getRewardList.redeem_info?.length){
        setValue('redeem_quantity', getRewardList.redeem_info[0]?.Redeem_Quantity);
        setValue('redeem_amount', getRewardList.redeem_info[0]?.Redeem_Amount);
        setValue('redeem_size', getRewardList.redeem_info[0]?.Redeem_Size);
    }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[getRewardList.redeem_info?.length]);


  const onSubmit = async()=> {
    const formData = getValues();
    await myPromiseModal({title: `Confirmation!`, body: `The following record will be saved! `
    ,showInput: false, placeHolder: ''}).then((res) => {
      if(res?.decision === true){
        props.submitReward(formData);

      }
  
    
  });

  }

  const onErrors = ()=> {
    
  }

    return (
        <>
        <form onSubmit={handleSubmit(onSubmit, onErrors)} >
        <Grid container spacing={1}
        justifyContent="center"
        alignItems="flex-start"
        >
                <Grid textAlign="left" item xs={3}>
                   
                    <div className=" mt-3">
                        No of Cowries 
                       <Tooltip title="Number of redeemable cowries">
                        <span className='show-cursor  font-weight-bold bg-secondary ml-2 text-white p-1 rounded-circle pr-2' > i </span></Tooltip> 
                    </div>
                   
                </Grid>
                <Grid item textAlign="left" xs={9}>
                    
                    <div className="">
                 
                  <div className="">
                  {/* w-full */}
                    <input
                      className="bg-filterInput h-[8vh]  pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="number"
                      placeholder='Enter number of cowries.'
                      {...register("redeem_quantity", {
                        required: "Redeem quantity is required",
                        pattern: {
                          value: /^([1-9]\d*|0)$/,
                          message: 'Please enter a valid size!',
                        },
                      })}
                    />
                    <br/>
                      <small className="text-red-500">
                            {Boolean(errors["redeem_quantity"]?.message) &&
                                String(errors["redeem_quantity"]?.message)}
                        </small>
                  </div>
                </div>
                   
                </Grid>
                
                <Grid item textAlign="left" xs={3}>
                   
                    <div className=" mt-3">
                         Equal(NGN)
                         <Tooltip title="Equal value of allocated cowries in Naira">
                        <span className='show-cursor rounded-circle pr-2 font-weight-bold bg-secondary ml-2 text-white p-1' > i </span></Tooltip> 
                    </div>
                   
                </Grid>
                <Grid item textAlign="left" xs={9}>
                  
                  <div className="">
                  {/* w-full */}
                  <input
                    className="bg-filterInput h-[8vh]  pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    type="number"
                    placeholder='Equal value in naira.'
                    {...register("redeem_amount", {
                      required: "Amount is required",
                      pattern: {
                        value: /^([1-9]\d*|0)$/,
                        message: 'Please enter a valid size!',
                      },
                    })}
                    />
                    <br/>
                    <small className="text-red-500">
                            {Boolean(errors["redeem_amount"]?.message) &&
                                String(errors["redeem_amount"]?.message)}
                        </small>
                    
                  </div>
               
                   
                </Grid>

                <Grid item textAlign="left" xs={3}>
                    
                    <div className=" mt-3"> 
                         Cowries Redeem Quantity
                         <Tooltip title="The total quantity of cowries that can be redeemed per transaction.">
                        <span className='show-cursor rounded-circle pr-2 font-weight-bold bg-secondary ml-2 text-white p-1' > i </span></Tooltip> 
                    </div>
                   
                </Grid>
                <Grid item textAlign="left" xs={9}>
                    
                    <div className="">
                 
                  <div className="">
                  {/* w-full */}
                  <input
                    className="bg-filterInput h-[8vh]  pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    type="number"
                    placeholder='Equal value in naira.'
                    {...register("redeem_size", {
                      required: "Redeem size  is required",
                      pattern: {
                        value: /^([1-9]\d*|0)$/,
                        message: 'Please enter a valid size!',
                      },
                     
                    })}
                    />
                    <br/>
                        <small className="text-red-500">
                            {Boolean(errors["redeem_size"]?.message) &&
                                String(errors["redeem_size"]?.message)}
                        </small>
                </div>
                </div>
                  
                </Grid>
                <Grid item xs={4}>
              
              </Grid>

                <Grid item xs={4}>
                <div className='text-center m-4'>
                  <button className='btn btn-primary' >SUBMIT</button>
                </div>
          
             
                </Grid>
                <Grid item xs={4}>
              
                </Grid>
        </Grid>
  
        </form>
   
        </>







    );
}

export default RedeemContent;


