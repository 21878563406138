import { atom , useRecoilValue, useSetRecoilState} from "recoil";

const productionAtom = atom({key:'productionAtom', default:[]});
const wareHouseAtom = atom({key:'wareHouseAtom', default:[]});
const productionMasterDataAtom = atom({key:'productionMasterDataAtom', default:[]});

const historyTableAtom = atom({key:'historyTableAtom', default:[]});


export  const SetProductionItems = () => useSetRecoilState(productionAtom);
export  const GetProductionItems = () => useRecoilValue(productionAtom);

export  const SetWareHouse = () => useSetRecoilState(wareHouseAtom);
export  const GetWareHouse = () => useRecoilValue(wareHouseAtom);

export  const SetProdMasterData = () => useSetRecoilState(productionMasterDataAtom);
export  const GetProdMasterData = () => useRecoilValue(productionMasterDataAtom);


export  const SetTableHistory = () => useSetRecoilState(historyTableAtom);
export  const GetTableHistory = () => useRecoilValue(historyTableAtom);