/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLocation, useNavigate } from "react-router-dom";
import { SetCreditNoteListAtom, GetCreditNoteListAtom } from "../../store/CreditNoteStore";

import { useCallback, useEffect, useState } from "react";

import RestartAlt from '@mui/icons-material/RestartAlt';
import Tooltip from "@mui/material/Tooltip";
import Grid from '@mui/material/Grid';
import { creditNoteService } from "../../services/creditNoteService";
import { Table, Input } from 'antd';
import CreditNoteFilter from "./CreditNoteFilter";

const { Search } = Input;




function CreditNoteMainPage() {
  const { pathname } = useLocation();
  const setCreditNoteListAtom = SetCreditNoteListAtom();
  const getCreditNoteListAtom = GetCreditNoteListAtom();
  const navigate = useNavigate();
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');


  useEffect(() => {
    document.title = 'Credit note page';
    if (!getCreditNoteListAtom.credit_note?.length) {
      loadTableList();
    } else {
      setFilteredData(getCreditNoteListAtom.credit_note);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCreditNoteListAtom.credit_note]);


  const reloadData = () => {
    loadTableList();
  }


  const loadTableList = () => {
    creditNoteService.creditNoteList().then(res => {
      if (res.data) {
        setCreditNoteListAtom(res.data);
        setFilteredData(res.data?.credit_note);
      }
    });
  }


  const onFilterSelect = (selection) => {
    const newList = getCreditNoteListAtom?.credit_note?.filter((item) => item?.Status_Id == selection);
    if (newList?.length) {
      setFilteredData(newList);
    } else {
      setFilteredData([]);
    }
  };

  function addNew() {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`)
  }

  const columns = [
    {
      title: 'Ref_No',
      key: 'Ref_No',
      dataIndex: 'Ref_No',
      sorter: (a, b) => a.Ref_No.localeCompare(b.Ref_No)
      //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },
    {
      title: 'Number',
      key: 'Credit_Note_Number',
      dataIndex: 'Credit_Note_Number',
      className: "text-primary",
      sorter: (a, b) => a.Credit_Note_Number.localeCompare(b.Credit_Note_Number)
      //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },
    {
      title: 'Name',
      dataIndex: 'Customer_Full_Name',
      sorter: (a, b) => a.Customer_Full_Name.localeCompare(b.Customer_Full_Name)
      //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },
   
    {
      title: 'Customer Email',
      dataIndex: 'Customer_Email_Address',
      sorter: (a, b) => a.Customer_Mobile_No.localeCompare(b.Customer_Email_Address)
      //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },

    {
      title: "Customer Mobile",
      dataIndex: "Customer_Mobile_No",
      sorter: (a, b) => a.Customer_Mobile_No.localeCompare(b.Customer_Mobile_No)
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      sorter: (a, b) => a.Amount.localeCompare(b.Amount)
      //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },

    {
      title: "Status",
      dataIndex: "Status_Name",
      sorter: (a, b) => a.Status_Name.localeCompare(b.Status_Name)
    },
    {
      title: "Date",
      dataIndex: "Transaction_Date",
      sorter: (a, b) => a.Transaction_Date.localeCompare(b.Transaction_Date)
    }

  ];


  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = setCreditNoteListAtom.filter((record) =>
      Object.values(record).some((text) =>
        String(text).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };


  const paginationOptions = {
    pageSize: 10,
    showSizeChanger: true,
    total: filteredData.length,
    // onChange: (page, pageSize) => {
    //   console.log(page, pageSize);
    //   const newList = tableDataList.slice(0, pageSize);
    //   console.log('check ', newList);
    //   setTableList(newList);
    // },
  };



  const handleRowClick = (record) => {
    console.log('Row clicked:', record);
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${record?.Ref_No}`);
  };




  return (

    <>
      <Grid container >
        <Grid item xs={12}>
          {/* grid start */}
          <div className="row   mt-5 mb-2" >
            <div className="col" >
              <Tooltip title="Reload">
                <RestartAlt
                  className="cursor ml-2 mt-2 mr-2"
                  onClick={() => reloadData()}
                  color="primary"
                  fontSize="large"
                />
              </Tooltip>
            </div>



            <div className="col offset-3" >

              <div className="flex  justify-end">
                {/* <button
                  style={{ backgroundColor: "#476EF8" }}
                  onClick={() => addNew()}
                  className=" mr-3  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
                >
                  EXPORT
                </button> */}
                <button
                  style={{ backgroundColor: "#476EF8" }}
                  onClick={() => addNew()}
                  className="  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
                >
                  ADD NEW
                </button>

              </div>
            </div>

          </div>
          {/* End of Grid */}


        </Grid>
      </Grid>

      <div class="flex">
        <div class="w-1/2  p-1">
        <CreditNoteFilter bulkActionData={getCreditNoteListAtom?.status_info}
            onFilterSelect={onFilterSelect}
             />
        </div>
        <div class="w-1/2  p-1">
        <Search
            placeholder="Search..."
            onSearch={handleSearch}
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{ marginBottom: 10, width: 250 }}
          />
         
        </div>
      </div>


      <section className="mt-5   w-full">
        <div className="">


          <Table key={filteredData?.length} columns={columns} dataSource={filteredData}
            pagination={paginationOptions}
            rowClassName="show-cursor"
            style={{ background: '#f0f2f5', borderRadius: '8px' }}
            onRow={(record) => {
              return {
                onClick: () => {
                  handleRowClick(record);
                },
              };
            }}
          />

        </div>
      </section>
    </>

  );
}

export default CreditNoteMainPage;
