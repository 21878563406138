// import CustomerContext from "../Context/CustomerContext";
import { useEffect, useState } from "react";
import { useContext } from "react";
import {
  GetRecoilCorporateList,
  SetRecoilCorporateList,
} from "../../store/corporateAtom";
import { Grid } from "@mui/material";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import { useParams } from "react-router-dom";
import { GetRecoilNotify} from "../../store/notificationMainAtom";
import { useLocation, useNavigate } from "react-router-dom";



function NotificationScrol(props) {
  const getNotifyList = GetRecoilNotify();
  const navigate = useNavigate();
  const { pathname } = useLocation();  
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const {  id } = useParams();




  const selectedItem = (id)=> {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`);
  }



  return (
    <>
      <section className="w-30  z-0 mt-3 main2 	 border-2 b">
        <div
      
        >
          {getNotifyList.notification?.map((item, i)=> {
            return (
              <Grid 
              className={"flex hover:bg-filterColor border  "
                + (Number(id) === item?.Ref_No ? "app_bg_color1 mb-2 cursor" : "mb-2 cursor")}
               onClick={()=> selectedItem(item?.Ref_No)}  key={i} container spacing={1} >
           
              <Grid item xs={12}>
                <label className="ml-2 m-2 ">
                  <span className="subheading font-bold md:text-sm">
                    {moment(item.Notification_Date).format(" DD-MM-YYYY")}
                  </span>{" "}
                  <br></br>
                
                </label>
              </Grid>
              <Grid item xs={7}>
                <div>
                  {" "}
                  <label className="ml-2 m-2 text-sm text-primary">{item?.Notification_Type_Name} </label>
                </div>
              </Grid>
              <Grid item xs={5}>
              <span className="subheading font-bold md:text-sm">
                    <i> Client : {item?.No_Of_Client} </i>
                  </span>
              </Grid>
            </Grid>

            )
          })}
      
        
        </div>
      </section>
    </>
  );
}

export default NotificationScrol;
