import { useEffect, useState } from "react";
import Select from 'react-select';
import { GetInvoiceEntryMasterData} from '../../store/invoiceAtom';



function InvoiceSalesPersonSelect(props) {
  const masterData = GetInvoiceEntryMasterData();

  const customOption = masterData['Sales_Officer']?.map((item)=> {
    return ({...item, label:`${item.User_Full_Name}`})
  })

  
  const customStyles = {
    control: base => ({
      ...base,
      height: 53,
      backgroundColor: '#F1F1F1',
      // width: 520,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
  }

    const handleChange = (value) => {
      props.onSalesPersonSelect(value)
      };


    return ( 
        <>
   
         <Select  placeholder={"Select sales person...."}   styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal}
          options={customOption}
         />


        </>
     );
}

export default InvoiceSalesPersonSelect;
