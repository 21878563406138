import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@mui/material/Badge";
import { GetNotificationList } from "../store/NotificationAtom";
import { useEffect, Fragment, useState } from "react";
import { settingsService } from "../services/settingsService";
import { SetNotificationList } from "../store/NotificationAtom";
import { useLocation, useNavigate } from "react-router-dom";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const NotificationPane = () => {
  const getNotification = GetNotificationList();
  const [open, setOpen] = useState(false);
  const setNotifications = SetNotificationList();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const notificationClick = (noti) => {
    setOpen(false);
    navigate(
      `/${noti?.Module_Id}/` +
        noti?.Module_Name +
        "/" +
        noti?.Function_Name.replace(/_/g, "-") +
        "?new_list=true"
    );
  };

  const openNotification = () => {
    // setOpen(prev => !prev);
    // if(currModuleId  && !open){
    settingsService.getNotificationByModule(currModuleId).then((res) => {
      if (res?.data?.length) {
        setNotifications(res?.data);
      }
    });
    // }
  };


  return (
    <>
      <div class="dropdown">
        <button
          className="p-2 outline-none focus:outline-none hover:bg-gray-200 active:bg-gray-100 rounded-full"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <Badge
            onClick={() => openNotification()}
            badgeContent={getNotification.length}
            color="primary"
          >
            <NotificationsIcon color="action" />
          </Badge>
        </button>
        <div
          class="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
          // className="absolute h-fit w-fit py-2 bg-white border-[1px] border-gray-200 rounded-xl shadow-xl"
        >
          <div className="">
            {getNotification.map((noti, i) => {
              return (
                <div
                  className="dropdown-item cursor  border-1"
                  onClick={() => notificationClick(noti)}
                >
                  <span className="font-bold text-danger">
                    {" "}
                    <Badge
                      variant="outlined"
                      badgeContent={noti?.Notification_Count}
                      color="primary"
                    ></Badge>
                  </span>{" "}
                  <span className="ml-3">{noti?.Notification_Title} </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationPane;
