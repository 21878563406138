
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const packageService = {
    getPackageList, packageMasterData, subOrUpdatePackage, packageDetails,
    deletePackage, deliveredPackage
  
    
};





async function  subOrUpdatePackage(form) {
    return  axios.post(`/inventory/new-or-update-package`,form, authHeader());
}


async function  getPackageList() {
    return  axios.get(`/inventory/packages`, authHeader());
}


async function  deliveredPackage(form) {
    return  axios.put(`/inventory/delivered-shipping`, form, authHeader());
}


async function  packageMasterData() {
    return  axios.get(`/inventory/packages-master-data`, authHeader());
}


async function  packageDetails(id) {
    return  axios.get(`/inventory/package-details/${id}`, authHeader());
}


async function  deletePackage(id) {
    return  axios.delete(`/inventory/delete-package/${id}`, authHeader());
}


// async function  submitCreditNote(form) {
//     console.log('sendt ', form);
//     return  axios.post(`/inventory/submit-credit-note`, form, authHeader());
// }

// async function  creditNoteStatus(form) {
//     console.log('sendt ', form);
//     return  axios.put(`/inventory/credit-note-status`, form, authHeader());
// }











