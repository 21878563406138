import { useEffect, useState } from "react";


const ChartAccountAddWatchList = (props) => {
    const [isChecked, setIsChecked] = useState(false); 
    

    useEffect(()=> {
        if(props.val?.Display_Id === 1){
            setIsChecked(true)
        }
      },[props.val?.Display_Id]);


      
      const handleOnChange = (ev) => {
          const decision =  ev.target.checked
        setIsChecked(decision);
        if(isChecked){
            props.addToWatchList(1)
        }else{
            props.addToWatchList(2)
        }
        
      };


    return ( 
        <>
           <input
           checked={isChecked}  onChange={(ev)=> handleOnChange(ev)} 
            type="checkbox"
            className="w-[0.8vw] h-[1.6vh]"
            value="1"
            />
        </>
     );
}
 
export default ChartAccountAddWatchList;