import { Grid } from "@mui/material";
import React, { useEffect } from "react";

import RoomIcon from '@material-ui/icons/Room';
import moment from "moment";


function SalesOrderHistory(props) {

  return (
    <section className="   mt-2 w-full max-w-6xl text-filterTextColor">
      <Grid className="mt-2   " container spacing={2}>
        <Grid className=" border-2 bg-light ml-3" container spacing={2}>
          <Grid item xs={12}>
      
          </Grid>
          <Grid item xs={12}>
            {props?.tableData?.map((history, i) => {
              return (
                <Grid key={i} item xs={12} container className=" pb-5 " spacing={2}>
                  <Grid item xs={5}>
                    <span className="text-sm font-bold"> 
                      {moment(history?.History_Date).format("DD-MM-YYYY HH:mm:ss")}</span>
                  </Grid>
                  <Grid item xs={7}>
                    <span style={{ border: "1px solid green " }} className=" px-[1.7vh] py-3 rounded-full  ">
                      <RoomIcon />
                    </span>
                    <span className="text-sm ml-2">{history?.History_Description} <span className="font-bold"> </span> by <i>{history?.History_Created_By_Name} </i> </span>
                  </Grid>
                </Grid>
              )

            })}
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
}

export default SalesOrderHistory;
