import Select from 'react-select';


function WarehouseItemSelect(props) {
 
    const customOption = props.list.map((item)=> {
      return ({...item, label:`${item.Name}`})
    })


function itemInInput(item){
    props.userSelection(item)
}

    return ( 
        <>
        
        <div className="">
        <small>Warehouse</small>
        <div >
        <Select  className="lg:w-[35vw] " onChange={itemInInput} options={customOption} />

        </div>
        </div>

        </>
     );
}

export default WarehouseItemSelect;