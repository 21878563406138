import { useEffect, useState } from 'react';
import Select from 'react-select';
import { GetEntryData} from "../../store/salesOrderAtom";




function SalesOrderSearchItem(props) {
    const masterData = GetEntryData();
    const [customOption, setCustomOption] = useState([]);
  

    useEffect(()=> {
     const tempData =  masterData['Item']?.map((element)=> {
        return ({...element, label:`${element?.Item_Name}`})
      }
      );
      setCustomOption(tempData)
    },[masterData])


    const handleChange = (item)=> {
        props.currentSelectedItem(item)
    }
  

    return ( 
        <>
        <Select placeholder="select item"  className="lg:w-[38.5vw] "  options={customOption} onChange={handleChange}  />

        </>
     );
}

export default SalesOrderSearchItem;
