
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';



export const rewardService = {
    submitRedeemContent,
    submitEarnContent,
    submitFAQContent,
    getEarnContent,
    updateEarnContent,
    updateFaqContent,
    getRewardHistory,
    updateRewardTableSequence,
    rewardHistoryDetails,
    getRewardRedeemList,
    getCarrierBagList,
    carrierDetails,
    createNewReturn,
    updateReturn,
    deleteReturn

    
 
};



async function  submitRedeemContent(data) {
    return  axios.post(`/rewards/submit-redeem-content`,data, authHeader());
}


async function  submitEarnContent(data) {
    return  axios.post(`/rewards/submit-earn-content`,data, authHeader());
}

async function  updateEarnContent(data) {
    return  axios.put(`/rewards/update-earn-content`,data, authHeader());
}

async function  submitFAQContent(data) {
    return  axios.post(`/rewards/submit-faq-content`,data, authHeader());
}


async function  updateFaqContent(data) {
    return  axios.put(`/rewards/update-faq-content`,data, authHeader());
}


async function  getEarnContent() {
    return  axios.get(`/rewards/get-earn-content`, authHeader());
}



async function  getRewardHistory() {
    return  axios.get(`/rewards/reward-history`, authHeader());
}


async function  updateRewardTableSequence(data) {
    return  axios.put(`/rewards/update-reward-table-sequence`,data, authHeader());
}

async function  rewardHistoryDetails(id) {
    return  axios.get(`/rewards/reward-details/${id}`, authHeader());
}

async function  getRewardRedeemList() {
    return  axios.get(`/rewards/reward-redeem`, authHeader());
}

async function  getCarrierBagList() {
    return  axios.get(`/rewards/carrier-bag-list`, authHeader());
}


async function  carrierDetails(id) {
    return  axios.get(`/rewards/carrier-details/${id}`, authHeader());
}



async function  createNewReturn(form) {
    return  axios.post(`/rewards/create-new-return`,form, authHeader());
}

async function  updateReturn(form) {
    return  axios.put(`/rewards/update-return`,form, authHeader());
}


async function  deleteReturn(id) {
    return  axios.delete(`/rewards/delete-return/${id}`, authHeader());
}















