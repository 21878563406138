

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


const paymentReceivedHeaderList = atom({key:'paymentReceivedHeaderList', default:[]});
const paymentReceivedList = atom({key:'paymentReceivedList', default:[]});
const paymentReceivedBulkAction = atom({key:'paymentReceivedBulkAction', default:[]});
const paymentReceivedFilterList = atom({key:'paymentReceivedFilterList', default:[]});
const paymentReceivedDepositTo = atom({key:'paymentReceivedDepositTo', default:[]});
const customerList = atom({key:'paymentCustomersList', default:[]});
const officerList = atom({key:'paymentOfficerList', default:[]});
const paymentEdit = atom({key:'paymentReceiveEditAtom', default:{}});
const paymentReceivedPaymentMode = atom({key:'paymentReceivedPaymentMode', default:[]});


export  const SetTableDataList = () => useSetRecoilState(paymentReceivedList);
export  const GetTableDataList = () => useRecoilValue(paymentReceivedList);

export  const SetPaymentReceiveEdit = () => useSetRecoilState(paymentEdit);
export  const GetPaymentReceiveEdit = () => useRecoilValue(paymentEdit);
export const ResetReceiveEdit = ()=> useResetRecoilState(paymentEdit);

export  const SetTableDataHeadList = () => useSetRecoilState(paymentReceivedHeaderList);
export  const GetTableDataHeadList = () => useRecoilValue(paymentReceivedHeaderList);


export  const SetTableFilter = () => useSetRecoilState(paymentReceivedFilterList);
export  const GetTableFilter = () => useRecoilValue(paymentReceivedFilterList);


export  const SetBulkActions = () => useSetRecoilState(paymentReceivedBulkAction);
export  const GetBulkActions = () => useRecoilValue(paymentReceivedBulkAction);

export  const SetDepositTo = () => useSetRecoilState(paymentReceivedDepositTo);
export  const GetDepositTo = () => useRecoilValue(paymentReceivedDepositTo);

export  const SetPaymentMode = () => useSetRecoilState(paymentReceivedPaymentMode);
export  const GetPaymentMode = () => useRecoilValue(paymentReceivedPaymentMode);

export  const SetCustomerList = () => useSetRecoilState(customerList);
export  const GetCustomerList = () => useRecoilValue(customerList);

export  const SetOfficerList = () => useSetRecoilState(officerList);
export  const GetOfficerList = () => useRecoilValue(officerList);




