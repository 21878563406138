import Select from 'react-select';
import { GetSubEntryMasterdata} from "../../store/subscriptionAtom";





function AddOnSubPay(props) {
  const getEntryMasterdata = GetSubEntryMasterdata();

  const customOption = getEntryMasterdata['Payment Term']?.map((item)=> {
    return ({...item, label:`${item.Payment_Term_Name}`})
  })

    const customStyles = {
        control: base => ({
          ...base,
          height: 48,
          backgroundColor:'#F1F1F1',
          width:450,
        }),
        indicatorSeparator: styles => ({ ...styles, display : 'none' }),
        placeholder: styles => ({ ...styles, display : 'none' }),
        valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
        option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
      }


    const handleChange = (value) => {
      props.onPaymentTermsSelect(value)
      };


    return ( 
        <>
   
         <Select  styles={customStyles} onChange={handleChange}
         defaultValue={props?.defaultVal}
          options={customOption} />


        </>
     );
}

export default AddOnSubPay;
