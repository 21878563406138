import React from 'react';
import CurrencyFormat from 'react-currency-format';
import moment from "moment";


const PackageDetailsContent = ({packageList, packageDetails}) => {


  const manageSalesStatus = (status, id) => {
    let defaultClass = "status_color1 text-white";

    switch (id) {
      case 1:
        defaultClass = "text-light status_color2";
        break;
      case 2:
        defaultClass = "text-light status_color3";
        break;
      case 3:
        defaultClass = "text-dark status_color4";
        break;
        case 4:
        defaultClass = "text-light status_color5";
        break;
      case 5:
        defaultClass = "text-light status_color6";
        break;
      case 6:
        defaultClass = "text-light status_color1";
        break;
      case 7:
        defaultClass = "text-light status_color8";
        break;

      default:
        break;
    }

    return (
      <span className={`px-4 py-2.5 rounded font-semibold ${defaultClass}`}>
        {status}
      </span>
    );
  };


  return (
    <div className="bg-white p-8 w-full shadow-lg">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-8">
        <div>
          <img
            src="https://inventory.zoho.com/ZFInvoiceLogo.zbfs?logo_id=70d87924b8a12a43636280e0f950de09"
            alt="Worldbay Logo"
            className="w-20"
          />
          <p className="text-gray-600">Worldbay Technologies Ltd</p>
          <p className="text-gray-600">TIN *********</p>
          <p className="text-gray-600">VAT ********* </p>
          <p className="text-gray-600">Plot 4b Chief Augustine Anozie Street</p>
          <p className="text-gray-600">Lekki Phase 1, Lagos, Nigeria</p>
        </div>
        <div className="text-right">
          <h2 className="text-3xl font-bold">PACKAGE</h2>
          <p className="text-gray-600">Package#  {packageDetails?.Package_Slip_Number} </p>

          <h2 className="text-3xl mt-4 font-bold"> {manageSalesStatus(packageDetails?.Sales_Order_Status_Name, packageDetails?.Sales_Order_Status_Id )}  </h2>

        </div>

       



      </div>

      {/* Details Section */}
      <div className="grid grid-cols-2 gap-4 mb-8">
        <div>
          <span className="font-semibold">Order Date:  <span className="text-gray-600"> {packageDetails?.Sales_Order_Date_String} </span></span> <br/>
         
          <span className="font-semibold">Package Date:  </span> <span className="text-gray-600">
            {moment(packageDetails?.Package_Date).format("YYYY-MM-DD")}  </span>
          <br/>
          
          <span className="font-semibold">Sales Order#: </span>
          <span className="text-gray-600">{packageDetails?.Sales_Order_No} </span>
          
        </div>
        {/* <div className="text-right">
          <span className="font-semibold">Total Qty:</span>
          <span className="text-gray-600"> {packageList?.length?.toFixed(2)} </span>
        </div> */}
      </div>

      {/* Billing & Shipping */}
      <div className="grid grid-cols-2 gap-4 mb-8">
        <div>
          <span className="font-semibold">Bill To :  <span className=" text-gray-600"> {packageDetails?.Customer_Full_Name} </span> </span>
         
          <p className="text-gray-600">{packageDetails?.Billing_Address1}</p>
          <p className="text-gray-600">{packageDetails?.Billing_Area_Name}, {packageDetails?.Shipping_City_Name}, 
            {packageDetails?.Shipping_Country_Name}</p>
        </div>
        <div>
          <p className="font-semibold">Ship To</p>
          <p className="text-gray-600">
          {packageDetails?.Shipping_Address1}<br />
            {packageDetails?.Shipping_Area_Name}, {packageDetails?.Shipping_City_Name} <br />
            {packageDetails?.Shipping_Country_Name} <br />
          </p>
        </div>
      </div>

      {/* Pick Up Date */}
      <div className="flex justify-between items-left mb-8">
        <span className="font-semibold">Pick Up Date: {packageDetails?.Pickup_Date_String}</span>
      
        
      </div>

      {/* Table Section */}
      <table className="w-full bg-white border">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">#</th>
            <th className="py-2 px-4 border-b">Item & Description</th>
            <th className="py-2 px-4 border-b">Qty</th>
            <th className="py-2 px-4 border-b">Rate</th>
            <th className="py-2 px-4 border-b">Discount</th>
            <th className="py-2 px-4 border-b">Sub Total</th>
          </tr>
        </thead>
        <tbody>
        {packageList?.map((pkg, i)=> {
            return (
                <>
                <tr>
                <td className="py-2 px-4 border-b">{i+1} </td>
                <td className="py-2 px-4 border-b">{pkg?.Item_Name} </td>
                <td className="py-2 px-4 border-b">
                
                  <CurrencyFormat value={parseFloat(pkg?.Item_Sales_Quantity )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} />
                  </td>
                <td className="py-2 px-4 border-b">
                <CurrencyFormat value={parseFloat(pkg?.Item_Sales_Rate )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} />
                  </td>
                <td className="py-2 px-4 border-b">
                <CurrencyFormat value={parseFloat(pkg?.Discount_Amount )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} />
                  
                   </td>
                <td className="py-2 px-4 border-b">
                <CurrencyFormat value={parseFloat((pkg?.Item_Sales_Rate * pkg.Item_Sales_Quantity ) - pkg?.Discount_Amount )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} />
                 </td>
              </tr>
             
                </>
            )
          
                
            })}
          
        </tbody>
      </table>

      {/* Footer Section */}
      <div className="flex justify-between items-center mt-8">
        <p className="text-sm text-gray-500"> Remark: {packageDetails?.Package_Remark} </p>
        <h3 className="font-semibold">
          Grand Total:  
          <CurrencyFormat value={parseFloat(packageDetails?.Sales_Order_Amount )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} />
          </h3>
      </div>
    </div>
  );
};

export default PackageDetailsContent;
