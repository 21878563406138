import { atom , useSetRecoilState, useRecoilValue, selector} from "recoil";




export const notificationAtom = atom({key:'notification_atopm',default:[]});



export  const SetNotificationList = () => useSetRecoilState(notificationAtom);
export  const GetNotificationList = () => useRecoilValue(notificationAtom);
