

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


const billListMasterData = atom({key:'billListMasterData', default:{}});
const billEntryMasterData = atom({key:'billEntryMasterData', default:{}});
const editAbleBillAtom = atom({key:'billEdit', default:{}});



export  const SetRecoilBillListData = () => useSetRecoilState(billListMasterData);
export  const GetRecoilBillListData = () => useRecoilValue(billListMasterData);

export  const SetRecoilBillEntryData = () => useSetRecoilState(billEntryMasterData);
export  const GetRecoilBillEntryData = () => useRecoilValue(billEntryMasterData);

export  const SetRecoilBillEdit = () => useSetRecoilState(editAbleBillAtom);
export  const GetRecoilBillEdit = () => useRecoilValue(editAbleBillAtom);
export const ResetRecoilBillEdit = ()=> useResetRecoilState(editAbleBillAtom);





