
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const settingsService = {
    getFeatureList,
    getFeatureDetails,
    submitNewFeature,
    statusChange,
    updateFeature,
    getHomePageFeature,
    actionUrlDetails,
     newActionUrl,
     ActionUrlStatusChange,
     updateActionUrl,
     getNotificationByModule,
     getAllNotification,
     getAddsSetting,
      getAdsDetails,
      submitNewAds,
      updateExistingAds,
      productActionURList,
      productActionUrlDetails,
      ActionUrlStatusChangeProduct,
      newOrUpdateProductActionUrl,
      getProductFeatureList,
      productFeatureDetails,
      newOrUpdateProductFeature,
      submitNewProductFeature,
      productStatusChange
};




async function  getProductFeatureList() {
    return  axios.get(`/settings/product-feature-list`, authHeader());
}

async function  productFeatureDetails(id) {
    return  axios.get(`/settings/product-feature-details/${id}`, authHeader());
}

async function  newOrUpdateProductFeature(data) {
    return  axios.post(`/settings/product-feature-new-or-update`, data, authHeader());
}



async function  getFeatureList() {
    return  axios.get(`/settings/feature-list`, authHeader());
}

async function  ActionUrlStatusChangeProduct(data) {
    return  axios.put(`/settings/status-change-action-url-product`,data, authHeader());
}

async function  newOrUpdateProductActionUrl(data) {
    return  axios.post(`/settings/product-new-or-update-action-url`, data, authHeader());
}



async function  updateActionUrl(data) {
    return  axios.put(`/settings/update-feature-action-url`,data, authHeader());
}



async function  productActionUrlDetails(id) {
    return  axios.get(`/settings/product-action-url-details/${id}`, authHeader());
}


async function  getNotificationByModule(id) {
    return  axios.get(`/settings/module-notification/${id}`, authHeader());
}

async function  getAllNotification() {
    return  axios.get(`/settings/get-all-notification`, authHeader());
}

async function  submitNewFeature(data) {
    return  axios.post(`/settings/submit-new-feature`,data, authHeader());
}


async function  submitNewProductFeature(data) {
    return  axios.post(`/settings/new-product-feature`,data, authHeader());
}

async function  submitNewAds(data) {
    return  axios.post(`/settings/submit-new-ads`,data, authHeader());
}

async function  updateFeature(data) {
    return  axios.put(`/settings/update-feature`,data, authHeader());
}
async function  updateExistingAds(data) {
    return  axios.put(`/settings/update-ads`,data, authHeader());
}


async function  statusChange(data) {
    return  axios.put(`/settings/status-change`,data, authHeader());
}

async function  productStatusChange(data) {
    return  axios.put(`/settings/product-status-change`,data, authHeader());
}

async function  ActionUrlStatusChange(data) {
    return  axios.put(`/settings/status-change-action-url`,data, authHeader());
}


async function  getFeatureDetails(id) {
    return  axios.get(`/settings/feature-details/${id}`, authHeader());
}

async function  getHomePageFeature() {
    return  axios.get(`/settings/homepage-feature`, authHeader());
}

async function  actionUrlDetails(id) {
    return  axios.get(`/settings/action-url-details/${id}`, authHeader());
}

async function  newActionUrl(data) {
    return  axios.post(`/settings/new-action-url`, data, authHeader());
}


async function  getAddsSetting() {
    return  axios.get(`/settings/get-adds-setting`, authHeader());
}

async function  getAdsDetails(id) {
    return  axios.get(`/settings/get-details/${id}`, authHeader());
}

async function  productActionURList() {
    return  axios.get(`/settings/product-action-url-list`, authHeader());
}








