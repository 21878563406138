import React, { useContext } from "react";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";

import "./productionEntryTab.css";
import { productService } from "../../services/product.service";
import LogicContext from "../../Context/LogicContext";
import ProductslideScroll from "./ProductslideScroll";
import ProductPlans from "../../components/ProductPlans";
import ProductAddon from "./ProductAddon";
import ProductCoupon from "./ProductCoupon";
import ProductPageToogle from "../product_page_helper/ProductPageToogle";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { useEffect } from "react";
import { SetProductEdit } from "../../store/productAtom";
import { Container } from "react-bootstrap";
import { MdDelete, MdEdit } from "react-icons/md";
const TAB_SECTION = {
  PLAN: "PLAN",
  ADDONS: "ADDONS",
  COUPONS: "COUPONS",
};

function ProductPage() {
  const [selectedTab, setSelectedTab] = useState(TAB_SECTION.PLAN);
  const logicContext = useContext(LogicContext);
  const myPromiseModal = createModal(GeneralModal);
  const setProductEditable = SetProductEdit();

  const [selectedProduct, setSelectedProduct] = useState({});

  useEffect(() => {
    document.title = "products";
  }, []);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  function viewItemDetails(product) {
    setSelectedProduct(product);
  }

  const selectTab = (section) => {
    setSelectedTab(section);
  };

  const modifyProduct = async () => {
    await myPromiseModal({  title: "Modify Product!",
      body: " Do you want to modify selected product?", showInput: false,
      placeHolder: ""  }).then((res) => {
      //  fix modify product here
      if (res.decision === true) {
        setProductEditable(selectedProduct);
      }
    });
  };

  const addProductPlan = () => {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/add-plan/${selectedProduct?.Product_Id}`
    );
  };

  const addProductAddon = () => {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/add-addon/${selectedProduct?.Product_Id}`
    );
  };

  const addProductCoupon = () => {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/add-coupon/${selectedProduct?.Product_Id}`
    );
  };

  // This method is for updating item status base on toggle response TRUE/FALSE
  const toogleItemStatus = (val) => {
    console.log('Look ', selectedProduct);
 
    // return;
    if (val === true) {
      setSelectedProduct({ ...selectedProduct, Product_Status_Id: 1,  Product_Status_Name: "Active" });

      const productData = {product_id: selectedProduct?.Product_Id,option:5};
         
      productService.changeProductStatus(productData).then((res) => {
        console.log("Product_update_esucc ", res.data);
        logicContext.showToast(res.data[0]["message_text"], "success");
      });
    }

    if (val === false) {
      setSelectedProduct({ ...selectedProduct, Product_Status_Id: 2,  Product_Status_Name: "Inactive" });

      
      const productData = {product_id: selectedProduct?.Product_Id,option:4 };

         
      productService.changeProductStatus(productData).then((res) => {
        console.log("Product_update_succ ", res.data);
        logicContext.showToast(res.data[0]["message_text"], "success");
      }).catch((err)=> {

      });
  
    }
  };

  return (
   
    <section className="@container">
  <div className="flex flex-row items-center justify-between">
    <h2 className="font-semibold text-slate-600 text-lg md:text-xl">
      {selectedProduct?.Product_Name}
    </h2>

    {/* <div className="flex flex-row items-center space-x-2">
      <div
        onClick={modifyProduct}
        className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer border-[1px] border-slate-200"
      >
        <MdEdit className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
      </div>
      <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer border-[1px] border-slate-200">
        <MdDelete className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
      </div>
    </div> */}
  </div>

  <div className="mt-2 w-full flex flex-col @md:flex-row @md:items-center @md:justify-between space-y-3 @md:space-y-0">
    <div className="flex items-center">
      <div>
        {selectedProduct.Product_Status_Id === 1 ? (
          <span className="text-sm text-success">ACTIVE</span>
        ) : (
          <span className="text-sm">INACTIVE</span>
        )}
      </div>
      <div>
        <ProductPageToogle
          toogleItemStatus={toogleItemStatus}
          val={selectedProduct.Product_Status_Id}
        />
      </div>
    </div>

    {selectedProduct?.Product_Id  &&
      <div className="flex flex-col @sm:flex-row items-center space-x-2">
      <button
        onClick={addProductPlan}
        className="px-3 py-2 bg-blue-600 text-white rounded text-sm hover:bg-blue-500 transition-all duration-100"
      >
        Add Plan
      </button>
      <button
        onClick={addProductAddon}
        className="px-3 py-2 bg-blue-600 text-white rounded text-sm hover:bg-blue-500 transition-all duration-100"
      >
        Add Addon
      </button>
      <button
        onClick={addProductCoupon}
        className="px-3 py-2 bg-blue-600 text-white rounded text-sm hover:bg-blue-500 transition-all duration-100"
      >
        Add Coupon
      </button>
    </div>
    
    
    }

  

  </div>

  <div className="mt-3 lg:flex lg:space-x-1">
    <div className="lg:w-[450px]">
      <ProductslideScroll
        selectedProduct={selectedProduct}
        viewItemDetails={viewItemDetails}
      />
    </div>

    <div className="w-full overflow-x-hidden lg:mt-0 lg:px-2">
      <div className="w-full mx-2 mt-2 flex justify-center">
        <div className="grid grid-cols-3 gap-2 w-full">
          <div
            onClick={() => selectTab(TAB_SECTION.PLAN)}
            className={`rounded-lg text-dark py-3 border-[1px] border-slate-200 cursor-pointer ${
              selectedTab === TAB_SECTION.PLAN ? "bg-[#E7ECFF]" : "bg-white"
            }`}
          >
            <div className="text-xl text-center">
              {selectedProduct?.Total_Plans}
            </div>
            <div className="text-sm text-center">Plans</div>
          </div>
          <div
            onClick={() => selectTab(TAB_SECTION.ADDONS)}
            className={`rounded-lg text-dark py-3 border-[1px] border-slate-200 cursor-pointer ${
              selectedTab === TAB_SECTION.ADDONS ? "bg-[#E7ECFF]" : "bg-white"
            }`}
          >
            <div className="text-xl text-center">
              {selectedProduct?.Total_Addons}
            </div>
            <div className="text-sm text-center">Addons</div>
          </div>
          <div
            onClick={() => selectTab(TAB_SECTION.COUPONS)}
            className={`rounded-lg text-dark py-3 border-[1px] border-slate-200 cursor-pointer ${
              selectedTab === TAB_SECTION.COUPONS ? "bg-[#E7ECFF]" : "bg-white"
            }`}
          >
            <div className="text-xl text-center">
              {selectedProduct?.Total_Coupons}
            </div>
            <div className="text-sm text-center">Coupons</div>
          </div>
        </div>
      </div>

      <div className="ml-2 mt-3 w-full">
        {selectedTab === TAB_SECTION.PLAN && (
          <ProductPlans Product_Id={selectedProduct?.Product_Id} />
        )}
        {selectedTab === TAB_SECTION.ADDONS && (
          <ProductAddon Product_Id={selectedProduct?.Product_Id} />
        )}
        {selectedTab === TAB_SECTION.COUPONS && (
          <ProductCoupon Product_Id={selectedProduct?.Product_Id} />
        )}
      </div>
    </div>
  </div>
</section>

  );
}

export default ProductPage;
