import { atom , useSetRecoilState, useRecoilValue} from "recoil";




export const ivrMainStoreAtom = atom({key:'ivr_main_history_template',
    default:{total:0, data:[]}});
export const ivrHistoryStoreAtom = atom({key:'ive_atom_history',default:[]});//


export const SetMainIVRStore = () => useSetRecoilState(ivrMainStoreAtom);
export const GetMainIVRStore = () => useRecoilValue(ivrMainStoreAtom);


export const SetIVRHistoryStore = () => useSetRecoilState(ivrHistoryStoreAtom);
export const GetIVRHistoryStore = () => useRecoilValue(ivrHistoryStoreAtom);
