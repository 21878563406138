import { React, Fragment, useEffect, useRef, useState } from "react";
import { BsPersonSquare } from "react-icons/bs";
import {
  FaChevronDown,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaPlusCircle,
} from "react-icons/fa";
import { IoMdPhonePortrait } from "react-icons/io";
import { Disclosure, Transition } from "@headlessui/react";
import Tooltip from "@mui/material/Tooltip";
import {  MdOutlineModeEditOutline } from "react-icons/md";
import CurrencyFormat from "react-currency-format"; 
import { Select } from 'antd';
import moment from "moment";
import axios from "axios";
import { customerService } from "../../services/customer.service";


const CustomerOverviewNew = (props) => {
  const  [subscriptionDisplay, setSubscriptionDisplay] =  useState([]);
  const {customerData, subscriptionList}  = props;



  useEffect(()=> {

    const newList = subscriptionList.filter((sub)=> sub?.Subscription_Status === 'live');
    setSubscriptionDisplay(newList);

  },[subscriptionList]);

  useEffect(()=> {
    setSubscriptionDisplay([]);
  },[]);





  const onSubscriptionFilterChange = (filter) => {
    const newList = subscriptionList.filter((sub)=> sub?.Subscription_Status === filter);
    setSubscriptionDisplay(newList);
  }
  const displayStatus =  (status)=> {
    let result =  null;
    switch (status) {
      case 'live':
        result = <div className="text-[#70CC40]">{status} </div>
        break;
      case 'cancelled':
        result = <div className="text-red-700">{status} </div>
        break;
    
      default:
        break;
    }

    return result;
  }


  return (
    <div className="bg-white @container">
      <div className="flex flex-col @md:flex-row @md:divide-x-[1px] @md:divide-gray-200 space-y-6 @md:space-y-0">
        <div className="w-full @md:w-[500px] p-2">
          <div className="flex space-x-2">
            <div>
              <BsPersonSquare className="h-[40px] w-[40px] text-gray-300 flex-shrink-0 " />
            </div>

            <div>
              <div className="text-slate-800 font-bold text-base">
                {customerData?.Customer_Full_Name}
              </div>
              <div className="flex flex-row text-sm space-x-[2px] text-gray-600">
                <span className="text-sm font-medium text-gray-400">Id:</span>
                <span className="text-gray-600 font-medium"> {customerData?.Customer_Id} </span>
              </div>
              <div className="flex flex-row text-sm space-x-[2px] text-gray-600">
                <span className="text-sm font-medium text-gray-400">Type:</span>
                <span className="text-gray-600 font-medium">{customerData?.Customer_Type_Name} </span>
              </div>
              <div className="flex flex-row text-sm space-x-[2px] text-gray-600">
                <span className="text-sm font-medium text-gray-400">
                  Company:
                </span>
                <span className="text-gray-600 font-medium">{customerData?.Corporate_Name} </span>
              </div>
              <div className="text-slate-700 font-normal text-sm">
                {customerData?.Customer_Email_Address}
              </div>
              <div className="flex flex-row items-center space-x-1">
                <FaPhoneAlt className="h-2.5 w-2.5 text-gray-600" />
                <div className="text-slate-700 font-normal text-sm">
                  {customerData?.Customer_Mobile_No}
                </div>
              </div>
              <div className="flex flex-row items-center space-x-1">
                <IoMdPhonePortrait className="h-3 w-3 text-gray-600" />
                <div className="text-slate-700 font-normal text-sm">
                  {customerData?.Customer_Work_Mobile_No}
                </div>
              </div>
         
            </div>
          </div>

          <div className="mt-3 w-full ">
            <Disclosure as="div" className="px-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex flex-row items-center w-full justify-between flex-shrink-0 border-b-[1px] border-b-gray-200 py-2 text-left text-sm focus:outline-none">
                    <span>ADDRESS</span>
                    <FaChevronDown
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-[10px] w-[10px] text-blue-600 flex-shrink-0`}
                    />
                  </Disclosure.Button>
                  <Transition
                    show={open}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel className="pb-2 pt-3 text-sm text-gray-500 space-y-4">
                      <div>
                        <div className="flex flex-row items-center space-x-2">
                          <div className="text-sm text-gray-400">
                            Billing Address
                          </div>
                          <div className="flex-1 h-[1px] w-full bg-gray-100" />
                        </div>
                        <div className="mt-2 flex flex-row items-center justify-between">
                          <div className="text-slate-700 font-semibold text-sm">
                            {customerData?.Customer_Full_Name}
                          </div>
                          <Tooltip title="Edit the Address">
                            <div className="w-fit p-[3px] rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer border-[1px] border-slate-200">
                              <MdOutlineModeEditOutline className="h-[16px] w-[16px] flex-shrink-0 text-gray-600" />
                            </div>
                          </Tooltip>
                        </div>
                        <div className="mt-1 text-gray-700">
                          <div className="text-sm text-wrap">
                            {customerData?.Billing_Address1}
                          </div>
                          <div className="text-sm text-wrap">{customerData?.Billing_Area_Name} </div>
                          <div className="text-sm text-wrap">{customerData?.Billing_City_Name} </div>
                          <div className="text-sm text-wrap">{customerData?.Billing_Country_Name} </div>
                          <div>
                            <div className="text-sm text-wrap">
                              Fax Number: 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="flex flex-row items-center space-x-2">
                          <div className="text-sm text-gray-400">
                            Shipping Address
                          </div>
                          <div className="flex-1 h-[1px] w-full bg-gray-100" />
                        </div>
                        <div className="mt-2 flex flex-row items-center justify-between">
                          <div className="text-slate-700 font-semibold text-sm">
                            {customerData?.Customer_Full_Name}
                          </div>
                          <Tooltip title="Edit the Address">
                            <div className="w-fit p-[3px] rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer border-[1px] border-slate-200">
                              <MdOutlineModeEditOutline className="h-[16px] w-[16px] flex-shrink-0 text-gray-600" />
                            </div>
                          </Tooltip>
                        </div>
                        <div className="mt-1 text-gray-700">
                          <div className="text-sm text-wrap">
                            {customerData?.Shipping_Address1}
                          </div>
                          <div className="text-sm text-wrap">{customerData?.Shipping_Area_Name} </div>
                          <div className="text-sm text-wrap">{customerData?.Shipping_City_Name} </div>
                          <div className="text-sm text-wrap">{customerData?.Shipping_Country_Name} </div>
                          <div>
                            <div className="text-sm text-wrap">
                              Fax Number: 
                            </div>
                          </div>
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-2 px-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex flex-row items-center w-full justify-between flex-shrink-0 border-b-[1px] border-b-gray-200 py-2 text-left text-sm focus:outline-none">
                    <span>GPS INFORMATION</span>
                    <FaChevronDown
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-[10px] w-[10px] text-blue-600 flex-shrink-0`}
                    />
                  </Disclosure.Button>
                  <Transition
                    show={open}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel className="pb-2 pt-3 text-sm text-gray-500 space-y-2">
                      <div className="flex items-center space-x-2">
                        <div className="text-slate-400 font-medium text-sm text-wrap">
                          Branch
                        </div>
                        <div className="flex-1 group rounded flex flex-row items-center justify-between space-x-4 cursor-pointer">
                          <div className="text-slate-700 font-semibold text-sm text-wrap peer ">
                            {customerData?.Branch_Name}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="text-slate-400 font-medium text-sm text-wrap">
                          Unit
                        </div>
                        <div className="flex-1 group rounded flex flex-row items-center justify-between space-x-4 cursor-pointer">
                          <div className="text-slate-700 font-semibold text-sm text-wrap peer ">
                            {customerData?.Unit_Name}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="text-slate-400 font-medium text-sm text-wrap">
                          GPS Info
                        </div>
                        <div className="flex-1 group px-2 py-2 rounded flex flex-row items-center justify-between space-x-4 hover:bg-gray-200 cursor-pointer">
                          <div className="text-slate-700 font-semibold text-xs text-wrap peer ">
                            {customerData?.GPS_Latitude}, {customerData?.GPS_Longitude}
                          </div>
                          <Tooltip title="Go to map">
                            <div className="w-fit cursor-pointer">
                            <a
                    className="rounded-lg px-10  my-3 py-2  text-blue-100"
                    href={`https://maps.google.com/maps?q=${customerData?.GPS_Latitude},${customerData?.GPS_Longitude}&t=&z=16&ie=UTF8&iwloc`}
                    target="_blank"
                    rel="noreferrer noopener"
                  > <FaMapMarkerAlt className="h-[14px] w-[14px] flex-shrink-0 text-transparent group-hover:text-gray-600" />
                  </a>
                             
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-2 px-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex flex-row items-center w-full justify-between flex-shrink-0 border-b-[1px] border-b-gray-200 py-2 text-left text-sm focus:outline-none">
                    <span>OTHER DETAILS</span>
                    <FaChevronDown
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-[10px] w-[10px] text-blue-600 flex-shrink-0`}
                    />
                  </Disclosure.Button>
                  <Transition
                    show={open}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel className="pb-2 pt-4 text-sm text-gray-500 divide-y-[1px] divide-gray-100">
                      <div className="space-y-1 text-sm">
                        <div className="flex items-center space-x-2">
                          <div className="text-slate-500 text-xs font-normal text-wrap">
                            Payment Terms:
                          </div>
                          <div className="flex-1 text-slate-700 font-semibold text-wrap peer ">
                            n{customerData?.Payment_Term_Name}
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="text-slate-500 text-xs font-normal text-wrap">
                            Price List:
                          </div>
                          <div className="flex-1 text-slate-700 font-semibold text-wrap peer ">
                            {customerData?.Price_List_Name}
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="text-slate-500 text-xs font-normal text-wrap">
                            Facebook:
                          </div>
                          <div className="flex-1 text-slate-700 font-semibold text-wrap peer ">
                            {customerData?.Facebook_URL}
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="text-slate-500 text-xs font-normal text-wrap">
                            Twitter:
                          </div>
                          <div className="flex-1 text-slate-700 font-semibold text-wrap peer ">
                            {customerData?.Twitter_URL}
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="text-slate-500 text-xs font-normal text-wrap">
                            Website:
                          </div>
                          <div className="flex-1 text-slate-700 font-semibold text-wrap peer ">
                            {/* www.grocedy.com */}
                            {customerData?.website_url}
                          </div>
                        </div>
                      </div>

                      <div className="mt-2.5 pt-2.5 space-y-1 text-sm">
                        <div className="flex items-center space-x-2">
                          <div className="text-slate-500 text-xs font-normal text-wrap">
                            Religion:
                          </div>
                          <div className="flex-1 text-slate-700 font-semibold text-wrap peer ">
                            {customerData?.Religion_Name}
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="text-slate-500 text-xs font-normal text-wrap">
                            Age:
                          </div>
                          <div className="flex-1 text-slate-700 font-semibold text-wrap peer ">
                            {customerData?.Age_Name}
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="text-slate-500 text-xs font-normal text-wrap">
                            Gender:
                          </div>
                          <div className="flex-1 text-slate-700 font-semibold text-wrap peer ">
                            {customerData?.Gender_Name}
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="text-slate-500 text-xs font-normal text-wrap">
                            Birthday:
                          </div>
                          <div className="flex-1 text-slate-700 font-semibold text-wrap peer ">
                            {customerData?.Date_Of_Birth}
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="text-slate-500 text-xs font-normal text-wrap">
                            Profession:
                          </div>
                          <div className="flex-1 text-slate-700 font-semibold text-wrap peer ">
                            {customerData?.Profession_Name}
                          </div>
                        </div>
                      </div>

                      <div className="mt-2.5 pt-2.5 space-y-1 text-sm">
                        <div className="flex items-center space-x-2">
                          <div className="text-slate-500 text-xs font-normal text-wrap">
                            Referring Email:
                          </div>
                          <div className="flex-1 text-slate-700 font-semibold text-wrap peer ">
                            {customerData?.Referring_Email}
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="text-slate-500 text-xs font-normal text-wrap">
                            Assigned officer:
                          </div>
                          <div className="flex-1 text-slate-700 font-semibold text-wrap peer ">
                            {customerData?.Assigned_Officer_Name}
                          </div>
                        </div>

                        <div className="flex items-center space-x-2">
                          <div className="text-slate-500 text-xs font-normal text-wrap">
                            Bustop/Landmark:
                          </div>
                          <div className="flex-1 text-slate-700 font-semibold text-wrap peer ">
                            {customerData?.Bus_Stop}
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="text-slate-500 text-xs font-normal text-wrap">
                            Referring Person:
                          </div>
                          <div className="flex-1 text-slate-700 font-semibold text-wrap peer ">
                            {customerData?.Referring_Person}
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="text-slate-500 text-xs font-normal text-wrap">
                            Remarks:
                          </div>
                          <div className="flex-1 text-slate-700 font-semibold text-wrap peer ">
                            {customerData?.Customer_Remark}
                          </div>
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          </div>
        </div>

        <div className="w-full p-2">
          <div className="px-4 py-4 border-[1px] border-gray-200 rounded-md bg-white">
            <h2 className="text-base font-medium"> Wallet Balance </h2>
            <div className="text-3xl font-bold">
            <CurrencyFormat value={customerData?.Wallet_Balance?.toFixed(2)}
              displayType={'text'} thousandSeparator={true} prefix={customerData?.Currency_Symbol} />
                </div>
          </div>

          {/* Subscription filter */}
          <Select
          
                showSearch
                optionFilterProp="label"
                onChange={onSubscriptionFilterChange}
                defaultValue={['Live']}
                options={[
                  {
                    value: "live",
                    label: 'Live',
                  },
                  {
                    value: "cancelled",
                    label: "Cancelled",
                  },
               
                ]}
                style={{ width: '300px' }}
              />
          {/* Subscription filter end */}
         
          {subscriptionDisplay?.map((sub)=> {
            return (
              <div className="mt-4 p-4 border-[1px] border-gray-200 rounded-md bg-white ">
              <div className="flex justify-end">
                <div className="flex flex-row items-center space-x-1">
                  <div className="h-[10px] w-[10px] rounded-full bg-[#70CC40] flex-shrink-0" />
                 {displayStatus(sub?.Subscription_Status)}
                
                </div>
              </div>
  
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-baseline">
                  <div className="text-lg text-gray-600 font-bold">
                    {sub?.Subsription_Name}
                  </div>
                  <div className="text-xs text-[#4999F6] ml-2 ">Grocedy</div>
                </div>
                <div className="font-bold text-lg"> <CurrencyFormat value={customerData?.Subscription_Amount?.toFixed(2)}
              displayType={'text'} thousandSeparator={true} prefix={customerData?.Currency_Symbol} /> </div>
              </div>
              <div className="text-sm text-gray-500 italic">ID: {sub?.Subscription_Id} </div>
              <div className="text-sm text-gray-500 italic">
             
                Amount: 
                <CurrencyFormat value={sub?.Subscription_Amount?.toFixed(2)}
              displayType={'text'} thousandSeparator={true} prefix={customerData?.Currency_Symbol} />
                 </div>
              <div className="text-sm text-gray-500 italic">Interval: {sub?.Subscription_Interval} {sub?.Subscription_Interval_Unit} </div>
              <div className="mt-1 flex flex-row items-center justify-between">
                <div>
                  <div className="text-sm text-gray-500">Last Billing Date</div>
                  <div className="text-sm text-gray-600 font-bold">
                    
                    {moment(sub?.Activated_Date).format("LLL")}
                  </div>
                </div>
                <div>
                  <div className="text-sm text-gray-500">Next Billing Date</div>
                  <div className="text-sm text-gray-600 font-bold">
                    {/* 09.09.2022 */}
                  </div>
                </div>
              </div>
            </div>

            )
          })}
          
     

        </div>
      </div>
      {/* For credit score */}

      {/*  credit end */}
        
    </div>
  );
};

export default CustomerOverviewNew;
