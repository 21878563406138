

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


const imageUploadAtom = atom({key:'imageUploadAtom', default:[]});



export  const SetRecoilImageUploadAtom = () => useSetRecoilState(imageUploadAtom);
export  const GetRecoilImageUploadAtom = () => useRecoilValue(imageUploadAtom);





