import * as React from "react";
import { useEffect, useState } from "react";

import {
  DataGrid,
  gridPageCountSelector,
  GridToolbar,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { customerService } from "../../services/customer.service";
import {
  GetCustomerTransListAtom,
  SetCustomerTransListAtom,
} from "../../store/customerTransListAtom";
import CustomerHistorySelect from "./CustomerHistorySelect";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      siblingCount={1} // Number of pagination items to display on each side of the current page
      boundaryCount={1} // Number of first and last page pagination items to always display
      renderItem={(props) => (
        <PaginationItem
          {...props}
          disableRipple
          style={{
            borderRadius: "50%", // Make pagination items circular
            margin: "0 4px", // Add some space between pagination items
            minWidth: "36px", // Set a minimum width for pagination items
          }}
        />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const tableHead = [
  {
    field: "Ref_No",
    headerName: "Ref",
    width: 130,
    className: "font-weight-bold",
  },
  { field: "Transaction_Date", headerName: "Date", width: 160 },
  { field: "Transaction_Type_Name", headerName: "Type", width: 150 },
  { field: "Transaction_Group", headerName: "Group ", width: 130 },
  { field: "Amount", headerName: "Amount (₦)", width: 150 },
  { field: "Narration", headerName: "Narration", width: 160 },
];

export default function CustomerTransactionTab({ customer_id }) {
  const [tableList, setTableList] = useState([]);
  const setTransList = SetCustomerTransListAtom();
  const transList = GetCustomerTransListAtom();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  console.log("look ", transList);
  useEffect(() => {
    if (!transList.transation_history?.length) {
      getDataFromServer();
    } else {
      setTableList(transList.transation_history);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id, transList.transation_history?.length]);

  const getDataFromServer = () => {
    customerService.getCustomerTransactions(customer_id).then((res) => {
      console.log("SEE  LIS", res.data);
      if (res.data?.transation_history?.length) {
        setTransList(res.data);
        setTableList(res.data.transation_history);
        // let tempList =  res.data?.transation_history.map((item, index)=> { return {...item, id: index}} );
      }
    });
  };

  const onTypeSelect = (selection) => {
    console.log(selection);
    if (selection?.Transaction_Type_Id === 0) {
      return setTableList(transList.transation_history);
    }
    let newList = transList.transation_history.filter(
      (item) => item.transaction_type_id === selection?.Transaction_Type_Id
    );
    setTableList(newList);
  };

  return (
    <>
      <div className="ml-1 @container">
        <div className="md:flex md:flex-row md:items-center md:justify-between">
          <div className="mt-1 text-gray-600 text-xl font-bold">
            transaction History
          </div>
          <div className="mr-1 mt-3 md:mt-0">
            <CustomerHistorySelect
              optionList={transList.transation_type}
              onTypeSelect={onTypeSelect}
            />
          </div>
        </div>

        <DataGrid
          className="mt-4 overflow-x-auto bg-white outline-none border-[1px] border-gray-200 rounded-xl"
          style={{ height: 600, width: "100%" }}
          columns={tableHead}
          rows={tableList?.map((item, index) => {
            return { ...item, id: index };
          })}
          // onRowClick={(event) => {
          //   OnItemCellClick(event);
          // }}

          // components={{
          //   Pagination: CustomPagination,
          // }}
          onCellClick={(event) => {
            event.defaultMuiPrevented = true;
          }}
          // sx={{
          //   borderColor: "primary.light",
          //   "& .MuiDataGrid-cell:hover": {
          //     color: "primary.main",
          //     cursor: "pointer",
          //   },
          // }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{ toolbar: { showQuickFilter: true } }}
        />
      </div>
    </>
  );
}
