import React from 'react';
import {  Navigate } from 'react-router-dom';

import { authenticationService } from '../services/authentication.service';

export const PrivateRoute = ({children}) => {
   
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return authenticationService.isLoggedIn() ? children : <Navigate to="/login" />;
}