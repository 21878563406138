

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


const corporateEditAtom = atom({key:'corporateEditAtom', default:{}});
const corporateListAtom = atom({key:'corporate_list', default:{
    Corporate_Info:[],Area:[],City:[],Corporate_Type:[],Corporate_officer_type:[],Country:[]
    ,Display_Column:[], trail_period:[],total:0, bank_info:[], subscription_type_info:[]}});


export  const SetRecoilCorporateList = () => useSetRecoilState(corporateListAtom);
export  const GetRecoilCorporateList = () => useRecoilValue(corporateListAtom);

export  const SetCorporateEdit = () => useSetRecoilState(corporateEditAtom);
export  const GetCorporateEdit = () => useRecoilValue(corporateEditAtom);
export const ResetCorporateEdit = ()=> useResetRecoilState(corporateEditAtom);





