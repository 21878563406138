import { useEffect, useState } from "react";
import Select from 'react-select';
import underscore  from 'underscore';



function FinishItemsSetupSearchItems(props) {
    const [itemList, setItemList] = useState([]);

    let dataOne = []
    let dataTwo = []
    let dataThree = []

  let mData = underscore.groupBy(props?.list,'Item_Category_Id');
  
  if(mData[1] !== undefined){
    dataOne = mData[1]
  }
  if(mData[2] !== undefined){
    dataTwo = mData[2]
  }
  if(mData[3] !== undefined){
    dataThree = mData[3]
  }

    useEffect(()=> {
    if(props.type === 1){
      if(dataOne !== undefined || dataTwo === undefined){
        let tempArr =  dataOne.concat(dataTwo);
        let temp =  tempArr?.map((item)=> {
          return ({...item, label:`${item.Item_Name}  `})
        });
        setItemList(temp);
      }
     
    }else if(props.type === 2){
     if(mData[3] !== undefined){
     let temp =  dataThree?.map((item)=> {
        return ({...item, label:`${item.Item_Name} `})
      });
      setItemList(temp);
     }
     
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.list.length, props.type]);



function itemInInput(item){
    props.userSelection(item)
}
  

    return ( 
        <>
        <div className="">
        <small>Select item</small>
        <div >
        <Select  className="lg:w-[38.5vw] " onChange={itemInInput} options={itemList} />

        </div>
        </div>

        
        </>
     );
}

export default FinishItemsSetupSearchItems;
