import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";



function ProductActionUrlContent(props) {
    const { featureDetail } = props;



    useEffect(()=> {
        
   

    },[]);


    //  dynamically manage different status
    const manageSalesStatus = (status) => {
        let defaultClass = 'status_color1 text-white';

        switch (status) {
            case 'Draft':
                defaultClass = 'text-light status_color2';
                break;
            case 'Active':
                defaultClass = 'text-light status_color3';
                break;
            case 'Pending for Approval':
                defaultClass = 'text-dark status_color4';
                break;
            case 'Fulfilled':
                defaultClass = 'text-light status_color5';
                break;
            case 'Rejected':
                defaultClass = 'text-light status_color6';
                break;
            case 'Confirmed':
                defaultClass = 'text-light status_color1';
                break;
            case 'Partially Billed':
                defaultClass = 'text-light status_color8';
                break;

            default:
                break;
        }

        return (
            <span className={`py-2 pl-4 pr-4 pt-2 pb-2 h5 text-sm rounded-full ${defaultClass} `}>
                {status}
            </span>
        );
    };


    // view subscription by going to subscription module




    return (
        <section className="   mt-2 w-full max-w-6xl text-filterTextColor">
           
            <Grid className=" bg-light pb-5  " container spacing={2}>
              
                <Grid className="border-2 mx-4  bg-light  my-2 " item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <span className="#476ef8 font-bold ">
                            <li class="list-group-item active font-bold text-center"> <h2>{featureDetail?.Feature_Name}</h2> </li>
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <div  className="row" >
                                <div className="col" >
                                <ul class="list-group">
                               
                                <li class="list-group-item"> Ref No : <span className="text-primary" > {featureDetail?.Action_URL_Id} </span> </li>
                                <li class="list-group-item"> Action Name : <span className="text-primary" > {featureDetail?.Action_URL_Name} </span> </li>
                                <li class="list-group-item"> Mobile App : <span className="text-primary" > {featureDetail?.Action_URL_Mobile} </span> </li>
                                <li class="list-group-item">  Web  App : <span className="text-primary" > {featureDetail?.Action_URL_Web} </span> </li>
                                <li class="list-group-item"> Created By : <span className="text-primary" > {featureDetail?.Encoded_By_Name} </span> </li>
                        
                                </ul>
                                </div>
                                <div className="col" >
                                <ul class="list-group">
                              
                                <li class="list-group-item">  <span className="text-primary" >  { manageSalesStatus(featureDetail?.Feature_Action_Status_Name)}  </span> </li>
                                <li class="list-group-item"> Created Date : <span className="text-primary" >  { moment(featureDetail?.Encoded_Date).format('LLL')}  </span> </li>
                        
                                </ul>
                                </div>
                           

                            </div>

                        </Grid>

                    </Grid>



                </Grid>




            </Grid>





        </section>
    );
}

export default ProductActionUrlContent;
