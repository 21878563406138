// import { DataGrid ,  gridPageCountSelector,
//     gridPageSelector, GridToolbarContainer, GridToolbarExport ,
//     useGridApiContext,
//     useGridSelector} from "@mui/x-data-grid";
import React,{useContext, useEffect, useState}  from 'react';

import { GetAddonMasterData } from '../store/productAtom';
import LogicContext from "../Context/LogicContext";

import Select from 'react-select';
import { PinDropSharp } from '@material-ui/icons';
import {SetAssoTable, GetAssoTable, ResetAssoTable} from '../store/productAtom';
import { TableBody, TableCell,TableContainer, TableHead,TableRow} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "react-bootstrap";


function AddonAddFeatures() {
  const masterData = GetAddonMasterData();
  const resetAssoTable = ResetAssoTable();
  const [currentSelection, setCurrentSelection] = useState({});
  const logicContext = useContext(LogicContext);
  const setTableData = SetAssoTable();
  const tableData = GetAssoTable();

  useEffect(()=> {


    return ()=> {
      resetAssoTable()
    }
  },[resetAssoTable])





  const handleChange = (item) => {
    setCurrentSelection({...item, id: item.Code})
  };


  const customStyles = {
    control: base => ({
      ...base,
      height: 50,
      backgroundColor: '#F1F1F1',
      width: 388,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"25px", marginLeft:"10px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),

  
    
  }

  function addtoTable(e){
    e.preventDefault()
    if(!currentSelection?.Code){
      return logicContext.showToast('Select new item','error')
    }
    const exist = tableData?.find((element) => element.Code === currentSelection.Code);
    if (exist){
      return logicContext.showToast('Item already exist in selection','error')
    }
    setTableData((prev)=> {
      if(prev){
        return ([...prev,currentSelection])
      }else{
        return ([currentSelection])

      }
    })
  }




  function OnItemCellClick(val){
      const newTableData = tableData?.filter((item)=> item.Code !== val.Code)
      setTableData(newTableData)
  }




  return (
    <>
      <div className="">
     
        <span className="text-sm">Addon Features <span className="ml-2 text-danger font-weight-bold">* </span> </span>
        <div className="plain_input_F">
          <div className="flex ">
         
            <Select placeholder="select item" styles={customStyles} onChange={handleChange}
              options={masterData[1]?.map((item, i) => { return ({ ...item, label: item.Name, id: item.Code}) })}
               />

            <div>
              <button style={{ backgroundColor: "#476EF8" }} onClick={(e)=> addtoTable(e)}
               className=" h-[7.0vh] rounded px-3 ml-2 text-white" >ADD</button>
            </div>

          </div>

        </div>
      </div>




<TableContainer style={{width:"450px"}} className="mt-[6.5vh] ">
<Table sx={{  }} aria-label="simple table">
  <TableHead style={{ backgroundColor: "#F1F1F1", height:"55px" }}>
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>Name</TableCell>
      <TableCell></TableCell>
      
    </TableRow>
  </TableHead>

  <TableBody>
    {tableData?.map((item, i) => {
      return (
        <TableRow
          key={i}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell align="left">{item?.Code} </TableCell>
          <TableCell align="left">{item?.Name} </TableCell>
         
          <TableCell align="left" >
            {" "}
            <DeleteIcon onClick={() => OnItemCellClick(item)} color="primary" />{" "}
          </TableCell>
        </TableRow>
      );
    })}


    
  </TableBody>
  
</Table>

</TableContainer>

    </>
  );
}

export default AddonAddFeatures;