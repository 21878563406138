import React, { useState } from "react";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "react-bootstrap";
import AlertConfirm from "../helpers/AlertConfirm";

function FinishItemTable2(props) {
  const [alertState, setAlertState] = useState(false);
  const [tempData, setTempData] = useState({});

  function deleteItem(item) {
    setAlertState(true);
    setTempData(item);
  }

  function closeAlert(response) {
    setAlertState(false);
    if (response === true) {
      props.deleteItemfromTableList(tempData);
      setTempData({});
    } else {
      setTempData({});
    }
  }

  function quantityChange(newQty, item) {
    props.modifyQuatity(newQty, item);
  }

  return (
    <>
      {alertState ? (
        <AlertConfirm
          title={"Delete Item from Finish item(s)?"}
          body={"Item will be deleted from finished item(s)"}
          closeAlert={closeAlert} 
        ></AlertConfirm>
      ) : null}
      <div className=" ">
        <TableContainer className="mt-3">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#F1F1F1" }}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {props?.tableData?.map((item, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{item.Item_Id} </TableCell>
                    <TableCell align="left">{item.Item_Name} </TableCell>
                    <TableCell align="left">{item.Item_Sku} </TableCell>
                    <TableCell align="left">
                      {item.Item_Category_Name}{" "}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <input
                        type="number"
                        style={{ width: "70px" }}
                        onChange={(e) => quantityChange(e.target.value, item)}
                        defaultValue={item.Item_quantity}
                      />{" "}
                    </TableCell>
                    <TableCell align="left" onClick={() => deleteItem(item)}>
                      {" "}
                      <DeleteIcon color="primary" />{" "}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <div  style={{ height: 450, width: '100%' }}>
          <DataGrid
            columns={TableHead}
            rows={props?.tableData}
            components={{
              Pagination: CustomPagination,
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div> */}
      </div>
    </>
  );
}

export default FinishItemTable2;
