import React from 'react';

import SalesOrderOverview from './SalesOrderOverview';




export class SalesCommentWrapper extends React.Component {


    render() {
        return (
           <SalesOrderOverview salesData={this.props.salesData} viewVendor={this.props?.viewVendor} />
        )
    }
}