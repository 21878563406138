import { Dropdown } from "react-bootstrap";
import { Link, } from "react-router-dom";

import '../../pages/ItemPage.css'
import { accountService } from "../../services/accountService";
import { useEffect, useState } from "react";
import { GetWalletVariantListAtom, SetWalletVariantListAtom } from "../../store/variantAtom";
import WalletTable from "./WalletTable";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Tooltip from "@mui/material/Tooltip";
import TextField from '@mui/material/TextField';



function WalletCheckPage() {

    const getWalletAtom = GetWalletVariantListAtom();
    const setWalletAtomList = SetWalletVariantListAtom();
    const [userList, setUserList] = useState([]);



    useEffect(() => {

        if (!getWalletAtom.wallet_info.length) {
            reloadData();
        } else {
            setUserList(getWalletAtom.wallet_info);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getWalletAtom.wallet_info]);



    const reloadData = () => {
        accountService.getPaymentVariate().then(response => {
            if (response?.data?.wallet_info?.length) {
                setWalletAtomList(response.data);
            }
        }).catch(err => {
            console.log('error ', err);
        })
    }


    const onSearchKeyChange = (eve)=> {
        const search =  eve.target.value;
        if(search.length > 2){
            let tempResult =  getWalletAtom.wallet_info?.filter((customer)=> {
                return customer?.Customer_Id?.toString()?.indexOf(search?.toLowerCase()) >= 0 ||
                        customer?.Zoho_Customer_Id?.toString().toLowerCase()?.indexOf(search?.toLowerCase()) >= 0 ||
                        customer?.Customer_Full_Name?.toString().toLowerCase()?.indexOf(search?.toLowerCase()) >= 0 ||
                        customer?.Customer_Email_Address?.toString().toLowerCase()?.indexOf(search?.toLowerCase()) >= 0 
              });
              setUserList(tempResult);
        } else if(search.length < 1){
            setUserList(getWalletAtom.wallet_info);
        }
       
    }



    return (
        <>

            <div className="">
                <section className="">

                    {/* grid start */}
                    <div className="row border-2 bg-filterColor mt-5 mb-2" >
                        <div className="col" >
                            <Tooltip title="Reload">
                                <RestartAlt
                                    className="cursor ml-2 mt-2 mr-2"
                                    onClick={() => reloadData()}
                                    color="primary"
                                    fontSize="large"
                                />
                            </Tooltip>
                        </div>

                        <div className="col" >
                            <div className="pt-2" >
                                <input onChange={(ev)=> onSearchKeyChange(ev)} className="form-control mr-sm-2" type="search" placeholder="Search  (id, name ,email)" aria-label="Search">
                                </input>
                            </div>
                        </div>

                        <div className="col offset-3" >
                        <button className="bg-filterColor mx-2 rounded text-black text-sm  mt-1 py-[1.8vh]  px-3">
                                    Export
                                </button>
                        </div>

                    </div>  
                    {/* End of Grid */}

                 
                    <WalletTable list={userList} />

                </section>
            </div>

        </>

    );
}

export default WalletCheckPage;
