import * as React from 'react';
import Switch from '@mui/material/Switch';


 interface PropType {
    visible: Number
    onVisibleChange(val: any): void
 }
export default function VisibleToggle(props: PropType) {
    const {onVisibleChange, visible} = props;
  const [checked, setChecked] = React.useState(true);


  React.useEffect(()=> {
    if(visible === 1){
        setChecked(true);
    }else if(visible === 2){
        setChecked(false);

    }

  },[visible]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
    if(event.target.checked === true){
        onVisibleChange(1);
    }else{
        onVisibleChange(2);
    }
  };

  return (
<>
<Switch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
      
    />
    <small>visible</small>
</>
    
  );
}
