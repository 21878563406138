import { Link } from "react-router-dom";
import home_image from "../assets/home_image.png";
import "./Homepage.css";
import Container from "../components/my-container/Container";

function HomePage() {
  return (
    <div className="bg-white">
      <Container className="pb-40 md:pb-0">
        <div className="mt-12 md:mt-6 flex flex-col md:grid md:grid-cols-2 items-center md:gap-6 lg:px-6">
          <div className="md:col-span-1">
            <h1 className="text-5xl lg:text-6xl font-bold text-gray-700">
              Impacting millions, one person at a time.
            </h1>
            <div className="mt-16 max-w-sm">
              <Link to="/login">
                <button className="w-full py-3 text-sm font-semibold bg-[#486EF8] outline-none rounded-lg text-white hover:bg-[#486EF8]/80 active:bg-[#486EF8] transition-all duration-300 active:outline-none">
                  GET STARTED
                </button>
              </Link>
            </div>
          </div>
          <div className="mt-24 md:mt-0 md:col-span-1 flex justify-center flex-shrink-0">
            <img
              src={home_image}
              className="h-auto w-full max-w-[480px] aspect-auto object-contain"
              alt="delgro_intro"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default HomePage;
