function NotFoundPage() {
  return (
    <div className="text-center">
      <div className="card m-5 bg-dark p-5">
        <h2 className="text-white">PAGE NOT FOUND!</h2>
      </div>
    </div>
  );
}

export default NotFoundPage;
