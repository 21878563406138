import { useEffect, useState } from "react";
import {imageService} from '../services/image.service';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};






function DocumentViewer(props) {
    const [open, setOpen] =  useState(false); 
    const [currentPreview, setCurrentPreview] =  useState(null); 

  const handleClose = () => setOpen(false);

    useEffect(()=> {

    },[props]);

    const handleOpen = (file) => {
        setOpen(true)
        setCurrentPreview(file)
    };

    console.log('file list  ', props?.fileList)
    let fileData = props?.fileList?.map((file, i)=> {

        return (
        
             <div key={i} className="col-sm">
            <img width='200' className="cursor" onClick={()=> handleOpen(file)}
             key={i} src={imageService.PURCHASE_ORDER_ATTACHMENT_URL + file.Purchase_Order_Ref_No 
                + file.Image_Reference_No + '.' + file.Image_Format} alt={file.Image_Format + 'file'} ></img>
              </div>
        )
    });


    return ( 
        <>
        <div className="container">
            <div className="row">
            {fileData}
            </div>
            <small  className="text-center" >Click on the item to preview </small>
            </div>


    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <img  
             key={currentPreview} src={imageService.PURCHASE_ORDER_ATTACHMENT_URL + currentPreview?.Purchase_Order_Ref_No 
                + currentPreview?.Image_Reference_No + '.' + currentPreview?.Image_Format} alt=' _file' ></img>

       
<p key={currentPreview}> <a href={imageService.PURCHASE_ORDER_ATTACHMENT_URL +
 currentPreview?.Purchase_Order_Ref_No 
                + currentPreview?.Image_Reference_No + '.' + 
                currentPreview?.Image_Format} target="_blank" alt=''>Click here to download file</a>.</p>
        </Box>
      </Modal>
    </div>
        
        </>
     );
}

export default DocumentViewer;