import { authenticationService } from "../services/authentication.service";


export function authHeader() {
    // return authorization header with jwt token
    const TOKEN =  authenticationService.getToken();
    if (authenticationService.isLoggedIn() &&   TOKEN) {
      return   {  headers: { Authorization: `Bearer ${TOKEN}` }};
        // return { Authorization: `Bearer ${TOKEN}` }
        // return  new Headers({
        //     'Authorization':  `Bearer ${TOKEN}`, 
        //     'Content-Type': 'application/json'
        // })
    } else {
        return {};
    }
}