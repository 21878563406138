import { useEffect } from "react";
import Userlayout from "../../layout/Userlayout";
import VoucherOfflineTable from "./VoucherOfflineTable";
import { voucherService } from "../../services/voucherService";
import { SetOfflineVoucherListAtom, GetOfflineVoucherListAtom } from "../../store/voucherAtom";
import { Link } from "react-router-dom";
import { useNavigate, useParams, useLocation } from "react-router-dom";




function VoucherOfflinePage() {
  const setVoucherList =  SetOfflineVoucherListAtom();
  const getVoucherList =  GetOfflineVoucherListAtom();
  const navigate = useNavigate();
  const { pathname } = useLocation();  
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  

  useEffect(()=> {
    // voucherService.getVoucherOffline().then( res => {
    //   console.log('Offline ', res.data);
    //   if(res.data?.length){
    //     setVoucherList(res.data);

    //   }
    // });


  },[]);

  const createNewVoucher = () => {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
  }



  return (
      <>
      <div className=" b">
      <section className="">
       
     
   
     <div className="flex justify-end w-full  "  >
          <button onClick={()=> createNewVoucher()}
                style={{ backgroundColor: "#476EF8" }}
                className="  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
              >
                New Redeem
              </button>
          </div>

     
        
        <VoucherOfflineTable list={getVoucherList} ></VoucherOfflineTable>
      </section>
    </div>
     
      </>
  
  );
}

export default VoucherOfflinePage;
