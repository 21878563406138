
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useForm } from "react-hook-form";
import { Container } from '@mui/material';
import EarnContentTable from './EarnContentTable';
import EarnContentSelect from './EarnContentSelect';
import { rewardService } from '../../services/Reward.service';

import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { useContext, useEffect, useState } from 'react';
import LogicContext from "../../Context/LogicContext";
import FAQTable from './FAQTable';
import FAQModal from './FAQModal';
import { GetRewardCenterList, SetRewardCenterList } from '../../store/rewardCenterAtom';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function FAQContent(props) {
  const {register, setValue, getValues, handleSubmit,control,reset,formState: { errors }} = useForm();
  const myPromiseModal = createModal(GeneralModal);
  const logicContext = useContext(LogicContext);
  const getRewardList = GetRewardCenterList();
  const setRewardList = SetRewardCenterList();
  const [tableList, setTableList] = useState([]);



  useEffect(()=> {
    if(getRewardList.faq_info.length){
      setTableList(getRewardList.faq_info);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[getRewardList.faq_info.length, getRewardList]);



  const onSubmit = async ()=> {
    await myPromiseModal({title: `Confirmation!`, body: `The following record will be saved! `
      ,showInput: false, placeHolder: ''}).then((res) => {
        if(res?.decision === true){
          const formValues =  getValues();
          props.submitFAQContent(formValues);
         

        }


  });
  

  }


  const changeFaqStatus = (decision, data) => {
    const newStatus = (decision === true) ? 1: 2;
    const newUpdate = {ref_no: data.Ref_No,question: data?.Question, 
      answer: data?.Answer, status_id: newStatus};
    rewardService.updateFaqContent(newUpdate).then((res)=> {
      const filteredData = logicContext.httpResponseManager(res?.data[0]);
      if(filteredData?.Message_Id == 1 || filteredData?.message_id == 1){
        logicContext.showToast(filteredData?.Message_Text || filteredData?.message_text, 'success');
        reloadMainData();
      }
    });
  }




  function reloadMainData(){
    rewardService.getEarnContent().then((response)=> {
      if(response.data.earn_info?.length){
        setRewardList(response.data);
      }
    });
  }


  const onStatusSelectValue = (val)=> {
    if(val === 0){
      return setTableList(getRewardList.faq_info);
    }else{
      let newList = getRewardList.faq_info.filter((item)=> item.Status_Id === val);
      setTableList(newList);
    }
  }




  const syncTableSequence = async (tableData)=> {
    await myPromiseModal({ title: `Confirm Action`, body:'The following action will sync table sequence',
    showInput:false, placeHolder:''}).then((res) => {
      if (res.decision === true) {
        let xml = '';
        let index  = 1;
        tableData.forEach(element => {
                xml +=  `<a><ref_no>${element?.Ref_No} </ref_no><dis_seq>${index} </dis_seq></a>`;
                index += 1;
        });

        rewardService.updateRewardTableSequence({xml_reward: `<root>${xml}</root>`, option: 9 }).then((res)=> {
          const filteredData = logicContext.httpResponseManager(res?.data[0]);
          if(filteredData?.Message_Id == 1 || filteredData?.message_id == 1){
            logicContext.showToast(filteredData?.Message_Text || filteredData?.message_text, 'success');
              reloadMainData();
          }
        });
 

      }});

  }


    return (
        <>
       <FAQModal  submitFAQContent={(formData)=> props.submitFAQContent(formData)} />

        <Container className='mt-12'>
        <div class="d-flex flex-row-reverse bd-highlight">
          <EarnContentSelect onSelectValue={onStatusSelectValue}  />
    
      </div>
            <FAQTable syncTableSequence={syncTableSequence}  changeFaqStatus={changeFaqStatus}  tableDataList={ tableList} />
        </Container>
   
        </>


    );
}

export default FAQContent;


