import { Route, Routes } from "react-router-dom";
import Userlayout from "../layout/Userlayout";
import NotFoundPage from "../pages/NotFoundPage";
import { PrivateRoute } from "./PrivateRoutes";


import SettingsDashBoard from "../pages/home_page_feature/SettingsDashboard";
import HomePageFeaturePage from "../pages/home_page_feature/HomePageFeaturePage";
import HomePageFeatureDetails from "../pages/home_page_feature/HomePageFeatureDetails";
import AddHomePageFeature from "../pages/home_page_feature/AddHomePageFeature";
import FeatureActionUrlMainPage from "../pages/feature_action_url/FeatureActionUrlMainPage";
import FeatureAddActionUrlPage from "../pages/feature_action_url/FeatureAddActionUrlPage";
import FeatureActionUrlDetailsPage from "../pages/feature_action_url/FeatureActionUrlDetails";
import AddsSettingMainPage from "../pages/ads-settings/AddsSettingMainPage";
import AddsSettingDetails from "../pages/ads-settings/AddsSettingDetailsPage";
import NewAdsPage from "../pages/ads-settings/NewAdsPage";
import ProductAddActionUrlPage from "../pages/product_action-url/ProductAddActionUrlPage";
import ProductionActionUrlMainPage from "../pages/product_action-url/ProductActionUrlMainPage";
import ProductActionUrlDetailsPage from "../pages/product_action-url/ProductActionUrlDetails";
import ProductFeaturePage from "../pages/product_feature/ProductFeaturePage";
import AddProductFeature from "../pages/product_feature/AddProductFeature";
import ProductFeatureDetail from "../pages/product_feature/ProductFeatureDetail";

function SettingsRoutes() {
    return ( 
        <>
      <Userlayout>
        <Routes>
          <Route exact  path='/dashboard' element={
                <PrivateRoute>  <SettingsDashBoard /> </PrivateRoute>}>
          </Route>
        
            {/* Home page features */}
            <Route  path='/home-page-feature' element={
                  <PrivateRoute>  <HomePageFeaturePage /> </PrivateRoute>}>
            </Route>
            <Route  path='/home-page-feature/add-new' element={
                  <PrivateRoute>  <AddHomePageFeature /> </PrivateRoute>}>
            </Route>
            <Route  path='/home-page-feature/details/:id' element={
                  <PrivateRoute>  <HomePageFeatureDetails /> </PrivateRoute>}>
            </Route>
            {/* Home page feature end */}

            {/* Grocedy Action url */}
            <Route  path='/feature-action-url'  element={
                  <PrivateRoute>  <FeatureActionUrlMainPage /> </PrivateRoute>}>
            </Route>

            <Route  path='/feature-action-url/add-new' element={
                  <PrivateRoute>  <FeatureAddActionUrlPage /> </PrivateRoute>}>
            </Route>

            <Route  path='/feature-action-url/details/:id' element={
                  <PrivateRoute>  <FeatureActionUrlDetailsPage />  </PrivateRoute>}>
            </Route>
            {/* Grocedy Action url end */}


            {/* Setting page */}
            <Route  path='/ad-settings'  element={
                  <PrivateRoute>  <AddsSettingMainPage /> </PrivateRoute>}>
            </Route>
            <Route  path='/ad-settings/details/:id'  element={
                  <PrivateRoute>  <AddsSettingDetails /> </PrivateRoute>}>
            </Route>
            <Route  path='/ad-settings/add-new'  element={
                  <PrivateRoute>  <NewAdsPage /> </PrivateRoute>}>
            </Route>
            {/* End setting routes */}

      
            {/* Product Action Url */}
            <Route  path='/product-feature-action-url' element={
                  <PrivateRoute>  <ProductionActionUrlMainPage /> </PrivateRoute>}>
            </Route>

            <Route  path='/product-feature-action-url/add-new'  element={
                  <PrivateRoute>  <ProductAddActionUrlPage /> </PrivateRoute>}>
            </Route>

            <Route  path='/product-feature-action-url/details/:id'  element={
                  <PrivateRoute>  <ProductActionUrlDetailsPage /> </PrivateRoute>}>
            </Route>
            {/* Product Action Url End */}


            {/* Product feature */}
            <Route  path='/product-category-feature' element={
                  <PrivateRoute>  <ProductFeaturePage /> </PrivateRoute>}>
            </Route>
            <Route  path='/product-category-feature/add-new'  element={
                  <PrivateRoute>  <AddProductFeature /> </PrivateRoute>}>
            </Route>
            <Route  path='/product-category-feature/details/:id'  element={
                  <PrivateRoute>  <ProductFeatureDetail /> </PrivateRoute>}>
            </Route>



        <Route path="/*" element={<NotFoundPage />}></Route>
        </Routes>
        </Userlayout>

        </>
     );
}

export default SettingsRoutes;