import { DataGrid ,  gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport ,
    useGridApiContext,
    useGridSelector} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';
  import  {GetFinishItems} from '../store/finishItemAtom';
  import { Link, useLocation, useNavigate } from "react-router-dom";
  import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import DeleteIcon from "@mui/icons-material/Delete";
  import { Table } from "react-bootstrap";



      function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarExport />
          </GridToolbarContainer>
        );
      }



function AddCompositTable(props) {
  // const { pathname } = useLocation();
  // const navigate = useNavigate();

  // var url = pathname.split("/");
  // const currModuleId = url[1];
  // const currModuleName = url[2];
  // const currUserMenu = url[3];


  function deleteItem(item) {
    props.deleteItemFromTable(item)
  }

  function quantityChange(newQty, item) {
    props.modifyQuatity(newQty, item);
  }






    return ( 
        <>

<TableContainer className="mt-3 mb-5">
          <Table sx={{  }} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#F1F1F1" }}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {props?.tableData?.map((item, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{item?.Item_Id} </TableCell>
                    <TableCell align="left">{item?.Item_Name} </TableCell>
                    <TableCell align="left">{item?.Item_Sku} </TableCell>
                    <TableCell align="left"> {item?.Item_Category_Name}{" "} </TableCell>
                    <TableCell align="left"> {item?.Sales_Rate}{" "} </TableCell>
                    <TableCell align="left">  {" "}
                      <input
                        type="number"
                        style={{ width: "70px" }}
                        onChange={(e) => quantityChange(e.target.value, item)}
                        value={item?.Item_Quantity}
                      />{" "}
                    </TableCell>
                    <TableCell align="left"> {item?.Sales_Rate  *item?.Item_Quantity }{" "} </TableCell>
                    <TableCell align="left" onClick={() => deleteItem(item)}>
                      {" "}
                      <DeleteIcon color="primary" />{" "}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        
        </>
     );
}

export default AddCompositTable;
