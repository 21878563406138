import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function SubscriptionGiftDetails(props) {
    const {giftPerson} = props;

    return ( 

    <div>
    <Accordion style={{width:'350px'}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className="text-muted">Gifted Contacts  <br />
        <div>Name: <span className='font-bold'>{giftPerson?.Gift_Person_Name} </span></div>
         </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        <div className="">
                     
                        <div className="pl-3" >
                          
                          <div>Email: <span className='font-bold'> {giftPerson?.Gift_Person_Email_Address}</span> </div>
                          <div>Phone: <span className='font-bold'>{giftPerson?.Gift_Person_Mobile_Number}</span> </div>
                         
                          <div>Address: <span className='font-bold'>{giftPerson?.Gift_Person_Address} </span> </div>
                          <div>Area: <span className='font-bold'>{giftPerson?.Gift_Person_Area_Name} </span>  </div>
                          <div>City: <span className='font-bold'>{giftPerson?.Gift_Person_City_Name}  </span> </div>
                          <div>Country: <span className='font-bold'>{giftPerson?.Gift_Person_Country_Name} </span> </div>
                          
                        </div> 
                      </div>
        </Typography>
      </AccordionDetails>
    </Accordion>
  
  </div>
     );
}

export default SubscriptionGiftDetails;