import Select from "react-select";

function CustomerHistorySelect(props) {
  const customOption = props?.optionList.map((item) => {
    return { ...item, label: `${item.Transaction_Type_Name}` };
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 34,
      backgroundColor: "#FFFFFF",
      width: 200,
    }),
    indicatorSeparator: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
    valueContainer: (styles) => ({
      ...styles,
      backgroundColor: "",
      height: 20,
      paddingBottom: "40px",
      marginLeft: "13px",
    }),
    option: (styles) => ({
      ...styles,
      backgroundColor: "#F1F1F1",
      color: "black",
    }),
  };

  const handleChange = (value) => {
    props.onTypeSelect(value);
  };

  return (
    <>
      <Select
        styles={customStyles}
        //   defaultValue={props?.defaultVal}
        placeholder="Select Type"
        onChange={handleChange}
        options={customOption}
        className=""
      />
    </>
  );
}

export default CustomerHistorySelect;
