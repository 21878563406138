

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


const salesOrderList = atom({key:'salesOrderList', default:{}});
const salesOrderEntryData = atom({key:'salesOrderEntryData', default:{}});
const editSalesOrder = atom({key:'editSaleOrder', default:{}});



export  const SetSalesOrderList = () => useSetRecoilState(salesOrderList);
export  const GetSalesOrderList = () => useRecoilValue(salesOrderList);

export  const SetEntryData = () => useSetRecoilState(salesOrderEntryData);
export  const GetEntryData = () => useRecoilValue(salesOrderEntryData);

export  const SetEditSalesOrder = () => useSetRecoilState(editSalesOrder);
export  const GetEditSalesOrder = () => useRecoilValue(editSalesOrder);
export const ResetEditSalesOrder = ()=> useResetRecoilState(editSalesOrder);






