


import {Fragment, useEffect, useRef, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { SetCarrierBagStoreList, GetCarrierBagStoreList } from "../../store/carrierBagReturn";
import { rewardService } from "../../services/Reward.service";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";




function CarrierBagReturnSearch() {

const itemAtomList = GetCarrierBagStoreList();
const setIReturnAtomList = SetCarrierBagStoreList();
    const [searchResult, setSearchResult] = useState([]);
    const [tempList, setTempList] = useState([]);
    const [textInput, setTextInput] = useState('');
    const navigate = useNavigate();
    const mountedRef = useRef(true);

    const { pathname } = useLocation(); 
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];



    useEffect(()=> {
      mountedRef.current =  true;
      if(itemAtomList?.length > 0){
        setTempList(itemAtomList);
      }else{
        rewardService.getCarrierBagList().then( response => {
            if(response?.data?.length){
              setIReturnAtomList(response?.data);
            }
        });
      }

      return ()=> { 
        mountedRef.current = false;
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[itemAtomList])



    function itemSearchChange (search: string) {
        setTextInput(search)

        if (search.length <  2) {
          setSearchResult([])
        }else{
          var tempResult = tempList?.filter((item: any)=>{
            return   item?.Customer_Full_Name?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
            ||  item?.Customer_Mobile_No?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
            ||  item?.Customer_Email_Address?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
            ||  item?.Ref_No?.toString()?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
          });
  
          setSearchResult(tempResult);
        }
     

    }



    function selectedOption(selection: any){
        const {Ref_No} = selection;
        setSearchResult([]);
        setTextInput('')
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${Ref_No}`);
    }


    return (  

        <>
              <Combobox  >
        <div className="relative">
          <div className="relative border-[1px] border-gray-200 rounded-md">
            <Combobox.Input
              className="w-full z-40 border-none py-2 pl-10 text-sm leading-5 text-gray-700 max-w-[350px] h-[40px] bg-white rounded-md overflow-hidden ring-0 focus:ring-1 focus:ring-[#1976D2]"
              // displayValue={(person) => person.name}
              placeholder="Search in Chart of Account"
              onChange={(event) => itemSearchChange(event.target.value)}
              // onChange={(event) => setTextInput(event.target.value)}
            />
            <Combobox.Button className="absolute top-2 left-2.5 z-50">
              {/* <img
                src={MagnifyingGlass}
                alt="search_icon"
                className="h-6 w-6"
              /> */}
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setTextInput("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {searchResult.length === 0 && textInput !== "" ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                searchResult.map((bag: any,  i: number) => (
                  <Combobox.Option
                    key={i}
                    onClick={()=> selectedOption(bag)}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                        active ? "bg-gray-100 text-white" : "text-gray-700"
                      }`
                    }
                    value={bag}
                  >
                    {({ selected, active }) => (
                      <>
                        <span  className={` ${ selected ? "font-medium" : "font-normal" }`} >
                          {bag.Invoice_No} |{bag?.Customer_Full_Name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-gray-700"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
        </>
    );
}

export default CarrierBagReturnSearch;