import { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import {
  GetTableDataList,
  GetTableFilter,
} from "../../store/paymentReceivedAtom";
import { Grid } from "@mui/material";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import { useParams } from "react-router-dom";
import { accountService } from "../../services/accountService";
import { twMerge } from "tailwind-merge";

function PaymentReceiveScroll(props) {
  const getPaymentList = GetTableDataList();

  const getTableFilter = GetTableFilter();

  const mountRef = useRef(true);

  const [tableData, setTableData] = useState([]);

  const { payment_ref_id } = useParams();

  useEffect(() => {
    mountRef.current = true;
    setTableData(
      getPaymentList.map((ele) => {
        return { ...ele, checked: false };
      })
    );

    return () => {
      mountRef.current = false;
    };
  }, [getPaymentList]);

  const onSelectBulkAction = (action) => {
    const option = action.target.value;
    if (option === "") {
      return setTableData(getPaymentList);
    }
    var newList = getPaymentList.filter(
      (item) => item.Payment_Status_Id === option
    );
    setTableData(newList);
  };

  const onCheckAll = async (e, index) => {
    let element = tableData[index];
    tableData[index] = { ...element, checked: e.target.checked };
    await setTableData((prev) => {
      return [...tableData];
    });
    props?.onSelection(tableData);
  };

  const manageInvoiceStatus = (status) => {
    let defaultClass = "text-primary";

    switch (status) {
      case "Failure by Online":
        defaultClass = "text-danger";
        break;
      case "Collected by Offline":
        defaultClass = "text-success";
        break;
      case "Pending For Approval":
        defaultClass = "text-warning";
        break;
      case "Collected by Online":
        defaultClass = "text-info";
        break;

      default:
        break;
    }

    return (
      <span className={`text-sm font-bold md:text-sm ${defaultClass} `}>
        {status}
      </span>
    );
  };

  return (
    <>
      <section className="border-[1px] rounded-lg bg-white border-gray-200 flex flex-col overflow-y-scroll h-[300px] lg:h-[1000px] scrollbar-thumb-gray-500 scrollbar-thin scrollbar-track-gray-100 pb-[100px]">
        <div className="border-b-[1px] border-b-gray-300">
          <ActionPaneComponent
            bulkActionData={getTableFilter}
            onSelectBulkAction={onSelectBulkAction}
          />
        </div>
        {tableData.map((payment, i) => {
          return (
            <div
              key={i}
              className={`p-2 lg:p-1 grid grid-cols-2 lg:grid-cols-none  border-b-[1px] cursor-pointer border-gray-200 ${
                payment_ref_id == payment?.Payment_Ref_No
                  ? "bg-gray-200"
                  : "hover:bg-gray-100"
              }`}
            >
              <div className="flex flex-row">
                <div className="ml-1">
                  <input
                    onChange={(e) => onCheckAll(e, i)}
                    checked={payment?.checked ? payment?.checked : ""}
                    type="checkbox"
                    className="w-4 h-4 border-[1px] border-gray-300 rounded-sm cursor-pointer"
                  />
                </div>

                <div
                  onClick={() => props.viewItemDetails(payment.Payment_Ref_No)}
                  className="ml-1"
                >
                  <div className="ml-2 ">
                    <div className="text-sm font-bold text-gray-700">
                      {payment?.Customer_First_Name}
                    </div>
                    <div className="text-xs text-gray-400 font-semibold italic">
                      {payment?.Payment_Ref_No}
                    </div>

                    <div className="text-sm lg:text-xs text-gray-600">
                      {moment(payment?.Payment_Date_String).format(
                        "DD-MM-YYYY"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:mt-2 lg:ml-5 ">
                <div
                  onClick={() => props.viewItemDetails(payment.Payment_Ref_No)}
                  className={twMerge("text-sm font-normal")}
                >
                  {manageInvoiceStatus(payment?.Payment_Status_Name)}
                </div>

                <div className="mt-[2px] text-sm lg:text-xs font-bold text-gray-700">
                  <CurrencyFormat
                    value={parseFloat(payment?.Payment_Amount)?.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
}

export default PaymentReceiveScroll;
