import React, { useEffect } from "react";
import Switch from "@mui/material/Switch";

function CustomerToggle(props) {
  // const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    console.log("props ", props?.val);
    if (props?.val === "A") {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [props?.val]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.toogleItemStatus(event.target.checked);
  };

  return (
    <>
      <div className="flex flex-row items-center space-x-2">
        <div className="mt-2.5 flex flex-row items-center space-x-3">
          <p className="font-semibold text-gray-700"> Status :</p>
          <p
            className={`${
              props?.val === "A" ? "text-green-500" : "text-red-500"
            } font-medium`}
          >
            {props?.val === "A" ? "Active" : "InActive"}
          </p>
        </div>
        <Switch checked={checked} onChange={handleChange} />
      </div>
    </>
  );
}

export default CustomerToggle;
