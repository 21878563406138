

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


const vendorListMasterData = atom({key:'vendorListMasterData', default:{}});
const vendorEntryMasterData = atom({key:'vendorEntryMasterdata', default:{}});
const editAbleVendorAtom = atom({key:'vendorEdit', default:{}});



export  const SetVendorListData = () => useSetRecoilState(vendorListMasterData);
export  const GetVendorListData = () => useRecoilValue(vendorListMasterData);

export  const SetEntryData = () => useSetRecoilState(vendorEntryMasterData);
export  const GetEntryData = () => useRecoilValue(vendorEntryMasterData);

export  const SetVendorEdit = () => useSetRecoilState(editAbleVendorAtom);
export  const GetVendorEdit = () => useRecoilValue(editAbleVendorAtom);
export const ResetVendorEdit = ()=> useResetRecoilState(editAbleVendorAtom);





