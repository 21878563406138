// import { DataGrid ,  gridPageCountSelector,
//     gridPageSelector, GridToolbarContainer, GridToolbarExport ,
//     useGridApiContext,
//     useGridSelector} from "@mui/x-data-grid";
import { DataGrid ,  gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport ,
    useGridApiContext,
    useGridSelector} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';
//   import { GetRecoilCorporateList, SetRecoilCorporateList } from "../store/corporateAtom";
  import { useEffect, useState } from "react";
  import moment from "moment/moment";
  import {  useLocation, useNavigate } from "react-router-dom";
  import Paper from '@mui/material/Paper';
  import { Box } from '@mui/material';




function CustomPagination() {
    const apiRef = useGridApiContext();
  
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
  
    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }


  const TableHead = [
    { field: 'Customer_Full_Name', headerName:'Name' , width: 195 , headerClassName: "tableHeader "},
    { field: 'Customer_Email_Address', headerName:'Email' , width: 195 , headerClassName: "tableHeader "},
    { field: 'Customer_Mobile_No', headerName:'Mobile Number' , width: 195 , headerClassName: "tableHeader "},
    { field: 'Corporate_Short_Code', headerName:'Corporate Name' , width: 195 , headerClassName: "tableHeader "},
    { field: 'Customer_Approve_Status_Name', headerName:'Status' , width: 195 , headerClassName: "tableHeader ", cellClassName:"font-bold"},
     { field: 'Encoded_Date', headerName:'Created Date' , width: 195 , headerClassName: "tableHeader "},
  
    ]
  

  function CustomerApprovedTable(props) {


    const [tableList, setTableList] = useState([]);
          const navigate = useNavigate();
          const { pathname } = useLocation();  
          var url = pathname.split('/')
          const currModuleId = url[1];
          const currModuleName = url[2];
          const currUserMenu = url[3];
  
  
    useEffect(()=> {
      let isMount = true;
    
        // let newTableHead = props?.header?.filter((item) => item.Display_Id === 1).map((item) => {
        //   return {
        //     ...item,field: item.Column_Value.trim(),headerName: item.Column_Name, width: 150 };
        // }).sort((a, b) => {
        //   return a.Print_Sequence - b.Print_Sequence;
        // });
       if(isMount)  {
        // setTableHeadHead(newTableHead)
       };
    
      
  
      // set table list
      var list = props?.tableList?.map((item, i) => {
        return { ...item, id: i, Encoded_Date:  moment(item.Encoded_Date).format('llll')  } });
           setTableList(list?.reverse());
      
    
      return ()=> {
        isMount = false;
      }
  
    },[ props?.tableList]);
  
  
  
    function OnItemCellClick(event){
      const id = event.row['Customer_Id'];
      navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`)
    }
    
    
    function onSelectChange(selectedList) {
      // var selection = selectedList?.map((index) => {
      //   return props.tableData[index];
      // });
      // props.selectedTableItems(selection);
    }
  
  return (
  
    <>
  <div className="mt-5">
  {/* style={{ height: 450, width: '100%' }} */}
  <Box style={{  width: '100%' }}  >

  <div className="table-responsive"  >
  <DataGrid
    columns={TableHead}
    rows={tableList}
    components={{ Pagination: CustomPagination}}
    onRowSelectionModelChange={onSelectChange}
    onRowClick={(event)=> {  OnItemCellClick(event) }}
    sx={{ borderColor: 'primary.light',
        '& .MuiDataGrid-cell:hover': {   color: 'primary.main', cursor: 'pointer' }  }} 
  />
  </div>
  </Box>
  </div>
    
    </>
  );
  }
  
  export default CustomerApprovedTable;