
import {
    DataGrid, gridPageCountSelector,GridToolbar,
    gridPageSelector, GridToolbarContainer, GridToolbarExport,
    useGridApiContext,
    useGridSelector
} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';
//   import { GetRecoilCorporateList, SetRecoilCorporateList } from "../store/corporateAtom";
  import { useEffect, useState } from "react";
  import moment from "moment/moment";
  import {  useLocation, useNavigate } from "react-router-dom";
  import Paper from '@mui/material/Paper';
  import { Box } from '@mui/material';

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            // @ts-expect-error
            rowsPerPageOptions={[6]}
            checkboxSelection={true}
            components={{
                Toolbar: CustomToolbar,
                Pagination: CustomPagination,
              }}
            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}

  const TableHead = [
    { field: 'Ref_No', headerName:'Ref. No' , width: 100 , headerClassName: "tableHeader "},
    { field: 'Transaction_Date', headerName:'Transaction Date' , width: 195 , headerClassName: "tableHeader "},
    { field: 'Customer_Full_Name', headerName:'Customer Name' , width: 195 , headerClassName: "tableHeader "},
    { field: 'Customer_Email_Address', headerName:'Customer Email Address' , width: 195 , headerClassName: "tableHeader "},
    { field: 'Customer_Mobile_No', headerName:'Mobile Number' , width: 195 , headerClassName: "tableHeader ", cellClassName:"font-bold"},
     { field: 'Reward_Cowries_Quantity', headerName:'Reward Cowries' , width: 150 , headerClassName: "tableHeader "},
     { field: 'Reward_Cowries_Amount', headerName:'Amount' , width: 195 , headerClassName: "tableHeader "},
     { field: '', headerName:'Remark' , width: 195 , headerClassName: "tableHeader "},
  
    ]
  

  function RewardHistoryTable(props) {


    const [tableList, setTableList] = useState([]);
          const navigate = useNavigate();
          const [tableHead, setTableHeadHead] = useState([]);
          const { pathname } = useLocation();  
          var url = pathname.split('/')
          const currModuleId = url[1];
          const currModuleName = url[2];
          const currUserMenu = url[3];
  
  
    useEffect(()=> {
      let isMount = true;
    
        let newTableHead = props?.header?.filter((item) => item.Display_Id === 1).map((item) => {
          return {
            ...item,field: item.Column_Value.trim(),headerName: item.Column_Name, width: 150 };
        }).sort((a, b) => {
          return a.Print_Sequence - b.Print_Sequence;
        });
       if(isMount)  {
        setTableHeadHead(newTableHead)
       };
    
      
  
      // set table list
      var list = props?.tableList?.map((item, i) => {
        return { ...item, id: i, Transaction_Date:  moment(item.Transaction_Date).format('llll')  } });
           setTableList(list?.reverse());
      
    
      return ()=> {
        isMount = false;
      }
  
    },[ props?.tableList]);
  
  
  
    function OnItemCellClick(event){
      const id = event.row['Ref_No'];
      navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`)
    }
    
    
    function onSelectChange(selectedList) {
      // var selection = selectedList?.map((index) => {
      //   return props.tableData[index];
      // });
      // props.selectedTableItems(selection);
    }
  
  return (
  
    <>
  <div className="mt-5">
  {/* style={{ height: 450, width: '100%' }} */}
  <Box style={{  width: '100%' }}  >

  <div className="table-responsive"  >
  <DataGrid
    columns={TableHead}
    rows={tableList}
    pageSize={6}
    rowsPerPageOptions={[6]}
    components={{ Pagination: CustomPagination}}
    onRowSelectionModelChange={onSelectChange}
    onRowClick={(event)=> {  OnItemCellClick(event) }}
    sx={{ borderColor: 'primary.light',
        '& .MuiDataGrid-cell:hover': {   color: 'primary.main', cursor: 'pointer' }  }} 
        slots={{ toolbar: GridToolbar }}
        slotProps={{  toolbar: {  showQuickFilter: true, } }}
  />
  </div>
  </Box>
  </div>
    
    </>
  );
  }
  
  export default RewardHistoryTable;