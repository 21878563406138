import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import React from "react";
import stlogo from "../../assets/statmlogo.png";
// import "./CustomerIStatment.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function VendorStatement() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Item>
          <section className=" ">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Item>
                  <div className="mt-2 border-2  py-1 px-2">
                    <img className="w-30" src={stlogo} alt="" />
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  {" "}
                  <section className="mt-1 grid grid-cols-2 border-2  py-3 px-3 ">
                    <section>
                      <h6 className="heading font-bold">To:</h6>
                      <h6 className="subheading">
                        A. Arogundade
                      </h6>
                      <h6 style={{ color: "#476EF8" }} className="subheading  text-filterTextColor">Address</h6>
                      <h6 className="subheading">
                        No 20,Ishiba Street, Orelope, Egbeda
                      </h6>
                      <h6 className="subheading">Carwash Bus-Stop</h6>
                      <h6 className="subheading">Dopemu</h6>
                      <h6 className="subheading"> Lagos</h6>
                      <h6 className="subheading">Nigeria</h6>
                    </section>

                    <section className=" flex justify-end ">
                      <div>
                        <div>
                          {" "}
                          <h6 style={{ color: "#476EF8" }} className="subheading text-filterTextColor">
                            {" "}
                            Worldbay Technologies Ltd
                          </h6>
                          <h6 className="subheading  text-filterTextColor">
                            <span className="font-bold subheading ">TIN :</span> 16311833-0001
                          </h6>
                          <h6 className="subheading  text-filterTextColor">
                          <span className="font-bold subheading ">VAT :</span> YBV060021065153
                          </h6>
                        </div>

                        <div className="">
                          <h6 className="subheading  text-filterTextColor">
                            <span className="font-bold subheading ">RC :</span> 1065153
                          </h6>
                          <h6 style={{ color: "#476EF8" }} className="subheading  text-filterTextColor">Address</h6>
                          <h6 className="subheading  text-filterTextColor">
                            Plot 4b Chief Augustine Anozie Street
                          </h6>
                          <h6 className="subheading  text-filterTextColor">
                            Lekki Phase 1 Lagos{" "}
                          </h6>
                          <h6 className="subheading  text-filterTextColor">
                            Nigeria
                          </h6>
                        </div>
                      </div>
                    </section>
                    <section></section>
                  </section>
                </Item>
              </Grid>

              <Grid item xs={12}>
                <Item>
                  {" "}
                  {/* ACCOUNT SUMMARY  */}
                  <section className="mt-1 border-2  py-1 px-3 ">
                    <section className="mt-1  py-1 px-3">
                      <span className="text-lg   font-bold text-filterTextColor">
                        Statement of Accounts
                      </span>{" "}
                      <br></br>
                      <span className="text-xs  text-filterTextColor">
                        01.03.2022 To 31.03.2022
                      </span>
                    </section>

                    <section className="flex  py-3 w-full">
                    <section
                      // style={{ backgroundColor: "#476EF8" }}
                      className=" drop-shadow-lg  w-45 py-3 rounded  bg-blue-300"
                    >   <section className="pt-2">
                    <div className="ml-4 w-full">
                      <h6
                        style={{ color: "#476EF8" }}
                        className="font-bold text-xs "
                      >
                        Account Summary
                      </h6>
                      <div className="mt-3 w-full">
                        <div className="flex w-full ">
                          <h6 className="text-xs">Opening Balance</h6>
                          <h6 className="text-xs ml-20">₦ 590,000.00</h6>
                        </div>
                        <div className="flex">
                          <h6 className="text-xs">Opening Balance</h6>
                          <h6 className="text-xs ml-20">₦ 590,000.00</h6>
                        </div>
                        <div className="flex">
                          <h6 className="text-xs">Opening Balance</h6>
                          <h6 className="text-xs ml-20">₦ 590,000.00</h6>
                        </div>
                        <div className="flex">
                          <h6 className="text-xs">Opening Balance</h6>
                          <h6 className="text-xs ml-20">₦ 590,000.00</h6>
                        </div>
                      </div>
                    </div>
                  </section>
                  
                  
                  </section>

                    </section>
                   

                 

                    {/* TABLE */}
                 
                  </section>
                </Item>
              </Grid>

              <Grid item xs ={12}>
              <section className=" border-2  py-1 px-3 mx-2">
                      {/* TABLE */}
                      <TableContainer className="">
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <colgroup>
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "15%" }} />
                            <col style={{ width: "20%" }} />
                            <col style={{ width: "20%" }} />
                            <col style={{ width: "20%" }} />
                            <col style={{ width: "20%" }} />
                          </colgroup>
                          <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                            <TableRow>
                              <TableCell align="center">Date</TableCell>
                              <TableCell align="center">Invoice</TableCell>
                              <TableCell align="center">Order Number</TableCell>
                              <TableCell align="center">Amount</TableCell>
                              <TableCell align="center">Balance Due</TableCell>
                              <TableCell align="center">Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row"></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left"></TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </section>
              </Grid>
            </Grid>
          </section>
        </Item>
      </Grid>
    </Grid>
  );
}

export default VendorStatement;
