import React, { useState } from 'react'
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import  DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "react-bootstrap";
import AlertConfirm from '../helpers/AlertConfirm';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import  moment from 'moment';

     


function HistoryProductionTable(props) {
  const [alertState, setAlertState] = useState(false);
  const [tempData, setTempData] = useState({});




  function closeAlert (response){
    setAlertState(false)
    if(response === true){
      props.modifyProduction(tempData);
      setTempData({})
    }else{
      setTempData({})
    }
  }



  function editProduction(production){
    setTempData(production);
    setAlertState(true);
  }


  return (
    <>
     {alertState ?  <AlertConfirm title={'Confirmation!'} body={'Do you want to modify selected item?'}
      closeAlert={closeAlert}></AlertConfirm> : null }
    <div className="">

    <TableContainer className="mt-3">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                  <TableRow>
                    <TableCell>Batch No.</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Warehouse</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Created By</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  
                  </TableRow>
                </TableHead>

               
                <TableBody>
                {props?.tableData?.map((item, i)=> {
                          return (
                  <TableRow key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }} >
                                <TableCell align="left">{item.Ref_No} </TableCell>
                             <TableCell align="left">{ item?.Production_Date}  </TableCell>
                            <TableCell align="left">{item.Warehouse_Name} </TableCell>
                            <TableCell align="left">{item.Production_Quantity} </TableCell>
                            <TableCell align="left">{item.Encoded_By_Name} </TableCell>
                            <TableCell align="left">{item.Status_Name} </TableCell>
                            <TableCell align="left">
                            <span >
                            <EditIcon  onClick={()=> editProduction(item)}  color="primary" />
                            <PrintIcon color="primary" />
                            </span>
                              
                               </TableCell>
                    
                            
                  </TableRow>
                           )
                        })}
                </TableBody>
       
              </Table>
            </TableContainer>
    
    {/* <div  style={{ height: 450, width: '100%' }}>
          <DataGrid
            columns={TableHead}
            rows={props?.tableData}
            components={{
              Pagination: CustomPagination,
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div> */}
    </div>
            
            </>
  )
}

export default HistoryProductionTable