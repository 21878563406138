

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


const chartTableHeader = atom({key:'chartTableHeader', default:[]});
const chartTableData = atom({key:'chartTableData', default:[]});
const chartTableFilter = atom({key:'chartTableFilter', default:[]});
const entryMasterData = atom({key:'entryMasterData', default:{}});
const chartOfAccountEdit = atom({key:'chartOfAccountEdit', default:{}});

export  const SetChartList = () => useSetRecoilState(chartTableData);
export  const GetChartList = () => useRecoilValue(chartTableData);
export const ResetChartList = ()=> useResetRecoilState(chartTableData);

export  const SetChartEdit = () => useSetRecoilState(chartOfAccountEdit);
export  const GetChartEdit = () => useRecoilValue(chartOfAccountEdit);
export const ResetChartEdit = ()=> useResetRecoilState(chartOfAccountEdit);

export  const SetChartTableHead = () => useSetRecoilState(chartTableHeader);
export  const GetChartTableHead = () => useRecoilValue(chartTableHeader);


export  const SetChartTableFilter = () => useSetRecoilState(chartTableFilter);
export  const GetChartTableFilter = () => useRecoilValue(chartTableFilter);

export  const SetChartEntryMasterData = () => useSetRecoilState(entryMasterData);
export  const GetChartEntryMasterData = () => useRecoilValue(entryMasterData);



