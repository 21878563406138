
import Select from 'react-select';
import { GetProductMasterData} from '../store/productAtom';

const PlanTaxSelect = (props) => {
    const masterData = GetProductMasterData();



  const customOption = masterData[3]?.map((item)=> {
    return ({...item, label:`${item.Name}`})
  })

    const customStyles = {
        control: base => ({
          ...base,
          height: 54,
          backgroundColor:'#F1F1F1',
          width:320,
        }),
        indicatorSeparator: styles => ({ ...styles, display : 'none' }),
        placeholder: styles => ({ ...styles, display : 'none' }),
        valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"40px", marginLeft:"13px"}),
        option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
      }


    const handleChange = (value) => {
      props.onTaxSelect(value)
      };

    return ( 
        <>
          <Select  styles={customStyles} 
            defaultValue={props?.defaultVal}
           onChange={handleChange} options={customOption} />


        </>
     );
}
 
export default PlanTaxSelect;