import React, { useState } from 'react'
import { useEffect } from 'react';

function AddSubtypeSelect(props) {
  const [checked, setChecked] = useState(false); 

  useEffect(()=> {
    if(props.val === 1){
      setChecked(true);
    }else if(props.val === 2){
      setChecked(true);
    }
  },[props.val])


  const handleChange = (e) => {
    if(e.target.checked === true){
      setChecked(true);
      props?.onEmailSendSelect(1)
    }else{
      setChecked(false);
      props?.onEmailSendSelect(2)
    }
    // setGoodsChecked(false);
    // setServiceChecked(true);
    // props.addItemType(2);
  };


  


  return (
    <div className=" text-sm flex">
    <div className=" flex">
      <div>
        {" "}
        <input
         checked={checked}  onChange={handleChange}
          type="checkbox"
          className="w-[0.8vw] h-[1.6vh]"
          value="1"
        />
      </div>
      <div className="mt-[0.2vh]">
        {" "}
        <span className="text-xs ml-2">{props?.email} </span>
      </div>
    </div>
  
  </div>
  )
}

export default AddSubtypeSelect