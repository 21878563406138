// import { DataGrid ,  gridPageCountSelector,
//     gridPageSelector, GridToolbarContainer, GridToolbarExport ,
//     useGridApiContext,
//     useGridSelector} from "@mui/x-data-grid";
import { DataGrid ,  gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport ,
    useGridApiContext,
    useGridSelector} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';
  import moment from "moment";


function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }



    const TableHead = [
      { field: 'Ref_No', headerName:'Ref.No' , width: 150 , headerClassName: "tableHeader "},
        { field: 'Transaction_Date', headerName:'Date' , width: 200 , headerClassName: "tableHeader "},
        { field: 'Total_Transaction_Amount', headerName:'(₦) transaction Amount' , width: 200, headerClassName: "tableHeader font-weight-bold "},
        { field: 'Previous_Wallet_Balance', headerName:'(₦) Prev Balance' , width: 200 , headerClassName: "tableHeader "},
        { field: 'Current_Wallet_Balance', headerName:'(₦) Curr Balance' , width: 200 , headerClassName: "tableHeader  "},
        { field: 'Transaction_Type_Name', headerName:' Type' , width: 200 , headerClassName: "tableHeader "},
        { field: 'Transaction_Group', headerName:'Group' , width: 200 , headerClassName: "tableHeader "},
    
        ]

    
function WalletDetailsTable(props) {

return (

    <>
<div className="mt-5">
<div  style={{ height: 450, width: '100%' }}>
  <DataGrid
  className="tableHeader"
   pageSize={6}
   rowsPerPageOptions={[6]}
    columns={TableHead} 
    rows={props?.walletList.map((item, index) => { return {...item, id: index,
      Total_Transaction_Amount: '₦' + item.Total_Transaction_Amount?.toLocaleString(),
      Previous_Wallet_Balance:  '₦' + item.Previous_Wallet_Balance?.toLocaleString(),
      Current_Wallet_Balance:  '₦' + item.Current_Wallet_Balance?.toLocaleString(),
      Transaction_Date : moment(item?.Transaction_Date).format("DD/MM/YYYY, h:mm:ss a")}} )}
    components={{
      Pagination: CustomPagination,
    }} 
  />
</div>
</div>
    </>
  );
}

export default WalletDetailsTable;