import React, { useEffect, useRef, useState,useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";

import { Grid } from "@mui/material";
import { Dropdown } from "react-bootstrap";
// import VoucherScroll from "./VoucherScroll";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { voucherService } from "../../services/voucherService";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import LogicContext from "../../Context/LogicContext";
import { SetVoucherListAtom, GetVoucherListAtom } from "../../store/voucherAtom";





const OfflineDetailsPage = () => {
    const { id } = useParams();
    const [voucherDetails, setVoucherDetails] = useState({});
    const myPromiseModal = createModal(GeneralModal);
    const logicContext = useContext(LogicContext);
    const setVoucherAtomData =  SetVoucherListAtom();
    const getVoucherAtomData =  GetVoucherListAtom();

    const navigate = useNavigate();
    const { pathname } = useLocation();  
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];


    useEffect(() => {
        document.title = "Voucher Details Page";
        loadVoucherDetails(id);
   
        return ()=>{ 
            //code here
          }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);



      const loadVoucherDetails = (id) => {
        voucherService.getVoucherOfflineDetails(id).then(res => {
            console.log('OFFF ', res?.data);
            if(res?.data?.length){
                setVoucherDetails(res.data[0]);
            }
        });
      }


      const createNewVoucher = () => {
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
      }






    return (
        <>
                <section className="  mt-2 ">
                    <Grid container className="" spacing={2}>
                        <Grid item xs={12}>
                            <section className="max-auto max-w-6xl ">
                                <div className="flex flex-wrap justify-between  ">
                                    <button onClick={()=> createNewVoucher()}
                                        style={{ borderRadius: "5px" }}
                                        className=" bg-blue-600  px-3 h-[6vh]  text-white text-xs hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50 ">
                                        ADD NEW
                                    </button>
                                    <div className="flex   justify-end">
                                        <div className="flex ">
                                            <button
                                                style={{ borderRadius: "5px" }}
                                                className="  px-3 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 ml-2">
                                                <img src={trash} alt="" />
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </section>
                        </Grid>

                        <Grid item xs={12}>
                            <section className=" ">
                                <Grid container className="" spacing={0}>
                                    <Grid className="" item xs={2.8}>
                                        {/* <VoucherScroll /> */}
                                    </Grid>
                                    <Grid item xs={9.2}>
                                        <section className="w-full  z-50 ">

                                     
                                        </section>
                                    </Grid>
                                </Grid>

                                {/* w-[56.3vw] */}
                            </section>
                        </Grid>
                    </Grid>
                </section>


                {/* <div class="container">
                <div class="row">
                    <div class="col-sm">
                    One of three columns
                    </div>
                    <div class="col-sm">
                    One of three columns
                    </div>
                    <div class="col-sm">
                    One of three columns
                    </div>
                </div>
                </div> */}


<ul class="list-group">
  <li class="list-group-item active">Amount  <span className="font-bold ml-3 text-white" >{ '(₦)' + voucherDetails?.Voucher_Amount?.toLocaleString()}</span> </li>
  <li class="list-group-item"> Voucher Number  <span className="font-bold ml-3"> {voucherDetails?.Voucher_Number} </span></li>
  <li class="list-group-item"> Recipient name  <span className="font-bold ml-3" >{voucherDetails?.Reciepient_Name} </span> </li>
  <li class="list-group-item"> Recipient mobile  <span className="font-bold ml-3" >{voucherDetails?.Reciepient_Mobile_Number}</span>  </li>
  <li class="list-group-item"> Recipient email  <span className="font-bold ml-3" >{voucherDetails?.Reciepient_Email_Address} </span> </li>
  <li class="list-group-item"> Status  <span className="font-bold ml-3" > {voucherDetails?.Voucher_Status_Name} </span> </li>
  <li class="list-group-item"> Redeem date  <span className="font-bold ml-3" > {voucherDetails?.Redeem_Date} </span> </li>
 
</ul>


        </>
    );
};

export default OfflineDetailsPage;
