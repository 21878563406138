import { useState } from "react";


function IVRCustomerSearch(props) {
    const [textInput, setTextInput] = useState('');

    

    return (
        <>
        <div className=" dropdown relative  text-gray-600">
           <input    onChange={(ev)=>{ props.debouncedSearch(ev?.target.value); setTextInput(ev?.target.value)} }  className="w-100 border-2 border-gray-300 bg-white h-10  rounded-lg text-sm focus:outline-none"
             type="search" name="search"   value={textInput}  placeholder="Search and select customer(s)" />
             </div>
        </>
      );
}

export default IVRCustomerSearch;