import React, { useEffect, useState, useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import "./SalesOrderTable.css";
import Select from 'react-select';
import plus from "../../assets/Plus.svg";
import LogicContext from "../../Context/LogicContext";
import { NumericFormat } from 'react-number-format';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import SalesOrderSearchItem from "./SalesOrderSalesItem";
import SalesOrderTax from "./SalesOrderTax";
import SalesOrderAmount from "./SalesOrderAmount";
import { GetEntryData } from "../../store/salesOrderAtom";
import { salesService } from "../../services/sales.service";
import AddBoxIcon from '@mui/icons-material/AddBox';
import moment from "moment";
import { useNavigate,Link, useLocation } from "react-router-dom";





const AddSalesOrderInvoiceTable = (props) => {
    const [invoiceTableList, setIInvoiceTableList] = useState([]);
    const [tempSelection, setTempSelection] = useState({});
    const masterData = GetEntryData();
    const [customOption, setCustomOption] = useState([]);
    const logicContext = useContext(LogicContext);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    var url = pathname.split("/");
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];

    console.log('Invoice list ', customOption)

    useEffect(() => {
        salesService.getSalesOrderInvoiceList().then(res => {
            if (res?.data !== undefined) {
                let temList = res?.data.map(element => {
                    return { ...element, label: element.Invoice_No }
                });
                setCustomOption(temList);
            }
        });
    }, []);


    const viewDetails = (id) => {
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`)
      }


    useEffect(() => {
        if (props?.defaultList?.length > 0) {
            setIInvoiceTableList(props?.defaultList);
    
          setTimeout(() => {
            props?.onInvoiceSelectionList([...props?.defaultList])
          }, 2500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props?.defaultList?.length]);





    const AddToTable = () => {
        if (tempSelection?.Invoice_Ref_No) {
            const found = invoiceTableList.find(element => element.Invoice_Ref_No === tempSelection.Invoice_Ref_No);
            if (found) {
                return logicContext.showToast('Selected Invoice already in selection', 'error');
            }
            setIInvoiceTableList((prev) => {
                return ([...prev, { ...tempSelection }])
            });
            props?.onInvoiceSelectionList([...invoiceTableList, { ...tempSelection }]);

        } else {
            return logicContext.showToast('Please select an Invoice', 'info');
        }

    }

    function deleteItemFromTable(removedItem) {
        const newList = invoiceTableList.filter((item) => item.Invoice_Ref_No !== removedItem.Invoice_Ref_No);
        setIInvoiceTableList(newList);
        props?.onInvoiceSelectionList(newList);
    }


    const onchangeInvoiceSelection = (item) => {
        console.log('ss ', item)
        setTempSelection(item);

    }



    return (
        <>
            <section className=" mt-2 mb-4 max-auto bg-light border-2 py-2 px-2 max-w-6xl text-filterTextColor">
                <section className=" mt-1 right-1 ">
                    <section className="profile_section ">
                        <div className="flex  justify-between w-full">

                            {/*  search invoice */}
                            <Select placeholder="Select invoice" className="lg:w-[38.5vw] "
                                options={customOption} onChange={onchangeInvoiceSelection} />
                            <div onClick={AddToTable}  className=" w-50 flex justify-center">
                                <AddBoxIcon fontSize="large" className="text-success" />
                            </div>


                            <div>
                            </div>
                        </div>

                        {/* end */}

                        <section className="mt-3">
                            {/*  start table */}
                            <div className=" ">
                                <TableContainer className="mt-3 mb-5  h-[25vh]">
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <colgroup>
                                            <col style={{ width: '25%' }} />
                                            <col style={{ width: '25%' }} />
                                            <col style={{ width: '25%' }} />
                                            <col style={{ width: '25%' }} />

                                        </colgroup>
                                        <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                                            <TableRow>
                                                <TableCell className="subheading text-filterTextColor">Reference </TableCell>
                                                <TableCell className="subheading text-filterTextColor"> NO</TableCell>
                                                <TableCell className="subheading text-filterTextColor"> Date</TableCell>
                                                <TableCell className="subheading text-filterTextColor">Amount</TableCell>


                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {invoiceTableList?.map((invoice, i) => {

                                                return (
                                                    <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} >
                                                        <TableCell className="Table2" style={{ width: "120px " }} align="left">
                                                        <Link  target="_blank" to={`/${currModuleId}/${currModuleName}/invoices/details/${invoice.Invoice_Ref_No}`} >
                                                            <span className="text-primary cursor">  {invoice.Invoice_Ref_No}</span>
                                                        </Link>
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            <div>{invoice.Invoice_No} </div>
                                                        </TableCell>

                                                        <TableCell align="left">

                                                            <div> {moment(invoice.Invoice_Date).format("DD-MM-YYYY")} </div>
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            <CurrencyFormat
                                                                className={"font-bold "}
                                                                value={invoice?.Invoice_Amount?.toFixed(2)}
                                                                displayType={"text"} thousandSeparator={true} prefix={"₦"} />

                                                        </TableCell>


                                                        <TableCell align="left">
                                                            <DeleteIcon color="error" onClick={() => deleteItemFromTable(invoice)} />
                                                        </TableCell>

                                                    </TableRow>

                                                )
                                            })}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>

                            {/* end table */}

                        </section>
                    </section>

                </section>

            </section>
        </>
    );
}

export default AddSalesOrderInvoiceTable;