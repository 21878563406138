import { GetCouponMasterData } from "../store/productAtom";

function CouponDiscountType(props) {
  const masterData = GetCouponMasterData();

  const handleChange = (e) => {
    props.onDiscountTypeSelect(e.target.value);
  };

  const createSelectItems = () => {
    let items = [];

    masterData[1]?.forEach((item, i) => {
      items.push(
        <option key={i} value={item.Code}>
          {item.Name}
        </option>
      );
    });

    return items;
  };

  return (
    <>
      <select onChange={handleChange}>{createSelectItems()}</select>
    </>
  );
}

export default CouponDiscountType;
