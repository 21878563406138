import { atom , useSetRecoilState, useRecoilValue, selector} from "recoil";



export const voucherAtom = atom({key:'rating_grocedy_atom',default:{rating_info:[], rating_type:[]}});
// export const voucherOfflineAtom = atom({key:'voucher_offline_atom',default:[]});

export  const SetRatingListAtom = () => useSetRecoilState(voucherAtom);
export  const GetRatingListAtom = () => useRecoilValue(voucherAtom);

// export  const SetOfflineVoucherListAtom = () => useSetRecoilState(voucherOfflineAtom);
// export  const GetOfflineVoucherListAtom = () => useRecoilValue(voucherOfflineAtom);





