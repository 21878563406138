import * as React from "react";
import { useEffect, useState } from "react";

import {
  DataGrid,
  gridPageCountSelector,
  GridToolbar,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { customerService } from "../../services/customer.service";
import {
  GetCustomerVoucherAtom,
  SetCustomerVoucherAtom,
} from "../../store/CustomerVoucherAtom";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      siblingCount={1} // Number of pagination items to display on each side of the current page
      boundaryCount={1} // Number of first and last page pagination items to always display
      renderItem={(props) => (
        <PaginationItem
          {...props}
          disableRipple
          style={{
            borderRadius: "50%", // Make pagination items circular
            margin: "0 4px", // Add some space between pagination items
            minWidth: "36px", // Set a minimum width for pagination items
          }}
        />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const tableHead = [
  {
    field: "Ref_No",
    headerName: "Ref",
    width: 130,
    className: "font-weight-bold",
  },
  { field: "Transaction_Date", headerName: "Date", width: 160 },
  { field: "Voucher_Number", headerName: "Voucher No", width: 150 },
  { field: "Voucher_Amount", headerName: "Amount (₦)", width: 130 },
  { field: "Reciepient_Name", headerName: "Recipient Name", width: 150 },
  {
    field: "Reciepient_Mobile_Number",
    headerName: "Recipient Mobile",
    width: 160,
  },
  { field: "Voucher_Status_Name", headerName: "Status", width: 130 },
];

export default function VoucherTab({ customer_id }) {
  const [tableList, setTableList] = useState([]);
  const setVoucherList = SetCustomerVoucherAtom();
  const voucherList = GetCustomerVoucherAtom();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  useEffect(() => {
    console.log("look ", voucherList);
    if (!voucherList.length) {
      getDataFromServer();
    } else {
      setTableList(voucherList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id, voucherList.length]);

  const getDataFromServer = () => {
    customerService.getCustomerVouchers(customer_id).then((res) => {
      console.log("SEE ", res.data);
      if (res.data?.length) {
        let tempList = res.data.map((item) => {
          return { ...item, id: item?.Ref_No };
        });
        setVoucherList(tempList);
      }
    });
  };

  // function OnItemCellClick(event) {
  //   const id = event.row['Invoice_Ref_No'];
  //   navigate(`/${currModuleId}/${currModuleName}/invoices/details/${id}`);
  // }

  return (
    <>
      <div className="ml-1 @container">
        <div className="mt-1 text-gray-600 text-xl font-bold">Voucher List</div>

        <DataGrid
          className="mt-4 overflow-x-auto bg-white outline-none border-[1px] border-gray-200 rounded-xl"
          style={{ height: 600, width: "100%" }}
          columns={tableHead}
          rows={tableList}
          // onRowClick={(event) => {
          //   OnItemCellClick(event);
          // }}

          // components={{
          //   Pagination: CustomPagination,
          // }}
          onCellClick={(event) => {
            event.defaultMuiPrevented = true;
          }}
          // sx={{
          //   borderColor: "primary.light",
          //   "& .MuiDataGrid-cell:hover": {
          //     color: "primary.main",
          //     cursor: "pointer",
          //   },
          // }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{ toolbar: { showQuickFilter: true } }}
        />
      </div>
    </>
  );
}
