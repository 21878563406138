
import Select from 'react-select';
import { GetAddonMasterData} from '../store/productAtom';




function AddonDeliverySelect(props) {
  const masterData = GetAddonMasterData();

  const customOption = masterData[9]?.map((item)=> {
    return ({...item, label:`${item.Name}`})
  })

    const customStyles = {
        control: base => ({
          ...base,
          height: 50,
          backgroundColor:'#F1F1F1',
          width:310,
        }),
        indicatorSeparator: styles => ({ ...styles, display : 'none' }),
        placeholder: styles => ({ ...styles, display : 'none' }),
        valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
        option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
      }


    const handleChange = (value) => {
      props.onDeliverySelect(value)
      };


    return ( 
        <>
   
         <Select  styles={customStyles}
         defaultValue={props?.defaultVal}
          onChange={handleChange} options={customOption} />


        </>
     );
}

export default AddonDeliverySelect;
