import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import React from "react";
import stlogo from "../../assets/statmlogo.png";
import "./CustomerIStatment.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function CustomerDetailsStatement() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Item>
          <section className=" w-full">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Item>
                  <div className="mt-2 border-[1px] border-gray-200 rounded-md  py-3 px-3">
                    <img src={stlogo} alt="" />
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  {" "}
                  <section className="mt-4  grid grid-cols-2 border-[1px] border-gray-200 rounded-md py-3 px-3 ">
                    <section>
                      <div>
                        <span className="text-xs">To :</span>
                        <span style={{ color: "#476EF8" }} className="text-xs">
                          {" "}
                          A. Arogundade
                        </span>
                      </div>

                      <div>
                        <span>
                          <span className="text-xs">Address:</span>
                        </span>
                        <span>
                          <span className="text-xs">
                            No 20,Ishiba Street, Orelope, Egbeda
                          </span>
                          <h6 className="text-xs">
                            Carwash Bus-Stop Dopemu Lagos, Nigeria
                          </h6>
                        </span>
                      </div>
                    </section>

                    <section className=" flex justify-end ">
                      <div>
                        <div>
                          {" "}
                          <h6 className="text-xs font-bold text-filterTextColor">
                            {" "}
                            Worldbay Technologies Ltd
                          </h6>
                          <h6 className="text-xs font-bold text-filterTextColor">
                            TIN:16311833-0001
                          </h6>
                          <h6 className="text-xs font-bold text-filterTextColor">
                            VAT: YBV060021065153
                          </h6>
                        </div>

                        <div className="mt-8">
                          <h6 className="text-xs font-bold text-filterTextColor">
                            RC: 1065153
                          </h6>
                          <h6 className="text-xs font-bold text-filterTextColor">
                            Address: Plot 4b Chief Augustine Anozie Street
                          </h6>
                          <h6 className="text-xs font-bold text-filterTextColor">
                            Lekki Phase 1 Lagos{" "}
                          </h6>
                          <h6 className="text-xs font-bold text-filterTextColor">
                            Nigeria
                          </h6>
                        </div>
                      </div>
                    </section>
                    <section></section>
                  </section>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  {/* STATEMENT OF ACCOUNT */}
                  <section className="mt-3 border-[1px] border-gray-200 rounded-md py-3 px-3">
                    <span className="text-lg  mt-7 font-bold text-filterTextColor">
                      Statement of Accounts
                    </span>{" "}
                    <br></br>
                    <span className="text-xs  text-filterTextColor">
                      01.03.2022 To 31.03.2022
                    </span>
                  </section>
                </Item>
              </Grid>

              <Grid item xs={12}>
                <Item>
                  {" "}
                  {/* ACCOUNT SUMMARY  */}
                  <section className="mt-3 border-[1px] border-gray-200 rounded-md bg-[#476EF8]/10 py-3 px-3 drop-shadow-lg ">
                    <div className="ml-4">
                      <h6 className="font-bold text-xs text-[#476EF8] ">
                        Account Summary
                      </h6>
                      <div>
                        <div className="flex">
                          <h6 className="text-xs">Opening Balance</h6>
                          <h6 className="text-xs ml-4">₦ 590,000.00</h6>
                        </div>
                        <div className="flex">
                          <h6 className="text-xs">Opening Balance</h6>
                          <h6 className="text-xs ml-4">₦ 590,000.00</h6>
                        </div>
                        <div className="flex">
                          <h6 className="text-xs">Opening Balance</h6>
                          <h6 className="text-xs ml-4">₦ 590,000.00</h6>
                        </div>
                        <div className="flex">
                          <h6 className="text-xs">Opening Balance</h6>
                          <h6 className="text-xs ml-4">₦ 590,000.00</h6>
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* TABLE */}
                  <section className="pt-20">
                    {/* TABLE */}
                    <TableContainer className="mt-10">
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                          <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell align="left">Invoice</TableCell>
                            <TableCell align="left">Order Number</TableCell>
                            <TableCell align="left">Amount</TableCell>
                            <TableCell align="left">Balance Due</TableCell>
                            <TableCell align="left">Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </section>
                </Item>
              </Grid>
            </Grid>
          </section>
        </Item>
      </Grid>
    </Grid>
  );
}

export default CustomerDetailsStatement;
