import Select from 'react-select';
import { GetSubEntryMasterdata} from "../../store/subscriptionAtom";




function AddTaxSelect(props) {
  const getEntryMasterdata = GetSubEntryMasterdata();

  const customOption = getEntryMasterdata?.Tax?.map((item)=> {
    return ({...item, label:`${item.Tax_Name}`})
  })

  
  const customStyles = {
    control: base => ({
      ...base,
      height: 44,
      backgroundColor: '#F1F1F1',
      width: 250,
      // zIndex: 9999,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),

  
    
  }

    const handleChange = (value) => {
      props.onSelection(value)
      };


    return ( 
        <>
   
         <Select   styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal}
          options={customOption}  />


        </>
     );
}

export default AddTaxSelect;
