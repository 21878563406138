import PackageCard from "./PackageCard";
import { packageService } from "../../services/packageService";
import { useEffect, useState } from "react";
import { GetPackageListAtom, SetPackageListAtom } from "../../store/packageStore";
import RestartAlt from '@mui/icons-material/RestartAlt';
import Tooltip from "@mui/material/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";


const PackagesMainPage = () => {
  const getPackageListAtom = GetPackageListAtom();
  const setPackageListAtom = SetPackageListAtom();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const [selections, setSelections] =  useState({channel:'', list:[]});


  useEffect(()=> {
    loadDataList();

  },[]);

  

  const loadDataList = ()=> {
    packageService.getPackageList().then((res)=> {
      if(res?.data){
        const mainData =  res.data;
        let so_delivered = mainData?.so_delivered.map((ele) => { return { ...ele, checked: false } });
        let so_not_shipped = mainData?.so_not_shipped.map((ele) => { return { ...ele, checked: false } });
        let so_shipped = mainData?.so_shipped.map((ele) => { return { ...ele, checked: false } });
        setPackageListAtom({...res?.data, so_delivered,so_not_shipped , so_shipped });
      }
    }).catch((err)=> {
      console.log('Errrrr', err);
    })
  }



  function reloadData(){
    loadDataList();

  }

  const addNew = ()=> {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`)

  }

  const onCheckboxClick = async (e, index, obJKey, packages, pkg) => {
   let newPackageList =  packages.map((item)=> {
      if(item.Sales_Order_Ref_No === pkg.Sales_Order_Ref_No){
        return {...pkg, checked: e.target.checked};
      }else{
        return item;
      }
    } );
    if(selections.channel === obJKey ){
      setSelections({channel: obJKey, list:[...selections.list,pkg ]});
    }else{
      setSelections({channel: obJKey, list:[pkg]});
    }
    setPackageListAtom({...getPackageListAtom, [obJKey]:newPackageList});

  };


   
  
    return (
      <div className="container mx-auto p-6">
        {/* flext box */}
        <div class="flex mb-5">
            <div class="flex-auto w-14 ...">
            <Tooltip title="Reload">
                <RestartAlt
                  className="cursor ml-2 mt-2 mr-2"
                  onClick={() => reloadData()}
                  color="primary"
                  fontSize="large"
                />
              </Tooltip>
                
            </div>
            <div class="flex-auto w-64 ...">
                
            </div>
            <div class="flex-auto w-64 ">
                
            </div>
            <div class="flex-auto w-32 ">
                <button onClick={()=> addNew() } className="btn btn-primary" >Add New</button>
            </div>
        </div>
        {/* flext box end */}

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <PackageCard selections={selections.channel === 'so_not_shipped'? selections.list: []} onCheckboxClick={onCheckboxClick} title="Packages, Not Shipped" dynamicColor={'#cffafe'}
            packages={getPackageListAtom.so_not_shipped} objKey={'so_not_shipped'} />

          <PackageCard selections={selections.channel === 'so_shipped'? selections.list: []}  onCheckboxClick={onCheckboxClick}  title="Shipped Packages" dynamicColor={'#fef9c3'} 
           packages={getPackageListAtom.so_shipped} objKey={'so_shipped'} />

          <PackageCard selections={selections.channel === 'so_delivered'? selections.list: []}  onCheckboxClick={onCheckboxClick}   title="Delivered Packages" dynamicColor={'#86efac'} 
           packages={getPackageListAtom.so_delivered}  objKey={'so_delivered'} />
        </div>
      </div> 
    );
  };
  
  export default PackagesMainPage;