

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


const purchaseReceiveMasterData = atom({key:'purchaseReceiveMasterData', default:{}});
const purchaseReceiveEntryMasterData = atom({key:'purchaseReceiveEntryMasterData', default:{}});
const addPurchaseReceiveAtom = atom({key:'purchaseReceiveEdit', default:{ list:[]}});
const editPurchaseReceiveAtom = atom({key:'customReceiveEdit', default:{}});



export  const SetPurchaseReceiveListData = () => useSetRecoilState(purchaseReceiveMasterData);
export  const GetPurchaseReceiveListData = () => useRecoilValue(purchaseReceiveMasterData);

export  const SetEntryData = () => useSetRecoilState(purchaseReceiveEntryMasterData);
export  const GetEntryData = () => useRecoilValue(purchaseReceiveEntryMasterData);

export  const SetAddPurchaseReceive = () => useSetRecoilState(addPurchaseReceiveAtom);
export  const GetAddPurchaseReceive = () => useRecoilValue(addPurchaseReceiveAtom);
export const ResetAddPurchaseReceive = ()=> useResetRecoilState(addPurchaseReceiveAtom);

export  const SetCustomReceive = () => useSetRecoilState(editPurchaseReceiveAtom);
export  const GetCustomReceive = () => useRecoilValue(editPurchaseReceiveAtom);
export const ResetCustomReceive = ()=> useResetRecoilState(editPurchaseReceiveAtom);





