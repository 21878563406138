import React, { useEffect, useState } from 'react'
import Switch from '@mui/material/Switch';




function ToggleSwitch(props) {
    // const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };
    const [checked, setChecked] = React.useState(false);

    useEffect(()=>{
      if(props.Item_Status_Id === 1){
        setChecked(true)
      }else{
        setChecked(false)
      }
    },[props.Item_Status_Id])


    
    const handleChange = (event) => {
      setChecked(event.target.checked);
      props.toogleItemStatus(event.target.checked)
    };
  

  return (
     
     <Switch 
     checked={checked}
     onChange={handleChange}
      />
   
  )
}

export default ToggleSwitch