import { atom , useSetRecoilState, useRecoilValue, selector} from "recoil";



const packageAtom = atom({key:'packageStore_atom',default:{so_delivered:[], 
    so_not_shipped:[],  so_shipped:[], pickup_info:[]}});

const packageMasterAtom = atom({key:'packageMaster_atom',default:{
    customer_info:[], so_details_info:[],  so_info:[] }});

const packageEditAtom = atom({key:'package_edit_atom',default:[]});

export  const SetPackageMasterData = () => useSetRecoilState(packageMasterAtom);
export  const GetPackageMasterData = () => useRecoilValue(packageMasterAtom);


export  const SetPackageListAtom = () => useSetRecoilState(packageAtom);
export  const GetPackageListAtom = () => useRecoilValue(packageAtom);


export  const SetPackageEditAtom = () => useSetRecoilState(packageEditAtom);
export  const GetPackageEditAtom = () => useRecoilValue(packageEditAtom);




