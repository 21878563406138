import React, { useCallback, useRef } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import imageD from "../../assets/imageDisplay.svg";
import message from "../../assets/message.svg";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
// import ".././CustomerDetails.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useContext } from "react";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { accountService } from "../../services/accountService";
import { Grid } from "@mui/material";
import Item from "antd/lib/list/Item";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import {
  SetBulkActions,
  GetBulkActions,
  SetTableDataHeadList,
  GetTableDataHeadList,
  SetTableDataList,
  GetTableDataList,
  SetTableFilter,
  GetTableFilter,
  SetDepositTo,
  GetDepositTo,
  SetPaymentMode,
  SetPaymentReceiveEdit,
} from "../../store/paymentReceivedAtom";
import LogicContext from "../../Context/LogicContext";
import PaymentReceiveScroll from "./PaymentReceiveScroll";
import PaymentDetailsTable from "./PaymentDetailsTable";
import PaymentBulkAction from "./PaymentBulkAction";
import FilePreviewModal from "../../helpers/FilePreviewModal";
import { ImAttachment } from "react-icons/im";
import { FaFilePdf } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";
import { BsPersonCircle } from "react-icons/bs";

const PaymentReceivedDetailsPage = () => {
  const [selectedList, setSelectedList] = useState([]);
  const myPromiseModal = createModal(GeneralModal);

  const { payment_ref_id } = useParams();
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const [paymentData, setPaymentData] = useState({});
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const paymentModeIdRef = useRef(null);
  const depositToRef = useRef(null);

  const setPaymentList = SetTableDataList();
  const setBulkActions = SetBulkActions();
  const setBulkFilter = SetTableFilter();
  const setDepositTo = SetDepositTo();
  const setPaymentMode = SetPaymentMode();
  const setTableHeaderList = SetTableDataHeadList();
  const setEdit = SetPaymentReceiveEdit();

  const getInvoiceCallBack = useCallback(async (id) => {
    const response = await accountService.getPaymentReceiveDetails(id);
    if (response?.data) {
      setPaymentData(response.data);
    }
  }, []);

  useEffect(() => {
    document.title = "Payment receive details";
    var isMounted = true;
    getInvoiceCallBack(payment_ref_id);
    return () => {
      isMounted = false;
      paymentModeIdRef.current = null;
      depositToRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_ref_id]);

  function viewItemDetails(id) {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
    getInvoiceCallBack(id);
  }

  const editPaymentDetails = async () => {
    const response = await myPromiseModal({
      title: "Modify Payment Receive",
      body: " Do you want to modify this record?",
      showInput: false,
      placeHolder: "",
    });
    if (response.decision === true) {
      setEdit(paymentData);
      navigate(
        `/${currModuleId}/${currModuleName}/${currUserMenu}/add-payment-received`
      );
    }
  };

  function onSelection(list) {
    let tempList = list?.filter((item) => item.checked === true);
    setSelectedList(tempList);
  }

  const deletePaymentReceive = async () => {
    await myPromiseModal({
      title: "confirm your Delete!",
      body: `Do you want to delete ${paymentData?.Payment_Ref_No} ?`,
      showInput: false,
      placeHolder: "",
    }).then((response) => {
      if (response.decision === true) {
        accountService
          .deletePaymentReceive(paymentData.Payment_Ref_No)
          .then((res) => {
            if (res?.data !== undefined) {
              const { Message_Text } = res.data[0];
              logicContext.showToast(Message_Text, "success");
              navigate(-1);
              // return reFilterMainData(invoiceData.Invoice_Ref_No);
            }
          })
          .catch((err) => {
            logicContext.showToast("Error", "error");
          });
      }
    });
  };

  const onPaymentModeSelect = (val) => {
    paymentModeIdRef.current = val;
    depositToRef.current = null;
  };

  const onDepositSelect = (val) => {
    paymentModeIdRef.current = null;
    depositToRef.current = val;
  };

  async function performBulkActions() {
    let temp = "";
    let option = 0;
    if (paymentModeIdRef !== null) {
      option = 5;
    }
    if (depositToRef !== null) {
      option = 6;
    }

    selectedList.map((item) => {
      temp += `<a> <refno> ${item.Payment_Ref_No} </refno> </a>`;
    });

    let xml_string_refno = `<root> ${temp} </root>`;
    let body = { xml_string_refno, option };
    if (paymentModeIdRef.current !== null) {
      body["payment_mode_id"] = paymentModeIdRef.current;
    }
    if (depositToRef.current !== null) {
      body["deposit_id"] = depositToRef.current;
    }
    accountService
      .savePaymentReceivedBulkAction({ ...body, option: option })
      .then((res) => {
        if (res?.data !== undefined) {
          const { Message_Text } = res.data[0];
          logicContext.showToast(Message_Text, "success");
          return getPaymentListApi();
        }
      })
      .catch((err) => {
        logicContext.showToast("Error", "error");
      });

    const getPaymentListApi = async () => {
      try {
        const response = await accountService.getPaymentReceiveList();

        if (response?.data !== undefined) {
          setPaymentList(response.data["Payment Info"]);
          setBulkFilter(response.data["Filtering Criteria"]);
          setBulkActions(response.data["Bulk Action"]);
          setTableHeaderList(response.data["Display Column"]);
          setDepositTo(response.data["Deposit To"]);
          setPaymentMode(response.data["Payment Mode"]);
        }
      } catch (error) {
        logicContext.showToast(
          "connection error getting invoice data",
          "error"
        );
      }
    };
  }

  const manageInvoiceStatus = (status) => {
    let defaultClass = "text-white bg-primary";

    switch (status) {
      case "Failure by Online":
        defaultClass = "text-white bg-danger";
        break;
      case "Collected by Offline":
        defaultClass = "text-white bg-success";
        break;
      case "Pending For Approval":
        defaultClass = "text-white  bg-warning";
        break;
      case "Collected by Online":
        defaultClass = "text-white bg-info";
        break;

      default:
        break;
    }

    return (
      <span
        className={`text-sm font-semibold py-2 px-3 lg:px-4 rounded ${defaultClass} `}
      >
        {status}
      </span>
    );
  };

  return (
    <>
      <section className="mt-2 @container">
        <div className="w-full @sm:flex @sm:flex-row @sm:justify-between space-y-3 @sm:space-y-0">
          <div>
            {selectedList.length > 0 && (
              <PaymentBulkAction
                onPaymentModeSelect={onPaymentModeSelect}
                onDepositSelect={onDepositSelect}
                performBulkActions={performBulkActions}
              />
            )}
          </div>

          <div className="flex">
            <button className="bg-[#476EF8] text-white font-medium rounded-md px-4 py-2">
              REFUND
            </button>

            <div className="ml-4 flex fle-row items-center space-x-1 ">
              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <ImAttachment className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <FaFilePdf className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <IoPrintSharp className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              <div
                onClick={editPaymentDetails}
                className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer"
              >
                <MdEdit className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              <div
                onClick={deletePaymentReceive}
                className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer"
              >
                <MdDelete className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
            </div>
          </div>
        </div>

        <section className="mt-4 lg:flex space-x-1">
          <div className="lg:w-[250px]">
            <PaymentReceiveScroll
              onSelection={onSelection}
              viewItemDetails={viewItemDetails}
            />
          </div>
          <section className="w-full overflow-x-hidden mt-4 lg:mt-0 lg:pl-4">
            <div className="p-4 bg-white border-[1px] border-gray-200 rounded-xl">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-3 sm:space-y-0">
                <div className="w-fit ml-8 sm:ml-0 p-1 rounded-full bg-gray-200 flex-shrink-0">
                  <BsPersonCircle className="h-[90px] w-[90px] text-[#486EF8] flex-shrink-0 " />
                </div>

                <div className="">
                  {manageInvoiceStatus(paymentData?.Payment_Status_Name)}
                  {/* {paymentData?.Payment_Status_Name} */}
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <span className="text-[#476ef8] font-semibold ">
                    Worldbay Technologies Ltd
                  </span>
                  <br></br>
                  <span className="text-sm font-semibold ">
                    TIN :16311833-0001
                  </span>{" "}
                  <br></br>
                  <span className="text-sm  font-semibold ">
                    VAT :YBV060021065153
                  </span>
                </div>
                <div className="">
                  <span className="text-sm  font-semibold ">RC 1065153</span>
                  <span className="text-sm"></span> <br></br>
                  <span className="text-sm">
                    <div className="mt-2 text-sm text-gray-400 italic font-semibold">
                      {" "}
                      Plot 4b Chief Augustine Anozie Street Lekki Phase 1 Lagos
                      Nigeria
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-4 p-4 bg-white border-[1px] border-gray-200 rounded-xl">
              <div className="@xl:grid @xl:grid-cols-2 space-y-6 @space-y-0">
                <div>
                  <span className="font-bold my-2 ">Payment Receipt :</span>
                  <br></br>
                  <span className="#476ef8 text-sm font-bold">
                    Billing To :
                  </span>{" "}
                  <br></br>
                  <span className="">
                    <span className=" text-sm font-bold">
                      {" "}
                      {paymentData?.Customer_Full_Name} :
                    </span>{" "}
                    {/* <br /> */}
                    <span className=" text-sm text-capitalize">
                      {" "}
                      {paymentData?.Billing_Address1}
                    </span>{" "}
                    <br />
                    <span className="text-sm font-bold text-capitalize">
                      {" "}
                      Collection Officer :{" "}
                      {paymentData?.Payment_Received_User_Name}
                    </span>
                    <br />
                    <span className=" font-bold text-sm text-capitalize">
                      {" "}
                      Entry Officer : <span>undefined</span>
                    </span>
                    <br />
                    <span className=" text-sm font-bold text-capitalize">
                      {" "}
                      Amount Received :
                    </span>
                    <span className=" text-2xl  font-bold ">
                      {" "}
                      <CurrencyFormat
                        value={parseFloat(paymentData?.Payment_Amount)?.toFixed(
                          2
                        )}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </span>
                    <br />
                  </span>
                </div>

                <div className="flex flex-row items-center space-x-2">
                  <div>
                    <div>
                      <span className="#476ef8 text-sm font-bold ">
                        Payment Date :
                      </span>
                      <br></br>
                      <span className="#476ef8 text-sm font-bold">
                        Reference Number :
                      </span>
                      <span>
                        {" "}
                        <br></br>
                        <span className="#476ef8 text-sm font-bold">
                          Payment Mode :
                        </span>
                        <br></br>
                        <span className="#476ef8 text-sm font-bold">
                          Deposit To :
                        </span>
                        <br></br>
                        <span className="#476ef8 text-sm font-bold">
                          Deposit Ref :
                        </span>
                      </span>
                    </div>
                  </div>

                  <div>
                    <div>
                      <span className="text-sm">
                        {" "}
                        {moment(paymentData?.Invoice_Date).format(
                          "DD-MM-YYYY"
                        )}{" "}
                      </span>
                      <br></br>
                      <span className="text-sm">
                        {paymentData?.Payment_Ref_No}
                      </span>
                      <br></br>
                      <span className="text-sm">
                        {paymentData?.Payment_Mode_Name}
                      </span>
                      <span>
                        {" "}
                        <br></br>
                        <span className="text-sm" id="deposit_to">
                          {" "}
                          {paymentData?.Deposit_Name}
                        </span>
                        <br></br>
                        <span className="text-sm">
                          {paymentData?.Deposit_Reference}{" "}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 p-4 bg-white border-[1px] border-gray-200 rounded-xl">
              <span className="font-bold">Payment For</span>
              <PaymentDetailsTable
                tableData={paymentData["payment_details"]}
              ></PaymentDetailsTable>
            </div>
            <div>
              {paymentData?.Attached_File_Name === "Yes" && (
                <div className="mt-4 p-4 bg-white border-[1px] border-gray-200 rounded-xl">
                  <div>
                    <h3>
                      ATTACHMENT FILE :{" "}
                      <span className="font-bold">
                        {" "}
                        {paymentData?.Payment_Ref_No +
                          "." +
                          paymentData?.Attached_File_Format}
                      </span>{" "}
                    </h3>
                  </div>
                  <div>
                    <FilePreviewModal
                      file={
                        paymentData?.Payment_Ref_No +
                        "." +
                        paymentData?.Attached_File_Format
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </section>
        </section>
      </section>
    </>
  );
};

export default PaymentReceivedDetailsPage;
