import { atom , useSetRecoilState,useRecoilValue, useResetRecoilState} from "recoil";


export const payGateWayAtom = atom({key:'payGatewayAtom',
default:{gateway_info:[], payment_info:[],status_info:[]}});


export  const SetGateWayData = () => useSetRecoilState(payGateWayAtom);
export  const GetGateWayData = () => useRecoilValue(payGateWayAtom);
export const ResetGateWayData = ()=> useResetRecoilState(payGateWayAtom);

