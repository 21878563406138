import { useContext, useEffect, useState } from "react";
import AddItemGroupForm from "./item-page/AddItemFormProps/AddItemGroupForm";
import { itemsGroupService } from "../services/itemGroup.service";
import LogicContext from "../Context/LogicContext"; 

import NewItemGroupInfoPage from "./NewItemGroupInfoPage";
import { useNavigate } from "react-router-dom";
import { GetGroupEdit } from "../store/itemGroupAtom";
import AddItemGroupImage from "./add-item-group-props/AddItemGroupImage";



const TAB = {GROUP_INFORMATION:'GROUP_INFORMATION', ITEM_INFORMATION:'ITEM_INFORMATION', GROUP_IMAGE:'GROUP_IMAGE'}

function AddItemGroup() {
  const navigate = useNavigate(); 
  const groupToEdit = GetGroupEdit();
    const [currentTab, setCurrentTab] = useState(TAB.GROUP_INFORMATION); 
    const logicContext = useContext(LogicContext);
    const [newItemId, setNewItemId] = useState(undefined);


    useEffect(()=> {
    setNewItemId(groupToEdit?.Item_Group_Id)
      
     
    },[groupToEdit?.Item_Group_Id])

    const changeTab = (tab)=> {
        setCurrentTab(tab)
    }


    function submitGroupInformation (data) {
        if(newItemId){
          itemsGroupService.updateItemGroup(data).then(res => {
            const {Message_Id, Message_Text, Message_Ref_No} = res.data[0];
            
            logicContext.showToast(res?.data[0]['Message_Text'], 'success');
            // setCurrentTab(TAB.GROUP_INFORMATION);
            // setNewItemId(res?.data[0]['Message_Ref_No']);
           
        }).catch(err => {
            console.log(err);
        });
        }else{
          itemsGroupService.submitItemGroup(data).then(res => {
            const {Message_Id, Message_Text, Message_Ref_No} = res.data[0];
            if(Message_Id === 5 || Message_Id == 6){
              logicContext.showToast(res?.data[0]['Message_Text'], 'error');

            }else if (Message_Id === 1){
              logicContext.showToast(res?.data[0]['Message_Text'], 'success');
              setCurrentTab(TAB.GROUP_INFORMATION);
              setNewItemId(res?.data[0]['Message_Ref_No']);
            }
          
           
        }).catch(err => {
            console.log(err);
        });

        }
   

    }


    function submitGroupInfo (data){
        itemsGroupService.saveGroupItems(data).then(res => {
            setCurrentTab(TAB.GROUP_IMAGE);
            logicContext.showToast(res?.data['Message_Text'], 'success');
        }).catch(e => console.log(e));
    }



    const submitItemImage = (imageFile, imageId)=> {
        let formData = new FormData();
            formData.append('file', imageFile);
            formData.append('Item_Id', newItemId);
            formData.append('image_id', imageId);
            itemsGroupService.submitGroupImage(formData).then(res => {
              console.info(res?.data);
              var newBody = {Item_group_Id: newItemId, picture_format: res?.data?.imageData?.picture_format,picture_id:imageId}
      
              saveImageRecord(newBody);
              // setCurrentTab(TAB.IMAGETAB)
            }).catch(err => {
              console.log(err);
            })
      }


      function saveImageRecord(body){
        itemsGroupService.submitImageToDb(body).then(res => {
          logicContext.showToast(res?.data[0]['Message_Text'], 'success');
        //  setTimeout(() => {
        //   navigate(-1)
        //  }, 3000);
          
        }).catch(err => console.log(err))
      }


    return ( 
        <>


        
      
    <section className="container max-auto max-w-6xl ">
    <section className="flex  my-3  w-60 ">
  

  <div className="  md:mt-2">
           <button
             className={
               "  rounded-lg btn  px-4 py-2 mx-2 " + (currentTab === TAB.GROUP_INFORMATION ? "bg-primary text-white btn-primary" : "bg-neutral-300")}
             role="button"
             onClick={() => changeTab(TAB.GROUP_INFORMATION)}
           >
            Group Information
           </button>
  
         
           <span  >
                 <button
                 disabled={!newItemId}
             className={
               "  rounded-lg btn px-4 py-2 mx-2 " + (currentTab === TAB.ITEM_INFORMATION ? "bg-primary text-white  btn-primary" : "bg-neutral-300")}
             role="button"
             onClick={() => changeTab(TAB.ITEM_INFORMATION)}
           >
            Item  Information
           </button>
  
           
           <button
           disabled={!newItemId}
             className={
               "  rounded-lg btn  px-4 py-2 mx-2 " + (currentTab === TAB.GROUP_IMAGE ? "bg-primary  btn-primary " : "bg-neutral-300")}
             role="button"
             onClick={() => changeTab(TAB.GROUP_IMAGE)}
           >
            Pictures
           </button>
           </span> 
  
       
           
           
         </div>
       </section>
     {currentTab === TAB.GROUP_INFORMATION ? <AddItemGroupForm  submitForm={submitGroupInformation} /> : null}
     {/* {currentTab === TAB.ITEM_INFORMATION ? <AddItemGroupFormInfo submitGroupInfo={submitGroupInfo}   item_id={newItemId} /> : null} */}
    
     <span>
        {currentTab === TAB.ITEM_INFORMATION ? <NewItemGroupInfoPage submitGroupInfo={submitGroupInfo}   item_group_id={newItemId} /> : null}
     {currentTab === TAB.GROUP_IMAGE ? <AddItemGroupImage submitItemImage={submitItemImage}  getItemImages={itemsGroupService.getItemImages}    item_group_id={newItemId} /> : null}
       
     </span>
     
   
    </section>
  
        </>
     );
}

export default AddItemGroup;