import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import {
  GetInvoiceTableHeader,
  SetInvoiceTableHeader,
} from "../../store/invoiceAtom";
import { useLocation, useNavigate } from "react-router-dom";
import InvoiceTableSelect from "./InvoiceTableSelect";
import { invoiceService } from "../../services/invoice.service";
import { twMerge } from "tailwind-merge";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function InvoiceTable(props) {
  const tableHead = GetInvoiceTableHeader();
  const setTableHead = SetInvoiceTableHeader();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const filteredHeader = tableHead
    ?.filter((item) => item.Display_Id === 1)
    .map((item) => {
      return {
        ...item,
        field: item.Column_Value.trim(),
        headerName: item.Column_Name,
        width: 150,
      };
    })
    .sort((a, b) => {
      return a.Print_Sequence - b.Print_Sequence;
    });

  const tableMenuSelect = (option, i) => {
    var choice = option.Display_Id === 1 ? 0 : 1;
    var temp = tableHead?.map((item) => {
      if (item.Ref_No === option.Ref_No) {
        return { ...item, Display_Id: choice };
      }
      return item;
    });
    // let newList = under
    setTableHead(temp);
  };

  function saveCurrentTableHeader() {
    var xmlString = "";
    filteredHeader?.map((element) => {
      if (element.Display_Id === 1) {
        xmlString += `<a><refno>${element.Ref_No} </refno></a>`;
      }
    });
    invoiceService
      .saveTableHeader({ xml_string: `<root> ${xmlString}} </root>` })
      .then((response) => {});
  }

  function OnItemCellClick(event) {
    const id = event.row["Invoice_Ref_No"];
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
  }

  function onSelectChange(selectedList) {
    var selection = selectedList?.map((index) => {
      return props.tableData[index];
    });
    props.selectedTableItems(selection);
  }

  return (
    <>
      <div className="dropdown mr-2">
        <button
          className="btn btn-primary dropdown-toggle text-white bg-blue-500"
          type="button"
          id="dropdownMenuButton1"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          Customize Columns
        </button>

        <ul
          className={twMerge(
            "mt-2 dropdown-menu scrollable-menu outline-none border-[1px] border-gray-200 rounded-lg shadow-xl overflow-y-scroll h-[300px] lg:h-[1000px] scrollbar-thumb-gray-400 scrollbar-thin scrollbar-track-gray-200 pb-[20px]"
          )}
          role="menu"
          aria-labelledby="dropdownMenuButton1"
        >
          {tableHead?.map((val, i) => {
            return (
              <InvoiceTableSelect
                key={i}
                val={val}
                i={i}
                tableMenuSelect={tableMenuSelect}
              />
            );
          })}

          <div className="mt-3 px-3 flex flex-row items-center justify-between text-sm font-medium text-gray-600">
            <div className="mr-3">
              <button className="btn text-white  bg-danger btn-sm">
                Cancel
              </button>
            </div>
            <div className="">
              <button
                className="px-4 py-1.5 border-[1px] border-gray-200 rounded-sm hover:bg-gray-100 active:bg-gray-200 outline-none focus:outline-none"
                onClick={() => saveCurrentTableHeader()}
              >
                Save
              </button>
            </div>
          </div>
        </ul>
      </div>
      <div className="mt-3 overflow-x-auto outline-none border-[1px] border-gray-200 rounded-xl">
        <DataGrid
          className="outline-none w-full border-0"
          style={{ height: 500, width: "100%" }}
          columns={filteredHeader ? filteredHeader : []}
          pageSize={6}
          rows={props?.tableData?.map((item, i) => {
            return { ...item, id: i };
          })}
          rowsPerPageOptions={[6]}
          onRowClick={(event) => {
            OnItemCellClick(event);
          }}
          checkboxSelection={true}
          onRowSelectionModelChange={onSelectChange}
          components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
          }}
          onCellClick={(event) => {
            event.defaultMuiPrevented = true;
          }}
          sx={{
            // boxShadow: 2,
            // border: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
              cursor: "pointer",
            },
          }}
        />
      </div>
    </>
  );
}

export default InvoiceTable;
