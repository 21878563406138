import React, { useContext, useEffect, useState } from "react";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { TableContainer } from "@mui/material";
import select from "../assets/selectarrow.png";
import "../components/RateHistory.css"
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import { GetItemRateHistory, SetItemRateHistory } from "../store/itemAtom";
import { itemsService } from "../services/items.service";
import { useForm } from "react-hook-form";
import { DataGrid, gridPageCountSelector, gridPageSelector,GridToolbarContainer,
  GridToolbarExport, useGridApiContext, useGridSelector,} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import underscore from "underscore";
import LogicContext from "../Context/LogicContext";
import {  useParams } from "react-router-dom";
import AlertConfirm from "../helpers/AlertConfirm";

const salesRateHeader = [
  {
    field: "Rate_Changed_Date",
    headerName: "Date",
    width: 195,
    headerClassName: "tableHeader ",
  },
  {
    field: "Changed_By_Name",
    headerName: "Changed By",
    width: 195,
    headerClassName: "tableHeader ",
  },
  {
    field: "Remark",
    headerName: "Reason",
    width: 195,
    headerClassName: "tableHeader ",
  },
  {
    field: "Total_Amount",
    headerName: "Rate",
    width: 195,
    headerClassName: "tableHeader ",
  },
];

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}





function RateHistory(props) {
  const { register,setValue,  getValues, handleSubmit, reset, formState: { errors },} = useForm();
  const setItemRate = SetItemRateHistory();
  const salesRate = GetItemRateHistory();
  const [sales, setSales] = useState([]);
  const [cost, setCost] = useState([]);
  const logicContext = useContext(LogicContext);
  const { item_id } = useParams();
  const [alertState, setAlertState] = useState(false);

  setValue('Item_Id', item_id);


  useEffect(() => {
    if (sales.length < 1) {
      itemsService.getItemRateHistory(props.item_id).then((res) => {
          if (res.data !== undefined) {
            var rateHistory = underscore.groupBy(  res?.data,"Item_Rate_Type_Id" );

            let rawSales = rateHistory[1];
            let rawCost = rateHistory[2];

            let salesRateList = rawSales.map((item, i) => { return { ...item, id: i }  });
            setSales(salesRateList);

            let costRateList = rawCost.map((item, i) => { return { ...item, id: i } });
            setCost(costRateList);
          }
        })
        .catch((err) => {
          logicContext.showToast("No Rate history found!", "info");
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item_id]);



  const onSubmit = () => {
    setAlertState(true);
  
   
  };



  function onErrors(error){
    console.error(errors)
    logicContext.showToast( 'One or more field is required!', 'warning');
  }



  function closeAlert(response){
    setAlertState(false);
    if(response === true){
      let bodyData = getValues();
      itemsService.submitRateHistory({...bodyData, Option: 1}).then(res => {
        if(res.data !== undefined){
          logicContext.showToast( res.data[0]['Message_Text'], 'success');
          reset({})
        }
      }).catch(err => {
        logicContext.showToast( 'Error saving record, please try again', 'error');
      })

    }
  }


  return (
    <>
    {alertState ? (
        <AlertConfirm title={"Confirmation?"}body={"Do you want to save?"} closeAlert={closeAlert}
        ></AlertConfirm>
      ) : null}
     <form onSubmit={ handleSubmit(onSubmit, onErrors)}>
     <section>
        <section className="flex rate mt-3 justify-between">
          <div className="flex ">
            <div className="flex text-sm">
              <div className="ml-2 flex">
                <div>
                  {" "}
                  <input
                        {...register("Item_Rate_Type_Id", {
                          required: "Type is required",
                        })}
                    type="radio"
                    className="w-[1vw] h-[2.1vh]"
                    value="1"
                  />
                </div>
                <div className="mt-[0.3vh]">
                  {" "}
                  <span className="text-sm ml-2">Sales Rate</span>
                </div>
              </div>
            </div>
            <div className="flex ml-5 text-sm">
              <div className="ml-2 flex">
                <div>
                  {" "}
                  <input
                    type="radio"
                    {...register("Item_Rate_Type_Id", {
                      required: "Type is required",
                    })}
                    className="w-[1vw] h-[2.1vh]"
                    value="2"
                  />
                </div>
                <div className="mt-[0.3vh]">
                  {" "}
                  <span className="text-sm ml-2">Cost Rate</span>
                </div>
              </div>
            </div>
         
          </div>
         
          <div className="input flex justify-between w-45">
          <div className="relative">
             <input className=" bg-filterInput"
               {...register("Item_Rate", {
                required: "Item rate is required",
              })}
             type="number"   />
             <span className="absolute right-2 top-0.5 px-2 py-[0.2vh] text-sm bg-white">NGN</span>

          </div>
          <div>
            <span className="text-sm">Effective Date</span>
            <input    {...register("Effective_Date")} className="bg-filterInput" type="date" />
          </div>
          </div>
          
          <button className="px-3 rounded py-1 text-white"         style={{ backgroundColor: "#476EF8" }}>Save</button>
        </section>
        <section className="mt-3">
          <div>
            <span>Remark</span>
            <input 
            {...register("Rate_Change_Remark")}
             className="border-0 bg-filterInput h-[5vh] w-[50vw]" type="text" />
          </div>
        </section>
      </section>
     </form>

      <section className="mt-10">
        <span className="font-bold text-sm">Sales Rate History</span>

        <div style={{ height: 250, width: "100%" }}>
          <DataGrid
            columns={salesRateHeader}
            rows={sales}
            components={{
              Pagination: CustomPagination,
            }}
          />
        </div>
      </section>

      <section className="mt-10">
        <span className="font-bold text-sm">Cost Rate History</span>
        <div className="mt-2" style={{ height: 250, width: "100%" }}>
          <DataGrid
            columns={salesRateHeader}
            rows={cost}
            components={{
              Pagination: CustomPagination,
            }}
          />
        </div>
      </section>
    </>
  );
}

export default RateHistory;
