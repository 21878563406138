import { DataGrid ,  gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport ,
    useGridApiContext,
    useGridSelector} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';
  import image from '../assets/add.svg'
import { GetChartTableHead, SetChartTableHead } from "../store/chartOfAccountAtom";
import ChartAccountTableSelect from "./ChartAccountTableSelect";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }



function ChartOfAccountTable(props) {
  const  tableHead = GetChartTableHead();
  const  setTableHead = SetChartTableHead();

  const navigate = useNavigate();
  const { pathname } = useLocation();  
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const filteredHeader = tableHead?.filter((item) => item.Display_Id === 1).map((item) => {
    return {
      ...item,
      field: item.Column_Value.trim(),
      // headerClassName: "bg-gray-200 ",
      headerName: item.Column_Name,
      width: 180,
    };
  });

 
  const tableMenuSelect = (option, i) => {
    var choice = option.Display_Id === 1 ? 0 : 1;
    var temp = tableHead?.map((item) => {
        if (item.Ref_No === option.Ref_No) {
          return { ...item, Display_Id: choice };
        }
        return item;
      }) ;
    // let newList = under
    setTableHead(temp);
  };


  function saveCurrentTableHeader() {
    var xmlString = "";
    filteredHeader?.map((element) => {
      if (element.Display_Id === 1) {
        xmlString += `<a><refno>${element.Ref_No} </refno></a>`;
      }
    });

  }


  function OnItemCellClick(event){
    const id = event.row['Account_Id'];
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`)
}


  function onSelectChange(selectedList) {
    var selection = selectedList?.map((index) => {
      return props.tableData[index];
    });
    props.selectedTableItems(selection);
  }


    return ( 
        <>
    <div className="dropdown mr-2">
        <button
          className="btn btn-primary bg-primary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-toggle="dropdown"
          aria-expanded="false"
        ></button>

        <ul className="dropdown-menu  scrollable-menu "
          role="menu"
          aria-labelledby="dropdownMenuButton1" >
          {tableHead?.map((val, i) => {
            return (
              <ChartAccountTableSelect
                key={i}
                val={val}
                i={i}
                tableMenuSelect={tableMenuSelect}
              />
            );
          })}

          <div className=" flex  justify-center">
            <div className="">
              {" "}
              <button
                style={{ backgroundColor: "#476EF8" }}
                className="btn text-white  btn-sm"
                onClick={() => saveCurrentTableHeader()}
              >
                Save
              </button>{" "}
            </div>
            <div className="ml-3">
              {" "}
              <button className="btn text-white  bg-danger btn-sm">
                Cancel
              </button>{" "}
            </div>
          </div>
        </ul>
      </div>
    <div className="table" >
          <DataGrid style={{ height: 500, width: '100%' }}
              columns={filteredHeader ? filteredHeader : []}
              rows={props.tableData?.map((item, i) => { return { ...item, id: i }; })}
            pageSize={6}
            onRowClick={(event)=> {
              OnItemCellClick(event);
            }}
            rowsPerPageOptions={[6]}
            checkboxSelection={true}
            onRowSelectionModelChange={onSelectChange}
            components={{ Toolbar: CustomToolbar, Pagination: CustomPagination }}
              onCellClick={( event) => { event.defaultMuiPrevented = true }}
              
          
              sx={{
                // boxShadow: 2,
                // border: 2,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                  cursor: 'pointer',
                },
              }}
          />
      
        </div>
        
        </>
     );
}

export default ChartOfAccountTable;