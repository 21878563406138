import { atom , useSetRecoilState, useRecoilValue, selector} from "recoil";


export const editCustomerAtom = atom({key:'editCustomer',default:{}});
export const editCustomerAtomNew = atom({key:'customer_list_atom_new',default:{customers:[]
    ,start:0, end:0, total: 0}});
export const editCustomerAtomNewBackup = atom({key:'customer_list_atom_new_backup',default:{customers:[]
    ,start:0, end:0, total: 0}});

export const customerListAtom = atom({key:'customerList',default:[]});
export const customerDetailsAtom = atom({key:'customerDetailsAtom',default:{}});
export const customerTableHeaderAtom = atom({key:'customerTableHeaderAtom',default:[]});

export  const SetCustomerListAtom = () => useSetRecoilState(editCustomerAtomNew);
export  const GetCustomerListAtom = () => useRecoilValue(editCustomerAtomNew);
export  const SetCustomerListBackUpAtom = () => useSetRecoilState(editCustomerAtomNewBackup);
export  const GetCustomerListBackUpAtom = () => useRecoilValue(editCustomerAtomNewBackup);



export const modifyCustomerHeadSelector = selector({
    key:'modifyCustomerHeadSelector',
    get: ({get}) => get(customerTableHeaderAtom),
    set: ({set, get}, Ref_No) => {
        const tableHeader = get(customerTableHeaderAtom);
        if (tableHeader.Ref_No === 1 ){
            return  {
                ...tableHeader, Display_Id : 0
            };
        }
        return tableHeader;
    }
})





export const UseSetCustomerListData = () => useSetRecoilState(customerListAtom);
export const CustomerList = () => useRecoilValue(customerListAtom);
