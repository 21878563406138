import { useEffect } from 'react';
import Select from 'react-select';


function ProductFeatureSelect(props) {


  useEffect(() => {
    console.info('def ', props?.defaultVal);

  }, [props?.defaultVal]);

  const customOption = props?.list?.map((item) => {
    return ({ ...item, label: `${item.Action_URL_Name}` });
  });


  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      backgroundColor: '#F1F1F1',
      zIndex: 9999,
      width: 250,
    }),

    indicatorSeparator: styles => ({ ...styles, display: 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '', height: 20, paddingBottom: "45px", marginLeft: "13px" }),
    option: styles => ({ ...styles, backgroundColor: 'white', color: "black" }),
    menuPortal: styles => ({ ...styles, zIndex: 99999 })
  }

  return (
    <>
      <div style={{ width: '300px' }}>
        <Select menuPlacement="auto"
        placeholder="Select one"
          menuPosition="fixed" defaultValue={{label: props?.defaultVal }}
          onChange={(e) => props?.onFeatureSelect(e)} options={customOption} styles={customStyles} />
      </div>






    </>
  );
}

export default ProductFeatureSelect;
