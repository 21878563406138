import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { subscriptionService } from "../../services/subscription.service";
import LogicContext from "../../Context/LogicContext";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import {
  SetSubList,
  GetSubList,
  SetSubMasterData,
  GetSubMasterData,
  SetTableHeader,
} from "../../store/subscriptionAtom";
import underscore from "underscore";
// import CsvDownload from "react-json-to-csv";
import Tooltip from "@mui/material/Tooltip";
import RestartAlt from "@mui/icons-material/RestartAlt";
import SubscriptionTable from "../../components/SubscriptionTable";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";

function SubscriptionPage() {
  const logicContext = useContext(LogicContext);
  const subList = GetSubList();
  const setSubList = SetSubList();
  const setSubMasterData = SetSubMasterData();
  const setTableHeader = SetTableHeader();
  const myPromiseModal = createModal(GeneralModal);
  const masterData = GetSubMasterData();

  const [itemSelected, setItemSelected] = useState([]);
  const [tabledata, setTableData] = useState([]);
  const [bulkActionData, setBulkActionData] = useState([]);


  const loadDataCallBack = useCallback(()=> {
    subscriptionService.getSubList().then((res) => {
      if (res?.data !== undefined) {
        setSubList(res?.data);
        setTableData(res?.data);
        
      }
    });
  
  },[setSubList])



  useEffect(() => {
    let isMounted = true;
    if(isMounted){
  
      if (subList.length < 1) {
        loadDataCallBack();
      } else {
        setTableData(subList);
      }
  
      if (masterData[4] !== undefined) {
        setBulkActionData(masterData[4]);
      }
    }

    return ()=> {
      isMounted = false;
    }

  }, [masterData, subList]);


  useEffect(()=> {
    if (masterData[1] === undefined) {
      subscriptionService.getMasterData().then((res) => {
        if (res?.data !== undefined) {
          const newGroup = underscore.groupBy(res.data, "Master_Type");
          setSubMasterData(newGroup);
          setTableHeader(newGroup[1]);
        }
      });
    }
  },[masterData])


  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  function customFilter(code) {
    switch (code) {
      case 1:
        setTableData(subList);
        break;
      case 2:
        const activeList = subList.filter(
          (item) => item.Subscription_Status_Id === 1
        );
        setTableData(activeList);
        break;
      case 3:
        const trialList = subList.filter(
          (item) => item.Subscription_Status_Id === 2
        );
        setTableData(trialList);
        break;

      // case 3:
      //   const trialList = subList.filter(item =>  item.Subscription_Status_Id ===3)
      //   setTableData(trialList)
      //   break;

      default:
        break;
    }
  }

  function selectedTableItems(itemList) {
    setItemSelected(itemList);
  }

  const resetselecteditems = () => {
    setItemSelected([]);
  };

  async function bulkActions(masterOption) {
    await myPromiseModal({
      title: masterOption?.Name,
      body: `Do you want to set ${masterOption?.Name} for selected items?`,
      showInput:false, placeHolder:''
    }).then((result) => {
      if (result.decision === true) {
        var xmlString = "";
        itemSelected.map((sub, i) => {
          xmlString += `<refno>${sub.Subscription_Ref_No} </refno>`;
        });

        subscriptionService
          .submitBulkAction({
            option: masterOption.Parent_Name1,
            xml_string_subs: `<root><a> ${xmlString} </a> </root>`,
          })
          .then((res) => {
            const { Message_Text, Message_Id } = res?.data[0];
            if (res.data !== undefined && Number(Message_Id) === 1) {
              loadDataCallBack();
              logicContext.showToast(Message_Text, "success");
            } else {
              logicContext.showToast("Error processing request!", "error");
            }
          });
      }
    });

  }

  function deleteItems() {
    // var xmlString = "";
    // itemSelect?.map((item) => {xmlString += `<a><refno>${item.Item_Id} </refno></a>`;
    // });
    // itemsService.deleteItems(`<root> ${xmlString} </root>`)
    //   .then((res) => {
    //     loadItemsFromServer();
    //   })
    //   .catch((err) => console.log("err ", err));
  }

  return (
    <>
      {itemSelected?.length > 0 ? (
        <div className="row">
          <div className="col col-md-4">
            <Dropdown>
              <Dropdown.Toggle
                style={{
                  backgroundColor: "#E5E5E5",
                  border: "none",
                  color: "#45444B",
                  fontSize: "12px",
                  height: "45px",
                }}
                id="dropdown-basic"
              >
                Bulk Actions
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {bulkActionData?.map((option, i) => {
                  return (
                    <Dropdown.Item key={i} onClick={() => bulkActions(option)}>
                      {option?.Name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="col col-md-4 offset-md-4 text-center">
            <button onClick={resetselecteditems} className="btn btn-danger">
              X
            </button>
          </div>
        </div>
      ) : (
        <Grid container spacing={10}>
          <Grid item xs={4}>
            <div className="flex ">
              <div className="flex mt-1  rounded bg-filterColor pr-4 -ml-2 ">
                <Dropdown>
                  <Dropdown.Toggle
                    style={{
                      backgroundColor: "#E5E5E5",
                      border: "none",
                      color: "#45444B",
                      fontSize: "12px",
                      height: "45px",
                    }}
                    id="dropdown-basic"
                  >
                    FILTER BY
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => customFilter(1)}>
                      All
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(2)}>
                      Active
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(3)}>
                      Trial
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(4)}>
                      Trial Expired in the Previous Week
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(5)}>
                      Trial Expiring in the Next Week
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(6)}>
                      Trial Expiring in the next seven Days
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(7)}>
                      Dunning
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(8)}>
                      Unpaid
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(9)}>
                      Subscription with Unpaid Invoices
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(10)}>
                      Subscription with Pending Invoices
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(11)}>
                      Canceled this Month
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(12)}>
                      Canceled last Month
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(13)}>
                      Non-renewing
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(14)}>
                      Subscription Expiring this Month
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(15)}>
                      Paused Subscriptions
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(16)}>
                      Metered Billing Enabled
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => customFilter(17)}>
                      Subscriptions with Unbilled Charges
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <Tooltip title="Reload">
                <RestartAlt
                  className="cursor ml-2 mt-1"
                  onClick={() => loadDataCallBack()}
                  color="primary"
                  fontSize="large"
                />
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className="flex  justify-end">
              <div className="flex ">
                <button
                  style={{ color: "#476ef8" }}
                  className="  rounded  text-md font-[Poppins] py-2 mt-1  px-3"
                >
                  Import
                </button>

                <button
                  style={{ color: "#476ef8" }}
                  className=" mx-2 rounded  text-md font-[Poppins] mt-1 py-[1.8vh]  px-3"
                >
                  Export
                </button>
              </div>

              <Link
                to={`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`}
              >
                <button
                  style={{ backgroundColor: "#476EF8" }}
                  className="  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
                >
                  ADD NEW
                </button>
              </Link>
            </div>
          </Grid>
        </Grid>
      )}

      <section className="mt-5   w-full">
        <SubscriptionTable
          tabledata={tabledata}
          selectedTableItems={selectedTableItems}
        ></SubscriptionTable>
      </section>
    </>
  );
}

export default SubscriptionPage;
