import { atom , useSetRecoilState, useRecoilValue} from "recoil";




export const currentMenuListAtom = atom({key:'currentMenuList',default:{}});


export const UseCurrentMenuState = () => useSetRecoilState(currentMenuListAtom);
export const CurrentMenuList = () => useRecoilValue(currentMenuListAtom);
