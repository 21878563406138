import { useEffect, useState } from "react";


function AddonTypeSelect(props) {
    const [oneTime, setOneTime] = useState(true); 
    const [reOccuring, setreOccuring] = useState(false); 

    useEffect(()=> {
        if(props.val === 1){
          setOneTime(true);
          setreOccuring(false);
        }else if(props.val === 2){
          setOneTime(false);
          setreOccuring(true);
        }
      
      },[props.val])


      const oneTimeChange = () => {
        setOneTime(true);
        setreOccuring(false);
        props.onAddonTypeSelect(1);
      };

      const onReoccuringChange = () => {
        setOneTime(false);
        setreOccuring(true);
        props.onAddonTypeSelect(2);
      };


    return ( 
        <>
          <div className=" text-sm flex">
                        <div className=" flex">
                          <div>
                            {" "}
                            <input
                              type="radio"
                              checked={oneTime}  onChange={oneTimeChange}
                              className="w-[0.8vw] h-[1.6vh]"
                              value="1"
                            />
                          </div>
                          <div className="mt-[0.2vh]">
                            {" "}
                            <span className="text-xs ml-2"> One Time</span>
                          </div>
                        </div>
                        <div className="ml-2 flex">
                          <div>
                            {" "}
                            <input
                            checked={reOccuring}  onChange={onReoccuringChange}
                              type="radio"
                              className="w-[0.8vw] h-[1.6vh]"
                              value="2"
                            />
                          </div>
                          <div className="mt-[0.2vh]">
                            {" "}
                            <span className="text-xs ml-2">Recurring</span>
                          </div>
                        </div>
                      </div>
        </>
     );
}

export default AddonTypeSelect;