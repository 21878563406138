import Item from "antd/lib/list/Item";
import { useState } from "react";


function NewItemGroupSearch(props) {

    const [earchResult, setSearchResult] = useState([]); 


    function customerSearchChange (ev) {
        const search = ev.target.value;

        if (search.length <  2) {
          setSearchResult([])
        }else{
          var tempResult = props.list.filter((item)=>{
         
            return item?.Item_Name.toLowerCase().indexOf(search.toLowerCase()) >= 0 
            || item?.Item_Sku?.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0 
            || item?.Item_EAN?.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0 
            || item?.Item_UPC?.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0 
            || item?.Item_Brand_Name?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
            ||  item?.Item_Id.toString()?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
          });
  
          setSearchResult(tempResult);
        }
     

    }


    function clearInput(){
        setSearchResult([])
    }




    return ( 
        <>
        
        <div className="dropdown">
              <input
                onChange={(ev)=> customerSearchChange(ev)}
                  className="px-4 bg-inputColor  py-2 h-[10] w-100"
                  placeholder="Search and add item"
                />

                {earchResult.length > 0 ? 
                      <ul  className="dropdown-menu stay-open" aria-labelledby="dropdownMenuButton1">
                      {earchResult.map((item, i)=> {
                        return (
                        
                          <li onClick={()=> {props.selectItem(item); clearInput()}} key={i}>
                            
                            <div className="dropdown-item  border">
                              <div  className=""> 
                              <small className="" > {item?.Item_Name} </small> 
                              <small>{item.Item_Id} </small>
                              </div>
                              
                            </div>
                            </li>
                        )
                      })}
               
                    </ul>
                : null}
          
              </div>
        </>
     );
}

export default NewItemGroupSearch;