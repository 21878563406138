import { atom , useSetRecoilState, useRecoilValue} from "recoil";




export const adsStoreAtom = atom({key:'addsStoreAtom',default:[]});
export const adsEditAtom = atom({key:'ads_edit_atom',default:{}});



export const SetAdsStoreData = () => useSetRecoilState(adsStoreAtom);
export const GetAdsStoreData = () => useRecoilValue(adsStoreAtom);

export const SetEditAdsStore = () => useSetRecoilState(adsEditAtom);
export const GetEditAdsStore = () => useRecoilValue(adsEditAtom);
