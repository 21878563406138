
import { Grid } from "@mui/material";

function AddNotificationScrol(props) {
  




  return (
    <>
      <section className="w-30  z-0 mt-3 main2 	 border-2 b">
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
           
            {props?.list?.map((item, i)=> {
              return (
                  <div key={i} className="flex mt-1 border-2 justify-center cursor">
                  <section className="w-full text-center" onClick={()=> props?.onTemplateSelect(item)} >
                  <div className="ml-2 m-2 text-sm font-bold"> {item?.Template_Name} </div>
                  <div className="ml-2 m-2 text-sm">{item?.Template_Message} </div>
                  </section>
              </div>
                )
              })}
         
            </Grid>
            
          </Grid>
        </div>
      </section>
    </>
  );
}

export default AddNotificationScrol;
