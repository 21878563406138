import { useEffect, useState } from "react";

function RedemptionTypeSelect(props) {
    const [oneTime, setOneTime] = useState(true); 
    const [forever, setForever] = useState(false); 
    const [limited, setLimited] = useState(false); 

    useEffect(()=> {
        if(props.val === 1){
          setOneTime(true);
          setForever(false);
          setLimited(false);
        }else if(props.val === 2){
          setOneTime(false);
          setForever(true);
          setLimited(false);
        }else if(props.val === 3){
          setOneTime(false);
          setForever(false);
          setLimited(true);
        }
      },[props.val])


      const oneTimeChange = () => {
        setOneTime(true);
        setForever(false);
        setLimited(false);
        props.onRedemptionSelect(1);
      };

      const onForeverChange = () => {
        setOneTime(false);
        setForever(true);
        setLimited(false);
        props.onRedemptionSelect(2);
      };

      const onLimitedChange = () => {
        setOneTime(false);
        setForever(false);
        setLimited(true);
        props.onRedemptionSelect(3);
      };




    return (  
        <>
        <div className="flex text-sm">
                  <div className="ml-2 flex">
                    <div>
                      {" "}
                      <input
                        type="radio"
                        className="w-[0.8vw] h-[1.6vh]"
                        value="1"
                        checked={oneTime}  onChange={oneTimeChange}
                      />
                    </div>
                    <div className="mt-[0.3vh]">
                      {" "}
                      <span className="text-xs ml-2">One Time</span>
                    </div>
                  </div>
                  <div className="ml-2 flex">
                    <div>
                      {" "}
                      <input
                       checked={forever}  onChange={onForeverChange}
                        type="radio"
                        className="w-[0.8vw] h-[1.6vh]"
                        value="1"
                      />
                    </div>
                    <div className="mt-[0.3vh]">
                      {" "}
                      <span className="text-xs ml-2">Forever</span>
                    </div>
                  </div>
                  <div className="ml-2 flex">
                    <div>
                      {" "}
                      <input
                       checked={limited}  onChange={onLimitedChange}
                        type="radio"
                        className="w-[0.8vw] h-[1.6vh]"
                        value="2"
                      />
                    </div>
                    <div className="mt-[0.3vh]">
                      {" "}
                      <span className="text-xs ml-2">
                      Limited No of Times
                      </span>
                    </div>
                  </div>
                </div>
        </>
    );
}

export default RedemptionTypeSelect;