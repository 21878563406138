// import CustomerContext from "../Context/CustomerContext";
import { useEffect, useState } from "react";
import { useContext } from "react";
import {  GetCarrierBagStoreList } from "../../store/carrierBagReturn";

import { Grid } from "@mui/material";
import CurrencyFormat from 'react-currency-format';
import  moment from 'moment';
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import {  useParams } from "react-router-dom";
import { GetRewardHistoryStoreList  } from "../../store/rewardHistoryAtom";



function CarrierScroller(props) {

//   const getInvoiceFilters = GetInvoiceFilter();
const getRecoilList = GetCarrierBagStoreList();
 const [tableData, setTableData] = useState([]); 
 const { id } = useParams();



 useEffect(()=> {
setTableData(getRecoilList.map(ele => { return  {...ele, checked: false}} ))
 },[getRecoilList]);

 


// const onSelectBulkAction = (action)=> {
//   const option = action.target.value;
//   if(option === ''){
//       return setTableData(invoiceList?.map(ele => { return {...ele, checked: false} }));
//   }
//   var newList = invoiceList.filter((item)=> item.Invoice_Status_Id == option );
//   setTableData(newList?.map(ele => { return {...ele, checked: false} }));
// }


const onCheckAll = async (e, index)=>{
  let element =  tableData[index];
  tableData[index] = {...element, checked:e.target.checked}
 await setTableData((prev)=> { return([...tableData])});
 props?.onSelection(tableData);
}


const onCheckboxClick = async (e, index) => {
  let element = tableData[index];
  tableData[index] = { ...element, checked: e.target.checked };
  await setTableData((prev) => {
    return [...tableData];
  });
  props?.onSelection(tableData);
};


const manageStatus = (statusCode, statusName) =>{
  let defaultClass = 'text-primary';

  switch (statusCode) {
    case 1:
    defaultClass = 'color_1';
      break;
    case  2:
    defaultClass = 'color_2';
      break;
    case 3:
    defaultClass = 'color_3';
      break;
    case 4:
    defaultClass = 'color_4';
      break;
    case 5:
    defaultClass = 'color_5';
      break;
  
    case 6:
    defaultClass = 'color_6';
      break;
  
    default:
      break;
  }


      return (
        <span className={`text-sm font-bold  ${defaultClass} `}>
        {statusName}
      </span>
     
      )}




      return ( 
        <>
     <section className="w-30  z-0 mt-3 main2 	 border-2 b">
      {/* <ActionPaneComponent bulkActionData={billListData['Filtering_Criteria']}
        onSelectBulkAction={onActionFilterPerform} />
      <div>
        <label className=" mt-3 mb-3 ml-2 ">
          <input onChange={(e) => onCheckAllElement(checkedAll)}
            type="checkbox" className="w-[2vh] h-[2vh]" checked={checkedAll ? checkedAll : ''} />
          <span className="ml-2 font-bold " >Select all</span>
        </label>
      </div> */}

      {tableData?.map((info, index) => {
        return (

          <div key={index} onClick={() => props.viewItemDetails(info?.Ref_No)}
            className={"flex hover:bg-filterColor border  "
              + (Number(id) === info?.Ref_No ? "app_bg_color1 mb-2 show-cursor" : "mb-2 show-cursor")}
          >
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <label className="ml-1">
                  <input onChange={(e) => onCheckboxClick(e, index)} checked={info?.checked ? info?.checked : ''}
                    type="checkbox" className="w-[2vh] h-[2vh]" />
                </label>
              </Grid>
              <Grid 
                item xs={6}>
                <label className="ml-2 ">
                  <span className="subheading font-bold md:text-sm">{info?.Corporate_Name} </span>{" "}
                  <br></br>
                  <span className="subheading font-bold md:text-sm">
                    <i>{info?.Return_Date_String} </i>
                  </span>
                  <br></br>
                  <div>{info?.Customer_Full_Name} </div>
                </label>
              </Grid>
              <Grid item xs={5}>

                <div>    <label className="ml-2" >
                  {/* {manageStatus(info?.Corporate_Approval_Status_Id, info?.Corporate_Approval_Status_Name)} */}
                  {info?.Bag_Quantity}

                    </label></div>

              </Grid>
            </Grid>
          </div>

        )
      })

      }

    </section>
        </>
      );

}

export default CarrierScroller;
