import "./UserPage.css";
import { useContext } from "react";
import React from "react";
import LogicContext from "../Context/LogicContext";
import { useNavigate } from "react-router-dom";
import { settingsService } from "../services/settingsService";
import { SetNotificationList } from "../store/NotificationAtom";




function ModuleListPage() {
  const logicContext = useContext(LogicContext);
  const navigate = useNavigate();
  const setNotifications = SetNotificationList();

  const moduleMenuList = logicContext.getModule();

  const modalSelection = (selection) => {
    logicContext.moduleSelect(selection.Module_Id);
    notificationLoader(selection.Module_Id);
    navigate(`/${selection.Module_Id}/${selection.Module_Name}/dashboard`);
  };


  const notificationLoader = (id)=> {
    settingsService.getNotificationByModule(id).then(res => {
      if(res?.data?.length){
        setNotifications(res?.data);
      }
    });

  }


  return (
    <div>
      <div className="max-w-6xl mt-20  w-90 mx-auto">
        <div className="h-40 grid grid-cols-1 gap-2 content-start md:grid-cols-3  ">
          {Object.keys(moduleMenuList).map((key, i) => {
            return (
              <div
                key={i}
                onClick={() => modalSelection(moduleMenuList[key][0])}
                className="bg-moduleColor shadow-sm h-[40hv] rounded transition duration-500 ease-in-out  hover:bg-blue-500 transform hover:-translate-y-1 hover:scale-100 sm:h-[50vh] hover:text-white md:h-[40vh]  xl:h-[40vh]"
              >
                <div className="bg-moduleColor cursor-pointer h-[6vh] w-[6vh] rounded-full mt-[5vh] ml-10 md:h-[6vh] w-[6vh] rounded-full mt-[5vh] ml-10">
                  {/* <img
                  src={item.photo}
                  className="ml-2 w-55 pt-2 first-line:md:ml-2 pt-2"
                ></img> */}
                </div>
                <p className="ml-10 py-2 text-lg cursor-pointer xl:text-lg">{key}</p>
                <p className="ml-10 mb-2 cursor-pointer text-sm xl:text-md">
                  {moduleMenuList[key][0]["Module_Description"]}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ModuleListPage;
