import React, { useRef, useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import moment from "moment/moment";
import message from "../../assets/message.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { MdOutlineCancel } from "react-icons/md";
import LogicContext from "../../Context/LogicContext";
import { useForm } from "react-hook-form";
import VoucherCreateTable from "./VoucherCreateTable";
import { GetVoucherListAtom, SetVoucherListAtom } from "../../store/voucherAtom";
import CooperateSelect from "./CooperateSelect";
import { voucherService } from "../../services/voucherService";
import { accountService } from "../../services/accountService";




const AddCorporateVoucherPage = () => {

    const navigate = useNavigate();
    const myPromiseModal = createModal(GeneralModal);
    const logicContext = useContext(LogicContext);
    const { register, setValue, getValues, handleSubmit, control, reset, formState: { errors } } = useForm();
    const getVoucherData = GetVoucherListAtom();
    const setVoucherAtomData = SetVoucherListAtom();


    useEffect(() => {



        return () => {
            // Cleanup
            setVoucherAtomData(prev => {
                return { ...prev, newVoucherList: [] };
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async () => {
        const newData = getValues();

        if (!newData?.customer_id) {
            return logicContext.showToast("Please select a corporate User!", "error");
        }

        await myPromiseModal({
            title: "Do you want to Create Voucher?", body: `The following action will create ${newData?.quantity} quantity of ₦${newData?.amount} Voucher(s)`,
            showInput: false, placeHolder: '',
        }).then((res) => {
            if (res.decision === true) {

                voucherService.createVoucher(newData).then((res) => {
                    if (res.data?.result_status[0]?.message_id === 1) {
                        setVoucherAtomData(prev => {
                            return { ...prev, newVoucherList: res.data?.voucher_info };
                        });
                        logicContext.showToast(res.data?.result_status[0]?.message_text, "success");
                    } else {
                        logicContext.showToast("error Creating Voucher", "error");

                    }


                });
            }
        }).catch((err) => {
            logicContext.showToast("error submitting record", "error");
        });
    };

    function formError(error) {
        logicContext.showToast("Form error", "error");
    }



    const shareActionButtonAll = async (selectedIdList) => {
        if (selectedIdList.length < 1) {
            return logicContext.showToast(`No selection was made. Please select one or more vouchers`, "error");
        }
        await myPromiseModal({
            title: "Confirm Share action?", body: `A total of  ${selectedIdList?.length} Voucher(s)  will be allocated`,
            showInput: false, placeHolder: '',
        }).then((res) => {
            if (res.decision === true) {

                let xml_voucher = "";
                let temp = "";
                let cop_ref = '';

                let i = 0;
                let j = 0;
                let found = false;
                while (i < selectedIdList.length && j < getVoucherData.newVoucherList.length) {
                    let voucherId = selectedIdList[i];
                    let voucher = getVoucherData.newVoucherList[j];
                    if (voucher?.Ref_No === voucherId) {
                        cop_ref = voucher?.Corporate_Ref_No;
                        found = true;
                        temp += `<a>
                        <voucher_no>${voucher.Voucher_Number} </voucher_no>
                        <name>${voucher?.Reciepient_Name ? voucher?.Reciepient_Name : ''} </name>
                        <mobile>${voucher?.Reciepient_Mobile_Number ? voucher?.Reciepient_Mobile_Number : ''} </mobile>
                        <email>${voucher?.Reciepient_Email_Address ? voucher?.Reciepient_Email_Address : ''} </email>
                         </a>`;
                        i += 1;
                    }
                    j += 1;
                }

                if (!found) {
                    return logicContext.showToast(`Selected Voucher(s) does not exist in table List`, "");
                }

                xml_voucher = `<root> ${temp} </root>`;
                voucherService.shareVouchers({ ref_no: cop_ref, xml_voucher: xml_voucher.replace(/^\s+|\s+$/gm, '') }).then((res) => {

                    voucherService.voucherDetails(cop_ref).then(res => {
                        if (res.data?.length) {
                            setVoucherAtomData(prev => {
                                return { ...prev, newVoucherList: res.data };
                            });
                        }
                    });
                });
            }
        }).catch((err) => {
            logicContext.showToast("error submitting record", "error");
        });

    }





    const onCooperateSelect = (user) => {
        setValue("customer_id", user?.Customer_Id);
    }


    function onErrors(error) {
        // logicContext.showToast("Form error", "error");
    }


    return (
        <>


            <section className="   mt-2 ">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <section className="flex relative justify-between ">

                            <section className="w-full z-50  ">
                                <section className=" ml-[2vw] mt-2 max-auto  text-filterTextColor">

                                    <Grid className="mt-2 border-2 p-2" container spacing={2}>

                                        <form onSubmit={handleSubmit(onSubmit, onErrors)} >


                                            <Grid item xs={12} container spacing={2} className="border-2 bg-light  my-2 mx-2">
                                                <div className="w-full flex justify-between">
                                                    <span className="text-sm text-filterTextColor font-bold">
                                                        VOUCHER CREATE
                                                    </span>
                                                    <div>
                                                        <MdOutlineCancel size={20} style={{ cursor: 'pointer' }} className=" poin pointer mx-2" />
                                                    </div>

                                                </div>



                                                <Grid item xs={6}>
                                                    <Grid item xs={12} container spacing={2} className=" bg-light  my-2 mx-2">
                                                        <Grid item xs={6}>
                                                            <label className=" mb-3">
                                                                <span className="text-sm text-filterTextColor font-bold">
                                                                    Corporate User <span className="text-danger font-bold" >*</span> :
                                                                </span>
                                                                <CooperateSelect onCooperateSelect={onCooperateSelect} list={getVoucherData.corporate_info} />

                                                            </label>

                                                        </Grid>
                                                        <Grid item className="flex justify-between" xs={12}>

                                                            <label className="mb-3 ">
                                                                <span className="text-sm text-filterTextColor font-bold">
                                                                    Total Voucher Quantity  <span className="text-danger font-bold" >*</span>:
                                                                </span>
                                                                <br></br>
                                                                <input
                                                                    className="bg-white  border-2  pl-3 lg:w-[20vw]"
                                                                    placeholder="enter quantity"
                                                                    name="quantity"
                                                                    type="number"
                                                                    {...register("quantity", {
                                                                        required: "quantity is required",
                                                                        pattern: /^[1-9]\d*/,
                                                                        message: 'Please enter a valid quantity!'
                                                                    })}
                                                                /> <br />
                                                                <small className="text-red-500">
                                                                    {Boolean(errors["quantity"]?.message) &&
                                                                        String(errors["quantity"]?.message)}
                                                                </small>
                                                            </label>

                                                            <label className=" mb-3">
                                                                <span className="text-sm text-filterTextColor font-bold">
                                                                    Amount Per Voucher  (₦) <span className="text-danger font-bold" >*</span> :
                                                                </span>
                                                                <br></br>
                                                                <input
                                                                    className="bg-white  border-2  pl-3 lg:w-[20vw]"
                                                                    placeholder="enter amount per voucher"
                                                                    type="number"
                                                                    name="amount"
                                                                    {...register("amount", {
                                                                        required: "Amount is required",
                                                                        pattern: /^[1-9]\d*/,
                                                                        message: 'Please enter a valid amount!'
                                                                    })}
                                                                /> <br />
                                                                <small className="text-red-500">
                                                                    {Boolean(errors["amount"]?.message) &&
                                                                        String(errors["amount"]?.message)}
                                                                </small>
                                                            </label>

                                                        </Grid>
                                                    </Grid>



                                                </Grid>



                                                <Grid item xs={6}>
                                                <label className="ml-10 " >
                                                        <span className="text-sm text-filterTextColor font-bold">
                                                            Description:
                                                        </span>
                                                        <br></br>

                                                        <input
                                                            className="bg-white border-2 h-[15vh] pl-3 w-[25vw]"
                                                            placeholder="enter notification message"
                                                            name="message"
                                                            {...register("remark", {
                                                            })}
                                                        />

                                                        <br></br>
                                                    </label>
                                                </Grid>

                                                <Grid item xs={12} className="flex justify-end mx-2 my-2">

                                                    <button type="submit" className="rounded-lg bg-blue-700 text-white   px-4 py-2 mx-2 ">
                                                        Purchase
                                                    </button>
                                                </Grid>
                                            </Grid>
                                        </form>


                                        {getVoucherData?.newVoucherList?.length &&

                                            <>
                                                <Grid item xs={12} className="border-2 my-2 bg-light mx-2">
                                                    <span className="text-sm mt-2 text-filterTextColor text-center  ml-2 font-bold">
                                                        NEW VOUCHER LIST
                                                    </span>
                                                    <VoucherCreateTable shareActionButtonAll={shareActionButtonAll} newVoucherList={getVoucherData.newVoucherList} ></VoucherCreateTable>

                                                </Grid>


                                            </>

                                        }


                                    </Grid>
                                </section>
                            </section>
                        </section>
                    </Grid>
                </Grid>
            </section>

        </>
    );
};

export default AddCorporateVoucherPage;
