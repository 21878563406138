import * as React from "react";

import { useState } from "react";

import "../../pages/ScrollB.css";
// import "./CustomerIventroyTransaction.css";
import VendorTransactionInner from "./VendorTransactionInner";
import VendorTSelect from "./VendorTSelect";
import VendorTBillpayment from "./VendorTBillpayment";
import VendorTPO from "./VendorTPO";
// import VendorTPR from "./CustomerInventoryItems/VendorTPR.JSX";
import VendorTPC from "./VendorTPC";
// import VendorTPR from "./CustomerInventoryItems/VendorTPRV.jsx";
import VendorTPRV from "./VendorTPRV.jsx";

function VendorTransaction(props) {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <>
      {/*  New tab  */}
      <div className="container">
        <div className="content-tabs">
          <div className="mx-4">
            <span className="text-md font-bold">Go to :</span>
            <VendorTSelect></VendorTSelect>
          </div>
          {/* inoice start */}
          <div
            className={
              toggleState === 1 ? "content  active-content" : "content"
            }
          >
            <VendorTransactionInner
              customer_id={props.customer_id}
            ></VendorTransactionInner>

            <div className="mt-2">
            <VendorTBillpayment></VendorTBillpayment>
            </div>
            <div className="mt-2">
            <VendorTPO></VendorTPO>
            </div>
            <div className="mt-2">
            <VendorTPRV></VendorTPRV>
            </div>
            <div className="mt-2">
            <VendorTPC></VendorTPC>
            </div>
        
                
                
          </div>

      
        </div>
      </div>
    </>
  );
}

export default VendorTransaction;
