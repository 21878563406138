import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";

function InvoicePaymentTable(props) {
  const { tableData } = props;

  return (
    <>
      <div className="mt-2">
        <TableContainer className="mt-3">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="font-weight-bold">Date</TableCell>
                <TableCell className="font-weight-bold">
                  Reference Number
                </TableCell>
                <TableCell className="font-weight-bold">Payment Mode</TableCell>
                <TableCell className="font-weight-bold">Amount</TableCell>
                <TableCell className="font-weight-bold">Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData?.map((payment, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      {payment.Payment_Date_String}{" "}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() =>
                        props.viewPaymentRecord(payment?.Payment_Ref_No)
                      }
                      className="text-primary cursor"
                    >
                      {payment.Payment_Ref_No}{" "}
                    </TableCell>
                    <TableCell align="left">
                      {payment.Payment_Mode_Name}{" "}
                    </TableCell>
                    <TableCell align="left">
                      <CurrencyFormat
                        className=""
                        value={payment.Payment_Amount?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {payment?.Payment_Status_Name}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default InvoicePaymentTable;
