
import React, { useEffect, useContext, useRef, useState } from "react";
import Userlayout from "../../layout/Userlayout";
import { Grid } from "@material-ui/core";
import WalletTable from "./WalletTable";
import Item from "antd/lib/list/Item";
import WalletDetailsTable from "./WalletDetailsTable";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { accountService } from "../../services/accountService";
import { useForm } from "react-hook-form";
import moment from "moment";
import WalletTypeSelect from "./WalletTypeSelect";
import LogicContext from "../../Context/LogicContext";





function WalletCheckDetails() {
    const { id } = useParams();
    const [selectedTypeValue, setSelectedTypeValue] = useState('1');
    const [customer, setCustomer] = useState({});
    const [walletList, setWalletList] = useState([]);
    const [defaultFromDate, setDefaultFromDate] = useState(moment(Date.now()).format("YYYY-MM-DD"));
    const [defaultToDate, setDefaultToDate] = useState(moment(Date.now()).format("YYYY-MM-DD"));
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState(0);
  const logicContext = useContext(LogicContext);

  



    useEffect(() => {
        document.title = "wallet Check Details";
        queryUserData();
       
        return () => {
        };
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id, selectedTypeValue, defaultToDate]);


      const selectedType = (eve)=> {
            setSelectedTypeValue(eve.target.value);
      }


      const resetDefaultDate = ()=> {
        setDefaultFromDate(moment(Date.now()).format("YYYY-MM-DD"));
        setDefaultToDate(moment(Date.now()).format("YYYY-MM-DD"));
    
      }


      const calculateTotal = (list) => {
        let total = 0;
        let deposit = 0;
        for (let i = 0; i < list.length; i++) {
            const element = list[i];
            total += element?.Total_Transaction_Amount;
            if(element?.transaction_type_id === 1){
                deposit +=  element?.Total_Transaction_Amount;
            }
        }
        setTotalAmount(total);
        setTotalDeposit(deposit);
      }


      const queryUserData = ()=> {
        accountService.walletCheckDetails({fr_date: defaultFromDate, to_date: defaultToDate , customer_id:id, wallet_type_id:selectedTypeValue}).then((res) => {
            console.log('Response ', res.data);
            if ( res.data?.customer?.length) {
              setCustomer(res.data?.customer[0]);
              
            }
            if (res.data?.transation_history?.length) {
              setWalletList(res.data?.transation_history);
              calculateTotal(res.data?.transation_history);
            }else{
              setWalletList([]);
              logicContext.showToast('No record for selected date range!');
              setTotalAmount(0);
            }
          });
      }

      const onFromDateChange = (e)=> {
        setDefaultFromDate(moment(e.target.valueAsDate).format("YYYY-MM-DD"));
      }

      const onToDateChange = (e)=> {
        setDefaultToDate(moment(e.target.valueAsDate).format("YYYY-MM-DD"));
      }


    return (
        <>     <section >
                    <Grid className="m-2 border-2 p-2" container spacing={2}>

                        <Grid item xs={5}>
                            <div className="text-black font-bold text-lg">
                                Customer ID: {customer?.Customer_Id}
                            </div>
                            <div>
                                <div>

                                    <span >
                                        <span className="text-lg font-bold " > Name</span>: <span style={{ color: "blue" }} className="text-sm">{customer?.Customer_Full_Name} </span>
                                    </span>{" "}
                                    <br></br>
                                    <span >
                                        <span className=" font-bold" > Email Address</span>: <span style={{ color: "blue" }} className="text-sm">{customer?.Customer_Email_Address} </span>
                                    </span>
                                    <br></br>
                                    <span >
                                        <span className=" font-bold" > Phone Number</span>: <span style={{ color: "blue" }} className="text-sm">{customer?.Customer_Mobile_No} </span>
                                    </span>
                                </div>

                            </div>
                        </Grid>
                       
                        <Grid  item xs={5}>
                           
                            <div className="pt-[3vh]">
                                <div>

                                    <span >
                                        <span className=" font-bold " > Registration Date</span>: <span style={{ color: "blue" }}
                                         className="text-sm">{moment(customer?.Encoded_Date).format("DD/MM/YYYY, h:mm:ss a")} </span>
                                    </span>{" "}
                                    <br></br>
                                    <span >
                                        <span className=" font-bold " > Zoho ID</span>: <span style={{ color: "blue" }} className="text-sm">{customer?.Zoho_Customer_Id} </span>
                                    </span>

                                </div>

                            </div>
                        </Grid>

                        <Grid item xs={2}>
                            <div className="flex justify-center">
                                {/* <button className="  font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8 text-sm">
                                    IMPORT
                                </button> */}
                                <div className="btn-group">
                                    <button className=" font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8   text-sm" data-toggle="dropdown" aria-expanded="false">
                                        EXPORT
                                    </button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <section className="bg-white  px-2    md:flex justify-between ">

                        <Grid container spacing={2}>


                            <Grid item xs={3}>
                                <div className="">
                                    <span className="text-sm ">From Date:</span>
                                    <div className="">
                                        <input
                                         value={defaultFromDate}
                                         onChange={(e)=> onFromDateChange(e)}
                                            className="bg-filterInput h-[5.6vh] w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                            type="date"

                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="">
                                    <span className="text-sm ">To Date:</span>
                                    <div className="">
                                        <input
                                        onChange={(e)=> onToDateChange(e)}
                                         value={defaultToDate}
                                        //  {...register("to_date")}
                                            className="bg-filterInput h-[5.6vh] w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                            type="date"

                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                               <WalletTypeSelect selectedType={selectedType} />
                            </Grid>

                            <Grid item xs={3} className="flex justify-start w-full">
                                <div className="mr-3" >
                                    <button
                                        type="submit"
                                        onClick={()=> resetDefaultDate()}
                                        className="text-sm text-white px-3 mt-4 rounded py-2 btn btn-danger bg-danger" >
                                        Reset Date
                                    </button>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        style={{ backgroundColor: "#476EF8" }}
                                        onClick={()=> queryUserData()}
                                        className="text-sm text-white px-3 mt-4 rounded py-2" >
                                        Search
                                    </button>
                                </div>
                            </Grid>

                        </Grid>


                        {/* <div className="flex justify-between">
         <button className="  font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8 text-sm">
           IMPORT
         </button>
         <div className="btn-group">
          <button  className=" font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8   text-sm" data-toggle="dropdown" aria-expanded="false">
           EXPORT
         </button>
       </div>
          
        
       </div> */}
     
                    </section>

    <div className="row mt-2 bg-primary" >
        <div className="col border-2 p-3 bg-light" >
        <h3>Transaction amount : ₦ {totalAmount.toLocaleString()} </h3>
        </div>

        <div className="col border-2 p-3 bg-light" >
        <h3>Deposit : ₦ {totalDeposit.toLocaleString()} </h3>
        </div>

    </div>

 
                    <section className="mt-5">
                        <WalletDetailsTable  walletList={walletList} />
                    </section>
                </section>
        </>

    )
}

export default WalletCheckDetails;
