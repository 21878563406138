import { useState, useEffect } from "react";



function PlanBillCycleTypeSelect(props) {
    const [auto, setAuto] = useState(true); 
    const [expires, setExpires] = useState(false);  
    const [manualValue, setManualValue] = useState(1);  



    useEffect(()=> {
      
      setManualValue(20);
        if(props.val === 1){
          setAuto(true);
          setExpires(false);
          setManualValue(props?.manual_value);
        }else if(props.val === 2){
          setAuto(false)
          setExpires(true);
          setManualValue(props?.manual_value);
        }
        else if(props.val === 3){
          setAuto(false)
          setExpires(false);
          setManualValue(props?.manual_value);
        }
      },[props?.manual_value, props.val])
      // props.val, props?.manual_value

    // onBillCycleSelect
    const handleAutoChange = () => {
        setAuto(true);
        setExpires(false);
        props.onBillCycleSelect(1);
        props.onManualBillDurationSelect(0)
      };


    const handleExpireChange = () => {
        setAuto(false);
        setExpires(true);
        props.onBillCycleSelect(2);
      };


      const manualValueChange =(e)=> {
          const val = e.target.value;
          if(val > 0){
            setManualValue(val);
            
            props.onManualBillDurationSelect(val)
          }
         
        //  
      }


    return (  
        <>
         <div>
            <div className=" text-sm">
              <div className="ml-2 flex">
                <div>
                  {" "}
                  <input
                  checked={auto}  onChange={handleAutoChange} 
                    type="radio"
                    className="w-[0.8vw] h-[1.6vh]"
                    value="1"
                  />
                </div>
                <div className="mt-[0.3vh]">
                  {" "}
                  <span className="text-xs ml-2">
                    {" "}
                    Auto renews until canceled
                  </span>
                </div>
              </div>
              <div className="ml-2 flex">
                <div>
                  {" "}
                  <input
                   checked={expires}  onChange={handleExpireChange} 
                    type="radio"
                    className="w-[0.8vw] h-[1.6vh]"
                    value="2"
                  />
                </div>
                <div className="mt-[0.3vh]">
                  {" "}
                  <span className="text-xs ml-2">
                    Expires after a specified no. of billing cycles
                  </span>
                </div>
                {expires ?
                <input name="expireAfter" type="number" className="ml-2 z-0"
                 style={{ backgroundColor:'#F1F1F1'}}
                 value={manualValue} onChange={manualValueChange} /> 
                 : null }

                
              </div>
            </div>
          </div>
        </>
    );
}

export default PlanBillCycleTypeSelect;