import React, { useEffect, useState } from 'react';
import {GetGroupItemList, SetGroupList, SetGroupBackup} from "../store/itemGroupAtom";
import {itemsGroupService} from '../services/itemGroup.service';
// import "../pages/ItemInformation.css"
import './scrollBar.css';


function ItemDetailsGroupScroll(props) {

const groupList  =  GetGroupItemList();
const setGList  =  SetGroupList();
const setBackup  =  SetGroupBackup();
const [list, setList] = useState(groupList)


useEffect(()=> {

  if(groupList.length === 0){
    itemsGroupService.getGroupItemsList().then(res => {
      if(res.data.length > 0){
        setList(res?.data);
        setGList(res?.data);
        setBackup(res?.data);
      }
  });
  }
},[])





  return (
    <>
        
    <section className=" scroll  z-0 mt-3	main border-2">
      <p className="ml-4 mt-4 text-xs font-bold md:text-sm ">All</p>

      {list.map((item, i) => {
        return (
          <div key={i} className="flex hover:bg-filterColor border-2 ">
            <label className="ml-1">
              <input type="checkbox" className="w-[2vh] h-[2vh]" />
            </label>
            <label onClick={()=> props.viewItemDetails(item.Item_Group_Id)} className="ml-2 ">
              <span className="scroll_text  ml-3 ">
               {item.Item_Group_Name}
              </span>
            </label>
          </div>
        );
      })}
    </section>
    </>
  )
}

export default ItemDetailsGroupScroll