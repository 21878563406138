import { Table } from "react-bootstrap";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CurrencyFormat from "react-currency-format";
import { Grid } from "@mui/material";

function BillOverviewTable(props) {
  const { billDetails } = props;

  const calculateSubTotal = (list) => {
    let result = 0;

    list?.forEach((element) => {
      result += element.Item_Quantity * element.Item_Rate;
    });
    return result?.toFixed(2);
  };

  const calculateTotal = (list) => {
    let result = 0;
    list?.forEach((element) => {
      result += element.Item_Quantity * element.Item_Rate;
    });
    let discountValue = billDetails?.Discount_Value;
    let discountType = billDetails?.Discount_Type_Id;
    if (discountValue && (discountType === 1 || discountType === 0)) {
      result = result - discountValue;
    }
    if (discountValue && discountType === 2) {
      let discount = (discountValue / 100) * result;
      result = Number(result) - Number(discount);
    }
    return result?.toFixed(2);
  };

  return (
    <>
      <div className="">
        <TableContainer className="">
          <Table sx={{ minWidth: 70, width: 1000 }} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#F1F1F1" }}>
              <TableRow>
                <TableCell>Item & Description</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {props?.tableData?.map((item, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{item.Item_Name} </TableCell>
                    <TableCell align="left">
                      {item.Item_Quantity?.toFixed(2)}{" "}
                    </TableCell>
                    <TableCell align="left">
                      <CurrencyFormat
                        value={parseFloat(
                          item.Item_Quantity * item?.Item_Rate
                        )?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <CurrencyFormat
                        value={parseFloat(
                          item.Item_Quantity * item?.Item_Rate
                        )?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="my-2 border-[1px] border-gray-200 rounded-xl  px-2 py-4 ">
          <div className="flex  w-full justify-end ">
            <div className="w-[270px] flex-shrink-0">
              <div className="flex justify-between text-sm w-full max-w-[270px]">
                <div className="text-sm text-gray-700 font-semibold">
                  Discount {props?.tableData?.Discount_Type_Name}
                </div>
                <div className="text-sm text-gray-700 font-semibold">
                  {billDetails?.Discount_Type_Id === 1 && (
                    <CurrencyFormat
                      value={billDetails?.Discount_Value?.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  )}
                  {billDetails?.Discount_Type_Id === 2 && (
                    <CurrencyFormat
                      value={billDetails?.Discount_Value?.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"%"}
                    />
                  )}
                </div>
              </div>

              <div className="flex justify-between text-sm w-full max-w-[270px]">
                <div className="text-sm text-gray-700 font-semibold">
                  Balance Due
                </div>{" "}
                <div className="text-sm text-gray-700 font-semibold">
                  <CurrencyFormat
                    value={billDetails?.Balance_Due_Amount?.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </div>
              </div>

              <div className="text-sm font-semibold text-gray-400 italic">
                Value Added Tax (7.5%)
              </div>

              <div className="flex justify-between w-full max-w-[270px]">
                <div className="text-gray-600 text-base font-bold">
                  Sub Total:
                </div>
                <div className="text-gray-600 font-bold">
                  <span className="text-gray-600">
                    <CurrencyFormat
                      value={calculateSubTotal(props?.tableData)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </span>
                </div>
              </div>
              <div className="mt-2 flex justify-between w-full max-w-[270px]">
                <div className="text-xl text-gray-700 font-bold">Total</div>
                <div className="text-xl text-gray-700 font-bold">
                  <CurrencyFormat
                    value={calculateTotal(props?.tableData)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BillOverviewTable;
