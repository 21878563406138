import { DataGrid ,  gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport ,
    useGridApiContext,
    useGridSelector} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";



  const TableHead = [
    { field: 'Gateway_Ref_No', headerName:'Ref No.' , width: 195 , headerClassName: "tableHeader "},
    { field: 'Transaction_Date', headerName:'Date' , width: 195 , headerClassName: "tableHeader "},
     { field: 'Payment_Gateway_Name', headerName:'Payment Gateway' , width: 195 , headerClassName: "tableHeader "},
     { field: 'Processor_Response', headerName:'Payment Ref No.' , width: 195 , headerClassName: "tableHeader "},
     { field: 'Client_Full_Name', headerName:'Customer Name' , width: 195 , headerClassName: "tableHeader "},
     { field: 'Amount', headerName:'(₦) Amount' , width: 195 , headerClassName: "tableHeader "},
     { field: 'Status', headerName:'Status' , width: 195 , headerClassName: "tableHeader "},
     { field: 'Customer_Email_Address', headerName:'Email' , width: 195 , headerClassName: "tableHeader "},
     { field: 'Narration', headerName:'Narration' , width: 195 , headerClassName: "tableHeader "},
  
    ]


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }



function PaymentGateWayTable(props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();  
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];

  const currUserMenu = url[3];



  function OnItemCellClick(event){
    console.log(event.row);
    const id = event.row['Ref_No'];
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`)
}


    return ( 
        <>
<div className="table" style={{ height: 500, width: '100%' }}>
      <DataGrid  rows={props?.list?.map((item, idx)=>{ return {...item,id: idx, Transaction_Date: moment(item?.Transaction_Date).format("DD/MM/YYYY")} } )}
        columns={TableHead}
        pageSize={6}
        rowsPerPageOptions={[6]}
        checkboxSelection={true}
        components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
          }}
          onRowClick={(event)=> {
            OnItemCellClick(event);
          }}
          
       
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
              cursor: 'pointer',
            },
          }}
      />
  
    </div>
        
        </>
     );
}

export default PaymentGateWayTable;