import React, { useRef } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { salesService } from "../../services/sales.service";

import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import {
  SetEditSalesOrder,
  SetSalesOrderList,
  GetSalesOrderList,
} from "../../store/salesOrderAtom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useReactToPrint } from "react-to-print";
import { Dropdown } from "react-bootstrap";

import LogicContext from "../../Context/LogicContext";
import { useContext } from "react";
import SalesScroll from "./SalesScroll";
import Package from "../Package";
import { SalesCommentWrapper } from "./SalesOrderOverviewWrapper";
import SalesOrderHistory from "./SalesOrderHistory";
import SalesOrderInvoice from "./SalesOrderInvoice";
import { ImAttachment } from "react-icons/im";
import { FaFilePdf } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";

const SalesOrderDetailsPage = () => {
  const [tabValue, setTabValue] = React.useState("1");
  const [selectedList, setSelectedList] = useState([]);
  const myPromiseModal = createModal(GeneralModal);
  const setEditSalesOrder = SetEditSalesOrder();
  const setSalesOrderListData = SetSalesOrderList();
  const salesOrderListData = GetSalesOrderList();

  const logicContext = useContext(LogicContext);

  const { sales_id } = useParams();
  const navigate = useNavigate();
  const [salesData, setSalesData] = useState({});
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const mountRef = useRef(false);
  const componentRef = useRef();

  useEffect(() => {
    document.title = " Sales Order Details";
    mountRef.current = true;
    salesService.salesOrderDetails(sales_id).then((response) => {
      console.log("Sales Rec ", response.data);
      if (mountRef.current && response.data !== undefined) {
        if (response?.data) {
          setSalesData(response.data);
        } else {
          setSalesData({});
        }
      }
    });

    return () => {
      mountRef.current = false;
    };
  }, [sales_id]);

  const editSalesOrder = async () => {
    const response = await myPromiseModal({
      title: "Modify Sales Order",
      body: " Do you want to modify this record?",
      showInput: false,
      placeHolder: "",
    });

    if (response.decision === true) {
      setEditSalesOrder(salesData);
      navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
    }
  };

  function reloadSalesOrder() {
    salesService.salesOrderDetails(sales_id).then((response) => {
      if (mountRef.current && response.data !== undefined) {
        if (response?.data) {
          setSalesData(response.data);
          setSalesOrderListData((prev) => {
            let newList = prev["Sales_Order_Info"].map((salesOrder, index) => {
              if (
                salesOrder?.Sales_Order_Ref_No ===
                response.data.Sales_Order_Ref_No
              ) {
                return response.data;
              } else {
                return salesOrder;
              }
            });
            return { ...prev, Sales_Order_Info: newList };
          });
        } else {
          setSalesData({});
        }
      }
    });
  }

  const viewDetails = (id) => {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
  };

  function onSelection(list) {
    let tempList = list?.filter((item) => item.checked === true);
    setSelectedList(tempList);
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function getSalesOrderListApi() {
    salesService.getSalesOrderInvoiceList().then((response) => {
      if (response?.data !== undefined) {
        setSalesOrderListData(response.data);
      }
    });
  }

  const deleteSalesOrder = async () => {
    customActionsSingle(4);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const customTabChange = (id) => {
    setTabValue(id);
  };

  function viewSalesDetails(salesOrderId) {
    navigate(
      `/${currModuleId}/${currModuleName}/${"sales"}/details/${salesOrderId}`
    );
  }

  function createSubscription() {
    if (
      salesData?.Subscription_Ref_No === 0 ||
      salesData?.Subscription_Ref_No === null
    ) {
      navigate(
        `/${2}/${"Subscription"}/${"subscriptions"}/add-new?Customer_Id=${
          salesData?.Customer_Id
        }&Customer_Full_Name=${
          salesData?.Customer_Full_Name
        }&Sales_Order_Ref_No=${salesData?.Sales_Order_Ref_No}`
      );
      setEditSalesOrder(salesData);
    } else {
      return logicContext.showToast(
        "sales order already have a subscription!",
        "info"
      );
    }
  }

  async function bulkActions(action) {
    console.log(action);
    await myPromiseModal({
      title: "confirm your action!",
      body: `Do you want to ${action.Action_Name} ?`,
      showInput: false,
      placeHolder: "",
    }).then((result) => {
      if (result.decision === true) {
        let temp = "";
        const option = action.Procedure_Option;
        selectedList.map((item) => {
          temp += `<a> <refno> ${item.Sales_Order_Ref_No} </refno> </a>`;
        });

        let xml_string_refno = `<root> ${temp} </root>`;
        salesService
          .setSalesOrderBulkAction({ xml_string_refno, option })
          .then((res) => {
            console.log("Bulk Success ", res.data);
            if (res?.data !== undefined) {
              const { Message_Text } = res.data[0];
              logicContext.showToast(Message_Text, "success");
              return reloadSalesOrder();
            }
          })
          .catch((err) => {
            logicContext.showToast("Error", "error");
          });
      }
    });
  }

  async function singleActionPerform(action) {
    console.log("SEEe ", action);
    await myPromiseModal({
      title: "confirm your action!",
      body: `Do you want to ${action.Action_Name?.replace("Bulk", "")} ?`,
      showInput: false,
      placeHolder: "",
    }).then((result) => {
      if (result.decision === true) {
        const option = action.Procedure_Option;
        let xml_string_refno = `<root> <a> <refno> ${sales_id} </refno> </a> </root>`;
        salesService
          .setSalesOrderBulkAction({ xml_string_refno, option })
          .then((res) => {
            if (res?.data !== undefined) {
              const { Message_Text } = res.data[0];
              logicContext.showToast(Message_Text, "success");
              return reloadSalesOrder();
            }
          })
          .catch((err) => {
            console.log("ERRRRRR ", err);
            logicContext.showToast("Error", "error");
          });
      }
    });
  }

  const magicButtons = (statusString, statusCode) => {
    switch (statusString) {
      case "Draft":
        return (
          <button
            onClick={() => customActionsSingle(statusCode)}
            style={{ borderRadius: "5px" }}
            className=" px-3 app_blue_color text-white h-[6vh] text-uppercase  subheading mr-3"
          >
            submit for approval
          </button>
        );

      case "Pending for Approval":
        return (
          <>
            <button
              onClick={() => customActionsSingle(2)}
              style={{ borderRadius: "5px", backgroundColor: "#476EF8" }}
              className=" px-3 app_blue_color text-white h-[6vh] text-uppercase  subheading mr-3"
            >
              APPROVE
            </button>
            <button
              onClick={() => customActionsSingle(9)}
              style={{ borderRadius: "5px" }}
              className=" px-3 bg-danger text-white h-[6vh] text-uppercase  subheading mr-3"
            >
              REJECT
            </button>
          </>
        );

      case "Approved":
        return (
          <>
            <button
              onClick={() => customActionsSingle(8)}
              style={{ borderRadius: "5px" }}
              className=" px-3  h-[6vh] text-uppercase bg-info text-white  subheading  mr-3"
            >
              Confirm
            </button>
            <button
              onClick={() => customActionsSingle(10)}
              style={{ borderRadius: "5px" }}
              className=" px-3  h-[6vh] text-uppercase app_blue_color text-white  subheading  mr-3"
            >
              Convert to Invoice
            </button>
          </>
        );

      case "Received & Not Billed":
        return (
          <button
            onClick={() => customActionsSingle(3)}
            style={{ borderRadius: "5px" }}
            className=" px-3  app_blue_color text-white h-[6vh] text-uppercase  subheading  mr-3"
          >
            Convert to Bill
          </button>
        );

      case "Confirmed":
        return (
          <button
            onClick={() => createSubscription()}
            style={{ borderRadius: "5px" }}
            className=" px-3  bg-secondary text-white h-[6vh] text-uppercase  subheading  mr-3"
          >
            Create Subscription
          </button>
        );

      default:
        return null;
    }
  };

  async function customActionsSingle(actionNumber) {
    var action = {};
    if (actionNumber === 1) {
      action.Action_Name = "Submit for Approval";
      action.Action_Id = 1;
      action.Procedure_Option = 5;
    }
    if (actionNumber === 2) {
      action.Action_Name = "Approve";
      action.Action_Id = 2;
      action.Procedure_Option = 9;
    }
    if (actionNumber === 10) {
      action.Action_Name = "Convert to Invoice";
      action.Action_Id = 10;
      action.Procedure_Option = 12;
    }
    if (actionNumber === 8) {
      action.Action_Name = "Confirm";
      action.Action_Id = 8;
      action.Procedure_Option = 8;
    }

    if (actionNumber === 9) {
      action.Action_Name = "REJECT " + salesData?.Sales_Order_No;
      action.Action_Id = 9;
      action.Procedure_Option = 13;
    }

    if (actionNumber === 4) {
      action.Action_Name = "Delete " + salesData?.Sales_Order_No;
      action.Action_Id = 4;
      action.Procedure_Option = 4;
    }

    let placeHolder = "";
    let showInput = false;
    //  show text nput for rejection
    if (actionNumber === 9) {
      placeHolder = "reason(s) for rejection";
      showInput = true;
    }

    await myPromiseModal({
      title: "confirm your action!",
      body: `Do you want to ${action.Action_Name} ?`,
      showInput,
      placeHolder,
    }).then((result) => {
      console.log("Result ", result);

      if (result.decision === true) {
        const option = action.Procedure_Option;
        let xml_string_refno = `<root> <a> <refno> ${sales_id} </refno> </a> </root>`;
        salesService
          .setSalesOrderBulkAction({
            xml_string_refno,
            option,
            comments: result.message,
          })
          .then((res) => {
            if (res?.data !== undefined) {
              const { Message_Text } = res.data[0];
              logicContext.showToast(Message_Text, "success");
              if (option === 4) {
                navigate(-1);
                return getSalesOrderListApi();
              } else {
                return reloadSalesOrder();
              }
            }
          })
          .catch((err) => {
            logicContext.showToast("Error", "error");
          });
      }
    });
  }

  return (
    <>
      <section className="mt-2">
        <div className="pb-3">
          {selectedList.length > 0 && (
            <div className="">
              <Dropdown>
                <Dropdown.Toggle
                  className="bg-[#E5E5E5] hover:bg-gray-200 hover:text-gray-600 text-gray-600 text-sm"
                  id="dropdown-basic"
                >
                  Bulk Actions
                </Dropdown.Toggle>

                <Dropdown.Menu className="mt-1 rounded-lg bg-white border-[1px] border-gray-200 text-sm shadow-xl">
                  {salesOrderListData["Bulk_Action"]?.map((action, i) => {
                    return (
                      <Dropdown.Item
                        key={i}
                        onClick={() => bulkActions(action)}
                      >
                        {action.Action_Name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>

        <section className="w-full">
          <div className="flex flex-row items-center justify-end">
            {magicButtons(
              salesData?.Sales_Order_Status_Name,
              salesData?.Sales_Order_Status_Id
            )}
            <Dropdown className=" mr-3">
              <Dropdown.Toggle
                className="text-sm text-white bg-[#476EF8]"
                id="dropdown-basic"
              >
                ACTION
              </Dropdown.Toggle>
              <Dropdown.Menu className="mt-1 shadow-xl border-[1px] border-gray-200 rounded-md bg-white">
                {salesOrderListData["Bulk_Action"]?.map((option, i) => {
                  return (
                    <Dropdown.Item
                      className="border-1 active:bg-gray-200"
                      key={i}
                      onClick={() => singleActionPerform(option)}
                    >
                      <div className=" text-sm text-gray-600 py-[2px] hover:bg-100">
                        {option.Action_Name.replace("Bulk", "")}
                      </div>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <div className="flex fle-row items-center space-x-1 ">
              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <ImAttachment className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div
                onClick={() => handlePrint()}
                className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer"
              >
                <FaFilePdf className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div
                onClick={() => handlePrint()}
                className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer"
              >
                <IoPrintSharp className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              <div
                onClick={editSalesOrder}
                className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer"
              >
                <MdEdit className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              <div
                onClick={deleteSalesOrder}
                className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer"
              >
                <MdDelete className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
            </div>
          </div>

          <section className="mt-4 lg:flex space-x-1">
            <div className="lg:w-[210px]">
              <SalesScroll
                viewDetails={viewDetails}
                onSelection={onSelection}
              />
            </div>

            {/* start tab */}
            <div className="w-full overflow-x-hidden mt-4 lg:mt-0 lg:pl-4">
              <TabContext value={tabValue} className="w-full">
                <Box className="mt-2">
                  <TabList
                    onChange={handleTabChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      label="Overview"
                      value="1"
                      className="outline-non focus:outline-none"
                    />
                    <Tab
                      label="Comment & History"
                      value="2"
                      className="outline-non focus:outline-none"
                    />

                    <Tab
                      label="Packages"
                      value="3"
                      className="outline-non focus:outline-none"
                    />

                    <Tab
                      label="Invoices"
                      value="4"
                      className="outline-non focus:outline-none"
                    />
                  </TabList>
                </Box>

                <TabPanel value="1" className="w-full m-0 p-0">
                  <SalesCommentWrapper
                    ref={componentRef}
                    salesData={salesData}
                    viewSalesDetails={viewSalesDetails}
                    customTabChange={customTabChange}
                  />
                </TabPanel>

                <TabPanel value="2">
                  <SalesOrderHistory tableData={salesData?.History} />
                </TabPanel>

                <TabPanel value="3">
                  <Package></Package>
                </TabPanel>
                <TabPanel value="4">
                  <SalesOrderInvoice
                    tableData={salesData?.Invoice_details}
                  ></SalesOrderInvoice>
                </TabPanel>
              </TabContext>
            </div>

            {/* end tab */}
          </section>
        </section>
      </section>
    </>
  );
};

export default SalesOrderDetailsPage;
