import React, { useEffect, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {  message, Upload } from 'antd';
import { itemsService } from '../../../services/items.service';
import { GetItemToEdit } from '../../../store/itemAtom';


const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };



function PrimaryImageUpload(props) {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
  const itemToEditData = GetItemToEdit();

  useEffect(()=> {
    console.log('fire_get_image');
      itemsService.getItemImages(itemToEditData.Item_Id).then((res)=> {
        console.log('Imgaes ', res.data);
      })
  },[itemToEditData.Item_Id]);
  
    const handleFileChange = (info) => {
      console.log('info ', info);
      const { file } = info;
  
      const formData = new FormData();
      const myFile = file.originFileObj;
      formData.append('file', myFile);
          const formObject = {item_id: itemToEditData?.Item_Id, option:1}
      formData.append('formObject', JSON.stringify(formObject));
      setLoading(true);
      itemsService.submitItemImage(formData).then(res => {
        setLoading(false);
        console.log('upload_success ', res.data);
        setImageUrl(res.data?.file_link);
      }).catch((err)=> {
        setLoading(false);
      })
  
    };
  

  const uploadButton = (
    <button  style={{  border: 0,  background: 'none'  }}type="button" >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{  marginTop: 8,  }}  >
        Upload
      </div>
    </button>
  );

    return ( 
        <>
           <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleFileChange}
        multiple={false}
      >
        {imageUrl ? (
          <img   src={imageUrl}  alt="avatar"   style={{   width: '100%',  }}  />
        ) : (    uploadButton  )}
      </Upload>
        </>
     );
}

export default PrimaryImageUpload;