import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import "../components/Notificatiom.css";
import Button from "@mui/material/Button";
import { purchaseService } from "../../services/purchase.service";



function VendorNotification() {

  const { vendor_id } = useParams();
  const [notificationList, setNotificationList] = useState([]);
  const [notificationListTemp, setNotificationListTemp] = useState([]);


  function handleCategoryChange (event){
        const selectedItem = event.target.value;
        if (selectedItem === 'All') return setNotificationListTemp(notificationList)

        var filteredItems = notificationList.filter((item)=> item.Notification_Type_Name === selectedItem)
        setNotificationListTemp(filteredItems);

  }



  useEffect(() => {
    purchaseService.getVendorNotification(vendor_id).then((res) => {
      console.log('notif ', res.data)
      setNotificationList(res.data);
      setNotificationListTemp(res.data);
    });
  }, [vendor_id]);



  

  return (
    <section className="   ">
          <section  style={{ backgroundColor: "#F9F9F9" }} className="w-100">
            <div className="w-full pl-[30vw] mt-3">
              <div className="flex  relative ">
                <span
                  style={{ backgroundColor: "#476EF8" }}
                  className="rounded-full absolute w-60 h-[5vh] opacity-30 px-3 py-1  mt-4"
                ></span>
                <span
                  style={{ color: "#476EF8" }}
                  className="font-bold absolute text-sm top-[4.5vh] left-[3vw] opacity-70"
                >
              Invoice notification
                </span>

                <span className="top-[24vh] pl-10 absolute ">
                  <span
                    style={{ color: "#70CC40" }}
                    className="font-bold text-sm"
                  >
                    Status:
                  </span>{" "}
                  <span
                    className="font-bold text-sm"
                    style={{ color: "#70CC40" }}
                  >
                    Success
                  </span>
                </span>
              </div>
            </div>

            <span className="pt-6 pl-3 absolute ">
          
              
            </span>

            <div className="ml-16 pt-[15vh] pb-5">
              <div>
                <div>
                  <span className="text-md">
                  abhitest@gmail.com - 13 Feb 2021
                  </span>
                </div>
                <div>
                  <span className="text-sm  font-bold">

                  Invoice from Grocedy (Invoice#: INV-002338) (This is a sample email generated for testing purposes only)
                  </span>
                </div>
                <div>
                  <span className="text-md ">
                    {" "}
               
                    <i>by lanre.smith@worldbaytech.com</i>
                  </span>
                </div>
              </div>
            </div>
          </section>
       
    </section>
  );
}

export default VendorNotification;
