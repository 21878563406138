import { ErrorBoundary } from 'react-error-boundary'

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    // <div role="alert">
    //   <p c >Something went wrong:</p>
    //   <pre>{error.message}</pre>
    //   <button onClick={resetErrorBoundary}>Try again</button>
    // </div>
    <>
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"> <br/>
                <span className="text-danger">Opps!</span> Error accessing page.</p>
                <p className="lead">
                   <span> Please contact Admin.</span>
                   <br/>
                    <a href={error.message} target='blank' className='text-danger m-5 p-5' >{error.message}</a>
                  </p>
                <a href="/" className="btn btn-primary">Go Home</a>
            </div>
        </div>
    
    </>
  )
}

export default ErrorFallback;