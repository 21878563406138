import React, { useEffect, useState } from 'react'
import { GetItemHistory, SetItemHistory } from "../store/itemAtom";
import{itemsService} from '../services/items.service';
import { DataGrid ,  gridPageCountSelector,
  gridPageSelector, GridToolbarContainer, GridToolbarExport ,
  useGridApiContext,
  useGridSelector} from "@mui/x-data-grid";
  import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}


const tableHeader = [
  { field: 'History_Date', headerName:'Date' , width: 250 , headerClassName: "tableHeader "},
   { field: 'History_Time', headerName:'History Time' , width: 250 , headerClassName: "tableHeader "},
   { field: 'Changed_By_Name', headerName:'Change by Name' , width: 250 , headerClassName: "tableHeader "},
   { field: 'Action', headerName:'Action' , width: 250 , headerClassName: "tableHeader "},

  ]

function History(props) {
const [historyList, setHistory]= useState([]);
  // const setHistory = SetItemHistory();
  // const historyList = GetItemHistory();


  useEffect(()=> {
   if(historyList.length < 1){
    itemsService.getItemHistory(props.item_id).then(res => {
      const tempData  =  res.data?.map((item, i)=> {
        return ({...item, id:i})
       });
       setHistory(tempData);
    }).catch(err => { console.log(err)})
   }
  },[historyList.length,props.item_id])



  return (
    <>    
     <section className="mt-10">
    <span className="font-bold text-sm">Sales Rate History</span>
  
    <div className="mt-7" style={{ height: 280, width: '100%' }}>
      <DataGrid
        columns={tableHeader}
        rows={historyList}
        components={{
          Pagination: CustomPagination,
        }}
      />
    </div>
  </section></>
  )
}

export default History