import { Route, Routes } from "react-router-dom";
import Userlayout from "../layout/Userlayout";
import NotFoundPage from "../pages/NotFoundPage";
import ModuleListPage from "../pages/ModuleListPage";
import { PrivateRoute } from "./PrivateRoutes";

function UsersRoutes() {
    return ( 
        <>
    <Userlayout>
        <Routes>
       <Route  path='/module-list' element={
                <PrivateRoute>
                <ModuleListPage />
                </PrivateRoute>}>
          </Route>
         
        <Route path="/*" element={<NotFoundPage />}></Route>
        </Routes>
        </Userlayout>

        </>
     );
}

export default UsersRoutes;