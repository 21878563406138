import { useEffect, useState } from "react";


function AddItemTrackingTypeCheckRadioButton(props) {
    const [noneChecked, setNoneChecked] = useState(false); 
    const [serialChecked, setSerialChecked] = useState(false); 
    const [batchesChecked, setBatchesChecked] = useState(false); 


    useEffect(()=> {
        switch (props.val) {
            case 1:
                setNoneChecked(true);
                setSerialChecked(false);
                setBatchesChecked(false)
                break;
            case 2:
                setNoneChecked(false);
                setSerialChecked(true);
                setBatchesChecked(false)
                break;
            case 3:
                setNoneChecked(false);
                setSerialChecked(false);
                setBatchesChecked(true)
                break;
        
            default:
                setNoneChecked(false);
                setSerialChecked(false);
                setBatchesChecked(false)
                break;
        }


      },[props.val])
    
    
        const noneSelectChange = () => {
            setNoneChecked(true);
                setSerialChecked(false);
                setBatchesChecked(false);
                props.addItemTrackingType(1)
           
          };
    
    
        const serialSelectChange = () => {
            setNoneChecked(false);
                setSerialChecked(true);
                setBatchesChecked(false);
                props.addItemTrackingType(2)
           
          };
    
    
        const batchSelectChange = () => {
            setNoneChecked(false);
                setSerialChecked(false);
                setBatchesChecked(true);
                props.addItemTrackingType(3)
           
          };
    
    
    

    return ( 
        <>
       <div className="flex text-sm">
        <div className="ml-2 flex">
          <div>
            {" "}
            <input  checked={noneChecked}  onChange={noneSelectChange} value="1" type="radio" className="w-[0.8vw] h-[1.6vh]" />
          </div>
          <div className="mt-[0.3vh]">
            {" "}
            <span className="text-xs ml-2">None</span>
          </div>
        </div>
        <div className="ml-2 flex">
          <div>
            {" "}
            <input checked={serialChecked} onChange={serialSelectChange} value="2" type="radio" className="w-[0.8vw] h-[1.6vh]" />
          </div>
          <div className="mt-[0.3vh]">
            {" "}
            <span className="text-xs ml-2">Track Serial No</span>
          </div>
        </div>
        <div className="ml-2 flex">
          <div>
            {" "}
            <input checked={batchesChecked} onChange={batchSelectChange}    value="3" type="radio" className="w-[0.8vw] h-[1.6vh]" />
          </div>
          <div className="mt-[0.3vh]">
            {" "}
            <span className="text-xs ml-2">Track Batches</span>
          </div>
        </div>
      </div>
        </>
     );
}

export default AddItemTrackingTypeCheckRadioButton;