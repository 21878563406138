import {
  DataGrid, gridPageCountSelector,
  gridPageSelector, GridToolbarContainer, GridToolbarExport,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useEffect } from "react";
import moment from "moment";


const TableHead = [
  { field: 'id', headerName: 'Id', width: 195, headerClassName: "tableHeader " },
  { field: 'Customer_Full_Name', headerName: 'Name', width: 195, headerClassName: "tableHeader " },
  { field: 'Customer_Email_Address', headerName: 'Email Address', width: 195, headerClassName: "tableHeader " },
  { field: 'Customer_Mobile_No', headerName: 'Mobile', width: 195, headerClassName: "tableHeader " },
  { field: 'Subject', headerName: 'Subject', width: 350, headerClassName: "tableHeader " },
  { field: 'Send_DateTime', headerName: 'Date', width: 200, headerClassName: "tableHeader " },

]


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}


function NotificationReasonTable(props) {


  useEffect(() => {

  }, []);


  return (
    <>


      <div className="table" style={{ height: 500, width: '100%' }}>
        <DataGrid rows={props?.dataList?.map((item, i) => {
          return {
            ...item, id: item?.Ref_No,
            Send_DateTime: moment(item?.Send_DateTime,).format(" DD-MM-YYYY:HH:mm:ss")
          }
        })}
          columns={TableHead}
          pageSize={6}
          rowsPerPageOptions={[6]}
          checkboxSelection={true}
          components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
          }}
          onCellClick={(event) => {
            event.defaultMuiPrevented = true;

          }}


          sx={{
            // boxShadow: 2,
            // border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
              cursor: 'pointer',
            },
          }}
        />

      </div>

    </>
  );
}

export default NotificationReasonTable;