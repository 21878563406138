import * as React from 'react';
import Box from '@mui/material/Box';
// import Slider as from '@mui/material/Slider';
import { Slider as MuiSlider, styled } from '@mui/material';

function valuetext(value) {
  return `${value}°C`;
}



export default function RatingRangeSlider(props) {
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    console.log('LOg_slide ', newValue);
    props.handlerRatingChange(newValue);
    setValue(newValue);
  };

  const Slider = styled(MuiSlider)({
    '& .MuiSlider-thumb[data-index="0"]': {
      color: 'green',
    },
    '& .MuiSlider-thumb[data-index="1"]': {
      color: 'red',
    },
  
  });

  return (
    <Box sx={{ width: 300 }}  >
      <Slider className='border-2'
        getAriaLabel={() => 'Temperature range'}
        value={value}
        defaultValue={5}
        step={1}
        valueLabelDisplay="on"
        onChange={handleChange}
        getAriaValueText={valuetext}
        color={'primary'}
        min={1}
        max={5}
        marks={true}
      />
    </Box>
  );
}