import React, { useRef, useContext } from "react";
import moment from "moment/moment";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import LogicContext from "../../Context/LogicContext";
import IVRScroll from "./IVRScroller";
import { notificationService } from "../../services/notification.service";
import Box from '@mui/material/Box';
import { Table, Input } from 'antd';

const { Search } = Input;



const IVRServiceDetailsPage = () => {
  const [details, setDetails] = useState({current_page:0, data:[],
     from:0, last_page:0, per_page:0, to:0,total:0});
  const {  id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);





  useEffect(() => {
  
       document.title = 'IVR history details';
    getHistoryDetails(id, );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  
  const getHistoryDetails = async (id, page= '1') => {
    notificationService.getSurveyItemDetails(id, `page=${page}` ).then((res)=> {
        if(res.data?.data){
          setDetails(res.data.data);
          setFilteredData(res.data.data?.data);
        }else{
          setFilteredData([]);
          
        }
    });

  };


  function viewItemDetails(id) {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/service-details/${id}`
    );
    getHistoryDetails(id);
  }


  const tableStructure = [
    {
      title: ' Id',
      dataIndex: 'id',
      // render: (data) => <span> </span> ,
    },
 
    {
      title: "Call No",
      dataIndex: "msisdn",
      sorter: (a, b) => a.msisdn.localeCompare(b.msisdn)
    },
  
    {
      title: "Status",
      dataIndex: "status",
      // sorter: (a, b) => a.reciepentsno.toString().localeCompare(b.reciepentsno.toString())
    },
  
    {
      title: "Job Status",
      dataIndex: "jobstatus",
      // sorter: (a, b) => a.status.localeCompare(b.status)
    },
  
    {
      title: "Duration(s)",
      dataIndex: "duration_in_seconds",
      // sorter: (a, b) => a.duration_in_seconds.localeCompare(b.duration_in_seconds)
    },
    {
      title: "Cost(₦)",
      dataIndex: "cost",
      // sorter: (a, b) => a.cost.localeCompare(b.cost)
    },
    {
      title: "Retry",
      dataIndex: "retry_count",
      // sorter: (a, b) => a.retry_count.localeCompare(b.retry_count)
    },
    {
      title: "Summary",
      dataIndex: "summary",
    },
 
    {
      title: "Run Time",
      dataIndex: "run_time",
    //   sorter: (a, b) => a.Earn_Title.localeCompare(b.Earn_Title)
    }, 
  ];


  const paginationOptions = {
    pageSize: 15,
    showSizeChanger: true,
    total: details.total,
    onChange: (page, pageSize) => {
      getHistoryDetails(id,page);
    },
    
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = details.data.filter((record) =>
      Object.values(record).some((text) =>
        String(text).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };





  return (
    <>
      <section className="  mt-2 ">
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <section className="mt-4 max-auto max-w-6xl d-flex flex-row-reverse ">
              <div className="flex flex-wrap justify-between md:">
                <div>
             
                  <div className="flex ">
                    {/* <button
                      onClick={deleteCurrentData}
                      style={{ borderRadius: "5px" }}
                      className="  px-3 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 ml-2"
                    >
                      <img src={trash} alt="" />
                    </button> */}
                  </div>
                </div>
              </div>
            </section>
          </Grid>
          

          <Grid item xs={12}>
            <section className="flex relative justify-between ">
              <IVRScroll
                // onSelection={onSelection}
                viewItemDetails={viewItemDetails}
              ></IVRScroll>

              <section className="w-full z-50  ">
              

                 
                <>
                <Box component="section" className="mb-5 mt-5" sx={{ p: 2, border: '1px dashed grey' }}>
                    A Total of <span className="font-bold text-primary"  >{details?.data.length}</span> IVR Calls was triggered with a trigger ID 
                     <span className="font-bold text-primary" >{id}</span>
                  </Box>
                </>
         

                <ul class="list-group">
                  <li class="list-group-item active"> Total Call(s) : <span className="text-white ml-3 font-bold" >{details?.length} </span> </li>
                  <li class="list-group-item"> Trigger ID : <span className="text-slate-600 ml-3 font-bold"  >{id} </span> </li>
                  <li class="list-group-item"> Template Name : <span className="text-slate-600 ml-3 font-bold"  >{details?.data[0]?.name} </span> </li>
                  <li class="list-group-item"> Caller No: <span className="text-slate-600 ml-3 font-bold"  >{details?.data[0]?.schedulecall} </span> </li>
                  {/* <li class="list-group-item">Vestibulum at eros</li> */}
                </ul>

                {/*  Search  */}
                 <Search
                 className="ml-5 p-4"
                  placeholder="Search in Table"
                  onSearch={handleSearch} 
                  onChange={(e) => handleSearch(e.target.value)}
                  value={searchText}
                  style={{ marginBottom: 10, width: 350 }}
                />
                {/*  Search  end */}
                
                            
                   {/* Table */}
                   <Table
                      columns={tableStructure}
                      dataSource={filteredData?.map((item)=> {
                        return {...item, created_at: moment(item.created_at).format(" DD-MM-YYYY:HH:mm:ss"),
                          updated_at: moment(item.updated_at).format(" DD-MM-YYYY:HH:mm:ss")
                        }
                      }) }
       
              pagination={paginationOptions}
              style={{ background: '#f0f2f5', borderRadius: '8px' }}
              rowClassName="show-cursor"
            
          />
        {/* Table end */}
              </section>
            </section>
          </Grid>
        </Grid>
     
      </section>
    </>
  );
};

export default IVRServiceDetailsPage;
