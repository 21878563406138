import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CurrencyFormat from "react-currency-format";
import TextField from '@mui/material/TextField';
import { NumericFormat } from 'react-number-format';
import { GetPriceDataStore } from '../../store/priceListAtom';
import Select from 'react-select';
import PriceUploadModal from './PriceUploadModal';
import * as XLSX from 'xlsx';
import LogicContext from "../../Context/LogicContext";
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import * as _ from 'underscore';
import Tooltip from '@mui/material/Tooltip';
import CopyPriceModal from './CopyPriceModal';
import {Switch} from 'antd';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));





export default function PriceDetailsTable(props) {
  const [tableDataList, setTableDataList] = React.useState([]);
  const [defOption, setDefOption] = React.useState(null);
  const [optionList, setOptionList] = React.useState([]);
  const priceData = GetPriceDataStore();
  const logicContext = React.useContext(LogicContext);



  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      zIndex: 0,
      width: 180,
    }),

    indicatorSeparator: styles => ({ ...styles, display: 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '', height: 20, paddingBottom: "45px", marginLeft: "13px" }),
    option: styles => ({ ...styles, color: "black", backgroundColor: 'white' }),
    menuPortal: styles => ({ ...styles, zIndex: 0 })
  }


  React.useEffect(() => {

    let newList = priceData.item_group.map((item) => {
      return { ...item, label: item?.Product_Name }
    });
    setOptionList(newList);
    if (newList?.length) {
      setDefOption(newList[0]);
      props?.onProductSelect(newList[0]);
    }

  }, [priceData.item_group, props?.cor_id]);



  React.useEffect(() => {
    setTableDataList(props?.data);

    return () => {

    }
  }, [props?.data]);


  const itemSearchChange = (ev) => {
    const search = ev?.target.value;
    if (search < 1) {
      setTableDataList(props?.data);
    } else {
      var tempResult = props?.data?.filter((product) => {
        return product?.Item_Type_Name?.toLowerCase().indexOf(search.toLowerCase()) >= 0
          || product?.Item_SKU?.toLowerCase().indexOf(search.toLowerCase()) >= 0
          || product?.Item_Id?.toLowerCase().indexOf(search.toLowerCase()) >= 0
          || product?.Item_Name?.toLowerCase().indexOf(search.toLowerCase()) >= 0
      });

      setTableDataList(tempResult);
    }
  }

  const onFileUpload = (file) => {
    let uploadedFile = file.target.files[0];
    const fileType = file.target.files[0]?.name.split('.')[1];
    let tempUpload = [];
    if (fileType === 'csv') {

      var reader = new FileReader();
      reader.onload = function (e) {
        const csvFile = e.target.result;
        let lines = csvFile.split("\n");

        let newList = [...tableDataList];

        let tempObj = { Item_Name: '', Item_Rate_Market: 0, Item_Rate_Zoho: 0, status: '' };
        let counter = 0;
        for (let i = 0; i < lines.length; i++) {
          const element = lines[i];
          if (i !== 0 && counter < newList.length) {
            console.log('element ', element);
            tempUpload.push(counter);
            if (element) {
              tempObj.Item_Name = element.split(',')[0] ? element.split(',')[0] : 0;
              tempObj.Item_Rate_Market = element.split(',')[1] ? Number(element.split(',')[1]) : 0;
              tempObj.Item_Rate_Zoho = element.split(',')[2] ? Number(element.split(',')[2]) : 0;
              tempObj.status = '';
            }


            if (tempObj.Item_Name?.length < 2) {
              tempObj.status = 'Not valid';
            }
            let storedData = newList[counter];
            newList[counter] = { ...storedData, ...tempObj };
            counter += 1
          }
        }
        // setRowSelectionModel(tempUpload);
        let temp = [];
        for (let i = 1; i < tempUpload.length; i++) {
          let element = tableDataList[i];
          temp.push(element?.Ref_No);
        }

        setTableDataList(newList);
      };
      reader.readAsText(uploadedFile);
      // end csv

    } else if (fileType === 'xlsx') { // For Excel file
      var reader = new FileReader();
      reader.readAsArrayBuffer(file.target.files[0]);
      reader.onload = function (e) {
        var data = new Uint8Array(reader.result);
        var workbook = XLSX.read(data, { type: 'array' });
        var sheet = workbook.Sheets[workbook.SheetNames[0]];
        let jsonList = XLSX.utils.sheet_to_json(sheet);

        let newList = [...tableDataList];

        let tempObj = { Item_Name: '', Item_Rate_Market: 0, Item_Rate_Zoho: 0, status: '' };

        let counter = 0;
        for (let i = 0; i < jsonList.length; i++) {
          const element = jsonList[i];

          if (element?.Item_Name) {
            tempUpload.push(counter);
            tempObj.status = '';
            tempObj.Item_Name = element?.Item_Name ? element?.Item_Name : '';
            tempObj.Item_Rate_Market = element?.Item_Rate_Market ? Number(element?.Item_Rate_Market) : 0;
            tempObj.Item_Rate_Zoho = element?.Item_Rate_Zoho ? Number(element?.Item_Rate_Zoho) : '';

            let storedData = newList[counter];
            newList[counter] = { ...storedData, ...tempObj };
            counter += 1;
          }
        }

        let temp = [];
        for (let i = 0; i < tempUpload.length; i++) {
          let element = tableDataList[i];
          temp.push(element?.Ref_No);
        }

        setTableDataList(newList);
      }
    }
  }



  const onMarketValueChange = (value, product, i) => {
    if (Number(value) < 0) {
      return logicContext.showToast('Negative value is accepted.', 'error');
    }
    if (tableDataList.length < props.data.length) {
      let productData = { ...product, Item_Rate_Market: Number(value ? value : 0) };
      setTableDataList(prevList => {
        let newList = prevList?.map((tempProd) => {
          if (tempProd.Item_Id === productData.Item_Id) {
            return productData;
          } else {
            return tempProd;
          }
        });
        setTableDataList(newList);
      });

    } else {
      props?.onMarketValueChange(value, product, i);

    }
  }



  const onGrocedyValueChange = (value, product, i) => {
    if (Number(value) < 0) {
      return logicContext.showToast('Negative value is accepted.', 'error');
    }
    if (tableDataList.length < props.data.length) {
      let productData = { ...product, Item_Rate_Zoho: Number(value ? value : 0) };
      setTableDataList(prevList => {
        let newList = prevList?.map((tempProd) => {
          if (tempProd.Item_Id === productData.Item_Id) {
            return productData;
          } else {
            return tempProd;
          }
        });
        setTableDataList(newList);
      });

    } else {
      props?.onGrocedyValueChange(value, product, i);


    }
  }

  const pricePercentChange =  _.debounce((value) => {
    console.log('debouch', value)
    props.onGrocedyPercentChange(value);
}, 1600);



const handleToggleChange = (checked, product, i) => {
    console.log(checked, product, i);
    let newTempList =  [...tableDataList];
    let item =  newTempList[i];
    let newStatus  =  (checked === true) ? 'active':'inactive' ;
    item.status = newStatus;
    newTempList[i] =  item;
    setTableDataList(newTempList);

    let newData = {sku: item?.Item_SKU, status: newStatus };
    props.updateStatus(newData)
}



  return (


    <>
      <TableContainer component={Paper}>


        <Grid container className='p-2' spacing={2}>
          <Grid item xs={2}  className='mr-3' >
            <Box
              sx={{ '& > :not(style)': { height: 3, width: '20ch' } }}
              noValidate
              autoComplete="off"
            >
              <Select key={defOption} defaultValue={defOption} 
                onChange={(e) => props?.onProductSelect(e)} options={optionList} styles={customStyles} />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{ '& > :not(style)': { height: 1, width: '18ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField
              className='ml-3'
                onChange={(ev) => itemSearchChange(ev)}
                id="outlined-basic" label="Search... " variant="outlined"
                inputProps={{
                  style: {
                    height: "10px",
                  },
                }}
              />
            </Box>
          </Grid >
          {/* % */}
          <Grid item xs={2}>
            <Tooltip title="Set discount for Grocedy Price." className='show-cursor' >
            <Box
              sx={{ '& > :not(style)': { height: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >
              <NumericFormat
                value={0}
                className='mt-1'
                style={{ width: "80px" }}
                de
                onValueChange={(values, sourceInfo) => {
                  console.log('va ', values.value);
                  pricePercentChange(values.value);
                }}
              />
              <small className='text-primary' > % </small>
            </Box>
              </Tooltip>
          </Grid >
          {/* % end */}
          <Grid item xs={2}> 
            <PriceUploadModal onFileUpload={onFileUpload} tableDataList={tableDataList} />
          </Grid>
          <Grid item xs={2}>
        
          <CopyPriceModal  productCatId={props.productCatId} tableDataList={tableDataList} />

            

          </Grid>
        </Grid>



        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell> Name</StyledTableCell>
              <StyledTableCell align="right">SKU</StyledTableCell>
              <StyledTableCell align="right">Market Price(₦)</StyledTableCell>
              <StyledTableCell align="right">Grocedy price(₦)</StyledTableCell>
              <StyledTableCell align="right"> Status </StyledTableCell>
              <StyledTableCell align="right"> Action </StyledTableCell>
              <StyledTableCell align="right"> History</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableDataList?.length && tableDataList.map((product, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  {product.Item_Name}
                </StyledTableCell>
                <StyledTableCell align="right">{product?.Item_SKU}</StyledTableCell>
                <StyledTableCell align="right">

                  <NumericFormat
                    value={(product?.Item_Rate_Market)}
                    // value={(parseFloat(product?.Item_Rate_Market)).toFixed(2)}
                    style={{ width: "120px" }}
                    thousandSeparator=","
                    decimalSeparator="."
                    onValueChange={(values, sourceInfo) => {
                      onMarketValueChange(values.value, product, i)
                    }}
                  />

                </StyledTableCell>

                <StyledTableCell align="right">
                  <NumericFormat
                    value={(product?.Item_Rate_Zoho)}
                    style={{ width: "120px" }}

                    thousandSeparator=","
                    decimalSeparator="."
                    onValueChange={(values, sourceInfo) => {
                      onGrocedyValueChange(values.value, product, i)
                    }}
                  />

                </StyledTableCell>
                <StyledTableCell align="right">
               <Switch
            checked={product.status === 'active'}
            onChange={(checked) => handleToggleChange(checked, product, i)}
          />
                </StyledTableCell>

                <StyledTableCell onClick={() => props.sendSingleItem(i, product)} align="right"><button className='btn btn-sm btn-primary' >SEND</button></StyledTableCell>
                <StyledTableCell align="right"><button className='btn btn-sm btn-light' >HISTORY</button>
                </StyledTableCell>

          
              </StyledTableRow>
            ))}
          </TableBody>

        </Table>
        <div class="d-flex align-items-end flex-column mt-3 mb-3 " >
          <button onClick={() => props.sendAllProductItems(tableDataList)} className='btn btn-primary btn-sm mr-4' >SAVE ALL</button>
        </div>
      </TableContainer>


      <br />
      <br />


    </>


  );
}