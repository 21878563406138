
function SubscriptionDashboard() {




    return (  
        <>

        <div className="card p-5">
                    Subscription Dashboard
        </div>
        
        </>



    );
}

export default SubscriptionDashboard;