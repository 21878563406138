import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Tooltip from '@mui/material/Tooltip';



const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});





export default function PriceUploadModal(props) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };



    const onFileUpload = (file) => {
        props.onFileUpload(file);
        setOpen(false);
    }





    return (
        <React.Fragment>
             <Tooltip title="Upload price list (xlsx/csv)" className='show-cursor' >
            <Button onClick={handleClickOpen} style={{
                width: '12em',
                height: '3em' // call it humonguous
            }} component="" variant="contained"
                startIcon={<CloudUploadIcon className="text-white"  style={{fontSize: 30}} />}>
                Upload
            </Button>
            </Tooltip>
            <Dialog
                className=''
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title">

                <div className='jumbotron border-2 p-4' >

                    <div className="list-group">
                        <a href="#" className="list-group-item list-group-item-action active">
                            INSTRUCTIONS
                        </a>
                        <a href="#" className="list-group-item list-group-item-action">
                            <span className='text-primary font-weight-bold'  >Upload file .csv or .xlsx :</span>
                            You have to upload csv or xlsx in this format (Item_Name,Item_Rate_Market,Item_Rate_Zoho) with a total of
                            <span className='font-bold' > {props?.tableDataList?.length}</span> row(s).
                        </a>

                    </div>
                    <span className='p-3 text-center' >
                    <a className='text-primary' href="https://s3.eu-west-2.amazonaws.com/delgro-bucket.com/Voucher_Upload_Format.xlsx" target="_blank" rel="noopener noreferrer">Download sample format</a>
                    </span> 


                </div>
                <div className='clearfix' >
                    <div className='btn ml-3  m-4' >
                        <Button component="label" variant="contained"
                            startIcon={<CloudUploadIcon className="text-white" style={{fontSize:30}} />}>
                            Upload file
                            <VisuallyHiddenInput onChange={(file) => onFileUpload(file)} accept=".csv, .xlsx" type="file" />
                        </Button>
                    </div>

                </div>
            </Dialog>
        </React.Fragment>
    );
}