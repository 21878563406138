import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import MagnifyingGlass from "../assets/menu_icons/magnifyingGlass.svg";
import {  useLocation, useNavigate } from "react-router-dom";
import CustomerSearch from "../pages/customer-page/CustomerSearch";
import CorporationSearch from "../pages/corprate_page/CorporationSearch";
import CustomerApprovedSearch from "../pages/customer_approval/CustomerApproveSearch";
// import NewItemGroupSearch from "../pages/NewItemGroupSearch";
import ItemSearchComponent from "./ItemSearchComponent";
import FinishItemsSetupSearchItems from "../pages/FinishItemSetupSearch";
import SubscriptionSearch from "./SubscriptionSearch";
import FinishItemSearch from "./FinishItemSearch";
import ChartAccountSearch from "./ChartAccountSearch";
import InvoiceSearch from "../pages/invoice_page/InvoiceSearch";
import PaymentReceiveSearch from "./PaymentReceiveSearch";
import PurchaseOrderSearch from "../pages/purchase_order/purchaseOrderSearch";
import VendorSearch from "./VendorSearch";
import BillSearch from "../pages/bill/BillSearch";
import PurchaseReceiveSearch from "../pages/purchase_receive/PurchaseReceiveSearch";
import SalesOrderSearch from "../pages/sales_order/SalesOrderSearch";
import VoucherOfflineSearch from "../pages/voucher-offline/VoucherOfflineSearch";
import FeatureSearch from "../pages/home_page_feature/FeatureSeaarch";
import PriceListSearch from "../pages/price_list/PriceListSearch";
import CorApprovalSearchBox from "../pages/corporate_approval/CopApprovalSearchBox";
import RatingSearch from "../pages/rating/RatingSearch";
import CarrierBagReturnSearch from "../pages/carreer_bag/CarrierBagReturnSearch";
import ItemGroupSearch from "./ItemGroupSearch";
import VoucherSearchBar from "../pages/vouchers/VoucherSearchBar";
import ProductActionUrlSearch from "../pages/product_action-url/ProductActionURLSearch";


const people = [
  { id: 1, name: "Wade Cooper" },
  { id: 2, name: "Arlene Mccoy" },
  { id: 3, name: "Devon Webb" },
  { id: 4, name: "Tom Cook" },
  { id: 5, name: "Tanya Fox" },
  { id: 6, name: "Hellen Schmidt" },
];

const SearchBarGeneralTwo = () => {
  const [selected, setSelected] = useState(people[0]);
  const [query, setQuery] = useState("");

  const filteredPeople = query === ""? people : people.filter((person) =>
          person.name.toLowerCase() .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, "")) );


    const { pathname } = useLocation(); 
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];

            function showDynamicSearch() {
              switch (currUserMenu) {
                case "customers":
                  return <CustomerSearch />;
                case "dashboard":
                  return <CustomerSearch />;
                  case "corporate":
                    return <CorporationSearch />;
                  case "customer-approval":
                    return <CustomerApprovedSearch />;
                    // Stop point
          
                case "item-groups":
                  return <ItemGroupSearch />;
                case "items":
                  return <ItemSearchComponent />;
                case "production-setup":
                  return <FinishItemsSetupSearchItems />;
                case "production-entry":
                  return <FinishItemSearch />;
                case "subscriptions":
                  return <SubscriptionSearch />;
                case "chart-of-accounts":
                  return <ChartAccountSearch />;
                case "invoices":
                  return <InvoiceSearch />;
                  // stop two
                case "payment-received":
                  return <PaymentReceiveSearch />;
                case "purchase-order":
                  return <PurchaseOrderSearch />;
                case "vendors":
                  return <VendorSearch />;
                case "bills":
                  return <BillSearch />;
                case "purchase-receives":
                  return <PurchaseReceiveSearch />;
                case "sales-order":
                  return <SalesOrderSearch />;
              
                case "voucher":
                  return <VoucherSearchBar />;
                case "redeem-offline-voucher":
                  return <VoucherOfflineSearch />;
                case "feature-action-url":
                  return <FeatureSearch />;
                case "product-feature-action-url":
                  return <ProductActionUrlSearch />;
                case "price-list":
                  return <PriceListSearch />;
                case "corporate-approval":
                  return <CorApprovalSearchBox />;
                case "grocedy-rating":
                  return <RatingSearch />;
                case "Carrier-Bag-Return":
                  return <CarrierBagReturnSearch />;

                default:
                  return null
              }
            }
          

  return (
    <div className="">
     {showDynamicSearch()}
    </div>
  );
};
export default SearchBarGeneralTwo;
