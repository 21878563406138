


function DimentionSelect(props) {

    const handleChange = (val) => {
      props.selectedDimension(val.Code)
      };

 
     const  createSelectItems = ()=> {
        let items = [];         
    
        props?.productDimension?.forEach((item, i)=> {
          items.push(<option key={i} value={i}>{item.Name}</option>)
        });
        return items;
    } 
      

    return ( 
        <>

   
  <select onChange={handleChange}>
    {createSelectItems()}
  </select>



        </>
     );
}

export default DimentionSelect;