import { useEffect, useState } from 'react';
import Select from 'react-select';
import { GetRecoilBillListData} from "../../store/billsAtom";





function BillTaxComponent(props) {  const masterData = GetRecoilBillListData();
  const [customOption, setCustomOption] = useState([]);



  useEffect(()=> {
    const tempData =  masterData['Tax']?.map((element)=> {
       return ({...element, label:`${element?.Tax_Name}`})
     }
     );
     setCustomOption(tempData);
 
   },[masterData])


  
  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      backgroundColor: '#F1F1F1',
      // width: 512,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"26px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
    menuPortal:styles => ({ ...styles, zIndex: 9999 })
  }

    const handleChange = (value) => {
      props.onTaxSelect(value)
      };


    return ( 
        <>
         <Select key={props?.defaultVal}   styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal} options={customOption}/>


        </>
     );
}

export default BillTaxComponent;
