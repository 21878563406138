import React from "react";

import locationImage from "../assets/location_img.jpeg";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { editCustomerAtom } from "../store/customerAtom";
import GeneralSelect from "./GeneralSelect";
import underscore from "underscore";


function AddCustomerLocation(props) {
  const [latitude, setLatitude] = useState(39.949338);
  const [longtitude, setLongtitude] = useState(-75.1968216);
  var customerToEdit = useRecoilValue(editCustomerAtom);
  const { masterData } = props;
  var masterDataGroup = underscore?.groupBy(masterData, "Master_Type");
  // let branchList = masterDataGroup[16];
  // let unitList = masterDataGroup[17];

  const {register, handleSubmit, reset,setValue ,formState: { errors }} = useForm();
  const onErrors = (errors) => console.error(errors);

  useEffect(() => {
    let defaultValues = {};
    defaultValues.GPS_Latitude = customerToEdit?.GPS_Latitude;
    defaultValues.GPS_Longitude = customerToEdit?.GPS_Longitude;

    reset({ ...defaultValues });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    props.submitSection(data);
  };

  function updateLatitude(ev) {
    const val = ev.target.value;
    setLatitude(val);
  }

  function updateLongtitude(ev) {
    const val = ev.target.value;
    setLongtitude(val);
  }


  function onUitSelect(item) {
  
  //  setValue("unit")
  }

  return (
    <>
      <section className="w-full max-w-6xl flex mb-5 justify-start ">
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <section className=" ">
            <section className="mt-10">
              <p className="font-bold text-sm ml-2"> GPS info </p>

              <div className="flex  sm:flex lg:flex ">
                <label className="ml-2">
                  <span className="text-sm text-filterTextColor"> Branch</span>
                  <br></br>
               
                  <input
                    className="bg-inputColor h-[6vh] pl-3  lg:w-[15vw]"
                    placeholder="unit"
                    disabled={true}
                  />
                </label>

                <label className="mr-2 ml-2 ">
                  <span className="text-sm text-filterTextColor">
                    {" "}
                    GPS latitude
                  </span>
                  <br></br>
                  <input
                  
                    className="bg-inputColor h-[6vh] pl-3  lg:w-[15vw]"
                    placeholder="enter latitude"
                    onChange={(evt) => updateLatitude(evt)}
                    name="gps_latitude"
                    {...register("GPS_Latitude")}
                  />
                </label>

                <div className="ml-2  sm:flex lg:flex justify-between">
                  <a
                    className="rounded-lg px-10  my-3 py-2  text-blue-100"
                    href={`https://maps.google.com/maps?q=${latitude},${longtitude}&t=&z=16&ie=UTF8&iwloc`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      src={locationImage}
                      width="50"
                      height="50"
                      alt=""
                      srcDet=""
                    />
                  </a>
                </div>
              </div>

              <div className="flex  sm:flex lg:flex ">
                <label className="ml-2">
                  <span className="text-sm text-filterTextColor"> Unit</span>
                  <br></br>
                  <input
                  disabled={true}
                    className="bg-inputColor h-[6vh] pl-3  lg:w-[15vw]"
                    placeholder="unit"
                  />
                </label>

                <label className="ml-2 ">
                  <span className="text-sm text-filterTextColor">
                    {" "}
                    GPS Longitude
                  </span>
                  <br></br>
                  <input
                    className="bg-inputColor h-[6vh] pl-3  lg:w-[15vw]"
                    onChange={(evt) => updateLongtitude(evt)}
                    placeholder="enter longitude"
                    name="gps_longitude "
                    {...register("GPS_Longitude")}
                  />
                </label>
              </div>
            </section>
          </section>
          <div className="flex justify-end mr-5 md:ml-[10vw] lg:hidden">
            <button
              type="submit"
              className="rounded-lg px-10  my-3 py-2 bg-blue-500 text-blue-100"
            >
              Save
            </button>
          </div>

          {/* BUTTON DESKTOP */}
          <div className="hidden  lg:flex justify-start">
            <button
              type="submit"
              className="rounded-lg px-10  my-3 py-2 bg-blue-500 text-blue-100"
            >
              Save
            </button>
          </div>
        </form>
      </section>

 
    </>
  );
}

export default AddCustomerLocation;
