import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import VoucherCreateTable from "./VoucherCreateTable";




function VoucherCorporateContent(props) {
    const { voucherDetails, newVoucherList } = props;
    const [summary, setSummary] = useState({total:0, assigned:0, open:0, un_assigned:0 , redeemed: 0});



    useEffect(()=> {
        
        let un_assigned = 0;
        let redeemed = 0;
        let open = 0;
        let total = newVoucherList.length;
        newVoucherList.forEach(element => {
            if(element?.Voucher_Status_Id === 3){
                un_assigned += 1;
            }
            if(element?.Voucher_Status_Id === 2){
                redeemed += 1;
            }
            if(element?.Voucher_Status_Id === 1){
                open += 1;
            }
            
        });

        setSummary({un_assigned: un_assigned, open: open, redeemed: redeemed, total: total});
    },[newVoucherList]);


    //  dynamically manage different status
    const manageSalesStatus = (status) => {
        let defaultClass = 'status_color1 text-white';

        switch (status) {
            case 'Draft':
                defaultClass = 'text-light status_color2';
                break;
            case 'Approved':
                defaultClass = 'text-light status_color3';
                break;
            case 'Pending for Approval':
                defaultClass = 'text-dark status_color4';
                break;
            case 'Fulfilled':
                defaultClass = 'text-light status_color5';
                break;
            case 'Rejected':
                defaultClass = 'text-light status_color6';
                break;
            case 'Confirmed':
                defaultClass = 'text-light status_color1';
                break;
            case 'Partially Billed':
                defaultClass = 'text-light status_color8';
                break;

            default:
                break;
        }

        return (
            <span className={`py-2 pl-4 pr-4 pt-2 pb-2 h5 ${defaultClass} rounded`}>
                {status}
            </span>
        );
    };


    // view subscription by going to subscription module




    return (
        <section className="   mt-2 w-full max-w-6xl text-filterTextColor">
           
            <Grid className="  " container spacing={2}>
                <Grid className="border-2 mx-4 my-2 " item xs={12}>

                    <Grid container spacing={2} className="bg-light">



                        <Grid className="mb-2 " item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={5}>
                                    <div>


                                        <div className="flex justify-between ">
                                            <div>
                                                <span className=" text-lg"><p className=" text-md">Corporate Name:  </p></span>
                                            </div>
                                            <div className="w-50">
                                                <span style={{ color: "#4999F6" }}>
                                                    <p className="text-primary text-lg font-bold" > {voucherDetails?.Corporate_Name}  </p>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex  justify-between">
                                            <div>
                                                <span className="text-md"><p>Address:</p></span>
                                            </div>
                                            <div className=" w-50">
                                                <span >
                                                    <p className=" text-md text-secondary" >
                                                        <i></i></p>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex  justify-between">
                                            <div>
                                                <span className="text-md"><p>Registration Date:</p></span>
                                            </div>
                                            <div className=" w-50">
                                                <p  className=" text-md text-secondary"></p>
                                            </div>
                                        </div>

                                        <div className="flex  justify-between">
                                            <div>
                                                <span className="text-md "><p>Contact Person:</p></span>
                                            </div>
                                            <div className=" w-50 ">
                                                <span className=" text-md text-secondary" ><p>  </p> </span>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div>
                                                <span className="text-md "><p>Created By:</p></span>
                                            </div>
                                            <div className=" w-50 ">
                                                <span className=" text-md text-secondary" ><p>{} </p> </span>
                                            </div>
                                        </div>
                                        <div className="flex ">
                                            <div>
                                                <span className="text-md "><p>Note:</p></span>
                                            </div>
                                            <div className=" w-50 ml-1">
                                                <span className=" text-md text-secondary" > {voucherDetails?.Voucher_Remark}  </span>
                                            </div>
                                        </div>


                                    </div>
                                </Grid>
                                <Grid item className="" xs={6}>
                                    <section className="  flex justify-center w-full">
                                        <div className=" ">


                                            <div className="flex justify-between ">
                                                <div>
                                                    <span className="text-md text-md"><p className="text-md text-md">Voucher Quantity:</p></span>
                                                </div>
                                                <div className="ml-3 ">
                                                    <span >
                                                        <p className="text-primary text-dm text-secondary" >{voucherDetails?.Voucher_Quantity} </p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex  justify-between">
                                                <div>
                                                    <span className="text-md"><p>Amount/Unity:</p></span>
                                                </div>
                                                <div className=" ml-3 ">
                                                    <span >
                                                        <p className=" text-md text-secondary" >₦{voucherDetails?.Voucher_Amount?.toLocaleString()} </p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex  justify-between">
                                                <div>
                                                    <span className="text-md"><p>Total Amount:</p></span>
                                                </div>
                                                <div className="ml-3 ">
                                                    <p className=" text-md text-secondary">₦{voucherDetails?.Total_Voucher_Amount?.toLocaleString()} </p>
                                                </div>
                                            </div>

                                            <div className="flex  justify-between">
                                                <div>
                                                    <span className="text-md "><p>Created Date:</p></span>
                                                </div>
                                                <div className=" ml-3 ">
                                                    <span className=" text-md  text-secondary" ><p>{moment(voucherDetails?.Voucher_Date).format('llll')} </p> </span>
                                                </div>
                                            </div>
                                        </div>

                                    </section>
                                </Grid>
                            </Grid>
                        
                        </Grid>

                    </Grid>
                </Grid>
                <Grid className="border-2 mx-4  bg-light  my-2 " item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <span className="#476ef8 font-bold ">
                                Voucher Information
                            </span>
                        </Grid>
                        <Grid item xs={12}>
              
                            {/* <  VoucherTable list={voucherDetailsList} ></VoucherTable> */}
                            {/* <VoucherCreateTable/> */}
                            <VoucherCreateTable shareActionButtonAll={props?.shareActionButtonAll}  ></VoucherCreateTable>


                        </Grid>

                    </Grid>



                </Grid>


            </Grid>





        </section>
    );
}

export default VoucherCorporateContent;
