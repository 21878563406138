// import { DataGrid ,  gridPageCountSelector,
//     gridPageSelector, GridToolbarContainer, GridToolbarExport ,
//     useGridApiContext,
//     useGridSelector} from "@mui/x-data-grid";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
  GridToolbar,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import {
  GetRecoilCorporateList,
  SetRecoilCorporateList,
} from "../store/corporateAtom";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      rowsPerPageOptions={[6]}
      checkboxSelection={true}
      components={{
        Toolbar: CustomToolbar,
        Pagination: CustomPagination,
      }}
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function CorporatePageTable(props) {
  const corporateData = GetRecoilCorporateList();
  const setCorporateData = SetRecoilCorporateList();
  const [tableList, setTableList] = useState([]);
  const [tableHeadList, setTableHeadHead] = useState([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  console.log("Table ", corporateData);

  useEffect(() => {
    let isMount = true;
    if (corporateData.Display_Column?.length) {
      let newTableHead = corporateData.Display_Column?.filter(
        (item) => item.Display_Id === 1
      )
        .map((item) => {
          return {
            ...item,
            field: item.Column_Value.trim(),
            headerName: item.Column_Name,
            width: 150,
            Due_Date_String: moment(item.Due_Date_String).format("DD/MM/YYYY"),
          };
        })
        .sort((a, b) => {
          return a.Print_Sequence - b.Print_Sequence;
        });
      if (isMount) {
        setTableHeadHead(newTableHead);
      }
    }

    // set table list
    var list = props?.tableList?.map((item, i) => {
      return { ...item, id: i };
    });
    setTableList(list);

    return () => {
      isMount = false;
    };
  }, [corporateData, props?.tableList]);

  function OnItemCellClick(event) {
    const id = event.row["Corporate_Id"];
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
  }

  function onSelectChange(selectedList) {
    // var selection = selectedList?.map((index) => {
    //   return props.tableData[index];
    // });
    // props.selectedTableItems(selection);
  }

  return (
    <>
      <div className="mt-4 h-screen overflow-x-auto outline-none border-[1px] border-gray-200 rounded-xl">
        <DataGrid
          className="outline-none w-full border-0"
          columns={tableHeadList}
          rows={tableList}
          components={{
            Pagination: CustomPagination,
          }}
          onRowSelectionModelChange={onSelectChange}
          onRowClick={(event) => {
            OnItemCellClick(event);
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
              cursor: "pointer",
            },
          }}
        />
      </div>
    </>
  );
}

export default CorporatePageTable;
