
import { notificationService } from '../../services/notification.service';

import React,{ useEffect, useContext, useState } from "react";
import 'antd/dist/antd.min.css';
import {Table} from 'antd';
import { useNavigate,  useLocation } from "react-router-dom";
import moment from "moment/moment";
import LogicContext from '../../Context/LogicContext';
import { DatePicker, Space } from 'antd';

import { SetServiceHistoryStore, GetServiceHistoryStore } from '../../store/IVRServiceHistory';



function ServiceHistory() {

  const navigate = useNavigate();
    const { pathname } = useLocation();
    var url = pathname.split("/");
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];
    const setStoreData = SetServiceHistoryStore();
    const getStoreData = GetServiceHistoryStore();


    useEffect(()=> {
        if(!getStoreData?.data?.length){
            loadHistory();
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ ]);



    const loadHistory = (page= '1') => {
      notificationService.getSurveyList(`page=${page}`).then((res)=> {
        if(res.data?.data){
          setStoreData(res?.data.data);
        }
     
      });
 
    }

   
    const handleRowClick = (record) => {
      navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/service-details/${record?.id}`);
    };

    const paginationOptions = {
        pageSize: 15,
        showSizeChanger: true,
        total: getStoreData.total,
        onChange: (page, pageSize) => {
          loadHistory(page);
        },
        
      };

    const columns = [
        {
          title: ' Id',
          dataIndex: 'id',
       
          // render: (data) => <span> </span> ,
        },
     
        {
          title: "Template Name",
          dataIndex: "name",
          // sorter: (a, b) => a.name.localeCompare(b.name)
        },
      
        {
          title: "Total Calls",
          dataIndex: "reciepentsno",
          // sorter: (a, b) => a.reciepentsno.toString().localeCompare(b.reciepentsno.toString())
        },
      
        {
          title: "Status",
          dataIndex: "status",
          // sorter: (a, b) => a.status.localeCompare(b.status)
        },
        {
          title: "Created At",
          dataIndex: "created_at",
          // sorter: (a, b) => a.created_at.localeCompare(b.created_at),
        key: 'created_at',
        //  render: (text) => moment(text).format('LLL'), 
        },
        {
          title: "Created At",
          dataIndex: "updated_at",
          // sorter: (a, b) => a.created_at.localeCompare(b.created_at),
        key: 'updated_at',
        //  render: (text) => moment(text).format('LLL'), 
        },
        {
          title: "Run Time",
          dataIndex: "run_time",
        //   sorter: (a, b) => a.Earn_Title.localeCompare(b.Earn_Title)
        },
     
   
    
      
      ];



    return ( 
        <>
     
        <div>
          <div className="row  mb-4" >
            <div className="col"  >
            {/* <ReloadOutlined onClick={()=> loadHistory()} style={{ fontSize: '30px', color:'blue' }} /> */}
            </div>
            <div className="col" >
                
            </div>
            <div className="col font-bold" >
              Showing :<span className="badge badge-primary p-2">
               {getStoreData?.data?.length} of {getStoreData?.total}
                </span> <br/>
                <small>Current page - {getStoreData?.current_page}/{getStoreData?.last_page} </small>
            </div>

          </div>
        </div>

         <Table
          columns={columns}
          dataSource={getStoreData?.data?.map((item)=> {
            return {...item, created_at: moment(item.created_at).format(" DD-MM-YYYY:HH:mm:ss"),
              updated_at: moment(item.updated_at).format(" DD-MM-YYYY:HH:mm:ss")
            }
          }) }
       
        pagination={paginationOptions}
         style={{ background: '#f0f2f5', borderRadius: '8px' }}
         rowClassName="show-cursor"
         onRow={(record) => {
          return {
            onClick: () => {
              handleRowClick(record);
            },
          };
        }}
          />

        </>
     );
}

export default ServiceHistory;