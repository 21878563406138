import React, { useRef, useContext, useEffect } from "react";
import NotificationSelect from "./NotificationSelect";
import { Grid } from "@mui/material";
import Userlayout from "../../layout/Userlayout";
import NotificationReasonTable from "./NotificationReasonTable";
import LogicContext from "../../Context/LogicContext";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { useLocation, useNavigate } from "react-router-dom";
import { notificationService } from "../../services/notification.service";
import { SetNotificationView, GetNotificationView } from '../../store/notificationViewAtom';
import { useForm } from "react-hook-form";
import moment from "moment";




const NotificationView = () => {
  const { register, setValue, getValues, handleSubmit, reset, formState: { errors } } = useForm();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const setNotiView = SetNotificationView();
  const getNotiView = GetNotificationView();



  useEffect(() => {
    if (getNotiView.notification.length < 1) {
      notificationService.notificationView().then(resp => {
        if (resp?.data?.notification_type?.length) {
          setNotiView(resp?.data);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const onSearchSelect = (data) => {
    setValue("notification_type", data?.code);
  }


  const searchByDate = async (event) => {
    const formData = getValues();
    const response = await notificationService.searchByDate(formData);
    if (response?.data?.length) {
      setNotiView((prev) => { return { ...prev, notification: response?.data } });
    } else {
      setNotiView((prev) => { return { ...prev, notification: [] } });

    }
  }


  return (
    <>
      <section className="  mt-2 ">

        <Grid container spacing={2}>
          <section className="w-full  mx-2 mt-2 flex justify-center  ">
            <section className="grid grid-cols-5 grid-flow-col gap-4 w-full border-1  text-dark py-4  ">

              <div className="card bg-light py-4 px-3 rounded">
                <h1 className=" text-center mt-1 h2 text-primary font-bold" >
                  900
                </h1>
                <div className="text-sm text-uppercase text-center font-bold mt-3 h3">Notification Info....</div>
              </div>

              <div className="card bg-light py-4 px-3 rounded">
                <div className=" text-center mt-1 h2 text-dark font-bold">
                  700
                </div>
                <div className="text-sm text-uppercase text-center font-bold mt-3 h3">Notification Info....</div>
              </div>

              <div className="card bg-light py-4 px-3 rounded">
                <div className=" text-center mt-1 h2 text-danger font-bold">
                  700
                </div>
                <div className="text-sm text-uppercase text-center font-bold mt-3 h3">Notification Info....</div>
              </div>

              <div className="card bg-light py-4 px-3 rounded">
                <div className=" text-center mt-1 h2 text-success font-bold">
                  400
                </div>
                <div className="text-sm text-uppercase text-center font-bold mt-3 h3">Notification Info....</div>
              </div>

              <div className="card bg-light py-4 px-3 rounded">
                <div className=" text-center mt-1 h2 text-warning font-bold">
                  20 
                  </div>
                <div className="text-sm text-uppercase text-center font-bold mt-3 h3">Notification Info....</div>
              </div>
            </section>
          </section>
          <Grid item className="mb-2" xs={3}>
            <div className="">
              <span className="text-sm ">From Date:</span>
              <div className="">
                <input
                  defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
                  {...register("fr_date")}
                  className="bg-filterInput  w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                  type="date"

                />
              </div>
            </div>
          </Grid>
          <Grid item className="mb-2" xs={4}>
            <div className="">
              <span className="text-sm ">To Date:</span>
              <div className="">
              {/* h-[5.6vh] */}
                <input
                  {...register("to_date")}
                  defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
                  className="bg-filterInput  w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                  type="date"


                />
              </div>
            </div>
          </Grid>
          <Grid item className="mb-2" xs={4}>
            <span className="text-sm ">Notification filter:</span>
            <NotificationSelect onSearchSelect={(event) => onSearchSelect(event)} dataList={getNotiView?.notification_type} />
          </Grid>
          <Grid item xs={1} className="flex justify-center w-full">
            <div>
              <button
                onClick={() => searchByDate()}
                type="submit"
                style={{ backgroundColor: "#476EF8" }}
                className="text-sm text-white px-3 mt-4 rounded py-2.5" >
                Search
              </button>
            </div>
          </Grid>

        </Grid>
        <Grid item x3={12}>
          <NotificationReasonTable dataList={getNotiView?.notification} ></NotificationReasonTable>

        </Grid>

      </section>

    </>
  );
};

export default NotificationView;
