import { useEffect, useState } from "react";
import Select from 'react-select';
import { GetInvoiceEntryMasterData} from '../store/invoiceAtom';




function InvoiceItemSearchItems(props) {

    const masterData = GetInvoiceEntryMasterData();
    const customOption = masterData['Plans']?.map((item)=> {
      return ({...item, label:`${item.Plan_Name}  ${item.Item_Sku}` })
    });


    const handleChange = (item)=> {
        props.currentSelectedItem(item)
    }
  

    return ( 
        <>
        <div className="">
        <small>Select item</small>
        <div >
        <Select  className="lg:w-[38.5vw] "  options={customOption} onChange={handleChange}  />

        </div>
        </div>

        
        </>
     );
}

export default InvoiceItemSearchItems;
