
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import "./ProductItem.css";

import "./productionEntryTab.css";
import { useState } from "react";
import { useEffect } from "react";
import LogicContext from "../../Context/LogicContext";

import { itemsSetupService } from "../../services/itemSetup.service";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Tooltip from '@mui/material/Tooltip';

import Box from "@mui/material/Box";
import { SetWareHouse, GetProdMasterData, SetProdMasterData} from "../../store/finishItemAtom";

import {  useLocation, useNavigate } from "react-router-dom";
import {SetProductionSelectItem, GetProductionSelectItem} from '../../store/productionAtom';
import '../../pages/scrollBar.css';
import  {GetProductionItems} from '../../store/finishItemAtom';
import ProductionSetupTab from "../ProductionSetupTab";
import ProductionEntryTab from "./ProductionEntryTab";
import ProductionScrollTable from "../ProductionScrollTable";
import FinishedItemHistory from "../FinishedItemHistory";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  





function ProductionDetailsPage() {
    const setWareHouse = SetWareHouse();
    const setMasterData = SetProdMasterData();
    const masterData = GetProdMasterData();
    const [tabState, setTabState] = useState(0);
    const setSelectedItem = SetProductionSelectItem();
    const selectedItem = GetProductionSelectItem();

  
    const logicContext = useContext(LogicContext);
    // const [editMode, setEditMode] = useState(true);
    // const { batch_id } = useParams();
  
    const navigate = useNavigate();
    const { pathname } = useLocation();
    var url = pathname.split("/");
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];
    var itemList = GetProductionItems();


    useEffect(() => {
        if (masterData.length === 0) {
          itemsSetupService.getProdMasterdata().then((res) => {
            if (res?.data !== undefined) {
              setMasterData(res.data);
            }
          });
        }
      }, [masterData]);



      useEffect(() => {
          itemsSetupService.getProductionDropDown().then((res) => {
            if (res.data !== undefined) {
              setWareHouse(res?.data);
            }
          });
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);


    const handleTabChange = (event, newValue) => {
        setTabState(newValue);
      };


      const changeTabDynamically = ()=> {
        setTabState(1);
      }


      useEffect(() => {
        if (masterData.length === 0) {
          itemsSetupService.getProdMasterdata().then((res) => {
            if (res?.data !== undefined) {
              setMasterData(res.data);
            }
          });
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [ masterData]);



      function selectItem(selectedItem) {
        setSelectedItem(selectedItem);
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${selectedItem.Item_Id}`+'_'+ 3)
     
      }

      function viewItemDetails(item_id) {
        navigate(
          `/${currModuleId}/${currModuleName}/items/item-details/${item_id}`
        );
      }


      function addItemType(type) {
        setSelectedItem((prev)=> {
          return ({...prev, Item_Variety_Id:type });
        });
      }
    
    
    return ( 
        <>
       
      <section className=" mt-2">

        
   
      {/* <ProductionSelect  Item_Variety_Id={selectedItem?.Item_Variety_Id}
              addItemType={(type)=> addItemType(type)}>

            </ProductionSelect> */}


        <section className="flex relative justify-between">
          <section className=" scroll w-40  z-0 	main border-1">
          
            <p className=" mx-4 pt-2 text-xs font-bold md:text-sm ">
              Finished Items{" "}
            </p>

            {/* <FinishProductionSideScroll tableData={itemList} selectItem={selectItem} /> */}
            <ProductionScrollTable tableData={itemList} selectItem={selectItem}></ProductionScrollTable>
          </section>

          <section className="w-full  z-50 ">
          <div className="w-full">
                    <div className="flex   justify-end ">
                

                   
                       <div>
                       {/* <Tooltip title="Print">
                      <button
                        style={{ borderRadius: "5px" }}
                        className=" rounded   px-3 h-[5.8vh] text-filterTextColor bg-filterColor  ml-1 "
                      >
                        <img src={print} alt="" />
                      </button>
                      </Tooltip> */}


                      <Tooltip title=" Edit production">
                      <button
                        style={{ borderRadius: "5px", backgroundColor:"#476EF8" }}
                        className=" rounded   px-3 h-[5.8vh] text-white  ml-1 "
                      >
                        ADD NEW ITEM
                      </button>
                      </Tooltip>
                       </div>
                    </div>
                  </div>
            <section className=" mt-[5vh] ">
              <div className="flex   flex-wrap justify-between md:">
                <div className="flex  ml-3  w-[56.5vw]">
                  
                    <div className=" w-full">
                      <div
                        onClick={() =>
                          viewItemDetails(selectedItem?.Item_Id)
                        }
                        style={{ color: "#2B0FDB" }}
                        className=" w-full text-xs font-bold md:text-sm flex "
                      >
                        <span>Finished Items</span>{" "} <span className="ml-2">{selectedItem?.Item_Name}</span>
                      </div>
                      
                      <span className="font-bold">
                        {" "}
                        <i style={{ color: "#2807F1", opacity: "0.5" }}>
                          {" "}
                          Item ID : {selectedItem?.Item_Id}{" "}
                        </i>{" "}
                      </span>
                    </div>
               

                 
                </div>
              </div>
            </section>

            {/* New Tab */}
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabState}
                  onChange={handleTabChange}
                  aria-label="">
                  <Tab label="Production Setup" {...a11yProps(0)} />
                  <Tab label="Production Entry" {...a11yProps(1)} />
                  <Tab label="History" {...a11yProps(2)} />
                </Tabs>
              </Box>

              {/* End Tab */}

              {/* Tab One */}
              <TabPanel value={tabState} index={0}>
             <ProductionSetupTab changeTab={changeTabDynamically}></ProductionSetupTab>
              </TabPanel>
              <TabPanel value={tabState} index={1}>
             <ProductionEntryTab ></ProductionEntryTab>
              </TabPanel>

              <TabPanel value={tabState} index={2}>
                  
                <FinishedItemHistory
                //   item_Id={selectedFinishItem?.Item_Id}
                //   modifyProduction={modifyProduction}
                //   batch_no={getValues("batch_no")}
                changeTab={changeTabDynamically}
                ></FinishedItemHistory>
              </TabPanel>
            </Box>

         
          </section>
        </section>
      </section>
        </>
     );
}

export default ProductionDetailsPage;