import React, { useEffect, useState } from "react";
import { itemsSetupService } from "../../../services/itemSetup.service";
import {
  GetProductionItems,
} from "../../../store/finishItemAtom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import "../../../pages/AddComposit.css";
import CompositeGroupForm from "./CompisiteGroupForm";
import CompositeGroupAddPicture from "./CompositesGroupAddPicture";
import {GetCompositeGRoupEdit, SetCompositeGroupEdit} from '../../../store/compositeGroup';



const TAB_SECTION = {
  ADD_GROUP: "ADD_GROUP",
  ADD_PICTURES: "ADD_PICTURES",

};



function CompositeItemGroupAddNew() {
  const [selectedTab, setSelectedTab] = useState(TAB_SECTION.ADD_GROUP);
  var itemList = GetProductionItems();
  const editableGroup = GetCompositeGRoupEdit();
  const  setEditableGroup = SetCompositeGroupEdit();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];



  function addNewItem() {
    navigate(`/${currModuleId}/${currModuleName}/items/add-item/${4}`)
  }

  function changeTab(tab){
      setSelectedTab(tab)
  }



  const submitGroupImage = (imageFile, imageId)=> {
    const imageFormat = imageFile.type.split('/')[1];
    // const format
    let formData = new FormData();
        formData.append('file', imageFile);
        formData.append('Composite_Group_Id', editableGroup.Composite_Group_Id);
        formData.append('picture_format', imageFormat);
        formData.append('option', 1);
        itemsSetupService.compositesUploadImage(formData).then(res => {
          console.info(res?.data);
     
        
        }).catch(err => {
        })
  
  }


  function submitImageRecord(body){
    itemsSetupService.saveImageProperties(body).then( res => {
      // setNewGroupId()
    })
  }

  
    return ( 
        <>

        <section className=" py-4 px-2  md:flex justify-between  ">
          <div>
            <Tooltip title="group information">
              <button
              onClick={()=> changeTab(TAB_SECTION.ADD_GROUP)}
                style={{ backgroundColor: "#476EF8" }}
                className=" btn  rounded text-white text-sm  mt-1 py-[1.8vh]  px-3"
              >
                Group Information
              </button>
            </Tooltip>

            {/* {editableGroup?.Composite_Group_Id ?  */}
               <Tooltip disabled={!editableGroup?.Composite_Group_Id } title="Add pictures">
               <button onClick={()=> changeTab(TAB_SECTION.ADD_PICTURES)}
                 className=" ml-5 btn rounded text-black bg-filterColor text-sm  mt-1 py-[1.8vh]  px-14"
               >
                 Pictures
               </button>
             </Tooltip>
          {/* : null} */}
         
          </div>

          <div className="flex  ">
            <Tooltip title="add new item">
              <button
                onClick={addNewItem}
                style={{ backgroundColor: "#476EF8" }}
                className="  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
              >
                ADD NEW ITEM
              </button>
            </Tooltip>
          </div>
        </section>
        {selectedTab ==='ADD_GROUP' ?  
        <CompositeGroupForm changeTab={changeTab}></CompositeGroupForm>
        : null}
        
        { selectedTab ==='ADD_PICTURES' ? 
         <CompositeGroupAddPicture submitItemImage={submitGroupImage} getGroupImages={itemsSetupService.getItCompositeGroupImages} Composite_Group_Id={editableGroup?.Composite_Group_Id}></CompositeGroupAddPicture>
         :
         null}
       


        </>
     );
}

export default CompositeItemGroupAddNew;