
import React, { useEffect, useContext, useRef} from "react";
import { accountService } from "../../services/accountService";
import LogicContext from "../../Context/LogicContext";
import {SetBulkActions, SetTableDataHeadList,
SetTableDataList, GetTableDataList, SetTableFilter, GetTableFilter,
SetDepositTo,SetPaymentMode,
SetOfficerList,SetCustomerList} from '../../store/paymentReceivedAtom';
import  {SetGateWayData, GetGateWayData} from '../../store/payGatewayAtom';
import { Grid } from "@mui/material";

import CurrencyFormat from 'react-currency-format';

import Tooltip from "@mui/material/Tooltip";
import RestartAlt from "@mui/icons-material/RestartAlt";
import { useState } from "react";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { useLocation, useNavigate } from "react-router-dom";
// import PaymentBulkAction from "./PaymentBulkAction";
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import { Dropdown } from "react-bootstrap";
import PaymentGateWayTable from "./PaymentGatewayTable";
import PaymentDatePicker from "./PaymentDatePicker";
import { useForm } from "react-hook-form";
import moment from "moment";






function PaymentGateWayPage() {
  const { pathname } = useLocation();  
  const navigate = useNavigate();
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const logicContext = useContext(LogicContext);
  const getGwayRecoilData = GetGateWayData();
  const setGwayRecoilData = SetGateWayData();
  const myPromiseModal = createModal(GeneralModal);
  const { register, setValue, getValues, handleSubmit, reset, formState: { errors } } = useForm();
  const [overviewData, setOverviewData] = useState({flutterWave:0, providus: 0, monify:0 , ussd: 0, smartCash:0, posted:0, notPosted:0});
  const [payList, setPayList] = useState(getGwayRecoilData?.payment_info);
  const [grandTotal, setGrandTotal] = useState(0);




  useEffect(()=> {
    let isMounted =  true;
 
    searchByDate();
  
    return ()=> { 
      isMounted = false;

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(()=> {
    let temp = 0;
    let overviewData = {flutterWave:0, providus: 0, monify:0 , ussd:0, smartCash:0, posted:0, notPosted:0};
    payList?.map((payment, i) => {

      switch (payment.Payment_Gateway_Id) {
        case 1:
          overviewData.flutterWave += payment.Amount;
          temp += payment.Amount;
          break;

          case 2:
          overviewData.providus += payment.Amount;
          temp += payment.Amount;
          break;
      
          case 3:
          overviewData.monify += payment.Amount;
          temp += payment.Amount;
          break;
      
        default:
          if(payment?.Amount ){
            // console.log('Setting ', payment);
            // setGrandTotal((prev =>{ return prev + payment.Amount } ));
          }
          break;
      }
      setGrandTotal(temp);

      switch (payment.Status) {
        case 'Posted':
          overviewData.posted += 1;
          break;

          case "Not Posted":
          overviewData.notPosted += 1;
          break;
     
        default:
          break;
      }
    });

    setOverviewData(overviewData);

  },[getGwayRecoilData?.payment_info, payList]);




  const onFilterSelection = (option) => {
    // Payment_Gateway_Id
    if(option.Payment_Gateway_Id === 0){
      return  setPayList(getGwayRecoilData?.payment_info);
    }
    let filteredList = getGwayRecoilData?.payment_info?.filter((pay)=>  pay.Payment_Gateway_Id === option.Payment_Gateway_Id );
      return setPayList(filteredList);
  }




  const searchByDate = async ()=> {
    const formData =  getValues();
    try {
      const response =  await  accountService.getPaymentGatewayListByDate(formData?.from_date, formData?.to_date);
      if(response?.data !== undefined){
          if (response.data?.gateway_info.length > 0){
            setGwayRecoilData(response.data);
            setPayList(response.data?.payment_info);
          }else{
            logicContext.showToast('No record for search query!', '');
            setPayList([]);
            setGrandTotal(0);
          }
      }
    } catch (error) {
      console.log('ERRR ', error);
      logicContext.showToast('connection error getting invoice data', 'error');
    }
  }
 


  return (

<>

 <section >
 <section className="w-full mx-2 mt-2  flex justify-center  ">
        <section className="grid grid-cols-5 grid-flow-col gap-4 w-full border-1  text-dark py-4  ">

             <div className="card bg-light py-4 px-3 rounded">
            <h1  className=" text-center mt-1 h6 text-primary font-bold" >
            <CurrencyFormat  style={{ color: "#476EF8" }}
               value={parseFloat(grandTotal)?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
            </h1>
            <div className="text-sm text-uppercase text-center font-bold mt-3 h3">Total</div>
          </div>

             <div className="card bg-light py-4 px-3 rounded">
            <h1  className=" text-center mt-1 h6 text-primary font-bold" >
            <CurrencyFormat  style={{ color: "#476EF8" }}
               value={parseFloat(overviewData.flutterWave)?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
            </h1>
            <div className="text-sm text-uppercase text-center font-bold mt-3 h3">FlutterWave</div>
          </div>

          <div className="card bg-light py-4 px-3 rounded">
            <div className=" text-center mt-1 h6 text-dark font-bold">
            <CurrencyFormat  style={{ color: "#476EF8" }}
               value={parseFloat(overviewData.providus)?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
            </div>
            <div className="text-sm text-uppercase text-center font-bold mt-3 h3">Providus</div>
          </div>

          <div className="card bg-light py-4 px-3 rounded">
            <div className=" text-center mt-1 h6 text-danger font-bold">
            <CurrencyFormat  style={{ color: "#476EF8" }}
               value={parseFloat(overviewData.monify)?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} /> </div>
            <div className="text-sm text-uppercase text-center font-bold mt-3 h3">Monify</div>
          </div>

          <div className="card bg-light py-4 px-3 rounded">
            <div className=" text-center mt-1 h6 text-success font-bold">
            <CurrencyFormat  style={{ color: "#476EF8" }}
               value={parseFloat(overviewData.smartCash)?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} /> </div>
            <div className="text-sm text-uppercase text-center font-bold mt-3 h3">SmartCash</div>
          </div>

          <div className="card bg-light py-4 px-3 rounded">
            <div className=" text-center mt-1 h6 text-success font-bold">
            <CurrencyFormat  style={{ color: "#476EF8" }}
               value={parseFloat(overviewData.ussd)?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} /> </div>
            <div className="text-sm text-uppercase text-center font-bold mt-3 h3">USSD</div>
          </div>

          <div className="card bg-light py-4 px-3 rounded">
            <div className=" text-center mt-1 h6 text-warning font-bold">
            <CurrencyFormat  style={{ color: "#476EF8" }}
               value={overviewData.posted}
                       displayType={'text'} thousandSeparator={true} /> </div>
            <div className="text-sm text-uppercase text-center font-bold mt-3 h3">Posted</div>
          </div>

          <div className="card bg-light py-4 px-3 rounded">
            <div className=" text-center mt-1 h6 text-warning font-bold">
            <CurrencyFormat  style={{ color: "#476EF8" }}
               value={overviewData.notPosted}
                       displayType={'text'} thousandSeparator={true}/> </div>
            <div className="text-sm text-uppercase text-center font-bold mt-3 h3">Not Posted</div>
          </div>
        </section>
      </section>

  {/*  end over view */}

 <section className="bg-white  px-2    md:flex justify-between ">
   
      
            
            
            <Grid  container spacing={2}>
           
            <Grid item xs={2}>
            <div></div>
            <div></div>
            <div></div>
            <div className="flex justify-center mt-[2.6vh] ">
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    backgroundColor: "#E5E5E5",
                    border: "none",
                    color: "#45444B",
                    fontSize: "12px",
                    height: "35px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    marginLeft: "10px",
                  }}
                  id="dropdown-basic"
                >
                  FILTER BY
                </Dropdown.Toggle>
                <Dropdown.Menu>
                {getGwayRecoilData.gateway_info?.map((option)=> {
                      return (
                        <Dropdown.Item onClick={()=> onFilterSelection(option)} key={option?.Payment_Gateway_Id} href="#/action-1" >
                        {option?.Payment_Gateway_Name}
                      </Dropdown.Item>
                      )
                      })}
                         <Dropdown.Item onClick={()=> onFilterSelection({Payment_Gateway_Id: 0, Payment_Gateway_Name: 'All'})} key={0} href="#/action-1" >
                        All
                      </Dropdown.Item>
               
                </Dropdown.Menu>
              </Dropdown>

              <Tooltip title="Reload">
              <RestartAlt
                className="cursor ml-2 mt-1"
                onClick={() => searchByDate()}
                color="primary"
                fontSize="large"
              />
            </Tooltip>
            
            </div>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <div className="">
                                <span className="text-sm ">From Date:</span>
                                <div className="">
                                    <input
                                    defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
                                     {...register("from_date")}
                                        className="bg-filterInput h-[5.6vh] w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                        type="date"

                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="">
                                <span className="text-sm ">To Date:</span>
                                <div className="">
                                    <input
                                     {...register("to_date")}
                                     defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
                                        className="bg-filterInput h-[5.6vh] w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                        type="date"

                                    />
                                </div>
                            </div>
                        </Grid>
                      
                        <Grid item xs={2} className="flex justify-start w-full">
                            <div>
                                <button
                                onClick={()=> searchByDate()}
                                    type="submit"
                                    style={{ backgroundColor: "#476EF8" }}
                                    className="text-sm text-white px-3 mt-4 rounded py-2" >
                                    Search
                                </button>
                            </div>
                        </Grid>

                    </Grid>

     
       <div className="flex justify-between">
         <button className="  font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8 text-sm">
           IMPORT
         </button>
         <div className="btn-group">
          <button  className=" font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8   text-sm" data-toggle="dropdown" aria-expanded="false">
           EXPORT
         </button>
       </div>
           {/* <button 
           className="bg-buttonColor font-[Poppins]  mr-1 px-4 rounded md:ml-8  text-white text-xs">
             ADD NEW
           </button> */}
        
       </div>
     </section>
     <section className="mt-5">
       <PaymentGateWayTable list={payList} />
     </section>
   </section>
</>

  )
}

export default PaymentGateWayPage;
