import { useEffect } from "react";
import { GetRecoilBillListData } from "../../store/billsAtom";
import CurrencyFormat from "react-currency-format";

import { Grid } from "@mui/material";
// import CurrencyFormat from "react-currency-format";
// import moment from "moment";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useRef } from "react";
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import { twMerge } from "tailwind-merge";

function BillsDetailsScroll(props) {
  const billListData = GetRecoilBillListData();
  const [tableData, setTableData] = useState([]);
  const { bill_id } = useParams();
  const [checkedAll, setCheckAll] = useState(false);

  const mountRef = useRef(true);

  useEffect(() => {
    mountRef.current = true;
    if (mountRef.current) {
      setTableData(
        billListData["Bill_Info"]?.map((ele) => {
          return { ...ele, checked: false };
        })
      );
    }

    return () => {
      mountRef.current = false;
    };
  }, [billListData]);

  const onCheckboxClick = async (e, index) => {
    let element = tableData[index];
    tableData[index] = { ...element, checked: e.target.checked };
    await setTableData((prev) => {
      return [...tableData];
    });
    props?.onSelection(tableData);
  };

  const onCheckAllElement = async (selection) => {
    setCheckAll((prev) => {
      return !prev;
    });

    let newList = tableData?.map((item, i) => {
      return { ...item, checked: !selection };
    });
    await setTableData((prev) => {
      return [...newList];
    });
    props?.onSelection(newList);
  };

  const onActionFilterPerform = (action) => {
    const option = action.target.value;
    if (Number(option) === 0) {
      return setTableData(billListData["Bill_Info"]);
    }
    var newList = billListData["Bill_Info"].filter(
      (item) => item.Bill_Status_Id === Number(option)
    );
    setTableData(newList);
  };

  const manageBillStatus = (status) => {
    let defaultClass = "text-primary";

    switch (status) {
      case "Draft":
        defaultClass = "text-danger";
        break;
      case "Pending Approval":
        defaultClass = "text-warning";
        break;
      case "Open":
        defaultClass = "text-info";
        break;
      case "Paid":
        defaultClass = "text-success";
        break;
      case "Received":
        defaultClass = "text-success";
        break;
      case "Partially Paid":
        defaultClass = "text-dark";
        break;

      default:
        break;
    }

    return (
      <span className={`text-sm font-bold md:text-sm ${defaultClass} `}>
        {status}
      </span>
    );
  };

  return (
    <section className="border-[1px] rounded-lg bg-white border-gray-200 flex flex-col overflow-y-scroll h-[300px] lg:h-[1000px] scrollbar-thumb-gray-500 scrollbar-thin scrollbar-track-gray-100 pb-[100px]">
      <div className="border-b-[1px] border-b-gray-300">
        <ActionPaneComponent
          bulkActionData={billListData["Filtering_Criteria"]}
          onSelectBulkAction={onActionFilterPerform}
        />
      </div>
      <div
        className={twMerge(
          `p-2 lg:p-1 grid grid-cols-2 lg:grid-cols-none  border-b-[1px] cursor-pointer border-gray-200`
        )}
      >
        <label className="ml-1 flex flex-row items-center">
          <input
            onChange={(e) => onCheckAllElement(checkedAll)}
            type="checkbox"
            className="w-4 h-4 border-[1px] border-gray-300 rounded-sm cursor-pointer"
            checked={checkedAll ? checkedAll : ""}
          />
          <span className="ml-2 font-bold ">Select all</span>
        </label>
      </div>
      <div>
        {tableData?.map((bill, index) => {
          return (
            <div
              key={index}
              className={twMerge(
                `p-2 lg:p-1 grid grid-cols-2 lg:grid-cols-none  border-b-[1px] cursor-pointer border-gray-200 ${
                  Number(bill_id) === bill?.Bill_Ref_No
                    ? "bg-gray-200"
                    : "hover:bg-gray-100"
                }`
              )}
            >
              <div className="flex flex-row">
                <div className="ml-1">
                  <input
                    onChange={(e) => onCheckboxClick(e, index)}
                    checked={bill?.checked ? bill?.checked : ""}
                    type="checkbox"
                    className="w-4 h-4 border-[1px] border-gray-300 rounded-sm cursor-pointer"
                  />
                </div>
                <div onClick={() => props.viewBillDetails(bill?.Bill_Ref_No)}>
                  <label className="ml-2 ">
                    <span className="text-sm font-bold text-gray-700">
                      {bill?.Vendor_Name}{" "}
                    </span>{" "}
                    <br></br>
                    <span className="text-xs text-gray-400 font-semibold italic">
                      {bill?.Bill_Ref_No}
                    </span>
                    <br></br>
                    <span className="text-sm lg:text-xs font-medium text-gray-600">
                      <CurrencyFormat
                        value={parseFloat(bill.Bill_Amount)?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </span>
                  </label>
                </div>
              </div>
              <div className="lg:mt-2 lg:ml-5 ">
                <div className={twMerge("text-sm font-normal")}>
                  {manageBillStatus(bill?.Bill_Status_Name)}{" "}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default BillsDetailsScroll;
