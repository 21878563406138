import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";



function ProductFeatureContent(props) {
    const { featureDetail } = props;


    useEffect(()=> {
        
   

    },[]);


    //  dynamically manage different status
    const manageSalesStatus = (status) => {
        let defaultClass = 'status_color1 text-white';

        switch (status) {
            case 'Draft':
                defaultClass = 'text-light status_color2';
                break;
            case 'Active':
                defaultClass = 'text-light status_color3';
                break;
            case 'Pending for Approval':
                defaultClass = 'text-dark status_color4';
                break;
            case 'Fulfilled':
                defaultClass = 'text-light status_color5';
                break;
            case 'Rejected':
                defaultClass = 'text-light status_color6';
                break;
            case 'Confirmed':
                defaultClass = 'text-light status_color1';
                break;
            case 'Partially Billed':
                defaultClass = 'text-light status_color8';
                break;

            default:
                break;
        }

        return (
            <span className={`py-2 pl-4 pr-4 pt-2 pb-2 h5 text-sm rounded-full ${defaultClass} `}>
                {status}
            </span>
        );
    };


    // view subscription by going to subscription module




    return (
        <section className="   mt-2 w-full max-w-6xl text-filterTextColor">
           
            <Grid className=" bg-light pb-5  " container spacing={2}>
              
                <Grid className="border-2 mx-4  bg-light  my-2 " item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <span className="#476ef8 font-bold ">
                            <li class="list-group-item active font-bold text-center"> <h2>{featureDetail?.Feature_Name}</h2> </li>
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <div  className="row" >
                                <div className="col" >
                                <ul class="list-group">
                               
                                <li class="list-group-item"> Product : <span className="text-primary font-bold" > {featureDetail?.Product_Name} </span> </li>
                                <li class="list-group-item"> Description : <span className="text-primary" > {featureDetail?.Product_Description} </span> </li>
                                <li class="list-group-item"> Ref No : <span className="text-primary" > {featureDetail?.Product_Id} </span> </li>
                                <li class="list-group-item"> Action Url Web : <span className="text-primary" > {featureDetail?.Action_URL_Web} </span> </li>
                                <li class="list-group-item"> Action Url Mobile : <span className="text-primary" > {featureDetail?.Action_URL_Web} </span> </li>
                        
                                </ul>
                                </div>
                                <div className="col" >
                                <ul class="list-group">
                                <li class="list-group-item font-bold  text-center">  {manageSalesStatus(featureDetail?.Status_Name)} </li>
                             
                                <li className="list-group-item">
                                    Background Color: 
                                    <span className="p-1 m-1" style={{ backgroundColor: featureDetail?.Background_Color }}>
                                        ____________
                                    </span>
                                </li>
                                <li class="list-group-item"> Display Order : <span className="text-primary" >  {featureDetail?.Display_Order} </span> </li>
                                <li class="list-group-item"> Created Date : <span className="text-primary" >  { moment(featureDetail?.Encoded_Date).format('LLL')}  </span> </li>
                                <li class="list-group-item"> Created By : <span className="text-primary" > {featureDetail?.Encoded_By_Name} </span> </li>

                                {/* <li class="list-group-item"> Mobile Active : <span className="text-primary" > {featureDetail?.App_Visible_Id === 1 ? <span className="text-success font-bold" >YES</span> : <span className="text-danger font-bold" >NO</span> }  </span> </li>
                                <li class="list-group-item"> Web Active : <span className="text-primary" > {featureDetail?.Web_Visible_Id === 1 ?  <span className="text-success font-bold" >YES</span> : <span className="text-danger font-bold" >NO</span>}  </span> </li>
                         */}
                                </ul>
                                </div>
                           

                            </div>

                            <div className="text-center card mt-1" >
                                <div className="text-lead text-center font-bold" >Preview </div>

                                <div class="grid grid-cols-1 gap-4">
                                    <div className="ml-5 mb-2" >
                                        <a  target="blank" href={featureDetail?.Image_URL} className="ml-4" >
                                        <img src={featureDetail?.Image_URL} alt="product" width="500" srcset="" />
                                        </a>

                                    </div>
                                </div>

                            </div>
              
                  

                        </Grid>

                    </Grid>



                </Grid>




            </Grid>





        </section>
    );
}

export default ProductFeatureContent;
