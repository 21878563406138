import { Grid } from "@mui/material";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";



const SubscriptionCreateSubFromSalesOrder = (props) => {
    const {salesOrder} = props;
    console.log('data ', salesOrder)
    const [tableData, setTableData] = useState([])

    const calculateBaseTotal = (list) => {
        let result = 0;
        list?.forEach((element) => {
            result += element.Item_Sales_Quantity * element.Item_Sales_Rate;
        });


        const discountValue = salesOrder?.Discount_Amount;
        const discountType = salesOrder?.Discount_Type_Id;
        const ShippingAmount = salesOrder?.Shipping_Amount;
        const adjustmentAmount = salesOrder?.Adjustment_Amount;

        if(ShippingAmount){
            result += ShippingAmount;
        }
        if(adjustmentAmount){
            result += adjustmentAmount;
        }

        if (discountValue && (discountType === 1 || discountType === 0)) {
            result = result - discountValue;
        }

        if (discountValue && discountType === 2) {
            let discount = (discountValue / 100) * result;
            result = Number(result) - Number(discount);
        }
        return result?.toFixed(2);
    };


    return ( 
        <>
        <section className="mx-4 mt-5 border bg-light p-3">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <table className=" w-full ">
                <tr
                  style={{
                    backgroundColor: "#F1F1F1",
                    height: "50px",
                    paddinLeft: "10px",
                  }}
                >
                  <th className="text-sm pl-2">Plan & Addon Details</th>
                  <th className="text-sm pl-2">Qty</th>
                  <th className="text-sm pl-2">Rate</th>
                  <th className="text-sm pl-2">Tax</th>
                  <th className="text-sm pl-2">Amount</th>
                </tr>
             
                    <tr  className="p-1 cursor">
                      <td className="text-sm">
                        <span className="text-sm font-bold pl-2">
                           {salesOrder?.Sales_Order_No}
                        </span>
                        <div>
                          <span className="text-sm pl-2">
                          Custom Plan
                          </span>
                        </div>
                    
                      </td>
                      <td className="text-sm pl-2">
                        1
                      </td>
                      <td className="text-sm pl-2">
                      <CurrencyFormat  value={parseFloat(calculateBaseTotal(salesOrder?.Sales_Order_details))?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={' ₦'} />
                      </td>
                      <td className="text-sm pl-2"></td>
                      <td className="text-sm pl-2">
                        <CurrencyFormat  value={parseFloat(calculateBaseTotal(salesOrder?.Sales_Order_details))?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={' ₦'} />
                      </td>
                    </tr>
              
              </table>
            </Grid>
          </Grid>
        </section>
        </>
     );
}
 
export default SubscriptionCreateSubFromSalesOrder;