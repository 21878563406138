import { atom , useSetRecoilState, useRecoilValue, selector} from "recoil";



export const pageAtomList = atom({key:'product_action_url_list__atom',default:[]});
export const productUrlEditAtom = atom({key:'product_action_url_list__atom_edit',default:{}});

export  const SetProductUrlListAtom = () => useSetRecoilState(pageAtomList);
export  const GetProductUrlListAtom = () => useRecoilValue(pageAtomList);

export  const SetProductActionUrlEditAtom = () => useSetRecoilState(productUrlEditAtom);
export  const GetProductActionUrlEditAtom = () => useRecoilValue(productUrlEditAtom);
