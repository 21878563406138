import React, { useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import { MdArrowDropDown, MdDelete, MdEdit } from "react-icons/md";
import { ImAttachment } from "react-icons/im";
import "./CustomerDetails.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useState } from "react";
import { useEffect } from "react";
import { Container, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { editCustomerAtom } from "../../store/customerAtom";
import CustomerDetailsHistory from "./CustomerDetailsHistory";
import "./CustomerDetails.css";
import Row from "react-bootstrap/Row";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Col from "react-bootstrap/Col";
import CustomerDetailsStatement from "./CustomerDetailsStatement";
import CustomerTransaction from "./CustomerTransaction";
import CustomerNotification from "./CustomerNotification";
import { transactionService } from "../../services/transaction.service";
import { transactionmasterDataAtom } from "../../store/transAtom";
import CustomerDetailsSideScroller from "./CustomerDetailsSideScroller";
import { customerService } from "../../services/customer.service";
import CustomerToggle from "./CustomerToggle";
import LogicContext from "../../Context/LogicContext";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FaPaperclip, FaPencilAlt, FaTrash } from "react-icons/fa";
import { Grid } from "@mui/material";
import CustomerCreditScore from "./CustomerCreditScore";
import { Tabs } from "antd";
import { createModal } from "react-modal-promise";
import GeneralModal from "../../helpers/GeneralModal";
import CustomerOverviewNew from "./CustomerOverviewNew";
import CustomerDetailsGiftList from "./CustomerDetailsGiftList";

const { TabPane } = Tabs;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const TAB_SECTION = {
  OVERVIEW: "OVERVIEW",
  COMMENT: "COMMENT",
  TRANSACTIONS: "TRANSACTIONS",
  NOTIFICATIONS: "NOTIFICATIONS",
  STATEMENTS: "STATEMENTS",
  CREDIT_SCORE: "STATEMENTS",
};

const CustomerDetails = () => {
  const [selectedTab, setSelectedTab] = useState(TAB_SECTION.OVERVIEW);
  const [customerEdit, setEditCustomer] = useRecoilState(editCustomerAtom);
  const setTransMasterData = useSetRecoilState(transactionmasterDataAtom);
  const [selectedList, setSelectedList] = useState([]);
  const [details, setDetails] = useState({});
  const [subscriptionList, setSubscriptionList] = useState([]);
  const logicContext = useContext(LogicContext);
  const myPromiseModal = createModal(GeneralModal);

  const { customer_id } = useParams();

  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    loadUserData();

    return () => {
      isMounted = false;
    };
  }, [customer_id]);

  const loadUserData = () => {
    customerService.getSingleCustomer(customer_id).then((res) => {
      if (res.data !== undefined) {
        if (res?.data?.customer?.length) {
          setDetails(res?.data?.customer[0]);
        }
        if (res?.data?.subscription_info?.length) {
          setSubscriptionList(res?.data?.subscription_info);
        }
      }
    });

    transactionService.getMasterData().then((res) => {
      setTransMasterData(res.data);
    });
  };

  function viewCustomer(id) {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/customer-details/${id}`
    );
  }

  const editCurrentCustomer = async () => {
    await myPromiseModal({
      title: "Confirmation",
      body: " Do you want to modify customer record?",
      showInput: false,
      placeHolder: "",
      showInput: false,
      placeHolder: "",
    })
      .then((res) => {
        if (res.decision === true) {
          setEditCustomer(details);
          navigate(
            `/${currModuleId}/${currModuleName}/${currUserMenu}/add_customer`
          );
        }
      })
      .catch((err) => {
        logicContext.showToast("error submitting record", "error");
      });
  };

  const newSubscription = () => {
    navigate(
      `/${currModuleId}/${"Subscription"}/${"subscriptions"}/add-new/${customer_id}/${
        details?.Customer_Full_Name
      }`
    );
  };

  const toogleItemStatus = (val) => {
    if (val === true) {
      // setSelectedProduct({ ...selectedProduct,Product_Status_Id: 1,Product_Status_Name: "Active" });

      customerService
        .changeCustomerStatus({ customer_id: customer_id, option: 7 })
        .then((res) => {
          console.log("body ", res.data);
          logicContext.showToast(res.data[0]["message_text"], "success");
          loadUserData();
        });
    }

    if (val === false) {
      customerService
        .changeCustomerStatus({ customer_id: customer_id, option: 8 })
        .then((res) => {
          console.log("body2 ", res.data);
          logicContext.showToast(res.data[0]["message_text"], "");
          loadUserData();
        });
    }
  };

  function onSelection(list) {
    let tempList = list?.filter((item) => item.checked === true);
    setSelectedList(tempList);
  }

  function callback(key) {
    console.log(key);
  }

  console.log('DETAILS ', details);

  return (
    <section className="mt-2 @container">
      <div className=" w-full flex flex-col @lg:flex-row @lg:items-center @lg:justify-between space-y-3 @lg:space-y-0">
        <div className="flex flex-row items-center">
          <div className="pl-3 pr-2 border-[1px] border-gray-200 rounded-full">
            <CustomerToggle
              toogleItemStatus={toogleItemStatus}
              val={details?.Customer_Status}
              className=""
            />
          </div>
        </div>

        <div className="flex flex-row items-center space-x-1">
          <Menu as="div" className="relative z-30">
            <Menu.Button className="w-full px-2 rounded-md flex flex-row items-center text-sm font-normal text-gray-700 bg-white border-[1px] border-gray-200 focus:outline-none">
              <p className="mt-2.5">New Transaction</p>
              <MdArrowDropDown className=" h-6 w-6 text-gray-500 " />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black/5 focus:outline-none">
                <div className="px-1 py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={newSubscription}
                        className={`${
                          active ? "bg-gray-100 text-gray-600" : "text-red-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-xs`}
                      >
                        SUBSCRIPTION
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-gray-100 text-gray-600" : "text-red-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-xs`}
                      >
                        CUSTOMER PAYMENT
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-gray-100 text-gray-600" : "text-red-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-xs`}
                      >
                        CREDIT NO
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
            <MdEdit
              onClick={() => editCurrentCustomer(/*  */)}
              className="h-[20px] w-[20px] flex-shrink-0 text-gray-600"
            />
          </div>

          <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
            <MdDelete className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
          </div>

          <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
            <ImAttachment className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
          </div>

          <div>
            <Link
              to={`/${currModuleId}/${currModuleName}/${currUserMenu}/add_customer`}
            >
              <button className=" bg-[#476EF8] px-2.5 py-2 text-white font-medium text-xs rounded hover:bg-blue-500">
                ADD
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-4 lg:flex space-x-1">
        <div className="lg:w-[240px]">
          <CustomerDetailsSideScroller
            onSelection={onSelection}
            viewCustomer={viewCustomer}
          ></CustomerDetailsSideScroller>
        </div>
        <div className="w-full overflow-x-hidden mt-4 lg:mt-0 lg:pl-4">
          {/* overview and comment */}
          <section className="border-b-2 text-gray-700">
            <Tabs defaultActiveKey="1" onChange={callback} className="">
              <TabPane className="" tab="Overview" key="1">
             
                <CustomerOverviewNew
                subscriptionList={subscriptionList}
                // viewCustomer={viewCustomer}
                customerData={details}
                />
              </TabPane>

              <TabPane tab="History" key="2">
                <CustomerDetailsHistory customer_id={customer_id} />
              </TabPane>

              <TabPane tab="Gift " key="3">
                <CustomerDetailsGiftList customer_id={customer_id} />
              </TabPane>

              <TabPane tab="Transactions" key="4">
                <CustomerTransaction customer_id={customer_id} />
              </TabPane>

              <TabPane tab="Credit Score" key="5">
                <CustomerCreditScore customer_id={customer_id} Zoho_Customer_Id={details?.Zoho_Customer_Id} />
              </TabPane>

              <TabPane tab="Notifications" key="6">
                <CustomerNotification customer_id={customer_id} />
              </TabPane>
              <TabPane tab="Statement" key="7">
                <CustomerDetailsStatement customer_id={customer_id} />
              </TabPane>
            </Tabs>
          </section>
        </div>
      </div>
    </section>
  );
};

export default CustomerDetails;
