import { Grid } from "@mui/material";
import React from "react";

import SalesOrderDetailsTable from "./SalesOrderDetailsTable";
import CurrencyFormat from "react-currency-format";
import { useNavigate } from "react-router-dom";

function SalesOrderOverview(props) {
  const { salesData } = props;
  const navigate = useNavigate();

  console.log(salesData);

  //  calculate  sub total
  const calculateSubTotal = (list) => {
    let resuilt = 0;

    list?.forEach((element) => {
      resuilt += element.Item_Sales_Quantity * element.Item_Sales_Rate;
    });
    return resuilt?.toFixed(2);
  };

  //  calculate base quantity with discount, adjustment and shipping
  const calculateBaseTotal = (list) => {
    let result = 0;
    list?.forEach((element) => {
      result += element.Item_Sales_Quantity * element.Item_Sales_Rate;
    });

    const discountValue = salesData?.Discount_Amount;
    const discountType = salesData?.Discount_Type_Id;
    const ShippingAmount = salesData?.Shipping_Amount;
    const adjustmentAmount = salesData?.Adjustment_Amount;

    if (ShippingAmount) {
      result += ShippingAmount;
    }
    if (adjustmentAmount) {
      result += adjustmentAmount;
    }

    if (discountValue && (discountType === 1 || discountType === 0)) {
      result = result - discountValue;
    }

    if (discountValue && discountType === 2) {
      let discount = (discountValue / 100) * result;
      result = Number(result) - Number(discount);
    }
    return result?.toFixed(2);
  };

  //  dynamically manage different status
  const manageSalesStatus = (status) => {
    let defaultClass = "status_color1 text-white";

    switch (status) {
      case "Draft":
        defaultClass = "text-light status_color2";
        break;
      case "Approved":
        defaultClass = "text-light status_color3";
        break;
      case "Pending for Approval":
        defaultClass = "text-dark status_color4";
        break;
      case "Fulfilled":
        defaultClass = "text-light status_color5";
        break;
      case "Rejected":
        defaultClass = "text-light status_color6";
        break;
      case "Confirmed":
        defaultClass = "text-light status_color1";
        break;
      case "Partially Billed":
        defaultClass = "text-light status_color8";
        break;

      default:
        break;
    }

    return (
      <span className={`px-4 py-2.5 rounded font-semibold ${defaultClass}`}>
        {status}
      </span>
    );
  };

  // view subscription by going to subscription module
  function viewSubscription(subId) {
    navigate(`/${2}/${"Subscription"}/${"subscriptions"}/details/${subId}`);
  }

  return (
    <section className="mt-2 w-full text-gray-700">
      <div className="border-[1px] border-gray-200 rounded-lg p-3 lg:p-4 bg-white ">
        <div className="flex flex-row items-center space-x-2 text-base">
          <div className=" font-bold text-gray-700">SALES ORDER NUMBER:</div>
          <div className=" font-bold text-[#AAA96A] italic">
            {salesData?.Sales_Order_No}
          </div>
        </div>
        <div className="mt-1 flex flex-row items-center space-x-2 text-base">
          <div className=" font-bold text-gray-700">Approvals:</div>
          <div className=" font-bold text-[#AAA96A] italic">
            View Approval List
          </div>
        </div>

        <div className="flex justify-end ">
          <div className="py-2.5 rounded px-4 text-sm">
            {manageSalesStatus(salesData?.Sales_Order_Status_Name)}
          </div>
        </div>
        <div>
          <div className="font-bold text-lg text-gray-600 ">Status</div>
          <div className="flex flex-row items-center">
            <div className="w-[120px]">Order:</div>
            <div className="text-[#4999F6]">
              {salesData?.Sales_Order_Status_Name}
            </div>
          </div>
          <div className="flex flex-row items-center">
            <div className="w-[120px]">Invoice:</div>
            <div className="text-[#B5B1CA]">
              {salesData?.Invoice_details?.length}
            </div>
          </div>
          <div className="flex flex-row items-center">
            <div className="w-[120px]">Payment:</div>
            <div className="text-[#B5B1CA]">{salesData?.Payment_Term_Name}</div>
          </div>

          <div className="flex flex-row items-center">
            <div className="w-[120px]">Shipment:</div>
            <div className="">Shipped</div>
          </div>
        </div>
      </div>

      <div className="mt-4 border-[1px] border-gray-200 rounded-lg p-3 lg:p-4 bg-white ">
        <div className="font-bold text-base text-gray-600">SALES ORDER</div>
        <div>
          <div className="flex flex-row items-center">
            <div className="w-[140px]">Reference Number:</div>
            <div className="text-[#4999F6]">{salesData?.Sales_Order_No} </div>
          </div>
          <div className="flex flex-row items-center">
            <div className="w-[140px]">Order Date:</div>
            <div className="text-[#B5B1CA]">
              {salesData?.Sales_Order_Date_String}
            </div>
          </div>
          <div className="flex flex-row items-center">
            <div className="w-[140px]">Payment Terms:</div>
            <div className="text-[#B5B1CA]">{salesData?.Payment_Term_Name}</div>
          </div>
          <div className="flex flex-row items-center">
            <div className="w-[140px]">Pickup Date:</div>
            <div className="text-[#B5B1CA]">
              {salesData?.Pickup_Date_String}
            </div>
          </div>
          <div className="flex flex-row items-center">
            <div className="w-[140px]">Subscription ID:</div>
            <div
              onClick={() => viewSubscription(salesData?.Subscription_Ref_No)}
              className="text-[#4999F6] font-medium hover:underline hover:underline-offset-2 cursor-pointer"
            >
              {salesData?.Subscription_Ref_No}
            </div>
          </div>
          <div className="flex flex-row items-center">
            <div className="w-[140px]">
              Subscription <br></br>
              Invoice Number:
            </div>
            <div className="text-[#B5B1CA]"></div>
          </div>
        </div>
        <div className="flex items-center justify-end">
          <div>
            <div className="flex   justify-between">
              <div>
                <span className="text-gray-600 text-base font-bold">
                  Billing Address
                </span>{" "}
                <br></br>
                <span className="subheading">
                  {salesData?.Billing_Area_Name}
                </span>
                <span className="subheading">
                  {" "}
                  {salesData?.Billing_City_Name}
                </span>
                <span className="subheading">
                  {" "}
                  {salesData?.Billing_City_Name}
                </span>
                <span className="subheading">
                  {" "}
                  {salesData?.Billing_Country_Name}
                </span>
              </div>
            </div>

            <div className="flex mt-2  justify-between">
              <div>
                <span className="text-gray-600 text-base font-bold">
                  Shipping Address
                </span>{" "}
                <br></br>
                <span className="subheading">
                  {salesData?.Shipping_City_Name}
                </span>
                <span className="subheading">
                  {" "}
                  {salesData?.Shipping_Area_Name}
                </span>
                <span className="subheading">
                  {" "}
                  {salesData?.Shipping_Country_Name}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 border-[1px] border-gray-200 rounded-lg py-3 lg:py-4 bg-white">
        <h1 className="text-center text-gray-700 text-xl font-bold">
          Sales Details List{" "}
        </h1>
        <div className=" overflow-y-scroll h-[350px] scrollbar-thumb-gray-500 scrollbar-thin scrollbar-track-gray-100 pb-[10px]">
          <SalesOrderDetailsTable
            salesData={salesData}
            tableData={salesData?.Sales_Order_details}
          ></SalesOrderDetailsTable>
        </div>
        <div className=" px-3 lg:px-4">
          <div className="mt-3  px-3 lg:px-4 border-[1px] border-gray-200 rounded-lg p-3 flex items-center justify-end">
            <div className="">
              <div className="flex flex-row">
                <div className="text-sm text-gray-600 font-bold mr-2">
                  Shipping Fee
                </div>
                <div className="w-[170px] flex-shrink-0">
                  <div className="text-sm text-gray-600">
                    <CurrencyFormat
                      value={salesData?.Shipping_Amount?.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="text-sm font-bold mr-2">Discount:</div>{" "}
                <div className="w-[170px] flex-shrink-0">
                  <div className="text-sm">
                    {salesData?.Discount_Type_Id === 1 && (
                      <CurrencyFormat
                        value={salesData?.Discount_Amount?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    )}

                    {salesData?.Discount_Type_Id === 2 && (
                      <CurrencyFormat
                        value={salesData?.Discount_Amount?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"%"}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="text-sm font-bold mr-2">Adjustment:</div>
                <div className="w-[170px] flex-shrink-0">
                  <div className="text-sm">
                    <CurrencyFormat
                      value={parseFloat(salesData?.Adjustment_Amount)?.toFixed(
                        2
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="text-gray-600 font-bold mr-2">Sub Total:</div>{" "}
                <div className="w-[170px] flex-shrink-0">
                  <div className="text-gray-600 font-bold">
                    <CurrencyFormat
                      value={parseFloat(
                        calculateSubTotal(salesData?.Sales_Order_details)
                      )?.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row ">
                <div className="text-2xl font-bold mr-2">Total:</div>{" "}
                <div className="w-[170px] flex-shrink-0">
                  <div className="text-2xl  font-bold">
                    <CurrencyFormat
                      value={parseFloat(
                        calculateBaseTotal(salesData?.Sales_Order_details)
                      )?.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <PurchaseReceiveTable tableData={purchaseData?.Purchase_Receive_details} /> */}
      </div>
    </section>
  );
}

export default SalesOrderOverview;
