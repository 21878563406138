import { DataGrid ,  gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport ,
    useGridApiContext,
    useGridSelector} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';
  


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
      <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          page={page + 1}
          count={pageCount}
          // @ts-expect-error
          rowsPerPageOptions={[6]}
          checkboxSelection={true}
          components={{
              Toolbar: CustomToolbar,
              Pagination: CustomPagination,
            }}
          renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
  );
}
  


  const TableHead = [
    { field: 'Customer_Id', headerName:'ID' , width: 100 , headerClassName: "tableHeader "},
    { field: 'Customer_Full_Name', headerName:'Name' , width: 200 , headerClassName: "tableHeader "},
    { field: 'Customer_Email_Address', headerName:'Email ' , width: 200 , headerClassName: "tableHeader "},
     { field: 'Customer_Mobile_No', headerName:'Mobile' , width: 150 , headerClassName: "tableHeader "},
     
    ]
 

  

  
  function AddNotificationTable(props) {
  
    return ( 
        <>
  <div className="table pr-5" style={{ height: 500, width: '100%' }}>
      <DataGrid 
       rows={props?.list?.map((customer, index)=> { return {...customer, id:customer?.Customer_Id} } )}
        columns={TableHead}
        pageSizeOptions={[5]}
        checkboxSelection={true}
        // onRowSelectionModelChange={(ls)=> props?.setUserList(ls)}
        onRowSelectionModelChange={(ls)=> props?.setUserList(ls)}
        components={{
          Toolbar: CustomToolbar, Pagination: CustomPagination}}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          onCellClick={( event) => {
            event.defaultMuiPrevented = true;
            
          }}
          
       
          sx={{
            // boxShadow: 2,
            // border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
              cursor: 'pointer',
            },
          }}
      />
  
    </div>
        
        </>
     );
  }
  
  export default AddNotificationTable