

/* eslint-disable jsx-a11y/anchor-is-valid */

import { itemsService } from "../../../services/items.service";
import { useEffect, useState, useContext } from "react";
import underscore from "underscore";
import { useForm } from "react-hook-form";
import "../../../pages/ItemInformation.css";
import TypeSelect from "./TypeSelect";
import ItemReturnable from "./ItemReturnable";
import DimentionSelect from "./DimensionSelect";
import AddItemTrackingTypeCheckRadioButton from "./AddItemTrackingTypeCheckRadioButton";
import {
  GetItemToEdit,
  SetItemMasterData,
  GetItemMasterData,
  SetItemToEdit,
  ResetItemToEdit,
  SettItemBackupList,
} from "../../../store/itemAtom";
import { useNavigate, useParams } from "react-router-dom";
import GeneralDropDown from "../../../helpers/GeneralDropDown";
import NotificationContext from "../../../Context/NotificationContext";

import GeneralModal from "../../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import LogicContext from "../../../Context/LogicContext";
import { Link, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";







function AddItemForm(props) {
    const navigate = useNavigate();
    const logicContext = useContext(LogicContext);
    const myPromiseModal = createModal(GeneralModal);
    const [tempForm, setTempForm] = useState({});
    const setItemToEdit = SetItemToEdit();
    const itemToEdit = GetItemToEdit();
  
    const setMasterData = SetItemMasterData();
    const getMasterData = GetItemMasterData();
    const notificationContext = useContext(NotificationContext);
    const [defaultCurrency, setDefaultCurrency] = useState([]);
    const [productUnit, setProductUnit] = useState([]);
    const [productBrand, setProductBrand] = useState([]);
    const [productCategory, setProductCategory] = useState([]);
    const [productManufacturer, setProductManufacturer] = useState([]);
    const [productDimension, setProductDimension] = useState([]);
    const [productWeight, setProductWeight] = useState([]);
    const [salesAccounts, setSalesAccounts] = useState([]);
    const [purchaseAccounts, setPurchaseAccounts] = useState([]);
    const [inventoryAccounts, setInventoryAccounts] = useState([]);
    const [taxList, setTaxList] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [itemtypeList, setItemtypeList] = useState([]);
    const [purchaseCheck, setPurchaseCheck] = useState(false);
    const [salesCheck, setSalesCheck] = useState(false);
    const [trackCheck, setTrackingCheck] = useState(false);
  
    const { pathname } = useLocation();
    var url = pathname.split("/");
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];
  
    const { option } = useParams();
  
    useEffect(() => {
      notificationContext.getMasterData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getMasterData?.length]);
  
    const { register, setValue, getValues, handleSubmit, reset, formState: { errors } } = useForm();
    setValue("Item_Type_Id", 1);
    console.log("Edit ", itemToEdit);
  
    useEffect(() => {
      if (itemToEdit.Sales_Accounts_Id) {
        setSalesCheck(true);
      }
      if (itemToEdit.Purchase_Accounts_Id) {
        setPurchaseCheck(true);
      }
      if (itemToEdit.Inventory_Accounts_Name) {
        setTrackingCheck(true);
      }
      if (itemToEdit?.Item_Name) {
        //  const cleanItemToEdit =  deleteByVal(null, itemToEdit);
        const newData = {
          ...itemToEdit,
          item_weight_id: 1,
          item_dimension_unit_id: 1,
        };
        
        reset({ ...newData });
      }
  
      return () => {
        // resetItemToEdit();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemToEdit?.Item_Id, itemToEdit]);
  
    useEffect(() => {
      var masterDataGroup = underscore.groupBy(getMasterData, "Master_Type");
      setDefaultCurrency(masterDataGroup[1]);
      setProductUnit(masterDataGroup[2]);
      setProductCategory(masterDataGroup[3]);
      setProductDimension(masterDataGroup[4]);
      setProductWeight(masterDataGroup[5]);
      setProductManufacturer(masterDataGroup[6]);
      setProductBrand(masterDataGroup[7]);
      setSalesAccounts(masterDataGroup[8]);
      setPurchaseAccounts(masterDataGroup[9]);
      setTaxList(masterDataGroup[10]);
      setInventoryAccounts(masterDataGroup[11]);
      setVendors(masterDataGroup[12]);
      setItemtypeList(masterDataGroup[13]);
    }, [getMasterData]);
  
  
    function selectedBrand(selection) {
      setValue("Item_Brand_Id", selection.Code);
    }
  
    function selectedCategory(selection) {
      setValue("Item_Category_Id", selection.Code);
    }
  
    function selectedItemType(selection) {
      setValue("Item_Variety_Id", selection.Code);
      props.controlAssociate(selection.Code);
    }
  
    function selectedUnitType(selection) {
      setValue("Item_Unit_Id", selection.Code);
    }
  
    function selectedManufacturer(selection) {
      console.log('selection ', selection)
      setValue("Manufacturer_Id", selection.Code);
    }
  
    function selectedDimensionWidth(selection) {
      setValue("item_dimension_unit_id", selection);
    }
  
    function selectedProductWeight(selection) {
      setValue("item_weight_id", selection);
    }
  
    function selectedSalesAccount(selection) {
      console.log('SEL_VIEW ', selection);
      setValue("Sales_Accounts_Id", selection.Code);
    }
  
    function selectedPurchaseAccount(selection) {
      setValue("Purchase_Accounts_Id", selection.Code);
    }
  
    function selectedInventoryAccount(selection) {
      setValue("Inventory_Accounts_Id", selection.Code);
    }
  
    function salesTaxList(selection) {
      setValue("Sales_Tax_Id", selection.Code);
    }
  
    function selectPurchaseTax(selection) {
      setValue("Purchase_Tax_Id", selection.Code);
    }
  
    function selectDefaultCurrency(selection) {
      setValue("Currency_Id", selection.Code);
    }
  
    function selectedVendor(selection) {
      setValue("Preferred_Vendor_Id", selection.Code);
    }
  
    function addItemType(type) {
      setValue("Item_Type_Id", type);
    }
  
    function addItemReturnable(type) {
      setValue("Item_Returnable_Id", type);
    }
  
    function addTrackingForItem(type) {
      setValue("Item_Tracking_Need_Id", type);
    }
  
    function addItemTrackingType(type) {
      setValue("item_tracking_type_id", type);
    }
  
    const onSubmit = async () => {
      await myPromiseModal({
        title: "Confirmation",
        body: ` Do you want to ${itemToEdit?.Item_Id ? 'update':' save'} this record?`,showInput:false, placeHolder:''
      }).then((response) => {
        if (response.decision === true) {
          let formValues = getValues();
          submitItemForm({ ...formValues, option_allow: 0 });
        }
      });
    };
  
    async function submitItemForm(form) {
      console.log("Form ", form);
      setTempForm(form);
      if (itemToEdit?.Item_Id) {
        // Update existing item.
        itemsService.updateItem({ ...form, option_allow: 1 }).then((res) => {
          console.log('UPDATE_ressss ', res.data);
                    let message_id = undefined;
                    let message_text = undefined;
                    let message_ref_no = undefined; 
                  if(res.data?.length){
                  message_id = res.data[0]?.message_id;
                  message_text = res.data[0]?.message_text;
                  message_ref_no = res.data[0]?.message_ref_no;

                  }
                  if(res.data?.result_status?.length){
                  message_id = res.data?.result_status[0]?.message_id;
                  message_text = res.data?.result_status[0]?.message_text;
                  message_ref_no = res.data?.result_status[0]?.message_ref_no;

                  }
  
          if (message_ref_no === form.Item_Name) {
            return logicContext.showToast(message_text, "error");
          } else if (message_ref_no === form.Item_Sku) {
            return logicContext.showToast(message_text, "error");
          }

          if (message_ref_no === form.Item_Name) {
            return logicContext.showToast(message_text, "error");
           } else if (message_ref_no === form.Item_Sku) {
             return logicContext.showToast(message_text, "error");
           } else {
            if(res.data?.item_info?.length){
              setItemToEdit(res.data?.item_info[0]);
            }
             // reset({});
             associateItemsPrompt(res.data?.item_info[0]?.Item_Id);
             logicContext.showToast( `${message_text}`, "success");
           } // End of update
  
          
        });
      } else {
        //  save new item
        itemsService.saveNewItem(form).then((res) => {
          console.log('RESPONSE ', res.data);
            let message_id = undefined;
             let message_text = undefined;
              let message_ref_no = undefined; 
            if(res.data?.length){
            message_id = res.data[0]?.message_id;
            message_text = res.data[0]?.message_text;
            message_ref_no = res.data[0]?.message_ref_no;

            }
            if(res.data?.result_status?.length){
            message_id = res.data?.result_status[0]?.message_id;
            message_text = res.data?.result_status[0]?.message_text;
            message_ref_no = res.data?.result_status[0]?.message_ref_no;

            }
            if (message_ref_no === form.Item_Name) {
             return logicContext.showToast(message_text, "error");
            } else if (message_ref_no === form.Item_Sku) {
              return logicContext.showToast(message_text, "error");
            } else {
              console.log('SET_EDITABLE ', res.data?.item_info[0]);
              if(res.data?.item_info?.length){
                setItemToEdit(res.data?.item_info[0]);
              }
              // reset({});
              associateItemsPrompt(res.data?.item_info[0]?.Item_Id);
              logicContext.showToast( `${message_text}`, "success");
            }
          })
          .catch((err) => {
            console.log('ITEM_ERROR ', err);
            logicContext.showToast("error saving record!", "erorr");
          });
      }
    }
  



    async function associateItemsPrompt(item_id) {
      if (option == 1) {
        await myPromiseModal({
          title: "Add Associated Items",
          body: " Do you want to add associated items?",showInput:false, placeHolder:''
        }).then((result) => {
          if (result.decision === true) {
            props.changeTab('2');
          }
        });
      }
      if (option == 2) {
        await myPromiseModal({
          title: "New item has been saved!",
          body: " Do you want to back production?",showInput:false, placeHolder:''
        }).then((result) => {
          if (result.decision === true) {
            return navigate(
              `/${currModuleId}/${currModuleName}/production-setup/production-setup-details/${item_id}`
            );
          }
        });
      }
  
      if (option == 3) {
        await myPromiseModal({
          title: "New item has been saved!",
          body: " Do you want to go back production?",showInput:false, placeHolder:''
        }).then((result) => {
          if (result.decision === true) {
            return navigate(
              `/${currModuleId}/${currModuleName}/production-setup/production-setup-details/${item_id}`
            );
          }
        });
      }
  
      if (option == 4) {
        await myPromiseModal({
          title: "New item has been saved!",
          body: " Do you want to go back to Composite Group?",showInput:false, placeHolder:''
        }).then((result) => {
          if (result.decision === true) {
            return navigate(
              `/${currModuleId}/${currModuleName}/composite-item-groups/add-new`
            );
          }
        });
      }
    }
  
    function onErrors(error) {
      logicContext.showToast("One or more value is required!",   "error" );
      console.error(errors);
    }


  
    function manageCategoryList() {
      notificationContext.showModal({
        sql_ref_no_send: 27,
        newOption: 1,
        updateOption: 2,
        sql_ref_no_get: 28,
        option_get: 1,
      });
    }
  
    function manageUnitList() {
      notificationContext.showModal({
        sql_ref_no_send: 27,
        newOption: 5,
        updateOption: 6,
        sql_ref_no_get: 28,
        option_get: 3,
      });
    }
  
    function manageBrandList() {
      notificationContext.showModal({
        sql_ref_no_send: 27,
        newOption: 3,
        updateOption: 4,
        sql_ref_no_get: 28,
        option_get: 2,
      });
    }
  
    function manageManufacturer() {
      notificationContext.showModal({
        sql_ref_no_send: 27,
        newOption: 7,
        updateOption: 8,
        sql_ref_no_get: 28,
        option_get: 4,
      });
    }
  
    function manageTaxList() {
      notificationContext.showModal({
        sql_ref_no_send: 27,
        newOption: 13,
        updateOption: 14,
        sql_ref_no_get: 28,
        option_get: 7,
      });
    }
  
    function manageVendorList() {
      notificationContext.showModal({
        sql_ref_no_send: 27,
        newOption: 15,
        updateOption: 16,
        sql_ref_no_get: 28,
        option_get: 8,
      });
    }
  
    return (
      <>
        <form className="" onSubmit={handleSubmit(onSubmit, onErrors)}>
          <section className="">
            <section className="absolute top-[15vh]">
              <section className="flex justify-end w-[76vw]">
              
              </section>
            </section>
  
            {/* TYPE SECTION */}
            <section className="">
              <Grid container className="" spacing={2}>
                <Grid item xs={4}>
                  <TypeSelect
                    val={itemToEdit?.Item_Type_Id}
                    addItemType={addItemType}
                  ></TypeSelect>
                </Grid>
                <Grid item xs={4}>
                  {/* returnable */}
                  <ItemReturnable
                    val={itemToEdit?.Item_Returnable_Id}
                    addItemReturnable={addItemReturnable}
                  />
                </Grid>
                <Grid item xs={4}>
                  <div className="mt-2">
                    <label className="">
                      <span className="text-xs text-danger">
                        Item Variety Type
                      </span>{" "}
                      <br></br>
                      <div className="dropdown item_a  bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer  border-0">
                    
                        <GeneralDropDown
                        defaultVal={itemToEdit?.Item_Variety_Name}
                          dropDown={itemtypeList}
                          optionSelect={selectedItemType}
                        />
                      </div>
                    </label>
                  </div>
                </Grid>
              </Grid>
              {/* here */}
            </section>
            {/* ITEM INFORMATION SECTION  */}
            <section className="mt-3  info_sec ">
              <Grid container className="info_sec " spacing={2}>
                <Grid item xs={4}>
                  <section>
                    <div>
                      <span style={{ color: "#F17E7E" }} className="text-xs">
                        Name
                      </span>{" "}
                      <br></br>
                      <input
                        className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type="text"
                        placeholder="Item name"
                        {...register("Item_Name", {
                          required: "Item name is required",
                        })}
                      />
                    </div>
                    <small className="text-danger">
                      {errors?.Item_Name && errors.Item_Name.message}
                    </small>
  
                    <div className="mt-2">
                      <label className="">
                        <span className="text-xs">Category</span> <br></br>
                        <div className="dropdown bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer  border-0">
                          <GeneralDropDown
                            manage={true}
                        defaultVal={itemToEdit?.Item_Category_Name}
                            manageList={manageCategoryList}
                            dropDown={productCategory}
                            optionSelect={selectedCategory}
                          />
                        </div>
                      </label>
                    </div>
  
                    <div className="">
                      <label className=" ">
                        <span className="text-xs">
                          Dimension <i>(Length X Width X Height)</i>
                        </span>{" "}
                        <br></br>
                        <div className="dropdown  bg-filterInput h-[6.5vh] select_dimension pt-[1.9vh]  border-0">
                          <span className=" dimension">
                            <span className="text-xs  ">
                              <input
                                type="number"
                                {...register("Item_Dimension_Width")}
                              />
                            </span>
                            <span className="text-xs  ">
                              X{" "}
                              <input
                                type="number"
                                {...register("Item_Dimension_Width")}
                              />
                            </span>
                            <span className="text-xs ">
                              X{" "}
                              <input
                                type="number"
                                {...register("Item_Dimension_Height")}
                              />
                            </span>
                            <span className="dimenSelect">
                              <DimentionSelect
                                selectedDimension={selectedDimensionWidth}
                                productDimension={productDimension}
                              />
                            </span>
                          </span>
                        </div>
                      </label>
                    </div>
  
                    <div className="">
                      <span className="text-xs">UPC</span> <br></br>
                      <input
                        {...register("Item_UPC")}
                        className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type="text"
                        placeholder=""
                      />
                    </div>
                  </section>
                </Grid>
                <Grid item xs={4}>
                  {/* take of this space to control the space  */}
                  <section className=" ">
                    <section>
                      <div>
                        <span style={{ color: "#F17E7E" }} className="text-xs">
                          Sku
                        </span>{" "}
                        <br></br>
                        <input
                          className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="text"
                          placeholder="Sku"
                          {...register("Item_Sku", {
                            required: "Sku is required",
                          })}
                        />
                      </div>
                      <small className="text-danger">
                        {errors?.Item_Sku && errors.Item_Sku.message}
                      </small>
  
                      <div className="mt-2">
                        <label className="">
                          <span className="text-xs">Unit</span> <br></br>
                          <div className="dropdown bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer border-0">
                           
                            <GeneralDropDown
                              manage={true}
                        defaultVal={itemToEdit?.Item_Unit_Name}
                              manageList={manageUnitList}
                              dropDown={productUnit}
                              optionSelect={selectedUnitType}
                            />
                          </div>
                        </label>
                      </div>
                      <div className="">
                        <label className="relative">
                          <span style={{ color: "#F17E7E" }} className="text-xs">
                            Weight
                          </span>{" "}
                          <br></br>
                          <span className="  dimension_input">
                            <input
                              {...register("Item_Weight")}
                              className="bg-filterInput placeHolder_input z-50 bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                              type="number"
                              placeholder=""
                            />
                            <span className="dimenSelect_input">
                              <DimentionSelect
                                selectedDimension={selectedProductWeight}
                                productDimension={productWeight}
                              />
                            </span>
                          </span>
                        </label>
                      </div>
                      <div className="">
                        <span className="text-xs">EAN</span> <br></br>
                        <input
                          {...register("Item_EAN")}
                          className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </section>
                  </section>
                </Grid>
                <Grid item xs={4}>
                  <section className=" ">
                    <section>
                      <div className="">
                        <label className="">
                          <span className="text-xs">Brand</span> <br></br>
                          <div className="dropdown bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer border-0">
                            <GeneralDropDown
                              manage={true}
                        defaultVal={itemToEdit?.Item_Brand_Name}
                              manageList={manageBrandList}
                              dropDown={productBrand}
                              optionSelect={selectedBrand}
                            />
                          </div>
                        </label>
                      </div>
                      <div className="">
                        <label className="">
                          <span className="text-xs">Manufacturer</span> <br></br>
                          <div className="dropdown bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer  border-0">
                            <GeneralDropDown
                              manage={true}
                        defaultVal={itemToEdit?.Manufacturer_Name}
                              manageList={manageManufacturer}
                              dropDown={productManufacturer}
                              optionSelect={selectedManufacturer}
                            />
                          </div>
                        </label>
                      </div>
  
                      <div className="">
                        <span className="text-xs">ISBN</span> <br></br>
                        <input
                          {...register("Item_ISBN")}
                          className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="text"
                          placeholder=""
                        />
                      </div>
                      <div className="mt-2">
                        <span className="text-xs">MPN</span> <br></br>
                        <input
                          {...register("Item_MPN")}
                          className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </section>
                  </section>
                </Grid>
              </Grid>
            </section>
  
            {/* 
    OTHER INFORMATION */}
            <section className="mt-3 info_sec flex ">
              <Grid container className="" spacing={2}>
                <Grid item xs={4}>
                  <section className="other_information ">
                    <div className=" font-bold  mt-4 ">
                      <input
                        onChange={(e) => setSalesCheck(e.target.checked)}
                        type="checkbox"
                        className="w-[0.8vw] h-[1.6vh]"
                      />
                      <span className="text-xs mt-4 ml-2">Sales Information</span>
                    </div>
  
                    {salesCheck && (
                      <span>
                        <div className="mt-2">
                          <label className="relative">
                            <span
                              style={{ color: "#F17E7E" }}
                              className="text-xs"
                            >
                              Selling Price
                            </span>{" "}
                            <br></br>
                            <span className="other_dimension_input">
                              <input
                                {...register("Sales_Rate")}
                                className="bg-filterInput  placeHolder_input z-50 bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                type="text"
                                placeholder=""
                              />
                              <span className="dimenSelect_input ">
                                <DimentionSelect
                                  selectedDimension={selectDefaultCurrency}
                                  productDimension={defaultCurrency}
                                />
                              </span>
                            </span>
                          </label>
                        </div>
                        <div className="mt-3">
                          <label className="">
                            <span
                              style={{ color: "#F17E7E" }}
                              className="text-xs"
                            >
                              Sales Account
                            </span>{" "}
                            <br></br>
                            <div className="dropdown bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer  border-0">
                              <GeneralDropDown
                                manage={false}
                                dropDown={salesAccounts}
                                defaultVal={itemToEdit?.Sales_Accounts_Name}
                                optionSelect={selectedSalesAccount}
                              />
                            </div>
                          </label>
                        </div>
  
                        <div className="mt-2">
                          <span className="text-xs">Description</span> <br></br>
                          <input
                            {...register("Sales_Description")}
                            className="bg-filterInput bottom-0 h-[20vh] outline-none border-transparent focus:border-transparent focus:ring-0"
                            type="text"
                            placeholder="Write a Description"
                          />
                        </div>
                        <div className="mt-3">
                          <label className="">
                            <span
                              style={{ color: "#F17E7E" }}
                              className="text-xs"
                            >
                              Sales Tax
                            </span>
                            <div className="dropdown bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer  border-0">
                           
                              <GeneralDropDown
                                manage={true}
                                defaultVal={itemToEdit?.Sales_Tax_Name}
                                manageList={manageTaxList}
                                dropDown={taxList}
                                optionSelect={salesTaxList}
                              />
                            </div>
                          </label>
                        </div>
                      </span>
                    )}
                  </section>
                </Grid>
                <Grid item xs={4}>
                  {/* CONTROL SPACE BETEEN */}
                  <section className="other_information  ">
                    <div className=" font-bold  mt-4 ">
                      <input
                        onChange={(e) => setPurchaseCheck(e.target.checked)}
                        type="checkbox"
                        className="w-[0.8vw] h-[1.6vh]"
                      />
                      <span className="text-xs mt-4 ml-2">
                        Purchase Information
                      </span>
                    </div>
  
                    {purchaseCheck && (
                      <span>
                        <div className="mt-2">
                          <label className="relative">
                            <span
                              style={{ color: "#F17E7E" }}
                              className="text-xs"
                            >
                              Cost Price
                            </span>{" "}
                            <br></br>
                            <span className="other_dimension_input">
                              <input
                                {...register("Sales_Rate")}
                                className="bg-filterInput  placeHolder_input z-50 bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                type="text"
                                placeholder=""
                              />
                              <span className="dimenSelect_input ">
                                <DimentionSelect
                                  selectedDimension={selectDefaultCurrency}
                                  productDimension={defaultCurrency}
                                />
                              </span>
                            </span>
                          </label>
                        </div>
                        <div className="mt-3">
                          <label className="">
                            <span
                              style={{ color: "#F17E7E" }}
                              className="text-xs"
                            >
                              Purchase Account
                            </span>{" "}
                            <br/>
                            <div className="dropdown bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer  border-0">
                           
                              <GeneralDropDown
                                manage={false}
                                defaultVal={itemToEdit?.Purchase_Account_Name}
                                dropDown={purchaseAccounts}
                                optionSelect={selectedPurchaseAccount}
                              />
                            </div>
                          </label>
                        </div>
  
                        <div className="mt-2">
                          <span className="text-xs">Description</span> <br></br>
                          <input
                            {...register("Purchase_Description")}
                            className="bg-filterInput bottom-0 h-[20vh] outline-none border-transparent focus:border-transparent focus:ring-0"
                            type="text"
                            maxLength="1000"
                            placeholder="Write a Description"
                          />
                        </div>
                        <div className="mt-3">
                          <label className="">
                            <span
                              style={{ color: "#F17E7E" }}
                              className="text-xs"
                            >
                              Purchase Tax
                            </span>{" "}
                            <br/>
                            <div className="dropdown bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer  border-0">
                              <GeneralDropDown
                                manage={true}
                                manageList={manageTaxList}
                                defaultVal={itemToEdit?.Purchase_Tax_Name}
                                dropDown={taxList}
                                optionSelect={selectPurchaseTax}
                              />
                            </div>
                          </label>
                        </div>
                      </span>
                    )}
                  </section>
                </Grid>
                <Grid item xs={4}>
                  <section className="other_information ">
                    {/* <AddTrackingItemCheckbox
                  val={itemToEdit?.Item_Tracking_Need_Id}
                  addTrackingForItem={addTrackingForItem}
                ></AddTrackingItemCheckbox> */}
                    <div className=" font-bold  mt-4 ">
                      <input
                        onChange={(e) => setTrackingCheck(e.target.checked)}
                        type="checkbox"
                        className="w-[0.8vw] h-[1.6vh]"
                      />
                      <span className="text-xs mt-4 ml-2">
                        Track inventory for this Item{" "}
                      </span>
                    </div>
  
                    {trackCheck && (
                      <span>
                        <AddItemTrackingTypeCheckRadioButton
                          val={itemToEdit?.Item_Tracking_Type_Id}
                          addItemTrackingType={addItemTrackingType}
                        ></AddItemTrackingTypeCheckRadioButton>
  
                        <div className="mt-2">
                          <label className="relative">
                            <span className="text-xs">Minimum Payment</span>{" "}
                            <br></br>
                            <span className="other_dimension_input">
                              <input
                                {...register("Sales_Rate")}
                                className="bg-filterInput  placeHolder_input z-50 bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                type="text"
                                placeholder=""
                              />
                              <span className="dimenSelect_input ">
                                <DimentionSelect
                                  selectedDimension={selectDefaultCurrency}
                                  productDimension={defaultCurrency}
                                />
                              </span>
                            </span>
                            {/* <input
                      {...register("Minimum_Payment")}
                      className="bg-filterInput placeHolder_input z-50 bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="number"
                      placeholder=""
                    />
                    <span className="text-filterTextColor -z-0 absolute bg-white left-0  h-[6vh] w-[4vw] mt-1  py-2 px-2 rounded  text-xs ">
                      NGN
                    </span> */}
                          </label>
                        </div>
                        <div className="mt-3">
                          <label className="">
                            <span
                              style={{ color: "#F17E7E" }}
                              className="text-xs"
                            >
                              Inventory Account
                            </span>{" "}
                            <br></br>
                            <div className="dropdown bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer  border-0">
                              <GeneralDropDown
                                manage={false}
                                defaultVal={itemToEdit?.Inventory_Accounts_Name}
                                dropDown={inventoryAccounts}
                                optionSelect={selectedInventoryAccount}
                              />
                            </div>
                          </label>
                        </div>
  
                        <div className="mt-2">
                          <span className="text-xs">Reorder Point</span> <br></br>
                          <input
                            {...register("Reorder_Quantity")}
                            className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                            type="number"
                            placeholder=""
                          />
                        </div>
  
                        <label className="">
                          <span style={{ color: "#F17E7E" }} className="text-xs">
                            Preferred Vendor
                          </span>{" "}
                          <br></br>
                          <div className="dropdown bg-inputColor h-[6.5vh] pt-2 select_dimension_manufaturer  border-0">
                            <GeneralDropDown
                              manage={true}
                              defaultVal={itemToEdit?.Preferred_Vendor_Name}
                              manageList={manageVendorList}
                              dropDown={vendors}
                              optionSelect={selectedVendor}
                            />
                          </div>
                        </label>
                      </span>
                    )}
  
                    {/* <div className="mt-2">
          <span className="text-xs">Preffered Vendor</span> <br></br>
          <input
            className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
            type="text"
            placeholder=""
          />
        </div> */}
                  </section>
                </Grid>
              </Grid>
            </section>
  
            <section className="flex my-5 justify-between   ">
              <a
                onClick={() => navigate(-1)}
                style={{ color: "#F17E7E" }}
                className="py-2 btn px-4 text-sm rounded "
              >
                Cancel
              </a>
  
              <button
                type="submit"
                style={{ backgroundColor: "#476EF8" }}
                className="py-2 px-4  text-sm  rounded text-white"
              >
                Save
              </button>
            </section>
          </section>
        </form>
      </>
    );
  }
  
  export default AddItemForm;
  