


import React from "react";


function AccountDashboard() {
  
    return (
        <>
              
              <div className="card p-5">

                <h3 className="text-center">Account dashboard </h3>

              </div>
        </>
  
    )
  }
  

export default AccountDashboard;