import { DataGrid ,  gridPageCountSelector,
  gridPageSelector, GridToolbarContainer, GridToolbarExport ,
  useGridApiContext,
  useGridSelector} from "@mui/x-data-grid";
  import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {  useLocation, useNavigate } from "react-router-dom";
import moment from "moment";


const TableHead = [
  { field: 'Customer_Id', headerName:'Customer Id' , width: 195 , headerClassName: "tableHeader "},
  { field: 'Customer_Full_Name', headerName:'Name' , width: 195 , headerClassName: "tableHeader "},
  { field: 'Customer_Email_Address', headerName:'Email' , width: 195 , headerClassName: "tableHeader "},
   { field: 'Zoho_Wallet_Balance', headerName:'(₦) Zoho Balance' , width: 195 , headerClassName: "tableHeader "},
   { field: 'Grocedy_Wallet_Balance', headerName:'(₦) Grocedy Balance' , width: 195 , headerClassName: "tableHeader "},
   { field: 'Difference_Balance', headerName:'(₦) Difference' , width: 195 , headerClassName: "tableHeader "},
   { field: 'Updated_Date', headerName:'Updated' , width: 195 , headerClassName: "tableHeader "},

  ]


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}


function WalletTable(props) {

  const navigate = useNavigate();
const { pathname } = useLocation();  
var url = pathname.split('/')
const currModuleId = url[1];
const currModuleName = url[2];
const currUserMenu = url[3];


  function OnItemCellClick(event){
    const id = event.row['Customer_Id'];
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`)
  }




  return ( 
      <>
<div className="table" style={{ height: 500, width: '100%' }}>
    <DataGrid  
      columns={TableHead}
         rows={props?.list.map((item, index) => { return {...item, id: index,
          Total_Transaction_Amount: '₦' + item.Total_Transaction_Amount?.toLocaleString(),
          Previous_Wallet_Balance:  '₦' + item.Previous_Wallet_Balance?.toLocaleString(),
          Current_Wallet_Balance:  '₦' + item.Current_Wallet_Balance?.toLocaleString(),
          Transaction_Date : moment(item?.Transaction_Date).format("DD/MM/YYYY, h:mm:ss a")}} )}
      pageSize={6}
      rowsPerPageOptions={[6]}
      checkboxSelection={true}
      components={{
          Toolbar: CustomToolbar,
          Pagination: CustomPagination,
        }}
        onCellClick={( event) => { event.defaultMuiPrevented = true}}
        onRowClick={(event)=> {  OnItemCellClick(event) }}
        
     
        sx={{
          boxShadow: 2,
          border: 2,
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
            cursor: 'pointer',
          },
        }}
    />

  </div>
      
      </>
   );
}

export default WalletTable
;