import React, { useEffect, useState } from 'react';

function ShippingCheck(props) {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked((prevChecked) => !prevChecked);
    
  };


  useEffect(()=> {
    props.onStatusCheck(isChecked);

  },[isChecked]);

  return (
    <label className="inline-flex items-center space-x-2">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
      />
      <span className="text-gray-700">Shipment already delivered </span>
    </label>
  );
}

export default ShippingCheck;
