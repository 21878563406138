

import { atom , useRecoilValue, useSetRecoilState} from "recoil";




const itemGroupList = atom({key:'itemGroupList', default:[]});
export const itemGGroupBackUp = atom({key:'itemGroupMasterAtom', default:[]});
export const  masterDataGroupAtom = atom({key:'masterDataGroupAtom', default:[]});
export const  groupListAtom = atom({key:'groupListAtom', default:[]});
export const itemGroupoEdit = atom({key:'itemGroupoEdit', default:{}});

export  const SetTGroupItemList = () => useSetRecoilState(itemGroupList);
export  const GetGroupItemList = () => useRecoilValue(itemGroupList);

export  const SetGroupBackup = () => useSetRecoilState(itemGGroupBackUp);
export  const GetGroupBackup = () => useRecoilValue(itemGGroupBackUp);

export  const SetMasterData = () => useSetRecoilState(masterDataGroupAtom);
export  const GetMasterData = () => useRecoilValue(masterDataGroupAtom);

export  const SetGroupList = () => useSetRecoilState(groupListAtom);
export  const GetGroupList = () => useRecoilValue(groupListAtom);


export  const SetGroupEdit = () => useSetRecoilState(itemGroupoEdit);
export  const GetGroupEdit = () => useRecoilValue(itemGroupoEdit);
export const ResetGroupEdit = ()=> useSetRecoilState(itemGroupoEdit)