import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useForm } from "react-hook-form";
import { useContext, useEffect } from 'react';
import LogicContext from "../../Context/LogicContext";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};





export default function FAQModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {register, setValue, getValues, handleSubmit,control,reset,formState: { errors }} = useForm();
  const myPromiseModal = createModal(GeneralModal);
  const logicContext = useContext(LogicContext);



  const onSubmit = async ()=> {
    await myPromiseModal({title: `Confirmation!`, body: `The following record will be saved! `
      ,showInput: false, placeHolder: ''}).then((res) => {
        if(res?.decision === true){
          const formValues =  getValues();
         props.submitFAQContent(formValues);
         handleClose();

        }


  });
  

  }

  

  return (
    <div>
      <button className='btn btn-primary' onClick={handleOpen}>NEW FAQ</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title " variant="h6" component="h2">
            <div className='text-center font-weight-bold' >Create New FAQ</div>
          </Typography>
             <div onClick={handleClose} class="d-flex flex-row-reverse bd-highlight mb-4">
                  <button className='btn btn-danger text-light' >X</button>
             </div>


             <form onSubmit={handleSubmit(onSubmit)} >
        <Grid container spacing={1}
        justifyContent="center"
        alignItems="flex-start"
        >
                <Grid item textAlign="left" xs={3}>
                   
                    <div className=" mt-3">
                        Question
                    </div>
                </Grid>
                <Grid item textAlign="left" xs={9}>
                  
                  <div className="">
                  {/* w-full */}
                    <input
                      className="bg-filterInput h-[8vh] w-full   pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="text"
                      placeholder='Enter question.'
                      {...register("question", {
                        required: "question is required",
                      })}
                    /> <br/>
                     <small className="text-red-500">
                            {Boolean(errors["question"]?.message) &&
                                String(errors["question"]?.message)}
                        </small>
                  </div>
                </Grid>
                
                <Grid item textAlign="left" xs={3}>
                    <div className=" mt-3">
                         Answer
                    </div>
                </Grid>
                <Grid item textAlign="left" xs={9}>
                  <textarea rows={8}
                    className="bg-filterInput  w-full   pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    type="text"
                    placeholder='Enter answer.'
                    {...register("answer", {
                      required: "answer is required",
                    })}
                    /><br/>
                    <small className="text-red-500">
                           {Boolean(errors["answer"]?.message) &&
                               String(errors["answer"]?.message)}
                       </small>
                </Grid>
                
                <Grid item textAlign="left" xs={3}>
                    <div className=" mt-3">
                         Sequence
                    </div>
                </Grid>
                <Grid item textAlign="left" xs={9}>
                  <input rows={8}
                    className="bg-filterInput  w-full   pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    type="number"
                    placeholder='Enter sequence.'
                    {...register("display_sequence", {
                      required: "Sequence is required",
                    })}
                    /><br/>
                    <small className="text-red-500">
                           {Boolean(errors["display_sequence"]?.message) &&
                               String(errors["display_sequence"]?.message)}
                       </small>
                </Grid>

                <Grid item xs={6}>
                    
                </Grid>
                <Grid item textAlign="left" xs={6}>
                    
                   
                    <div className='text-center mt-4  mr-10'>
                    <button className='btn  btn-primary' >SAVE FAQ</button>
                </div>
                
                    
                </Grid>

               
        </Grid>
        
        </form>
         
        </Box>
      </Modal>
    </div>
  );
}