

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


const notificationData = atom({key:'notificationViewDataAtom', default:{notification:[],
notification_type:[]}});




export  const SetNotificationView = () => useSetRecoilState(notificationData);
export  const GetNotificationView = () => useRecoilValue(notificationData);






