// import { DataGrid ,  gridPageCountSelector,
//     gridPageSelector, GridToolbarContainer, GridToolbarExport ,
//     useGridApiContext,
//     useGridSelector} from "@mui/x-data-grid";
import {
  DataGrid, gridPageCountSelector,
  gridPageSelector, GridToolbarContainer, GridToolbarExport,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ShareIcon from '@material-ui/icons/Share';
import CorporateShareModal from "./CorporateShareModal";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import VoucherCorpUploadModal from "./VoucherCorpUploadModal";
import { useState, useContext, useEffect } from "react";
import { SetVoucherListAtom, GetVoucherListAtom } from "../../store/voucherAtom";
import LogicContext from "../../Context/LogicContext";
import * as XLSX from 'xlsx';



function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const TableHead = [
  { field: 'sn', headerName: 'S/N', width: 40, headerClassName: "tableHeader " },
  { field: 'Ref_No', headerName: 'Ref No', width: 80, headerClassName: "tableHeader " },
  { field: 'Voucher_Number', headerName: 'Voucher Number', width: 120, headerClassName: "tableHeader ", cellClassName:"font-bold show-cursor text-primary " },
  { field: 'Voucher_Amount', headerName: 'Amount (₦)', width: 90, headerClassName: "tableHeader " },
  { field: 'Voucher_Status_Name', headerName: 'Status', width: 90, headerClassName: "tableHeader " },
  { field: 'Reciepient_Name', headerName: 'Recipient Name', width: 110, headerClassName: "tableHeader ", cellClassName:' border-2', editable: 'text'},
  { field: 'Reciepient_Mobile_Number', headerName: 'Recipient Mobile', width: 120, headerClassName: "tableHeader", editable: 'text', cellClassName:' border-2', placeHolder:"Enter name"},
  { field: 'Reciepient_Email_Address', headerName: 'Email Address', width: 150, headerClassName: "tableHeader ", editable: 'text', cellClassName:' border-2' },
  { field: 'edit', headerName: 'Modify', width: 60, headerClassName: "tableHeader ", editable: 'text', cellClassName:' text-center text-primary font-weight-bold show-cursor'},
  { field: 'status', headerName: 'Status', width: 80, headerClassName: "tableHeader ", editable: 'text', cellClassName:' text-center text-danger font-weight-bold'},
]


const myRegex = new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$');



function VoucherCreateTable(props) {
  const getVoucherData = GetVoucherListAtom();

  const [selectedIdList, setSelectedIdList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  // getVoucherData?.newVoucherList
  const [editTrigger, setEditTrigger] = useState(false);
  const [triggerData, setTriggerData] = useState({});
  const setVoucherAtomData = SetVoucherListAtom();
  const logicContext = useContext(LogicContext);
  const [summary, setSummary] = useState({total:0, assigned:0, open:0, un_assigned:0 , redeemed: 0});


  useEffect(()=> {
    let un_assigned = 0;
    let redeemed = 0;
    let open = 0;
    let total = getVoucherData.newVoucherList.length;
    getVoucherData?.newVoucherList.forEach(element => {
        if(element?.Voucher_Status_Id === 3){
            un_assigned += 1;
        }
        if(element?.Voucher_Status_Id === 2){
            redeemed += 1;
        }
        if(element?.Voucher_Status_Id === 1){
            open += 1;
        }
        
    });

    setSummary({un_assigned: un_assigned, open: open, redeemed: redeemed, total: total});

// eslint-disable-next-line react-hooks/exhaustive-deps
setTableList(getVoucherData.newVoucherList);
},[getVoucherData]);



const onFilterClick = (type) => {
  if(type === 0){
    setTableList(getVoucherData.newVoucherList);
  }

  if(type === 1){
    let newList = getVoucherData.newVoucherList.filter((item)=> {
      if(item?.Voucher_Status_Id === type ){
        return  item;
      }
    } 
    );
    setTableList(newList);
  }

  if(type === 2){
    let newList = getVoucherData.newVoucherList.filter((item)=> {
      if(item?.Voucher_Status_Id === type ){
        return  item;
      }
    } 
    );
    setTableList(newList);
  }

  if(type === 3){
    let newList = getVoucherData.newVoucherList.filter((item)=> {
      if(item?.Voucher_Status_Id === type ){
        return  item;
      }
    } 
    );
    setTableList(newList);
  }

}



  const onColTypingEdit = (e) => {
    const rowIndex = Object.keys(e)

    let newList = [...getVoucherData.newVoucherList];
    const newEditable = e[Number(rowIndex)];
    let existData = newList[Number(rowIndex)];

    const keyVariableName = Object?.keys(newEditable);
    if (newEditable[keyVariableName]?.value && newEditable !== undefined) {
      let UpdatedData = { ...existData, [keyVariableName]: newEditable[keyVariableName]?.value };
      newList[Number(rowIndex)] = UpdatedData;
      setVoucherAtomData(prev => {
        return { ...prev, newVoucherList: newList };
      });
      logicContext.showToast(`${[keyVariableName]} has been updated!`, 'info');

    }

  }



  function onSelectChange(selectedList) {
    setRowSelectionModel([]);
    let temp = [];
    var selection = selectedList?.map((index) => {
       let element = tableList[index];
       temp.push(index);
      return element?.Ref_No;
    });
    setSelectedIdList(selection);
    setRowSelectionModel(temp);
  }


  function onTableCellClick(event) {
    if(event.field === 'edit'){
      setEditTrigger(true);
      setTriggerData(event.row);
    }else if(event.field === 'Voucher_Number'){
      navigator.clipboard.writeText(event?.row?.Voucher_Number).then(success => {
        logicContext.showToast(`${event?.row?.Voucher_Number} copied!`, '');

      });

      
    }else{
      setEditTrigger(false);
    }
  }


  const onFileUpload = (file) => {
    let uploadedFile = file.target.files[0];
    const fileType = file.target.files[0]?.name.split('.')[1];
    let tempUpload = [];
    if(fileType === 'csv'){

      var reader = new FileReader();
      reader.onload = function (e) {
          const csvFile = e.target.result;
          let lines = csvFile.split("\n");
          
          let newList = [...getVoucherData.newVoucherList];
     
          let tempObj = {Reciepient_Name:'', Reciepient_Mobile_Number:'', Reciepient_Email_Address:'', status:''};
          let counter = 0;
          for (let i = 0; i < lines.length; i++){
              const element = lines[i];
              if (i !== 0 && counter < newList.length ){
                tempUpload.push(counter);
                  tempObj.Reciepient_Name = element.split(',')[0] ? element.split(',')[0] : '';
                  tempObj.Reciepient_Mobile_Number = element.split(',')[1] ? element.split(',')[1]: '';
                  tempObj.Reciepient_Email_Address = element.split(',')[2] ? element.split(',')[2] :'';
                  tempObj.status = '';
                  const isValid = checkIfEmailIsValid(tempObj[tempObj.Reciepient_Email_Address]);
                  if(!isValid){
                    tempObj.status = 'Not valid';
                  }

                  if(tempObj.Reciepient_Mobile_Number?.length < 8){
                    tempObj.status = 'Not valid';
                }

                  if(tempObj.Reciepient_Name?.length < 2){
                    tempObj.status = 'Not valid';
                }
                  let storedData =   newList[counter];
                  newList[counter] = {...storedData, ...tempObj};
                  counter += 1
              }
          }
          setRowSelectionModel(tempUpload);
          let temp = [];
          for (let i = 0; i < tempUpload.length; i++ ){
              let element = tableList[i];
              temp.push(element?.Ref_No);
          }
          setSelectedIdList(temp);

          setVoucherAtomData(prev => {
            return { ...prev, newVoucherList: newList };
          });
          setTableList(newList);
       };
       reader.readAsText(uploadedFile);
       // end csv

    }else if(fileType === 'xlsx'){ // For Excel file
      var reader = new FileReader();
      reader.readAsArrayBuffer(file.target.files[0]);
      reader.onload = function (e) {
          var data = new Uint8Array(reader.result);
          var workbook = XLSX.read(data, { type: 'array' });
          var sheet = workbook.Sheets[workbook.SheetNames[0]];
          let jsonList = XLSX.utils.sheet_to_json(sheet);

          let newList = [...getVoucherData.newVoucherList];
     
          let tempObj = {Reciepient_Name:'', Reciepient_Mobile_Number:'', Reciepient_Email_Address:'', status:''};
          let counter = 0;
          for (let i = 0; i < jsonList.length; i++){
            const element = jsonList[i];
            
              if (i !== 0 && counter < newList.length){
                tempUpload.push(counter);
                tempObj.status = '';
                tempObj.Reciepient_Name = element?.name ? element?.name : '';
                tempObj.Reciepient_Mobile_Number = element?.mobile ? element?.mobile : '';
                tempObj.Reciepient_Email_Address = element?.email ? element?.email :'';
                const isValid = checkIfEmailIsValid(tempObj.Reciepient_Email_Address);
                  if(!isValid){
                    tempObj.status = 'Not valid';
                }

                if(tempObj.Reciepient_Mobile_Number?.length < 8){
                  tempObj.status = 'Not valid';
              }
                
                if(tempObj.Reciepient_Name?.length < 2){
                  tempObj.status = 'Not valid';
              }
                  let storedData =   newList[counter];
                  newList[counter] = {...storedData, ...tempObj};
                  counter += 1;
              }
          }

          let temp = [];
          for (let i = 0; i < tempUpload.length; i++ ){
              let element = tableList[i];
              temp.push(element?.Ref_No);
          }
          setSelectedIdList(temp);
          setRowSelectionModel(tempUpload);
          setVoucherAtomData(prev => {
            return { ...prev, newVoucherList: newList };
          });
          setTableList(newList);
      }
    }
}


const checkIfEmailIsValid =  (email)=> {
  let result =   myRegex.test(`${email}`);
  // return result ;
  return true ;

}


const onSubmitRecord = (data)=> {
  let status = '';
  setEditTrigger(false);
  if(data?.Ref_No){
    const isValid = checkIfEmailIsValid(data.Reciepient_Email_Address);
    if(!isValid){
      data.status = 'Not valid';
  }

  if(data.Reciepient_Mobile_Number?.length < 8){
    status = 'Not valid';
}
  
  if(data.Reciepient_Name?.length < 2){
    status = 'Not valid';
}
    let newList = [...getVoucherData.newVoucherList];
    let newData = {...data, status: status};
    newList[data.id ] = newData;
    setVoucherAtomData(prev => {
      return { ...prev, newVoucherList: newList };
    });
    setTableList(newList);
    setTriggerData({});

  }
}




const onSearchKeyChange = (eve)=> {
  const search =  eve.target.value;
  if(search.length > 2){
      let tempResult =  getVoucherData.newVoucherList?.filter((voucher)=> {
          return voucher?.Voucher_Number?.toString()?.indexOf(search?.toLowerCase()) >= 0 ||
                  voucher?.Reciepient_Mobile_Number?.toString().toLowerCase()?.indexOf(search?.toLowerCase()) >= 0 ||
                  voucher?.Reciepient_Email_Address?.toString().toLowerCase()?.indexOf(search?.toLowerCase()) >= 0 ||
                  voucher?.Customer_Email_Address?.toString().toLowerCase()?.indexOf(search?.toLowerCase()) >= 0  ||
                  voucher?.Voucher_Number?.toString().toLowerCase()?.indexOf(search?.toLowerCase()) >= 0  ||
                  voucher?.Ref_No?.toString().toLowerCase()?.indexOf(search?.toLowerCase()) >= 0  
        });
        setTableList(tempResult);
  } else if(search.length < 1){
    setTableList(getVoucherData.newVoucherList);
  }
 
}





  return (
    <>
    {/* Start display */}
    <section className="grid grid-cols-5  grid-flow-col gap-4 w-full border-1  text-dark py-4  ">
                                <div onClick={()=> onFilterClick(0) }  className="card bg-light py-1 px-2 rounded show-cursor "  >
                                    <h1 className=" text-center mt-1 display-4 text-primary font-bold" > {summary.total} </h1>
                                    <div className="text-sm  text-center ">Total</div>

                                </div>

                                 <div   onClick={()=> onFilterClick(1) }  className="card bg-light py-1 px-2 rounded show-cursor"   >
                                    <div className=" text-center mt-1 display-4 text-danger font-bold">{summary.open} </div>
                                    <div className="text-sm  text-center ">Open</div>

                                </div> 

                                <div  onClick={()=> onFilterClick(3) }  className="card bg-light py-1 px-2 rounded show-cursor " >
                                    <div className=" text-center mt-1 display-4  text-warning font-bold">{summary.un_assigned}  </div>
                                    <div className="text-sm  text-center ">Unassigned</div>
                                </div>

                                <div  onClick={()=> onFilterClick(2) }  className="card bg-light py-1 px-2 rounded show-cursor " >
                                    <div className=" text-center mt-1 display-4  text-success font-bold"> {summary.redeemed} </div>
                                    <div className="text-sm  text-center ">Redeemed</div>
                                </div>
                            </section>

    {/* end display */}

        {/* grid start */}
        <div className="row border-2 bg-filterColor mt-5 mb-2" >
                        <div className="col" >
                        <div className="pt-2" >
                                <input onChange={(ev)=> onSearchKeyChange(ev)} className="form-control mr-sm-2" type="search" placeholder="Search..." aria-label="Search">
                                </input>
                            </div>
                        
                        </div>

                        {/* <div className="col" >
                         
                        </div> */}

                        <div className="col offset-1" >
                              <div className="flex justify-end mx-2 my-2">
                          <CorporateShareModal onSubmitRecord={onSubmitRecord} editTrigger={editTrigger} triggerData={triggerData} />

                         {(summary.un_assigned > 0 )  &&
                         <>
                          <VoucherCorpUploadModal  newVoucherList={getVoucherData.newVoucherList} onFileUpload={onFileUpload}  />
                          <button className="rounded-lg bg-blue-700 text-white  px-4 py-2 mx-2 "
                          variant="outlined" onClick={() => props?.shareActionButtonAll(selectedIdList)}>
                          Assign  <ShareIcon />
                        </button>
                         </>
                         }
                         
                        </div>
                        
                        </div>

                    </div>  
                    {/* End of Grid */}


      <div className="mt-5">

        <div style={{ height: 450, width: '100%' }}>
          <DataGrid
            checkboxSelection={true}
            columns={TableHead}
            selectionModel={[...rowSelectionModel]} 
    
        //       onRowSelectionModelChange={(newRowSelectionModel) => {
        //   setRowSelectionModel(newRowSelectionModel);
        // }}
        rowSelectionModel={rowSelectionModel}

            pageSize={10}
            rowsPerPageOptions={[4]}
            onRowSelectionModelChange={onSelectChange}
            onCellClick={onTableCellClick}
            rows={tableList?.length && tableList?.map((item, i) => {
              return {
                ...item, edit:'EDIT', id: i, sn: i+1, Voucher_Amount:  + item?.Voucher_Amount?.toLocaleString()
              }
            })}
            components={{
              Pagination: CustomPagination,
            }}
          />
        </div>
      </div>

    </>
  );
}

export default VoucherCreateTable;