import { useEffect, useState } from 'react';
import Select from 'react-select';




function ApproveFilterSelect(props) {
  const [customOptionList, setCustomOptionList] = useState([]);

  useEffect(()=> {
    const customOption = props.approve_status?.map((item)=> {
      return ({...item, label:`${item.Status_Name }`})
    });
    if(customOption.length){
      setCustomOptionList(customOption);
    }
  },[props.approve_status]);


//   useEffect(() => {
//     console.log('LOg_select  ', props?.defaultVal);
//     console.log('LOg_select_data ', options[Number(props?.defaultVal - 1)]);
//     if (props?.defaultVal) {
//       setDefData(options[Number(props?.defaultVal - 1)]);
//     }

//   }, [props?.defaultVal]);

  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      zIndex: 0,
      width: 150,
    }),

    indicatorSeparator: styles => ({ ...styles, display: 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '', height: 20, paddingBottom: "45px", marginLeft: "13px" }),
    option: styles => ({ ...styles, color: "black" }),
    menuPortal: styles => ({ ...styles, zIndex: 99999 })
  }

  return (
    <>
      {/* <Select  defaultValue={props?.defaultVal}
          onChange={(e)=> props?.onCountrySelect(e)} options={customOption}     styles={customStyles} /> */}

      <div style={{ width: '300px' }}>

        <Select
          menuPlacement="auto"
          menuPosition="fixed"
          placeholder=" Type"
          key={customOptionList.length} options={customOptionList}   onChange={props?.onStatusFilterSelect} styles={customStyles} />
      </div>
      {/* defaultValue={} */}
    </>
  );
}

export default ApproveFilterSelect;
