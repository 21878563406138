
import React from "react";
import moment from "moment";
import CurrencyFormat from "react-currency-format"; 
import { IoMdMenu } from "react-icons/io";
import { Dropdown, Space } from 'antd';
import { useNavigate, useParams, useLocation } from "react-router-dom";


const items  = [
  {
    key: '1',
    label: 'Sort by Package Date',
    disabled: true,
  },
  {
    key: '2',
    label: 'Package',
    extra: '⌘P',
  },
  {
    key: '3',
    label: 'Sales Order',
    extra: '⌘B',
  },
  {
    key: '4',
    label: 'Customer Name',
    extra: '⌘B',
  },
  {
    key: '5',
    label: 'Quantity',
    extra: '⌘B',
  },
  // {
  //   key: '4',
  //   label: 'Settings',
  //   icon: <SettingOutlined />,
  //   extra: '⌘S',
  // },
];

const PackageCard = ({selections, title, packages, dynamicColor, objKey , onCheckboxClick}) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];





  function viewDetails(salesOrderId) {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/details/${salesOrderId}?type=${objKey}`
    );
  }


  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <h3 className="text-lg  mb-4 p-2"
      style={{ "--dynamic-color": dynamicColor, backgroundColor: "var(--dynamic-color)" }}>
        {/* Part 1 */}
      {selections?.length  &&
        <div class="flex justify-between">
        <div class="w-8/10">
          <p>Selected {selections?.length} Item(s) </p>
        </div>
        <div class="w-2/10 text-right">
        <span>❌</span>
        </div>
        </div>
       }
      

        {/* Part 2 */}
        {!selections?.length  &&
            <div class="flex justify-between">
            <div class="w-8/10">
              <p>{title}</p>
            </div>
            <div class="w-2/10 text-right">
            {/* Drop down */}
            <Dropdown  menu={{  items}} trigger="click" >
            <a onClick={(e) => e.preventDefault()}>
            <Space>
            <IoMdMenu className="ml-2" />
            </Space>
            </a>
            </Dropdown>
              
            </div>
            </div>
        }
     
        {/* Drop down end */}


        </h3>
      
      <ul className="space-y-3 h-screen overflow-y-auto">

      {/* <div className={`base-class ${someBoolean ? "class-if-true" : "class-if-false"}`} */}
  {packages.map((pkg, index) => (
    <li onClick={()=> viewDetails(pkg?.Sales_Order_Ref_No)}
      key={index}
      className=" show-cursor mr-3 mb-2 border-b pb-2 flex justify-between "
    >
    <input type="checkbox"
    onChange={(e) => onCheckboxClick(e, index, objKey, packages, pkg)}
    checked={pkg?.checked ? pkg?.checked : ""}
     />
      <div>
        <h4 className="text-sm font-medium">{pkg.Customer_Full_Name}</h4>
        <p className="text-xs text-gray-500">{pkg.Subscription_Ref_No}</p>
        <p className="text-xs text-gray-400">{moment(pkg.Created_Date).format("YYYY-MM-DD, h:mm:ss ")} </p>
      </div>
      <div className="text-right">
        <p className="text-sm">
          <CurrencyFormat
                  value={pkg.Sales_Order_Amount?.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                />
          
          </p>
        <p className="text-xs text-gray-400">{pkg.Sales_Order_No}</p>
      </div>
    </li>
  ))}
</ul>
    </div>
  );
};

export default PackageCard;
