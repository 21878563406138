import Select from 'react-select';




function CorporateType(props) {

  const customOption = props?.customList?.map((item)=> {
    return ({...item, label:`${item.Corporate_Type_Name}`})
  });



  
  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      backgroundColor: '#F1F1F1',
      zIndex: 0,
      // width: 512,
    }),
  
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
    menuPortal:styles => ({ ...styles, zIndex: 9999 })
  }



    return ( 
        <>
   
         <Select defaultValue={props?.defaultVal} 
         onChange={(e)=> props?.onCorporateSelect(e)} options={customOption}    styles={customStyles} />


        </>
     );
}

export default CorporateType;
