import { useEffect } from "react";
import Select from 'react-select';

const customStyles = {
    control: base => ({
      ...base,
      height: 45,
      backgroundColor: '#ffff',
      width: 200,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    // option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
    option: styles => ({ ...styles, backgroundColor: '#ffff', color:"black"}),
  }

function CreditNoteOptions(props) {
  useEffect(() => {}, [props?.bulkActionData]);

  const customOption = props?.bulkActionData.filter((item)=> item?.Credit_Note_Status_Id !== 0).map((item)=> {
    return ({...item, label:`${item.Credit_Note_Status_Name}`});

  });



  return (
    <>
        {/* className=" text-sm border-[1px] border-gray-200 rounded-lg outline-none focus:outline-none " */}
        <Select   styles={customStyles} onChange={(val)=>  props.onFilterSelect(val)} 
          defaultValue={props?.defaultVal} placeholder="Change Status"
          options={customOption}
         />
    </>
  );
}

export default CreditNoteOptions;
