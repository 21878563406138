// import Navbar from "../components/Navbar";
// import classes from "./layoutModule.css";
import "./layoutModule.css";
import { useContext, useEffect } from "react";
import LogicContext from "../Context/LogicContext";
import Logo from "../assets/delgro_logo_svg.png";
import { useNavigate, useLocation } from "react-router-dom";
import underscore from "underscore";
import { currentMenuListAtom } from "../store/storeMenuAtom";
import { useRecoilValue } from "recoil";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";

// mat inport
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";

// import MuiDrawer from "@mui/material/Drawer";

import List from "@mui/material/List";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import UserNameItem from "../components/UserNameItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NotificationPane from "./NotificationPane";
import Container from "../components/my-container/Container";
import SearchBarGeneralTwo from "../components/SearchBarGeneralTwo";
import SidebarModalTwo from "../components/SidebarModalTwo";
import DelgroLogo from "../components/delgro_logo/DelgroLogo";
import DelgroLogoSVG from "../components/delgro_logo/DelgroLogoSVG";
import { IoClose } from "react-icons/io5";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    background: "black",
    color: "white",
  },
  rootExpanded: {
    background: "black",
    flexGrow: 1,
  },
}));

const Userlayout = (props) => {
  const currentMenu = useRecoilValue(currentMenuListAtom);
  const logicContext = useContext(LogicContext);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  const { name } = props;
  const [expanded, setExpanded] = React.useState(false);
  const rootClass = expanded ? classes.rootExpanded : classes.root;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen((prev) => !prev);
  };

  const selectedMenu = underscore.pick(currentMenu, (value, key, menuList) => {
    return value["IsSetting_Menu"] === 0;
  });

  const parentMenu = underscore.groupBy(currentMenu, "Menu_Parent_Name");

  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const moduleSelectHandler = (selection) => {
    logicContext.moduleSelect(selection.Module_Id);
  };

  useEffect(() => {
    if (!isNaN(currModuleId) && !selectedMenu[0]) {
      setTimeout(() => {
        logicContext.moduleSelect(currModuleId);
      }, 1000);
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currModuleId]);

  const dynamicRoute = (route, menu) => {
    // navigate(`/${currModuleId}/` + currModuleName + "/" + menu["Menu_Name"].toLowerCase().replace(/ /g,"-"));
    setOpen(!open);
    navigate(
      `/${currModuleId}/` +
        currModuleName +
        "/" +
        menu["Function_Name"].replace(/_/g, "-")
    );
  };

  return (
    <>
      <div className="bg-[#F5F5F5]">
        <Container className="flex flex-row overflow-x-hidden">
          <div>
            {/* SMALL_SCREEN */}
            <div className="sm:hidden h-screen">
              <Drawer
                open={open}
                onClose={() => setOpen(!open)}
                direction="left"
                className="bla bla bla"
              >
                <div className="bg-black z-50 pl-1 pt-1">
                  <div className="w-full flex flex-row items-center justify-end p-1">
                    <div
                      onClick={handleDrawerClose}
                      className="w-fit border-[1px] border-gray-300 cursor-pointer rounded"
                    >
                      <IoClose className="h-4 w-4 text-gray-300" />
                    </div>
                  </div>
                  <div className="w-full flex flex-row items-center justify-center pb-2">
                    <div>
                      <DelgroLogoSVG />
                    </div>
                  </div>

                  <div className="flex flex-col overflow-y-scroll h-screen scrollbar-thumb-gray-800 scrollbar-thin scrollbar-track-gray-500 pb-[500px]">
                    <div
                      className={`mt-6 flex items-center justify-center text-center text-base text-primary font-weight-bold text-uppercase ${
                        open ? "inline" : "hidden"
                      }`}
                    >
                      {currModuleName}
                    </div>
                    <section
                      className={`h-[105vh]  ${open ? "inline" : "hidden"}`}
                    >
                      <List className=" text-white pb-[400px]">
                        {Object.keys(parentMenu).map((menu, i) => (
                          <ListItem button key={i}>
                            {parentMenu[menu][0]["IsParent_Menu"] === 1 ? (
                              <ListItem
                                className={
                                  "py-3 rounded text-lg font-semibold hover:bg-[#0000FF]/50  " +
                                  (currUserMenu?.toLowerCase() ===
                                  parentMenu[menu][0]["Function_Name"]
                                    ?.toLowerCase()
                                    .split("_")
                                    .join("-")
                                    ? "menu-active"
                                    : null)
                                }
                                onClick={() =>
                                  dynamicRoute(
                                    parentMenu[menu][0]["Function_Name"],
                                    parentMenu[menu][0],
                                    parentMenu[menu][0]["Menu_Name"]
                                  )
                                }
                              >
                                <ListItemIcon className="listitemIcon">
                                  <img
                                    src={require(`../assets/menu_icons/${
                                      i + 1
                                    }.png`)}
                                  />
                                </ListItemIcon>
                                <Typography className="typo">
                                  {" "}
                                  {parentMenu[menu][0]["Menu_Parent_Name"]}
                                </Typography>
                              </ListItem>
                            ) : (
                              <Accordion className={rootClass}>
                                <AccordionSummary
                                  sx={{
                                    minHeight: "0px",
                                    ".MuiAccordionSummary-content": {
                                      margin: 0,
                                    },
                                  }}
                                  expandIcon={
                                    <ExpandMoreIcon className="expand-icon" />
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="py-2.5 hover:bg-[#0000FF]/20 "
                                >
                                  <ListItemIcon className="listitemIcon">
                                    <img
                                      src={require(`../assets/menu_icons/${
                                        i + 1
                                      }.png`)}
                                      alt=""
                                    />
                                  </ListItemIcon>
                                  {open ? (
                                    <Typography className="typo ">
                                      {menu.substring(0, 25)}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </AccordionSummary>

                                <AccordionDetails className="drop-down ">
                                  {parentMenu[menu].map((subMenu, j) => {
                                    return (
                                      <ListItem
                                        onClick={() =>
                                          dynamicRoute(
                                            subMenu["Function_Name"],
                                            subMenu,
                                            subMenu["Menu_Name"]
                                          )
                                        }
                                        className={
                                          "two-img py-2.5 w-full expand hover:bg-[#0000FF]/20  " +
                                          (currUserMenu?.toLowerCase() ===
                                          subMenu["Menu_Name"]
                                            ?.toLowerCase()
                                            .split(" ")
                                            .join("-")
                                            ? "menu-active"
                                            : null)
                                        }
                                        key={j}
                                      >
                                        <Typography className="sub-menu typo">
                                          {" "}
                                          {subMenu["Menu_Name"]}{" "}
                                        </Typography>
                                      </ListItem>
                                    );
                                  })}
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </ListItem>
                        ))}
                      </List>
                    </section>
                  </div>
                </div>
              </Drawer>
            </div>

            {/* SMALL_SCREEN_END */}

            {/* BIG_SCREEN */}
            <div className="left-0 h-screen w-[250px] fixed z-50 hidden sm:inline bg-black">
              <div className="mt-2 flex items-center justify-center pb-2">
                <DelgroLogoSVG />
              </div>

              <div className="flex flex-col overflow-y-scroll h-screen scrollbar-thumb-gray-800 scrollbar-thin scrollbar-track-gray-500 pb-[500px]">
                <div className="mt-6 pb-2 bg-black text-center text-base text-primary text-uppercase">
                  {open ? currModuleName : null}{" "}
                </div>

                <section className="">
                  <List className="pb-[200px] text-white">
                    {Object.keys(parentMenu).map((menu, i) => (
                      <ListItem button key={i}>
                        {parentMenu[menu][0]["IsParent_Menu"] === 1 ? (
                          <ListItem
                            className={
                              "py-3 rounded text-lg font-semibold hover:bg-[#0000FF]/50  " +
                              (currUserMenu?.toLowerCase() ===
                              parentMenu[menu][0]["Function_Name"]
                                ?.toLowerCase()
                                .split("_")
                                .join("-")
                                ? "menu-active"
                                : null)
                            }
                            onClick={() =>
                              dynamicRoute(
                                parentMenu[menu][0]["Function_Name"],
                                parentMenu[menu][0],
                                parentMenu[menu][0]["Menu_Name"]
                              )
                            }
                          >
                            <ListItemIcon className="listitemIcon">
                              <img
                                src={require(`../assets/menu_icons/${
                                  i + 1
                                }.png`)}
                                alt=""
                              />
                            </ListItemIcon>

                            <Typography className="typo">
                              {" "}
                              {parentMenu[menu][0]["Menu_Parent_Name"]}
                            </Typography>
                          </ListItem>
                        ) : (
                          <Accordion className={rootClass}>
                            <AccordionSummary
                              sx={{
                                minHeight: "0px",
                                ".MuiAccordionSummary-content": { margin: 0 },
                              }}
                              expandIcon={
                                <ExpandMoreIcon className="expand-icon" />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="py-2.5 hover:bg-[#0000FF]/20 "
                            >
                              <ListItemIcon className="listitemIcon">
                                <img
                                  src={require(`../assets/menu_icons/${
                                    i + 1
                                  }.png`)}
                                  alt=""
                                />
                              </ListItemIcon>
                              {/* Simon222 */}
                              {/* {parentMenu[menu][0]["Menu_Name"]} */}
                              <Typography className="typo ">
                                {" "}
                                {menu.substring(0, 25)}{" "}
                              </Typography>
                            </AccordionSummary>

                            <AccordionDetails className="drop-down ">
                              {parentMenu[menu].map((subMenu, j) => {
                                return (
                                  <ListItem
                                    onClick={() =>
                                      dynamicRoute(
                                        subMenu["Function_Name"],
                                        subMenu,
                                        subMenu["Menu_Name"]
                                      )
                                    }
                                    className={
                                      "two-img py-2.5 w-full expand hover:bg-[#0000FF]/20  " +
                                      (currUserMenu?.toLowerCase() ===
                                      subMenu["Menu_Name"]
                                        ?.toLowerCase()
                                        .split(" ")
                                        .join("-")
                                        ? "menu-active"
                                        : null)
                                    }
                                    key={j}
                                  >
                                    <Typography className="sub-menu typo">
                                      {" "}
                                      {subMenu["Menu_Name"]}{" "}
                                    </Typography>
                                  </ListItem>
                                );
                              })}
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </section>
              </div>
            </div>
            {/* BIG_SCREEN END */}
          </div>
          <div className="w-full flex-1 sm:pl-[250px] pb-40">
            <div className="fixed top-0 left-0 sm:left-[250px] z-50 right-0 px-4 bg-[#F5F5F5]">
              <header className="xl:pr-4 py-2 flex items-center justify-between border-b-[1.5px] border-b-gray-200 @container">
                <div
                  onClick={handleDrawerOpen}
                  className="p-2 rounded-full hover:bg-gray-200 active:bg-gray-100 cursor-pointer flex-shrink-0 sm:hidden"
                >
                  <MenuIcon className="h-6 w-6 text-gray-600 flex-shrink-0" />
                </div>

                <div>
                  {/* <SearchBarGeneral /> */}
                  <SearchBarGeneralTwo />
                </div>
                <div className="flex flex-row items-center space-x-4">
                  <div>
                    <NotificationPane />
                  </div>

                  <div className="hidden @2xl:inline">
                    <SidebarModalTwo selectModal={moduleSelectHandler} />
                  </div>

                  <div>
                    <UserNameItem />
                  </div>
                </div>
              </header>
            </div>

            <div className="py-20">{props.children}</div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Userlayout;
