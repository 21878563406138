import { FileUploader } from "react-drag-drop-files";
import React, { useContext, useEffect, useState } from "react";
import "./ImageUploader.css";
import Select from "react-select";
import LogicContext from "../../Context/LogicContext";
import { productService } from "../../services/product.service";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { toast } from "react-toastify";
import ImageScrollGrocedyItems from "./ImageScrol";
import { GetRecoilImageUploadAtom, SetRecoilImageUploadAtom } from "../../store/imageUploadAtom";
import ImageScrollItemGroup from "./imageScrollItemGroup";
import ImageScrollAddonList from "./ImageScrollAddonList";
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';

const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];
const listDisplay = {GROCEDY_ITEMS:'2', GROCEDY_PLANS:"1", GROCEDY_ITEM_GROUP:"3",
GROCEDY_PRODUCTS:"4", GROCEDY_ADDONS:"4", BUTCHERS:"5"};

const successList = ['2','3','4','5'];

const grocedyOptions = [
  { value: "1", label: "Grocedy plans" },
  { value: "2", label: "Grocedy items" },
  { value: "5", label: "Butchers" },
  { value: "3", label: "Grocedy items group" },
  { value: "6", label: "Grocedy products" },
  { value: "4", label: "Grocedy addons" },
];

const BASE_WIDTH = 554;
const BASE_HEIGHT = 554;

const SingleImageTab = () => {
  const [file, setFile] = useState(null);
  const [categoryOption, setCategoryOption] = useState(null);
  const [product, setProduct] = useState({});
  const [tempWidth, setTempWidth] = useState(0);
  const [tempHeight, setTempHeight] = useState(0);
  const [imageSource, setImageSource] = useState(null);
  const [responseData, setResponseData] = useState("");
  const logicContext = useContext(LogicContext);
  const [productId, setProductId] = useState(null);
  const [outOfstock, setOutOfStock] = useState(false);
  const  getImageUploadList =  GetRecoilImageUploadAtom();
  const  setImageUploadList =  SetRecoilImageUploadAtom();



  useEffect(() => {
    document.title = "Upload image";
  }, []);



  const onImageLoad = (event) => {
    const imageWidth = event?.target?.naturalWidth;
    const imageHeight = event?.target?.naturalHeight;
    setTempHeight(imageHeight);
    setTempWidth(imageWidth);
    if (imageHeight !== BASE_HEIGHT){
      return logicContext.showToast(`Image height of ${imageHeight}h is unacceptable. Image width and height must be ${BASE_HEIGHT}h and ${BASE_WIDTH}w`, 'error');
    }else if(imageWidth !== BASE_WIDTH){
      return logicContext.showToast(`Image width of ${imageWidth}w is unacceptable. Image width and height must be ${BASE_HEIGHT}h and ${BASE_WIDTH}w`, 'error');
    }
  }


  const handleImageChange = (imageFile) => {
    setFile(imageFile);
    if (imageFile) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImageSource(reader.result);
      });

      reader.readAsDataURL(imageFile);
    }
  };



  const onGrocedyOptionTypeChange = (selection) => {
    const typeSelect = selection.value;

    if(!successList.includes(typeSelect)){
      return logicContext.showToast("Feature not implemented", 'info');
    }
    setImageUploadList([]);
    setCategoryOption(typeSelect);
    productService.getProductByCategory(typeSelect).then(res => {

      if(res?.data?.list){
        setImageUploadList(res?.data?.list);
      }
    });
  };




  function uploadImage() {
    if (!file ) {
      return logicContext.showToast("Please upload an image", "error");
    }

    if (tempHeight !== BASE_HEIGHT){
      return logicContext.showToast(`Image height of ${tempHeight}h is unacceptable. Image width and height must be ${BASE_HEIGHT}h and ${BASE_WIDTH}w`, 'error');
    }else if(tempWidth !== BASE_WIDTH){
      return logicContext.showToast(`Image width of ${tempWidth}w is unacceptable. Image width and height must be ${BASE_HEIGHT}h and ${BASE_WIDTH}w`, 'error');
    }
    
    const formData = new FormData();
    switch (categoryOption) {
      case '4':
        formData.append("option", '3');
        break;
      case '2':
        formData.append("option", '5');
        break;
      case '3':
        formData.append("option", '4');
        break;
      case '5':
        formData.append("option", '6');
        break;
    
      default:
        formData.append("option", '2');
        break;
    }
  
    const randomInt =  getRandomInt();
    formData.append("id", productId);
    formData.append("random_int", randomInt);
    formData.append("file", file);
    productService.uploadProductImage(formData).then((response) => {
      const itemId = response.data?.id;
      toast.success("Image uploaded successful!");
      setResponseData(JSON.stringify(response.data.Location));
      updateStore(itemId, response.data.Location);
    });
  }


  const updateStore = ( id, newLink)=> {
      let newList =  getImageUploadList.filter((item) => {
            if (item?.Item_Id === id){
              return {...item, Item_Image_URL : newLink }
            }else{
              return item;
            }
      });
      setImageUploadList(newList);
  }



  const onImageSelect = (imageItem)=> {
    setOutOfStock(imageItem?.Item_Stock_Out);
    setProduct(imageItem);
    setProductId(imageItem?.Item_Id)
    setImageSource(imageItem?.Item_Image_URL);
  }

  const onItemGroupImageSelect = (imageItem)=> {
    setProduct(imageItem);
    setOutOfStock(imageItem?.Item_Stock_Out);
    setProductId(imageItem?.group_id);
    setImageSource(imageItem?.Image_URL);
  }

  const onAdonSelect = (imageItem)=> {
    setProduct(imageItem);
    setOutOfStock(imageItem?.Item_Stock_Out);
    setProductId(imageItem?.addon_id);
    setImageSource(imageItem?.addon_image_url);
  }


  const onOutOfStockChange = (event) => {
      const decision = event.target.checked;
      setOutOfStock(!decision);
      setProduct({...product, Item_Stock_Out:!decision });
      let updatedList  = getImageUploadList.map((prod)=> {
        if(prod?.Item_Id === product?.Item_Id ){
          return {...product, Item_Stock_Out: !decision};
        }else{
          return prod
        }
      });
      setImageUploadList(updatedList);
      let option = 0;
      if (categoryOption ==  5){
          option = 8;
      }else if (categoryOption == 2){
        option = 7;
      }
      let stock_out = decision ? 0: 1;
      let data = {refno: product?.Item_Id, stock_out: stock_out,  option: option};
      productService.changeProductInStock(data).then(res => {
        console.log('res_data ', res.data);
      let myData  =  logicContext.httpResponseManager(res?.data[0]);
      if(myData?.message_id === 1){
        return logicContext.showToast(myData?.message_text,'success');
      }
      });
  }


  function getRandomInt(min=0, max=20) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

  return (
    <>
      <div className="container  jumbotron">
        <h2 className="text-center font-weight-bold text-xl text-uppercase text-primary mb-3">
          Single Image Upload
        </h2>
        <div className="row flex justify-between">
          {/* side selections  */}
          <div className="col-4 ">
          
            <div>
              Total : {getImageUploadList?.length}
              <Select
                placeholder="select type"
                onChange={onGrocedyOptionTypeChange}
                options={grocedyOptions}
                className="text-md" />
            </div>
            <div className="p-1 mt-3 border-3">
              {categoryOption ===  listDisplay.GROCEDY_ITEMS &&
              <ImageScrollGrocedyItems list={getImageUploadList} onImageSelect={onImageSelect} ></ImageScrollGrocedyItems>
              }
              {categoryOption ===  listDisplay.BUTCHERS &&
              <ImageScrollGrocedyItems list={getImageUploadList} onImageSelect={onImageSelect} ></ImageScrollGrocedyItems>
              }

              {
                categoryOption === listDisplay.GROCEDY_ITEM_GROUP &&
                <ImageScrollItemGroup list={getImageUploadList} onImageSelect={onItemGroupImageSelect} />
              }
              {
                categoryOption === listDisplay.GROCEDY_ADDONS &&
                <ImageScrollAddonList list={getImageUploadList} onImageSelect={onAdonSelect} />
              }
              
            </div>

          </div>
       
          <div className="col-6  w-full ">
            <div className="flex justify-center mb-2">
              <img className="m-1 small-pic" onLoad={(e)=> onImageLoad(e)}
                src={imageSource}  alt="" width="300" />
            </div>
            <div>

              <FileUploader
                className="uploader"
                id="uploader"
                handleChange={handleImageChange}
                name="file"
                types={fileTypes}
                multiple={false}
              />
            </div>
              <div className="text-center text-white bg-primary m-1 border-1 p-1" >{product?.Item_Name}</div>
              <div className="text-center text-white bg-primary m-1 border-1 p-1" >{product?.Item_Id}</div>
              <div className=" ">
              <input
                type="text"
                className="form-control w-50 "
                placeholder="Enter product ID"
                required
                onChange={(e) => setProductId(e.target.value)}
              />
            </div>
          

            <div className="text-center mt-2">
              <button onClick={uploadImage} className="btn btn-primary">
                UPLOAD IMAGE
              </button>
            </div>



            <div className="text-center mt-2">
            <FormGroup>
              <FormControlLabel
                    control={
                      <Switch color="primary"  checked={!outOfstock}  onChange={(event)=> onOutOfStockChange(event)}  name="gilad" />
                    }
                    label={outOfstock ? " Item is out of Stock " : "Item in stock"}
                  />
            </FormGroup>

              
            </div>
          </div>

        </div>

     
      </div>
    </>
  );
};

export default SingleImageTab;
