import React, { useState } from "react";
import { Table } from "react-bootstrap";
import {itemsService} from '../services/items.service';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';




function AssociateSection(props) {
  const [tableData, setTableData] = useState([]);


  function showInformation(){
    if(tableData.length < 1){
      itemsService.getAssociateItems(props.Item_Id).then( res => {
        if( res?.data !== undefined){
          setTableData(res.data);
        }
      })
    }

  }




  return (
    <>


   <section className="my-2 ">
   <Accordion>
        <AccordionSummary onClick={showInformation} style={{ backgroundColor: "#F1F1F1" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography >  View Associates Items</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           <section className=" purchase ">
           <span className="text-xs font-bold">Associated Items</span>
       <Table striped bordered hover>
         <thead>
           <tr style={{ backgroundColor: "#F1F1F1" }}>
             <th className="text-xs">Item Details</th>
             <th className="text-xs text-center">Quantity</th>
           </tr>
         </thead>
         <tbody>
           {tableData?.map((item, i)=> {
            return(
              <tr key={i} style={{ backgroundColor: "white" }}>
              <td className="w-60">
                <div className="w-40">
                  <span style={{color:"#4999F6"}} className=" font-bold text-sm ">{item.Item_Name} </span> <br></br>
                  <i>[SKU   : {item.Item_Sku} ]</i>
                  <div>
                  <span className="text-xs">Accouting Stock :0.00</span>
                  </div>
                  
                  <span className="text-xs">Physical Stock :0.00</span>
                </div>
              </td>
              <td className="text-xs text-center">{item?.Item_Quantity} </td>
            </tr>
            )
           })}
        
         </tbody>
       </Table>
       </section>
          </Typography>
        </AccordionDetails>
      </Accordion>
 
   </section>
    


    {/* {!showTable ?
    <button className="btn btn-sm btn-primary ml-4 mt-5" onClick={showInformation}> View Associate Items</button>
  : null  } */}
       
       
    
   
    </>
  );
}

export default AssociateSection;
