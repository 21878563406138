import React, { useRef, useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import moment from "moment/moment";
import message from "../../assets/message.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { NumericFormat } from 'react-number-format';
import CurrencyFormat from "react-currency-format";
import LogicContext from "../../Context/LogicContext";
import { useForm } from "react-hook-form";

import AddNotificationTable from "./AddNotificationTable";
import NotificationType from "./NotificationType";
import AddNotificationScrol from "./AddNotificationScrol";
import { GetRecoilNotify, SetRecoilNotify } from "../../store/notificationMainAtom";
import { notificationService } from "../../services/notification.service";
import NotificationFilter from "./NotificationFilter";





const NotificationAddNew = () => {
  const setNotifyList = SetRecoilNotify();
  const getNotifyList = GetRecoilNotify();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const navigate = useNavigate();
  const myPromiseModal = createModal(GeneralModal);
  const { register, setValue, getValues, handleSubmit, control, reset, formState: { errors } } = useForm();
  const logicContext = useContext(LogicContext);
  const [customerList, setCustomerList] = useState([]);





  useEffect(() => {
    // if (!getNotifyList.customers) {
    //   notificationService.getCustomerList().then(res => {
    //     if (res?.data?.length) {
    //       setNotifyList({ ...getNotifyList, customers: res?.data });
    //     }
    //   });
    // } else {
    //   setCustomerList(getNotifyList.customers);
    // }

  }, [getNotifyList?.customer, getNotifyList]);


  const setUserList = (ls) => {
    setSelectedUserIds(ls);
  }

  const onNotificationTypeSelect = async (value) => {
    setValue("notification_type", value?.Notification_Type_Id);

    try {
      const notiType = getValues();
      let response = await notificationService.notificationFilter({ option: notiType?.filter_option, notification_type: notiType?.notification_type });
      if (response?.data?.length > 0) {
        setNotifyList(prev => {
          return { ...prev, customers: response.data }
        });
        setCustomerList(response?.data);

      } else {
        setCustomerList([]);
      }

    } catch (error) {
      logicContext.showToast('Feature not available!');
    }
  }


  const onFilterSelect = async (value) => {
    setValue("filter_option", value?.filter_option);
    try {
      const notiType = getValues();
      let response = await notificationService.notificationFilter({ option: value?.filter_option, notification_type: notiType?.notification_type });
    console.log('res   ', response?.data);
      if (response?.data?.length > 0) {
        setNotifyList(prev => {
          return { ...prev, customers: response.data }
        });
        setCustomerList(response?.data);

      } else {
        setCustomerList([]);
      }

    } catch (error) {
      logicContext.showToast('Feature not available!');
    }

  }




  const onSubmit = async () => {
    const newData = getValues();

    if (selectedUserIds.length < 1) {
      return logicContext.showToast("At least one user should be selected!", "error");
    }
    if (!newData?.notification_type) {
      return logicContext.showToast("Notification type is required!", "error");
    }

    if (newData?.message == "") {
      return logicContext.showToast("Notification message is required!", "error");
    }

    if (newData?.subject == "") {
      return logicContext.showToast("Notification subject is required!", "error");
    }




    await myPromiseModal({
      title: "Confirmation", body: "Do you want to send notification message?",
      showInput: false, placeHolder: '',
    }).then((res) => {
      if (res.decision === true) {
        var xmlStringList = "";
        let subElement = ""
        selectedUserIds?.map((user_id) => {
          let temp = ` <a> <customer_id>${user_id} </customer_id></a>`;
          subElement += temp
        });
        xmlStringList += `<root> ${subElement}  </root>`


        const formToSubmit = { ...newData, xml_string: xmlStringList }
        if(formToSubmit?.notification_type === 1){
          // formToSubmit.message = `<p> ${formToSubmit.message.replace(/ /g,"<br/>") }</p>`  //"\\/"
          console.log('ddd ', formToSubmit.message.split(' ') );
          formToSubmit.message = `<p> ${formToSubmit.message.replace( "\\n","<br/>") }</p>`  //"\\/"
        }
        console.log('Submit ', formToSubmit);
        notificationService.sendNotificationMessage(formToSubmit).then((res) => {
          console.log('result ', res.data);
          const resultData = logicContext.httpResponseManager(res.data[0]);
          console.log('resultData', resultData);


          if (resultData?.message_id == 1) {
            logicContext.showToast(resultData?.message_text, "success");
          }

        });
      }
        
    })
      .catch((err) => {
        logicContext.showToast("error submitting record", "error");
      });
  };

  function formError(error) {
    logicContext.showToast("Form error", "error");
  }


  function onTemplateSelect(selection) {
    setValue("subject", selection?.Template_Name);
    setValue("message", selection?.Template_Message);
  }



  const onSearchKeyChange = (eve) => {
    const search = eve.target.value;

    if (search.length > 2 && getNotifyList.customers?.length) {
      let tempResult = getNotifyList.customers?.filter((customer) => {
        return customer?.Customer_Email_Address?.toString()?.indexOf(search?.toLowerCase()) >= 0 ||
          customer?.Customer_Full_Name?.toString().toLowerCase()?.indexOf(search?.toLowerCase()) >= 0 ||
          customer?.Customer_Id?.toString().toLowerCase()?.indexOf(search?.toLowerCase()) >= 0 ||
          customer?.Customer_Mobile_No?.toString().toLowerCase()?.indexOf(search?.toLowerCase()) >= 0
      });
      setCustomerList(tempResult);
    } else if (search.length < 1  && getNotifyList.customers?.length) {
      setCustomerList(getNotifyList.customers);
    }

  }



  return (
    <>

<section className="mt-2">
  <div className="mt-4">
    <div className="flex justify-end space-x-3">
      <button className="px-3 h-[6vh] text-filterTextColor bg-filterColor rounded-lg lg:pt-1">
        <img src={paper} alt="paper icon" />
      </button>
      <button className="px-3 h-[6vh] text-filterTextColor bg-filterColor rounded-lg lg:pt-1">
        <img src={pdf} alt="pdf icon" />
      </button>
      <button className="px-3 h-[6vh] text-filterTextColor bg-filterColor rounded-lg lg:pt-1">
        <img src={printer} alt="printer icon" />
      </button>
      <button className="px-3 h-[6vh] text-filterTextColor bg-filterColor rounded-lg lg:pt-1">
        <img src={pencil} alt="pencil icon" />
      </button>
      <button className="px-3 h-[6vh] text-filterTextColor bg-filterColor rounded-lg lg:pt-1">
        <img src={trash} alt="trash icon" />
      </button>
    </div>
  </div>

  <div className="flex justify-between relative mt-4">
    <AddNotificationScrol
      onTemplateSelect={onTemplateSelect}
      list={getNotifyList.notification_template}
    />
    <div className="w-full z-50 ml-[2vw] mt-2 max-auto max-w-6xl text-filterTextColor">
      <div className="border-2 p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 border-2 p-4">
          <div>
            <label className="block text-sm font-bold text-filterTextColor">
              Notification Type <span className="text-danger">*</span>:
            </label>
            <NotificationType
              onNotificationTypeSelect={onNotificationTypeSelect}
              list={getNotifyList.notification_type}
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-filterTextColor">
              Notification Filter <span className="text-danger">*</span>:
            </label>
            <NotificationFilter
              onFilterSelect={onFilterSelect}
              list={getNotifyList.notification_filter}
            />
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit, formError)}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border-2 p-4 mb-4">
            <div>
              <label className="block text-sm font-bold text-filterTextColor">
                Notification Subject <span className="text-danger">*</span>:
              </label>
              <input
                className="bg-inputColor mb-3 border-2 pl-3 w-full"
                placeholder="Enter subject"
                name="subject"
                {...register("subject", {
                  required: "Subject message is required",
                })}
              />
            </div>
            <div>
              <label className="block text-sm font-bold text-filterTextColor">
                Notification Message <span className="text-danger">*</span>:
              </label>
              <textarea
                className="bg-inputColor border-2 h-[15vh] pl-3 w-full"
                placeholder="Enter notification message"
                name="message"
                {...register("message", {
                  required: "Notification message is required",
                })}
              />
              <small className="text-danger">
                {errors?.message && errors.message.message}
              </small>
            </div>
          </div>
        </form>

        <div className="border-2 p-4">
          <div className="flex flex-col md:flex-row justify-between bg-filterColor p-4 mb-2">
            <div className="font-bold pt-2">
              <CurrencyFormat
                value={customerList?.length}
                displayType={"text"}
                thousandSeparator={true}
                suffix={" Customers"}
                renderText={(value) => <div>{value}</div>}
              />
            </div>
            <div className="pt-2">
              <input
                onChange={(ev) => onSearchKeyChange(ev)}
                className="form-control bg-white border border-gray-300 rounded-lg py-2 px-4 w-full md:w-auto"
                type="search"
                placeholder="Search (id, name, email)"
                aria-label="Search"
              />
            </div>
          </div>

          <AddNotificationTable
            setUserList={setUserList}
            list={customerList}
          />
        </div>

        <div className="flex justify-end mt-4">
          <button
            onClick={() => onSubmit()}
            className="bg-blue-700 text-white px-4 py-2 rounded-lg"
          >
            Send Message
          </button>
        </div>
      </div>
    </div>
  </div>
</section>


    </>
  );
};

export default NotificationAddNew;
