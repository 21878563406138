import { useEffect, useState } from "react";



function PurchaseDeliverCheckBox(props) {
    const [goodChecked, setGoodsChecked] = useState(true); 
    const [serviceChecked, setServiceChecked] = useState(false); 
    

    useEffect(()=> {
        props.onDeliverOptionSelect(1);
        if(props.val === 1){
            setGoodsChecked(true);
            setServiceChecked(false);
        }else if(props.val === 2){
            setServiceChecked(true);
            setGoodsChecked(false);
        }
      },[props.val])
    
    
        const handleGoodsChange = () => {
            setGoodsChecked(true);
            setServiceChecked(false);
            props.onDeliverOptionSelect(1);
          };
    
    
        const handleServiceChange = () => {
            setGoodsChecked(false);
            setServiceChecked(true);
            props.onDeliverOptionSelect(2);
          };



    return ( 
        <>
           <div className="flex justify-between tax-page">
                    <div className="flex justify-between  ">
                      <input
                        style={{ width: "20px", height: "20px" }}
                        value={goodChecked}
                        type="radio" onChange={handleGoodsChange} 
                        checked={goodChecked}
                        className="tax-page"
                      />
                      <span className="ml-2 mb-2">Warehouse</span>
                    </div>
                    <div className="flex justify-between">
                      <input
                        style={{ width: "20px", height: "20px" }}
                        value={serviceChecked}
                        type="radio" onChange={handleServiceChange} 
                        checked={serviceChecked}
                        className="tax-page"
                      />
                      <span className="ml-2 ">Customer</span>
                    </div>
                  </div>
   
        </>
     );
}

export default PurchaseDeliverCheckBox;