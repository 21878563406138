
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Table } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import { accountService} from '../services/accountService';
import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};




  function ForeignCurrencyPopUp(props) {
    const [open, setOpen] = React.useState(false);
    const [tableItems, setTableItems] = React.useState([]);
   
    const handleClose = () => setOpen(false);


    const handleOpen = () => {
        setOpen(true)
        accountService.getConversionRate(props?.item?.Account_Ref_NO,props?.defaultCurrId,
            3).then(res => {
            })
    };



  


    return (
      <div>
        <ListAltIcon color="primary" onClick={handleOpen} />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>

          <section>
            <div className='clear-text '>
            <CloseIcon className='float-right mb-4 cursor' color="primary" onClick={handleClose} />
            </div>

           
            <TableContainer className="mt-3">

              <Table sx={{ minWidth: 850 }} aria-label="simple table">
                <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                  <TableRow>
                    <TableCell>Currency Name</TableCell>
                    <TableCell>Conversion Rate</TableCell>
                   
                  </TableRow>
                </TableHead>

               
                <TableBody>
                {tableItems?.map((item, i)=> {
                          return (
                  <TableRow key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }} >
                                <TableCell align="left">{item.Id} </TableCell>
                             <TableCell align="left">{item.Name} </TableCell>
                  </TableRow>
                           )
                        })}
                </TableBody>
       
              </Table>
            </TableContainer>
          </section>
           
          </Box>
        </Modal>
      </div>
    );
  }


  export default ForeignCurrencyPopUp;